import React from 'react'
import ConnexionBox from '../components/ConnexionBox'
import { Carousel } from 'react-carousel-minimal';


const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
const Medias = () => {
    
  return (
    <>
        <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">Organigramme</h1>
                       <ConnexionBox />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row g-5 d-flex justify-content-center flex-wrap">
                <Carousel
                    data={data}
                    time={2000}
                    width="1550px"
                    height="600px"
                    captionStyle={captionStyle}
                    radius="10px"
                    slideNumber={true}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={true}
                    thumbnailWidth="100px"
                    style={{
                    textAlign: "center",
                    maxWidth: "1550px",
                    maxHeight: "600px",
                    margin: "40px auto",
                    }}
                />
                </div>
            </div>
        </div>
    </>
  )
}

export default Medias


const data = [
    {
        image:require('assets/medias/IMG-20230504-WA0003.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0004.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0005.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0006.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0007.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0008.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230504-WA0015.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0001.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0002.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0004.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0011.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0013.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0017.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0023.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0024.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0029.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0026.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0027.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0029.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0047.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0048.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0049.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0051.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0054.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0082.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0089.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230509-WA0092.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230519-WA0002.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230519-WA0003.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/IMG-20230519-WA0008.jpg'),
        caption: "Activités"
    },
    {
        image: require('assets/medias/nchgq3xkPLu2a_22np_ctnMgs8Jwdg8-rSnfno9h9PM=_plaintext_638192069225482411.jpg'),
        caption: "Activités"
    }
  ];