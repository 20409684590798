import React,{useCallback,useEffect,useState} from 'react'
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Card } from 'components'
import { Form } from 'components'
import { villes,secteuractivites,metiers,niveauetudes,niveauexperience,competences } from 'fixtures/data';
import { makeStyles } from 'tss-react/mui'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'custumstyle.css'
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'lib/axios';
import swal from 'sweetalert';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import { useSelector } from 'react-redux';

const useStyles = makeStyles()((theme=>({
    coreadd:{
      width:'60%',
      alignSelf:'center',
      [theme.breakpoints.down('md')]: {
        width:'100%',
      },
    }
})))


function UpdateOffres() {

  const {classes} =useStyles()
  const location = useLocation()
  const data =location.state.data
  const [partenaires,setPartenaires] = React.useState(null)
  const [currentField,setCurrentField] = useState({...data})
  const navigate = useNavigate()
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [categorie,setCategorie] = useState(null)
  React.useEffect(()=>{
    axios.get(`/api/partenaire/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setPartenaires(res.data)
      }
    })
  },[])
  
  const handleList = ()=>{
    navigate(`/${portail}/offres`)
  }

  const handleChange = (event) => {
    const {name,value} =event.target
    setCurrentField({...currentField,[name]:value})
  };
    
    const handleChangeDate = (name,newValue) => {
      setCurrentField({...currentField, [name]:newValue})
    };

  const handeleAutoCompleteChange =(name,values)=>{setCurrentField({...currentField,[name]:values})}
  const [categories,setCategories] = useState(null)
 
  const handleCategories = useCallback(()=>{
      axios.get(`/api/categorie-offre/all`).then(res=>{
          if(res.status === 200 || res.status === 201){
            setCategories(res.data)
          }
      })
  },[data])


  const handleSelectCategorie = (cat)=>{
    if(cat){
        setCategorie(cat),
        setCurrentField({...currentField,"categorie":cat})
    }
  }

 useEffect(()=>{
      handleCategories()
      if(!currentField.categorie_id){
        setCurrentField({...currentField,['categorie_id']:data.categorie.id})
        setCategorie(data.categorie)
      }
      if(data.hasOwnProperty(partenaires) && data.partenaires.length!==0){
        setCurrentField({...currentField,['partenaire']:data.partenaires[0].id})
      }
      handleDefaulValue()
  },[data])
  useEffect(()=>{
    if(categories&& currentField.categorie_id){
      handleSelectCategorie(categories.filter(item=>item.id===currentField.categorie_id)[0])
    }
  },[currentField.categorie_id])
  const handleReset =()=>{
    handleList()
  }

  const handleDefaulValue =() =>{
      let val
      if(currentField.hasOwnProperty('partenaires') && currentField.partenaires && partenaires){
        if( typeof currentField.partenaires === 'object'){
          val =   (partenaires && partenaires.length!==0)&&partenaires.filter(item=>item.id === currentField.partenaires[0].id)[0]
        }else{
          val =   (partenaires && partenaires.length!==0)&&partenaires.filter(item=>item.id === currentField.partenaires)[0]
        }
      }else{
        if(data.hasOwnProperty('partenaires') &&   data.partenaires.length!==0){
          val = data.partenaires[0]
        }
      }
      return setDefaultPart(val);
  }


  const handleSubmit = (e)=>{
    e.preventDefault()
    const fieldSend = {...currentField}
      axios.put(`/api/offre/update/${data['id']}`,fieldSend).then(res=>{
        if(res.status === 200 || res.status === 201){
          swal("Bien","Modification effectué avec succès",'success')
        }else{
          swal("Ooops!","Impossible d'efefctué cette opération","error")
        }
      }).catch(err=>{
        console.log(err.response)
        swal("Ooops!","Erreur lors de la modification","error")
      })
  }
  const [defaultPart,setDefaultPart] = useState(null)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={handleSubmit}>
          <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
            <Card>
              <Card.Header>
                  <Card.Title>Modification offre -  {currentField['code']}</Card.Title>
                  <Card.Hr />
                </Card.Header>
                <div className={classes.coreadd}>
                      {(categories && categories.length!==0)&&<Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                            <Form.Input required size="small" name="categorie_id" margin="dense" onChange={handleChange} value={currentField['categorie_id']||""} select style={{marginRight:20}} wd='100%'  label="Catégorie d'offre">
                                {categories.map(cat=><MenuItem key={cat.id}  value={cat.id}>{cat.libelle}</MenuItem>)}
                            </Form.Input>
                      </Form.Group>}
                
                      <Form.Group>
                        <RadioField
                            groupItems={[{label:'ML',name:'ML'},{label:'Autre',name:'Autre'}]}
                            group="Origine"
                            checkedDefault="Normal"
                            appearance={radiobutton_appearances.primary}
                            onChange ={handleChange}
                            name='origine'
                            vvalue ={currentField.origine}
                        />
                      </Form.Group>
                      <Form.Group  margin='10px 0 0px 0'direction ='row' flex={1} >
                      {(partenaires && partenaires.length!==0)&&<Autocomplete
                        id="partenaire-standard"
                        fullWidth
                        defaultValue={defaultPart?defaultPart:[]}
                        options={partenaires}
                        getOptionLabel={(option) => option.raisonsociale}
                        onChange={(e,values)=>values&&handeleAutoCompleteChange("partenaires",values['id'])}
                        renderInput={(params) => (
                          <Form.Input
                            {...params}
                            name='partenaires'
                            value={currentField['partenaires']}
                            label="Partenaires"
                            placeholder="Partenaire"
                            size="small" margin="dense" wd='100%'
                          />
                        )}
                      />}
                    
                      </Form.Group>
                      <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                        <Form.Input name='titre' value={currentField.titre} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Titre" wd='100%' />
                      </Form.Group>
                      
                      
                      <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                          <Form.Input required size="small" name="typecontrat" margin="dense" onChange={handleChange} value={currentField['typecontrat']||""} select style={{marginRight:20}}  label="Type de contrat">
                                  <MenuItem  value='No'>Aucun</MenuItem>
                                  <MenuItem value='CDI'>CDI</MenuItem>
                                  <MenuItem value='CDD'>CDD</MenuItem>
                                  <MenuItem value='Stage'>Stage</MenuItem>
                                  <MenuItem value='Apprentissage'>Apprentissage</MenuItem>
                                  <MenuItem value='Travail tempaoraire'>Travail tempaoraire</MenuItem>
                                  <MenuItem value='Consultance'>Consultance</MenuItem>
                                  <MenuItem value='Autre'>Autre</MenuItem>
                              </Form.Input>
                      </Form.Group>

                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                      <Form.Label>Missions</Form.Label>
                          <ReactQuill  placeholder={"Missions"} name='missions' theme="snow" value={currentField.missions} onChange={(v)=>setCurrentField({...currentField,['missions']:v})} />
                      </Form.Group>
                      <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                      <DesktopDatePicker
                        label="Date debut"
                        inputFormat="dd/MM/yyyy"
                        value={currentField["datedebut"] || new Date()}
                        name='datedebut'
                        onChange={(val)=>handleChangeDate('datedebut',val)}
                        renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      <DesktopDatePicker
                        label="Date cloture"
                        inputFormat="dd/MM/yyyy"
                        value={currentField["datefin"]  ||new Date()}
                        name='datefin'
                        onChange={(val)=>handleChangeDate('datefin',val)}
                        renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      </Form.Group>
                      <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                      <Form.Input 
                          size="small" 
                          name="ville" 
                          margin="dense" 
                          onChange={handleChange} 
                          value={currentField['ville']||""} 
                          select 
                          wd="100%"
                          label="Lieu">
                              <MenuItem  value='No'>Aucun</MenuItem>
                              {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem key={ville} value={ville}>{ville}</MenuItem>))}
                        </Form.Input>
                      </Form.Group>
                      <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                        <Form.Input name='nombrepost' value={currentField.nombrepost} type='Number' style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Nombre de post" wd='50%' />
                      </Form.Group>

                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                      <Form.Label>Profils recherchés</Form.Label>
                          <ReactQuill  placeholder={"Profils recherchés"} name='profils' theme="snow" value={currentField.profils} onChange={(v)=>setCurrentField({...currentField,['profils']:v})} />
                      </Form.Group>
                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                      <Autocomplete
                            multiple
                            freeSolo
                            id="experience-standard"
                            fullWidth
                            defaultValue={currentField['niveauexperience'] ||[]}
                            options={niveauexperience}
                            onChange={(e,values)=>handeleAutoCompleteChange("niveauexperience",values)}
                            renderInput={(params) => (
                              <Form.Input
                                {...params}
                                name='niveauexperience'
                                label="Niveau d'experience"
                                placeholder="Niveau d'experience"
                                size="small" margin="dense" wd='100%'
                              />
                            )}
                          />
                    
                      </Form.Group>
                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                          <Autocomplete
                            multiple
                            freeSolo
                            id="partenaire-standard"
                            fullWidth
                            defaultValue={currentField['niveauetudes'] ||[]}
                            options={niveauetudes}
                            onChange={(e,values)=>handeleAutoCompleteChange("niveauetudes",values)}
                            renderInput={(params) => (
                              <Form.Input
                                {...params}
                                name='niveauetudes'
                                label="Niveau d'etude"
                                placeholder="Niveau d'etude"
                                size="small" margin="dense" wd='100%'
                              />
                            )}
                          />
                          
                      </Form.Group>

                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                      <Autocomplete
                            multiple
                            freeSolo
                            id="secteur-standard"
                            fullWidth
                            defaultValue={currentField['secteuractivites'] ||[]}
                            options={secteuractivites}
                            onChange={(e,values)=>handeleAutoCompleteChange("secteuractivites",values)}
                            renderInput={(params) => (
                              <Form.Input
                                {...params}
                                name='secteuractivites'
                                label="Secteurs d'activité concernés"
                                placeholder="Secteurs d'activité concernés"
                                size="small" margin="dense" wd='100%'
                              />
                            )}
                          />
                        
                      </Form.Group>
                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                        <Autocomplete
                            multiple
                            freeSolo
                            id="metiers-standard"
                            fullWidth
                            defaultValue={currentField['metiers']||[]}
                            options={metiers}
                            onChange={(e,values)=>handeleAutoCompleteChange("metiers",values)}
                            renderInput={(params) => (
                              <Form.Input
                                {...params}
                                name='metiers'
                                label="Metiers concernés"
                                placeholder="Metiers concernés"
                                size="small" margin="dense" wd='100%'
                              />
                            )}
                          />
                      </Form.Group>
                  
                    
                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                        <Autocomplete
                            multiple
                            freeSolo
                            id="competence-standard"
                            fullWidth
                            defaultValue={currentField['competences'] ||[]}
                            options={competences?competences:[]}
                            onChange={(e,values)=>handeleAutoCompleteChange("competences",values)}
                            renderInput={(params) => (
                              <Form.Input
                                {...params}
                                name='competences'
                                label="Compétences exigées"
                                placeholder="Compétences exigées"
                                size="small" margin="dense" wd='100%'
                              />
                            )}
                          />
                      </Form.Group>
                      <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                          <Form.Label>Description</Form.Label>
                          <ReactQuill  
                              placeholder={"Plus d'information"} 
                              name='description' 
                              theme="snow" 
                              value={currentField.description} 
                              onChange={(v)=>setCurrentField({...currentField,['description']:v})} 
                          />
                      </Form.Group>
              </div>
            </Card>
            <Card>
              <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                      <Form.Button onClick={handleReset} >Annuler</Form.Button>
                      <Form.Button type="submit"  primary>Valider</Form.Button> 
              </Form.Group>
            </Card>
              
          </div>
        </form>
      </LocalizationProvider>
  )
}
UpdateOffres.propTypes = {
  data: PropTypes.object
};
export default UpdateOffres