import useSWR from 'swr'
import axios from 'lib/axios'
import {useEffect, useState} from 'react'

export const useJeune = (id) => {
    const { data:jeune, error, isLoading,mutate } = useSWR(`/api/personne/${id}`,url=> 
        axios.get(url)
             .then(res=>res.data)
             .catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
    }))
    const [jeuneInfos,setJeuneInfos] = useState({})

    const getDossier = async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/dossier/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {"dossier":res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const getState = async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/state/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {"stats":res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const getDemandes =async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/demandes/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {"demandes":res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const addDemande = () => {

    }

    const getDocuments = async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/documents/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {"documents":res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const addDocument = ()=>{

    }
    const getOffres = async () =>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/offres/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {"offres":res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const postuler = ()=>{

    }
    const getDispositifs =async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/dispositifs/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                   return {'dispositifs':res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }

    const getEvenements =async ()=>{
        if(jeune && !error){
            return axios.get(`/api/portail-jeune/evenements/${jeune?.id}`).then(res=>{
                if(res.status === 200 || res.status ===201){
                    return {'evenements':res.data}
                }else{
                    return null
                }
            }).catch(err=>{
                if (err.response.status !== 409) {
                    throw error
                }
                console.log(err.response);
                return null
            })
        }else{
            return null
        }
    }
    const resoleRequest = ()=> {
        Promise.all([getDossier(),getDemandes(),getState(),getDocuments(),getOffres(),getDispositifs(),getEvenements()]).then(values=>{
            const newInfos ={}
            values.forEach(el=>{
                if(el){
                    Object.assign(newInfos, el);
                }
            })
            setJeuneInfos({...newInfos})
            
        })
    }
    useEffect(()=>{
        resoleRequest()
    },[jeune])

    return{
        jeune,
        isLoading,
        isError: error,
        jeuneInfos,
        postuler,
        addDemande,
        addDocument
    }
}