import { Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Form, InputGroup} from 'react-bootstrap';

import axios from 'lib/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Table from 'react-bootstrap/Table';
import {Pagination} from "@mui/material";
import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';
import { Card } from 'components'

import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ModuleList = (props) => {

    const [search,setSearch] = useState(null)
    const [modules,setModules] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const [currentModule,setCurrentModule] = useState(null)
  
    const [loading,setLoading] = useState(false)
    const [mode,setMode] = useState('ADD')
    const navigate = useNavigate()
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    
    const handleModule = (numberPage=1)=>{
        const url = search?`/api/modules/list/${search}?page=${numberPage}`:`/api/modules/list?page=${numberPage}`
        axios.get(url).then(res=>{
          if(res.status ===200 || res.status === 201){
            setModules(res.data.data)
            setPagnateData({
                current_page:res.data.current_page,
                last_page:res.data.last_page,
                per_page:res.data.per_page,
                total:res.data.total
              })
          }   
        })
      }
      useEffect(()=>{
        return handleModule()
      },[])
      const handleDelete = (id)=>{
        swal({
            title: "Voullez-vous vraiment supprimer ce privilège?",
            text: "Cett opération est irreversible, cependant elle ne supprimera pas le module entier, uniquement le privilège selectionné. Pour supprimer tout le module y compris les privilèges veuillez vous rendre sur la fiche du module",
            icon: "warning",
            buttons:{
            cancel:"Annuler",
            confirm:'Supprimer'
            },
            dangerMode: true,
        }).then(willDelete=> {
            if(willDelete){
            axios.post(`api/modules/delete/privilege/${id}`).then(res=>{
                if(res.status ===204 || res.status ===200 && res.status ===201){
                swal(
                    'Suppression!',
                    'Module supprimé avec succès.',
                    'success'
                )
                navigate(`/${portail}/parametres`)
                }else{
                swal({
                    title:"Oops",
                    text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
                    icon:"error",
                    buttons:{
                    confirm:{
                        text:"OK",
                        className:'bg-red-500'
                    }
                    }
                })
                }
            })
            }
            
        })
      }
      

      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
       };
      const handleClose = () => {
            setAnchorEl(null);
      };
      const [openImp,setOpenImp] = useState()
      const handleCloseImp =() =>{
        setOpenImp(false)
      }
      const handleOpenImp =() =>{
        setOpenImp(true)
      }
  return (
    <div className='container mx-auto'>
        <div className='flex justify-between items-center p-2'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Gestion des modules/Privilèges</Typography>
            <div className='flex flex-row items-center gap-2'>
                <Button 
                    variant="outline-success" 
                    className="bg-green-600 text-white flex justify-center items-center hover:bg-green-900" 
                    onClick={()=>navigate(`/${portail}/parametres/modules/add`)}>
                    <AddIcon/>Ajouter un module/privilège
                </Button>
                <Button 
                    variant="outline-success" 
                    className="bg-green-600 text-white flex justify-center items-center hover:bg-green-900" 
                    onClick={()=>navigate(`/${portail}/parametres/modules/importation`)}>
                    <AddIcon/>Importer
                </Button>
            </div>
            
        </div>
        <div className="w-full p-2 flex flex-col gap-3">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="flex px-2 pt-3" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(libelle,statut.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='bg-green-600 hover:bg-orange-500 text-white' onClick={()=>handleModule(1)}>
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Portail</th>
                        <th>Module</th>
                        <th>Libelle</th>
                        <th>Permissions</th>
                        <th>Subperms</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(modules && modules.length!==0)?modules.map(module=><tr>
                            <td>{module['id'] || ""}</td>
                            <td>{module.portail?.libelle || ""}</td>
                            <td>{module.module.libelle || ""}</td>
                            <td>{module['libelle'] || ""}</td>
                            <td>{module['perms'] || ""}</td>
                            <td>{module['subperms'] || ""}</td>
                            <td>
                                <div className="tbaction flex flex-row justify-center items-center">
                                    <Tooltip title="Détail">
                                        <IconButton onClick={()=>navigate(`/${portail}/parametres/modules/fiche/${module.module.id}`)} >
                                            <ListAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editer" onClick={()=>navigate(`/${portail}/parametres/modules/edit/${module.module.id}`)}>
                                        <IconButton >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Supprimer">
                                        <IconButton onClick={()=>handleDelete(module.module.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>{/**
                                    <Tooltip title="plus d'action">
                                        <IconButton onClick={handleClick}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip> */}
                                </div>
                            </td>
                        </tr>):<tr><td colSpan={3}><p>Aucun module trouvé, Veuillez utiliser le bouton "Ajouter un module" pour créer un nouveau module</p></td></tr>}
                    
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleNature(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
            </Paper>
        </div>
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
        <MenuItem onClick={handleClose}>Fiche module</MenuItem>
        <MenuItem onClick={handleClose}>Liste des droits</MenuItem>
        <MenuItem onClick={handleClose}>Portails référencé</MenuItem>
        <MenuItem onClick={handleClose}>Profils associés</MenuItem>
      </Menu>
    </div>
  )
}

export default ModuleList