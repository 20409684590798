import React, { useCallback, useState } from 'react'
import { Button, IconButton, Pagination, Paper, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import './styles.css'
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material'
import "./styles/ProfilsAdd.scss"
import axios from 'lib/axios';
import { useEffect } from 'react';
import swal from 'sweetalert';
import { Alert, Form, InputGroup, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth } from 'hooks/auth';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import ModulePrivilege from './ModulePrivilege';
import slug from 'slug'
function ResultSearch ({resultData,handleChangePage,dettacheProfil,attacheProfil,profil,usersLier}){
    const {data,current_page,last_page,per_page,total} =resultData
    
    return <div style={{display:'flex',flexDirection:'column',marginTop : 20}}>
    <Card.Hr />
    <Typography>-- RESULTAT DE LA RECHERCHE</Typography>
    <Table striped bordered hover>
        <thead>
            <tr>
                <th>Nom</th>
                <th>Né(e) le</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {(data.length!==0)?data.map(userRow=>(<tr key={userRow.id}>
                <td>{`${userRow.personne?.civilite} ${userRow.personne?.nom} ${userRow.personne?.prenom}`}</td>
                <td>{`${dayjs(userRow.personne?.datenaissance).format('DD/MM/YYYY')}`}</td>
                <td>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {usersLier?((usersLier.findIndex(item=>item.id === userRow.id)=== -1)?(
                        <IconButton onClick={()=>attacheProfil(userRow.id,profil)}>
                            <AddCircleOutlineIcon />
                        </IconButton>):(
                        <IconButton onClick={()=>dettacheProfil(userRow.id)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>)):(<IconButton onClick={()=>attacheProfil(userRow.id,profil)}>
                            <AddCircleOutlineIcon />
                        </IconButton>)}
                        {}
                        
                        
                    </div>
                </td>
            </tr>)):<tr><td colSpan={3}><p>Aucun résultat trouvé</p></td></tr>}
        </tbody>
    </Table>
    <div style={{display:'flex',justifyContent:'flex-end'}}>
    <Pagination
        component="div"
        count={total}
        defaultPage={1}
        page={current_page}
        onChange={(event,numberPage)=>handleChangePage(numberPage)}
        rowsPerPage={per_page}
        variant='outlined'
        showFirstButton={current_page>10} 
        showLastButton ={total>=10}
        />
    </div>
    
</div>
        
    
}

function ProfilsAdd(props) {
    const location  =  useLocation()
    const {mode,profil} = location.state
    const [loading,setLoading] = useState(false)
    const [errors,setErrors] = useState(null)
    const [cprofil,setCprofil]  =useState(profil)
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const navigate =useNavigate()
    const {user,assignerRole,removeRole} = useAuth({middleware: "auth"})

    const handlelist = ()=>{
        navigate(`/${portail}/profils`)
    }
    const [activeStep,setActiveStep] = useState(1)
    const [parcouru,setParcouru] =useState([1])
    const [portails,setPortails] = useState(null)

    const handlePortails = () =>{
        axios.get(`/api/portail/list`).then(res=>{
            if(res.status ===200 || res.status === 201){
                setPortails(res.data.data)
            }
        })
    }

    const [inputField,setInputField] = useState(mode==='UP' ? {...profil}:{portails:[]})
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const handleEditorChange =(field,value)=>{
        setInputField({...inputField,[field]:value});
    }
    
    const handleCheckboxChange =(port)=>{
       
        let currentPortail =inputField.portails? [...inputField.portails]:[]
        let indexfind = currentPortail.findIndex(item=>item.id === port.id)
        
        if(indexfind !== -1){
            const newPort = currentPortail.filter(item=>item.id !==port.id)
            setInputField({...inputField,'portails':[...newPort]})
        }else{
            const newPort = [...currentPortail,{id:port.id,default:0}]
            
            setInputField({...inputField,'portails':[...newPort]})
        }
    }

    const handleCheckDefault = (port,value)=>{
        let currentPortail = [...inputField.portails]
        const newPort =currentPortail.map(element=>{
            if((element.id===port.id) && value ==='on'){
                element['default'] = 1
                return element
            }else{
                element['default'] = 0
                return element
            }
        })
       
        setInputField({...inputField,['portails']:[...newPort]})
    }

    useEffect(()=>{
        handlePortails()
    },[])
    //enregistrement des données
   const handleSubmit  = (e) =>{
        e.preventDefault()
        setLoading(true)
        if(!inputField.hasOwnProperty('libelle') || inputField.libelle.trim() ==="" ){
            swal("Erreur !","Veuillez renseigner un intitulé",'error')
            setLoading(false)
            return
        }
        if(!inputField.hasOwnProperty('portails') || inputField.portails.length ===0 ){
            swal("Erreur !","Veuillez selectionner au moin un portail",'error')
            setLoading(false)
            return
        }

        const dataSend = {
            libelle: inputField.libelle,
            description: inputField.description,
            portails: inputField.portails,
        }
        if(mode==='ADD'){
            axios.post(`/api/profile/add`,dataSend).then(res=>{
                if(res.status === 200 || res.status ===201){
                    setCprofil(res.data)
                    handleGetUserLier(res.data?.id,1)
                    swal("Bien !","Profile créé avec succès, Vous pouvez rattacher des utilisateurs",'success')
                    setLoading(false)
                    setParcouru([...parcouru,activeStep])
                    setActiveStep(activeStep + 1)
                }else{
                    swal("Erreur !","Erreur lors de la création du profils, veillez corriger et réessayer",'error')
                    setLoading(false)
                }
            }).catch(err=>{
                console.log(err)
                if(err.hasOwnProperty("response")){
                    setErrors(err.response.data?.errors)
                }
                setLoading(false)
            })
        }else{
            dataSend['id'] = inputField.id
            axios.post(`/api/profile/update/${cprofil.id}`,dataSend).then(res=>{
                if(res.status === 200 || res.status ===201){
                    setCprofil(res.data)
                    handleGetUserLier(res.data?.id,1)
                    swal("Bien !","Profile a été modifié avec succès, Vous pouvez rattacher des utilisateurs",'success')
                    setLoading(false)
                    setParcouru([...parcouru,activeStep])
                    setActiveStep(activeStep + 1)
                }else{
                    swal("Erreur !","Erreur lors de la modification du profils, veillez corriger et réessayer",'error')
                    setLoading(false)
                }
            }).catch(err=>{
                console.log(err)
                if(err.hasOwnProperty("response")){
                    setErrors(err.response.data?.errors)
                }
                setLoading(false)
            })
        }
        
   }

   //utilisateurs
   const [usersLier,setUsersLier] = useState([])
   const handleGetUserLier = (profil,numberPage)=>{
        const url = `api/users/user-profile/${profil}?page=${numberPage}`
        axios.get(url).then(res=>{
            if(res.status === 200 || res.status === 201){
                setUsersLier(res.data)
            }else{
                console.log(res.data);
            }
        }).catch(err=>{
            if(err.hasOwnProperty('response')){
                console.log(err.response)
            }
        })
   }

  const handleDettacheUser = (user) =>{
    if(user && cprofil){
        if(removeRole(cprofil.libelle,user.id)){
            handleGetUserLier(cprofil.id,1)
            setSmessage("L'utilisateur à bien été détaché avec succès")
            handleSopen()
        }else{
           setSmessage("Erreur l'hors de l'opération")
           handleSopen()
        }
    }
  }

   const handleAttacheProfil = (user,pro) =>{
        if(user,pro,cprofil){
            if(assignerRole(cprofil.libelle,user)){
                handleGetUserLier(pro,1)
                setSmessage("L'utilisateur à bien été attché au profil")
                handleSopen()
            }else{
               setSmessage("Erreur l'hors de l'opération")
               handleSopen()
            }
        }
   }

   const [smessage,setSmessage]=  useState("Opération effctué avec succes")
   const [sopen,setSopen] = useState(false)
   const handleSopen = () => {
       setSopen(true);
    };
    const handleSClose = (event, reason) => {
       if (reason === 'clickaway') {
         return;
       }
       setSopen(false);
    };

   const [inputSearch,setInputSearch] = useState(null)
   const handelChangeInputSearch = (e)=>{
   const {name,value} = e.target
     setInputSearch({...inputSearch,[name]:value})
   }
   const [usersResult,setUsersResult] = useState(null)
   const handleSearchUser = (pageNumger=1)=>{
        if(!inputSearch?.hasOwnProperty('nom') && inputSearch?.hasOwnProperty('datenaissance')){
            swal("Ooops","Veuillez renseigner au moin un champ de recherche","warning")
            return
        }
        const dataSend ={
            ...inputSearch,
            structure_id:user?.hasOwnProperty('structure_id')?user.structure_id:null
        }
        const url = `/api/users/basic-search?page=${pageNumger}`
        axios.post(url,dataSend).then(res=>{
            if(res.status===200 ||  res.status===201){
                setUsersResult(res.data)
            }else{
                swal("Ooops","Aucun utilisateur trouver","warning")
                return
            }
        }).catch(err=>{
            if(err.hasOwnProperty('response')){
                console.log(err.response);
                setErrors(err.response.data?.errors)
            }
        })
   }

   const assPortail = async (resData,profil)=>{
        let rdata=[]
        let cPrivile =resData.map(async (prt)=>{
            const privileges =  await axios.get(`/api/modules/privilege-from-profil/${profil.id}/${prt.id}`).then(res=>((res.status===200 ||  res.status===201) ? res.data[0] : []))
            const permissions = await axios.get(`/api/user-perms-role/${profil.id}`).then(res=>((res.status===200 ||  res.status===201) ? res.data : []))
           
            if(privileges && permissions && permissions.length!==0){
                privileges.modules.map(mod=>{
                    Array.isArray(mod.privileges) ? mod.privileges.map(per=>{
                        const permis = `${slug(profil.libelle)}_${prt.id}_${mod.codemodule}_${per.perms}`
                      
                        if(permissions.find(p=>p==permis)){
                            per['checked']=true
                            mod['checked']=true
                        }
                        return per
                    }  ): null 
                    return mod
                })
            }
       
            return {id:prt.id,libelle:prt.libelle,default:prt.default,privileges:privileges ? privileges.modules :null}
        })
        rdata = await Promise.all(cPrivile);
        return rdata
   }

    const chargeProfilData =()=>{
        if(mode === 'UP'){
            axios.get(`/api/profile/${profil.id}`).then(async res=>{
                if(res.status === 200 || res.status ===201){
                    const cdata ={
                        'libelle':res.data?.libelle,
                        'id':res.data.id,
                        'description':res.data.description,
                        'statut':res.data.statut,
                        'portails':await assPortail(res.data.portails,profil)
                    }
                   
                    setInputField({...inputField,...cdata})
                }
            })
        }
    }

   //chargement des données de modification
   useEffect(()=>{
    chargeProfilData()
   },[mode,profil])
   useEffect(()=>{
    if(profil){
        handleGetUserLier(profil.id,1)
    }
   },[profil])
   useEffect(()=>{
    setLoading(false)
   },[])
   //selection de rôle
   const handleChecked =(list=[])=>{
        if(list&&inputField.hasOwnProperty('portails') && inputField.portails.length!==0 && list.length!==0){
            const currentportail = [...inputField.portails]
            const copiePrivileges = structuredClone(list)

            const newPortail = currentportail.map(inputPortail=>{

                 const cop=copiePrivileges.filter(item=>item.portail_id === inputPortail.id)?.filter(mod=>{ 
                    const cp =  Array.isArray(mod.privileges)&&[...mod.privileges].filter(pr=>pr.checked===true)
                    if(cp  && cp.length!==0){
                        mod.privileges = cp
                        return true
                    }else{
                        return false
                    }
                })
                if(cop && cop.length!==0){
                    inputPortail['privileges']=cop
                }
                return  inputPortail
            })
            setInputField({...inputField,'portails':[...newPortail]})
        }
   }

   
 
  return (
    <div className="profilsadd">
        <div className="titlebox">
            <Typography variant='h5'>{mode==='UP'?"Nouveau profil":"Mise à jour du profil #"+profil?.id}</Typography>
            <Button className="btn" onClick={(e)=>handlelist()}><ArrowBackIosIcon sx={{color:'#00B04B'}} />Retour à la liste</Button>
        </div>
        <div className="bodybox">
            <form onSubmit={handleSubmit}>
                <Tab.Container id="left-tabs-example" activeKey={activeStep}>
                    <Row>
                        <Col sx={12} sm={4} md={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item onClick={()=>mode==='UP'&&setActiveStep(1)}>
                                    <Nav.Link className={parcouru.includes(1)&&'nav-parcouru'} eventKey="1">DESCRIPTION</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={()=>mode==='UP'&&setActiveStep(2)}>
                                    <Nav.Link className={parcouru.includes(2)&&'nav-parcouru'} eventKey="2">UTILISATEURS</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>

                        <Col sx={12} sm={8} md={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="1">
                                    <Paper className="formbox" variant='outlined'>
                                        <Typography>DESCRIPTION</Typography>
                                            <Card>
                                                <Card.Header>
                                                    <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                                                    <Card.Hr />
                                                </Card.Header>
                                                <CustumForm>
                                                    <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                                    <CustumForm.Input 
                                                        size="small" 
                                                        margin="dense" 
                                                        style={{marginRight:20,marginBottom:20}}  
                                                        label="Intitule"
                                                        required 
                                                        wd='100%'
                                                        name='libelle' 
                                                        value={inputField['libelle'] ||""}
                                                        onChange={handleChange} 
                                                        />
                                                        {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                                                <ul>
                                                                {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                                                </ul>
                                                            </Alert>}
                                                        <ReactQuill  
                                                        placeholder={"Description du profil"} 
                                                        style={{width:'100%',marginBottom:20}}
                                                        name='description' 
                                                        theme="snow" 
                                                        value={inputField['description']||""} 
                                                        onChange={(v)=>handleEditorChange('description',v)} 
                                                    
                                                    />
                                                    {(errors && errors.hasOwnProperty('description'))&&<Alert key="danger" variant="danger">
                                                                <ul>
                                                                {errors['description'].map((err,index)=><li key={index}>{err}</li>)}
                                                                </ul>
                                                            </Alert>}
                                                    </CustumForm.Group>
                                                    
                                                </CustumForm>
                                            </Card>
                                            
                                            <Card>
                                                <Card.Header>
                                                    <Card.Title>ACCES AUX  DIFFERENTS PORTAILS</Card.Title>
                                                <Card.Hr />
                                                </Card.Header>
                                                {(errors && errors.hasOwnProperty('portails'))&&<Alert key="danger" variant="danger">
                                                                <ul>
                                                                    {errors['portails'].map((err,index)=><li key={index}>{err}</li>)}
                                                                </ul>
                                                            </Alert>}
                                                {(portails && portails.length!==0)&&
                                                <CustumForm>
                                                    <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                                        <CustumForm.Group direction ='column' salign='center' flex={1}>
                                                        {portails.map((port,index)=>(<div  key={index}  style={{display:'flex',alignItems:'center'}}>
                                                            <FormControlLabel control={ 
                                                                <Checkbox 
                                                                    name={port.libelle} 
                                                                    onChange={(e)=>handleCheckboxChange(port)}  
                                                                    color="success" />} 
                                                                    checked={(inputField.portails.findIndex(item=>item.id === port.id)!== -1)}
                                                                    label={`PORTAIL ${port.libelle.toUpperCase()}`} />
                                                                <Form.Check 
                                                                    type="radio"
                                                                    disabled = {(inputField.portails.findIndex(item=>(item.id === port.id)) === -1)}
                                                                    id={`d-${port.id}`}
                                                                    label="Par defaut"
                                                                    name ='default'
                                                                    checked={(inputField.portails.findIndex(item=>((item.id === port.id) && item.default===1)) !== -1)}
                                                                    onChange={(event)=>handleCheckDefault(port,event.target.value)}
                                                                />
                                                            
                                                            </div>)
                                                        )}

                                                        </CustumForm.Group>
                                                    </CustumForm.Group>
                                                    </CustumForm>
                                                }
                                                
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <Card.Title>DROITS D'ACCES PAR PORTAIL</Card.Title>
                                                <Card.Hr />
                                                </Card.Header>
                                                {(portails && portails.length!==0 && inputField.hasOwnProperty('portails') && inputField.portails.length!==0)&&<div>
                                                    <Accordion defaultActiveKey="1">
                                                   
                                                       {(portails.filter(item=>(inputField.portails.findIndex(el=>(el.id===item.id || el.id===item.id)) !== -1 ))).map((port,idx)=>(
                                                            <Accordion.Item key={`custumer-${port.libelle}`} eventKey={idx}>
                                                                <Accordion.Header>{`PORTAIL ${port.libelle.toUpperCase()}` }</Accordion.Header>
                                                                <Accordion.Body>
                                                                   <ModulePrivilege 
                                                                    mode ={mode}
                                                                    portail={port.id} 
                                                                    handleChecked={handleChecked} 
                                                                    pprivileges={inputField.portails.filter(iport=>iport.id===port.id)[0]?.privileges}
                                                                  />
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                       ))}
                                                        
                                                    </Accordion>
                                                 </div>}
                                                
                                                    
                                            </Card>
                                            <Card style={{padding:20,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                                                    {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                                                </Button>
                                            </Card>
                                    </Paper>
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <div style={{display:'flex',flexWrap:'wrap'}}>
                                        <Paper className="listbox" variant='outlined'>
                                            <Typography>UTILISATEURS</Typography>
                                            <Card>
                                                <Card.Header>
                                                    <Card.Title>LISTE DES UTILISATEURS DU PROFIL</Card.Title>
                                                    <Card.Hr />
                                                </Card.Header>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Civilite</th>
                                                            <th>Nom</th>
                                                            <th>Prénoms</th>
                                                            <th>Date de naissance</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                        
                                                        (usersLier && usersLier.hasOwnProperty('data'))?usersLier.data.map(userlier=>(<tr>
                                                            <td>{userlier.personne?.civilite}</td>
                                                            <td>{userlier.personne?.nom}</td>
                                                            <td>{userlier.personne?.prenom}</td>
                                                            <td>{dayjs(userlier.personne?.datenaissance).format('DD/MM/YYYY')}</td>
                                                           
                                                            <th>
                                                            <IconButton onClick={()=>handleDettacheUser(userlier.id)}>
                                                                <RemoveCircleOutlineIcon />
                                                            </IconButton> 
                                                            </th>
                                                        </tr>)):<tr><td colSpan={5}>
                                                                <p>Aucun utilisateur n'est attaché à ce profil.<br />Vous pouvez rechercher les utilisateurs et leur attribuer ce profil. ou bien renseigner les profils directement à partir de l'utilisateur</p>
                                                            </td></tr>}
                                                    </tbody>
                                                </Table>
                                                {(usersLier && usersLier.hasOwnProperty('data'))&&
                                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                                    <Pagination
                                                        component="div"
                                                        count={usersLier.last_page}
                                                        defaultPage={1}
                                                        page={usersLier.current_page}
                                                        onChange={(event,numberPage)=>handleGetUserLier(profil.id,numberPage)}
                                                        rowsPerPage={usersLier.per_page}
                                                        variant='outlined'
                                                        showFirstButton={usersLier.current_page>10} 
                                                        showLastButton ={usersLier.total>=10}
                                                        />
                                                </div>
                                                }
                                                
                                            </Card>
                                        </Paper>
                                        <Paper style={{flex:1,backgroundColor:'#f1f1f1',marginLeft:20,borderRadius:10,padding:'20px 10px'}} variant='outlined'>
                                            <Typography>LIER UN UTILISATEUR A CE PROFIL</Typography>
                                        <div style={{marginTop:20}}>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        placeholder="Nom"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        name ="nom"
                                                        value ={inputSearch?.nom}
                                                        onChange={handelChangeInputSearch}
                                                    />
                                                </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Date de naissance"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    type='date'
                                                    name ="datenaissance"
                                                    value ={inputSearch?.datenaissance}
                                                    onChange={handelChangeInputSearch}
                                                />
                                            </InputGroup>
                                            <div style ={{display:'flex',justifyContent:'center'}}>
                                                <Button type='button' style={{backgroundColor:'#00B04B'}} onClick={()=>handleSearchUser(1)}>
                                                    {loading?<Spinner />:<SearchIcon sx={{fontSize:25,color:'#ffffff'}} />} 
                                                </Button>
                                                <Button type='button' style={{backgroundColor:'#00B04B',marginLeft:20}} onClick={()=>setInputSearch(null)}>
                                                    {loading?<Spinner />:<ClearIcon sx={{fontSize:25,color:'#ffffff'}} />} 
                                                </Button>
                                            </div>
                                            
                                        </div>
                                        {usersResult&&<ResultSearch 
                                                        resultData={usersResult} 
                                                        handleChangePage={handleSearchUser} 
                                                        dettacheProfil={handleDettacheUser}
                                                        attacheProfil={handleAttacheProfil}
                                                        profil={cprofil?.id}
                                                        usersLier={usersLier.data}
                                                        />}
                                        
                                    </Paper>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </form>
        </div>
    </div>
  )
}

export default ProfilsAdd
