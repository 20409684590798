import React,{useState,useEffect } from 'react'
import {AuthSessionStatus, AuthValidationErrors, Homer} from 'components'
import logo from "assets/logo.jpg"
import { useAuth } from 'hooks/auth'
import { Button } from 'react-bootstrap';
import { Paper } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const PasswordReset = () => {
    const [searchParams] = useSearchParams();
    const {user} = useAuth()
    const { resetPassword } = useAuth({ middleware: 'guest' })
  
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)
    const submitForm = event => {
        event.preventDefault()
        resetPassword({
          email,
          password,
          password_confirmation,
          setErrors,
          setStatus
        })
      }

      useEffect(() => {
        setEmail(searchParams.get('email') || '')
      }, [])
    
  return (
    <Homer>
    <Homer.Frame flex={0.3} style={{marginBottom:10}} >
         <Homer.Inner>
           <Homer.Link to="/">
               <div style={{position:'absolute',borderRadius:5,width:80}} >
                 <img src={logo} alt='Logo' style={{width:250,borderRadius:10,objectFit: "fill",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} />
               </div>
           </Homer.Link>
            
         </Homer.Inner>
     </Homer.Frame>
     <Homer.FGradient flex={2}>
             <Homer.Inner salign='center' className="w-min-[600px]">
                <Paper elevation={5} className='p-4 rounded-lg flex flex-col justify-center items-center flex-1'>
                     <h1 className='p-4 text-green-800'>Réinitialiser votre mot de passe</h1>
                    
                             {/* Session Status */}
                     <AuthSessionStatus className="mb-4" status={status} />
                     {/* Validation Errors */}
                     <AuthValidationErrors className="mb-4" errors={errors} />
                     <form onSubmit={submitForm}>
          {/* Email Address */}
          <div>
            <label htmlFor="email">Adresse email</label>
            <Homer.Input
              id="email"
              type="email"
              value={email}
              className="block mt-1 w-full"
              onChange={event => setEmail(event.target.value)}
              required
              autoFocus
              disabled
            />
          </div>
          {/* Password */}
          <div className="mt-4">
            <label htmlFor="password">Nouveau mot de passe</label>
            <Homer.Input
              id="password"
              type="password"
              value={password}
              className="block mt-1 w-full"
              onChange={event => setPassword(event.target.value)}
              required
            />
          </div>
          {/* Confirm Password */}
          <div className="mt-4">
            <label htmlFor="password_confirmation">
                Confrmé le nouveau mot de passe
            </label>
            <Homer.Input
              id="password_confirmation"
              type="password"
              value={password_confirmation}
              className="block mt-1 w-full"
              onChange={event =>
                setPasswordConfirmation(event.target.value)
              }
              required
            />
          </div>
          <div className="flex items-center justify-end mt-4">
            <Button type='submit'>Réinitialiser</Button>
          </div>
        </form>
                </Paper>
             </Homer.Inner>
     </Homer.FGradient>
     <Homer.Frame flex={0.3} style={{marginTop:10}}>
         <Homer.Inner  palign="center">
                 Copyright &#169; { new Date().getFullYear() } - Tous droits réservés Mairie de Port-Bouet
         </Homer.Inner>
     </Homer.Frame>
 </Homer>
  )
}

export default PasswordReset