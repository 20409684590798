import {ComiteGestionList,AddComitegestion,UpdateComitegestion} from 'pages/backend/administrateur/comitegestions';
import {AddQuartier,QuartierList,UpdateQuartier } from 'pages/backend/administrateur/quartiers';
import {CategorieOffreAdd,CategorieOffreList} from 'pages/backend/administrateur/categorieoffres';
import {DispositifAdd,DispositifList,DispositifUpdate,DetailsDispositif } from 'pages/backend/administrateur/dispositifs';
import {NatureList} from 'pages/backend/administrateur/natureevenement';
import {TypeDemandeList } from 'pages/backend/administrateur/typedemandes';
import {TypeStructureList } from 'pages/backend/administrateur/typestructures';
import { PortailHome } from 'pages/backend/administrateur/portails';
import { ProfilsAdd,ProfilsList } from 'pages/backend/administrateur/profils';
import { UtilisateurAdd,UtilisateurList,UtilisateurEdit } from 'pages/backend/administrateur/utilisateurs';

import DashAdministrateur from 'pages/backend/dashbord/DashAdministrateur';
import PartenaireHome from 'pages/backend/administrateur/partenaires/PartenaireHome';
import PartenaireListe from 'pages/backend/administrateur/partenaires/PartenaireListe';
import PartenaireFiche from 'pages/backend/administrateur/partenaires/PartenaireFiche';
import AddPartenaireComplet from 'pages/backend/administrateur/partenaires/AddPartenaireComplet';
import UpdatePartenaireComplet from 'pages/backend/administrateur/partenaires/UpdatePartenaireComplet';
import {OffreHome,OffreAccueil,AddOffres,OffresLsit,OffreFiche,UpdateOffres} from 'pages/backend/conseillers/offres';
import PropositionOffre from 'pages/backend/conseillers/offres/PropositionOffre';
import SearchResult from 'pages/backend/recherche/SearchResult';
import SearchFields from 'pages/backend/recherche/SearchFields';
import ModuleList from 'pages/backend/administrateur/modules/ModuleList';
import AddModule from 'pages/backend/administrateur/modules/AddModule';
import ParametreHome from 'pages/backend/parametres/ParametreHome';
import FicheModule from 'pages/backend/administrateur/modules/FicheModule';
import ImportModule from 'pages/backend/administrateur/modules/ImportModule';
import EditModule from 'pages/backend/administrateur/modules/EditModule';

  /*ParametreHome,Parametres,*/
const routes = [
    { path: '/', index:true, component: DashAdministrateur},

    { path: 'utilisateurs', name: 'Liste des utilisateurs', component: UtilisateurList },
    { path: 'utilisateur/nouveau', name: 'Ajouter un utilisateur', component: UtilisateurAdd },
    { path: 'utilisateur/modifier/:code', name: 'Modifier un utilisateur', component: UtilisateurEdit },
    { path: 'utilisateur/:code', name: 'Modifier un utilisateur', component: UtilisateurEdit },
    { path: 'portails',name: 'Liste des portails', component: PortailHome },
    { path: 'partenaires',name: 'gestion des partenaires', component: PartenaireHome, childs:[
        { path: '/', index:true, component: PartenaireListe},
        { path: 'nouveau',  component: AddPartenaireComplet},
        { path: 'fiche/:id',  component: PartenaireFiche},
        { path: 'modifier/:id',  component: UpdatePartenaireComplet},
    ] },
    { path: 'profils',name: 'Profile', component: ProfilsList },
    { path: 'profil/nouveau',name: 'Nouveau profile', component: ProfilsAdd },
    { path: 'profil/modifier/:id',name: 'Modifier un Profile', component: ProfilsAdd },

    { path: 'comites', name: 'Liste des comités de gestion', component: ComiteGestionList },
    { path: 'comite/nouveau', name: 'Ajouter un comité de gestion', component: AddComitegestion },
    { path: 'comite/modifier/:id', name: 'Modifier un comité de gestion', component: UpdateComitegestion },
    
    { path: 'quartiers', name: 'Liste des quartiers', component: QuartierList },
    { path: 'quartier/nouveau', name: 'Nouveau quartier', component: AddQuartier },
    { path: 'quartier/modifier/:code', name: 'Modifier quartier', component: UpdateQuartier },

    { path: 'types-demandes',  name: 'Liste de types de demande', component: TypeDemandeList },
    
    { path: 'types-structures',  name: 'Liste de types de demande', component: TypeStructureList },
   
    { path: 'natures-evenements',  name: "Liste de natures d'évémenent", component: NatureList },
   
    { path: 'categories-offres',  name: "Liste des catégorie d'offres", component: CategorieOffreList },
    { path: 'categorie-offre/nouveau', name: "Nouvelle catégorie d'offre", component: CategorieOffreAdd },
    { path: 'categorie-offre/modifier/:code', name: 'Modifier une catégorie', component: CategorieOffreAdd },

    { path: 'dispositifs',  name: "Liste des dispositifs", component: DispositifList },
    { path: 'dispositifs/nouveau', name: "Nouveau dispositif", component: DispositifAdd },
    { path: 'dispositifs/modifier/:code', name: 'Modifier un dispositif', component: DispositifUpdate },
    { path: 'dispositifs/fiche/:code', name: 'Fiche un dispositif', component: DetailsDispositif },
    { path: 'recherches/resultats', name: 'Résultat recherche', component: SearchResult },
    { path: 'recherches/criteres', name: 'critère de recherche', component: SearchFields },

    { path: 'offres', name: 'Liste des utilisateurs', component: OffreHome, childs:[
      { path: '/', index:true, component: OffreAccueil},
      { path: 'nouveau',  component: AddOffres},
      { path: 'fiche/:id',  component: OffreFiche},
      { path: 'modifier/:id',  component: UpdateOffres},
      { path: 'liste',  component: OffresLsit},
      { path: 'proposition/:offre',  component: PropositionOffre},
  ] },

  { path: "parametres", name: 'Paramètres',component: ParametreHome, childs:[
    { path: '/', index:true, component: ModuleList},
    { path: 'modules/add', component:AddModule},
    { path: 'modules/fiche/:id', component:FicheModule},
    { path: 'modules/edit/:id', component:EditModule},
    { path: 'modules/importation', component:ImportModule},
  ]}
];

export default routes