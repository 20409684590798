import React, { useState } from 'react'
import { Button, Paper, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControlLabel } from '@mui/material'
import "./styles/CategorieAdd.scss"
import axios from 'lib/axios';
import { useEffect } from 'react';
import swal from 'sweetalert';
import { Alert, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';

function CategorieAdd() {
  const location  =  useLocation()
  const navigate = useNavigate()
  const {mode,categorie} = location.state
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
  const [loading,setLoading] = useState(false)
  const [errors,setErrors] = useState(null)
  const handleSubmit =(e)=>{
    e.preventDefault()
    setLoading(true)
    if(!inputField.hasOwnProperty('libelle') || inputField.libelle.trim() ==="" ){
        swal("Erreur !","Veuillez renseigner un intitulé",'error')
        setLoading(false)
        return
    }
      if(mode==='ADD'){
        axios.post(`/api/categorie-offre/add`,inputField).then(res=>{
            if(res.status === 200 || res.status ===201){
                swal("Bien !","Catégorie créée avec succès",'success')
                setLoading(false)
                 handlelist()
            }else{
                swal("Erreur !","Erreur lors de la création du profils, veillez corriger et réessayer",'error')
                setLoading(false)
            }
        }).catch(err=>{
            console.log(err)
            if(err.hasOwnProperty("response")){
                setErrors(err.response.data?.errors)
            }
            setLoading(false)
        })
    }else{
       
        axios.put(`/api/categorie-offre/update/${categorie.id}`,inputField).then(res=>{
            if(res.status === 200 || res.status ===201){
                swal("Bien !","Catégorie a été modifié avec succès",'success')
                setLoading(false)
                
            }else{
                swal("Erreur !","Erreur lors de la modification de la catégorie, veillez corriger et réessayer",'error')
                setLoading(false)
            }
        }).catch(err=>{
            console.log(err)
            if(err.hasOwnProperty("response")){
                setErrors(err.response.data?.errors)
            }
            setLoading(false)
        })
    }
  }
  const handlelist = ()=>{
    navigate(`/${portail}/categories-offres`)
  }
  const [inputField,setInputField] = useState({portails:[]})
  const handleChange =(e)=>{
      const {name,value} = e.target
      setInputField({...inputField,[name]:value})
  }
  const handleEditorChange =(field,value)=>{
      setInputField({...inputField,[field]:value});
  }

  const loadData = ()=>{
    if(mode =='UP' && categorie){
      axios.get(`/api/categorie-offre/${categorie.id}`).then(res=>{
        if(res.status === 200 || res.status === 201){
          setInputField({...res.data})
        }
      })
    }
  }
  useEffect(()=>{
    loadData()
  },[mode])
  return (
    <div className="categorieadd">
        <div className="titlebox">
            <Typography variant='h5'>{mode==='ADD'?"Nouvelle catégorie d'offre":"Mise à jour d'catégorie #"+categorie?.id}</Typography>
            <Button className="btn" onClick={(e)=>handlelist()}><ArrowBackIosIcon sx={{color:'#00B04B'}} />Retour à la liste</Button>
        </div>
        <div className="bodybox">
            <form onSubmit={handleSubmit}>
              <Paper className="formbox" variant='outlined'>
                  <Typography>DESCRIPTION</Typography>
                  <Card>
                      <Card.Header>
                          <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                      <div style={{display:'flex',flexDirection:'column',justifyContent:"flex-start"}}>
                          <CustumForm style={{display:'flex',flexDirection:'column'}}>
                              <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                  <CustumForm.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20,marginBottom:20}}  
                                      label="Intitule"
                                      required 
                                      wd='100%'
                                      name='libelle' 
                                      value={inputField['libelle'] ||""}
                                      onChange={handleChange} 
                                      />
                                  {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}
                                  <ReactQuill  
                                  placeholder={"Description du profil"} 
                                  style={{width:'100%',marginBottom:20}}
                                  name='description' 
                                  theme="snow" 
                                  value={inputField['description']||""} 
                                  onChange={(v)=>handleEditorChange('description',v)} 
                              
                              />
                              {(errors && errors.hasOwnProperty('description'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['description'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}

                              
                              </CustumForm.Group>
                              <div>
                                  <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='autoriseinfogeneral'
                                          label="Oui"
                                          name ='autoriseinfogeneral'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.autoriseinfogeneral==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='autoriseinfogeneral'
                                          label="Non"
                                          name ='autoriseinfogeneral'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.autoriseinfogeneral==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé information générale ?"
                                      labelPlacement='start'
                                      />
                                </div>
                                <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='autorisefinanceur'
                                          label="Oui"
                                          name ='autorisefinanceur'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.autorisefinanceur==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='autorisefinanceur'
                                          label="Non"
                                          name ='autorisefinanceur'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.autorisefinanceur==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé information financeur ?"
                                      labelPlacement='start'
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='autorisepartenaire'
                                          label="Oui"
                                          name ='autorisepartenaire'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.autorisepartenaire==='O'}
                                          
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='autorisepartenaire'
                                          label="Non"
                                          name ='autorisepartenaire'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.autorisepartenaire==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé choix partenaire ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='autorisecontrat'
                                          label="Oui"
                                          name ='autorisecontrat'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.autorisecontrat==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='autorisecontrat'
                                          label="Non"
                                          name ='autorisecontrat'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.autorisecontrat==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé contrat ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='autorisecondition'
                                          label="Oui"
                                          name ='autorisecondition'
                                          onChange={handleChange}
                                          style={{marginLeft:20}}
                                          value='O'
                                          checked = {inputField?.autorisecondition==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='autorisecondition'
                                          label="Non"
                                          name ='autorisecondition'
                                          onChange={handleChange}
                                          style={{marginLeft:10}}
                                          value='N'
                                          checked = {inputField?.autorisecondition==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Est soumis à conditions ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='chpaction'
                                          label="Oui"
                                          name ='chpaction'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.chpaction==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='chpaction'
                                          label="Non"
                                          name ='chpaction'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.chpaction==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Est soumis à un champ d'action ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='chpmesurenational'
                                          label="Oui"
                                          name ='chpmesurenational'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.chpmesurenational==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='chpmesurenational'
                                          label="Non"
                                          name ='chpmesurenational'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.chpmesurenational==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Est soumis à une mésure nationale ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='chpreferent'
                                          label="Oui"
                                          name ='chpreferent'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.chpreferent==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='chpreferent'
                                          label="Non"
                                          name ='chpreferent'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.chpreferent==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Affiche référent ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                          </CustumForm>
                      </div>
                  </Card>
                      
                  </Paper>
                  <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                        </Button>
                        <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                            <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                        </Button>
                  </Card>     
            </form>
        </div>
    </div>
  )
}

export default CategorieAdd