import React from 'react';
import {
    useLocation,
    Navigate,
  } from "react-router-dom";
import { useSelector } from 'react-redux';


export function IsUserRedirect({ user, loggedInPath, children }) {
    let location = useLocation();
    const localuser = user?user:(localStorage.getItem("user") === null)?JSON.parse(localStorage.getItem('user')):null
    if(localuser?.name){
        return <Navigate to={loggedInPath} state={{ from: location }} replace />;
    }else{
        return children;
    }
}

export function ProtectedRoute({ user, children,Lalout:Layout, ...rest }) {
    let location = useLocation();
    const {pathname} = location
    const root = pathname.split('/')[1]
    const localuser = user?user:localStorage['user']&&JSON.parse(localStorage.getItem('user'))
    const portail =useSelector(state=>state.ui.portail)

    if(localuser?.name){
        if(root!== portail.toLowerCase()){
            return <Navigate to={`/${portail}`} state={{ from: location }} replace />;
        }else{
            return children
        }
    }else{
        return <Navigate to="/" state={{ from: location }} replace />;
    } 
}
