import axios from 'lib/axios';
import React, { useEffect, useState } from 'react'
import { Pie } from "react-chartjs-2";
import { chartColors } from "utils/colors";
const pieOptions = {
  legend: {
    display: false,
    position: "right"
  },
  elements: {
    arc: {
      borderWidth: 0
    }
  }
};


const data = {
  maintainAspectRatio: false,
  responsive: false,
  labels: ["En attente","En traitement","Satisfait","Rejeter"],
  datasets: [
    {
      data: [300, 50,20, 40],
      
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors
    }
  ]
};


function TauxTrtDemande() {
  let chartInstance = null;
  const [dataSet,setDataSet] = useState({
    maintainAspectRatio: false,
    responsive: false,
  labels: ["En attente","En traitement","Satisfait","Rejeter"],
  datasets: [
    {
      data: [300, 50,20, 40],
      
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors
    }
  ]
  })
  useEffect(()=>{
    return ()=>{
      axios.get(`api/state/taux-demande`).then(res=>{
        if(res.status ===200 || res.status === 201){
          const mydataset ={
            data: [],
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors
          }
          mydataset['data'] = res.data.map(dt=>dt['total'])
          setDataSet({...dataSet,['labels']:res.data.map(dt=>dt['statut']),datasets:[mydataset]})
        }
      })
    }
  },[])
    
  return (
    <div>
        {dataSet&&<Pie
          data={dataSet}
          options={pieOptions}
          ref={input => {
            chartInstance = input;
          }}
        />}
    </div>
       
  )
}
export default TauxTrtDemande