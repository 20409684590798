import React from 'react'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux'
import ConnexionBox from '../components/ConnexionBox'

function Contact() {
    const {user}  =useAuth({ middleware: 'auth' })
    const portail = useSelector(state=>state.ui.portail)
  return (
    <>
   
      <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">Nous contacter</h1>
                        <ConnexionBox/>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-0">
                <div className="col-lg-7">
                    <div className="bg-primary h-100 p-5">
                        <form>
                            <div className="row g-3">
                                <div className="col-6">
                                    <input type="text" className="form-control bg-light border-0 px-4" placeholder="Votre nom" style={{height: 55}} />
                                </div>
                                <div className="col-6">
                                    <input type="email" className="form-control bg-light border-0 px-4" placeholder="Votre adresse email" style={{height: 55}} />
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control bg-light border-0 px-4" placeholder="Sujet" style={{height: 55}} />
                                </div>
                                <div className="col-12">
                                    <textarea className="form-control bg-light border-0 px-4 py-3" rows="2" placeholder="Message"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-secondary w-100 py-3" type="submit">Envoyer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="bg-secondary h-100 p-5">
                        <h2 className="text-white mb-4">Nous joindre</h2>
                        <div className="d-flex mb-4">
                            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{width: 60, height: 60}}>
                                <i className="bi bi-geo-alt fs-4 text-white"></i>
                            </div>
                            <div className="ps-3">
                                <h5 className="text-white">Bureau</h5>
                                <span className="text-white">Côte d'ivoire - Abidjan - Commune de Port-Bouet</span>
                            </div>
                        </div>
                        <div className="d-flex mb-4">
                            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{width: 60, height: 60}}>
                                <i className="bi bi-envelope-open fs-4 text-white"></i>
                            </div>
                            <div className="ps-3">
                                <h5 className="text-white">Email</h5>
                                <span className="text-white">contact@pfs-portbouet.com</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center" style={{width: 60, height: 60}}>
                                <i className="bi bi-phone-vibrate fs-4 text-white"></i>
                            </div>
                            <div className="ps-3">
                                <h5 className="text-white">Téléphone</h5>
                                <span className="text-white">+225 07 59 99 34 64</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-0">
                <div className="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127123.03325037842!2d-4.038588339292558!3d5.325722403372809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1eee12f786db1%3A0xc42ae3209da5693a!2sCentre%20Pilote%20De%20Port%20Bou%C3%ABt!5e0!3m2!1sfr!2sci!4v1681690454297!5m2!1sfr!2sci" width="100%" height="450" style={{border:0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        </div>
    
    </>
  )
}

export default Contact
