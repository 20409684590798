import React, { useState } from 'react'
import {Pagination} from "@mui/material";
import { Button, Paper} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import "./styles/StepUserSearch.scss"
import axios from 'lib/axios';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
const groupItems=[
  {
    name: "Mme",
    label: "Mme",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "Mlle",
    label: "Mlle",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "M",
    label: "M",
    information: "",
    disabled: false
  }
]
function StepUserSearch(props) {
  const {handleNext,setInputField,inputField} =props
  const [inputSearch,setInputSearch]= useState({})
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
  const navigate = useNavigate()
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputSearch({...inputSearch,[name]:value})
  }
  const [resultSearch,setResultSearch] = useState(null)
  const [paginateData,setPagnateData] = useState(null)
  const handleShowCreerCompte =()=>{
    setInputField({...inputField,...inputSearch})
    handleNext()
  }
  const handleSearchDoublon = (numberPage) =>{
      const url = `/api/users/basic-search?page=${numberPage}`
      const dataSend ={...inputSearch,'combine':true}
     
      axios.post(url,dataSend).then(res=>{
        if(res.status === 200 || res.status ===201){
          setResultSearch(res.data.data)
          setPagnateData({
              current_page:res.data.current_page,
              last_page:res.data.last_page,
              per_page:res.data.per_page,
              total:res.data.total}  )
      }
      })
  }
  const handleAnnule =()=>{
    navigate(`/${portail}/utilisateurs`)
  }
 //date field echange
 const handleChangeDate = (name,newValue) => {
  setInputField({...inputSearch,[name]:newValue})
};

 
  return (
    <div className="stepusersearch">
       
        <Paper>
          <Card>
            <Card.Header>
              <Card.Title>RECHERCHE DE DOUBLON</Card.Title>
              <Card.Hr />
            </Card.Header>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className='formContainer'>
                <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                  <RadioField
                    groupItems={groupItems}
                    group="Civilité"
                    checkedDefault="Madame"
                    appearance={radiobutton_appearances.primary}
                    name='civilite'
                    onChange={handleChange}
                    vvalue ={inputSearch.civilite}
                  />
                  <CustumForm.Input 
                    size="small" 
                    margin="dense" 
                    style={{marginRight:20,marginBottom:20}}  
                    label="Nom"
                    required 
                    wd='100%'
                    name='nom' 
                    onChange={handleChange}
                  />
                  <CustumForm.Input 
                    size="small" 
                    margin="dense" 
                    style={{marginRight:20,marginBottom:20}}  
                    label="Prenom"
                    required 
                    wd='100%'
                    name='prenom' 
                    onChange={handleChange}
                  />
                  <DesktopDatePicker
                    label="Date de naissance"
                    inputFormat="dd/MM/yyyy"
                    name='datenaissance'
                    value={inputSearch["datenaissance"] || ""}
                    onChange={(v)=>handleChangeDate('datenaissance',v)}
                    renderInput={(params) => <CustumForm.Input size="small"  wd='100%' margin="dense" style={{marginRight:20,marginBottom:20}}  {...params} />}
                  />
                </CustumForm.Group>
                <Button className='btngreen' onClick={()=>handleSearchDoublon(1)}>Rechercher <SearchIcon /></Button>
              </div>
            </LocalizationProvider>
          </Card>
        </Paper>
        {(resultSearch)&&<Paper>
          <Card>
              <Card.Header>
                  <Card.Title>LISTE DES COMPTES UTILISATEURS EXISTANTS</Card.Title>
              <Card.Hr />
              </Card.Header>
             { paginateData?.total&&<span>Total(s) : {paginateData?.total}</span>}
              <Table striped bordered hover>
                    <thead>
                        <tr>
                          <th>#</th>
                          <th>Civilite</th>
                          <th>Nom</th>
                          <th>Prénom</th>
                          <th>Date de naissance</th>
                          <th>Etat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(resultSearch && resultSearch.length !==0)?(
                            resultSearch.map(utilisateur=><tr key={utilisateur.id}>
                            <td>{utilisateur.id}</td>
                            <td>{utilisateur.personne.civilite}</td>
                            <td>{utilisateur.personne.nom}</td>
                            <td>{utilisateur.personne.prenom}</td>
                            <td>{dayjs(utilisateur.personne.datenaissance).format('DD/MM/YYYY')}</td>
                            <td>{utilisateur.personne.statut}</td>
                        </tr>)
                        ):(
                            <tr><td colSpan={8}>
                                <p>Aucun utilisateur trouvé.<br /> Cliquez sur le bouton ci-dessous "Créer un nouveau compte" pour demarrer la création du compte.</p>
                            </td></tr>
                        )}
                        
                        
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.total}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleSearchDoublon(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>} 
          </Card>
        </Paper>}
        {(resultSearch && resultSearch.length ===0)&& <div className='btncontainer'>
          <Button variant="outline-success" onClick={handleShowCreerCompte} className='btndemarrer'>Créer un nouveau compte maintenant</Button>
          <Button  variant="outline-reset" onClick={handleAnnule} className='btnannuler'>Annuler</Button>

          
        </div>}
       
        
    </div>
  )
}

export default StepUserSearch
