import React, { useEffect, useState } from 'react'
import {Pagination} from "@mui/material";
import { Button, Paper, Typography,IconButton, } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Table from 'react-bootstrap/Table';
import Tooltip from '@mui/material/Tooltip';
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Form  } from 'components'
import "./styles/UtilisateurList.scss"
import dayjs from 'dayjs';
import axios from 'lib/axios';
import { useSelector } from 'react-redux';

const groupItems=[
    {
      name: "Tous",
      label: "Tous",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "Enfonction",
      label: "Uniquement en fonction",
      information: "Veuillez selectionner un type",
      disabled: false
    },
  ]
function UtilisateurList() {
    const navigate =useNavigate()
    const handlenewprofil = ()=>{
        navigate('/administrateur/utilisateur/nouveau')
    }
    const [searchInput,setSearchInput]= useState(null)
    const [utilisateurs,setUtilisateurs] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const handleUtilisateurs = (numberPage=1)=>{
        const url =`/api/users/basic-search?page=${numberPage}`
        const dataSend = searchInput&&{search:searchInput}
        axios.post(url,dataSend).then(res=>{
            if(res.status === 200 || res.status ===201){
                setUtilisateurs(res.data.data)
               
                setPagnateData({
                    current_page:res.data.current_page,
                    last_page:res.data.last_page,
                    per_page:res.data.per_page,
                    total:res.data.total}  )
            }
        })
    }

    useEffect(()=>{
        return handleUtilisateurs(1)
    },[])
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
  
  return (
    <div className="utlisateurlist">
        <div className='titlebox'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Comptes utilisateurs</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={()=>handlenewprofil()}>
                <AddIcon/>Ajouter un compte utilisateur
            </Button>
        </div>
        <div className="bodybox">
            <Typography  variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
              
                    <div style={{display:'flex', marginLeft:20,alignItems:'center'}}>
                        <Form.Input 
                            size="small" 
                            margin="dense" 
                            style={{marginRight:20,marginBottom:20}}  
                            label="Rechercher (Nom,Prenom,statut)"
                            required 
                            wd='60%'
                            name='recherhe' 
                            onChange = {(e)=>setSearchInput(e.target.value)}
                        />
                        
                        <div className="btncontainer">
                        <Button className="btngreen" variant="succes" onClick={()=>handleUtilisateurs(1)}>
                                Rechercher  <SearchIcon />
                        </Button>
                        <Button className="btnorange" variant="outline-reset" onClick={()=>setSearchInput(null)}>
                                <BackspaceIcon /> Annuler
                        </Button>
                    </div>
                    </div>
                    
               
            </Paper>
            <Paper className="listBox" variant='outlined'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Civilite</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Date de naissance</th>
                        <th>Etat</th>
                        <th>Dernière connexion</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(utilisateurs && utilisateurs.length !==0)?(
                            
                            utilisateurs.map(utilisateur=><tr key={utilisateur.id}>
                            <td>{utilisateur.id}</td>
                            <td>{utilisateur.personne.civilite}</td>
                            <td>{utilisateur.personne.nom}</td>
                            <td>{utilisateur.personne.prenom}</td>
                            <td>{dayjs(utilisateur.personne.datenaissance).format('DD/MM/YYYY')}</td>
                            <td>{utilisateur.personne.statut}</td>
                            <td>{dayjs(utilisateur.update_at).format('DD/MM/YYYY HH:MM')}</td>
                            <td>
                                <div className="tbaction">
                                    <Tooltip title="Afficher">
                                        <IconButton >
                                            <PageviewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editer">
                                        <IconButton onClick={()=>navigate(`/${portail}/utilisateur/modifier/${utilisateur.id}`)}>
                                            <EditIcon />
                                        </IconButton>
                            </Tooltip>
                                    <Tooltip title="Supprimer">
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </td>
                        </tr>)
                        ):(
                            <tr><td colSpan={8}>
                                <p>Aucun utilisateur trouvé.<br /> Cliquez sur le bouton "Ajouter un compte" pour créer un nouvel utilisateur. Ou Utilisez la zone de recherche pour trouver un utilisateur</p>
                            </td></tr>
                        )}
                        
                        
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleUtilisateurs(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
                
            </Paper>
        </div>
    </div>
  )
}

export default UtilisateurList
