import React from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import {Paper} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';

const Container = styled(Paper)`
  display:'flex';
  flex:1;
  flex-direction:column;
  border-left:5px solid #00B04B;
  padding:10px;
  cursor:pointer;
  margin-top:10px;
  margin-right:20px;
  margin-bottom:20px;
  &:hover{
    background-color:#f9f8e3;
    border:none;
  }

`
const Frame = styled.div`
  display:flex;
  flex-direction:row
`
const CType = styled.div`
  display:flex;
  background-color:${({type})=>type==='cdi'?"#00B04B":(type==='cdd'?"#9E9E9E":"#707070")};
  padding:5px;
  border-radius:5px;
  color:#ffffff;
  font-weight:bold;
  font-size:14px;
  margin-left:20px;
  margin-right:20px;
`
const Addbutton = styled.button`
  display:flex;
  border-radius:5px;
  justify-content:center;
  align-items:center;
  font-size:20px;
  color:white;
  border:0px;
  padding:5px;
  box-sizing: content-box;
  background:${({primary})=>primary?'#00B04B':'#FF0471'};
` 
const Title =  styled.h2`
  font-size:14px;
  text-transform: uppercase;
  color:#00B04B;
  font-weight:bold;
  margin:10px 0px
`
const Texte = styled(Typography)`
  font-size:8px;
  color:#777777;
`
const Header = styled.div`
  display:flex;
  flex-direction:row;
  padding:5;
  justify-content:space-between;
  align-items:center;
`
const Offrev = ({offre,type,readmore,...restProps}) => {

  return (
    <Paper elevation={3} style={{padding:10}}>
    <Frame>
        <Header>
            <Typography style={{fontStyle:'italic'}} variant="subtitle1">{`N°Ref : ${offre['ref'] || ''}`}  </Typography>
        </Header>
    </Frame>

    <Title>{offre?offre['titre'] :""}</Title>
    <div style={{display:'flex',}}>
        <Texte variant='caption'>Publié le: {offre?dayjs(offre['datedebut']).format("DD/MM/YYYY"):"12/05/2025"} </Texte >
        <Texte variant='caption'>Cloture : {offre?dayjs(offre['datefin']).format("DD/MM/YYYY"):"12/05/2025"}</Texte>
    </div>
    <Texte variant='body2'>{`Lieu : ${offre&&offre['ville']} - Côte d'ivoire `}</Texte>
    <CType >{offre['typecontrat']||""}</CType>
</Paper>
  )
}

export default Offrev
