import React, { useEffect, useState ,useContext} from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SchoolIcon from '@mui/icons-material/School';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Card, Form } from 'components'
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell,{tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox, Divider, FormControlLabel, IconButton, MenuItem, Tooltip} from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import  DeleteIcon from '@mui/icons-material/Delete';
import  CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled as uiStyled } from '@mui/material/styles';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'lib/axios';
import { CommonsContext } from 'context/CommonsContext'
import CustumModal from 'components/CustumModal';
import StepsIdentification from './steps/StepsIdentification';
import StepsCoordonnes from './steps/StepsCoordonnes';
import WorkIcon from '@mui/icons-material/Work';
import StepsStuation from './steps/StepsStuation';
import StepsConnaissance from './steps/StepsConnaissance';
import StepsCursus from './steps/StepsCursus';
import StepsDocuments from './steps/StepsDocuments';
const styles ={
  detContainer:{
    display:'flex',
    flexDirection:'row',
    flex:1,
    justifyContent:'center'
  },

  detItemLeft:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    alignItems:'flex-end',
    fontWeight:'bold',
    justifyContent:'flex-start',
   
  },
  detItemRight:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flex:1,
    marginLeft:20,
  },
  label:{
    fontStyle:'italic',
    fontWeight:'bold'
  },
  toolsbard:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  logoContainer:{
    display: 'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    flex:1,
  },
}
const StyledTableCell = uiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = uiStyled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Button = styled.button`
  display:flex;
  padding: 10px 20px;
  border-radius:5px;
  flex-wrap:no-wrap;
  font-size:18px;
  font-weight:bold;
  letter-spacing:0.1rem;
  color:white;
  margin:1rem;
  background-color:${({bg})=>bg||'#00B04B'};
  border:none;
  cursor:pointer;
  &:hover{
    background-color:${({hbg})=>hbg||'#F29400'};
  }
`

const useStyles = makeStyles()((theme)=>({
  detaildossier:{
    display:'flex',
    flex:1,
    justifyContent:'space-between'
  },
  labelle:{
    fontStyle:'italic',
    fontWeight:'bold'
  },
  navLabel:{
    textTransform:'uppercase', 
    marginLeft:10,
    fontSize:14
  },
  titleBox:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  iconsbtn:{
    color:'#00B04B',
    '&:hover':{
      color:'#F29400'
    }
  }
}))

function AddEvent(props){
  const [natures,setNatures] = useState(null)
  const handleGetNatures = ()=>{
    axios.get(`/api/nature/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setNatures(res.data)
      }
    })
  }
  const [eventField,setEventField] = useState({
    dateevenement:"",
    notificationok:true
  })
  const handleChange = (e) =>{
    const {value,name} = e.target
    setEventField({...eventField,[name]:value})
  }
  useEffect(()=>{
    handleGetNatures()
  },[])
  const handleEditorChange =(field,value)=>{
    setEventField({...eventField,[field]:value});
  }
  const eventSubmit = ()=>{

  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <div>
      <Typography variant='h5'>Nouvel événement</Typography>
      <form onSubmit={eventSubmit}>
          <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
            <DesktopDatePicker
                label="Date début"
                inputFormat="dd/MM/yyyy"
                value={eventField['dateevenement'] || ""}
                name='dateevenement'
                onChange={(newvalue)=>setEventField({...eventField,'dateevenement':newvalue})}
                renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
            />
            <DesktopDatePicker
                label="Date fin"
                inputFormat="dd/MM/yyyy"
                value={eventField['datefin'] || ""}
                name='datefin'
                onChange={(newvalue)=>setEventField({...eventField,'datefin':newvalue})}
                renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
            />
        </Form.Group>
        {(natures && natures.length !==0)&& <Form.Group direction ='row' flex={1}>
          <Form.Input required size="small" name="nature" margin="dense" onChange={handleChange} value={eventField['nature']||""} select style={{marginRight:20}}  label="Nature">
            <MenuItem  value='No'>Aucun</MenuItem>
            {natures.map(nature=> <MenuItem value={nature.id}>{nature.libelle}</MenuItem>)}
          </Form.Input>
          <FormControlLabel control={ 
            <Checkbox name='notificationok' checked={eventField.notificationok} onChange={(event)=>setEventField({...eventField,'notificationok':!eventField.notificationok})}  color="success" />} label="Activer la notification" />
        </Form.Group>}
        <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
          <Form.Input 
            size="small" 
            margin="dense" 
            style={{marginRight:20}}  
            label="Intervenant"
            required 
            name='intervenant' 
            value={eventField['intervenant'] ||""}
            onChange={handleChange} 
          />
          <Form.Input 
            size="small" 
            name='lieu' 
            required
            margin="dense" 
            label="Lieu"
            value={eventField['lieu'] ||""}
            onChange={handleChange} 
          />
        </Form.Group>
        <Form.Group  margin='10px 0 20px 0'direction ='row' flex={1} >
          <ReactQuill  
              placeholder={"Context"} 
              style={{width:'100%'}}
              name='context' 
              theme="snow" value={eventField['context'] || ""} 
              onChange={(v)=>handleEditorChange('context',v)} 
          
          />
        </Form.Group>
        <Form.Group  margin='20px 0 20px 0'direction ='row' flex={1} >
          <ReactQuill  
              placeholder={"Commentaire"} 
              style={{width:'100%'}}
              name='commentaire' 
              theme="snow" value={eventField["commentaire"] || ""} 
              onChange={(v)=>handleEditorChange('commentaire',v)} 
          />
        </Form.Group>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:30}}>
            <Form.Button type='reset' >Annuler</Form.Button> 
            <Form.Button type="submit" primary>Enregistrer</Form.Button> 
        </div>
        
      </form>
    </div>
    </LocalizationProvider>
  )
}
function ListEvent(props){
  return (
    <div></div>
  )
}

function DossierFiche() {
  const {handleCommonsdata} = useContext(CommonsContext)
  useEffect(()=>{
    handleCommonsdata('titre',"Dossier - Saisir une sortie")
  },[])
 ;
  const location =useLocation()
  const {data} = location.state
  const [dossier,setDossier] = useState({...data})
  const {classes} = useStyles()
  const [photo,setPhoto] =useState(null)
  const navigate = useNavigate()

  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [activerPanel,setActivePanel]  = useState('identite')
  useEffect(()=>{
    if(dossier && dossier.jeune?.hasOwnProperty('photo') && dossier.jeune?.photo !==null && dossier.jeune?.document!=='null'){
      setPhoto(dossier.jeune.photo)
    }else{
      if(dossier.jeune.hasOwnProperty('documents')){
        const tof = dossier.jeune.documents.filter(item=>item.titre === 'Photo')
        if(tof && tof.length !== 0){
          setPhoto(tof[0].url)
        }
      }
    }
  },[])

  const handleShowDocument =(doc)=>{
    let a = document.createElement('a')
    a.href =`${process.env.REACT_APP_BACKEND_URL}/${doc}`
    a.target ='_blank'
    a.click()
  }
  const handleDeletedemande = (dmd)=>{
    swal({
        title: "Voulez-vous vraiment supprimer cette demande?",
        text: "Cette opération est irreversible",
        type: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Supprimer',
        denyButtonText: `Annuler`,
        buttons: true,
        dangerMode: true
      }).then(result=>{
        console.log(result);
        if(result){
            axios.delete(`/api/demande/${dmd.id}`).then(res=>{
                if(res.status === 200 || res.status === 204 || res.status === 201){
                    swal("Supprimer!", "La demande à été supprimé avec succès", "success");
                }else{
                    swal("Ooops!", "Erreur au momment de la suppresion", "error");
                }
            }).catch(err=>{
                swal("Ooops!", "Erreur au momment de la suppresion", "error");
            })     
        }else{
            swal("Ooops!", "Opération de suppression echouée", "error");
        }
      });
  }
  
  const handleProposition = (type)=>{
    navigate(`/${portail}/dossiers/proposition/${type}/${dossier.code.replace('/','')}`,{replace:true,state:{dossier}})
  }
  const handleEvenement = (type)=>{
    navigate(`/${portail}/dossiers/evenements/nouveau`,{replace:true,state:{dossier}})
  }
  const dateJour =  dayjs()
  const [openid,setOpenId] = useState(false)
  const handleIdOpen =()=>{
    setOpenId(true)
  }
  const handleIdClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
        return;
    setOpenId(false)
  }
  const refleshDossier =()=>{
    axios.get(`/api/dossier/${dossier.id}`).then(res=>{
      console.log(res);
      if(res.status ===200 || res.status === 201 ){
        console.log(res.data);
        setDossier(res.data)
      }
    }).catch(err=>{
      console.error(err);
    })
  }
  const [opencoord,setOpenCoord] = useState(false)
  const handleCoordOpen =()=>{
    setOpenCoord(true)
  }
  const handleCoordClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
    return;
    setOpenCoord(false)
  }

  const [openatout,setOpenatout] = useState(false)
  const handleatoutOpen =()=>{
    setOpenatout(true)
  }
  const handleatoutClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
    return;
    setOpenatout(false)
  }

  const [openparc,setOpenparc] = useState(false)
  const handleparcOpen =()=>{
    setOpenparc(true)
  }
  const handleparcClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
    return;
    setOpenparc(false)
  }

  //documents
  const [opendoc,setOpendoc] = useState(false)
  const handledocOpen =()=>{
    setOpendoc(true)
  }
  const handledocClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
    return;
    setOpendoc(false)
  }

  //situation
  const [opensit,setOpensit] = useState(false)
  const handlesitOpen =()=>{
    setOpensit(true)
  }
  const handlesitClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
    return;
    setOpensit(false)
  }

  //main scope
  return (
    <div style={{marginLeft:20, marginRight:20}}>
      <Card>
          <div style={{display:'flex',flexDirection:'column'}} >
              <div style={styles.logoContainer}>
                <div>{photo&&<img width="100" src={`${photo}`}/>}</div> 
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:20}}>
                    <Typography variant='h3'>{`${dossier.jeune?.nom} ${dossier.jeune?.prenom}`}</Typography> 
                    <div className='flex flex-row gap-3'>
                        <div className={classes.left}>
                            <div><span className={classes.labelle}>Age : </span>{dateJour.diff(dossier.jeune?.datenaissance,'year') } ans</div>
                            <div><span className={classes.labelle}>N° Dossier : </span> <Typography variant='subtitle'>{dossier['code']||' '}</Typography></div>
                            <div><span className={classes.labelle}>Date : </span> <Typography variant='subtitle'>{dossier['datedossier'] ? dayjs(dossier['datedossier']).format("DD/MM/YYYY") : ""}</Typography></div>
                            <div><sapn className={classes.labelle}>Créer le :</sapn><span>{dayjs(dossier.created_at).format('DD/MM/YYYY')}</span></div>
                        </div>  
                        <div className={classes.midlle}>
                            <div><span className={classes.labelle}>Commune : </span>{(dossier.jeune?.commune!=='null')&&dossier.jeune?.commune}</div>
                            <div><span className={classes.labelle}>Quartier : </span><span>{dossier.jeune?.quartier}</span> </div>
                            <div><sapn className={classes.labelle}>CGQ/CGV :</sapn><span>{dossier.jeune?.cgqcgv}</span></div>
                        </div>  
                        <div className={classes.right}>
                            <div><span className={classes.labelle}>Statut : </span>{dossier.statut}</div>
                            <div><span className={classes.labelle}>Etape traitement : </span>{dossier.etape}</div>
                        </div>  
                    </div>
                    
                  </div>
              </div>
            </div>
            <Divider />
            <div style={styles.toolsbard}>
              <Button onClick={()=>handleProposition('offres')}>Proposer une offres</Button>
              <Button onClick={()=>handleProposition('dispositifs')}>Proposer un accompagnement</Button>
              <Button onClick={handleEvenement}>Programmer un evenement</Button>
              {/*<Button onClick={()=>handleProposition('envoyer')}>Envoyer le dossier</Button>*/}
          </div>
          </Card>
     

      <Tab.Container id="left-tabs-example" defaultActiveKey={activerPanel}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" activeKey={activerPanel} className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="demandes"> <FolderZipIcon /> <span className={classes.navLabel}>DEMANDES </span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="offres"><DriveFolderUploadIcon /><span className={classes.navLabel}>OFFRES</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="dispositifs"><DesignServicesIcon /><span className={classes.navLabel}>DISPOSITIFS</span></Nav.Link>
              </Nav.Item>
              <Divider />
              <Nav.Item>
                <Nav.Link eventKey="evenements"><CalendarMonthIcon /><span className={classes.navLabel}>EVENEMENTS</span></Nav.Link>
              </Nav.Item>
                <Divider />
              <Nav.Item>
                <Nav.Link eventKey="identite"><AccountBoxIcon /><span className={classes.navLabel}>IDENTITE</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="situation"><PeopleAltIcon /><span className={classes.navLabel}>SITUATION FAMILIALE</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="coordonnees"><ImportContactsIcon /><span className={classes.navLabel}>COORDONNEES</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="situationactuelle"><WorkIcon /><span className={classes.navLabel}>SITUATION ACTUELLE</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="parcours"><SchoolIcon /><span className={classes.navLabel}>CURSUS / EXPERIENCES</span></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="atouts"><LightbulbIcon /><span className={classes.navLabel}>ATOUTS </span><small>(Connaissances/Loisirs)</small></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="documents"><AttachFileIcon /><span className={classes.navLabel}>DOCUMENTS</span></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="demandes">
                <Card>
                  <Card.Header>
                      <div className={classes.titleBox}>
                        <Card.Title>Liste des demandes</Card.Title>
                        <IconButton>
                          <AddCircleOutlineIcon className={classes.iconsbtn} />
                        </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                  <List>
            {(dossier && dossier.hasOwnProperty('demandes') && dossier.demandes.length!==0) ? dossier.demandes.map(demande=><div><ListItem key={demande.id} 
                secondaryAction={<div>
                    <Tooltip title="Modifier">
                        <IconButton edge="end" aria-label="delete">
                            <ModeEditOutlineIcon sx={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                        <IconButton edge="end" aria-label="delete" onClick={()=>handleDeletedemande(demande)}>
                            <DeleteIcon sx={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                </div>
              }
            >
            <ListItemIcon>
                <Chip label={demande['statut']?(demande['statut'].charAt(0).toUpperCase() + demande['statut'].slice(1)) : ""} color={demande['statut']==='En attente'?"primary":(demande['statut']==='En traitement'?"warning":(demande['statut']==='satisfait'?"success":"error"))} />
            </ListItemIcon>
                        <ListItemText
                          primary={<div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                              <Typography sx={{marginRight:10}}>{dossier.type?.libelle?dossier.type?.libelle : ""}</Typography>
                             
                          </div>}
                          secondary={
                            <div style={{marginLeft:20}}>
                                    <span style={{fontWeight:'bold'}}>{ formatDistance(new Date(demande.created_at), new Date(), { addSuffix: true,locale:fr })}</span>
                                    <p>{demande['description']&&<ReactQuill readOnly={true} theme={"bubble"} value={demande['description'] || ""} />}</p>
                          </div> 
                        }
                        />
                      </ListItem><Divider variant="inset" component="li" /></div>): <ListItem>
                        <ListItemText primary="Aucune damnde trouvée" />
                      </ListItem>
                    }
                    
                </List>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="offres">
              <Card>
                  <Card.Header>
                      <div className={classes.titleBox}>
                        <Card.Title>Liste des offres candidatées</Card.Title>
                        <IconButton>
                          <AddCircleOutlineIcon className={classes.iconsbtn} />
                        </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="dispositifs">
              <Card>
                  <Card.Header>
                      <div className={classes.titleBox}>
                        <Card.Title>Liste des dispostifs appliquées</Card.Title>
                        <IconButton>
                          <AddCircleOutlineIcon className={classes.iconsbtn} />
                        </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="evenements">
              <Card>
                  <Card.Header>
                      <div className={classes.titleBox}>
                        <Card.Title>Evénements / actions</Card.Title>
                        <IconButton>
                          <AddCircleOutlineIcon className={classes.iconsbtn} />
                        </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                  <AddEvent />
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="identite">
                <Card>
                  <Card.Header>
                      <div className={classes.titleBox}>
                        <Card.Title>INFORMATIONS D'IDENTIFICATION</Card.Title>
                        <IconButton onClick={handleIdOpen}>
                          <ModeEditSharpIcon className={classes.iconsbtn} />
                        </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                  <div style={styles.detContainer}>
                    <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Civilité : </Typography> 
                      <Typography style={styles.label}>Nom : </Typography>
                      <Typography style={styles.label}>Prénoms : </Typography> 
                      <Typography style={styles.label}>Date de naissance : </Typography>
                      <Typography style={styles.label}>Lieu de naissance : </Typography>
                      <Typography style={styles.label}>Nationalité : </Typography>
                      <Typography style={styles.label}>Nom du pére : </Typography>
                      <Typography style={styles.label}>Nom de la mère : </Typography>
                      <Typography style={styles.label}>{dossier.jeune?.naturepiece || 'CNI'} : </Typography>
                      
                    </div>

                    <div style={styles.detItemRight}>
                      <Typography>{dossier.jeune?.civilite ||' '}</Typography>
                      <Typography>{dossier.jeune?.nom ||' '}</Typography>
                      <Typography>{dossier.jeune?.prenom ||' - '} </Typography> 
                      <Typography>{dossier.jeune?.datenaissance?dayjs(dossier.jeune['datenaissance']).format('DD/MM/YYYY'):" - "} </Typography> 
                      <Typography>{dossier.jeune?.lieunaissance ||' - '} </Typography>
                      <Typography>{dossier.jeune?.nationalite ||' - '} </Typography>
                      <Typography>{dossier.jeune?.nompere ||' - '}</Typography>
                      <Typography>{dossier.jeune?.nommere ||' - '}</Typography>
                      <Typography>{dossier.jeune?.numeropiece ||' - '} : </Typography>
                    </div>
                  </div>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="situation">
                <Card>
                  <Card.Header>
                    
                      <div className={classes.titleBox}>
                          <Card.Title>Situation familiale</Card.Title>
                          <IconButton onClick={handleIdOpen}>
                            <ModeEditSharpIcon className={classes.iconsbtn} />
                          </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                  <div style={styles.detContainer}>
                    <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Situation matrimoniale : </Typography>
                      <Typography style={styles.label}>Nombre d'enfant : </Typography>
                      <Typography style={styles.label}>Nom du conjoint/de la coinjointe : </Typography>
                      <Typography style={styles.label}>Profession du conjoint/ de la coinjointe : </Typography>
                    </div>
                    <div style={styles.detItemRight}>
                      <Typography>{dossier.jeune?.sitmatrimoniale ||' - '} </Typography>
                      <Typography>{dossier.jeune?.nbenfant ||' -'}</Typography>
                      <Typography>{dossier.jeune?.nomconjoint ||' - '} </Typography> 
                      <Typography>{dossier.jeune?.professionconjoint ||' - '} </Typography>
                    </div>
                  </div>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="coordonnees">
                <Card >
                  <Card.Header>
                      <div className={classes.titleBox}>
                          <Card.Title>Coordonnées</Card.Title>
                          <IconButton onClick={handleCoordOpen}>
                            <ModeEditSharpIcon className={classes.iconsbtn} />
                          </IconButton>
                      </div>
                      <Card.Hr />
                  </Card.Header>
                  <div style={styles.detContainer}>
                    <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Ville de résidence : </Typography> 
                      <Typography style={styles.label}>Commune de résidence : </Typography>
                      <Typography style={styles.label}>Quartier : </Typography>
                      <Typography style={styles.label}>CGQ/CGV : </Typography>
                    </div>

                    <div style={styles.detItemRight}>
                      <Typography>{(dossier.jeune?.lieuresidence !== 'null' && dossier.jeune?.lieuresidence) ? dossier.jeune?.lieuresidence : ' - '} </Typography>
                      <Typography>{(dossier.jeune?.commune!=='null' && dossier.jeune?.commune) ? dossier.jeune?.commune : ' - '}</Typography>
                      <Typography>{(dossier.jeune?.quartier !== 'null' && dossier.jeune?.quartier) ? dossier.jeune?.quartier : ' - '} </Typography>
                      <Typography>{(dossier.jeune?.cgqcgv !== 'null' && dossier.jeune?.cgqcgv) ? dossier.jeune?.cgqcgv : ' - '} </Typography>
                    </div>
                  </div>
                  <div style={styles.detContainer}>
                    <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Adresse postale : </Typography>
                      <Typography style={styles.label}>Téléphone fixe : </Typography>
                      <Typography style={styles.label}>Mobile: </Typography>
                      <Typography style={styles.label}>N° whatsapp : </Typography>    
                      <Typography style={styles.label}>Adresse email : </Typography>
                  </div>
                    <div style={styles.detItemRight}>
                      <Typography>{(dossier.jeune?.bp !== 'null' && dossier.jeune?.bp) ? dossier.jeune?.bp : ' - '} </Typography>
                      <Typography>{(dossier.jeune?.telephone !== 'null' && dossier.jeune?.telephone) ? dossier.jeune?.telephone : ' - '}</Typography>
                      <Typography>{(dossier.jeune?.mobile1 !=='null' && dossier.jeune?.mobile1) ? dossier.jeune?.mobile1 :' - '} / {(dossier.jeune?.mobile2 !=='null' && dossier.jeune?.mobile2) ? dossier.jeune?.mobile2 : ' - '}</Typography>
                      <Typography>{(dossier.jeune?.numerowhatsapp !==null && dossier.jeune?.numerowhatsapp) ? dossier.jeune?.numerowhatsapp :' - '} </Typography> 
                      <Typography>{(dossier.jeune?.email !=='null' && dossier.jeune?.email) ? dossier.jeune?.email :' - '}</Typography>
                    </div>
                  </div>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="situationactuelle">
                <Card>
                    <Card.Header>
                        <div className={classes.titleBox}>
                            <Card.Title>Situation actuelle</Card.Title>
                            <IconButton onClick={handlesitOpen}>
                              <ModeEditSharpIcon className={classes.iconsbtn} />
                            </IconButton>
                        </div>
                         
                      <Card.Hr />
                      
                    </Card.Header>
                    <div style={styles.detContainer}>
                        <div style={styles.detItemLeft}>
                          <Typography style={styles.label}>Situation : </Typography> 
                          <Typography style={styles.label}>Niveau d'etude : </Typography>
                          <Typography style={styles.label}>Activité pratiqué : </Typography> 
                          <Typography style={styles.label}>Secteur de métier : </Typography>
                          <Typography style={styles.label}>Nombre d'année d'expérience : </Typography>
                        </div>

                        <div style={styles.detItemRight}>
                          <Typography>{ dossier['situation']=='null' ?  ' -' : (dossier['situation'] || '-')} {dossier['autresituation']=='null' ? "" :(dossier['autresituation'] ||'')}</Typography>
                          <Typography>{dossier['niveauetude']=='null' ? '-' : (dossier['niveauetude'] ||' - ')}</Typography>
                          <Typography>{dossier['activite']=='null' ? ' - ' : (dossier['activite'] ||' - ')} </Typography> 
                          <Typography>{dossier['secteuractivite']=='null' ? ' - ' : dossier['secteuractivite'] || ' - '}</Typography> 
                          <Typography>{dossier['anneeexperience']=='null' ? ' - ' : dossier['anneeexperience'] ||' - '} </Typography>
                        </div>
                      </div>
                      <Card.Hr />
                      <div style={styles.detContainer}>
                        <div style={styles.detItemLeft}>
                          <Typography style={styles.label}>Diplôme : </Typography> 
                          <Typography style={styles.label}>Etablissement d'obtention : </Typography>
                          <Typography style={styles.label}>Année d'obtention : </Typography> 
                          <Typography style={styles.label}>Pays d'obtention : </Typography>
                          
                        </div>

                        <div style={styles.detItemRight}>
                          <Typography>{dossier['diplome']=='null' ? ' - ' : dossier['diplome'] ||' - '}</Typography>
                          <Typography>{dossier['etablissement'] == 'null' ? ' - ' : dossier['etablissement']||' - '}</Typography>
                          <Typography>{dossier['anneediplome']=='null' ? ' - ' : dossier['anneediplome'] ||' - '} </Typography> 
                          <Typography>{dossier['paysdiplome'] =='null' ? ' - ' :dossier['paysdiplome'] || ' - '}</Typography> 
                        </div>
                      </div>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="parcours">
                <Card >
                  <Card.Header>
                        <div className={classes.titleBox}>
                            <Card.Title>Cursus / Experiences</Card.Title>
                            <IconButton onClick={handleparcOpen}>
                              <AddCircleOutlineIcon className={classes.iconsbtn} />
                            </IconButton>
                        </div>
                         
                      <Card.Hr />
                  </Card.Header>
                  {(dossier.jeune && dossier.jeune.hasOwnProperty('parcours') && dossier.jeune.parcours.length!==0)&&<div style={{marginTop:20}}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            {dossier.jeune.parcours.map((cur,indx)=>{
                              if(cur.type ==='cursus' || cur.type ==='formation'){
                                return <TableRow key={"cur-"+indx}>
                                  <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                                  <TableCell align="left">{cur['diplome'] || ""}</TableCell>
                                  <TableCell align="left">{cur['etablissement']||""}</TableCell>
                                  <TableCell align="left">{cur['missions'] ||""}</TableCell>
                                </TableRow>
                              }else{
                                return false
                              }
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </div>}
                  {(dossier.jeune && dossier.jeune?.hasOwnProperty('experiences') && dossier.jeune?.parcours?.length!==0)&&<div style={{marginTop:20}}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          {dossier.experiences.map((cur,indx)=>{
                            if(cur.type==='experience'){
                              return <TableRow key={"ex-"+indx}>
                                <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datedebut).format('DD/MM/YYYY')}`}</TableCell>
                                <TableCell align="left">{cur.diplome}</TableCell>
                                <TableCell align="left">{cur.etablissement}</TableCell>
                                <TableCell align="left">{cur.missions}</TableCell>
                          </TableRow>
                            }else{
                              return false
                            }
                            
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>}
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="atouts">
                <Card>
                  <Card.Header>
                        <div className={classes.titleBox}>
                            <Card.Title>CONNAISSANCES / LOISIRS</Card.Title>
                            <IconButton onClick={handleatoutOpen}>
                              <AddCircleOutlineIcon className={classes.iconsbtn} />
                            </IconButton>
                        </div>
                  
                      <Card.Hr />
                  </Card.Header>
                    {(dossier.jeune && dossier.jeune?.hasOwnProperty('atouts') && dossier.jeune?.atouts?.length!==0)&&<div style={{marginTop:20}}>
                    
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          {dossier.jeune.atouts.map((cur,indx)=>{
                            if(cur.type === 'connaissance'){
                              return <TableRow key={"cn-"+indx}>
                                <TableCell align="center">{cur.libelle}</TableCell>
                                <TableCell align="left">{cur.niveau}</TableCell>
                          </TableRow>
                            }else{
                              return false
                            }
                            
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>}
                  {(dossier.jeune && dossier.jeune?.hasOwnProperty('atouts') && dossier.jeune?.atouts?.length!==0)&&<div style={{marginTop:20}}>
                  
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          {dossier.loisirs.map((cur,indx)=>{
                            if(cur.type === 'loisir'){
                              return <TableRow key={"lrs-"+indx}>
                                  <TableCell align="center">{cur.libelle}</TableCell>
                                  <TableCell align="left">{cur.niveau}</TableCell>
                            </TableRow>
                            }else{
                              return false
                            }
                              
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>}
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="documents">
              <Card>
                  <Card.Header>
                        <div className={classes.titleBox}>
                            <Card.Title>Liste des documents</Card.Title>
                            <IconButton onClick={handledocOpen}>
                              <AddCircleOutlineIcon className={classes.iconsbtn} />
                            </IconButton>
                        </div>
                        
                      <Card.Hr />
                  </Card.Header>
              {(dossier.jeune?.hasOwnProperty('documents') && dossier.jeune?.documents?.length!==0)?(
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {dossier.jeune?.documents.map((document) => (
                    <StyledTableRow key={document.id}>
                      <StyledTableCell component="th" scope="row">
                        {document.titre}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <IconButton onClick={(e)=>handleShowDocument(document.url)}>
                              <CloudDownloadIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <IconButton >
                              <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
      </TableContainer>):(<Typography>Aucun document attaché</Typography>)}
      </Card>
              </Tab.Pane>
            </Tab.Content>

          </Col>
        </Row>
    </Tab.Container>
    
          {/*Modal infos personnelle*/}
        <CustumModal open={openid} handleClose={handleIdClose} titre='Mise à jour identité' width='md'>
            {dossier&&<StepsIdentification personne={dossier?.jeune} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>
       {/*Modal cordonnées*/}
        <CustumModal open={opencoord} handleClose={handleCoordClose} titre='Mise à jour des coordonnées' width='md'>
            {dossier&&<StepsCoordonnes personne={dossier?.jeune} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>

         {/*Modal situation*/}
         <CustumModal open={opensit} handleClose={handlesitClose} titre='Situation actuelle' width='md'>
            {dossier&&<StepsStuation dossier={dossier} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>

        {/*Modal atouts*/}
        <CustumModal open={openatout} handleClose={handleatoutClose} titre='Connaissances, Atouts, Hobbies' width='md'>
            {dossier&&<StepsConnaissance dossier={dossier} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>
       {/*Modal parcours*/}
        <CustumModal open={openparc} handleClose={handleparcClose} titre='Parcours (Scolaire,Expériences, Formations' width='md'>
            {dossier&&<StepsCursus dossier={dossier} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>
        {/*Modal document*/}
        <CustumModal open={opendoc} handleClose={handledocClose} titre='Documents' width='md'>
            {dossier&&<StepsDocuments dossier={dossier} handleBack={handleIdClose} reflesh={refleshDossier} />}
        </CustumModal>
    </div>
  )
}

export default DossierFiche