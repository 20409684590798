import useSWR from 'swr'
import axios from 'lib/axios'
import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const useAuth = ({middleware, redirectIfAuthenticated} = {}) => {
 
    let navigate = useNavigate();
    let params = useParams();
    const dispatch =useDispatch()

    const {data: user, error, mutate} = useSWR('/api/user', () =>
      axios
        .get('/api/user')
        .then(res => {
          return res.data
        })
        .catch(error => {
          //console.log(error);
          if (error.response.status !== 409) {
            throw error
          }
          mutate('/verify-email')
        }),
      {
        revalidateIfStale: false,
        revalidateOnFocus: false
      }
    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')
      
    const register = async ({setErrors, ...props}) => {
      await csrf()
      setErrors([])
      axios
        .post('/register', props)
        .then(() => mutate())
        .catch(error => {
        
          if (error.response.status !== 422) throw error
          setErrors(Object.values(error.response.data.errors).flat())
        })
    }
  
    const login = async ({setErrors, setStatus, ...props}) => {
      await csrf()
      setErrors([])
      setStatus(null)
      axios
        .post('/login', props)
        .then(() => mutate())
        .catch(error => {
          if (error.response.status !== 422) throw error
          console.log(error.response);
          setErrors(Object.values(error.response.data.errors).flat())
        })
    }
  
    const forgotPassword = async ({setErrors, setStatus, email}) => {
      await csrf()
      setErrors([])
      setStatus(null)
      axios
        .post('/forgot-password', {email})
        .then(response => setStatus(response.data.status))
        .catch(error => {
          if (error.response.status !== 422) throw error
            setErrors(Object.values(error.response.data.errors).flat())
          })
    }
  
    const resetPassword = async ({setErrors, setStatus, ...props}) => {
      await csrf()
      setErrors([])
      setStatus(null)
      axios
        .post('/reset-password', {token: params.token, ...props})
        .then(response => navigate(`/login?reset=${  btoa(response.data.status)}`))
        .catch(error => {
          if (error.response.status !== 422) throw error
          setErrors(Object.values(error.response.data.errors).flat())
        })
    }
  
    const resendEmailVerification = ({setStatus}) => {
      axios
        .post('/email/verification-notification')
        .then(response => setStatus(response.data.status))
    }
  
    const logout = async () => {
      if (!error) {
        await axios.post('/logout')
        window.localStorage.clear();
        mutate()
        window.location.pathname = '/'
      }
    }

    const assignerRole =(profil,user)=>{
      if(profil,user){
          axios.post(`/api/user-assign-role/${user}`,{"profil":profil}).then(res=>{
            if(res.status ===200 || res.status ===201){
              mutate()
              return true
            }else{
              return false
            }
            
          }).catch(error=>{
            if (error.response.status !== 422) throw error
            setErrors(Object.values(error.response.data.errors).flat())
          })
      }
    }

     const removeRole =(profil,user)=>{
      if(profil,user){
          axios.post(`/api/user-remove-role/${user}`,{profil:profil}).then(res=>{
            if(res.status ===200 || res.status ===201){
              mutate()
              return true
            }else{
              return false
            }
            
          }).catch(error=>{
            if (error.response.status !== 422) throw error
            setErrors(Object.values(error.response.data.errors).flat())
          })
      }
     }

     const updateRole =(profil,user)=>{
      if(profil,user){
          axios.post(`/api/user-synchro-role/${user}`,{profil:profil}).then(res=>{
            if(res.status ===200 || res.status ===201){
              mutate()
              return true
            }else{
              return false
            }
            
          }).catch(error=>{
            if (error.response.status !== 422) throw error
            setErrors(Object.values(error.response.data.errors).flat())
          })
      }
     }

     const permsRole = (profil)=>{
      return axios.get(`/api/user-perms-role/${profil}`).then(res=>{
        if(res.status ===200 || res.status ===201){
          return res.data
        }else{
          return false
        }
        
      }).catch(error=>{
        if (error.response.status !== 422) throw error
        setErrors(Object.values(error.response.data.errors).flat())
      })
     }
     const can =(perms)=>((user?.permissions || []).find((p)=>p==perms) ? true : false)
     const hasRole = (role)=>(user?.role || []).find(r=>r==role) ? true : false
     const hasanyrole = (roles)=>(user?.role || []).some(ur=>roles.includes(ur)) ? true :false

    useEffect(() => {
      if (middleware === 'guest' && redirectIfAuthenticated && user) {
        console.log(user);
        localStorage.setItem('user',JSON.stringify(user))
        axios.get(`/api/profile/${user.role_id}`).then(re=>{
          if(re?.data){
            const cportial = re.data['portails'].filter(item=>item.default===1)[0]['libelle'].toLowerCase()
            dispatch({type:'SET_PORTAILS',payload:re.data['portails']})
            dispatch({type:'SET_PORTAIL',payload:cportial})
            navigate(`/${cportial}`)
          }
        }) 
      }

      if (middleware === 'auth' && error) {
        localStorage.removeItem('user')
        logout()
      }else{
    
        localStorage.setItem('user',JSON.stringify(user))
      }
    }, [user, error])
    

    return {
      user,
      register,
      login,
      forgotPassword,
      resetPassword,
      resendEmailVerification,
      logout,
      assignerRole,
      removeRole,
      updateRole,
      permsRole,
      can,
      hasRole,
      hasanyrole
    }
  }