
const initialState = {
    portail: 'Conseiller',
    portails:[],
    errors: null,
    loading:false,
  };

  export default function (state =initialState,action){
    switch (action.type) {
        case "SET_PORTAIL":
            return {
                ...state,
                loading:false,
                portail: action.payload,
            }
        case "SET_PORTAILS":
            return {
                ...state,
                loading:false,
                portails: action.payload,
            }
        default:
            return state;
    }
  }
  