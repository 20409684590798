import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import {Paper} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Container = styled(Paper)`
  display:'flex';
  flex:1;
  flex-direction:column;
  border-left:5px solid #00B04B;
  padding:10px;
  cursor:pointer;
  margin-top:10px;
  margin-right:20px;
  margin-bottom:20px;
  &:hover{
    background-color:#f9f8e3;
    border:none;
  }

`
const Frame = styled.div`
  display:flex;
  flex-direction:row
`
const CType = styled.div`
  display:flex;
  background-color:${({type})=>type==='cdi'?"#00B04B":(type==='cdd'?"#9E9E9E":"#707070")};
  padding:5px;
  border-radius:5px;
  color:#ffffff;
  font-weight:bold;
  font-size:14px;
  margin-left:20px;
  margin-right:20px;
`
const Addbutton = styled.button`
  display:flex;
  border-radius:5px;
  justify-content:center;
  align-items:center;
  font-size:20px;
  color:white;
  border:0px;
  padding:5px;
  box-sizing: content-box;
  background:${({primary})=>primary?'#00B04B':'#FF0471'};
` 
const Title =  styled.h2`
  font-size:16px;
  text-transform: uppercase;
  color:#00B04B;
  font-weight:bold;
  margin:10px 0px
`
const Texte = styled(Typography)`
  font-size:8px;
  color:#777777;
`
const Header = styled.div`
  display:flex;
  flex-direction:row;
  padding:5;
  justify-content:space-between;
  align-items:center;
`
function Offreh({offre,type,readmore,...restProps}) {
 
  const txt ="Nulla ipsum esse nisi laborum nisi cupidatat do deserunt adipisicing tempor duis. Aliquip ad velit commodo pariatur ullamco esse. Ipsum excepteur aute officia ipsum esse adipisicing id ad proident Lorem velit enim. Pariatur id in veniam ex officia aliqua labore ea ullamco pariatur adipisicing magna ipsum."
  return (
    <Container elevation={3}>
        <Frame>
            <Header>
                <Typography style={{fontStyle:'italic'}} variant="subtitle1">{`${offre?offre['ref']:"N°Ref : 25589-12-5-22"}`}  </Typography>
                <CType type={offre?offre['typecontrat']:type}>{offre?offre['typecontrat']:'CDD'}</CType>
                <Addbutton primary>+</Addbutton>
            </Header>
        </Frame>
        <Title>{offre?offre['titre']:"Technocomercial en informatique"}</Title>
        <div style={{display:'flex',}}>
          <Texte variant='caption'>Publié le: {offre?dayjs(offre['datedebut']).format("DD/MM/YYYY"):"12/05/2025"} </Texte >
          <LocationOnIcon  disabled/> <Texte variant='caption'>{offre?offre['ville']:"Abidjan"}, Côte d'ivoire </Texte><AccessTimeIcon /><Texte variant='caption'>Date de cloture : {offre?dayjs(offre['datefin']).format("DD/MM/YYYY"):"12/05/2025"}</Texte></div>
          {(offre.hasOwnProperty('missions'))?
          (<ReactQuill readOnly={true} theme={"bubble"} value={(offre.hasOwnProperty('missions') && offre['missions'])&&offre['missions'].slice(0,250)+"..."} /> ):<Texte variant='body2'>{txt.slice(0,150)}</Texte>}
        <Texte variant='body2'>{`Lieu : ${offre&&offre['ville']}`}</Texte>
        <Texte variant='body2'>{`Compétences clé : ${offre&&offre['competences']}`}</Texte>
    </Container>
  )
}

export default Offreh