import React,{useEffect,useState} from 'react'
import Dash from 'components/dash'
import styled from 'styled-components';
import { useNavigate,useLocation,useOutletContext } from 'react-router-dom';
import {Pagination, Paper, Tooltip, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add'
import Offreh from 'containers/Offreh/Offreh';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import ReplyAllSharpIcon from '@mui/icons-material/ReplyAllSharp';
import swal from 'sweetalert';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';

const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
const useStyles =makeStyles()((theme)=>({
  actionBar:{
    display:'flex',
    justifyContent:'flex-end'
},
btnAdd:{
  color:'#00B04B',
  fontSize:40,
  '&:hover':{
    color:'#F29400'
  }
},
searchBox:{
  display: 'flex',
  padding:20,
  marginBottom:20,
  flexDirection: 'column',
},
btnsearch:{
    paddingLeft:20,
    paddingRight:20,
    backgroundColor: '#00B04B',
    color:'white',
    marginRight:20
  }
}))

const DispositifList = (pros) => {
  const {classes} = useStyles()
    const navigate =useNavigate()
    const [search,setSearch] = useState(null)
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    const [paginateData,setPagnateData] = useState(null)
   
  const [dispositifs,setDispositifs] = React.useState(null)
  const handleDispositifs =(numberPage = 1)=>{
    const url =  search? `/api/dispositif/list/${search}?page=${numberPage}` : `/api/dispositif/list?page=${numberPage}` 
    axios.get(url).then(res=>{
      if(res.status === 200 || res.status === 201){
        setDispositifs(res.data.data)
        setPagnateData({
          current_page:res.data.meta?.current_page,
          last_page:res.data.meta?.last_page,
          per_page:res.data.meta?.per_page,
          total:res.data.meta?.total
        })
      }else{
        swal("Ooops !","Aucun dispositifs trouvé","error")
      }
    }).catch(err=>{
      swal("Ooops !","Erreur lors de l'opération","error")
    })
    
  } 
  useEffect(()=>{
    handleDispositifs()
  },[])
  const handleNouveau =()=>{
    navigate(`/${portail}/dispositifs/nouveau`)
  }
  
  const handleDetails =(data)=>{
    if(data){
      navigate(`/${portail}/dispositifs/fiche/${data['id']}`,{replace:true, state:{data}})
    }
  }
  const handleEdit =(data)=>{
    if(data){
      navigate(`/${portail}/dispositifs/modifier/${data['id']}`,{
        state: {
          data:data,
        }
      })
    }
  }


  const handleDelete =(offre)=>{
     axios.delete(`/api/dispositif/${offre['id']}`).then(res=>{
      if(res.status ===200 || res.status === 201 || res.status ===204){
        swal("Bien !","Suppression effectuée avec succès","success")
        handleDispositifs()
      }else{
        swal("Ooops!","Impossible d'effectué cette opération",'error')
      }
     }).catch(err=>{
      swal("Ooops!",`Erreur inconnu lors de la suppression`,'error')
     })
  }

  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
         <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,padding:10}}>
              <OfferHeader>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <OfferTitle>Gestion des dispositifs d'accompnements / services</OfferTitle>
                  {portail==='administrateur'&&<div className={classes.actionBar}>
                    <IconButton  aria-label="part-add" onClick={handleNouveau}>
                        <AddIcon className={classes.btnAdd} />
                    </IconButton>
                  </div>}
                  
                </div>
                <Hr />
                <div>
                  <Typography variant='h5'>RECHERCHER</Typography>
                  <Paper className={classes.searchBox} variant='outlined'>
                      <InputGroup className="mb-3">
                          <Form.Control
                              placeholder="Rechercher ...(titre,partenaire,contrat,...)"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(event)=>setSearch(event.target.value)}
                          />
                          <div>
                            <Button variant="outline-success" id="button-addon2" className={classes.btnsearch} onClick={()=>handleDispositifs(1)}>
                                <SearchIcon />
                            </Button>
                            <Button variant="outline-success" id="button-addon2" onClick={()=>handleDispositifs(1)}>
                                <PageviewIcon />Recherche détaillée
                            </Button>
                          </div>
                      </InputGroup>
                  </Paper>
                </div>
              </OfferHeader>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Libelle</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                {(dispositifs  && dispositifs.length!==0) ?dispositifs.map(dispositf=>(
                  <tr>
                    <td>{dispositf.id}</td>
                    <td>{dispositf.libelle}</td>
                    <td>{dispositf.dscription}</td>
                    <td>{dispositf.type}</td>
                    <td>{dispositf.statut}</td>
                    <td valign='middle'>
                      <div style={{display:'flex'}}>
                      <Tooltip title="Détails">
                        <IconButton onClick={() =>handleDetails(dispositf)}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      {portail==='administrateur'&&<Tooltip title="Modidier">
                        <IconButton onClick={() =>handleEdit(dispositf)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>}
                      {portail==='administrateur'&&<Tooltip title="Supprimer">
                        <IconButton onClick={() =>handleDelete(dispositf)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>}
                      
                      </div>
                    </td>
                  </tr>)) : (<tr><td colSpan={4}><p>Aucun dispositif trouvée</p></td></tr>)

                }
                </tbody>
              </Table>
              {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleDispositifs(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
      </Dash.Frame>
           
    </div>
  )
}

export default DispositifList
