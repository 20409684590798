import React from 'react';
import "index.css"
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter,
} from "react-router-dom";
import { GlobalStyles } from './GlobalStyle';
import { createTheme,ThemeProvider } from '@mui/material';
import { orange } from '@mui/material/colors';
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import PortailContext from 'context/PortailContext';
import CommonsProvider from 'context/CommonsContext';
import { StyledEngineProvider } from '@mui/material';
const root = ReactDOM.createRoot(document.getElementById('root'));
const outerTheme = createTheme({
  palette: {
    secondary: {
      main: orange[500],
    },
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CommonsProvider>
          <PortailContext>
            <BrowserRouter>
            <GlobalStyles />
            <ThemeProvider theme={outerTheme}>
              <App />
            </ThemeProvider>
            </BrowserRouter>
          </PortailContext>
        </CommonsProvider>
      </PersistGate>
    </Provider>
    </StyledEngineProvider>
  </React.StrictMode>
);
