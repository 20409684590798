import React from 'react'
import { Paper, Typography,Divider } from '@mui/material'
import ListGroup from 'react-bootstrap/ListGroup';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import "./DashAdministrateur.scss"
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DashAdministrateur() {
  const navigate = useNavigate()
  const portail = useSelector(state=>state.ui.portail)
  const handleNavigate =(route)=>{
    navigate(`/${portail.toLowerCase()}/${route}`)
  }
  return (
    <div className="DashAdministrateur">
        <div className="titlebox">
            <Typography variant='h5'>Accueil</Typography>
        </div>
        
        <div className="bodybox">
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>SERVICES</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                  <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[GESTION]</div>
                        Administrer les profils
                      </div>
                      <IconButton onClick={()=>handleNavigate("profils")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[GESTION]</div>
                        Administer les comptes utilisateurs
                      </div>
                      <IconButton onClick={()=>handleNavigate("utilisateurs")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[SUIVI]</div>
                        Administer les actions des utilisateurs
                      </div>
                      <IconButton>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="widFooter"></div>
            </Paper>
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>STRUCTURES</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[PARTENAIRES]</div>
                        Administrer les partenaires
                      </div>
                      <IconButton onClick={()=>handleNavigate("partenaires")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[ZONE COUVERTURES]</div>
                        Administer les quartiers
                      </div>
                      <IconButton onClick={()=>handleNavigate("quartiers")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[ZONE COUVERTURES]</div>
                        Administer les comités de gestion (CGQ/CGV)
                      </div>
                      <IconButton onClick={()=>handleNavigate("comites")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="widFooter"></div>
            </Paper>
            {/* 
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>REFERENCES</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[TABLES DE REFERENCE]</div>
                        Prescripteur de dispositifs
                      </div>
                      <IconButton onClick={()=>handleNavigate("references")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[TABLES DE REFERENCE]</div>
                        Administer les opérateurs
                      </div>
                      <IconButton onClick={()=>handleNavigate("reference/operateurs")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[TABLES DE REFERENCE]</div>
                        Administer les référents
                      </div>
                      <IconButton onClick={()=>handleNavigate("references/referents")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">[AUTRES]</div>
                        Administer les thèmes
                      </div>
                      <IconButton onClick={()=>handleNavigate("references/themens")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    
                  </ListGroup>
                </div>
                <div className="widFooter">
                    <Button>Plus de paramètres ...</Button>
                </div>
            </Paper>*/}
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>PORTAIL CONSEILLER</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les dispositifs
                      </div>
                      <IconButton onClick={()=>handleNavigate("dispositifs")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administer les offres
                      </div>
                      <IconButton>
                        <NavigateNextIcon onClick={()=>handleNavigate("offres")}/>
                      </IconButton>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="widFooter"></div>
            </Paper>
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>PORTAIL JEUNES</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les bureaux de jeunes
                      </div>
                      <IconButton onClick={()=>handleNavigate("bureaux")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <div className="widFooter"></div>
            </Paper>
            <Paper elevation={3} className="widget">
                <div className="widTitle"><Typography variant='h5'>PARAMETRES</Typography></div>
                <Divider style={{marginTop:20,marginBottom:20}} />
                <div className="widBody">
                  <ListGroup>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les types d'offre
                      </div>
                      <IconButton onClick={()=>handleNavigate("categories-offres")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les types de demandes
                      </div>
                      <IconButton onClick={()=>handleNavigate("types-demandes")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les types de structures
                      </div>
                      <IconButton onClick={()=>handleNavigate("types-structures")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Administrer les nature d'événements
                      </div>
                      <IconButton onClick={()=>handleNavigate("natures-evenements")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        Gestion des modules
                      </div>
                      <IconButton onClick={()=>handleNavigate("parametres")}>
                        <NavigateNextIcon />
                      </IconButton>
                    </ListGroup.Item>
                  </ListGroup>
                  
                </div>
                <div className="widFooter"></div>
            </Paper>
        </div>
    </div>
  )
}

export default DashAdministrateur
