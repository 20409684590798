
import React,{useState,useContext, useEffect} from 'react'
import { Card } from 'components'
import { Form } from 'components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Autocomplete, Divider,  MenuItem, Typography } from '@mui/material'

import { pays,secteuractivites,niveauetudes, } from 'fixtures/data'

const groupItems=[
  {
    name: "cursus",
    label: "Parcours scolaire",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "formation",
    label: "Formations",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "experience",
    label: "Expériences professionnelles",
    information: "",
    disabled: false
  }
]

function StepsStuation({dossier,handleBack,reflesh}) {

  const {situation,autresituation,niveauetude,activite,secteuractivite,anneeexperience,diplome,etablissement,anneediplome,paysdiplome}=dossier

  const [inputField,setInputField] = useState({situation,autresituation,niveauetude,activite,secteuractivite,anneeexperience,diplome,etablissement,anneediplome,paysdiplome})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  //Année diplome
  const curentDate = new Date()
  const anneeStart  = (curentDate.getFullYear())-40
  const anneeEnd  = curentDate.getFullYear()
  const annee =()=>{
    const rows =[]
    for (let i = anneeEnd; i >= anneeStart; i--){
        rows.push(<MenuItem key={i} style={{fontWeight:'bold'}} value={i}>{i}</MenuItem>)
    }
    return rows
  }
  const handleSave =(e)=>{
    e.stopPropagation()
  e.preventDefault()
  
  axios.post(`/api/dossier/update/${dossier.id}`,inputField).then(res=>{
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
      <form onSubmit={handleSave}>
      
      <Card>
        
        <Form.Group>
          <Form.Input 
                name='situation' 
                size="small" 
                margin="dense" 
                style={{ width: '50%' }}
                select 
                value ={inputField['situation'] || ""} 
                onChange={handleChange}  label="Situation actuelle"
          >
              <MenuItem  value='No'>Aucun</MenuItem>
              <MenuItem value='occupe'>Occupé</MenuItem>
              <MenuItem value='chomeur'>Chômeur</MenuItem>
              <MenuItem value='enquete'>En quête</MenuItem>
              <MenuItem value='entrepreneur'>A mon propre compte</MenuItem>
              <MenuItem value='autre'>Autre</MenuItem>
            </Form.Input>
         
                {(inputField['situation'] ==='autre')&&
                  <Form.Input 
                      size="small" 
                      name='autresituation' 
                      required
                      margin="dense" 
                      label="Veuillez préciser votre situation actuelle"
                      style={{width:'100%'}}
                      value={inputField['autresituation'] ||""}
                      onChange={handleChange}
                    />
                
              }
          </Form.Group>
          <Form.Group direction ='row' salign='center' flex={1}>
            <Form.Input 
                  name='niveauetude' 
                  size="small" 
                  margin="dense" 
                  style={{ width: '50%',marginRight:20 }}
                  select 
                  value ={inputField['niveauetude'] || ""} 
                  onChange={handleChange}  label="Niveau d'étude"
            >
              <MenuItem  value='No'>Aucun</MenuItem>
              {niveauetudes.map((niveau,idx)=><MenuItem key={idx} style={{fontWeight:'bold'}} value={niveau}>{niveau}</MenuItem>)} 
          </Form.Input>
          </Form.Group>
          <Divider style={{marginTop:10, marginBottom:10}} />
          <Form.Group direction ='row' salign='center' flex={1}>
            <Form.Input 
                  size="small" 
                  margin="dense" 
                  label="Activité pratiqué" 
                  wd='100%' 
                  style={{marginRight:20}}
                  value={inputField['activite'] ||""}
                  name='activite'
                  onChange={handleChange}
                />
          </Form.Group>
          <Form.Group direction ='row' salign='center' flex={1}>
              <Form.Input 
                  name='secteuractivite' 
                  size="small" 
                  margin="dense" 
                  style={{ width: '50%',marginRight:20 }}
                  select 
                  value ={inputField['secteuractivite'] || ""} 
                  onChange={handleChange}  label="Secteur de métier"
            >
              <MenuItem  value='No'>Aucun</MenuItem>
              {secteuractivites.map((activite,idx)=><MenuItem key={idx} style={{fontWeight:'bold'}} value={activite}>{activite}</MenuItem>)} 
          </Form.Input>
          <Form.Input 
                  size="small" 
                  margin="dense" 
                  label="Nombre d'année d'expérience" 
                  wd='50%' 
                  type='number'
                  style={{marginRight:20}}
                  value={inputField['anneeexperience'] ||""}
                  name='anneeexperience'
                  onChange={handleChange}
                />
          </Form.Group>
      </Card>
      <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Qualifications</Typography>
      <Card>
        <Form.Group direction ='row' salign='center' flex={1}>
          <Form.Input 
              size="small" 
              margin="dense" 
              label="Diplôme" 
              wd='50%' 
              style={{marginRight:20}}
              value={inputField['diplome'] ||""}
              name='diplome'
              onChange={handleChange}
            />
            <Form.Input 
              size="small" 
              margin="dense" 
              label="Etablissement d'obtention" 
              wd='50%' 
              value={inputField['etablissement'] ||""}
              name='etablissement'
              onChange={handleChange}
            />
        </Form.Group>
        <Form.Group direction ='row' salign='center' flex={1}>
          <Form.Input 
                  name='anneediplome' 
                  size="small" 
                  margin="dense" 
                  style={{ width: '50%',marginRight:20 }}
                  select 
                  value ={inputField['anneediplome'] || ""} 
                  onChange={handleChange}  label="Année d'obtention"
            >
              <MenuItem  value='No'>Aucun</MenuItem>
              {annee().map(r=>r)}
          </Form.Input>
          <Autocomplete
              disablePortal
              id="paysdiplome"
              size="small" 
              margin="dense"
              getOptionLabel={(option) => option.libelle}
              defaultValue="Côte d'Ivoire"
              options={pays}
              style={{ width:'50%'}}  
              value={inputField['paysdiplome'] ||""}
              name='paysdiplome'
              onChange={(e,v)=>setInputField({...inputField,'paysdiplome':v})}
              renderInput={(params) => <Form.Input {...params} name='paysdiplome' label="Pays d'obtention" wd='100%' inputProps={{
                ...params.inputProps
              }} />}
          />
        </Form.Group>
          
      </Card> 
              
      
        <Card direction='row' palign='space-between'>
          <Form.Button onClick={handleBack} >Annuler</Form.Button>
            <Form.Button type='submit' primary>Suivant</Form.Button> 
        </Card>
      </form>
      </div>
    </LocalizationProvider>
  )
}

export default StepsStuation