import React from 'react'
import { useAuth } from 'hooks/auth'
import { useJeune } from 'hooks/jeune'
import Typography from '@mui/material/Typography';
import styled from 'styled-components'
import { Card } from 'components'
import { useNavigate } from "react-router-dom";
import axios from 'lib/axios'
import swal from 'sweetalert'
import dayjs from 'dayjs'
import { makeStyles } from 'tss-react/mui'
import { Form } from 'components'
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {villes,communes,doctype} from 'fixtures/data'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import Checkbox from '@mui/material/Checkbox';
import {Pagination, FormControlLabel, Divider } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useDropzone} from 'react-dropzone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {CardActionArea,Paper} from '@mui/material';
import { getExtension } from 'utils/utils';
import { useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent'
import { fichieInscription } from 'lib/application';
import { CommonsContext } from 'context/CommonsContext';
import { Button } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pays,secteuractivites,niveauetudes,niveauexperience } from 'fixtures/data'
import CircularProgress from '@mui/material/CircularProgress';
import FolderIcon from '@mui/icons-material/Folder';
const DossierJeunes = () => {
  const {user} =useAuth()
    const {jeune,jeuneInfos:{dossier}} = useJeune(user?.personne_id)
  return (
    <div className='w-screen-sm mx-auto p-2'>
        <div className='flex my-4 flex-row gap-2 items-center'>
          
          <FolderIcon className='text-green-600' sx={{fontSize:40}} />
          <h1 className='text-semibold text-green-600'>Mon dossier</h1>
          <Button>Modifier</Button>
          <Button>Editer un PDF</Button>
          <Button>Envoyer par mail</Button>
        </div>
      Dossiers jeunes
    </div>
  )
}

export default DossierJeunes
