import React,{useContext, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { IconButton, MenuItem, Paper, Typography } from '@mui/material'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import axios from 'lib/axios'
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs'
import {Divider} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Form as CustomForm } from 'components'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Pagination} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Offrev from 'containers/Offrev/Offrev'
import Offreh from 'containers/Offreh/Offreh'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { red } from '@mui/material/colors'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { CommonsContext } from 'context/CommonsContext'
const styles ={
    detContainer:{
      display:'flex',
      flexDirection:'row',
      flex:1,
      justifyContent:'center'
    },
  
    detItemLeft:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      alignItems:'flex-end',
      fontWeight:'bold',
      justifyContent:'flex-start',
     
    },
    detItemRight:{
      display:'flex',
      flexDirection:'column',
      alignItems:'flex-start',
      justifyContent:'flex-start',
      flex:1,
      marginLeft:20,
    },
    label:{
      fontStyle:'italic',
      fontWeight:'bold'
    },
    toolsbard:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    logoContainer:{
      display: 'flex',
      alignItems:'center',
      justifyContent:'flex-start',
      flex:1,
    },
  }
const useStyles = makeStyles()((theme)=>({
    containBox:{
        display:'flex',
        justifyContent:'space-between'
    },
    searchBox:{
        display: 'flex',
        padding:20,
        marginBottom:20,
        flexDirection: 'column',
    },
    btnAdd:{
        paddingLeft:20,
        paddingRight:20,
        backgroundColor: '#00B04B',
        color:'white',
        marginRight:20
    },
    detaildossier:{
        display:'flex',
        flex:1,
        justifyContent:'space-between'
      },
      labelle:{
        fontStyle:'italic',
        fontWeight:'bold'
      },
      navLabel:{
        textTransform:'uppercase', 
        marginLeft:10,
        fontSize:14
      },
      titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
      },
      iconsbtn:{
        color:'#00B04B',
        '&:hover':{
          color:'#F29400'
        }
      },
      iconsbtnDis:{
        color:red[400],
        '&:hover':{
          color:'#F29400'
        }
      }
}))
const FichePropositionOffres = (props) => {
    const location =useLocation()
    const navigate = useNavigate()
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    const {dossier} = location.state
    const {classes} = useStyles()
    const [photo,setPhoto] =useState(null)
    const [inputSearch,setInputSearch] = useState({
        etat:'En cours'
    })
    const {handleCommonsdata} = useContext(CommonsContext)
    useEffect(()=>{
      handleCommonsdata('titre',"Dossier - proposer une offre")
    },[])
    useEffect(()=>{
        if(dossier.jeune?.hasOwnProperty('photo') && dossier.jeune?.photo !==null && dossier.jeune?.document!=='null'){
        setPhoto(dossier.jeune.photo)
        }else{
            if(dossier.jeune.hasOwnProperty('documents')){
                const tof = dossier.jeune.documents.filter(item=>item.titre === 'Photo')
                if(tof && tof.length !== 0){
                    setPhoto(tof[0].url)
                }
            }
        }
    },[])
    const handleList =()=>{
      navigate(`/${portail}/dossiers`)
    }
  
    const [offreList,SetOffreList] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const handleSearch = (numberPage=1)=>{
        const url  = `/api/offre/recherche?page=${numberPage}`
        axios.post(url,inputSearch).then(res=>{
          if(res.status === 200 || res.status === 201){
            SetOffreList(res.data.data)
            setPagnateData({
              current_page:res.data.meta?.current_page,
              last_page:res.data.meta?.last_page,
              per_page:res.data.meta?.per_page,
              total:res.data.meta?.total
            })
          }else{
            swal("Ooops !","Aucune offre trouvée",'warning')
          }
        }).catch(err=>{
          console.log(err.response);
          swal("Erreur","Erreur lors de l'exécution de la requête",'error')
        })
      }
      useEffect(()=>{
        //handleSearch(1)
      },[])
      const [inputField,setInputField] = useState({
        dossier_id:dossier.id,
        personne_id:dossier.jeune?.id,
        demande_id:null,
        offres:[],
        statut:'Proposer',
        etat:'En attente',
        venu:'Proposition'
      })
      const handleChange = (e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
      }
     
      const handleDissociate =(offre)=>{
        if(offre){
          const currentOffres = [...inputField.offres]
          if(currentOffres.findIndex(item=>item.id === offre.id) !== -1){
            setInputField({...inputField,['offres']:[...currentOffres.filter(item=>item.id !== offre.id)]})
          }
        }
      }
      const handleAssociate =(offre)=>{
        if(offre){
          const currentOffres = [...inputField.offres]
          if(currentOffres.findIndex(item=>item.id === offre.id) === -1){
            setInputField({...inputField,['offres']:[...currentOffres,offre]})
          }
        }
      }
      const handleSubmit = (e)=>{
        e.preventDefault()
        if(inputField.demande_id && inputField.offres.length !==0){
          axios.post(`/api/offre-jeune/add/${inputField.dossier_id}`,inputField).then(res=>{
            if(res.status===200 || res.status === 201){
              swal("Bien","Porposition effectué avec succès","success")
              handleList()
            }else{
              swal(`Ooops !`,"Erreur lors de l'enregistrement",'error')
            }
          }).catch(err=>{
            console.log(err)
            swal("Ooops","Erreur lors de l'enregistrement",'error')
          })
        }
      }
      
  return (
    <div>
        <Card>
          <div style={{display:'flex',flexDirection:'column'}} >
              <div style={styles.logoContainer}>
                <div>{photo&&<img width="100" src={`${process.env.REACT_APP_BACKEND_URL}/${photo}`}/>}</div> 
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:20}}>
                    <Typography variant='h2'>{`${dossier.jeune?.nom} ${dossier.jeune?.prenom}`}</Typography> 
                    <div className='flex flex-row gap-3'>
                        <div className={classes.left}>
                            <div><span className={classes.labelle}>Age : </span>{}</div>
                            <div><span className={classes.labelle}>N° Dossier : </span> <Typography variant='subtitle'>{dossier['code']||' '}</Typography></div>
                            <div><sapn className={classes.labelle}>Créer le :</sapn><span>{dayjs(dossier.created_at).format('DD/MM/YYYY')}</span></div>
                        </div>  
                        <div className={classes.midlle}>
                            <div><span className={classes.labelle}>Commune : </span>{(dossier.jeune?.commune!=='null')&&dossier.jeune?.commune}</div>
                            <div><span className={classes.labelle}>Quartier : </span><span>{dossier.jeune?.quartier}</span> </div>
                            <div><sapn className={classes.labelle}>CGQ/CGV :</sapn><span>{dossier.jeune?.cgqcgv}</span></div>
                        </div>  
                        <div className={classes.right}>
                            <div><span className={classes.labelle}>Statut : </span>{dossier['statut'] || ""}</div>
                            <div><span className={classes.labelle}>Etape de traitement : </span> {dossier['etape']}</div>
                        </div>  
                    </div>
                  </div>
              </div>
            </div>
            <Divider />
          </Card>
          <div className={classes.containBox}>
          <div style={{flex:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <form onSubmit={handleSubmit}>
                    <Card style={{flex:2}}>
                        <Card.Header>  
                            <Card.Title>Fiche de proposition d'offres</Card.Title>
                            <Card.Hr />
                        </Card.Header>
                        <CustomForm.Group>
                            <CustomForm.Input 
                                name='demande_id' 
                                size="small" 
                                margin="dense" 
                                sx={{ width: '100%' }}
                                style={{marginRight:20}}
                                select 
                                value ={inputField['demande_id']||""} 
                                onChange={handleChange}  label="Demande">
                                {(dossier?.hasOwnProperty('demandes') && dossier.demandes!==0)&& dossier?.demandes.map(srs=>(<MenuItem  value={srs.id}>{srs.type.libelle}</MenuItem>))}
                            </CustomForm.Input>
                        </CustomForm.Group>
                        <CustomForm.Group direction ='row' flex={1}>
                            <DesktopDatePicker
                                label="Date de debut"
                                inputFormat="dd/MM/yyyy"
                                value={inputField['datedebut'] || ""}
                                name='datedebut'
                                onChange={(val)=>setInputField({...inputField,['datedebut']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                            <DesktopDatePicker
                                label="Date fin"
                                name='datefin'
                                inputFormat="dd/MM/yyyy"
                                value={inputField['datefin'] || ""}
                                onChange={(val)=>setInputField({...inputField,['datefin']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                        </CustomForm.Group>
                        <FormControl>
                            <FormLabel id="statut-group-label">Staut</FormLabel>
                            <RadioGroup row aria-labelledby="dstatut-group-label" name="statut" onChange={handleChange}  >
                                <FormControlLabel value="Proposer" control={<Radio checked={inputField['statut'] == 'Proposer'} color="success" />} label="Proposer" />
                                <FormControlLabel value="Accepter" control={<Radio checked={inputField['statut'] == 'Accepter'} color="success" />} label="Accepter" />
                                <FormControlLabel value="Refuser" control={<Radio checked={inputField['statut'] == 'Refuser'} color="success" />} label="Refuser" />
                                <FormControlLabel value="Initialiser" control={<Radio checked={inputField['statut'] == 'Initialiser'} color="success" />} label="Initialiser"/>
                                <FormControlLabel value="Suspendu" control={<Radio checked={inputField['statut'] == 'Suspendu'}  color="success"/>} label="Suspendu"/>
                                <FormControlLabel value="Cloturer" control={<Radio checked={inputField['statut'] == 'Cloturer'} color="success" />} label="Cloturer"/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="etat-group-label">Etat</FormLabel>
                            <RadioGroup row aria-labelledby="etat-group-label"name="etat" onChange={handleChange}>
                                <FormControlLabel value="En attente" control={<Radio checked={inputField['etat'] == 'En attente'} color="success" />} label="En attente" />
                                <FormControlLabel value="En cours" control={<Radio checked={inputField['etat'] == 'En cours'} color="success" />} label="En cours" />
                                <FormControlLabel value="Terminé" control={<Radio checked={inputField['etat'] == 'Terminé'} color="success" />} label="Terminé" />
                            </RadioGroup>
                        </FormControl>
                        <FormGroup>
                          <FormControlLabel name='notificationok' control={<Checkbox name='notificationok' onChange={handleChange} color="success" />} label="Envoyé une notification au concerné" />
                          
                        </FormGroup>
                        <div style={{margin:20}}></div>
                        <Card.Title>Offres associées</Card.Title>
                        <Table>
                            <tbody>
                                {(inputField.offres && inputField.offres?.length!==0)?(inputField.offres.map(offre=><tr>
                                  <td><Offreh offre={offre} /></td>
                                  <td valign='middle'><IconButton onClick={()=>handleDissociate(offre)}>
                                  <RemoveCircleOutlineIcon className={classes.iconsbtnDis} />
                              </IconButton></td>
                                  </tr>)):<tr>
                                    <td><p>Aucune offre associée, Vous pouver utiliser la recherche pour trouver une offre à associer à cette demande</p></td></tr>}
                            </tbody>
                        </Table>
                        </Card>
                        <Card direction='row' palign='space-between'>
                            <CustomForm.Button type='reset' >Annuler</CustomForm.Button> 
                            {inputField.offres.length!==0&&<CustomForm.Button type="submit" primary>Enregistrer</CustomForm.Button> }
                            
                        </Card>
                    </form>
                    </LocalizationProvider>
            </div>
            <Card style={{flex:1,marginLeft:40}}>
                <div>
                    <Typography variant='h5'>RECHERCHE D'UNE OFFRE</Typography>
                    <Paper className={classes.searchBox} variant='outlined'>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Rechercher ...(titre,description,partenaire,statut ...)"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onChange={(event)=>setInputSearch({...inputSearch,['search']:event.target.value})}
                            />
                            <div>
                            <Button variant="outline-success" id="button-addon2" className={classes.btnAdd} onClick={()=>handleSearch(1)}>
                                <SearchIcon />
                            </Button>
                            </div>
                        </InputGroup>
                    </Paper>
                </div>
                <Table>
                    <tbody>
                        {(offreList && offreList.length!==0)?offreList.map(offre=><tr key={offre.id}>
                            <td>
                                <Offrev offre={offre} readmore />
                            </td>
                            <td valign='middle'>
                              {((inputField.offres.findIndex(item=>item.id === offre.id)!== -1) ? (
                                <IconButton onClick={()=>handleDissociate(offre)}>
                                  <RemoveCircleOutlineIcon className={classes.iconsbtnDis} />
                              </IconButton>) :  <IconButton onClick={()=>handleAssociate(offre)}>
                                <AddCircleOutlineIcon className={classes.iconsbtn} />
                            </IconButton>)}
                           
                            </td>
                            </tr>) : <tr><td colSpan={2}><p>Aucune offre trouvée</p></td></tr>}
                    </tbody>
                </Table>
                {(offreList && offreList.length!==0 && paginateData)&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleDossierList(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                    </div>
                    }
            </Card>
          </div>
    </div>
  )
}

export default FichePropositionOffres
