import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Button, Form, InputGroup, Table } from 'react-bootstrap';
import { Divider, IconButton, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import  FolderIcon  from '@mui/icons-material/Folder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import swal from 'sweetalert';
import axios from 'lib/axios';
import * as XLSX from 'xlsx'
import Spinner from 'react-bootstrap/Spinner';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { getCiviliteFromSex, getTypeDemandeExport, splitName, splitPhoneNumer } from 'lib/application';
import dayjs from 'dayjs';
import { Item } from 'components/sidebar/styles/sidebar';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
   
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
const useStyles = makeStyles()(theme=>({
  btnAdd:{
    paddingLeft:20,
    paddingRight:20,
    backgroundColor: '#00B04B',
    color:'white',
    marginRight:20
  },
  searchBox:{
    display: 'flex',
    padding:20,
    marginBottom:20,
    flexDirection: 'column',
  },
}))

const ImportDossier = (props) => {
    const {open,handleClose}  = props
    const {classes} =useStyles()
    const [data,setData] = useState()
    const [nbimport,setNbimport] = useState(0)
    const [loading,setLoading] =useState(false)
    const [typedemandes,setTypedemandes] =  useState([])
    const handleTypeDemande = (demande)=>{
      const url = demande ? `/api/type-demande/list/${demande}` : `/api/type-demande/all`
       axios.get(url).then(res=>{
          if(res.status ===200 || res.status ===201){
            setTypedemandes(res.data)
          }
       })
    }
    const handleFileUpload =(e)=>{
      setLoading(true)
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString;
      //reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const bstr = e.target.result;
        const workbook = XLSX.read(bstr,{ type: rABS ? "binary" : "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet,{raw: false,defval:"",dateNF:'dd/mm/yyyy'})
        setData(parsedData)
       
      }
      if (rABS) reader.readAsBinaryString(e.target.files[0]);
      else reader.readAsArrayBuffer(e.target.files[0]);
      setLoading(false)
    }
    useEffect(()=>{
      handleTypeDemande()
    },[])
    const handleSave =  ()=>{
      let dataSend =[]
      setLoading(true)
      let indice = 1

      if(data && data.length !==0){
        indice=1
        data.map(async(row,idx) => {
          let currentdata ={}

          const {nom,prenom} = splitName(row['Nom et  Prenoms'])
          let dateNaissance = (row['Date de naissance'] && row['Date de naissance']!=="") ? dayjs(row['Date de naissance']).format('YYYY-MM-DD') :  ((row['Age'] && row['Age']!=="")&&dayjs().subtract(row['Age'],'year').format('YYY-MM-DD'))
          if(row['DatedeNaissance'] && row['DatedeNaissance']!==""){
            dateNaissance=dayjs(row['DatedeNaissance']).format('YYYY-MM-DD')
          }
          const dateDossier = (row['Date'] && row['Date']!=="") ? dayjs(row['Date']).format('YYYY-MM-DD') :  ((row['Année'] && row['Année']!=="") ? dayjs(`01/01/${row['Année']}`).format('YYYY-MM-DD'):dayjs().format('YYYY-MM-DD'))
          
          currentdata['civilite'] = getCiviliteFromSex(row['Sexe'])
          currentdata['nom'] = nom
          currentdata['prenom'] = prenom
          currentdata['datenaissance'] = dateNaissance
          currentdata['lieunaissance'] = row['Lieu de naissance']
          //localisation
          currentdata['ville'] = "Abidjan"
          currentdata['commune'] = row['Commune'].toLowerCase()
          currentdata['quartier'] = row['Quartier']
          currentdata['cgqcgv'] = row['Secteur']
          currentdata['datedossier'] = dateDossier
          //contacts
          const phones = splitPhoneNumer(row['Contact'])
          currentdata['mobile1'] = phones['mobile1'] || ""
          currentdata['mobile2'] = phones['mobile2'] || ""
          currentdata['numerowhatsapp'] = phones['mobile1'] || ""
          currentdata['email'] = row['Email']
          currentdata['diplome'] = row['Diplôme']
          currentdata['niveauetude'] = row['Niveau']
          currentdata['activite'] = row['Activites']
          currentdata['secteuractivite'] = row['Activites']
          currentdata['nationalite'] = row['Nationalité']
          currentdata['nationalite2'] = row['Nationalité2']
          currentdata['annee'] = (row['Année'] && row['Année']!=="") ?row['Année'] : dayjs().format('YYYY')
          console.log(row['Pieces fournies']);
          currentdata['piecesfournies'] = (row['Pieces fournies'] && row['Pieces fournies']!=="")&&row['Pieces fournies'].split(',')
          currentdata['resteafournir'] = (row['Pieces restantes à fournir'] && row['Pieces restantes à fournir']!=="")&&row['Pieces restantes à fournir'].split(',')
          currentdata['decision'] = row['Decision definitive']
          currentdata['commentaires'] = row['Commentaires ']

          currentdata['parcours'] =JSON.stringify([
            {
              type:'formation',
              encours:false,
              datedebut:null,
              datefin:null,
              diplome:`${row['Diplôme']} - ${row['Formation']}`,
              etablissement:null,
              mission:null}
          ])
          //parcours
          //demandes
            if(row['Demande'] && row['Demande']!==""){

              const currentDemd = typedemandes.filter(item=> ((item.libelle.indexOf(row['Demande'].toLowerCase()) !== -1)))
              currentdata['demandes'] =(currentDemd && currentDemd.length!==0)&&JSON.stringify({service:currentDemd[0]['id'],priorite:'Normal',description:""})
            }

          //sauvegarde
          dataSend.push(currentdata)
          setNbimport(nbimport + 1)
        
            setNbimport(indice)
            indice ++
            if(dataSend && dataSend.length !==0 && (dataSend.length === data.length)){
              let formdata = new FormData()
             
              formdata.append('data',JSON.stringify(dataSend))
              axios.post('/api/dossier/import',formdata).then(res=>{
                if(res){
                  setLoading(false)
                  setData(null)
                  swal("Bien !","Importation terminée","success")
                }else{
                  setLoading(false)
                  swal("Ooops!","erreur lors de l'enregistrement","error")
                }
              }).catch(err=>{
                console.log(err);
                setLoading(false)
                swal("Ooops!","erreur lors de l'enregistrement","error")
              })
            }
        });


        
      }else{
        setLoading(false)
        swal("Ooops!","erreur lors de l'enregistrement","error")
      }
    }
  return (
    <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth='md'
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:20}}>
              <div style={{display:'flex', alignItems:'center'}}>
                <FolderIcon />
                <Typography variant='h5'>Importation de dossiers jeunes</Typography>
              </div>
            </div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div>
                    
                    <Paper className={classes.searchBox} variant='outlined'>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control size="lg" type="file" onChange={handleFileUpload} accept=".xlsx,xls" />
                        
                      </Form.Group>
                      <div style={{display:'flex',justifyContent:'center'}}>
                          
                            {loading&&<Spinner animation="border" />}
                            </div>
                    </Paper>
                </div>  
                <Divider />
                {(data && data.length!==0)&&(
                <div style={{display:'flex',padding:20,justifyContent:'space-between',alignItems:'center'}}> 
                <Chip label={`${data.length} Ligne(s)`} variant="outlined" />
                
                {(nbimport!==1)&&<Chip label={`${nbimport} / ${data.length} Ligne(s)`} variant="outlined" />}
               
                <Button disabled={loading} variant="outline-success" id="button-addon2" className={classes.btnAdd} onClick={handleSave}>
                              {loading?<Spinner />:"Enregistrer"} 
  </Button></div>)}
                {(data && data.length!==0)&&<Table striped bordered hover>
                  <thead>
                    <tr>
                      {Object.keys(data[1]).map((key)=>(
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row,index)=>(
                      <tr key={index}>
                        {Object.values(row).map((value,idx)=>(<td key={idx}>{value}</td>))}
                      </tr>
                    ))}
                  </tbody>
                </Table>}
                
          </DialogContent>
        </BootstrapDialog>
    </div>
  )
}

export default ImportDossier