import React,{useContext, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { IconButton, MenuItem, Paper, Typography } from '@mui/material'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import axios from 'lib/axios'
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs'
import {Divider} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Form as CustomForm } from 'components'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Pagination} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Offrev from 'containers/Offrev/Offrev'
import Offreh from 'containers/Offreh/Offreh'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { red } from '@mui/material/colors'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { sensEvenement,communes } from 'fixtures/data'
import Autocomplete from '@mui/material/Autocomplete';
import { CommonsContext } from 'context/CommonsContext'
const styles ={
    detContainer:{
      display:'flex',
      flexDirection:'row',
      flex:1,
      justifyContent:'center'
    },
  
    detItemLeft:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      alignItems:'flex-end',
      fontWeight:'bold',
      justifyContent:'flex-start',
     
    },
    detItemRight:{
      display:'flex',
      flexDirection:'column',
      alignItems:'flex-start',
      justifyContent:'flex-start',
      flex:1,
      marginLeft:20,
    },
    label:{
      fontStyle:'italic',
      fontWeight:'bold'
    },
    toolsbard:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    },
    logoContainer:{
      display: 'flex',
      alignItems:'center',
      justifyContent:'flex-start',
      flex:1,
    },
  }
const useStyles = makeStyles()((theme)=>({
    containBox:{
        display:'flex',
        justifyContent:'space-between'
    },
    searchBox:{
        display: 'flex',
        padding:20,
        marginBottom:20,
        flexDirection: 'column',
    },
    btnAdd:{
        paddingLeft:20,
        paddingRight:20,
        backgroundColor: '#00B04B',
        color:'white',
        marginRight:20
    },
    detaildossier:{
        display:'flex',
        flex:1,
        justifyContent:'space-between'
      },
      labelle:{
        fontStyle:'italic',
        fontWeight:'bold'
      },
      navLabel:{
        textTransform:'uppercase', 
        marginLeft:10,
        fontSize:14
      },
      titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
      },
      iconsbtn:{
        color:'#00B04B',
        '&:hover':{
          color:'#F29400'
        }
      },
      iconsbtnDis:{
        color:red[400],
        '&:hover':{
          color:'#F29400'
        }
      }
}))

function AddEvenement(props) {
  const location =useLocation()
    const navigate = useNavigate()
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    const {dossier} = location.state
    const {classes} = useStyles()
    const {handleCommonsdata} = useContext(CommonsContext)
    useEffect(()=>{
      handleCommonsdata('titre',"Dossier - Programmer un événement")
    },[])
    const [natures,setNatures] = useState([])
    const handleNature = ()=>{
        axios.get(`/api/nature/all`).then(res=>{
          if(res.status === 200 || res.status === 201){
            setNatures(res.data)
          }
        })
    }
    useEffect(()=>{
      handleNature()
    },[])
    const handleList =()=>{
      navigate(`/${portail}/dossiers`)
    }
  
      const [eventDossier,setEventDossier] = useState(null)
      const [paginateData,setPagnateData] = useState(null)
   
      const [inputField,setInputField] = useState({
        dossier:dossier.id,
        personne_id:dossier.jeune?.id,
        
      })
      const handleChange = (e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
      }
     
      
      const handleSubmit = (e)=>{
        e.preventDefault()
        if(inputField.nature_evenement_id && inputField.dateevenement && inputField.dossier){
          axios.post(`/api/evenement/add`,inputField).then(res=>{
            if(res.status===200 || res.status === 201){
              swal("Bien","Evénement programmé avec succès","success")
              handleList()
            }else{
              swal(`Ooops !`,"Erreur lors de l'enregistrement",'error')
            }
          }).catch(err=>{
            console.log(err)
            swal("Ooops","Erreur lors de l'enregistrement",'error')
          })
        }
      }

    const handeleAutoCompleteChange =(name,values)=>{
        setInputField({...inputField, [name]:values})
    }
    const handleEventDossier = (numberPage)=>{
      axios.get(`/api/dossier/evenement/${dossier.id}?page=${numberPage}`).then(res=>{
          if(res.status === 200 || res.status === 201){
            setEventDossier(res.data.data)
            setPagnateData({
              current_page:res.data.meta?.current_page,
              last_page:res.data.meta?.last_page,
              per_page:res.data.meta?.per_page,
              total:res.data.meta?.total
            })
          }
        })
    }
    useEffect(()=>{
      handleEventDossier()
    },[])
  return (
    <div>
      <Card>
          <div style={{display:'flex',flexDirection:'column'}} >
              <div style={styles.logoContainer}>
             
                <div className='flex flex-col ml-2'>
                    <Typography variant='h2'>{`${dossier.jeune?.nom} ${dossier.jeune?.prenom}`}</Typography> 
                    <div className='flex flex-row gap-3 flex-wrap'>
                        <div className={classes.left}>
                            <div><span className={classes.labelle}>Age : </span>{}</div>
                            <div><span className={classes.labelle}>N° Dossier : </span> <Typography variant='subtitle'>{dossier['code']||' '}</Typography></div>
                            <div><sapn className={classes.labelle}>Créer le :</sapn><span>{dayjs(dossier.created_at).format('DD/MM/YYYY')}</span></div>
                        </div>  
                        <div className={classes.midlle}>
                            <div><span className={classes.labelle}>Commune : </span>{(dossier.jeune?.commune!=='null')&&dossier.jeune?.commune}</div>
                            <div><span className={classes.labelle}>Quartier : </span><span>{dossier.jeune?.quartier}</span> </div>
                            <div><sapn className={classes.labelle}>CGQ/CGV :</sapn><span>{dossier.jeune?.cgqcgv}</span></div>
                        </div>  
                        <div className={classes.right}>
                            <div><span className={classes.labelle}>Statut : </span>{dossier['statut'] || ""}</div>
                            <div><span className={classes.labelle}>Etape de traitement : </span> {dossier['etape']}</div>
                        </div>  
                    </div>
                  </div>
              </div>
            </div>
            <Divider />
          </Card>
        
          <div className='grid grid-cols-1 md:grid-cols-8'>
            <div className='col-span-1 md:col-span-5' style={{flex:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <form onSubmit={handleSubmit} >    
                    <Card style={{flex:2}}>
                        <Card.Header>  
                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                              <Card.Title>Programmer un événement</Card.Title>
                              <CalendarMonthIcon sx={{fontSize:40}} />
                            </div>
                            <Card.Hr />
                        </Card.Header>
                        <CustomForm.Group direction ='row' flex={1}>
                            <DesktopDatePicker
                                label="Date de l'évenement"
                                inputFormat="dd/MM/yyyy"
                                value={inputField['dateevenement'] || ""}
                                name='dateevenement'
                                onChange={(val)=>setInputField({...inputField,['dateevenement']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                          
                        </CustomForm.Group>
                        <CustomForm.Group>
                            <CustomForm.Input 
                                name='nature_evenement_id' 
                                size="small" 
                                margin="dense" 
                                sx={{ width: '100%' }}
                                style={{marginRight:20}}
                                select 
                                value ={inputField['nature_evenement_id']||""} 
                                onChange={handleChange}  label="Nature">
                                {(natures && natures!==0)&& natures.map(nature=>(<MenuItem  value={nature.id}>{nature.libelle}</MenuItem>))}
                            </CustomForm.Input>
                            <Autocomplete
                                multiple
                                freeSolo
                                id="experience-standard"
                                fullWidth
                                options={sensEvenement}
                                onChange={(e,values)=>handeleAutoCompleteChange("sens",values)}
                                renderInput={(params) => (
                                  <CustomForm.Input
                                    {...params}
                                    name='sens'
                                    label="Sens événement"
                                    placeholder="Niveau d'experience"
                                    size="small" margin="dense" wd='100%'
                                  />
                                )}
                              />
                        </CustomForm.Group>
                        
                        <CustomForm.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                          <Autocomplete
                                multiple
                                freeSolo
                                id="secteur-standard"
                                fullWidth
                                options={communes}
                                onChange={(e,values)=>handeleAutoCompleteChange("lieu",values)}
                                renderInput={(params) => (
                                  <CustomForm.Input
                                    {...params}
                                    name='lieu'
                                    label="Lieu"
                                    placeholder="Lieu"
                                    size="small" margin="dense" wd='100%'
                                  />
                                )}
                              /> 
                              <CustomForm.Input name='intervenant' value={inputField.intervenant} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Intervenant" wd='100%' />
                          </CustomForm.Group>
                          <CustomForm.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                            <CustomForm.Label>Commentaire</CustomForm.Label>
                                <ReactQuill  placeholder={"Commentaires"} name='profils' theme="snow" value={inputField.commentaire} onChange={(v)=>setInputField({...inputField,['commentaire']:v})} />
                          </CustomForm.Group>
                        <FormGroup>
                          <FormControlLabel name='notificationok' control={<Checkbox name='notificationok' onChange={handleChange} color="success" />} label="Envoyé une notification au concerné" />
                          
                        </FormGroup>
                        <div style={{margin:20}}></div>
                      
                        </Card>
                        <Card direction='row' palign='space-between'>
                            <CustomForm.Button type='reset' >Annuler</CustomForm.Button> 
                            {(inputField.dateevenement && inputField.sens)&&<CustomForm.Button type="submit" primary>Enregistrer</CustomForm.Button> }
                            
                        </Card>
                    </form>
                </LocalizationProvider>
            </div>
            <Card className='col-span-1 md:col-span-3 mr-2' style={{flex:1,marginLeft:40}}>
                <div>
                    <Typography variant='h5'>Liste des événements</Typography>
                </div>
                <Table>
                    <tbody>
                        <tr><td colSpan={2}><p>Aucune offre événements</p></td></tr>
                    </tbody>
                </Table>
                {(eventDossier && eventDossier.length!==0 && paginateData)&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleEventDossier(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                    </div>
                    }
            </Card>
        </div>
    </div>
  )
}

export default AddEvenement