import React,{useState,useEffect, useCallback} from 'react'
import HomeIcon from '@mui/icons-material/Home'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import VibrationIcon from '@mui/icons-material/Vibration';
import { Link } from 'react-router-dom';
import "./Fheader.scss"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth } from 'hooks/auth'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'lib/axios';
function Fheader({user}) {
    const { logout } = useAuth()
    const [active, setActive] = useState(false);
  
    const [dispositifs,setDispositifs] = useState(null)
    const isActive = () => {
        window.scrollY > 0 ? setActive(true) : setActive(false);
      };
    
      useEffect(() => {
        window.addEventListener("scroll", isActive);
        return () => {
          window.removeEventListener("scroll", isActive);
        };

      }, []);
      
const [typeOffres,setTypeOffres] = useState(null)
const handleTypeOffre = useCallback(()=>{
    axios.get(`/api/categorie-offre/list?page=1`).then(res=>{
        if(res.status === 200 || res.status === 201){
            setTypeOffres(res.data.data)
        }
    })
},[])

useEffect(()=>{
    handleTypeOffre()
  },[])
      
  return (
    <div className={active? "Fheader active" : "Fheader"}>
    <div className="container-fluid px-5 d-none d-lg-block">
        <div className="row gx-5 py-3 d-flex align-items-center justify-content-between" >
            <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-start">
                    <div style={{width:100,borderRadius:20,marginRight:20}}>
                        <img style={{width:100,borderRadius:10,objectFit: 'contain'}} src={require('../../../../assets/logo.jpg')} title='Logo pfs port-bouet' alt='Logo pfs port-bouet' />
                    </div>
                    <VibrationIcon className="fs-1 text-primary me-2"/>
                    <h3 className="mb-0">+225 07 59 99 34 64</h3>
               </div>
            </div>
            <div className={user?"col-lg-4":"col-lg-5"}>
                <div className="d-flex align-items-center justify-content-center">
                    <Link to="/" className="navbar-brand ms-lg-5">
                        <h1 className="m-0 display-4 text-primary">
                            <span className="text-secondary">PFS-</span>PORT-BOUET
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="d-flex align-items-center justify-content-end">
                    <Link className="btn btn-primary btn-square rounded-circle me-2" to="#"><TwitterIcon /></Link>
                    <Link to="https://www.facebook.com/pfsportbouet" className="btn btn-primary btn-square rounded-circle me-2"><FacebookIcon /></Link>
                    <Link className="btn btn-primary btn-square rounded-circle me-2" to="#"><LinkedInIcon /></Link>
                </div>
            </div>
            {user&&<div className="col-lg-1">
                <Dropdown>
                    <Dropdown.Toggle className="btn btn-secondary btn-square rounded-circle">
                        <AccountCircleIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1"><DashboardIcon sx={{marginRight:2}}/>Acceder à mon compte</Dropdown.Item>
                    
                        <Dropdown.Item onClick={()=>logout()}>
                            <PowerSettingsNewIcon sx={{marginRight:2}} />Se deconnecter
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                

            </div>}
            
        </div>
    </div>
    <nav className="navbar navbar-expand-lg bg-primary navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-5">
    <Link to="/" className="navbar-brand d-flex d-lg-none">
        <h1 className="m-0 display-4 text-secondary"><span className="text-white">PFS-</span>PORT-BOUET</h1>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav mx-auto py-0">
            <Link to="/" className="nav-item nav-link active"><HomeIcon sx={{fontSize:30}} /></Link>
            <Link to="/a-propos" className="nav-item nav-link">A propos</Link>
            <Link to="/actualites" className="nav-item nav-link">Actualités</Link>
            <Link to="/organes" className="nav-item nav-link">Organes</Link>
            <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Offres</Link>
                <div className="dropdown-menu m-0">
                {(typeOffres && typeOffres.length!==0)&&typeOffres.map(typeoffre=><Link key={typeoffre.id} to={`/offres/${typeoffre.id}`} className="dropdown-item">{typeoffre.libelle}</Link>)}
                    
                    <Link to="/offres/autres" className="dropdown-item">Autres offres</Link>
                </div>
            </div>
            <Link to="/partenaires" className="nav-item nav-link">Partenaires</Link>
            <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Médiathèque</Link>
                <div className="dropdown-menu m-0">
                    <Link to="/mediatheque/images" className="dropdown-item">Images</Link>
                </div>
            </div>
            
            <Link to="/contact" className="nav-item nav-link">Contact</Link>
        </div>
    </div>
</nav>
</div>
  )
}

export default Fheader