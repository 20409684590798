import React from "react";
import {Container,Header,Title,Hr} from './styles/card'

export default function Card({children,...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}

Card.Header = function CardHeard({children,...restProps}){
    return (<Header {...restProps}>{children}</Header>)
}

Card.Title = function CardTitle({children,...restProps}){
    return (<Title {...restProps}>{children}</Title>)
}

Card.Hr = function CardHr({...restProps}){
    return (<Hr {...restProps} />)
}