import React,{useState} from 'react'
import {AuthSessionStatus, AuthValidationErrors, Homer} from 'components'
import logo from "assets/logo.jpg"
import { useAuth } from 'hooks/auth'
import { Button } from 'react-bootstrap';
import { Paper } from '@mui/material';

const ForgotPassword = () => {
  const { forgotPassword } = useAuth({ middleware: 'guest' })

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const submitForm = event => {
    event.preventDefault()
    forgotPassword({ email, setErrors, setStatus })
  }
  return (
    <Homer>
       <Homer.Frame flex={0.3} style={{marginBottom:10}} >
            <Homer.Inner>
              <Homer.Link to="/">
                  <div style={{position:'absolute',borderRadius:5,width:80}} >
                    <img src={logo} alt='Logo' style={{width:250,borderRadius:10,objectFit: "fill",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} />
                  </div>
              </Homer.Link>
               
            </Homer.Inner>
        </Homer.Frame>
        <Homer.FGradient flex={2}>
                <Homer.Inner salign='center' className="w-min-[600px]">
                   <Paper elevation={5} className='p-4 rounded-lg flex flex-col justify-center items-center flex-1'>
                        <h1 className='p-4 text-green-800'>Réinitialiser votre mot de passe</h1>
                        <div className="mb-4 text-sm text-gray-600 text-[16px] flex flex-wrap">
                            Vous avez oublié votre mot de passe? Aucun problème. Faites-nous savoir votre
                            adresse e-mail et nous vous enverrons un lien de réinitialisation du mot de passe
                            cela vous permettra d'en choisir un nouveau.
                        </div>
                                {/* Session Status */}
                        <AuthSessionStatus className="mb-4" status={status} />
                        {/* Validation Errors */}
                        <AuthValidationErrors className="mb-4" errors={errors} />
                        <form onSubmit={submitForm}>
                        {/* Email Address */}
                        <div>
                            <label htmlFor="email">Adresse email</label>
                            <Homer.Input
                                id="email"
                                type="email"
                                name="email"
                                placeholder ="Adresse email"
                                value={email}
                                className="block mt-1 w-full"
                                onChange={event => setEmail(event.target.value)}
                                required
                                autoFocus
                            />
                        </div>
                        <div className="flex items-center justify-end mt-4">
                            <Button type="submit">Envoyez moi un lien de réinitialisation</Button>
                        </div>
                        </form>
                   </Paper>
                </Homer.Inner>
        </Homer.FGradient>
        <Homer.Frame flex={0.3} style={{marginTop:10}}>
            <Homer.Inner  palign="center">
                    Copyright &#169; { new Date().getFullYear() } - Tous droits réservés Mairie de Port-Bouet
            </Homer.Inner>
        </Homer.Frame>
    </Homer>
  )
}

export default ForgotPassword