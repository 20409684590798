
import React, { useEffect,useContext } from 'react'
import Dash from 'components/dash'
import {CommonsContext} from 'context/CommonsContext';

const ListeDossierAffecter = () => {
    const {handleCommonsdata} = useContext(CommonsContext)
    useEffect(()=>{
        
        handleCommonsdata('titre','Gestion des dossiers - dossiers affectés par connseiller')
      },[])
  return (
    <Dash>
      <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,marginRight:20,padding:10}}>
        
      </Dash.Frame>
    </Dash>
  )
}

export default ListeDossierAffecter