import { CommonsContext } from 'context/CommonsContext'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button, Chip, CircularProgress, Divider, IconButton, Paper, Tooltip } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Form } from 'components'
import axios from 'lib/axios'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustumModal from 'components/CustumModal'
import  MaterialReactTable  from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert'
import { dispositiSortie } from 'fixtures/data'
import { useSelector } from 'react-redux'

const FicheSortieDossier = () => {
  const navigate =useNavigate()
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const handlelist = ()=>{
        navigate(`/${portail}/dossiers`)
    }
    const {handleCommonsdata} = useContext(CommonsContext)
    useEffect(()=>{
      handleCommonsdata('titre',"Dossier - Saisir une sortie")
    },[])

    const [loading,setLoading] = useState(false)
    const [sloading,setSloading] = useState(false)
    const [inputfield,setInputField] = useState({})
    const handleChange =(e)=>{
      const {name,value} = e.target
      setInputField({...inputfield,[name]:value})
    }
    const location =  useLocation()
    const [dossier,setDossier] =  useState(location.state?.dossier)
    const handleEditorChange =(field,value)=>{
      setInputField({...inputfield,[field]:value});
    }
    const [dossierList,setDossierList] = useState()

    const handleGetDossier = ()=>{
      axios.post(`/api/dossier/parametrer`,{statut:['Actif','Suspendu']}).then(res=>{
        setLoading(true)
        if(res.status ===200 || res.status===201){
          setDossierList(res.data)
          setLoading(false)
          handlelist
        }else{
          setLoading(false)
        }
      }).catch(err=>{
        console.log(err);
        setLoading(false)
      })
    }
    const [open,setOpen] = useState(false)
    const handleClose =(e,reason)=>{
      if (reason && reason === "backdropClick") 
          return;
      setOpen(false)
    }
    const handleOpen =()=>{
      setOpen(true)
    }
    useEffect(()=>{
      handleGetDossier
    },[])
    const table = {
      columns:useMemo(()=>[
        { accessorFn:row=>(<div className='flex flex-col'>
            <h6>{`${row.jeune.nom} ${row.jeune.prenom}`}</h6>
              <div><span className='mr-2 italic'>Né(e) le : </span><span>{dayjs(row.jeune.datenaissence).format('DD/MM/YYYY')}</span></div>
              <div><span className='mr-2 italic'> Dossier N°:</span><span>{row.code}</span></div>
             
              <div><span className='mr-2 italic'>Date dossier : </span><span>{dayjs(row.datedossier).format('DD/MM/YYYY')}</span></div>
            </div>
            ),
          header: 'Jeune'
        },
        { 
          accessorFn:row=>(
            row.demandes.map(dmd=>dmd.libelle)
          ),
          header: 'Demande' }
       
      ]),
      data:dossierList || [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    };
    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);
    const [selection,setSelection] = useState(null)
    const handleSelection =()=>{
      setSelection(tableInstanceRef.current?.getSelectedRowModel().rows?.map(r=>r.original))
      handleClose()
    }
    useEffect(()=>{
      handleSelection()
    },[rowSelection])
   const handleAddDossier = ()=>{
      let newDossier =[]  
    if(dossier && Array.isArray(dossier)){
      newDossier = cmerge(dossier, selection, 'code')
    }else{
      if(selection.findIndex(item=>item.code === dossier.code) !==-1){
        newDossier =[...selection]
      }else{
        newDossier =[dossier,...selection]
      }
      
    }
    setDossier(newDossier)
   }
  
   const handleRemove=(row)=>{
    setDossier(dossier.filter(item=>item.code!==row.code))
   }
   //
   const handleSave =(e)=>{
    e.preventDefault();
    e.stopPropagation()
  
    setSloading(true)
    const dataSend = {
      datesortie:inputfield.datesortie,
      motif:inputfield.motif,
      observation:inputfield.observation,
      dossiers:Array.isArray(dossier) ? dossier : [dossier]
    }

    axios.post(`api/dossier/sortir/dossier`,dataSend).then(res=>{
      if(res.status === 200 || res.status === 201){
        setSloading(false)
        swal("Bien !","Sortie effectuée avec succès","success")
      }else{
        setSloading(false)
        swal("Ooops!","Une erreur s'est produite lors l'opération","error")
      }
    }).catch(err=>{
      if(err.hasOwnProperty('response')){
        setSloading(false)
        console.log(err.response.data);
        swal("Ooops!",err.response.data.message,"error")
      }else{
        setSloading(false)
        swal("Ooops!","Une erreur inconnu s'est produite lors de l'opération","error")
        console.log(err);
      }
    })
   }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustumModal 
        open={open} 
        handleClose={handleClose} 
        titre="Selectionnez un dossier dans la liste" 
        width='md'
        actions={
          <div className='flex flex-row justify-between items-center py-3 mx-2'>
            <Button className='px-4 py-2 bg-orange-400 text-white font-medium' onClick={handleClose}>Annuler</Button>
            <Button type='button' onClick ={handleAddDossier} disabled={!(selection && selection.length!==0)} className='px-4 py-2 bg-green-500 hover:bg-orange-400 text-white font-medium'>Ajouter</Button>
          </div>
        }
      >
        {!loading ?<MaterialReactTable 
          columns={table.columns} 
          data={table.data}
          enableRowSelection={true}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePinning
          enableRowActions={false}
          enableSelectAll={true}
          localization={MRT_Localization_FR}
          muiSelectCheckboxProps={{
            color: 'secondary', //makes all checkboxes use the secondary color
          }}
          tableInstanceRef={tableInstanceRef}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
        />:<CircularProgress />}
      </CustumModal>
    <div className='my-4'>
        <div className='rounded shadow-lg bg-white md:max-w-screen-sm max-w-md mx-auto '>
          <div className=' flex flex-row justify-end items-center p-2 bg-green-200 rounded-s-md'>
            <Tooltip title='Completer la liste des dossiers'>
              <IconButton className='group' onClick={handleOpen}>
                <AddCircleIcon className='text-green-500 text-[40px]' />
              </IconButton>
            </Tooltip>
          </div>
          {
            (dossier && Array.isArray(dossier)) ?(dossier.map(doss=>(
              <div className='flex flex-col relative'>

             
              <div className='flex flex-row justify-between bg-green-100 p-2 group'>
                <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{doss['code'] || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(doss['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(doss['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{doss['objet'] || ""}</span></div>     
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{doss['statut'] || ""}</span></div>     
                </div>
                <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{doss['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{doss['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(doss['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{doss['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{doss['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{doss['jeune']['quartier'] || ""}</span></div>     
                </div>
              
          </div>
            
            <Divider  />
            <div className='bg-[#000000a8] absolute flex flex-row justify-center items-center  left-0 top-0 bottom-0 right-0'>
                <IconButton onClick={()=>handleRemove(doss)}>
                  <DeleteIcon />
                </IconButton>
            </div>
           </div>
            ))):(
              
              <div className='flex flex-row justify-between bg-green-100 p-2'>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{dossier['code'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(dossier['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(dossier['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{dossier['objet'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{dossier['statut'] || ""}</span></div>     
              </div>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{dossier['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{dossier['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(dossier['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{dossier['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{dossier['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{dossier['jeune']['quartier'] || ""}</span></div>     
              </div>
          </div>
            ) 
          }
          <div className='p-2'>
          <form onSubmit={handleSave}>
            <Paper className='my-4 p-2 pb-4'>
              
                  <Autocomplete
                      disablePortal
                      id="motif"
                      size="small" 
                      margin="dense"
                      
                      options={dispositiSortie.sort() || []}
                      style={{marginRight:20,width: '100%'}}  
                      value={inputfield['motif'] ||""}
                      name='motif'
                      onChange={(e,v)=>setInputField({...inputfield,'motif':v})}
                      renderInput={(params) => <Form.Input {...params} name='motif' defaltValue='' label="Selectionnez un motif de sortie" wd='100%' />}
                  />
                   <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <DesktopDatePicker
                          required
                         
                          label="Date de la sortie"
                          inputFormat="dd/MM/yyyy"
                          value={inputfield['datesortie'] || dayjs().format('DD/MM/YYYY')}
                          name='datesortie'
                          onChange={(newvalue)=>setInputField({...inputfield,'datesortie':newvalue})}
                          renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      </Form.Group>
                    <Form.Group  margin='10px 0 20px 0'direction ='row' flex={1} >
                      <ReactQuill  
                          placeholder={"Observation"} 
                          style={{width:'100%'}}
                          name='observarion' 
                          theme="snow" value={inputfield.observation || ""} 
                          onChange={(v)=>handleEditorChange('observation',v)} 
                      
                      />
                    </Form.Group>
             
            </Paper>
            <Paper className='my-2 p-2'>
                <div className='flex flex-row justify-between items-center'>
                    <Form.Button type='reset' >Annuler</Form.Button> 
                    <Form.Button disabled={sloading} type="submit" primary>
                        {sloading ? <CircularProgress /> : "Enregistrer"} 
                    </Form.Button> 
                </div>
            </Paper>
            </form>
          </div>
        </div>
        
    </div>
    </LocalizationProvider>
  )
}

export default FicheSortieDossier