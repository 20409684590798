import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Divider, IconButton, Typography } from '@mui/material'

import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, Form, Row, Spinner, ToggleButton } from 'react-bootstrap'
import LoopIcon from '@mui/icons-material/Loop';
import axios from 'lib/axios'
import { AuthSessionStatus, AuthValidationErrors } from 'components'
function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    return (false)
}
const OffrePostuler = () => {
  const [searchParams] = useSearchParams();
  const {user}  =useAuth({ middleware: 'auth' })
  const {pathname,search} =useLocation()
  
  const [inputfield,setInputField] = useState({
    userExist:false,
    userChecked:false,
    email:"",
  })
  const [loading,setLoading] = useState(false)

const handleInputChange = (e)=>{
  const {name,value} = e.target
  setInputField({...inputfield,[name]:value})
} 
let inputRef = useRef()

const onCheckedUser =useCallback(() =>{
  setLoading(true)
  axios.get(`/api/users/check-exist/${inputfield.email}`).then(res=>{
    let cuField ={}
    if(res.status ===200  || res.status === 201){
      if(res.data[0] !==false){
        cuField ={"userExist":true,"userChecked":true}
        setLoading(false)
      }else{
        console.log(res);
        cuField ={"userExist":false,"userChecked":true}
        setLoading(false)
      }
      setInputField({...inputfield,...cuField})
    }else{
      console.log(res);
      cuField = {"userExist":false,"userChecked":true}
      setInputField({...inputfield,...cuField})
      setLoading(false)
    }
  }).catch(err=>{
    console.log(err);
    setInputField({...inputfield,"userExist":false,"userChecked":false})
    setLoading(false)
  })
},[inputfield.email])

useEffect(()=>{
  if(ValidateEmail(inputfield.email)){
    onCheckedUser()
  }
},[inputfield.email])

const { login } = useAuth({
  middleware: 'guest',
  redirectIfAuthenticated: `/${pathname}${search}`
}) 
const [errors, setErrors] = useState([])
const [status, setStatus] = useState(null)
const handleLogin =(event)=>{
  event.preventDefault();
  login({input_type:loginInput.input_type, password:loginInput.password, setErrors, setStatus })
}
const [offre,setOffre] =  useState(null)
const handleOffre = ()=>{
  axios.get(`/api/offre/${searchParams.getAll('ref')[1]}`).then(res=>{
    if(res.status ===200 || res.status ===201){
      setOffre(res.data)
    }
  })
}
useEffect(()=>{
  handleOffre()
},[])

  return (
    <div className='container lg:max-w-screen-sm md:max-w-screen- w-full pt-5 md:p-4 mx-auto' >
        <div className='row'>
          <div className='col-md-8 col-lg-8 col-sm-12 col-xs-12 mx-auto'>
            <Card className='p-4'>
              <Card.Title>
                <Typography variant='h2'>Vous postulez actuellement à l'offres N° : {offre&&offre['ref']}</Typography>
                <h1>Titre : {offre&&offre['titre']}</h1>
              </Card.Title>
              <Divider/>
          </Card>

          {user?(<>
            <Card>
                <Card.Title>Documents</Card.Title>

          </Card>
         <Card>
             <Card.Header>
              <Card.Title>Vos motivations</Card.Title>
            </Card.Header>
         </Card>  
          
         </>
         ):(
         
              <Card className='p-4 mt-3'>
                 <Card.Title className='bg-red-100 p-2 rounded-sm text-center mb-4 '>Vous devez être connecté pour poistuler à cette offre
              
                 </Card.Title>
                <form className='mt-5'>
                   {/* Session Status */}
                   <AuthSessionStatus className="mb-4" status={status} />
                            {/* Validation Errors */}
                            <AuthValidationErrors className="mb-4" errors={errors} />
                   <div className='col-lg-12 col-sm-12 mb-3'>
                      <label for="email" className="form-label">Votre adresse email</label>
                      <div className='d-flex gap-2'>
                        <input type="email" className={`form-control ${(inputfield.userChecked && !inputfield.userExist)&& "is-invalid"}`} ref={inputRef} onChange={(e)=>{
                          handleInputChange(e)
                         
                          }} value={inputfield['email'] || ""} name='email' id="email" placeholder="email@example.com" />
                       
                       {!inputfield.userExist&&<Button>
                          {loading ? <Spinner /> : <LoopIcon />} 
                          
                          
                        </Button>} 
                      </div>
                  </div>
                  {(inputfield.userChecked && inputfield.userExist)&&(
                  <div className='col-lg-12 col-sm-12 mb-3'>
                      <label for="password" className="form-label">Mot de passe</label>
                      <input type="password" className="form-control" onChange={handleInputChange} value={inputfield['password'] || ""} name='password' id="password" />
                  </div>)}

                 {(inputfield.userChecked && inputfield.userExist)&&<Button onClick={handleLogin} className='rounded-md' type='submit'> Se connecter</Button>}
                  


                  {(inputfield.userChecked && inputfield.userExist ==false)&&<div className='d-flex flex-column justify-content-center'>
                    <p className='font-bold text-red-500'>Veuillez vous inscrire à la plate de forme de services (PFS) pour bénéficier de cette offre et des milliers d'autres opportunités</p>
                  <Button className='rounded-md' type='button'> je m'inscris maintenant</Button>
                  </div>}
                  
                
                  </form>
              </Card>
            
          
         )}
          </div>
        </div>
         
    </div>
  )
}

export default OffrePostuler