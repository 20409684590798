import React from "react";
import {Container,Inner,Link,Texte,Button,Group,ButtonAdd,Input,LightButton} from './styles/Header'

export default function Header({children, ...restProps}) {
    return (<Container {...restProps}>{children}</Container>)
}

Header.Inner = function HeaderInner({children,...restProps}){
    return (<Inner {...restProps}>{children}</Inner>)
}


Header.Link = function HeaderLink({to,children,...restProps}){
    return (<Link to={to} {...restProps}>{children}</Link>)
}

Header.Texte = function HeaderTexte({children,...restProps}){
    return (<Texte {...restProps}>{children}</Texte>)
}


Header.Button = function HeaderButton({children,...restProps}){
    return (<Button {...restProps}>{children}</Button>)
}

Header.ButtonAdd = function HeaderButtonAdd({children,...restProps}){
    return (<ButtonAdd {...restProps}>{children}</ButtonAdd>)
}


Header.Group = function HeaderGroup({children,...restProps}){
    return (<Group {...restProps}>{children}</Group>)
}
Header.Input = function HeaderInput({...restProps}){
    return (<Input {...restProps} />)
}

Header.LightButton = function HeaderLightButton({children,...restProps}){
    return (<LightButton {...restProps}>{children}</LightButton>)
}
