import React from 'react'
import 'pages/frontend/bootstrap.min.css'
import 'pages/frontend/style.css'
import Fheader from 'pages/frontend/components/fheader/Fheader'
import FFooter from 'pages/frontend/components/ffooter/FFooter'
import {Outlet } from 'react-router-dom'
import { useAuth } from 'hooks/auth'

function SiteLayout() {
const {user}  =useAuth({ middleware: 'auth' })
  return (
    <div className='site'>
        <Fheader user={user}/>
          <Outlet />
        <FFooter />
        
    </div>
  )
}

export default SiteLayout
