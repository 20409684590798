import styled from "styled-components/macro";
import { Paper } from "@mui/material";
export const Container = styled.div`
    display:flex;
    flex-direction:column;
    min-height:100%
    
`
export const Inner = styled.div`
    display:flex;
    flex:${({flex})=>flex||1};
    border-radius:${({radius})=>radius||'0px'};
    background-color:${({bgcolor})=>bgcolor||"transparent"};
    flex-direction:${({direction})=>direction||'column'};
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
    margin-left:20px;
`
export const Frame = styled(Paper)`
    display:flex;
    flex:${({flex})=>flex||1};
    border-radius:${({radius})=>radius||'0px'};
    background-color:${({bgcolor})=>bgcolor||"#ffffff"};
    flex-direction:${({direction})=>direction||'column'};
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
    margin-left:20px;
`
export const MinCard = styled.div`
    background-color:#ffffff;
    padding:20px;
    border-left:5px solid ${({color})=>color || '#00B04B'};
    margin-right:20px
`
export const Card = styled.div``
export const Text = styled.h1`
    color:${({color})=>color || '#00B04B'};
    font-size:16px;
`
export const Label = styled.p`
    font-style:italic;
    font-size:14px;
    color:#00B04B;
`
export const SmallText = styled.p`
    color:#ffffff;
    font-weight:semi-bold;
`

export const ButtonAdd  = styled.button`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#00B04B;
    border:1px dashed #707070; 
    background-color:white;
    justify-content:center;
    align-items:center;
    padding:20px;
    cursor:pointer;
    font-size:18px;
    margin-right:20px;
    &:hover {
        background-color:#00B04B;
        color:white;
    }  
`
