import React,{useEffect, useState} from 'react'
import MenuItem from '@mui/material/MenuItem';
import { Card } from 'components'
import { Form } from 'components'
import { villes} from 'fixtures/data';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {CardActionArea} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components'
import { makeStyles } from 'tss-react/mui'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'custumstyle.css'
import { useAuth } from 'hooks/auth';
import axios from 'lib/axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddPersoRessource from './components/AddPersoRessource';
import AddOffresExpress from './components/AddOffresExpress';

const CType = styled.div`
  display:flex;
  background-color:${({type})=>type==='cdi'?"#00B04B":(type==='cdd'?"#9E9E9E":"#707070")};
  padding:5px;
  border-radius:5px;
  color:#ffffff;
  font-weight:bold;
  font-size:14px;
  margin-left:20px;
  margin-right:20px;
`
const Title =  styled.h2`
  font-size:16px;
  text-transform: uppercase;
  color:#00B04B;
  font-weight:bold;
  margin:10px 0px
`
const Texte = styled(Typography)`
  font-size:8px;
  color:#777777;
`
const useStyles = makeStyles()((theme=>{
    return {
        titlediv:{
            display:'flex',
            flex:1,
            justifyContent:'space-between',
            alignItems:'center'
        },
    content:{
        display:'flex',
        flex:1,
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column'
        }
   
    },
    content_form:{
        display:'flex',
        flexDirection:'column',
        width:'70%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_diag:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_img:{
        display:'flex',
        flexDirection:'column',
        minHeight:'100%',
        flex:1
    },
    photo_label:{
        fontSize:15,
        fontStyle:'italic',
        fontWeight:'bold'
    },
    logo_box:{
        display:'flex',
        justifyContent:'center',
        alignItems:'ceùnter',
        border:'1px dashed #707070',
        minHeight:'85%',
        background: 'rgba(244,244,244,0.6)',
        position:'relative',
    },
    photo_check:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    input: {
        display: "none"
      },
    img_card:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    boxOffres:{
        display:'flex',
        flexWrap:'wrap',
        flex:1,
        padding:20
    },
    cardOffres:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        '&:hover':{
            backgroundColor:'#00B04B',
            color:'white',
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        }
    },
    cardOffresCheck:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        backgroundColor:'#00B04B',
        color:'white',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

    }
};
}
))


function AddPartenaireComplet(props) {
    const {classes} = useStyles()
    const {user} = useAuth({middleware:'auth'});
    const navigate = useNavigate()
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const [errors,setErrors] =React.useState([])
    const [openR, setOpenR] =React.useState(false)
    const [loading,setLoading] = useState(false)
    const handleOpenR =()=>{
        setOpenR(true)  
    }
    const handleCloseOpenR =()=>{
        setOpenR(false)  
    }
    const [openO, setOpenO] =React.useState(false)
    const handleOpenO =()=>{
        setOpenO(true)
    }
    const handleCloseOpenO =()=>{
        setOpenO(false)  
    }
   const initializ = {
      raisonsociale:null,
      formejuridique:null,
      activitesprincpale:null,
      secteuractivite:null,
      logo:null,
      capital:null,
      comptecontribuable:null,
      numerorc:null,
      telephone:null,
      siteweb:null,
      adressemail:null,
      ville:null,
      personnesressources:[],
      offres:[],
      evenements:[],
      description:null,
      mobile:null,
      mobile1:null
   }
 
    let currentDate = new Date()
    const [currentField,setCurrentField] = useState(initializ);

    //uploade photo
    const [logostaut,setLogostaut] = React.useState({
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null
    })
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: files => {
            setLogostaut({
                mainState: "uploaded",
                selectedFile: files.map((file) => Object.assign(file, {
                    preview:  URL.createObjectURL(file)
                  })),
                imageUploaded: 1
            });
        }
    });


    const imageResetHandler = event => {
        setLogostaut({
          mainState: "initial",
          selectedFile: null,
          imageUploaded: 0
        });
        event.stopPropagation()
    };
      
    useEffect(() => {
        if(logostaut.hasOwnProperty('selectedFile') && logostaut.selectedFile){
           return ()=>logostaut.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
        }
    }, [logostaut]);

    const thumbs = (logostaut.hasOwnProperty('selectedFile') && logostaut.selectedFile)&&logostaut.selectedFile.map(file => (
        <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
            <img width="100%" className={classes.media} src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }}/>
        </CardActionArea>));
      //redirection sur la liste
        const handleList = ()=>{
            navigate(`/${portail}/partenaires`)
        }
        //resset field
        const handleReset =()=>{
            setCurrentField(initializ)
        }
    //      submit
    const handleSubmit =(event)=>{
        event.preventDefault();
        if(currentField['raisonsociale']){
            const formdata = new FormData()
            const {
                offres,
                personnesressources,
                evenements,
                capital,
                ...resInput} = currentField
            for (const key in resInput) {
                formdata.append(`${key}`,`${resInput[key]}`)
            }

            formdata.append('offres',JSON.stringify(currentField.offres))
            formdata.append('capital',capital.replace(/\s/g, ""))
            formdata.append('personnesressources',JSON.stringify(currentField.personnesressources))
            formdata.append('logo',logostaut.selectedFile[0])
            
            axios.post(`/api/partenaire/add`,formdata).then(res=>{
                if(res.status === 200 || res.status ===201){
                    setLoading(false)
                    swal('Bien !',"Partenaires ajouter avec succès","success")
                    handleReset()
                    handleList()
                }else{
                    setLoading(false)
                    console.log(res.data)
                    swal("Ooops !","Une erreur s'est produit lors de l'opération",'error')
                }
            }).catch(err=>{
                console.log(err.response)
                if(res.response.hasOwnProperty('data')){
                    setErrors(res.response.data?.errors)
                }
                setLoading(false)
                swal("Ooops !","Une erreur s'est produit lors de l'opération",'error')
            })
        }
        
    }
    //set field
    const handleChange = (event) => {
        const {name,value} =event.target
        setCurrentField({...currentField,
            [name]:value
        })
      };
    
      
      const handleSetPersonneRessource =(data)=>{
        data['id'] = currentField['personnesressources'].length
        const newData =[data,...currentField['personnesressources']]
        setCurrentField({...currentField,
            ['personnesressources']:newData
        })
      }
      const handleSetOffres =(data)=>{
        data['id'] = currentField['offres'].length
        const newData =[data,...currentField['offres']]
        setCurrentField({...currentField,
            ['offres']:newData
        })
      }
      

      //supression des de personnes resources
      const handleDeletePerso =(id)=>{
        const newPerso = currentField.personnesressources.filter(item=>item.id!==id)
        setCurrentField({...currentField,
            ['personnesressources']:newPerso
        })
      }
      //supression de l'offres
      const handleDeleteOffres =(id)=>{
        const newOffer = currentField.offres.filter(item=>item.id!==id)
        setCurrentField({...currentField,
            ['offres']:newOffer
        })
      }
  return (
    
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
          <AddPersoRessource handleClose={handleCloseOpenR} open={openR} handleSetData={handleSetPersonneRessource}/>
          <AddOffresExpress handleClose={handleCloseOpenO} open={openO} handleSetData={handleSetOffres}/>
         <Card>
         <Card.Header>
              <Card.Title>INFORMATIONS GENERALES</Card.Title>
              <Card.Hr />
          </Card.Header>
            <div className={classes.content}>
                    <div className={classes.content_form}>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                        <Form.Input   name='raisonsociale' value={currentField.raisonsociale} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Raison sociale" wd='100%' />
                    </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
              <Form.Input required size="small" name="formejuridique" margin="dense" onChange={handleChange} value={currentField['formejuridique']||""} select style={{marginRight:20}}  label="Forme juridique/Type">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='INDIVIDUEL'>INDIVIDUEL</MenuItem>
                        <MenuItem value='SARL'>SARL</MenuItem>
                        <MenuItem value='SA'>SA</MenuItem>
                        <MenuItem value='CONSULTANT'>CONSULTANT</MenuItem>
                        <MenuItem value='SOCIETE'>SOCIETE D'ETAT</MenuItem>
                        <MenuItem value='MINISTERE'>MINISTERE</MenuItem>
                        <MenuItem value='INSTITUTION'>INSTITUTION</MenuItem>
                        <MenuItem value='ONG'>ONG</MenuItem>
                        <MenuItem value='ASSOCIATION'>ASSOCIATION</MenuItem>
                    </Form.Input>
                    <Form.Input   name='capital' value={currentField.capital} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Capital" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='activitesprincpale' value={currentField.activitesprincpale} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Activité principale" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='comptecontribuable' value={currentField.comptecontribuable} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° Compte contribuable" wd='50%' />
                  <Form.Input   name='numerorc' value={currentField.numerorc} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° contribuable" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
              <Form.Input 
                    size="small" 
                    margin="dense" 
                    select style={{marginRight:20}}  
                    label="Ville"
                    value={currentField['ville'] ||""}
                    name='ville'
                    onChange={handleChange}
                    >
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.length!=0)&&villes.map(ville=>(<MenuItem key={ville} value={ville}>{ville}</MenuItem>))}
                  </Form.Input>
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='telephone' value={currentField.telephone} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° téléphone" wd='50%' />
                  <Form.Input   name='mobile' value={currentField.mobile} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Mobile" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input  type="email" name='adressemail' value={currentField.adressemail} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Email" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='siteweb' value={currentField.siteweb} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Site web" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <ReactQuill  
                    placeholder={"Description"} 
                    name='description' 
                    theme="snow" 
                    value={currentField.description} 
                    onChange={(v)=>setCurrentField({...currentField,['description']:v})} />
            </Form.Group>
                    </div>
                    <div className={classes.content_img}>
                        <Typography className={classes.photo_label}>Cliquez ou glissez déposer pour joindre le logo du parténaire</Typography>
                        <div className={classes.logo_box} {...getRootProps()}>
                        
                            {thumbs}
                       
                            <input
                                {...getInputProps()}
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                name='photo'
                                disabled={!logostaut.imageUploaded}
                                />
                                 {(logostaut.imageUploaded===0)&&
                            <label className={classes.photo_check} htmlFor="contained-button-file">
                                <AddAPhotoIcon sx={{fontSize:60,color:'#888888'}} />
                            </label>}
                        </div>
                    </div>
                </div>
        </Card>
        <Card>
         <Card.Header>
            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                <Card.Title>Personnes ressources</Card.Title>
              <IconButton aria-label="part-add" onClick={handleOpenR}>
                 <AddIcon />
                </IconButton>
            </div>
              
              <Card.Hr />
          </Card.Header>
          <div style={{display:'flex',flex:1, padding:20}}>
            {(currentField['personnesressources'].length!==0)&&<TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableBody>
                    {currentField.personnesressources.map((perso,indx)=>{
                        return <TableRow key={perso.id}>
                            <TableCell align="center">{perso['civilite'] || ""}</TableCell>
                            <TableCell align="left">{perso['nom'] || ""}</TableCell>
                            <TableCell align="left">{perso['prenom'] ||""}</TableCell>
                            <TableCell align="center">{perso['fonction'] || ""}</TableCell>
                            <TableCell align="left">{perso['telephone'] ||""}</TableCell>
                            <TableCell align="left">{perso['mobile'] ||""}</TableCell>
                            <TableCell align="left">{perso['email'] ||""}</TableCell>
                            <TableCell align="center">
                            <IconButton aria-label="delete" onClick={()=>handleDeletePerso(perso.id)}>
                                <DeleteIcon />
                            </IconButton>
                            </TableCell>
                    </TableRow>
                    })}
                    </TableBody>
                </Table>
                </TableContainer>}
          </div>
        </Card>
        <Card>
        <Card.Header>
            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
              <Card.Title>Offres</Card.Title>
              <IconButton aria-label="part-add" onClick={handleOpenO}>
                 <AddIcon />
                </IconButton>
              </div>
              <Card.Hr />
          </Card.Header>
          <div style={{display:'flex',flex:1, padding:20}}>
          {(currentField['offres'].length!==0)&&<TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableBody>
                    {currentField.offres.map((offre,indx)=>{
                        return <TableRow key={offre.id}>
                            <TableCell align="left">
                                <div style={{display:'flex',flexDirection:'column'}}>
                                    <Title>{offre['titre']}  - [{offre.categorie?.libelle}]</Title>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <Texte variant='caption'>{`Date publication : ${dayjs(offre['datedebu']).format('DD/MM/YYYY')}`} </Texte >
                                       
                                        <Texte variant='caption'>  <LocationOnIcon  disabled/>{offre['ville'] ||""}, Côte d'ivoire </Texte>
                                        <Texte variant='caption'> <AccessTimeIcon />{`Date de cloture : ${dayjs(offre['datefin']).format('DD/MM/YYYY')}`} </Texte></div>
                                    <Texte variant='body2'>{offre.description&&offre['description'].slice(0,150)+"..."}</Texte>
                                </div>
                            </TableCell>
                            <TableCell align="center"> <CType style={{textAlign:'center'}} type={offre['type']}>{offre['type']}</CType></TableCell>
                            
                            <TableCell align="center">
                            <IconButton aria-label="delete" onClick={()=>handleDeleteOffres(offre.id)}>
                                <DeleteIcon />
                            </IconButton>
                            </TableCell>
                    </TableRow>
                    })}
                    </TableBody>
                </Table>
                </TableContainer>}
          </div>
        </Card>
        
        <Card>
            <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Button onClick={handleReset} >Annuler</Form.Button>
                    <Form.Button onClick={handleSubmit} primary>Valider</Form.Button> 
            </Form.Group>
        </Card>
    </div>
  )
}

export default AddPartenaireComplet