import React, { useEffect, useState } from 'react'
import Dash from 'components/dash'
import {formatNumber,randomColor} from 'utils/utils'
import { Divider, Typography } from '@mui/material';
import Evolution from './Evolution';
import TauxTrtDemande from './TauxTrtDemande';
import "./styles.css"
import HeaderTitle from 'containers/HeaderTitle'
import GrDossier from './GrDossier';
import axios from 'lib/axios';


function DashDecisionnel() {
  const [hstate,setHstate]= useState(null)
  const handleState = ()=>{
    axios.get(`/api/state/home`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setHstate(res.data)
      }
    })
  }
  useEffect(()=>{
    handleState()
  },[])
  return (
    <Dash>
      <HeaderTitle />
      
      {hstate&&<Dash.Inner direction='row'>
        {hstate.map(e=>(
           <Dash.MinCard key={e.cible} style={{position:'relative'}}>
           <Dash.Label> {e.cible}</Dash.Label>
           <Dash.Text> {formatNumber(e['total'])}</Dash.Text>
           {(e.nouveau!==0)&&<div style={{backgroundColor:randomColor(),position:'absolute',top:0,right:-5,padding:5,borderRadius:20}}>
            <Dash.SmallText> {e['nouveau']||""}</Dash.SmallText>
            </div>}
           
         </Dash.MinCard>
        ))}
         
      </Dash.Inner>}
      <Dash.Inner direction='row' style={{marginTop:20,marginRight:20}}>
        <div>
           <Dash.Frame elevation={3} radius={0} style={{padding:20,marginLeft:0,marginBottom:20}}>
            <Typography variant='h5'>Taux de satisfaction des demandes</Typography>
              <Divider />
              <TauxTrtDemande />
          </Dash.Frame>
          <Dash.Frame elevation={3} radius={0} style={{padding:20,marginLeft:0}}>
              <Typography variant='h5'>Evolution des demandes</Typography>
              <Divider />
                <Evolution />
          </Dash.Frame>
        </div>

          <Dash.Frame elevation={3} salign='center' radius={0} style={{padding:20}}>
                <Typography variant='h5'>Dossiers</Typography>
                <Divider />
                <GrDossier />
          </Dash.Frame> 
      </Dash.Inner>
    </Dash>
  )
}

export default DashDecisionnel