import React from 'react'
import Fheader from '../components/fheader/Fheader'
import FFooter from '../components/ffooter/FFooter'
import Flink from '../components/Flink'
import ConnexionBox from '../components/ConnexionBox'
function Partenaires() {
  return (
    <>
       
        <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">Nos partenaires</h1>
                       <ConnexionBox />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row g-5 d-flex justify-content-center flex-wrap">

                    <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/marie-port-pouet.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Mairie de port-bouet</h5>
                        </div>
                    </div>
                
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/logo_aej_new.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Agence emploi jeunes</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/cropped-logoagefop-1.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Agence nationale de formation professionnelle</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/fns.jpg")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Fond nationale de solidarité</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/logo-fdfp.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">FDFP</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/cropped-Logo-Horizontal.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Mission locale du Havre</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/logo_fafede.jpg")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">Fondation FAFEDE</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/logo_moi_jeu_tri.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">ONG Moi jeu tri</h5>
                        </div>
                </div>
                <div className="col-lg-3 col-md-6 bg-white ms-4 d-flex flex-column justify-content-center">
                        <div className="mb-0">
                            <img className="img-fluid" src={require("../img/fondateur/logo_cerad.png")} alt="" />
                        </div>
                        <hr />
                        <div className="mb-3 text-center">
                            <h5 className="text-primary text-uppercase">CERAD</h5>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Partenaires