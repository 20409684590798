import { createContext, useCallback, useMemo, useState } from 'react';

 
import React from 'react'
export const CommonsContext = createContext(null)

const CommonsProvider = ({children}) => {

    const [commonsField,setCommons] = useState({titre:"Bienvenue sur la plate forme de service"})
    
    const handleCommonsdata = useCallback((name,value)=> {
        setCommons({...commonsField,[name]:value})
    },[])

    const contextValue = useMemo(() => ({
        commonsField,
        handleCommonsdata
    }), [commonsField, handleCommonsdata]);

  return (
    <CommonsContext.Provider value={contextValue}>
        {children}
    </CommonsContext.Provider>
  )
}

export default CommonsProvider