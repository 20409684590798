import React from "react";
import {Container,Frame,Inner,FGradient,Link,Title,Group,Input,Button,Select} from './styles/homer'

export default function Homer({children, ...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}

Homer.Frame = function HomerFrame({children,...restProps}){
    return (<Frame {...restProps}>{children}</Frame>)
}

Homer.Inner = function HomerInner({children,...restProps}){
    return (<Inner {...restProps}>{children}</Inner>)
}

Homer.FGradient = function HomerFGradient({children,...restProps}){
    return (<FGradient {...restProps}>{children}</FGradient>)
}

Homer.Link = function HomerLink({to,children,...restProps}){
    return (<Link to={to} {...restProps}>{children}</Link>)
}

Homer.Title = function HomerTitle({to,children,...restProps}){
    return (<Title to={to} {...restProps}>{children}</Title>)
}

Homer.Group = function HomerGroup({to,children,...restProps}){
    return (<Group to={to} {...restProps}>{children}</Group>)
}

Homer.Input = function HomerInput({to,children,...restProps}){
    return (<Input to={to} {...restProps} />)
}

Homer.Button = function HomerButton({to,children,...restProps}){
    return (<Button to={to} {...restProps}>{children}</Button>)
}

Homer.Select = function HomerSelect({children,...restProps}){
    return (<Select {...restProps}>{children}</Select>)
}