import styled from "styled-components/macro";
import {Link as RouterLink} from 'react-router-dom'
export const Container = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content: space-around;
    margin:0;
    height:100vh;
    color:white;
    background-color:#00B04B
`
export const Frame = styled.div`
   display:flex;
   width:100%;
   flex:${({flex})=>flex || 1};
   background-color:${({bgcolor})=>bgcolor || "#F29400"};
`
export const Inner =styled.div`
    display:flex;
    max-width:75%;
    margin:auto;
    flex:${({flex})=>flex || 1};
    flex-direction:${({direction})=>direction || "row"};
    justify-content:${({palign})=>palign || "flex-start"};
    align-items:${({salign})=>salign || "flex-start"};
    @media (max-width: 768px){
        max-width:100%;
        font-size:18px;
        flex-direction:column;
    }
`

export const FGradient =styled.div`
    display:flex;
    width:100%;
    flex:3;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(242,148,0,1) 100%, rgba(0,212,255,1) 100%);
    @media (@max-width:600px){
        flex-direction:column;
    }
    `

export const Link = styled(RouterLink)``
export const Logo = styled.img``
export const Group = styled.div`
    display:flex;
    flex:${({flex})=>flex || 1};
    flex-direction:${({direction})=>direction || "row"};
    justify-content:${({palign})=>palign || "flex-start"};
    align-items:${({salign})=>salign || "flex-start"};
`
export const Title = styled.h1`
    font-size: 50px;
    ligne-heigh:1.1;
    margin-top:0;
    margin-bottom:8px;
    color:white;
    text-align:center;
    
    @media (@max-width:600px){
        font-size:35px
    }
`
export const subTitle = styled.p``

export const Input  = styled.input`
    border-radius:10px;
    background-color:#F8F8F8;
    height:20px;
    border: none;
    margin-bottom: 20px;
    padding:20px;
    font-size:20px;
    border-bottom:2px solid #00B04B;
    &:focus {
        outline: none;
        box-shadow: 2px 2px 2px #00B04B;
    }
`
export const Button  = styled.button`
    display:flex;
    color:white;
    background-color:#00B04B;
    width:100%;
    margin-top:20px;
    margin-bottom:20px;
    justify-content:center;
    align-items:center;
    padding:20px;
    border-radius:10px;
    border:none;
    cursor:pointer;
    font-size:18px;
    &:hover {
        outline: none;
        box-shadow: 0px 0px 5px #00B04B;
    }  
`

export const ButtonAdd  = styled.button`
    display:flex;
    color:#00B04B;
    background-color:white;
    justify-content:center;
    align-items:center;
    padding:20px;
    border:none;
    cursor:pointer;
    font-size:18px;
    border:1px dashed ##F8F8F8; 
    &:hover {
        background-color:#00B04B;
        color:white;
    }  
`

export const Select  = styled.select`
    border-radius:10px;
    background-color:#F8F8F8;
    border: none;
    height:20px;
    border: none;
    margin-bottom: 20px;
    padding:20px;
    font-size:20px;
    &:focus {
        outline: none;
        box-shadow: 2px 2px 2px #00B04B;
    }
`