import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
const LoadingPage = () => {
  return (
    <div className='absolute w-full z-[999999] top-0 bottom-0 bg-[#0000005a] flex justify-center items-center'>
         <Spinner animation="grow" />;
    </div>
  )
}

export default LoadingPage