
import React, {  useEffect, useState,useContext } from 'react'
import Dash from 'components/dash'
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import Dossier from 'containers/Dossier'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import { Table as TableBoostrap } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import {Pagination} from "@mui/material";
import { Form, InputGroup,Button } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from 'tss-react/mui';
import PageviewIcon from '@mui/icons-material/Pageview';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DocumentsList from './modals/DocumentsList';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import swal from 'sweetalert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ModDemandeList from './modals/ModDemandeList';
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImportDossier from './modals/ImportDossier';
import {CommonsContext} from 'context/CommonsContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import { green } from '@mui/material/colors';
import LoadingPage from 'containers/LoadingPage';
import dayjs from 'dayjs';
import { getAge } from 'utils/utils';
import { exportationExelFromJson } from 'lib/application';
import CritereRechercheDossier from './modals/CritereRechercheDossier';
import DeleteIcon from '@mui/icons-material/Delete';
import ReinitialiserMotDePasse from './ReinitialiserMotDePasse';

const OfferHeader = styled.div`
display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
const BtnDetails =styled.button`
  background:transparent;
  padding:2px 2rem 0 2rem;
  border:1px solid #f8f8f8;
  display:flex;
  font-size:11px;
  flex-direction:column;
  border-radius: 0px 0px 4px 4px;
  flex:1;
  width:100%;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  &:hover{
    background-color:#00B04B;
    width:100%;
    color:white
  }
`
const useStyles = makeStyles()((theme)=>({
  searchBox:{
    display: 'flex',
    padding:20,
    marginBottom:20,
    flexDirection: 'column',
    backgroundColor:green[50]
  },
  btnAdd:{
      paddingLeft:20,
      paddingRight:20,
      backgroundColor: '#00B04B',
      color:'white',
      marginRight:20
    }
}))

const BtnMore = styled.button`
  background:transparent;
  padding:2px 2rem 0 2rem;
  border:1px solid #707070;
  font-weight:bold;
  border-radius:20px;
  &:hover{
    background-color:#00B04B;
    color:white;
    font-weight:300;
  }
`

function DosseierList() {
const [loading,setLoading] = useState(false)
const {classes} =useStyles()
const navigate = useNavigate()
const [fromSearch,setFromSearch] = useState(false)
const [dossierList,SetDossierList] =useState([])
const [paginateData,setPagnateData] = useState(null)
const portail = useSelector(state=>state.ui.portail).toLowerCase()
const {handleCommonsdata} = useContext(CommonsContext)
const [currentDossier,setCurrentSossier] = useState(null)
const location = useLocation()
const {titre,statut,etape} = location.state || {}
const handleDossierList = (numberPage = 1)=>{
  const dataSend={
    statut:statut ? [statut]:null,
    etape:etape ? [etape]:null
  }

  axios.post(`/api/dossier/list?page=${numberPage}`,dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
      SetDossierList(res.data.data)
      setPagnateData({
        current_page:res.data.meta?.current_page,
        last_page:res.data.meta?.last_page,
        per_page:res.data.meta?.per_page,
        total:res.data.meta?.total
      })
    }
  })
}
const handleActionsTop =(route)=>{
  navigate(route)
}
const [search,setSearch] = useState(null)
useEffect(()=>{
  handleDossierList()
  handleCommonsdata('titre',titre ? titre : 'Liste des dossiers')
},[])

const handleShowDossier =(data)=>{
  navigate(`/${portail.toLowerCase()}/dossiers/fiche/${data['id']}`,{replace:true,state:{data}})
}
const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

const [currentDocument,setCurrentDocument] =useState([])
const handlesetListDocument = (docs)=>{
  setCurrentDocument(docs)
  handleClickOpen()
}
const handleSearch = (numberPage=1)=>{
  setLoading(true)
  const url  =  (search && search!=='') ? `/api/dossier/list/${search}?page=${numberPage}` : `/api/dossier/list?page=${numberPage}`
  const dataSend={
    statut:statut ? [statut]:null,
    etape:etape ? [etape]:null
  }
  axios.post(url,dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
      SetDossierList(res.data.data)
      setPagnateData({
        current_page:res.data.meta?.current_page,
        last_page:res.data.meta?.last_page,
        per_page:res.data.meta?.per_page,
        total:res.data.meta?.total
      })
      setFromSearch(false)
      setLoading(false)
    }else{
      setLoading(false)
      setFromSearch(false)
      swal("Ooops !","Aucun dossier trouvé",'warning')
    }
  }).catch(err=>{
    setLoading(false)
    setFromSearch(false)
    console.log(err.response);
    swal("Erreur","Erreur lors de l'exécution de la requête",'error')
  })
}
const formatPhone =(mobile1,mobile2)=>{
  if(mobile1 && mobile2){
    let conct=""
    if(mobile1 !=='null' && mobile1 !=='undefined'){
      conct +=""+mobile1
    }
    if(mobile2 !=='null' && mobile2 !=='undefined'){
      if(conct === ""){
        conct +=""+mobile2
      }else{
        conct +="/"+mobile2
      }
       
    }
    return conct
  }else if((mobile1 && mobile1 !=='null' && mobile1 !=='undefined') && !mobile2){
    return mobile1
  }else if (!mobile1 && (mobile2 && mobile2 !=='null' && mobile2 !=='undefined')){
    return mobile2
  }else{
    return ""
  }

}
//exportation
const handleExport =()=>{
  const dataSend ={mode:'all',...searchInput}
  setLoading(true)
  axios.post(`/api/dossier/exporter`,dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
      const newArray = res.data.map((row,index)=>({
        "N°":index+1,
        "Nom & Prénoms": row.jeune.nom +" "+row.jeune.prenom,
        "Sexe":row.jeune.genre || "",
        "Contact":formatPhone(row.jeune.mobile1,row.jeune.mobile2),
        "Email":row.jeune.email || "",
        "Formation":row.jeune.parcours.map(item=>{
          if(item.type === "formation"){
            return item.diplome
          }
        }).join("/"),
        "Niveau d'etude":"",
        "Diplome":"",
        "Date de naissance":dayjs(row.jeune.datenaissance).format("DD/MM/YYYY"),
        "Age":getAge(row.jeune.datenaissance),
        "Commune":(row.jeune.commune && row.jeune.commune!=='null' && row.jeune.commune!=='undefined') ? row.jeune.commune : "",
        "Quartier":(row.jeune.quartier && row.jeune.quartier !=='null' && row.jeune.quartier !=='undefined') ? row.jeune.quartier : "",
        "Cgv/Cgq":(row.jeune.cgqcgv && row.jeune.cgqcgv !=='null' && row.jeune.cgqcgv !=='undefined') ? row.jeune.cgqcgv :"",
        "Demandes":row.demandes.map(item=>item.type.libelle).join("/")
      }))
      exportationExelFromJson(newArray,["N°","Nom & Prénoms","Sexe","Contact","Email","Formation","Niveau d'etude","Diplome","Date de naissance","Age","Commune","Quartier","Cgv/Cgq","Demandes"],"dossiers")
      setLoading(false)
    }else{
      setLoading(false)
      swal("Ooops !","Aucun dossier trouvé",'warning')
    }
  }).catch(err=>{
    setLoading(false)
    console.log(err);
    swal("Erreur","Erreur lors de l'exécution de la requête",'error')
  })
}
const [currentData,setCurrentData] = useState(null)
const [opendmd,setOpendmd] = useState(false)
const handleCloseDmd = ()=>{
  setOpendmd(false)
}
const handleOpenDmd = ()=>{
  setOpendmd(true)
}
const showMoreDemande = (data)=>{
  setCurrentData(data)
  handleOpenDmd()
}
const handleShowOffre =()=>{

}
const [openImport,setOpenImport] = useState(false)
const handleOpenImport = ()=>{
  setOpenImport(true)
}
const handleCloseImport = ()=>{
  setOpenImport(false)
}
const [anchorEl, setAnchorEl] = React.useState(null);
const openm = Boolean(anchorEl);
const handleClickm = (event,data) => {
  setCurrentSossier(data)
  setCurrentDocument(data.jeune.documents)
  setAnchorEl(event.currentTarget);
};
const handleClosem = () => {
  setAnchorEl(null);
};
const [openSearch,setOpenSearch] =  useState(false)
const handleOpenSearch = ()=>{
  setOpenSearch(true)
}
const handleCloseSearch = ()=>{
  setOpenSearch(false)
}
const [searchInput,setSearchInput] = useState({statut:[],search})
const handleSearchAvance =(numberPage=1)=>{
  setLoading(true)
  handleCloseSearch()
  axios.post(`/api/dossier/recherche?page=${numberPage}`,searchInput).then(res=>{
    if(res.status === 200 || res.status === 201){
      SetDossierList(res.data.data)
      setPagnateData({
        current_page:res.data.meta?.current_page,
        last_page:res.data.meta?.last_page,
        per_page:res.data.meta?.per_page,
        total:res.data.meta?.total
      })
      setFromSearch(true)
      setLoading(false)
    }else{
      setLoading(false)
      setFromSearch(false)
      swal("Ooops !","Aucun dossier trouvé",'warning')
    }
  }).catch(err=>{
    setLoading(false)
    setFromSearch(false)
    console.log(err.response);
    swal("Erreur","Erreur lors de l'exécution de la requête",'error')
  })
}

{/*Suppression definitive d'un dossier*/}
const suppressionDefinitive =()=>{
 if(currentDossier){
    swal({
      title: "Voullez-vous vraiment supprimer ce dossier?",
      text: "Cett opération est irreversible, vous perdriez toutes les informations enregistrées à propos de ce dossier !",
      icon: "warning",
      icon: "warning",
      buttons:{
        cancel:"Annuler",
        confirm:'Supprimer'
      },
      dangerMode: true,
    }).then(willDelete=>{
        if (willDelete) {
          axios.delete(`api/dossier/delete/${currentDossier['id']}`).then(res=>{
            if(res.status === 200 || res.status === 201 || res.status === 204){
              swal({text:"Le dossier à été supprimer avec succès",
                icon: "success",
                buttons:{
                  confirm:{
                    text:"OK",
                    className:"bg-green-500"
                  }
                }
              });
              handleSearch()
            }else{
              swal({
                title:"Oops",
                text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
                icon:"error",
                buttons:{
                  confirm:{
                    text:"OK",
                    className:'bg-red-500'
                  }
                }
              })
            }
          }).catch(err=>{
            if(err.hasOwnProperty('response')){
              console.log(err.response);
              swal({
                title:"Ooops",
                text:`${err.response['data'] || "Echec! une erreur inconnu s'est produit lors de la suppression"} :)`,
                icon:"error",
                buttons:{
                  confirm:{
                    text:"OK",
                    className:'bg-red-500'
                  }
                }
              })
            }else{
              console.log(err);
              swal({
                title:"Oops",
                text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
                icon:"error",
                buttons:{
                  confirm:{
                    text:"OK",
                    className:'bg-red-500'
                  }
                }
              })
            }
          })
        } else {
          swal({
            text:"Opération annulée :)",
            icon:"error",
            buttons:{
              confirm:{
                text:"OK",
                className:'bg-red-500'
              }
            }
          })
      }
    });
   
  }else{
      swal({title:"Oops",
      text:"Veuillez selectionner un dossier et reessayer",
      icon:"warning",
      buttons:{
        confirm:{
          text:"OK",
          className:'bg-red-500'
        }
      }
    })
  }  
}

{/*Affecter un dossier à un conseiller*/}
const handleAffectationDOssier = ()=>{
  handleClosem()
  if(currentDossier){
    navigate(`/${portail}/dossiers/affectation/affecter`,{state:{dossier:currentDossier}})
  } 
}
{/*Generer les paramettre de connsexxion*/}

const handleGenerateUserAccount = ()=>{
  handleClosem()
  if(currentDossier){
    navigate(`/${portail}/dossiers/compte/generer`,{state:{dossier:currentDossier}})
  } 
}

{/*Envoyer un lien de modification de mot de passe*/}
const lienPasswordForgot = ()=>{

}
{/*Lister les doccuments d'un dossier*/}
{/*Sortir un dossier*/}
const handleSortieDossier = ()=>{
  handleClosem()
  if(currentDossier){
    navigate(`/${portail}/dossiers/sortir`,{state:{dossier:currentDossier}})
  } 
}
const handleListDocs = ()=>{
  handleClickOpen()
}
const [rnopen,setRnOpen] = useState(false)
const handleRnClose =() =>{
  setRnOpen(false)
}
const handleRnOpen =(data)=>{
  setRnOpen(true)
}

  return (
    <div>
       <DocumentsList open={open} handleClose={handleClose} documents={currentDocument} />
       <ModDemandeList open={opendmd} handleClose={handleCloseDmd} data={currentData} />
       <ImportDossier open={openImport} handleClose={handleCloseImport} />
       <CritereRechercheDossier handleClose={handleCloseSearch} handleSearch={handleSearchAvance} searchInput={searchInput} setSearchInput={setSearchInput} open={openSearch} />
       {rnopen&&<ReinitialiserMotDePasse open={rnopen} dossier={currentDossier} handleClose={handleRnClose} />}
    <Dash>
      <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,marginRight:20,padding:10}}>
        <OfferHeader>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <OfferTitle>Liste des dossiers</OfferTitle>
          <div className='flex items-center'>
            <Button className='mr-2' variant="warning" onClick={()=>handleActionsTop(`/${portail?.toLowerCase()}/dossiers`)}><ReplyAllIcon /> Retour</Button>
            <Button className='mr-2' onClick={()=>handleActionsTop(`/${portail?.toLowerCase()}/dossiers/nouveau/express`)}><AddCircleOutlineIcon /> Créer</Button>
          </div>
        </div>
        <Hr />
        <div>
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className={classes.searchBox} variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(civilite,nom,prenom,statut ...)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <div>
                      <Button variant="outline-success" id="button-addon2" className={classes.btnAdd} onClick={()=>handleSearch(1)}>
                          <SearchIcon />
                      </Button>
                      <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={handleOpenSearch}>
                          <PageviewIcon />Recherche détaillée
                      </Button>
                    </div>
                </InputGroup>
            </Paper>
          </div>
    </OfferHeader>
    {(dossierList.length!==0)&&(
    <div className='flex flex-row w-full mb-4 items-center justify-between'>
        <Chip label={`Total : ${paginateData.total}`} variant="outlined" />
        <div className='flex flex-row items-center justify-end'>
        <Button className='mr-2' onClick={handleExport}>
          <FileDownloadIcon />Exporter
        </Button>
        <Button className='mr-2' onClick={handleOpenImport}>
          <ImportExportIcon /> Importer
        </Button>
        </div>
   </div>)}
    
    <TableBoostrap>
      <thead>
        <tr>
          <th>Dossier</th>
          <th>Staut</th>
          <th>Traitement...</th>
          <th>Dernières demandes</th>
          <th>Offres</th>
          <th>Dispositifs</th>
          <th>candidature</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {(dossierList.length!==0)?(
        dossierList.map((row,index)=><tr key={index}>
          <td><Dossier data={row} /></td>
          <td valign='middle'>
                <Chip label={`${row.status || "Inconnu"}`} color={row.status==='Actif'?"success":(row.status==='Suspendu' ?"warning":"error")} />
              </td>
              <td valign='middle'>
                {row.etape==='Nouveau dossier'&&<Chip label={`${row.etape || "Inconnu"}`} color='primary' variant='outlined' />}
                {row.etape==='Affecter'&&<Chip label={`${row.etape || "Inconnu"}`} color='primary' />}
                {row.etape==='En traitement'&&<Chip label={`${row.etape || "Inconnu"}`} color='warning' variant='outlined' />}
                {row.etape==='Terminer'&&<Chip label={`${row.etape || "Inconnu"}`} color='warning' />}
                {row.etape==='Non satisfait'&&<Chip label={`${row.etape || "Inconnu"}`} color='succes' variant='outlined' />}
                {row.etape==='Satisfait'&&<Chip label={`${row.etape || "Inconnu"}`} color='succes' />}
                {row.etape==='Annuler'&&<Chip label={`${row.etape || "Inconnu"}`} color='error' />}
                {(!row.etape && row.etape==='')&&<Chip label={`Inconnu`} color='error' variant='outlined' />}
              </td>
          <td><div>
          <div>
            {row.demandes&&<List dense>
                {row.demandes?.map((dossier,index)=>{
                  if(index <= 1 ){
                    return <ListItem key={dossier.id}>
                      <ListItemText primary={<div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                          <Typography sx={{marginRight:10}}>{dossier.type?.libelle?dossier.type?.libelle : ""}</Typography>
                      </div>}
                      secondary={<div style={{marginLeft:20}}>
                        <span style={{fontWeight:'bold'}}>{ formatDistance(new Date(dossier.created_at), new Date(), { addSuffix: true,locale:fr })}</span>
                      </div> }
                    />
                  </ListItem>
                  }else if(index === 2){
                    return <div style={{display:'flex',justifyContent:'center'}}><BtnMore onClick={()=>showMoreDemande(row)}>
                      ... toutes les demandes
                    </BtnMore></div>
                  }else{
                    return false
                  }  
                  
                })}
              </List>}
              
    </div>
          </div></td>
          <td valign='middle'>{row.hasOwnProperty('offres')&&<Chip
            label={row.offres}
            onClick={()=>handleShowOffre(row)}
            icon={<ExpandMoreIcon />}
            variant="outlined"
          />}</td>
          <td>{row.hasOwnProperty('dispositifs')&&<Chip
            label={row.offres}
            onClick={()=>handleShowOffre(row)}
            icon={<ExpandMoreIcon />}
            variant="outlined"
          />}</td>
          <td>{row.hasOwnProperty('candidatures')&&<Chip
            label={row.offres}
            onClick={()=>handleShowOffre(row)}
            icon={<ExpandMoreIcon />}
            variant="outlined"
          />}</td>
          
          <td valign='middle'>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Tooltip title="Détail du dossier">
                <IconButton onClick={(e)=>handleShowDossier(row) }>
                    <VisibilityIcon sx={{fontSize:30}} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Documents attachés">
                <IconButton onClick={(e)=>handlesetListDocument(row.jeune.documents) }>
                    <AttachFileIcon sx={{fontSize:30}} />
                </IconButton>
              </Tooltip>
              <Tooltip title="plus">
                <IconButton  id="demo-positioned-button"
                  aria-controls={openm ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openm ? 'true' : undefined}
                  onClick={(e)=>handleClickm(e,row)}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
             
            </div>
          </td>
          
          </tr>)
        
      ) : (<tr><td colSpan={3}><p>Aucun dossier trouvé</p></td></tr>)}
      </tbody>

    </TableBoostrap>
    {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>fromSearch ? handleDossierList(numberPage) : handleSearchAvance(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
      
  </Dash.Frame>
</Dash>

<Menu
    id="demo-positioned-menu"
    aria-labelledby="demo-positioned-button"
    anchorEl={anchorEl}
    open={openm}
    onClose={handleClosem}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
       {(currentDossier && currentDossier.etape==='Nouveau dossier') ? <MenuItem onClick={handleAffectationDOssier}><PersonAddAltIcon sx={{fontSize:30}}/> Affecter à un conseiller</MenuItem>:null} 
        {(currentDossier && !currentDossier.comptegenerer) ? <MenuItem onClick={handleGenerateUserAccount}><PersonAddAltIcon sx={{fontSize:30}}/> Générer les paramètres de connexion</MenuItem>:null}
        {(currentDossier && currentDossier.comptegenerer) ? <MenuItem onClick={handleRnOpen}><SendTimeExtensionIcon sx={{fontSize:30}}/>Envoyer un lien de changement de mot de passe</MenuItem>:null}
        <MenuItem onClick={handleListDocs}><AttachFileIcon sx={{fontSize:30}} /> Documents attachés</MenuItem>
        {(currentDossier && currentDossier.statut!=='Sortie') ? <MenuItem onClick={handleSortieDossier}> <RemoveCircleOutlineIcon  sx={{fontSize:30}}/> Sortir le dossier</MenuItem> : null}
        <MenuItem onClick={suppressionDefinitive}> <DeleteIcon  sx={{fontSize:30}}/> Supprimer définitivement</MenuItem>
      </Menu>
      {loading ? <LoadingPage /> : null}
</div>
  )
}

export default DosseierList