import React, { useState } from 'react'
import HeaderTitle from 'containers/HeaderTitle'
import { Outlet } from 'react-router-dom'
function OffreHome() {
   const [titre,setTitre] = useState("Gestion des offres")
  return (
      <div>
          <HeaderTitle title={titre}/>
          <main>
                <Outlet context={[titre,setTitre]} />
          </main> 
      </div>
  )
}

export default OffreHome