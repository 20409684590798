import React,{useState} from 'react'
import {AuthSessionStatus, AuthValidationErrors, Form, Homer} from 'components'
import logo from "assets/logo.jpg"
import { useAuth } from 'hooks/auth'
import { Button } from 'react-bootstrap';
import { Paper } from '@mui/material';
import CustumModal from 'components/CustumModal';
import dayjs from 'dayjs';
import swal from 'sweetalert';

const ReinitialiserMotDePasse = ({open,handleClose,dossier}) => {
 
  const { forgotPassword } = useAuth({ middleware: 'guest' })
  const [email, setEmail] = useState(dossier?.jeune?.email)
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const submitForm = event => {
    event.preventDefault()
    forgotPassword({ email, setErrors, setStatus })
    swal("Bien !","Lien envoyé !",'success')
  }
  const onClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
        return;
    
    handleClose()
  }
  return (
    <CustumModal 
        open={open} 
        handleClose={onClose} 
        titre='Envoyer un lien de reinitialisation du mot de passe'
        actions={ <div className="flex items-center justify-end mt-4">
        <Button onClick={submitForm}>Envoyez le lien de réinitialisation</Button>
    </div>}
        >
        {dossier&&
          <div className='flex flex-row justify-between bg-green-100 p-2'>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{dossier['code'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(dossier['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(dossier['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{dossier['objet'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{dossier['statut'] || ""}</span></div>     
              </div>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{dossier['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{dossier['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(dossier['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{dossier['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{dossier['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{dossier['jeune']['quartier'] || ""}</span></div>     
              </div>
          </div>}

          <p className="mb-4 text-sm text-orange-800 flex flex-wrap mt-2 bg-orange-200 italic">
                L'envoie d'un lien de réinitialisation du mot de passe permet à un utilisateur ayant perdu ou oublier sont mot de passe de récréer un nouveau.<br />
                Veuillez saisir ou confirmer l'adresse e-mail de utilisateur pour la création du compte.<br />
                NB: Cette action est valable uniquement pour des comptes exitants.<br /> Les utilisateurs ne disposant pas de compte, veuillez utiliser plutot la fonction de génération de compte
          </p>
     
          
            <Paper className='p-2'>
                
                {/* Session Status */}
                <AuthSessionStatus className="mb-4" status={status} />
                {/* Validation Errors */}
                <AuthValidationErrors className="mb-4" errors={errors} />
                      
                <label htmlFor="email">Adresse email</label>
                <Form.Input 
                    id="email"
                    type="email"
                    name="email"
                    placeholder ="Adresse email"
                   
                    style={{width:'100%'}}
                    value={email}
                    
                    onChange={event => setEmail(event.target.value)}
                    required
                    autoFocus
                />
                        
                   
            </Paper>
         
    </CustumModal>
  )
}

export default ReinitialiserMotDePasse