import React, {useEffect, useState} from 'react'
import { Typography } from '@mui/material'
import { Card } from 'components'
import styled from 'styled-components';
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import dayjs from 'dayjs'
import { makeStyles } from 'tss-react/mui';
import axios from 'lib/axios';
import { useSelector } from 'react-redux';


const useStyles = makeStyles()(theme=>({
    headerContainer:{
        background:'#707070',
        color:'white',
        fontSize:'18px',
        display:'flex',
        flex:1,
        flexDirection:'column',
        padding:'20px',
        alignItems:'center'
    },
    headerSub:{
        display:'flex',
        justifyContent:'flex-start',
        marginTop:20,
        fontSize:18,
    },
    paritem:{
        display:'flex',
        marginBottom:10,
    },
   
}))
const styles ={
    label:{
        fontWeight:'bold',
        marginRight:20,
    }
}
const Button =styled.button`
  background-color:#00B04B;
  padding:10px 2rem 10px 2rem;
  border:1px solid #f8f8f8;
  color:white;
  font-weight:bold;
  display:flex;
  font-size:16px;
  flex-direction:column;
  border-radius: 10px;
  flex:1;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  cursor:pointer;
  &:hover{
    background-color:#F29400;
    color:white
  }
`

const Offre = () => {
    const params = useParams()
    const {classes} = useStyles()
    const location =useLocation()
   const [data,setData] = useState({})
    const portail = useSelector(state=>state.ui.portail)
    const navigate =useNavigate()
 
    const handleOffre = ()=>{
      axios.get(`/api/offre/${params['id']}`).then(res=>{
        if(res.status ===200 || res.status ===201){
          setData(res.data)
        }
      })
    }
    useEffect(()=>{
      handleOffre()
    },[])
    
const handlePostuler =(data)=>{

}
  return (
   <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
        <div className={classes.headerContainer}>
            <Typography variant='h4'>{data&& (data['titre']||"")}</Typography>
            <Button onClick={()=>handlePostuler(data)}>Postuler maintenant</Button>
            <div className={classes.headerSub}><Typography style={{marginRight:20}}>Publié le :{(data &&data.hasOwnProperty('datedebut'))?dayjs(data['datedebut']).format("DD/MM/YYYY"):""} </Typography> <Typography>Date de cloture le :{(data && data.hasOwnProperty('datefin'))?dayjs(data['datefin']).format("DD/MM/YYYY"):""} </Typography></div>
        </div>
        <Card>
            <Card.Header>
              <Card.Title>Partenaires</Card.Title>
              <Card.Hr />
            </Card.Header>
                {(data && data.partenaires && data.partenaires.length!==0)&&data.partenaires.map(partenaire=>(
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                      <div className={classes.paritem}><Typography  variant='h4'>{partenaire&&partenaire['raisonsociale']}</Typography></div>
                      <div className={classes.paritem}><Typography style={styles.label}>Site web : </Typography> <Typography> {(partenaire.siteweb && partenaire.siteweb!=='null') ? partenaire.siteweb :"-"} </Typography></div>
                      <div className={classes.paritem}><Typography style={styles.label}>Secteur d'activités : </Typography> <Typography> {(partenaire.activitesprincpale && partenaire.activitesprincpale!=='null') ? partenaire.activitesprincpale : "-"} </Typography></div>
                      <div className={classes.paritem}><Typography style={styles.label}>Description : </Typography> <Typography>  {(partenaire.description && partenaire.description!=='null') ? partenaire.description : '-'} </Typography></div>
                  </div>
                  {partenaire.logo&&<div> <Box sx={{width:'25%'}}>
                      <img src={`${process.env.REACT_APP_BACKEND_URL}/${partenaire.logo}`}  />
                      </Box> </div>}
                  
               </div>
                ))}
                
        </Card>
        <Card>
            <Card.Header>
              <Card.Title>Détails de l'offre - {data && (data.categorie?.libelle)}</Card.Title>
              <Card.Hr />
              <div><Typography variant='h5'>Poste proposé : {data&&(data['titre']||" - ")}</Typography></div>
              <div>
                    <Typography variant='h5'>Mission : </Typography>
                    <p style={{marginLeft:25,lineHeight: 1.5}}>{(data && data['missions'])?<div dangerouslySetInnerHTML={{__html:  data['missions']+"..."}}></div>:" - "} </p>
              </div>
              <div>
                    <Typography variant='h5'>Profil recherché pour le poste de : {data['titre']||" - "}</Typography>
                    <p style={{marginLeft:25,lineHeight: 1.5}}>{(data && data['profils'])?<div dangerouslySetInnerHTML={{__html:  data['profils']+"..."}}></div>:" - "}</p>
              </div>
              <div>
                    <Typography variant='h5'>Critères de l'annonce : </Typography>
                    <div className={classes.paritem}><Typography style={styles.label}>Metier : </Typography> <Typography> {(data && data['metiers'])? data['metiers'].join(","):" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Secteur d'activité : </Typography> <Typography> {(data && data['secteuractivites'])? data['secteuractivites'].join(","):" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Type de contrat : </Typography> <Typography> {(data && data['typecontrat'])? data['typecontrat']:" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Ville : </Typography> <Typography> {data && data['ville']? data['ville']:" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Niveau d'expérience : </Typography> <Typography> {data['niveauexperience']? data['niveauexperience'].join(","):" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Niveau d'étude : </Typography> <Typography> {data['niveauetudes']? data['niveauetudes'].join(","):" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Compétence clés : </Typography> <Typography> {data['competences']? data['competences'].join(","):" - "} </Typography></div>
                    <div className={classes.paritem}><Typography style={styles.label}>Nombre de post(s) : </Typography> <Typography> {data['nombrepost']? data['nombrepost']:" - "} </Typography></div>
              </div>
            </Card.Header>
           
        </Card>
        <div style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'}}><Button onClick={()=>handlePostuler(data)}>Postuler maintenant</Button></div>
    </div>
  )
}

export default Offre
