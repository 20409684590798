import React, { useEffect, useState } from 'react'
import faker from 'faker';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import axios from 'lib/axios';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
};


  ChartJS.register(...registerables);

 const compare = (a,b)=> {
    if ( a.mois < b.mois ){
      return -1;
    }
    if ( a.mois > b.mois ){
      return 1;
    }
    return 0;
  }
  
function Evolution() {

  //Nouveau dossier,Affecter,En traitement,Terminer,Non satisfait,Satisfait,Annuler
const labels = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Aout','Septembre','Octobre','Novembre','Decembre'];

const [evodata,setEvodata] = React.useState([])
const handlaDataSet = ()=>{
  axios.get(`/api/state/demande-mensuel`).then(res=>{
    
    if(res.status ===200 || res.status===201){
      const data = res.data.sort(compare)
      let final =[]
      labels.forEach((m,ix)=>{
        const str =`000${ix+1}`.slice(-2)
        const idx = data.findIndex(item => item.mois === str)
        if(idx!==-1){
          final.push(data[idx].total)
        }else{
         
          final.push(0)
        }
      })
      setEvodata(final)
    }
  })
}
useEffect(()=>{
  handlaDataSet()
},[])

const data = {
  labels:labels,

  datasets: [
    {
      label: 'Nouvelle demande',
      data:evodata,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};
  return (
    <div className='flex-1'>
       <Line options={options} data={data} />
    </div>
  )
}

export default Evolution