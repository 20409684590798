import React, { useEffect, useState } from 'react'
import { Button, IconButton, MenuItem, Tooltip, Typography} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Col from 'react-bootstrap/Col';
import { Alert, Form, InputGroup, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import SaveIcon from '@mui/icons-material/Save';
import './styles.css'
import 'react-quill/dist/quill.snow.css';
import './styles/UtilisateurAdd.scss'
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import { 
    StepUserSearch, 
} from './components';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import axios from 'lib/axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makePassword,generateLogin } from 'lib/application';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const groupItems=[
    {
      name: "Mme",
      label: "Mme",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "Mlle",
      label: "Mlle",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "M",
      label: "M",
      information: "",
      disabled: false
    }
  ]
function UtilisateurAdd() {
    const navigate =useNavigate()
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const handlelist = ()=>{
        navigate(`/${portail}/utilisateurs`)
    }
    const [loading,setLoading] = useState(false)
    const [activeStep,setActiveStep] = useState(1)
    const [parcouru,setParcouru] =useState([])
    const handleNext = () =>{
        if(!parcouru.includes(activeStep)){
            setParcouru([...parcouru,activeStep])
        }
        if(activeStep<2){
            setActiveStep(activeStep + 1)
        }else{
            setActiveStep(2)
        }
       
    }
    const handleback = () =>{
        if(!parcouru.includes(activeStep)){
            setParcouru(parcouru.filter(item=>item!==activeStep))
        }
        if(activeStep>1){
            setActiveStep(activeStep - 1)
        }else{
            setActiveStep(1)
        }
       
    }
    const [inputField,setInputField] = useState({})
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const handleEditorChange =(field,value)=>{
        setInputField({...inputField,[field]:value});
    }
    const handleCheckboxChange =(name)=>{
        const currentPortail =inputField.hasOwnProperty('portails')?[...inputField.portails]:[]
        let newPortail
        if(currentPortail.includes(name)){
            newPortail = currentPortail.filter(item=>item===name)
        }else{
            newPortail =  currentPortail.push(name)
        }
        setInputField({...inputField,['portails']:newPortail})
    }
    const [profils,setProfils] =useState(null)
    const handleProfile = ()=>{
        axios.get('/api/profile/list').then(res=>{
            if(res.status === 200 || res.status ===201){
                setProfils(res.data.data)
            }else{
                console.log(res.data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    const [structures,setStructures] = useState(null)
    const handleStructure = () =>{
        axios.get('/api/structure/list').then(res=>{
            if(res.status === 200 || res.status ===201){
                setStructures(res.data.data)
            }else{
                console.log(res.data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        handleProfile()
    },[])

    const handleChangeDate = (name,newValue) => {
        setInputField({...inputField,[name]:newValue})
      };
    const handleConnexionData = useCallback(async ()=>{
        if(inputField.civilite  && inputField.nom && inputField.prenom){
            setInputField({...inputField,'password':makePassword(8)})
            setInputField({...inputField,'login':await generateLogin(inputField.nom,inputField.prenom)})
        }
    },[inputField.civilite,inputField.nom,inputField.prenom])

      useEffect( ()=>{
        handleConnexionData()
      },[inputField.civilite,inputField.nom,inputField.prenom])

      const handleInitLogin =async()=>{
        setInputField({...inputField,'login':await generateLogin(inputField.nom,inputField.prenom)})
       
      }
      const handleInitPassword =async ()=>{
        setInputField({...inputField,'password':makePassword(8)})
      }
    const [errors,setErrors] =  useState([])
  const handleSubmit =(e)=>{
    setErrors([])
    setLoading(true)
    if(!inputField.email || inputField.email===""){
        setErrors([...errors,"Veuillez remplir le champ email !"])
    }
    if(!inputField.nom || inputField.nom===""){
        setErrors([...errors,"Veuillez remplir le champ nom !"])
    }
    if(!inputField.prenom || inputField.prenom===""){
        setErrors([...errors,"Veuillez remplir le champ prénom !"])
    }
    const dataSend = {credentials:{
        email:inputField.email,
        name:inputField.nom +" "+inputField.prenom,
        username:inputField.login,
        password:inputField.password,
        password_confirmation:inputField.password,
        profil_id:inputField.profil_id,
    },
    personne:{
        civilite:inputField.civilite,
        nom:inputField.nom,
        prenom:inputField.prenom,
        datenaissance:inputField.datenaissance
    }}
    
    e.preventDefault()
    if(errors && errors.length!==0){
        swal("Ooops","Veuillez remplir tous les champs","warning")
        setLoading(false)
        return
    }
    axios.post(`/api/users/create`,dataSend).then(res=>{
        if(res.status!==200 || res.status!==201){
            swal("Bien !","Enregistrement effectué avec succès","success")
            setLoading(false)
            handlelist()
        }else{
            console.log(res.data);
            setLoading(false)
            swal("Ooops !","Erreur lors de l'enregistrement","error")
        }
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        swal("Ooops !","Erreur lors de l'enregistrement","error")
    })
  }
  return (
    <div className="utilisateuradd">
        <div className="titlebox">
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Ajout d'un compte utilisateur</Typography>
            <Button className="btngreen" onClick={(e)=>handlelist()}><ArrowBackIosIcon />Retour à la liste</Button>
        </div>
        <div className="bodyBox">

            {activeStep===1&&<StepUserSearch 
                                step ={activeStep}  
                                handleNext={handleNext} 
                                handleback={handleback} 
                                setInputField={setInputField}
                                inputField={inputField}  /> }
            {activeStep>1&&<form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={2} />
                    <Col sm={8}>
                        <div className="bodybox">
                            <Typography variant='h5'>CREEATION DE COMPTE</Typography>
                                {(errors && errors.length!==0)&&<Alert key={variant} variant='danger'>
                                    <ul>
                                        {errors.map(error=><li>{error}</li>)}
                                    </ul>
                                </Alert>}
                                                              
                                <Card>
                                    <Card.Header>
                                        <Card.Title>INFORMATIONS PERSONNEL </Card.Title>
                                    <Card.Hr />
                                    </Card.Header>
                                    <div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                        <RadioField
                                            groupItems={groupItems}
                                            group="Civilité"
                                            checkedDefault="Madame"
                                            appearance={radiobutton_appearances.primary}
                                            name='civilite'
                                            onChange={handleChange}
                                            vvalue ={inputField?.civilite}
                                        />
                                        <CustumForm.Input 
                                            size="small" 
                                            margin="dense" 
                                            style={{marginRight:20,marginBottom:20}}  
                                            label="Nom"
                                            required 
                                            wd='100%'
                                            name='nom' 
                                            value={inputField?.nom}
                                            onChange={handleChange}
                                        />
                                        <CustumForm.Input 
                                            size="small" 
                                            margin="dense" 
                                            style={{marginRight:20,marginBottom:20}}  
                                            label="Prenom"
                                            required 
                                            wd='100%'
                                            name='prenom' 
                                            value={inputField?.prenom}
                                            onChange={handleChange}
                                        />
                                        <DesktopDatePicker
                                            label="Date de naissance"
                                            inputFormat="dd/MM/yyyy"
                                            value={inputField["datenaissance"] || ""}
                                            name='datenaissance'
                                            onChange={(v)=>handleChangeDate('datenaissance',v)}
                                            renderInput={(params) => <CustumForm.Input size="small"  wd='100%' margin="dense" style={{marginRight:20,marginBottom:20}}  {...params} />}
                                        />
                                          <Card.Hr />
                                        </CustumForm.Group>
                                        </LocalizationProvider>
                                    </div>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Card.Title>PROFIL / STRUCTURE </Card.Title>
                                    <Card.Hr />
                                    </Card.Header>
                                    <div>
                                    <CustumForm.Group direction ='row' flex={1}>
                                            <CustumForm.Input 
                                                required size="small" 
                                                name="profil_id" 
                                                margin="dense" 
                                                onChange={(event)=>setInputField({...inputField,[event.target.name]:event.target.value})} 
                                                value={inputField?.profil_id} 
                                                select 
                                                style={{marginRight:20}}  label="Profile">
                                                    <MenuItem  value='0'>Aucun</MenuItem>
                                                    {profils.map(profil=>(
                                                        <MenuItem  value={profil.id}>{profil.libelle.toUpperCase()}</MenuItem>
                                                    ))}
                                                </CustumForm.Input>
                                                {structures && <CustumForm.Input 
                                                    required size="small" 
                                                    name="structure_id" 
                                                    margin="dense" 
                                                    onChange={(event)=>setInputField({...inputField,[event.target.name]:event.target.value})} 
                                                    value={inputField?.structure_id} 
                                                    select 
                                                    style={{marginRight:20}}  label="Structure">
                                                    <MenuItem  value='0'>Aucun</MenuItem>
                                                    {structures.map(structure=>(
                                                        <MenuItem  value={structure.id}>{structure.libelle.toUpperCase()}</MenuItem>
                                                    ))}
                                                </CustumForm.Input>}
                                                
                                        </CustumForm.Group>
                                    </div>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>INFORMATIONS DE CONNEXION </Card.Title>
                                    <Card.Hr />
                                    </Card.Header>
                                    <div>
                                    <CustumForm.Input 
                                            size="small" 
                                            margin="dense" 
                                            style={{marginRight:20,marginBottom:20}}  
                                            label="email"
                                            required 
                                            wd='100%'
                                            name='email'
                                            type='email' 
                                            value={inputField?.email}
                                            onChange={handleChange}
                                        />
                                        <CustumForm.Group row direction='row'>
                                            <div style={{display:'flex'}}>
                                                <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                                                    <span style={{fontSize:16,margin:20}}>Nom d'utilisateur : </span>
                                                    <span style={{fontSize:18,margin:20, fontWeight:'bold'}} >{inputField['login']||""}</span>
                                                </div>
                                                <Tooltip title="Régénérer le nom d'utilisateur">
                                                    <IconButton onClick={handleInitLogin}>
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </CustumForm.Group>
                                        <CustumForm.Group row>
                                            <div style={{display:'flex'}}>
                                                <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                                                    <span style={{fontSize:16,margin:20}}>Mot de passe  : </span>
                                                    <span style={{fontSize:18,margin:20, fontWeight:'bold'}}>{inputField['password']||""}</span>
                                                </div>
                                                <Tooltip title="Réinitialisé le mot de passe">
                                                    <IconButton onClick={handleInitPassword}>
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            
                                        </CustumForm.Group>
                                    </div>
                                </Card>
                                <Card style={{padding:20,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                                        </Button>
                                        <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                                            <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                                        </Button>
                                    </div>
                                    
                                </Card>
                            </div>
                        </Col>
                        <Col sm={2} />
                    </Row>
                </form>}
            
        </div>
    </div>
  )
}

export default UtilisateurAdd
