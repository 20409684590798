import { Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Form, InputGroup, Modal,Spinner ,Alert} from 'react-bootstrap';
import "./TypeDemandeList.scss"
import axios from 'lib/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Table from 'react-bootstrap/Table';
import {Pagination} from "@mui/material";
import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RefreshIcon from '@mui/icons-material/Refresh';
const TypeDemandeList = () => {
    
    const [search,setSearch] = useState(null)
    const [typeDmds,setTypeDemandes] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const [typedmd,setTypedmd] = useState(null)
    const [errors,setErrors] = useState(null)
    const [loading,setLoading] = useState(false)
    const [mode,setMode] = useState('ADD')
    //selection de la liste des types de demandes
    const handleTypeDemande = (numberPage=1)=>{
      const url = search?`/api/type-demande/list/${search}?page=${numberPage}`:`/api/type-demande/list?page=${numberPage}`
      axios.get(url).then(res=>{
        if(res.status ===200 || res.status === 201){
          setTypeDemandes(res.data.data)
          setPagnateData({
              current_page:res.data.current_page,
              last_page:res.data.last_page,
              per_page:res.data.per_page,
              total:res.data.total
            })
        }   
      })
    }
    useEffect(()=>{
      return handleTypeDemande()
    },[])
    const handleDelete = (id)=>{
      axios.delete(`/api/type-demande/${id}`).then(res=>{
        if(res.status ===200 || res.status ===201){
          swal("Bien !","Suppression réalisé avec succès",'success')
         
        }else{
          swal("Ooops !","Erreur lors de l'opération",'error')
        }
        handleTypeDemande()
      }).catch(err=>{
        swal("Ooops !","Erreur lors de l'opération",'error')
        console.log(err);
      })
    }
    const loadData = (id)=>{
        if(mode =='UP' && id){
          axios.get(`/api/type-demande/${id}`).then(res=>{
            if(res.status === 200 || res.status === 201){
                setTypedmd(res.data)
              setInputField({...res.data})
            }
          })
        }
      }
    const [inputField,setInputField] = useState({})
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const handleEditorChange =(field,value)=>{
        setInputField({...inputField,[field]:value});
    }

    const [modeTitle,setModeTitle] = useState("Ajouter un type")
    const [show,setShow] = useState(false)
    const handleShow = (mod,type=null)=>{
        setMode(mod)
      if(mod =='UP' && type){
        setModeTitle(`Mise à jour type de demande #${type.id}`)
        loadData(type.id)
        setShow(true)
      }else{
        setInputField({})
        setModeTitle(`Ajouter un type`)
        setShow(true)
      }
    }
    const handleClose = ()=>{
        setShow(false)
    }

    const handleSubmit =(e)=>{
        e.preventDefault()
        setLoading(true)
        if(!inputField.hasOwnProperty('libelle') || inputField.libelle.trim() ==="" ){
            swal("Erreur !","Veuillez renseigner un intitulé",'error')
            setLoading(false)
            return
        }
          if(mode==='ADD'){
            axios.post(`/api/type-demande/add`,inputField).then(res=>{
                if(res.status === 200 || res.status ===201){
                    swal("Bien !","Type demande créée avec succès",'success')
                    setLoading(false)
                    handleTypeDemande()
                    handleClose()
                }else{
                    swal("Erreur !","Erreur lors de la création du type, veillez corriger et réessayer",'error')
                    setLoading(false)
                }
            }).catch(err=>{
                console.log(err)
                if(err.hasOwnProperty("response")){
                    setErrors(err.response.data?.errors)
                }
                setLoading(false)
            })
        }else{
           
            axios.put(`/api/type-demande/update/${typedmd.id}`,inputField).then(res=>{
                if(res.status === 200 || res.status ===201){
                    swal("Bien !","Type de damnde a été modifié avec succès",'success')
                    setLoading(false)
                    handleTypeDemande()
                }else{
                    swal("Erreur !","Erreur lors de la modification du type de demande, veillez corriger et réessayer",'error')
                    setLoading(false)
                }
            }).catch(err=>{
                console.log(err)
                if(err.hasOwnProperty("response")){
                    setErrors(err.response.data?.errors)
                }
                setLoading(false)
            })
        }
    }

  return (
    <div className='typedemandes'>
            <Modal show={show} onHide={handleClose} centered backdrop="static">
              <Modal.Header>
                  <Modal.Title>{modeTitle.toLocaleUpperCase()}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form onSubmit={handleSubmit}>
                <Paper className="formbox" variant='outlined'>
                  <Card>
                      <Card.Header>
                          <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:"flex-start"}}>
                          <CustumForm style={{display:'flex',flexDirection:'column'}}>
                              <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                  <CustumForm.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20,marginBottom:20}}  
                                      label="Intitule"
                                      required 
                                      wd='100%'
                                      name='libelle' 
                                      value={inputField['libelle'] ||""}
                                      onChange={handleChange} 
                                      />
                                  {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}
                                  <ReactQuill  
                                  placeholder={"Description du profil"} 
                                  style={{width:'100%',marginBottom:20}}
                                  name='description' 
                                  theme="snow" 
                                  value={inputField['description']||""} 
                                  onChange={(v)=>handleEditorChange('description',v)} 
                              
                              />
                              {(errors && errors.hasOwnProperty('description'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['description'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}

                              
                              </CustumForm.Group>       
                          </CustumForm>
                      </div>
                  </Card>
                      
                  </Paper>
                  <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                        </Button>
                        <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                            <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                        </Button>
                  </Card>     
            </form>
              </Modal.Body>
              
        </Modal>
        <div className='titlebox'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Gestion des types de demande</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={()=>handleShow('ADD')}>
                <AddIcon/>Ajouter un type
            </Button>
        </div>
        <div className="bodybox">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(libelle,statut.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={()=>handleTypeDemande(1)}>
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Intitulé</th>
                        <th>Description</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(typeDmds && typeDmds.length!==0)?typeDmds.map(type=><tr>
                            <td>{type['id'] || ""}</td>
                            <td>{type['libelle'] || ""}</td>
                            <td>{type['description'] || ""}</td>
                            <td>
                                <div className="tbaction">
                                    <Tooltip title="Editer">
                                        <IconButton onClick={()=>handleShow('UP',type)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Supprimer">
                                        <IconButton onClick={()=>handleDelete(type.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                
                                </div>
                            </td>
                        </tr>):<tr><td colSpan={3}><p>Aucun type trouvé, Veuillez utiliser le bouton "Ajouter un type" pour créer un nouveau type de demande</p></td></tr>}
                    
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.total}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleTypeDemande(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
            </Paper>
        </div>
    </div>
  )
}

export default TypeDemandeList