import styled from "styled-components/macro";
import { TextField,Select } from "@mui/material";
export const Container = styled.div`
    display:flex;
    width:100%;
`
export const Base = styled.form`
    width:100%
`

export const InputField = styled(TextField)`
    background: #F8F8F8;
    border-radius:${({height})=>height || '10px' };
    
    width: ${({wd})=>wd||'50%'}; 
    
    margin-bottom:20px;
    & label.Mui-focused {
        color: white;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
    & fieldset {
        border-color: white;
        border-bottom:2px solid #00B04B;
    }
    &:hover fieldset {
        border-color: white;
    }
    &.Mui-focused fieldset {
        border-color: white;
    }
    & > svg {
        color: #00B04B;
    };
    };
    
`
export const Label =styled.label`
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    letter-spacing: 0.00938em;
    font-size: 1rem;
    line-height: 1.4375em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
`
export const SelectField = styled(Select)`
        background: #F8F8F8;
        width: 50%;
        color: #707070;
        & > div {
            border: 2px solid green;
            &:hover{
                border-color: white;
            }
        };
        & > svg {
            color: #00B04B;
        };
        & > fieldset {
            border-color: white;
            border-bottom:2px solid #00B04B;
        };
       & .MuiSelect-select {
            border-color: white;
            border-bottom:2px solid #00B04B;
       };
       
       
  `;

export const CheckBoxField = styled.input``
export const RadioField = styled.input``
export const Button = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    border:none;
    cursor:pointer;
    padding:10px;
    color:white;
    font-weight:bold;
    background-color:${({primary})=>primary?'#00B04B':'#707070' };
    &:hover{
        background-color:${({primary})=>primary?'#F29400':'#00B04B' }
    };
`

export const Group = styled.div`
    display:flex;
    flex:${({flex})=>flex || 1};
    flex-direction:${({direction})=>direction || 'row'};
    justify-content:${({palign})=>palign || 'flex-start'};
    align-items:${({salign})=>salign || 'flex-start'}
    padding:${({padding})=>padding || '0px'};
    margin:${({margin})=>margin || '0px'};
    box-sizing: content-box;
`

export const RButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    border:none;
    cursor:pointer;
    padding:10px;
    color:white;
    font-weight:bold;
    background-color:${({color})=>color||'#00B04B'};
    border-radius:4px;
    &:hover{
        background-color:#9ACD32;  
    };
`
export const Hr = styled.hr`
width:100%;
background:#00B04B;
`

export const Error = styled.div`
  background: #e87c03;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 16px;
  color: white;
  padding: 15px 20px;
`;
