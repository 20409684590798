export const  hstate =[
    {cible:"Dossiers",total:50,nouveau:10},
    {cible:"Demandes",total:150,nouveau:0},
    {cible:"Offres",total:10,nouveau:10},
    {cible:"Partenaires",total:80,nouveau:1},
    {cible:"Prospects",total:10,nouveau:1},
    {cible:"Requêtes non traitées",total:0,nouveau:0}]
export const partenaires=[]
export const services =[
    {code: "S001",designation:"Demande d'emploi"},
    {code: "S002",designation:"Aide à la recherche d'emploi"},
    {code: "S003",designation:"Demande de stage"},
    {code: "S004",designation:"Aide au financement de projet"},
    {code: "S005",designation:"Demande de formation"},
    {code: "S006",designation:"Demande de formation professionnelle"},
    {code: "S007",designation:"Demande de formation qualifiante"},
    {code: "S008",designation:"Demande de prise en charge scolaire"},
    {code: "S009",designation:"Demande d'aide scolaire"},
    {code: "S010",designation:"Intermédiation"},
    {code: "S011",designation:"Sponsoring"},
    {code: "S012",designation:"Autres"},

]
export const objectifs=[
"Intégrer des activités sportives ou culturelles dans mon parcours",
"Engager des démarches liées à ma santé",
"Engager des démarches liées à ma couverture sociale",
"Développer ma mobilité",
"Engager des démarches liées au logement ou à l'hébergement",
"Créer mon activité",
"Choisir mon secteur professionnel",
"Élaborer mon plan de formation ou d'accès à la qualification",
"Mettre à jour ma situation administrative, sociale et fiscale",
"Connaître mon bassin d'emploi",
"Engager ma recherche d'emploi",
"Gérer mon budget",
"Faire reconnaître et valoriser mes connaissances et compétences",
"Intégrer des actions civiques et citoyennes dans mon parcours",
"Engager ma recherche d'un contrat en alternance",
"Engager des démarches de retour en formation initiale",
"Engager des démarches d'accès aux droits",
]

export const evenements=[
    {code:"E001",libelle:"Premier accueil"},
    {code:"E002",libelle:"Visite (Jeune,Partenaire)"},
    {code:"E003",libelle:"Echange Téléphone (Partenaire,dossier jeune)"},
    {code:"E004",libelle:"Envoie de SMS (Partenaire,jeune) "},
    {code:"E005",libelle:"Entretien individuel"},
    {code:"E006",libelle:"Entretien par un partenaire"},
    {code:"E007",libelle:"Information collective"},
    {code:"E008",libelle:"Information par un tiers"},
    {code:"E009",libelle:"Atelier"},
    {code:"E010",libelle:"Entretien de bilan de phase"},
    {code:"E011",libelle:"Lettre"},
    {code:"E012",libelle:"Administratif (Evenement :modification interne)"},
    {code:"E013",libelle:"Visite (Jeune,Partenaire)"},
]

export const sensEvenement =[
   "Structure vers jeune",
    "Jeune vers Structure",
    "PSF vers jeune",
    "Jeune vers PSF",
   "PSF vers structure",
   "Structure vers PSF"
]
export const doctype=[
    'Attestation de stage',
    "Attestation d'identité",
    "Carte nationale d'identitté(CNI)",
    'Cassier judiciaire',
    'Certificat de nationalité',
    'Certificat de travail',
    'Curriculum vitae (CV)',
    'Diplôme',
    'Photo'    
]

export const dispositiSortie=[
    "Abandon du jeune",
    "Abandonné",
    "Absence à convocation",
    "Accès à l'autonomie \"avec situation active\"",
    "Accès à l'autonomie \"sans situation active\"",
    "Accompagnement emploi jeune",
    "Arrêt d'un accord commun",
    "Autre (motif de sortie)",
    "CDD chez l'employeur",
    "CDI chez l'employeur",
    "Décès",
    "Déménagement",
    "Emploi durable",
    "Fin de la durée du programme",
    "Formalisation projet réalisée",
    "Interruption constatée",
    "Interruption formalisée",
    "Non disponible",
    "Non renouvellement",
    "Non-respect des engagements",
    "Objectif atteint (hors Emploi ou Formation)",
    "Radiation",
    "Réalisé",
    "Renouvellement",
    "Réorientation",
    "Reprise Emploi déclaré",
    "Rupture anticipée - accord conventionnel",
    "Rupture anticipée : changement d'emploi",
    "Rupture anticipée : formation, reprise d'étude",
    "Rupture anticipée : raisons personnelles",
    "Rupture anticipée du Ct de travail par l'employeur",
    "Sortie pour accès contrat par alternance",
    "Sortie pour accès emploi",
    "Sortie pour accès formation"
]

export const dispositifs =[
    {code:'D001', libelle:"Conseil en Évolution Professionnelle (CEP)"},
    {code:'D002', libelle:"Contrat d'Insertion dans la Vie Sociale (CIVIS)"},
    {code:'D003', libelle:"Emploi d'avenir (Mise en rélation avec un employeur)"},
    {code:'D004', libelle:"Parcours contractualisé d'accompagnement vers l'emploi et l'autonomie (PACEA)"},
    {code:'D005', libelle:"Parrainage"},
    {code:'D006', libelle:"Projet Personnalisé d'Accès à l'Emploi"},
    {code:'D007', libelle:"Accompagnement vers l’apprentissage"}
]


export const villes=[
'Abengourou',
'Abidjan',
'Abié',
'Abigui',
'Abobo',
'Aboisso',
'Aboisso-Comoé',
'Abolikro',
'Abongoua',
'Aboudé',
'Abra',
'Abradinou',
'Abronamoué',
'Aby (Aboisso)',
'Aby-Adjouan-Mohoua',
'Adaou',
'Addah',
'Adessé',
'Adiaké',
'Adjamé',
'Adjaméné',
'Adjouan',
'Adouakouakro',
'Adoukro',
'Adzopé',
'Affalikro',
'Afféry',
'Affiénou',
'Afotobo',
'Agbaou-Ahéoua',
'Agboville',
'Agnia',
'Agnibilékrou',
'Agou',
'Ahigbé-Koffikro',
'Ahouabo-Bouapé',
'Ahouakro',
'Ahouanou',
'Ahougnanssou',
'Ahougnassou-Alahou',
'Akoboissué',
'Akounougbé',
'Akoupé',
'Akoupé-Zeudji',
'Akouré',
'Akradio',
'Akridou-Laddé',
'Alépé',
'Allangouassou',
'Allosso 2',
'Amanvi',
'Amélékia',
'Amian Kouassikro',
'Amoriakro',
'Ananda',
'Ananda (Daoukro)',
'Ananguié (Adzopé), (département d\'Adzopé)',
'Ananguié (Agboville), (département d\'Agboville)',
'Andé',
'Ando-Kékrénou',
'Angoda',
'Anianou',
'Aniassué',
'Annépé',
'Anno (Agboville)',
'Anoumaba',
'Anyama',
'Appimadoum',
'Appimandoum',
'Appoisso',
'Appouasso',
'Apprompron-Afêwa',
'Apprompronou',
'Arikokaha',
'Arokpa',
'Arrah',
'Assahara',
'Assalé-Kouassikro',
'Assandrè',
'Assié-Koumassi',
'Assikoi',
'Assinie-Mafia',
'Assuéfry',
'Attecoubé',
'Attiégouakro',
'Attiékoi',
'Attiguéhi',
'Attinguié',
'Attobrou',
'Attokro',
'Attoutou ',
'Ayamé',
'Ayaou-Sran',
'Ayénouan',
'Azaguié',
'Babakro',
'Bacanda',
'Bacon',
'Badikaha',
'Bagohouo',
'Bakandesso-Sogbeni',
'Bakanou',
'Bako',
'Bakoubli',
'Baléko',
'Bambalouma',
'Bamoro',
'Bandakagni Tomora',
'Bandakagni-Sokoura',
'Bandiahi',
'Bangolo',
'Bangoua',
'Banneu',
'Bannonfla',
'Bassawa',
'Baya (Kouto)',
'Bayota',
'Bazra-Nattis',
'Bazré',
'Bécédi Brignan',
'Bécouéfin',
'Bédiala',
'Bédi-Goazon',
'Bédy-Goazon',
'Bégbessou',
'Belleville, (département de Bouaké)',
'Belleville, (département de Zoukougbeu)',
'Bengassou',
'Béoué-Zibiao',
'Béoumi',
'Béréni Dialla',
'Bériaboukro',
'Bettié',
'Biakalé',
'Biankouma',
'Bianouan',
'Biasso',
'Biéby',
'Bilimono',
'Binao-Boussoué',
'Bingerville',
'Bin-Houyé',
'Binzra',
'Bla',
'Blanfla',
'Blapleu',
'Bléniméouin',
'Blességué',
'Bloléquin',
'Blotilé',
'Boahia',
'Bobi',
'Bobo-Tiénigbé',
'Bocanda',
'Bodo (Didiévi), (département de Didiévi)',
'Bodo (Tiassalé), (département de Tiassalé)',
'Bodokro',
'Bogofa',
'Bogouiné',
'Boguédia',
'Bohobli',
'Bokala-Niampondougou',
'Boli',
'Bolona',
'Bonahouin',
'Bondo',
'Bondoukou',
'Bongo (Grand-Bassam)',
'Bongouanou',
'Bonguéra',
'Boniérédougou',
'Bonikro',
'Bonon',
'Bonoua',
'Bonoufla',
'Booko',
'Boron (Korhogo)',
'Borotou',
'Borotou-Koro',
'Botindé',
'Botro',
'Bouadikro',
'Bouaflé',
'Bouaké',
'Bouandougou',
'Bouboury',
'Boudépé',
'Bougou',
'Bougousso',
'Bouko',
'Bouna',
'Boundiali',
'Boyaokro',
'Bozi',
'Bricolo',
'Brihiri',
'Brima',
'Brobo',
'Brofodoumé',
'Broma',
'Brou Ahoussoukro',
'Brou Akpaoussou',
'Broubrou',
'Broudoukou-Penda',
'Buyo (Côte d\'Ivoire)',
'Céchi',
'Chiépo',
'Cocody',
'Cosrou',
'Dabadougou-Mafélé',
'Dabakala',
'Dabou',
'Dabouyo',
'Dadiassé',
'Dagba',
'Dahiépa-Kéhi',
'Dahiri',
'Dah-Zagna',
'Dairo-Didizo',
'Dakouritrohoin',
'Dakpadou',
'Daleu',
'Daloa',
'Damé',
'Danané',
'Dananon',
'Dandougou',
'Danguira',
'Dania',
'Danoa',
'Dantogo',
'Daoukro',
'Dapéoua',
'Dapo-Iboké',
'Dassioko',
'Dassoungboho',
'Débété',
'Dèdègbeu',
'Détroya',
'Diabo',
'Diahouin',
'Dialakoro',
'Diamakani',
'Diamarakro',
'Diamba',
'Diangobo (Abengourou), (département d\'Abengourou)',
'Diangobo (Yakassé-Attobrou), (département de Yakassé-Attobrou)',
'Diangokro',
'Dianra',
'Dianra-Village',
'Diarabana',
'Diasson',
'Diawala',
'Diawalla',
'Dibobly',
'Diboké',
'Dibri-Assirikro',
'Didiévi',
'Didoko',
'Diégonéfla',
'Diéméressédougou',
'Diéouzon',
'Diéviéssou',
'Digbapia',
'Dignago',
'Dikodougou',
'Dimandougou',
'Dimbokro',
'Dinaoudi',
'Dingbi',
'Dioman',
'Dioulatièdougou',
'Diourouzon',
'Divo',
'Djamadjoké',
'Djapadji',
'Djébonouan',
'Djékanou',
'Djibrosso',
'Djidji',
'Djoro-Djoro',
'Djouroutou',
'Doba (Côte d\'Ivoire)',
'Dobré',
'Dogbo',
'Doh (Touba)',
'Doké',
'Domangbeu',
'Doropo',
'Doudoukou',
'Douèlé',
'Dougroupalégnoa',
'Doukouya',
'Doukouyo',
'Dousséba',
'Dribouo',
'Dualla',
'Duékoué',
'Duffrébo',
'Duonfla',
'Dzeudji',
'Ebikro-N’dakro',
'Ebilassokro',
'Ebonou',
'Eboué (Aboisso)',
'Ehuasso',
'Ellibou-Badasso',
'Eloka',
'Ettrokro',
'Etuéboué',
'Etuessika',
'Facobly',
'Fadiadougou',
'Famienkro',
'Fapaha-M’binguébougou',
'Faraba (Mankono)',
'Fengolo',
'Férémandougou',
'Férentéla',
'Ferkessédougou',
'Finessiguédougou',
'Fizanlouma',
'Flakièdougou',
'Foto-Kouamékro',
'Foumbolo',
'Foungbesso',
'Frambo',
'Fresco',
'Fronan',
'Gabia (Issia), (département d\'Issia)',
'Gabia (Oumé), (département d\'Oumé)',
'Gabiadji',
'Gadago',
'Gagnoa',
'Gagny (Côte d\'Ivoire)',
'Galébou',
'Ganaoni',
'Ganhoué',
'Ganleu',
'Gaoté',
'Gbablasso',
'Gbadjié',
'Gbagbam',
'Gbamélédougo',
'Gbangbégouiné',
'Gbangbégouiné-Yati',
'Gbapleu',
'Gbatongouin',
'Gbazoa',
'Gbèkèkro',
'Gbéléban',
'Gbétogo',
'Gbliglo',
'Gbofesso-Sama',
'Gbogolo',
'Gboguhé',
'Gbon',
'Gbongaha',
'Gbon-Houyé',
'Gbonné',
'Glangleu',
'Gligbeuadji',
'Gloplou',
'Gnagbodougnoa',
'Gnagboya',
'Gnago',
'Gnakouboué',
'Gnamanou',
'Gnato',
'Gnégrouboué',
'Gnogboyo',
'Godélilié 1',
'Gogné',
'Gogo (Côte d\'Ivoire)',
'Gogoguhé',
'Gohitafla',
'Gohouo-Zagna',
'Gomon',
'Gonaté',
'Gopoupleu',
'Gotongouiné 1',
'Gouané',
'Goudi',
'Goudouko',
'Gouékan',
'Gouenzou',
'Gouiné',
'Goulaleu',
'Goulia',
'Gouméré',
'Gouotro',
'Gourané',
'Grabo',
'Gragba-Dagolilié',
'Grand Alépé',
'Grand-Akoudzin',
'Grand-Bassam',
'Grand-Béréby',
'Grand-Lahou',
'Grand-Morié',
'Grand-Pin',
'Grand-Yapo',
'Grand-Zattry',
'Grégbeu',
'Grihiri',
'Grobiakoko',
'Grobonou-Dan',
'Guéhiébly',
'Guékpé',
'Guénimanzo',
'Guépahouo',
'Guessabo',
'Guessiguié',
'Guéyo',
'Guézon (Duékoué) (département de Duékoué)',
'Guézon (Kouibly) (département de Kouibly)',
'Guézon-Tahouaké',
'Guiamapleu',
'Guibéroua',
'Guiembé',
'Guiendé',
'Guiglo',
'Guiméyo',
'Guinglo-Gbéan',
'Guinglo-Tahouaké',
'Guintéguéla',
'Guitry',
'Hérébo',
'Hermankono-Diès',
'Hermankono-Garo',
'Hiré',
'Huafla',
'Iboguhé',
'Iboké',
'Idibouo-Zépréguhé (Daloa Est)',
'Ipouagui',
'Iriéfla',
'Irobo',
'Iroporia',
'Issia',
'Jacqueville',
'Kaadé',
'Kadéko',
'Kadioha',
'Kafoudougou-Bambarasso',
'Kagbolodougou',
'Kahin-Zarabaon',
'Kakpi',
'Kalaha',
'Kalamon',
'Kaloa',
'Kamala',
'Kamalo',
'Kamoro',
'Kanagonon',
'Kanakono',
'Kanawolo',
'Kani',
'Kaniasso',
'Kanoroba',
'Kanzra',
'Kaouara',
'Karakoro',
'Kasséré',
'Katchiré-Essékro',
'Katiali',
'Katimassou',
'Katiola',
'Kato (Séguéla)',
'Katogo',
'Kawolo-Sobara',
'Kébi',
'Ké-Bouébo',
'Kéibla',
'Kéibly',
'Kétesso',
'Kétro-Bassam',
'Kibouo',
'Kiélé',
'Kiémou',
'Kimbirila Nord',
'Kimbirila Sud',
'Klan',
'Kodiossou',
'Koffi-Amonkro',
'Koffikro-Afféma',
'Koko (Bouaké), (département de Bouaké)',
'Koko (Korhogo), (département de Korhogo)',
'Kokolopozo',
'Kokomian',
'Kokoumba',
'Kokoun',
'Kokumbo',
'Kolia',
'Kombolokoura',
'Komborodougou',
'Konan Kokorékro',
'Konandikro',
'Konan-N’drikro',
'Kondiébouma',
'Kondokro-Djassanou',
'Kondossou',
'Kondrobo',
'Kong',
'Kongasso',
'Kongoti',
'Koni',
'Kononfla',
'Koonan',
'Koréahinou',
'Korhogo',
'Koro',
'Koro (Côte d\'Ivoire)',
'Korokaha',
'Korokopla',
'Koroumba',
'Kossandji',
'Kosséhoa',
'Kossihouen',
'Kossou',
'Kotobi',
'Kotogwanda',
'Kotronou',
'Koua',
'Kouadioblékro',
'Kouadiokro',
'Kouafo-Akidom',
'Kouakro',
'Kouaméfla',
'Kouan-Houlé',
'Kouassi Kouassikro',
'Kouassia-Nanguni',
'Kouassi-Datèkro',
'Kouassi-N’Dawa',
'Kouatta',
'Koudougou',
'Kouétinfla',
'Kouibly',
'Koulalé',
'Koulikoro (Biankouma)',
'Koumassi',
'Koumbala',
'Kounahiri',
'Koun-Fao',
'Kounzié',
'Kouto',
'Koutouba',
'Koyékro',
'Koziayo 1',
'Kpada',
'Kpana-Kalo',
'Kpanan',
'Kpanpleu-Sin-Houyé',
'Kpapékou',
'Kpata',
'Kpèbo',
'Kpotè',
'Kpouèbo',
'Krégbé',
'Kreuzoukoué',
'Krindjabo',
'Krofoinsou',
'Labokro',
'Lafokpokaha',
'Lahou Kpandah',
'Lahouda',
'Lakota',
'Lamékaha (Ferkessédougou), (département de Ferkessédougou)',
'Lamékaha (Korhogo), (département de Korhogo)',
'Landiougou',
'Languibonou',
'Laoudi-Ba',
'Larabia',
'Lataha',
'Lauzoua',
'Léléblé',
'Lengbrè',
'Lessiri',
'Ligrohoin',
'Liliy',
'Lissolo-Sobara',
'Lobakuya',
'Lobogba',
'Logbonou',
'Logoualé',
'Logroan (Daloa Sud)',
'Lolobo',
'Lolobo (Béoumi), (département de Béoumi)',
'Lolobo (Yamoussoukro), (département de Yamoussoukro)',
'Lomokankro',
'Lopou',
'Loukou-Yaokro',
'Loviguié',
'Luénoufla',
'M\'Bahiakro',
'M\'batto',
'M’bahiakro',
'M’batto',
'M’bengué',
'M’bonoua',
'M’Borla-Dioulasso',
'M’brou',
'M’possa',
'Mabéhiri 1',
'Mabouo',
'Madinani',
'Maféré',
'Maguéhio',
'Mahalé',
'Mahandiana Soukourani',
'Mahandougou',
'Mahapleu',
'Mahino',
'Makey-Liboli',
'Mamini',
'Maminigui',
'Man',
'Manabri',
'Mandougou',
'Manfla',
'Mangouin-Yrongouin',
'Mankono',
'Mantongouiné',
'Manzanouan',
'Marabadiassa',
'Marahoué',
'Marandallah',
'Marcory',
'Massadougou',
'Massala (Séguéla)',
'Massala-Barala',
'Mayo',
'Méagui',
'Médon',
'Mékro',
'Memni',
'Ménéké',
'Méo',
'Miadzin',
'Mignoré',
'Minfla',
'Minignan',
'Moapé',
'Molonou',
'Molonou-Blé',
'Momirasso',
'Monga',
'Mongbara',
'Monoko Zohi',
'Monongo',
'Mont Korhogo',
'Morokinkro',
'Morokro',
'Morondo',
'Moronou',
'Moussobadougou',
'N\'douci',
'N\'Gokro',
'N’dakro',
'N’dénou',
'N’déou',
'N’douci',
'N’douffoukankro',
'N’doukahakro',
'N’gangoro-Attoutou',
'N’Ganon',
'N’Gattadolikro',
'N’Gattakro',
'N’Gban Kassê',
'N’gohinou',
'N’Goloblasso',
'N’gribo-Takikro',
'N’guessan-Brindoukro',
'N’Guessankro (Béoumi), (département de Béoumi)',
'N’Guessankro (Bongouanou), (département de Bongouanou)',
'N’guiémé',
'N’Guyakro',
'N’Zécrézessou',
'N’Zianouan',
'N’Zi-N’Ziblékro',
'N’Zué-Kokoré',
'Nafana (Ferkessédougou), (département de Ferkessédougou)',
'Nafana (Prikro)',
'Nafana (Prikro), (département de Prikro)',
'Nafana Sienso',
'Nafoun',
'Nahio',
'Namahounondougou',
'Namané',
'Namassi',
'Nambingué',
'Nandjélé-Ségbéré',
'Napié',
'Nassian',
'Nébo (Divo)',
'Néguépié',
'Néko',
'Nézobly',
'Niablé',
'Niakaramandougou',
'Niakoblognoa',
'Niamana (Odienné)',
'Niambézaria',
'Niazaroko',
'Nidrou',
'Niédiékaha',
'Niellé',
'Niéméné',
'Nigui Assoko',
'Nigui Saff',
'Niofoin',
'Niokosso',
'Niorouhio',
'Niouldé',
'Nizahon',
'Noé (Tiapoum)',
'Nofou',
'Nouamou',
'Odienné',
'Offa (Agboville)',
'Offoumpo',
'Oghlwapo',
'Ogoudou',
'Okrouyo',
'Olodio',
'Ondéfidouo',
'Ono',
'Orbaff',
'Oress-Krobou',
'Ottawa',
'Ottopé',
'Ouangolodougou',
'Ouaninou',
'Ouattaradougou',
'Ouédallah',
'Ouellé',
'Ouéoulo',
'Ouffouédiékro',
'Oumé',
'Oupoyo',
'Ouragahio',
'Ouréguékaha',
'Ousrou',
'Ouyably-Gnondrou',
'Pacobo',
'Pakouabo',
'Pambasso-Diédou',
'Pangalakaha',
'Paoufla',
'Papara (Tengréla)',
'Para (Tabou)',
'Pauly',
'Péguékaha',
'Péhé',
'Péhékanhouébli',
'Pélézi',
'Pétigoa 2',
'Petit Guiglo',
'Pinda-Boroko',
'Pinhou',
'Pitiengomon',
'Plateau',
'Pleuro',
'Podiagouiné',
'Podoué',
'Pogo (Ouangolodougou)',
'Pokréagui',
'Ponondougou',
'Port-Bouët',
'Poumbly',
'Pranouan',
'Prikro',
'Raviart',
'Roa',
'Rubino',
'Saboudougou',
'Sago',
'Sahébo',
'Sahuyé',
'Saïoua',
'Sakahouo',
'Sakassou',
'Sakré',
'Samango',
'Samanza',
'Samatiguila',
'Saminikro',
'Sandala',
'Sandégué',
'Sandougou-Soba',
'Sangouiné',
'Sankadiokro',
'San-Pédro',
'Santa (Biankouma) (département de Biankouma)',
'Santa (Touba) (département de Touba)',
'Sapli-Sépingo',
'Sarhala',
'Sassako Bégnini',
'Sassandra',
'Satama-Sokoro',
'Satama-Sokoura',
'Satikran',
'Satroko',
'Sébédoufla',
'Séguéla',
'Séguélon',
'Séileu',
'Séitifla',
'Sémien',
'Sépikaha',
'Sérébissou',
'Sérihi',
'Seydougou',
'Sianhala',
'Siansoba',
'Siempurgo',
'Sifié',
'Sikensi',
'Sikolo',
'Silakoro',
'Sinématiali',
'Sinfra',
'Singo',
'Siolokaha',
'Sipilou',
'Sirana',
'Sirasso',
'Siriho',
'Sissédougou',
'Soaékpé-Douédy',
'Soba (Korhogo)',
'Soba-Banandjé',
'Sohouo',
'Sokala-Sobara',
'Sokoro',
'Sokorodougou',
'Sokourala-Mahou',
'Sokrogbo',
'Sokrogbo-Carrefour',
'Sominassé',
'Somokoro',
'Songan',
'Songon',
'Songori',
'Sononzo',
'Sorobango',
'Soubré',
'Soukourougban',
'Soungassou',
'Subiakro',
'Taabo',
'Taabo-Village',
'Tabagne',
'Tabayo 1',
'Tabléguikou',
'Taboth',
'Tabou',
'Tafiré',
'Tagadi',
'Tahakro',
'Tahibly',
'Taï',
'Takikro',
'Takoréagui',
'Tambi',
'Tanda',
'Tangafla',
'Tanguélan',
'Tankessé',
'Taoudi',
'Tapéguia',
'Tawara',
'Téapleu',
'Téguéla',
'Téhini',
'Téhiri',
'Tendéné-Bambarasso',
'Tengréla',
'Tézié',
'Tiagba',
'Tiapoum',
'Tiassalé',
'Tibéita',
'Tié N’diékro',
'Tiébissou',
'Tiédio',
'Tiégba',
'Tiékpé',
'Tiémé',
'Tiémélékro',
'Tiénindiéri',
'Tiéningboué',
'Tienko (département de Minignan)',
'Tienko (département de Touba)',
'Tienkoikro',
'Tiény-Séably',
'Tiéolé-Oula',
'Tiéviéssou',
'Timbé',
'Tingréla',
'Tinhou',
'Tiobli',
'Tiobly',
'Tioro',
'Tofla',
'Togoniéré',
'Toliesso',
'Tomono',
'Tonla',
'Torossanguéhi',
'Tortiya',
'Totrodrou',
'Touba',
'Toubalo',
'Tougbo',
'Touih',
'Toukouzou',
'Toulepleu',
'Toumodi',
'Toumodi-Sakassou',
'Toumoukoro',
'Toupah',
'Toutoubré',
'Trafesso',
'Transua',
'Treichville',
'Vaafla',
'Vao',
'Varalé',
'Vavoua',
'Vouéboufla',
'Walèbo',
'Waté',
'Wonséaly',
'Worofla',
'Yaakro',
'Yabayo',
'Yacolidabouo',
'Yakassé-Attobrou',
'Yakassé-Feyassé',
'Yakassé-Mé',
'Yakpabo-Sakassou',
'Yala (Vavoua)',
'Yamoussoukr',
'Yamoussoukro',
'Yaossédougou',
'Yaou',
'Yapleu',
'Yelleu',
'Yérétiélé',
'Yézimala',
'Yobouakro',
'Yocoboué',
'Yokoréa',
'Yoourédoula',
'Yopohué',
'Yopougon',
'Yorobodi',
'Yorodougou',
'Yrozon',
'Zagné',
'Zagoréta-Gadouan',
'Zagoué (Man)',
'Zaguiéta',
'Zaguinasso',
'Zahia',
'Zaïbo',
'Zakoéoua',
'Zaliohouan',
'Zambakro',
'Zanzansou',
'Zanzra',
'Zaranou',
'Zatta',
'Zéaglo',
'Zébouo Nord (Daloa Nord)',
'Zébra',
'Zédé-Dianhoun',
'Zégo',
'Zéménafla-V',
'Zéo',
'Zérégbo',
'Zialégréhoa (Grand-Zia)',
'Ziki-Diès',
'Zikisso',
'Ziogouiné',
'Zirifla',
'Zokoguhé',
'Zonneu',
'Zorofla',
'Zou',
'Zouan-Hounien',
'Zouatta',
'Zougounéfla',
'Zoukougbeu',
'Zoukpangbeu',
'Zoupleu',
'Zraluo',
'Zro',
'Zuénoula'
]

export const communes =[
'Abengourou',
'Abié',
'Abigui',
'Abobo',
'Aboisso',
'Aboisso-Comoé',
'Abolikro',
'Abongoua',
'Abongoua',
'Aboudé',
'Abra',
'Abradinou',
'Abronamoué',
'Aby (Aboisso)',
'Aby-Adjouan-Mohoua',
'Adaou',
'Addah',
'Adessé',
'Adiaké',
'Adjamé',
'Adjaméné',
'Adjouan',
'Adouakouakro',
'Adoukro',
'Adzopé',
'Affalikro',
'Afféry',
'Affiénou',
'Afotobo',
'Agbaou-Ahéoua',
'Agboville',
'Agnia',
'Agnibilékrou',
'Agou',
'Ahigbé-Koffikro',
'Ahouabo-Bouapé',
'Ahouakro',
'Ahouanou',
'Ahougnanssou',
'Ahougnassou-Alahou',
'Akoboissué',
'Akounougbé',
'Akoupé',
'Akoupé-Zeudji',
'Akouré',
'Akradio',
'Akridou-Laddé',
'Alépé',
'Allangouassou',
'Allosso 2',
'Amanvi',
'Amélékia',
'Amian Kouassikro',
'Amoriakro',
'Ananda (Daoukro)',
'Ananguié (Agboville), (département d\'Agboville)',
'Ananguié (Adzopé), (département d\'Adzopé)',
'Andé',
'Ando-Kékrénou',
'Angoda',
'Anianou',
'Aniassué',
'Annépé',
'Anno (Agboville)',
'Anoumaba',
'Anyama',
'Appimandoum',
'Appoisso',
'Appouasso',
'Apprompron-Afêwa',
'Apprompronou',
'Arikokaha',
'Arokpa',
'Arrah',
'Assahara',
'Assalé-Kouassikro',
'Assandrè',
'Assié-Koumassi',
'Assikoi',
'Assinie-Mafia',
'Assuéfry',
'Attecoubé',
'Attiégouakro',
'Attiékoi',
'Attiguéhi',
'Attinguié',
'Attobrou',
'Attokro',
'Attoutou ',
'Ayamé',
'Ayaou-Sran',
'Ayénouan',
'Azaguié',
'Babakro',
'Bacanda',
'Bacon',
'Badikaha',
'Bagohouo',
'Bakandesso-Sogbeni',
'Bakanou',
'Bako',
'Bakoubli',
'Baléko',
'Bambalouma',
'Bamoro',
'Bandakagni Tomora',
'Bandakagni-Sokoura',
'Bandiahi',
'Bangolo',
'Bangoua',
'Banneu',
'Bannonfla',
'Bassawa',
'Baya (Kouto)',
'Bayota',
'Bazra-Nattis',
'Bazré',
'Bécédi Brignan',
'Bécouéfin',
'Bédiala',
'Bédy-Goazon',
'Bégbessou',
'Belleville, (département de Bouaké)',
'Belleville, (département de Zoukougbeu)',
'Bengassou',
'Béoué-Zibiao',
'Béoumi',
'Béréni Dialla',
'Bériaboukro',
'Bettié',
'Biakalé',
'Biankouma',
'Bianouan',
'Biasso',
'Biéby',
'Bilimono',
'Bin-Houyé',
'Binao-Boussoué',
'Bingerville',
'Binzra',
'Bla',
'Blanfla',
'Blapleu',
'Bléniméouin',
'Blességué',
'Bloléquin',
'Blotilé',
'Boahia',
'Bobi',
'Bobo-Tiénigbé',
'Bocanda',
'Bodo (Didiévi), (département de Didiévi)',
'Bodo (Tiassalé), (département de Tiassalé)',
'Bodokro',
'Bogofa',
'Bogouiné',
'Boguédia',
'Bohobli',
'Bokala-Niampondougou',
'Boli',
'Bolona',
'Bonahouin',
'Bondo',
'Bondoukou',
'Bongo (Grand-Bassam)',
'Bongouanou',
'Bonguéra',
'Boniérédougou',
'Bonikro',
'Bonon',
'Bonoua',
'Bonoufla',
'Booko',
'Boron (Korhogo)',
'Borotou',
'Borotou-Koro',
'Botindé',
'Botro',
'Bouadikro',
'Bouaflé',
'Bouandougou',
'Bouboury',
'Boudépé',
'Bougou',
'Bougousso',
'Bouko',
'Bouna',
'Boundiali',
'Boyaokro',
'Bozi',
'Bricolo',
'Brihiri',
'Brima',
'Brobo',
'Brofodoumé',
'Broma',
'Brou Ahoussoukro',
'Brou Akpaoussou',
'Broubrou',
'Broudoukou-Penda',
'Buyo (Côte d\'Ivoire)',
'Céchi',
'Chiépo',
'Cocody',
'Cosrou',
'Dabadougou-Mafélé',
'Dabakala',
'Dabou',
'Dabouyo',
'Dadiassé',
'Dagba',
'Dah-Zagna',
'Dahiépa-Kéhi',
'Dahiri',
'Dairo-Didizo',
'Dakouritrohoin',
'Dakpadou',
'Daleu',
'Damé',
'Danané',
'Dananon',
'Dandougou',
'Danguira',
'Dania',
'Danoa',
'Dantogo',
'Daoukro',
'Dapéoua',
'Dapo-Iboké',
'Dassioko',
'Dassoungboho',
'Débété',
'Dèdègbeu',
'Détroya',
'Diabo',
'Diahouin',
'Dialakoro',
'Diamakani',
'Diamarakro',
'Diamba',
'Diangobo (Abengourou), (département d\'Abengourou)',
'Diangobo (Yakassé-Attobrou), (département de Yakassé-Attobrou)',
'Diangokro',
'Dianra',
'Dianra-Village',
'Diarabana',
'Diasson',
'Diawala',
'Dibobly',
'Diboké',
'Dibri-Assirikro',
'Didiévi',
'Didoko',
'Diégonéfla',
'Diéméressédougou',
'Diéouzon',
'Diéviéssou',
'Digbapia',
'Dignago',
'Dikodougou',
'Dimandougou',
'Dimbokro',
'Dinaoudi',
'Dingbi',
'Dioman',
'Dioulatièdougou',
'Diourouzon',
'Divo',
'Djamadjoké',
'Djapadji',
'Djébonouan',
'Djékanou',
'Djibrosso',
'Djidji',
'Djoro-Djoro',
'Djouroutou',
'Doba (Côte d\'Ivoire)',
'Dobré',
'Dogbo',
'Doh (Touba)',
'Doké',
'Domangbeu',
'Doropo',
'Douèlé',
'Dougroupalégnoa',
'Doukouya',
'Doukouyo',
'Dousséba',
'Dribouo',
'Dualla',
'Duékoué',
'Duffrébo',
'Duonfla',
'Dzeudji',
'Ebikro-N’dakro',
'Ebilassokro',
'Ebonou',
'Eboué (Aboisso)',
'Ehuasso',
'Ellibou-Badasso',
'Eloka',
'Ettrokro',
'Etuéboué',
'Etuessika',
'Facobly',
'Fadiadougou',
'Famienkro',
'Fapaha-M’binguébougou',
'Faraba (Mankono)',
'Fengolo',
'Férémandougou',
'Férentéla',
'Ferkessédougou',
'Finessiguédougou',
'Fizanlouma',
'Flakièdougou',
'Foto-Kouamékro',
'Foumbolo',
'Foungbesso',
'Frambo',
'Fresco',
'Fronan',
'Gabia (Issia), (département d\'Issia)',
'Gabia (Oumé), (département d\'Oumé)',
'Gabiadji',
'Gadago',
'Gagnoa',
'Gagny (Côte d\'Ivoire)',
'Galébou',
'Ganaoni',
'Ganhoué',
'Ganleu',
'Gaoté',
'Gbablasso',
'Gbadjié',
'Gbagbam',
'Gbamélédougo',
'Gbangbégouiné',
'Gbangbégouiné-Yati',
'Gbapleu',
'Gbatongouin',
'Gbazoa',
'Gbèkèkro',
'Gbéléban',
'Gbétogo',
'Gbliglo',
'Gbofesso-Sama',
'Gbogolo',
'Gboguhé',
'Gbon',
'Gbon-Houyé',
'Gbongaha',
'Gbonné',
'Glangleu',
'Gligbeuadji',
'Gloplou',
'Gnagbodougnoa',
'Gnagboya',
'Gnago',
'Gnakouboué',
'Gnamanou',
'Gnato',
'Gnégrouboué',
'Gnogboyo',
'Godélilié 1',
'Gogné',
'Gogo (Côte d\'Ivoire)',
'Gogoguhé',
'Gohitafla',
'Gohouo-Zagna',
'Gomon',
'Gonaté',
'Gopoupleu',
'Gotongouiné 1',
'Gouané',
'Goudi',
'Goudouko',
'Gouékan',
'Gouenzou',
'Gouiné',
'Goulaleu',
'Goulia',
'Gouméré',
'Gouotro',
'Gourané',
'Grabo',
'Gragba-Dagolilié',
'Grand Alépé',
'Grand-Bassam',
'Grand-Béréby',
'Grand-Lahou',
'Grand-Morié',
'Grand-Pin',
'Grand-Yapo',
'Grand-Zattry',
'Grand-Akoudzin',
'Grégbeu',
'Grihiri',
'Grobiakoko',
'Grobonou-Dan',
'Guéhiébly',
'Guékpé',
'Guénimanzo',
'Guépahouo',
'Guessabo',
'Guessiguié',
'Guéyo',
'Guézon (Duékoué) (département de Duékoué)',
'Guézon (Kouibly) (département de Kouibly)',
'Guézon-Tahouaké',
'Guiamapleu',
'Guibéroua',
'Guiembé',
'Guiendé',
'Guiglo',
'Guiméyo',
'Guinglo-Gbéan',
'Guinglo-Tahouaké',
'Guintéguéla',
'Guitry',
'Hérébo',
'Hermankono-Diès',
'Hermankono-Garo',
'Hiré',
'Huafla',
'Iboguhé',
'Iboké',
'Idibouo-Zépréguhé (Daloa Est)',
'Ipouagui',
'Iriéfla',
'Irobo',
'Iroporia',
'Issia',
'Jacqueville',
'Kaadé',
'Kadéko',
'Kadioha',
'Kafoudougou-Bambarasso',
'Kagbolodougou',
'Kahin-Zarabaon',
'Kakpi',
'Kalaha',
'Kalamon',
'Kaloa',
'Kamala',
'Kamalo',
'Kamoro',
'Kanagonon',
'Kanakono',
'Kanawolo',
'Kani',
'Kaniasso',
'Kanoroba',
'Kanzra',
'Kaouara',
'Karakoro',
'Kasséré',
'Katchiré-Essékro',
'Katiali',
'Katimassou',
'Katiola',
'Kato (Séguéla)',
'Katogo',
'Kawolo-Sobara',
'Ké-Bouébo',
'Kébi',
'Kéibla',
'Kéibly',
'Kétesso',
'Kétro-Bassam',
'Kibouo',
'Kiélé',
'Kiémou',
'Kimbirila Nord',
'Kimbirila Sud',
'Klan',
'Kodiossou',
'Koffi-Amonkro',
'Koffikro-Afféma',
'Koko (Bouaké), (département de Bouaké)',
'Koko (Korhogo), (département de Korhogo)',
'Kokolopozo',
'Kokomian',
'Kokoumba',
'Kokoun',
'Kokumbo',
'Kolia',
'Kombolokoura',
'Komborodougou',
'Konan Kokorékro',
'Konan-N’drikro',
'Konandikro',
'Kondiébouma',
'Kondokro-Djassanou',
'Kondossou',
'Kondrobo',
'Kong',
'Kongasso',
'Kongoti',
'Koni',
'Kononfla',
'Koonan',
'Koréahinou',
'Koro (Côte d\'Ivoire)',
'Korokaha',
'Korokopla',
'Koroumba',
'Kossandji',
'Kosséhoa',
'Kossihouen',
'Kossou',
'Kotobi',
'Kotogwanda',
'Kotronou',
'Koua',
'Kouadioblékro',
'Kouadiokro',
'Kouafo-Akidom',
'Kouakro',
'Kouaméfla',
'Kouan-Houlé',
'Kouassi-Datèkro',
'Kouassi Kouassikro',
'Kouassi-N’Dawa',
'Kouassia-Nanguni',
'Kouatta',
'Koudougou',
'Kouétinfla',
'Kouibly',
'Koulalé',
'Koulikoro (Biankouma)',
'Koumassi',
'Koumbala',
'Koun-Fao',
'Kounahiri',
'Kounzié',
'Kouto',
'Koutouba',
'Koyékro',
'Koziayo 1',
'Kpada',
'Kpana-Kalo',
'Kpanan',
'Kpanpleu-Sin-Houyé',
'Kpapékou',
'Kpata',
'Kpèbo',
'Kpotè',
'Kpouèbo',
'Krégbé',
'Kreuzoukoué',
'Krindjabo',
'Krofoinsou',
'Labokro',
'Lafokpokaha',
'Lahou Kpandah',
'Lahouda',
'Lakota',
'Lamékaha (Ferkessédougou), (département de Ferkessédougou)',
'Lamékaha (Korhogo), (département de Korhogo)',
'Landiougou',
'Languibonou',
'Laoudi-Ba',
'Larabia',
'Lataha',
'Lauzoua',
'Léléblé',
'Lengbrè',
'Lessiri',
'Ligrohoin',
'Liliy',
'Lissolo-Sobara',
'Lobakuya',
'Lobogba',
'Logbonou',
'Logoualé',
'Logroan (Daloa Sud)',
'Lolobo (Béoumi), (département de Béoumi)',
'Lolobo (Yamoussoukro), (département de Yamoussoukro)',
'Lomokankro',
'Lopou',
'Loukou-Yaokro',
'Loviguié',
'Luénoufla',
'M’bahiakro',
'M’batto',
'M’bengué',
'M’bonoua',
'M’Borla-Dioulasso',
'M’brou',
'M’possa',
'Mabéhiri 1',
'Mabouo',
'Madinani',
'Maféré',
'Maguéhio',
'Mahalé',
'Mahandiana Soukourani',
'Mahandougou',
'Mahapleu',
'Mahino',
'Makey-Liboli',
'Mamini',
'Maminigui',
'Man',
'Manabri',
'Mandougou',
'Manfla',
'Mangouin-Yrongouin',
'Mankono',
'Manzanouan',
'Marabadiassa',
'Marandallah',
'Marcory',
'Massadougou',
'Massala (Séguéla)',
'Massala-Barala',
'Mayo',
'Méagui',
'Médon',
'Mékro',
'Memni',
'Ménéké',
'Méo',
'Miadzin',
'Mignoré',
'Minfla',
'Minignan',
'Moapé',
'Molonou',
'Molonou-Blé',
'Momirasso',
'Monga',
'Mongbara',
'Monoko Zohi',
'Monongo',
'Mont Korhogo',
'Morokinkro',
'Morokro',
'Morondo',
'Moronou',
'Moussobadougou',
'N’dakro',
'N’dénou',
'N’déou',
'N’douci',
'N’douffoukankro',
'N’doukahakro',
'N’gangoro-Attoutou',
'N’Ganon',
'N’Gattadolikro',
'N’Gattakro',
'N’Gban Kassê',
'N’gohinou',
'N’Goloblasso',
'N’gribo-Takikro',
'N’guessan-Brindoukro',
'N’Guessankro (Béoumi), (département de Béoumi)',
'N’Guessankro (Bongouanou), (département de Bongouanou)',
'N’guiémé',
'N’Guyakro',
'N’Zécrézessou',
'N’Zi-N’Ziblékro',
'N’Zianouan',
'N’Zué-Kokoré',
'Nafana (Ferkessédougou), (département de Ferkessédougou)',
'Nafana (Prikro), (département de Prikro)',
'Nafana Sienso',
'Nafoun',
'Nahio',
'Namahounondougou',
'Namané',
'Namassi',
'Nambingué',
'Nandjélé-Ségbéré',
'Napié',
'Nassian',
'Nébo (Divo)',
'Néguépié',
'Néko',
'Nézobly',
'Niablé',
'Niakaramandougou',
'Niakoblognoa',
'Niamana (Odienné)',
'Niambézaria',
'Niazaroko',
'Nidrou',
'Niédiékaha',
'Niellé',
'Niéméné',
'Nigui Assoko',
'Nigui Saff',
'Niofoin',
'Niokosso',
'Niorouhio',
'Niouldé',
'Nizahon',
'Noé (Tiapoum)',
'Nofou',
'Nouamou',
'Odienné',
'Offa (Agboville)',
'Offoumpo',
'Oghlwapo',
'Ogoudou',
'Okrouyo',
'Olodio',
'Ondéfidouo',
'Ono',
'Orbaff',
'Oress-Krobou',
'Ottawa',
'Ottopé',
'Ouangolodougou',
'Ouaninou',
'Ouattaradougou',
'Ouédallah',
'Ouellé',
'Ouéoulo',
'Ouffouédiékro',
'Oumé',
'Oupoyo',
'Ouragahio',
'Ouréguékaha',
'Ousrou',
'Ouyably-Gnondrou',
'Pacobo',
'Pakouabo',
'Pambasso-Diédou',
'Pangalakaha',
'Paoufla',
'Papara (Tengréla)',
'Para (Tabou)',
'Pauly',
'Péguékaha',
'Péhé',
'Péhékanhouébli',
'Pélézi',
'Pétigoa 2',
'Petit Guiglo',
'Pinda-Boroko',
'Pinhou',
'Pitiengomon',
'Plateau',
'Pleuro',
'Podiagouiné',
'Podoué',
'Pogo (Ouangolodougou)',
'Pokréagui',
'Ponondougou',
'Port-Bouët',
'Poumbly',
'Pranouan',
'Prikro',
'Raviart',
'Roa',
'Rubino',
'Saboudougou',
'Sago',
'Sahébo',
'Sahuyé',
'Saïoua',
'Sakassou',
'((Sakahouo))',
'Sakré',
'Samango',
'Samanza',
'Samatiguila',
'Saminikro',
'San-Pédro',
'Sandala',
'Sandégué',
'Sandougou-Soba',
'Sangouiné',
'Sankadiokro',
'Santa (Biankouma) (département de Biankouma)',
'Santa (Touba) (département de Touba)',
'Sapli-Sépingo',
'Sarhala',
'Sassako Bégnini',
'Sassandra',
'Satama-Sokoro',
'Satama-Sokoura',
'Satikran',
'Satroko',
'Sébédoufla',
'Séguéla',
'Séguélon',
'Séileu',
'Séitifla',
'Sémien',
'Sépikaha',
'Sérébissou',
'Sérihi',
'Seydougou',
'Sianhala',
'Siansoba',
'Siempurgo',
'Sifié',
'Sikensi',
'Sikolo',
'Silakoro',
'Sinématiali',
'Sinfra',
'Singo',
'Siolokaha',
'Sipilou',
'Sirana',
'Sirasso',
'Siriho',
'Sissédougou',
'Soaékpé-Douédy',
'Soba (Korhogo)',
'Soba-Banandjé',
'Sohouo',
'Sokala-Sobara',
'Sokoro',
'Sokorodougou',
'Sokourala-Mahou',
'Sokrogbo',
'Sokrogbo-Carrefour',
'Sominassé',
'Somokoro',
'Songan',
'Songon',
'Songori',
'Sononzo',
'Sorobango',
'Soubré',
'Soukourougban',
'Soungassou',
'Subiakro',
'Taabo',
'Taabo-Village',
'Tabagne',
'Tabayo 1',
'Tabléguikou',
'Taboth',
'Tabou',
'Tafiré',
'Tagadi',
'Tahakro',
'Tahibly',
'Taï',
'Takikro',
'Takoréagui',
'Tambi',
'Tanda',
'Tangafla',
'Tanguélan',
'Tankessé',
'Taoudi',
'Tapéguia',
'Tawara',
'Téapleu',
'Téguéla',
'Téhini',
'Téhiri',
'Tendéné-Bambarasso',
'Tengréla',
'Tézié',
'Tiagba',
'Tiapoum',
'Tiassalé',
'Tibéita',
'Tié N’diékro',
'Tiébissou',
'Tiédio',
'Tiégba',
'Tiékpé',
'Tiémé',
'Tiémélékro',
'Tiénindiéri',
'Tiéningboué',
'Tienko (département de Minignan)',
'Tienko (département de Touba)',
'Tienkoikro',
'Tiény-Séably',
'Tiéolé-Oula',
'Tiéviéssou',
'Timbé',
'Tinhou',
'Tiobly',
'Tioro',
'Tofla',
'Togoniéré',
'Toliesso',
'Tomono',
'Tonla',
'Torossanguéhi',
'Tortiya',
'Totrodrou',
'Touba',
'Toubalo',
'Tougbo',
'Touih',
'Toukouzou',
'Toulepleu',
'Toumodi',
'Toumodi-Sakassou',
'Toumoukoro',
'Toupah',
'Toutoubré',
'Trafesso',
'Transua',
'Treichville',
'Vaafla',
'Vao',
'Varalé',
'Vavoua',
'Vouéboufla',
'Walèbo',
'Waté',
'Wonséaly',
'Worofla',
'Yaakro',
'Yabayo',
'Yacolidabouo',
'Yakassé-Attobrou',
'Yakassé-Feyassé',
'Yakassé-Mé',
'Yakpabo-Sakassou',
'Yala (Vavoua)',
'Yamoussoukro',
'Yaossédougou',
'Yaou',
'Yapleu',
'Yelleu',
'Yérétiélé',
'Yézimala',
'Yobouakro',
'Yocoboué',
'Yokoréa',
'Yoourédoula',
'Yopohué',
'Yopougon',
'Yorobodi',
'Yorodougou',
'Yrozon',
'Zagné',
'Zagoréta-Gadouan',
'Zagoué (Man)',
'Zaguiéta',
'Zaguinasso',
'Zahia',
'Zaïbo',
'Zakoéoua',
'Zaliohouan',
'Zambakro',
'Zanzansou',
'Zanzra',
'Zaranou',
'Zatta',
'Zéaglo',
'Zébouo Nord (Daloa Nord)',
'Zébra',
'Zédé-Dianhoun',
'Zégo',
'Zéménafla-V',
'Zéo',
'Zérégbo',
'Zialégréhoa (Grand-Zia)',
'Ziki-Diès',
'Zikisso',
'Ziogouiné',
'Zirifla',
'Zokoguhé',
'Zonneu',
'Zorofla',
'Zou',
'Zouan-Hounien',
'Zouatta',
'Zougounéfla',
'Zoukougbeu',
'Zoukpangbeu',
'Zoupleu',
'Zraluo',
'Zro',
'Zuénoula',
]

export const quartiers =[
'Vridi',
'Port bouet centre',
'Derriere - WHARF',
'Adjouffou',
'Jean-Folly',
'Gonzagueville',
'Brakre',
'Ako',
'Petit bassam',
'Anani',
'Anamou',
'Amangouakoi',
'Bakro',
'Abbeykro',
'Agbabou',
'Adjahui',
'Benegosso',
'N’guessanblekro',
'Ellokro',
'Mafible 1',
'Mafible 2',
'Cnra',
]

export const cg =[
    {quartier:"Vridi",denomination:"CGQ VRIDI CANAL",localisation:"",type:'cgq'},
    {quartier:"Vridi",denomination:"CGQ VRIDI 3",localisation:"",type:'cgq'},
    {quartier:"Vridi",denomination:"CGQ VRIDI CITE",localisation:"",type:'cgq'},

    {quartier:"Port bouet centre",denomination:"CGQ ABATOIR",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ SILOVE",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ OCEAN",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ PHARE",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ ESPOIR",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ MAIRIE",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ SOGEPHIA",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ HOPITAL",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ JERICHO",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ MARCHEE",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ PORT BOUET 4",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ LYCEE",localisation:"",type:'cgq'},
    {quartier:"Port bouet centre",denomination:"CGQ NOGBOU",localisation:"",type:'cgq'},

    {quartier:"Derriere - WHARF",denomination:"SIPIM",localisation:"CITE ATLANTIQUE - CITE SIRENE",type:'cgq'},
    {quartier:"Derriere - WHARF",denomination:"CGQ AEROPORTUAIRE",localisation:"CITE 345 - CITE DE L'AIR - CITE METEO-CITE POLICIERE - CITE DOUANE - CITE 18 LGTS 1 ET 2 - CITE GENDAMERIE - CITE EMMANUEL - SIPIM EXTENSION",type:'cgq'},
    {quartier:"Derriere - WHARF",denomination:"CGQ 48 LOGEMENTS",localisation:"SECTEUR COCOTERAIE - SECTEUR RUE 12 - SECTEUR ORYX-48 LOGEMENTS",type:'cgq'},
    
    {quartier:"Adjouffou",denomination:"CGQ CENTRE ESPOIR",localisation:"1er,2eme ET 3eme ARRET - CENTRE ESPOIR - IRAN - MOSQUEE",type:'cgq'},
    {quartier:"Adjouffou",denomination:"CGQ BELLEVILLE",localisation:"BELLE VILLE - AGNEBY - LIBERTE AN 2000-ESPOIR",type:'cgq'},
    {quartier:"Adjouffou",denomination:"CGQ CELESTE",localisation:"LIBERTE - ESPOIR - SOAGECI - COLAS - DOUGOUBA - BACHELIER",type:'cgq'},
    
    {quartier:"Jean-Folly",denomination:"CGQ CASIER",localisation:"CAISIER - MOSQUEE BACHIROU - CARREFOUR AIR FRANCE - CARREFOUR PAIN ISRAEL - ANTENNE - JEANNOT - AKA ANGUI - CHRIST-ROI - CARREFOUR MARCHE - MOBIBOIS - ST EXUPERY - MOSQUEE - AUTO-ECOLE",type:'cgq'},
    {quartier:"Jean-Folly",denomination:"CGQ SIABA",localisation:"BELLEVILLE A DROITE - AIR FRANCE 1 - SIABA - BELLE VILLE EXTENSION",type:'cgq'},
    
    {quartier:"Gonzagueville",denomination:"CGQ COMMISSARIAT",localisation:"PORTO - GRACE + CANAL - PETITE THERESE - WILLIAM BROWN  - SECTEUR A - SECTEUR B - SECTEUR C - SECTEUR D",type:'cgq'},
    {quartier:"Gonzagueville",denomination:"CGQ SANTE",localisation:"ABRAHAM - PIERRE ET MARIE CURIE - IRHO - USINE - LYCEE - PETIT PARIS",type:'cgq'},
    {quartier:"Gonzagueville",denomination:"CGQ CHAPELLE",localisation:"MARCHE - DOMAINE CATHOLIQUE - PENTECOTE - JERUSALEM - IBC - GUEDEGUE - SAINT LOUIS - BAD",type:'cgq'},
    {quartier:"Gonzagueville",denomination:"CGQ CEVENOLE",localisation:"AIR FRANCE 2 - DEUX PLATEAU - RENAISSANCE",type:'cgq'}
]


export const metiers =[
'Achats',
'Commercial', 
'Vente',
'Gestion',
'Comptabilité',
'Finance', 
'Informatique', 
'Nouvelles technologies',
'Juridique', 
'Management', 
'Direction générale',
'Marketing',
'Communication',
'Métiers de la santé et du social',
'Métiers des service',
'Métiers du BTP',
'Production',
'Maintenance', 
'Qualité',
'R&D', 
'Gestion de projets',
'RH', 
'Formation',
'Secrétariat', 
'Assistanat',
'Télémarketing', 
'Téléassistance',
'Tourisme', 
'Hôtellerie', 
'Restauration',
'Transport', 
'Logistique'
]

export const niveauetudes =['Qualification avant bac','Bac','Bac+1','Bac+2','Bac+3','Bac+4','Bac+5','plus']
export const secteuractivites =[
    "Activités associatives",
"Administration publique",
"Aéronautique, navale", 
"Agriculture, pêche, aquaculture", 
"Agroalimentaire", 
"Ameublement, décoration",
"Automobile, matériels de transport, réparation",
"Banque, assurance, finances",
"BTP, construction",
"Centres d´appel, hotline, call center",
"Chimie, pétrochimie, matières premières, mines",
"Conseil, audit, comptabilité",
"Distribution, vente, commerce de grosilter",
"Édition, imprimerie",
"Éducation, formation",
"Electricité, eau, gaz, nucléaire, énergie",
"Environnement, recyclage",
"Equip. électriques, électroniques, optiques,précision",
"Equipements mécaniques, machines",
"Espaces verts,forêts, chasse",
"Évènementiel, hôte(sse),accueil",
"Hôtellerie,restauration", 
"Immobilier, architecture, urbanisme", 
"Import, export",
"Industrie pharmaceutique",
"Industrie, production, fabrication, autres",
"Informatique, SSII, Internet",
"Ingénierie, études développement",
"Intérim, recrutement",
"Location",
"Luxe, cosmétiques",
"Maintenance, entretien, service après vente",
"Manutention",
"Marketing, communication, médias",
"Métallurgie, sidérurgie",
"Nettoyage, sécurité, surveillance",
"Papier, bois, caoutchouc, plastique, verre, tabac",
"Produits de grande consommation",
"Qualité, méthodes",
"Recherche et développement",
"Santé, pharmacie, hôpitaux, équipements médicaux",
"Secrétariat",
"Services aéroportuaires et maritimes",
"Services autres",
"Services collectifs et sociaux, services à la personne",
"Sport, action culturelle et sociale",
"Télécom",
"Textile, habillement, cuir, chaussures",
"Tourisme, loisirs",
"Transports, logistique, services postaux"
]

export const niveauexperience=[
    'Aucun diplome exigé',
    'Niveau CEP',
    'Niveau BEPC',
    'Niveau BAC',
    'Etudiant',
    'Jeune diplômé',
    'Debutant < 2 ans',
    "Expérience entre 2 ans et 5ans",
    "Experience entre 5 ans et 10 ans",
    "Experience > 10 ans"
    ]

export const competences=[]

export const typePhase = [
    "Phase 1",
    "Phase 2",
    "Phase 3",
    "Phase 4",
    "Phase 5",
    "Phase 6",
]

export const pays =[{
    "id": 1,
    "nationalite": "afghane",
    "libelle": "Afghanistan"
},
    {
        "id": 2,
        "nationalite": "sudafricaine",
        "libelle": "Afrique du Sud"
    },
    {
        "id": 3,
        "nationalite": "albanaise",
        "libelle": "Albanie"
    },
    {
        "id": 4,
        "nationalite": "algérienne",
        "libelle": "Algérie"
    },
    {
        "id": 5,
        "nationalite": "allemande",
        "libelle": "Allemagne"
    },
    {
        "id": 6,
        "nationalite": "andorrane",
        "libelle": "Andorre"
    },
    {
        "id": 7,
        "nationalite": "angolaise",
        "libelle": "Angola"
    },
    {
        "id": 8,
        "nationalite": "antiguayenne",
        "libelle": "Antigua-et-Barbuda"
    },
    {
        "id": 9,
        "nationalite": "saoudienne",
        "libelle": "Arabie saoudite"
    },
    {
        "id": 10,
        "nationalite": "argentine",
        "libelle": "Argentine"
    },
    {
        "id": 11,
        "nationalite": "arménienne",
        "libelle": "Arménie"
    },
    {
        "id": 12,
        "nationalite": "australienne",
        "libelle": "Australie"
    },
    {
        "id": 13,
        "nationalite": "autrichienne",
        "libelle": "Autriche"
    },
    {
        "id": 14,
        "nationalite": "azerbaïdjanaise",
        "libelle": "Azerbaïdjan"
    },
    {
        "id": 15,
        "nationalite": "bahaméenne",
        "libelle": "Bahamas"
    },
    {
        "id": 16,
        "nationalite": "bahreïnienne",
        "libelle": "Bahreïn"
    },
    {
        "id": 17,
        "nationalite": "bangladaise",
        "libelle": "Bangladesh"
    },
    {
        "id": 18,
        "nationalite": "barbadienne",
        "libelle": "Barbade"
    },
    {
        "id": 19,
        "nationalite": "belge",
        "libelle": "Belgique"
    },
    {
        "id": 20,
        "nationalite": "bélizienne",
        "libelle": "Belize"
    },
    {
        "id": 22,
        "nationalite": "bhoutanaise",
        "libelle": "Bhoutan"
    },
    {
        "id": 24,
        "nationalite": "birmane",
        "libelle": "Birmanie"
    },
    {
        "id": 23,
        "nationalite": "biélorusse",
        "libelle": "Biélorussie"
    },
    {
        "id": 25,
        "nationalite": "bolivienne",
        "libelle": "Bolivie"
    },
    {
        "id": 26,
        "nationalite": "bosnienne",
        "libelle": "Bosnie-Herzégovine"
    },
    {
        "id": 27,
        "nationalite": "botswanaise",
        "libelle": "Botswana"
    },
    {
        "id": 29,
        "nationalite": "brunéienne",
        "libelle": "Brunei"
    },
    {
        "id": 28,
        "nationalite": "brésilienne",
        "libelle": "Brésil"
    },
    {
        "id": 30,
        "nationalite": "bulgare",
        "libelle": "Bulgarie"
    },
    {
        "id": 31,
        "nationalite": "burkinabé",
        "libelle": "Burkina"
    },
    {
        "id": 32,
        "nationalite": "burundaise",
        "libelle": "Burundi"
    },
    {
        "id": 21,
        "nationalite": "béninoise",
        "libelle": "Bénin"
    },
    {
        "id": 33,
        "nationalite": "cambodgienne",
        "libelle": "Cambodge"
    },
    {
        "id": 34,
        "nationalite": "camerounaise",
        "libelle": "Cameroun"
    },
    {
        "id": 35,
        "nationalite": "canadienne",
        "libelle": "Canada"
    },
    {
        "id": 36,
        "nationalite": "cap-verdienne",
        "libelle": "Cap-Vert"
    },
    {
        "id": 144,
        "nationalite": "centrafricaine",
        "libelle": "Centrafrique"
    },
    {
        "id": 37,
        "nationalite": "chilienne",
        "libelle": "Chili"
    },
    {
        "id": 38,
        "nationalite": "chinoise",
        "libelle": "Chine"
    },
    {
        "id": 39,
        "nationalite": "chypriote",
        "libelle": "Chypre"
    },
    {
        "id": 40,
        "nationalite": "colombienne",
        "libelle": "Colombie"
    },
    {
        "id": 41,
        "nationalite": "comorienne",
        "libelle": "Comores"
    },
    {
        "id": 42,
        "nationalite": "congolaise",
        "libelle": "Congo"
    },
    {
        "id": 43,
        "nationalite": "nord-coréenne",
        "libelle": "Corée du Nord"
    },
    {
        "id": 44,
        "nationalite": "sud-coréenne",
        "libelle": "Corée du Sud"
    },
    {
        "id": 45,
        "nationalite": "costaricienne",
        "libelle": "Costa Rica"
    },
    {
        "id": 47,
        "nationalite": "croate",
        "libelle": "Croatie"
    },
    {
        "id": 48,
        "nationalite": "cubaine",
        "libelle": "Cuba"
    },
    {
        "id": 46,
        "nationalite": "ivoirienne",
        "libelle": "Côte d'Ivoire"
    },
    {
        "id": 49,
        "nationalite": "danoise",
        "libelle": "Danemark"
    },
    {
        "id": 50,
        "nationalite": "djiboutienne",
        "libelle": "Djibouti"
    },
    {
        "id": 51,
        "nationalite": "dominiquaise",
        "libelle": "Dominique"
    },
    {
        "id": 52,
        "nationalite": "egyptienne",
        "libelle": "Egypte"
    },
    {
        "id": 53,
        "nationalite": "emirienne",
        "libelle": "Emirats arabes unis"
    },
    {
        "id": 54,
        "nationalite": "equatorienne",
        "libelle": "Equateur"
    },
    {
        "id": 55,
        "nationalite": "erythréenne",
        "libelle": "Erythrée"
    },
    {
        "id": 56,
        "nationalite": "espagnole",
        "libelle": "Espagne"
    },
    {
        "id": 57,
        "nationalite": "estonienne",
        "libelle": "Estonie"
    },
    {
        "id": 58,
        "nationalite": "américaine",
        "libelle": "Etats-Unis"
    },
    {
        "id": 59,
        "nationalite": "ethiopienne",
        "libelle": "Ethiopie"
    },
    {
        "id": 60,
        "nationalite": "fidjienne",
        "libelle": "Fidji"
    },
    {
        "id": 61,
        "nationalite": "finlandaise",
        "libelle": "Finlande"
    },
    {
        "id": 62,
        "nationalite": "française",
        "libelle": "France"
    },
    {
        "id": 63,
        "nationalite": "gabonaise",
        "libelle": "Gabon"
    },
    {
        "id": 64,
        "nationalite": "gambienne",
        "libelle": "Gambie"
    },
    {
        "id": 66,
        "nationalite": "ghanéenne",
        "libelle": "Ghana"
    },
    {
        "id": 68,
        "nationalite": "grenadienne",
        "libelle": "Grenade"
    },
    {
        "id": 67,
        "nationalite": "grecque",
        "libelle": "Grèce"
    },
    {
        "id": 69,
        "nationalite": "guatémaltèque",
        "libelle": "Guatemala"
    },
    {
        "id": 70,
        "nationalite": "guinéenne",
        "libelle": "Guinée"
    },
    {
        "id": 71,
        "nationalite": "equatoguinéenne",
        "libelle": "Guinée équatoriale"
    },
    {
        "id": 72,
        "nationalite": "bissaoguinéenne",
        "libelle": "Guinée-Bissao"
    },
    {
        "id": 73,
        "nationalite": "guyanienne",
        "libelle": "Guyana"
    },
    {
        "id": 65,
        "nationalite": "géorgienne",
        "libelle": "Géorgie"
    },
    {
        "id": 74,
        "nationalite": "haïtienne",
        "libelle": "Haïti"
    },
    {
        "id": 75,
        "nationalite": "hondurienne",
        "libelle": "Honduras"
    },
    {
        "id": 77,
        "nationalite": "hongroise",
        "libelle": "Hongrie"
    },
    {
        "id": 79,
        "nationalite": "indienne",
        "libelle": "Inde"
    },
    {
        "id": 80,
        "nationalite": "indonésienne",
        "libelle": "Indonésie"
    },
    {
        "id": 81,
        "nationalite": "irakienne",
        "libelle": "Irak"
    },
    {
        "id": 82,
        "nationalite": "iranienne",
        "libelle": "Iran"
    },
    {
        "id": 83,
        "nationalite": "irlandaise",
        "libelle": "Irlande"
    },
    {
        "id": 84,
        "nationalite": "islandaise",
        "libelle": "Islande"
    },
    {
        "id": 85,
        "nationalite": "israélienne",
        "libelle": "Israël"
    },
    {
        "id": 86,
        "nationalite": "italienne",
        "libelle": "Italie"
    },
    {
        "id": 87,
        "nationalite": "jamaïquaine",
        "libelle": "Jamaïque"
    },
    {
        "id": 88,
        "nationalite": "japonaise",
        "libelle": "Japon"
    },
    {
        "id": 90,
        "nationalite": "jordanienne",
        "libelle": "Jordanie"
    },
    {
        "id": 89,
        "nationalite": "palestinienne",
        "libelle": "Jérusalem - Territoires palestiniens"
    },
    {
        "id": 91,
        "nationalite": "kazakhstanais",
        "libelle": "Kazakhstan"
    },
    {
        "id": 92,
        "nationalite": "kényane",
        "libelle": "Kenya"
    },
    {
        "id": 93,
        "nationalite": "kirghize",
        "libelle": "Kirghizstan"
    },
    {
        "id": 94,
        "nationalite": "kiribatienne",
        "libelle": "Kiribati"
    },
    {
        "id": 198,
        "nationalite": "kosovare",
        "libelle": "Kosovo"
    },
    {
        "id": 95,
        "nationalite": "koweïtienne",
        "libelle": "Koweït"
    },
    {
        "id": 96,
        "nationalite": "laotienne",
        "libelle": "Laos"
    },
    {
        "id": 97,
        "nationalite": "lesothienne",
        "libelle": "Lesotho"
    },
    {
        "id": 98,
        "nationalite": "lettone",
        "libelle": "Lettonie"
    },
    {
        "id": 99,
        "nationalite": "libanaise",
        "libelle": "Liban"
    },
    {
        "id": 100,
        "nationalite": "libérienne",
        "libelle": "Liberia"
    },
    {
        "id": 101,
        "nationalite": "libyenne",
        "libelle": "Libye"
    },
    {
        "id": 102,
        "nationalite": "liechtensteinoise",
        "libelle": "Liechtenstein"
    },
    {
        "id": 103,
        "nationalite": "lituanienne",
        "libelle": "Lituanie"
    },
    {
        "id": 104,
        "nationalite": "luxembourgeoise",
        "libelle": "Luxembourg"
    },
    {
        "id": 105,
        "nationalite": "macédonienne",
        "libelle": "Macédoine"
    },
    {
        "id": 106,
        "nationalite": "malgache",
        "libelle": "Madagascar"
    },
    {
        "id": 107,
        "nationalite": "malaisienne",
        "libelle": "Malaisie"
    },
    {
        "id": 108,
        "nationalite": "malawienne",
        "libelle": "Malawi"
    },
    {
        "id": 109,
        "nationalite": "maldivienne",
        "libelle": "Maldives"
    },
    {
        "id": 110,
        "nationalite": "malienne",
        "libelle": "Mali"
    },
    {
        "id": 111,
        "nationalite": "maltaise",
        "libelle": "Malte"
    },
    {
        "id": 112,
        "nationalite": "marocaine",
        "libelle": "Maroc"
    },
    {
        "id": 113,
        "nationalite": "marshallaise",
        "libelle": "Marshall"
    },
    {
        "id": 114,
        "nationalite": "mauricienne",
        "libelle": "Maurice"
    },
    {
        "id": 115,
        "nationalite": "mauritanienne",
        "libelle": "Mauritanie"
    },
    {
        "id": 116,
        "nationalite": "mexicaine",
        "libelle": "Mexique"
    },
    {
        "id": 117,
        "nationalite": "micronésienne",
        "libelle": "Micronésie"
    },
    {
        "id": 118,
        "nationalite": "moldave",
        "libelle": "Moldavie"
    },
    {
        "id": 119,
        "nationalite": "monégasque",
        "libelle": "Monaco"
    },
    {
        "id": 120,
        "nationalite": "mongole",
        "libelle": "Mongolie"
    },
    {
        "id": 197,
        "nationalite": "monténégrine",
        "libelle": "Monténégro"
    },
    {
        "id": 121,
        "nationalite": "mozambicaine",
        "libelle": "Mozambique"
    },
    {
        "id": 122,
        "nationalite": "namibienne",
        "libelle": "Namibie"
    },
    {
        "id": 123,
        "nationalite": "nauruane",
        "libelle": "Nauru"
    },
    {
        "id": 125,
        "nationalite": "nicaraguayenne",
        "libelle": "Nicaragua"
    },
    {
        "id": 126,
        "nationalite": "nigérienne",
        "libelle": "Niger"
    },
    {
        "id": 127,
        "nationalite": "nigériane",
        "libelle": "Nigeria"
    },
    {
        "id": 128,
        "nationalite": "norvégienne",
        "libelle": "Norvège"
    },
    {
        "id": 129,
        "nationalite": "néo-zélandaise",
        "libelle": "Nouvelle-Zélande"
    },
    {
        "id": 124,
        "nationalite": "népalaise",
        "libelle": "Népal"
    },
    {
        "id": 130,
        "nationalite": "omanaise",
        "libelle": "Oman"
    },
    {
        "id": 131,
        "nationalite": "ougandaise",
        "libelle": "Ouganda"
    },
    {
        "id": 132,
        "nationalite": "ouzbèke",
        "libelle": "Ouzbékistan"
    },
    {
        "id": 133,
        "nationalite": "pakistanaise",
        "libelle": "Pakistan"
    },
    {
        "id": 134,
        "nationalite": "palaoise",
        "libelle": "Palaos"
    },
    {
        "id": 135,
        "nationalite": "panaméenne",
        "libelle": "Panama"
    },
    {
        "id": 136,
        "nationalite": "papouasienne",
        "libelle": "Papouasie-Nouvelle-Guinée"
    },
    {
        "id": 137,
        "nationalite": "paraguayenne",
        "libelle": "Paraguay"
    },
    {
        "id": 138,
        "nationalite": "néerlandaise",
        "libelle": "Pays-Bas"
    },
    {
        "id": 140,
        "nationalite": "philippine",
        "libelle": "Philippines"
    },
    {
        "id": 141,
        "nationalite": "polonaise",
        "libelle": "Pologne"
    },
    {
        "id": 142,
        "nationalite": "portugaise",
        "libelle": "Portugal"
    },
    {
        "id": 139,
        "nationalite": "péruvienne",
        "libelle": "Pérou"
    },
    {
        "id": 143,
        "nationalite": "qatarienne",
        "libelle": "Qatar"
    },
    {
        "id": 148,
        "nationalite": "roumaine",
        "libelle": "Roumanie"
    },
    {
        "id": 149,
        "nationalite": "britannique",
        "libelle": "Royaume-Uni"
    },
    {
        "id": 150,
        "nationalite": "russe",
        "libelle": "Russie"
    },
    {
        "id": 151,
        "nationalite": "rwandaise",
        "libelle": "Rwanda"
    },
    {
        "id": 146,
        "nationalite": "dominicaine",
        "libelle": "République dominicaine"
    },
    {
        "id": 145,
        "nationalite": "congolaise (RDC)",
        "libelle": "République démocratique du Congo"
    },
    {
        "id": 147,
        "nationalite": "tchèque",
        "libelle": "République tchèque"
    },
    {
        "id": 152,
        "nationalite": "christophienne",
        "libelle": "Saint-Christophe-et-Niévès"
    },
    {
        "id": 154,
        "nationalite": "marinaise",
        "libelle": "Saint-Marin"
    },
    {
        "id": 155,
        "nationalite": null,
        "libelle": "Saint-Siège"
    },
    {
        "id": 156,
        "nationalite": "vincentaise",
        "libelle": "Saint-Vincent-et-les Grenadines"
    },
    {
        "id": 153,
        "nationalite": "lucienne",
        "libelle": "Sainte-Lucie"
    },
    {
        "id": 157,
        "nationalite": "salomonaise",
        "libelle": "Salomon"
    },
    {
        "id": 158,
        "nationalite": "salvadorienne",
        "libelle": "Salvador"
    },
    {
        "id": 159,
        "nationalite": "samoene",
        "libelle": "Samoa"
    },
    {
        "id": 160,
        "nationalite": "santoméenne",
        "libelle": "Sao Tomé-et-Principe"
    },
    {
        "id": 162,
        "nationalite": "serbe",
        "libelle": "Serbie"
    },
    {
        "id": 163,
        "nationalite": "seychelloise",
        "libelle": "Seychelles"
    },
    {
        "id": 164,
        "nationalite": "sierraléonaise",
        "libelle": "Sierra Leone"
    },
    {
        "id": 165,
        "nationalite": "singapourienne",
        "libelle": "Singapour"
    },
    {
        "id": 166,
        "nationalite": "slovaque",
        "libelle": "Slovaquie"
    },
    {
        "id": 167,
        "nationalite": "slovène",
        "libelle": "Slovénie"
    },
    {
        "id": 168,
        "nationalite": "somalienne",
        "libelle": "Somalie"
    },
    {
        "id": 169,
        "nationalite": "soudanaise",
        "libelle": "Soudan"
    },
    {
        "id": 170,
        "nationalite": "srilankaise",
        "libelle": "Sri Lanka"
    },
    {
        "id": 172,
        "nationalite": "suisse",
        "libelle": "Suisse"
    },
    {
        "id": 173,
        "nationalite": "surinamaise",
        "libelle": "Suriname"
    },
    {
        "id": 171,
        "nationalite": "suédoise",
        "libelle": "Suède"
    },
    {
        "id": 174,
        "nationalite": "swazie",
        "libelle": "Swaziland"
    },
    {
        "id": 175,
        "nationalite": "syrienne",
        "libelle": "Syrie"
    },
    {
        "id": 161,
        "nationalite": "sénégalaise",
        "libelle": "Sénégal"
    },
    {
        "id": 176,
        "nationalite": "tadjike",
        "libelle": "Tadjikistan"
    },
    {
        "id": 178,
        "nationalite": "tanzanienne",
        "libelle": "Tanzanie"
    },
    {
        "id": 177,
        "nationalite": "taïwanaise",
        "libelle": "Taïwan"
    },
    {
        "id": 179,
        "nationalite": "tchadienne",
        "libelle": "Tchad"
    },
    {
        "id": 180,
        "nationalite": "thaïlandaise",
        "libelle": "Thaïlande"
    },
    {
        "id": 181,
        "nationalite": "timoraise",
        "libelle": "Timor oriental"
    },
    {
        "id": 182,
        "nationalite": "togolaise",
        "libelle": "Togo"
    },
    {
        "id": 183,
        "nationalite": "tongienne",
        "libelle": "Tonga"
    },
    {
        "id": 184,
        "nationalite": "trinidadienne",
        "libelle": "Trinité-et-Tobago"
    },
    {
        "id": 185,
        "nationalite": "tunisienne",
        "libelle": "Tunisie"
    },
    {
        "id": 186,
        "nationalite": "turkmène",
        "libelle": "Turkménistan"
    },
    {
        "id": 187,
        "nationalite": "turque",
        "libelle": "Turquie"
    },
    {
        "id": 188,
        "nationalite": "tuvaluane",
        "libelle": "Tuvalu"
    },
    {
        "id": 189,
        "nationalite": "ukrainienne",
        "libelle": "Ukraine"
    },
    {
        "id": 190,
        "nationalite": "uruguayenne",
        "libelle": "Uruguay"
    },
    {
        "id": 191,
        "nationalite": "vanuataise",
        "libelle": "Vanuatu"
    },
    {
        "id": 192,
        "nationalite": "vénézuélienne",
        "libelle": "Venezuela"
    },
    {
        "id": 193,
        "nationalite": "vietnamienne",
        "libelle": "Viêt Nam"
    },
    {
        "id": 194,
        "nationalite": "yéménite",
        "libelle": "Yémen"
    },
    {
        "id": 195,
        "nationalite": "zambienne",
        "libelle": "Zambie"
    },
    {
        "id": 196,
        "nationalite": "zimbabwéenne",
        "libelle": "Zimbabwe"
    }]