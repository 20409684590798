import { configureStore,combineReducers } from '@reduxjs/toolkit'
import uiReducer from './reducer/uiReducer'

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import thunk from 'redux-thunk';

const persistConfig = {
  key:'ui',
  storage
}
const rootReducer = combineReducers({ 
  ui: uiReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store =  configureStore({
  reducer:persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)
