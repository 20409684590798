import React,{useState,useEffect, useRef} from 'react'
import {Button, IconButton, MenuItem, Paper, Tooltip, Typography } from '@mui/material'
import { Card } from 'components'
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'components'
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Modal } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import swal from 'sweetalert';
const EditModule = () => {
    const params = useParams()
    const navigate = useNavigate()
    const portail =  useSelector(state=>state.ui.portail).toLowerCase()
    const [portails,setPortails] = useState()
    const [loading,setLoading] = useState(false)
    const [inputField,setInputField] = useState({})
      const handlePortails = ()=>{
        axios.get(`/api/portail/all`).then(res=>{
            if(res.status ===200 || res.status ===201){
                setPortails(res.data)
            }
        })
      }
      
      const handleModule =()=>{
        if(params && params.hasOwnProperty('id')){
            axios.get(`/api/modules/${params['id']}`).then(res=>{
                if(res.status ===200 || res.status ===201){
                    
                    setInputField({
                        ...res.data,
                        portailcode:res.data['portail']['id'],
                        modulecode:res.data['codemodule']

                    })
                }
              })
        }
      }
      useEffect(()=>{
        handlePortails()
        handleModule()
      },[])
    
   
    const modref = useRef()
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const handleEditorChange =(field,value)=>{
        setInputField({...inputField,[field]:value});
    }
    const [errors,setErrors] = useState(null)
    
    const [show,setShow] = useState(false)
    const handleModalClose = ()=>{
        modref.current.reset()
        setShow(false)
    }
    const handleModalOpen = ()=>{
        setShow(true)
    }
    
    const [rightsFields,setRightFields] = useState({})
    const handlePrivilesChange =(e)=>{
        const {name,value} = e.target
        setRightFields({...rightsFields,[name]:value})
    }
    const handleAddPrivilege = (e)=>{
        e.preventDefault()
        const oldRights = inputField.privileges || []
        let el_index = oldRights.findIndex(item=>item.perms===rightsFields.perms)
        if(el_index ===-1){
            setInputField({...inputField,"privileges":[...oldRights,rightsFields]})
        }else{
            const newRights = oldRights.splice(el_index,1,rightsFields)
            setInputField({...inputField,"privileges":[...newRights]})
        }
    }
    const handleDeleteRight = (right)=>{
        const oldRights = inputField.privileges || []
        const newRights = oldRights.filter(item=>item.perms!== right.perms)
        if(right.hasOwnProperty('id') && right.hasOwnProperty('module_id')){
            axios.post(`/api/modules/privilege/${right.module_id}`,{id:right.id}).then(res=>{
                if(res.status === 204 || res.status ===200 || res.status ===201){
                    console.log(res.data);
                    setInputField({...inputField,"privileges":[...newRights]})
                }
            }).catch(err=>{
                if(err.hasOwnProperty('response')){
                    console.log(err.response);
                    swal("Oops!",err.response.message,'error')
                }else{
                    console.log(err);
                }
            })
        }else{
            setInputField({...inputField,"privileges":[...newRights]})
        }
        
    }

    const [subFields,setSubFields] = useState()
    const [subShow,setSubShow] = useState(false)
    const handleSubClose = ()=>{
        setSubShow(false)
    }
    const handleSubOpen = ()=>{
        setSubShow(true)
    }
    const addSubPerms =()=>{
        if(subFields && subFields!==""){
            const oldSub = rightsFields['subperms'] || [] 
            setRightFields({...rightsFields,subperms:[...oldSub,subFields]})
            handleSubClose()
        }
    }
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          const {value} = e.target
          if(value && value !==""){
            addSubPerms()
          }
        }
      }
      const handleDelete = (sub)=>{
        setRightFields({...rightsFields,'subperms':rightsFields.subperms.filter(item=>item!==sub)})
      }
      useEffect(()=>{
        if(inputField.hasOwnProperty('libelle') && inputField.libelle!==""){
            setInputField({...inputField,'modulecode':inputField.libelle.toLowerCase().replaceAll(/[^\w\s]/gi, '').replaceAll(' ','_')})
        }
      },[inputField.libelle])

      const handleSubmit = ()=>{
        if(inputField.libelle && inputField.portailcode && inputField.perms){
            axios.put(`/api/modules/update/${params["id"]}`,inputField).then(res=>{
                if(res.status === 200 || res.status===201){
                    swal("Bien !","Modification effectuée avec succès",'success')
                }else{
                    swal("Oops !","Ereur système opération interompue","error")
                }
            }).catch(err=>{
                if(err.hasOwnProperty('response')){
                    console.log(err.response);
                    swal("Oops !",err.response.message,"error")
                }else{
                    swal("Oops !","Erreur lors de l'opération, veuillez réessayer","error")
                }

            })
        }        
      }
      
  return (
    <div className='container md:max-w-screen-sm mx-auto'>
        {/* modal sub perm */}
        <Modal show={subShow} onHide={handleSubClose} centered backdrop="static">
            <Modal.Body className='bg-slate-200 rounded-md'>
                <div className='flex justify-end'>
                    <IconButton onClick={handleSubClose}><CloseIcon style={{fontSize:20}} /></IconButton>
                </div>
                <Form.Group direction ='row'flex={1} className="flex items-center">
                    <Form.Input 
                        size="small" 
                        margin="dense" 
                        label="sous-permission"
                        wd='100%'
                        name='libelle'
                        className="mt-3 "
                        value={subFields ||""}
                        onChange={(e)=>setSubFields(e.target.value)}
                        onKeyDown={handleKeyDown} 
                        />
        
                    <IconButton onClick={addSubPerms} onKeyDown={handleKeyDown}  className='rounded-sm h-[40px] flex items-start justify-center hover:bg-green-700 hover:text-white'>
                        <NavigateNextIcon />
                    </IconButton>
                </Form.Group>
            </Modal.Body>
        </Modal> {/* end modal sub perm */}
         {/* modal  perms */}
        <Modal show={show} onHide={handleModalClose} centered backdrop="static">
              <Modal.Header>
                    <div className='flex flex-row items-center justify-between w-full'>
                    <Modal.Title>Ajouter un privilège</Modal.Title>
                        <Tooltip title='Fermer'>
                            <IconButton onClick={handleModalClose}>
                            <CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
              </Modal.Header>
              <Modal.Body>
              <form onSubmit={handleAddPrivilege} ref={modref} onReset={handleModalClose}>
                <Paper className="formbox" variant='outlined'>
                  <Card>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:"flex-start"}}>
                          <Form style={{display:'flex',flexDirection:'column'}}>
                              <Form.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                  <Form.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20,marginBottom:20}}  
                                      label="Libelle"
                                      required 
                                      wd='100%'
                                      name='libelle' 
                                      value={rightsFields['libelle'] ||""}
                                      onChange={handlePrivilesChange} 

                                      />
                                  {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}
                              </Form.Group> 

                                
                              <Form.Group direction ='row' flex={1}>
                                <Form.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20}}  
                                      label="Permission"
                                      required 
                                      wd='100%'
                                      name='perms' 
                                      value={rightsFields['perms'] ||""}
                                      onChange={handlePrivilesChange} 
                                      />

                                      <Tooltip title="Ajouter une sous-permission">
                                        <IconButton  className='text-green-500' disabled={(!rightsFields['perms'] || rightsFields['perms']==='')} onClick={handleSubOpen}>
                                            <AddCircleOutlineIcon />
                                      </IconButton>
                                      </Tooltip>
                              </Form.Group>
                              <p className='text-[10px] bg-red-200 text-red-600 rounded-sm'>* Utilisez " _" pour les espace espace, Pas de caractères accentués Ex. (é,à, ù ...), </p>
                                
                                {(rightsFields.subperms && rightsFields.subperms.length!==0)&& <div className='flex flex-row items-center gap-1 p-2'>
                                    {rightsFields.subperms.map(subp=><Chip label={subp} variant="outlined" onDelete={()=>handleDelete(subp)} />)}</div>}
                               
                          </Form>
                      </div>
                  </Card>
                      
                  </Paper>
                  <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        
                        <Button disabled={loading} type='reset' style={{marginRight: 20, backgroundColor:'#F29400'}} className='text-white'>
                             Annuler
                        </Button>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}} className='text-white'>
                             Ajouter
                        </Button>
                  </Card>     
            </form>
              </Modal.Body>
              
        </Modal>
            <div className='flex justify-between items-center p-2'>
                <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Modification Module/Privillèges #{params&&params['id']}</Typography>
                <Button 
                    variant="outline-success" 
                    className="bg-green-600 text-white flex justify-center items-center hover:bg-green-900" 
                    onClick={()=>navigate(`/${portail}/parametres`)}>
                    <ReplyIcon/>Retour à la liste
                </Button>
            </div>
              <form onSubmit={handleSubmit}>
                <Paper className="formbox" variant='outlined'>
                    <Card>
                      <Card.Header>
                          <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                      <div className='flex flex-col w-1/2 self-center'>
                          <Form style={{display:'flex',flexDirection:'column'}}>
                              <Form.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                {(portails&& portails.length!==0)&&(
                                    <Form.Input 
                                    name='portailcode' 
                                    size="small" 
                                    margin="dense" 
                                    sx={{ width: '100%' }}
                                    select 
                                    wd='100%'
                                    value ={inputField['portailcode']||''} 
                                    onChange={handleChange}  label="Portail">
                                    <MenuItem  value='No'>Aucun</MenuItem>
                                    {portails.map(pot=>(<MenuItem  value={pot.id}>{pot.libelle}</MenuItem>))}
                                </Form.Input>
                                )}
                                
                                  <Form.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20,marginBottom:20}}  
                                      label="Intitule du module"
                                      required 
                                      wd='100%'
                                      name='libelle' 
                                      value={inputField['libelle'] ||""}
                                      onChange={handleChange} 
                                      />
                                  {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}
                                      <Form.Input 
                                      size="small" 
                                      margin="dense" 
                                      disabled
                                      style={{marginRight:20,marginBottom:20}}  
                                      required 
                                      wd='100%'
                                      value={inputField['modulecode'] ||""}
                                      onChange={handleChange} 
                                      />
                                  <ReactQuill  
                                  placeholder={"Description du profil"} 
                                  style={{width:'100%',marginBottom:20}}
                                  name='description' 
                                  theme="snow" 
                                  value={inputField['description']||""} 
                                  onChange={(v)=>handleEditorChange('description',v)} 
                              />
                              {(errors && errors.hasOwnProperty('description'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['description'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}

                              
                              </Form.Group>       
                          </Form>
                      </div>
                    </Card>

                    <Card>
                      <Card.Header>
                          <Card.Title>PRIVILEGES</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                      <Button onClick={handleModalOpen} className='bg-white w-1/2 self-center rounded-full flex-1 border-solid text-green-600 border-2 border-green-600 shadow-md hover:bg-orange-500'>
                        <AddIcon />
                     </Button>
                     {(inputField.privileges && inputField.privileges.length!==0)?<TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                {inputField.privileges.map((right) => (
                                    <TableRow
                                    key={right.perms}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell>{right.libelle}</TableCell>
                                    <TableCell align="center">{right.perms}</TableCell>
                                    <TableCell align="center">{(right.hasOwnProperty('subperms') && right.subperms?.length!==0)&&right.subperms?.join()}</TableCell>
                                    <TableCell align="center">{right.type}</TableCell>
                                    <TableCell align="center">{right.default}</TableCell>
                                    <TableCell align="center"><Tooltip title="Supprimer">
                                            <IconButton onClick={()=>handleDeleteRight(right)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> :<p className='p-4 my-4 rounded-sm bg-slate-200 text-slate-600 font-bold' >Aucun privilège disponible</p>}
                     
                    </Card>
                    <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        
                        <Button disabled={loading} type='reset' style={{marginRight: 20, backgroundColor:'#F29400'}} className='text-white'>
                            <CloseIcon sx={{fontSize:40,color:'#ffffff'}} /> Annuler
                        </Button>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}} className='text-white'>
                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} Enregistrer
                        </Button>
                    </Card>   
                </Paper>
              </form>

    </div>
  )
}

export default EditModule