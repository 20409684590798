import React, { useCallback, useEffect,useState } from 'react'
import Paper from '@mui/material/Paper';
import {Typography,Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Form } from 'components'
import Autocomplete from '@mui/material/Autocomplete';
import {communes} from 'fixtures/data'
import swal from 'sweetalert';
import { radiobutton_appearances } from "components/radioField";
import {RadioField} from "components";
import { Form as CustumForm } from 'components'
import { Card } from 'components'
import RefreshIcon from '@mui/icons-material/Refresh';
import {Spinner } from 'react-bootstrap';
import axios from 'lib/axios';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import './styles/AddComitegestion.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddComitegestion() {
  const navigate = useNavigate()
  const [loading,setLoading] =useState(false)
  const [quartiers,setQuartiers] = useState([])
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase() 
  const handlelist = ()=>{
    navigate(`/${portail}/comites`)
  }

  const handleQuartier =()=>{
    axios.get(`/api/quartier/all`).then(res=>{
      if(res.status === 200 || res.status ===201){
        setQuartiers(res.data)
      }
    })
  }

  const [inputField,setInputField] = useState({commune:""})
  const handleInputChange = (e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  const handleEditorChange =(field,value)=>{
    setInputField({...inputField,[field]:value});
}
  const handleSave =(e)=>{
    e.preventDefault()
    if(inputField.hasOwnProperty('nom') && inputField.hasOwnProperty('quartier') && inputField.hasOwnProperty('type')){
      setLoading(true)
      axios.post(`/api/comite/add`,inputField).then(res=>{
        if(res.status === 200 || res.status === 201){
          setLoading(false)
          swal('Bien !',"Enregistrement effectué avec succès",'succes')
          handlelist()
        }else{
          console.log(res.data);
          swal('Ooops !',"Une erreur s'est produite lors de l'enregistrement",'error')
        }
      }).catch(err=>{
            setLoading(false)
            console.log(err?.response);
            swal('Ooops !',"Une erreur s'est produite lors de l'enregistrement",'error')
      })
    }else{
      setLoading(false)
      swal('Attention',"Veuillez remplir tous les champs",'warning')
    }
  }

useEffect(()=>{
  handleQuartier()
},[])

const [liveData,setLiveData] = useState([])
const handleLiveData = useCallback(()=>{
  if(quartiers && quartiers.length !==0){
    const currentquartier = quartiers.filter(item =>item.commune === inputField.commune)
    if(currentquartier){
      setLiveData([...currentquartier.map(cq=>({label:cq.nom,id:cq.id}))])
    }
  }
},[inputField.commune])

useEffect(()=>{
  return handleLiveData()
},[inputField.commune])


  return (
    <div className="addcomitegestion">
        <div className="titlebox">
            <Typography variant='h5'>Ajouter un CGQ / CGV</Typography>
            <Button className="btn" onClick={(e)=>handlelist()}><ArrowBackIosIcon sx={{color:'#00B04B'}} />Retour à la liste</Button>
        </div>
        <div className="bodybox">
          <form onSubmit={handleSave}>
              <Paper className="formbox" variant='outlined'>
                <Card>
                  <Card.Header>
                      <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                      <Card.Hr />
                  </Card.Header>
                  <CustumForm style={{display:'flex',flexDirection:'column'}}>
                    <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1} >
                      <CustumForm.Input  
                        name='nom' 
                        value={inputField['nom'] || ""} 
                        onChange={handleInputChange} 
                        size="small" 
                        margin="dense" 
                        label="nom du cgq /cgv" 
                        wd='100%'
                        style={{marginRight:20,marginBottom:20}} 
                        />
                            
                      <RadioField
                        groupItems={[ {
                          name: "cgq",
                          label: "CGQ",
                          information: "Veuillez selectionner un type",
                          disabled: false
                        },{
                          name: "cgv",
                          label: "CGV",
                          information: "Veuillez selectionner un type",
                          disabled: false
                        }]}
                        group="Type"
                        checkedDefault="Type"
                        appearance={radiobutton_appearances.primary}
                        onChange ={handleInputChange}
                        name='type'
                        vvalue ={inputField['type']||""}
                        style={{marginRight:20,marginBottom:20}} 
                      />
                      <Autocomplete
                        disablePortal
                        id="commune"
                        size="small" 
                        margin="dense"
                        defaultValue='Port-Bouët'
                        options={communes}
                        sx={{ width: '100%' }}
                        style={{marginRight:20,marginBottom:20}}
                        name='commune'

                        value={inputField['commune'] || ""}
                        onChange={(event, newValue) => {
                          setInputField({...inputField,['commune']:newValue});
                        }}
                        inputValue={inputField['commune'] || ""}
                        onInputChange={(event, newInputValue) => {
                          setInputField({...inputField,['commune']:newInputValue});
                        }}
                        renderInput={(params) => <CustumForm.Input  {...params} label="Commune" />}
                        />

                        {(liveData && liveData.length!==0)&&<Autocomplete
                        disablePortal
                          id="quartier"
                          size="small" 
                          margin="dense"
                          options={liveData}
                          sx={{ width: '100%' }}
                          style={{marginRight:20,marginBottom:20}} 
                          value={inputField['quartier'] || ""}
                          name='quartier'
                          onChange={(event, newValue) => {
                            setInputField({...inputField,['quartier']:newValue});
                          }}
                          inputValue={inputField['quartier'] || ""}
                          onInputChange={(event, newInputValue) => {
                            setInputField({...inputField,['quartier']:newInputValue});
                          }}
                          renderInput={(params) => <Form.Input {...params} label="Quartier" />}
                        />}  
                        <ReactQuill  
                              placeholder={"Localisation"} 
                              style={{width:'100%',marginBottom:20}}
                              name='localisation' 
                              theme="snow" 
                              value={inputField['localisation']||""} 
                              onChange={(v)=>handleEditorChange('localisation',v)} 
                          
                          />
                      </CustumForm.Group>
                  </CustumForm>
                </Card>
                <Card>
                  <Card.Header>
                      <Card.Title>MEMBRES DU BUREAU</Card.Title>
                  <Card.Hr />
                  </Card.Header>
                </Card>
            </Paper>
            <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                  <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                      {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                  </Button>
                  <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                      <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                  </Button>
            </Card>   
          </form>
        </div>
    </div>
  )
}

export default AddComitegestion
