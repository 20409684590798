import styled from "styled-components/macro"
import {Link as RouterLink} from 'react-router-dom'

export const Container =styled.div`
    display:flex;
    flex-direction:column;
    background-color: #ffffff;
    transition:width .3s ease;
    cursor:pointer;
    width:100%;
    box-shadow: 4px 7px 10px rgba(0,0,0,.4);
    height:100vh;
`
export const Inner =styled.div`
    display:flex;
    flex:${({flex})=>flex||1};
    flex-direction:column;
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
`
export const Base =styled.ul`
display:flex;
flex-direction:column;
flex:1;
width:100%;
list-style-type: none;
color:#00B04B;
`
export const Item = styled.li`
    display:flex;
    flex-grow:1;
    font-family:'roboto';
    border-bottom:1px dashed #00B04B;
    &:hover{
        border-bottom:5px solid #00B04B;
    }
`
export const Link = styled(RouterLink)`
    transition:all .3s ease;
    text-decoration: none;
    color:#00B04B;
    font-size: 1.35em;
    padding:20px 20px;
    @media (max-width:800px){
        padding:10px 10px;
    }
`
export const Icon = styled.div`
`
export const Texte = styled.h1`
    font-size: 1.35rem;
    color:#00B04B;
`

export const Logo = styled.img`
    width:200px;
`
export const Group = styled.div`
    display:flex;
    flex-grow:1;
    padding-left:20px;
    flex-direction:${({direction})=>direction||'row'};
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
`
export const Button  = styled.button`
    display:flex;
    color:white;
    background-color:#00B04B;
    width:100%;
    margin-top:20px;
    justify-content:center;
    align-items:center;
    padding:20px;
    border:none;
    cursor:pointer;
    font-size:18px;
    &:hover {
        outline: none;
        box-shadow: 0px 0px 5px #00B04B;
    }  
`

export const ButtonAdd  = styled.button`
    display:flex;
    color:#00B04B;
    background-color:white;
    justify-content:center;
    align-items:center;
    padding:20px;
    border:none;
    cursor:pointer;
    font-size:18px;
    border:2px dashed #707070; 
    &:hover {
        background-color:#00B04B;
        color:white;
    }  
`