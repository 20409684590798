import React,{useEffect, useState, useCallback} from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'custumstyle.css'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { Form } from 'components'
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import axios from 'lib/axios';
import { villes,secteuractivites,metiers,niveauetudes,niveauexperience,competences } from 'fixtures/data';
const useStyles = makeStyles()((theme=>{
    return {
        titlediv:{
            display:'flex',
            flex:1,
            justifyContent:'space-between',
            alignItems:'center'
        },
    content:{
        display:'flex',
        flex:1,
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column'
        }
   
    },
    content_form:{
        display:'flex',
        flexDirection:'column',
        width:'70%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_diag:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_img:{
        display:'flex',
        flexDirection:'column',
        minHeight:'100%',
        flex:1
    },
    photo_label:{
        fontSize:15,
        fontStyle:'italic',
        fontWeight:'bold'
    },
    logo_box:{
        display:'flex',
        justifyContent:'center',
        alignItems:'ceùnter',
        border:'1px dashed #707070',
        minHeight:'85%',
        background: 'rgba(244,244,244,0.6)',
        position:'relative',
    },
    photo_check:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    input: {
        display: "none"
      },
    img_card:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    boxOffres:{
        display:'flex',
        flexWrap:'wrap',
        flex:1,
        padding:20
    },
    cardOffres:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        '&:hover':{
            backgroundColor:'#00B04B',
            color:'white',
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        }
    },
    cardOffresCheck:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        backgroundColor:'#00B04B',
        color:'white',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

    }
};
}
))

const AddOffresExpress= (props)=>{
    const {open,handleSetData,handleClose} =props
    const {classes} =useStyles()
    const [categories,setCategories] = useState(null)
    const [categorie,setCategorie] = useState(null)
    const [activeStep,setActiveStep] = useState(1)
    const handleCategories = useCallback(()=>{
        axios.get(`/api/categorie-offre/all`).then(res=>{
            if(res.status === 200 || res.status === 201){
                setCategories(res.data)
            }
        })
    },[open])

    useEffect(()=>{
        if(open){handleCategories()} 
    },[open])

    const [offreField,setOffreField] = useState({
      titre:null,
      categorie:null,
      description:null,
      typecontrat:null,
      datedebut:null,
      datefin:null,
      ville:null,
      theme:null,
      actes:null,
      profils:null,
      nombrepost:null,
      datecloture:null,
      missions:"",
      competences:null,
      secteuractivites:null,
      niveauexperience:null,
      metiers:null,
      niveauetudes:null,
      financeur:null,
      conseiller:null,
      lieuformation:null,
      tempsplein:null
    });

    const handleEditorChange =(field,value)=>{
        setOffreField({...offreField,[field]:value});
    }
    const handleChangeDate = (name,newValue) => {
        setOffreField({...offreField,[name]:newValue})
    };
    const handleChange = (event) => {
        const {name,value} =event.target
        setOffreField({...offreField,[name]:value})
    };
    const handleSelectCategorie = (cat)=>{
        if(cat){
            setCategorie(cat),
            setOffreField({...offreField,"categorie":cat})
            setActiveStep(activeStep + 1)
        }
    }
    const handleAdd = ()=>{
        if(offreField['titre'] && offreField['typecontrat'] && offreField['categorie']){
            handleSetData(offreField)
            handleClose()
        }
    }
    
    const handeleAutoCompleteChange =(name,values)=>{
        setOffreField({...offreField,[name]:values})
    }

    return (
        <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        keepMounted
        disableEscapeKeyDown
        onClose={(event, reason) => {
            if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClose()
            }
        }}
    >
            <DialogTitle>
                <div className={classes.titlediv}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        {activeStep===2&&<IconButton aria-label="part-close" onClick={()=>setActiveStep(activeStep-1)}>
                            <ArrowBackIosIcon sx={{fontSize:30,color:'#00B04B'}} />
                        </IconButton>}
                        <Typography variant='h6'> Ajouter une offre {categorie&&`[ ${categorie.libelle} ]`}</Typography>
                    </div>
                    
                    <IconButton aria-label="part-close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Card.Hr />
            </DialogTitle>
            <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {activeStep===1&&<div style={{display:'flex',flexDirection:'column'}}>
                    <Typography variant='h6'> Quel type d'offres propose le partenaire ?</Typography>
                    <div className={classes.boxOffres}>
                        {(categories && categories.length!==0)&&categories.map(cat=>{
                            return <Card key={cat.id} onClick={()=>handleSelectCategorie(cat)} className={(categorie && cat.id === categorie.id)?classes.cardOffresCheck:classes.cardOffres}>
                                <span>{cat.libelle}</span>
                            </Card>
                        })}
                    </div>
                    
                </div>}
                {activeStep===2&&<div className={classes.content_diag}>
                
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input name='titre' value={offreField.titre} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Titre" wd='100%' />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Input required size="small" name="typecontrat" margin="dense" onChange={handleChange} value={offreField['typecontrat']||""} select style={{marginRight:20}}  label="Type de contrat">
                            <MenuItem  value='No'>Aucun</MenuItem>
                            <MenuItem value='CDI'>CDI</MenuItem>
                            <MenuItem value='CDD'>CDD</MenuItem>
                            <MenuItem value='Stage'>Stage</MenuItem>
                            <MenuItem value='Apprentissage'>Apprentissage</MenuItem>
                            <MenuItem value='Travail tempaoraire'>Travail tempaoraire</MenuItem>
                            <MenuItem value='Consultance'>Consultance</MenuItem>
                            <MenuItem value='Autre'>Autre</MenuItem>
                        </Form.Input>
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Form.Label>Missions</Form.Label>
                    <ReactQuill  
                        placeholder={"Missions"} 
                        name='missions' 
                        theme="snow" 
                        value={offreField.missions} 
                        onChange={(v)=>handleEditorChange('missions',v)}
                    />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                <DesktopDatePicker
                  label="Date debut"
                  inputFormat="dd/MM/yyyy"
                  value={offreField["datedebut"] || new Date()}
                  name='datedebut'
                  onChange={(val)=>handleChangeDate('datedebut',val)}
                  renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                />
                <DesktopDatePicker
                  label="Date cloture"
                  inputFormat="dd/MM/yyyy"
                  value={offreField["datefin"]  ||new Date()}
                  name='datefin'
                  onChange={(val)=>handleChangeDate('datefin',val)}
                  renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                <Form.Input 
                    size="small" 
                    name="ville" 
                    margin="dense" 
                    onChange={handleChange} 
                    value={offreField['ville']||""} 
                    select 
                    wd="100%"
                    label="Lieu">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem key={ville} value={ville}>{ville}</MenuItem>))}
                  </Form.Input>
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input 
                    name='nombrepost' 
                    value={offreField.nombrepost} 
                    type='Number' 
                    style={{marginRight:20}} 
                    onChange={handleChange} 
                    size="small" 
                    margin="dense" 
                    label="Nombre de post" 
                    wd='50%' />
                </Form.Group>

                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Form.Label>Profils recherchés</Form.Label>
                    <ReactQuill  
                    placeholder={"Profils recherchés"} 
                    name='profils' 
                    theme="snow" value={offreField.profils} 
                    onChange={(v)=>setOffreField({['profils']:v})} />
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                    <Autocomplete
                      multiple
                      freeSolo
                      id="experience-standard"
                      fullWidth
                      options={niveauexperience}
                      onChange={(e,values)=>handeleAutoCompleteChange("niveauexperience",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='niveauexperience'
                          label="Niveau d'experience"
                          placeholder="Niveau d'experience"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
               
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                    <Autocomplete
                      multiple
                      freeSolo
                      id="partenaire-standard"
                      fullWidth
                      options={niveauetudes}
                      onChange={(e,values)=>handeleAutoCompleteChange("niveauetudes",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='niveauetudes'
                          label="Niveau d'etude"
                          placeholder="Niveau d'etude"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                    
                </Form.Group>

                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Autocomplete
                      multiple
                      freeSolo
                      id="secteur-standard"
                      fullWidth
                      options={secteuractivites}
                      onChange={(e,values)=>handeleAutoCompleteChange("secteuractivites",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='secteuractivites'
                          label="Secteurs d'activité concernés"
                          placeholder="Secteurs d'activité concernés"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                   
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                  <Autocomplete
                      multiple
                      freeSolo
                      id="metiers-standard"
                      fullWidth
                      options={metiers}
                      onChange={(e,values)=>handeleAutoCompleteChange("metiers",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='metiers'
                          label="Metiers concernés"
                          placeholder="Metiers concernés"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                </Form.Group>
            
               
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                  <Autocomplete
                      multiple
                      freeSolo
                      id="competence-standard"
                      fullWidth
                      options={competences?competences:[]}
                      onChange={(e,values)=>handeleAutoCompleteChange("competences",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='competences'
                          label="Compétences exigées"
                          placeholder="Compétences exigées"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                </Form.Group>
                
                </div>}
                


                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Button type='reset' onClick={handleClose} >Annuler</Form.Button>
                    <Form.Button type='button' disabled={(activeStep!==2)} onClick={handleAdd} primary>Ajouter</Form.Button> 
                    </Form.Group>
            </DialogActions>
        </Dialog>
    )
}

export default AddOffresExpress