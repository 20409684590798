import React,{useCallback, useState,useEffect} from 'react'
import MenuItem from '@mui/material/MenuItem';
import { Card } from 'components'
import { Form } from 'components'
import { villes,secteuractivites,metiers,niveauetudes,niveauexperience,competences } from 'fixtures/data';
import { makeStyles } from 'tss-react/mui'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'custumstyle.css'
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'lib/axios';
import swal from 'sweetalert';
import { IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()((theme=>({
    coreadd:{
      width:'60%',
      alignSelf:'center',
      [theme.breakpoints.down('md')]: {
        width:'100%',
      },
    },
    boxOffres:{
      display:'flex',
      flexWrap:'wrap',
      flex:1,
      padding:20
  },
  cardOffres:{
    flex:1,
    margin:10,
    padding:20,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
        backgroundColor:'#00B04B',
        color:'white',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
    }
},
cardOffresCheck:{
    flex:1,
    margin:10,
    padding:20,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    backgroundColor:'#00B04B',
    color:'white',
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

}
})))
const initialState ={titre:null,
categorie:null,
description:null,
typecontrat:null,
datedebut:null,
datefin:null,
ville:null,
theme:null,
actes:null,
profils:null,
nombrepost:null,
datecloture:null,
missions:"",
competences:null,
secteuractivites:null,
niveauexperience:null,
metiers:null,
niveauetudes:null,
financeur:null,
conseiller:null,
lieuformation:null,
tempsplein:null,
statut:"En cours",
partenaires:null,
origine:'ML'
}
function AddOffres(props) {
  const {classes} =useStyles()
  const[partenaires,setPartenaires] = React.useState([])
  const navigate = useNavigate()
  const portail = useSelector(state=>state.ui.portail)
  React.useEffect(()=>{
    axios.get(`/api/partenaire/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setPartenaires(res.data)
      }
    })
  },[])

  const handleList = ()=>{
    navigate(`/${portail}/offres`)
  }
  const handleChangeDate = (name,newValue) => {
    setCurrentField({...currentField,
        [name]:newValue
    })
  };

  const [currentField,setCurrentField] = useState(initialState) 
  const handleChange = (event) => {
      const {name,value} =event.target
      setCurrentField({...currentField,[name]:value})
  };
    
  const handleReset = ()=>{
    setCurrentField(initialState)
  }
     
  const handleSubmit =async (e)=>{
    e.preventDefault()
    const fieldSend = {...currentField}
    axios.post(`/api/offre/add`,fieldSend).then(res=>{
      if(res.status === 200 || res.status === 201){
        handleReset()
        swal("Bien !",`Le partenaire ${fieldSend.titre} à été créer avec succès`,'success' )
        handleList()
      }else{
        swal("Ooops !","Impossible de terminé cette opération",'error')
        console.log(res.data);
      }
    }).catch(err=>{
      swal("Ooops!","Une erreur s'est produit lors de le l'enregistrement")
      console.log(err.response);
    })
  }
    
  
  const handeleAutoCompleteChange =(name,values)=>{
      setCurrentField({...currentField, [name]:values})
  }
  const [categories,setCategories] = useState(null)
    const [categorie,setCategorie] = useState(null)
    const [activeStep,setActiveStep] = useState(1)
    const handleCategories = useCallback(()=>{
        axios.get(`/api/categorie-offre/all`).then(res=>{
            if(res.status === 200 || res.status === 201){
                setCategories(res.data)
            }
        })
    },[open])

  const handleSelectCategorie = (cat)=>{
    if(cat){
        setCategorie(cat),
        setCurrentField({...currentField,"categorie":cat})
        setActiveStep(activeStep + 1)
    }
  }
 useEffect(()=>{
      handleCategories()
  },[])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit}>
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
      <Card>
         <Card.Header>
            <div style={{display:'flex',alignItems:'center'}}>
                {activeStep===2&&<IconButton aria-label="part-close" onClick={()=>setActiveStep(activeStep-1)}>
                    <ArrowBackIosIcon sx={{fontSize:30,color:'#00B04B'}} />
                </IconButton>}
                <Card.Title>Créer une offre {categorie&&` [ ${categorie.libelle} ]`}</Card.Title>
            </div>          
            <Card.Hr />
          </Card.Header>
          {activeStep===1&&<div style={{display:'flex',flexDirection:'column'}}>
                    <Typography variant='h6'> Quel type d'offres souhaitez-vous créer ?</Typography>
                    <div className={classes.boxOffres}>
                        {(categories && categories.length!==0)&&categories.map(cat=>{
                            return <Card key={cat.id} onClick={()=>handleSelectCategorie(cat)} className={(categorie && cat.id === categorie.id)?classes.cardOffresCheck:classes.cardOffres}>
                                <span>{cat.libelle}</span>
                            </Card>
                        })}
                    </div>
                    
                </div>}
          {activeStep===2&&
          <div className={classes.coreadd}>
                    <Form.Group>
                      <RadioField
                          groupItems={[{label:'ML',name:'ML'},{label:'Autre',name:'Autre'}]}
                          group="Origine"
                          checkedDefault="Normal"
                          appearance={radiobutton_appearances.primary}
                          onChange ={handleChange}
                          name='origine'
                          vvalue ={currentField.origine}
                      />
                    </Form.Group>
                <Form.Group  margin='10px 0 0px 0'direction ='row' flex={1} >
                  {(partenaires && partenaires.length!==0)&&<Autocomplete
                    id="partenaire-standard"
                    fullWidth
                    options={partenaires}
                    getOptionLabel={(option) => option.raisonsociale}
                    onChange={(e,values)=>handeleAutoCompleteChange("partenaires",values['id'])}
                    renderInput={(params) => (
                      <Form.Input
                        {...params}
                        name='partenaires'
                        label="Partenaires"
                        placeholder="Partenaire"
                        size="small" margin="dense" wd='100%'
                      />
                    )}
                  />}
               
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input name='titre' value={currentField.titre} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Titre" wd='100%' />
                </Form.Group>
                
                
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Input required size="small" name="typecontrat" margin="dense" onChange={handleChange} value={currentField['typecontrat']||""} select style={{marginRight:20}}  label="Type de contrat">
                            <MenuItem  value='No'>Aucun</MenuItem>
                            <MenuItem value='CDI'>CDI</MenuItem>
                            <MenuItem value='CDD'>CDD</MenuItem>
                            <MenuItem value='Stage'>Stage</MenuItem>
                            <MenuItem value='Apprentissage'>Apprentissage</MenuItem>
                            <MenuItem value='Travail tempaoraire'>Travail tempaoraire</MenuItem>
                            <MenuItem value='Consultance'>Consultance</MenuItem>
                            <MenuItem value='Autre'>Autre</MenuItem>
                        </Form.Input>
                </Form.Group>

                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Form.Label>Missions</Form.Label>
                    <ReactQuill  placeholder={"Missions"} name='missions' theme="snow" value={currentField.missions} onChange={(v)=>setCurrentField({...currentField,'missions':v})} />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                <DesktopDatePicker
                  label="Date debut"
                  inputFormat="dd/MM/yyyy"
                  value={currentField["datedebut"] || ""}
                  name='datedebut'
                  onChange={(val)=>handleChangeDate('datedebut',val)}
                  renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                />
                <DesktopDatePicker
                  label="Date cloture"
                  inputFormat="dd/MM/yyyy"
                  value={currentField["datefin"]  ||""}
                  name='datefin'
                  onChange={(val)=>handleChangeDate('datefin',val)}
                  renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                <Form.Input 
                    size="small" 
                    name="ville" 
                    margin="dense" 
                    onChange={handleChange} 
                    value={currentField['ville']||""} 
                    select 
                    wd="100%"
                    label="Lieu">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem key={ville} value={ville}>{ville}</MenuItem>))}
                  </Form.Input>
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input name='nombrepost' value={currentField.nombrepost} type='Number' style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Nombre de post" wd='50%' />
                </Form.Group>

                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Form.Label>Profils recherchés</Form.Label>
                    <ReactQuill  placeholder={"Profils recherchés"} name='profils' theme="snow" value={currentField.profils} onChange={(v)=>setCurrentField({...currentField,['profils']:v})} />
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Autocomplete
                      multiple
                      freeSolo
                      id="experience-standard"
                      fullWidth
                      options={niveauexperience}
                      onChange={(e,values)=>handeleAutoCompleteChange("niveauexperience",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='niveauexperience'
                          label="Niveau d'experience"
                          placeholder="Niveau d'experience"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
               
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                    <Autocomplete
                      multiple
                      freeSolo
                      id="partenaire-standard"
                      fullWidth
                      options={niveauetudes}
                      onChange={(e,values)=>handeleAutoCompleteChange("niveauetudes",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='niveauetudes'
                          label="Niveau d'etude"
                          placeholder="Niveau d'etude"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                    
                </Form.Group>

                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                <Autocomplete
                      multiple
                      freeSolo
                      id="secteur-standard"
                      fullWidth
                      options={secteuractivites}
                      onChange={(e,values)=>handeleAutoCompleteChange("secteuractivites",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='secteuractivites'
                          label="Secteurs d'activité concernés"
                          placeholder="Secteurs d'activité concernés"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                   
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                  <Autocomplete
                      multiple
                      freeSolo
                      id="metiers-standard"
                      fullWidth
                      options={metiers}
                      onChange={(e,values)=>handeleAutoCompleteChange("metiers",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='metiers'
                          label="Metiers concernés"
                          placeholder="Metiers concernés"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                </Form.Group>
            
               
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                  <Autocomplete
                      multiple
                      freeSolo
                      id="competence-standard"
                      fullWidth
                      options={competences?competences:[]}
                      onChange={(e,values)=>handeleAutoCompleteChange("competences",values)}
                      renderInput={(params) => (
                        <Form.Input
                          {...params}
                          name='competences'
                          label="Compétences exigées"
                          placeholder="Compétences exigées"
                          size="small" margin="dense" wd='100%'
                        />
                      )}
                    />
                </Form.Group>
                <Form.Group  margin='10px 20px 20px 0px'direction ='column' flex={1} >
                    <Form.Label>Description</Form.Label>
                    <ReactQuill  
                        placeholder={"Plus d'information"} 
                        name='description' 
                        theme="snow" 
                        value={currentField.description} 
                        onChange={(v)=>setCurrentField({...currentField,['description']:v})} 
                    />
                </Form.Group>
        </div>}
      </Card>
      <Card>
            <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Button onClick={handleReset} >Annuler</Form.Button>
                    <Form.Button type='submit' onClick={handleSubmit} primary>Valider</Form.Button> 
            </Form.Group>
        </Card>
        
    </div>
    </form>
    </LocalizationProvider>
  )
}

export default AddOffres