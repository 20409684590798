import React from 'react'

const DossierRechercheDetaille = () => {
  return (
    <div>
        recherche détaillé
    </div>
  )
}

export default DossierRechercheDetaille
