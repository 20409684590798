import React, {useEffect, useRef, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux'
import Portail from 'components/portail/Portail'
import { Button, IconButton } from '@mui/material'

const JeuneLayout = () => {
  
  let [isDropdownOpen,setIsDropdownOpen] = useState(false);
  const [active,setActive] = useState("profil")
  const [theme,setTheme] = useState('light')
  let profilRef = useRef()
  const myref = useRef()
  const {user}  =useAuth({ middleware: 'auth' })
  const portail = useSelector(state=>state.ui.portail)
  const { logout } = useAuth()
  
  const toggleDropdown = ()=> {
    setIsDropdownOpen(!isDropdownOpen);
 }
useEffect(()=>{
    let handler =(e)=>{
        if(!profilRef.current?.contains(e.target)){
            setIsDropdownOpen(false)
            console.log(profilRef.current);
        }
    }
    document.addEventListener("mousedown",handler)
    return()=>{
        document.removeEventListener("mousedown",handler)
    }
})
// Initialize the dropdown state

  useEffect(()=>{
    setTheme(localStorage.getItem('theme')?localStorage.getItem('theme'):"light")
  },[])

  return (
    <div ref={myref} className={`${theme} ${theme=='dark'?'bg-[#121212]':null} min-h-[100vh]`}>
           <div className='grid grid-cols-4 h-full'>
                <div className="h-full hidden md:block bg-white">
                    <div className="flex flex-col items-center justify-center">
                        <img className="rounded-full w-8 h-8 object-cover" src={require('assets/logo.jpg')} alt="" />
                        <Link to="index.html" className="flex flex-row justify-center items-center mb-2">
                            <h6 className="font-bold text-green-600">PFS PORT-BOUET</h6>
                        </Link>
                    </div>
                    
                    <div className="flex flex-col justify-center items-center mx-4 mb-4">
                        <div className="relative">
                            <img className="rounded-full w-8 h-8" src={user?.photo ? user?.photo : "img/user.jpg"} alt="" />
                            <div className="bg-green-500 rounded-full border-2 border-white absolute right-0 bottom-0 p-1"></div>
                        </div>
                        <div className="flex flex-col">
                            <span className="mb-0 text-[10px] text-black">{user&&user?.name}</span>
                            <Portail current="conseiller"/>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <Link to={`/${portail.toLowerCase()}`} className="nav-item nav-link active"><i className="fa fa-tachometer-alt me-2"></i>Tableau de bord</Link>
                        <Link to="#" className="nav-item nav-link"><i className="fa fa-folder me-2"></i>Mon dossier</Link>
                        
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-th me-2"></i>Demandes</Link>
                            <div className="dropdown-menu bg-transparent border-0">
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-plus me-2"></i>Nouvelle demande</Link>
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-folder-open me-2"></i>Mes demandes</Link>
                            </div>
                        </div>

                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-user-graduate me-2"></i>Formations</Link>
                            <div className="dropdown-menu bg-transparent border-0">
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-search me-2"></i>Recherche une formation</Link>
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-folder-open me-2"></i>Mes formations</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-user-nurse me-2"></i>Emplois</Link>
                            <div className="dropdown-menu bg-transparent border-0">
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-search me-2"></i>Chercher des offres</Link>
                                <Link to="signin.html" className="dropdown-item"><i className="fa fa-folder-open me-2"></i>Mes demandes d'emploi</Link>
                            </div>
                        </div>
                        
                        <Link to="chart.html" className="nav-item nav-link"><i className="fa fa-project-diagram me-2"></i>Projets</Link>
                        <Link to="chart.html" className="nav-item nav-link"><i className="fa fa-servicestack me-2"></i>Dispositifs</Link>

                        <Link to='/' className="nav-item nav-link"><i className="fa fa-arrow-left me-2"></i>Retour au site</Link>
                    </div>
                </div>
                <div className="col-span-4 md:col-span-3">
                    
                        <div className='sticky top-0 text-white bg-green-600 shadow-md flex items-center justify-between px-8 py-02'>

                           
                            <Link to="#" className="text-white mx-2 flex-shrink-0">
                                <i className="fa fa-bars"></i>
                            </Link>
                            <Link to="index.html" className="hidden navbar-brand d-flex d-lg-none me-4">
                                <h4 className="text-white mb-0">PFS-PORTBOUET</h4>
                            </Link>
                            <nav className="nav font-semibold text-lg" >
                        <div className="relative inline-block text-left" ref={profilRef}>
                            <IconButton onClick={toggleDropdown} variant='outlined' className="inline-flex justify-center items-center font-medium text-gray-700 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 p-0">
                                <img className="rounded-full object-cover" src={user?.personne?.photo ? user?.personne?.photo : "img/user.jpg"} alt="" style={{width: 40, height: 40}} />    
                            </IconButton>
                            <div id="dropdown-menu" className={`${isDropdownOpen ? "block" : "hidden"} p-2 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
                                <span className='text-[10px] font-bold text-green-600'>{user?.name}</span>
                                <div class="py-2 p-2" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                                    <Link to="#" className={`flex rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-slate-200 ${active=='profil'&&"bg-blue-100"} cursor-pointer`} role="menuitem">
                                        Mon profile
                                    </Link>
                                    <Link onClick={logout} className={`flex rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-slate-200 ${active=='logout'&&"bg-blue-100"} cursor-pointer`} role="menuitem">
                                        Deconnexion
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </nav>
                    </div>
               
                <Outlet context={[myref]} />         
                <div className="container-fluid pt-4 px-2">
                    <div className="bg-light rounded-top p-4">
                        <div className="row">
                            <div className="col-12 col-sm-6 text-center text-sm-start">
                                &copy; <Link to="#">PFS Port-bouet</Link>, Tous droits réservés. 
                            </div>
                            <div className="col-12 col-sm-6 text-center text-sm-end">
                                Proposé par <Link to="https://arrowgroupeservices.com">Arrow Groupe Service</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

function DropdownItem(props){
    return(
      <li className = 'dropdownItem'>
        <img src={props.img}></img>
        <Link to={props.to}> {props.text} </Link>
      </li>
    );
  }
  

export default JeuneLayout