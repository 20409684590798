import React,{useContext, useEffect, useState} from 'react'
import { Card } from 'components'
import { Form } from 'components'
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AddContext } from 'context/add'
import {villes} from 'fixtures/data'
import StepsPhoto from './StepsPhoto';
import { Autocomplete, Typography } from '@mui/material';
import { pays} from 'fixtures/data'
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {CardActionArea} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import { getExtension } from 'utils/utils';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {styled as  makeStyles} from "@mui/material";
import axios from 'lib/axios';
import swal from 'sweetalert';
import dayjs from 'dayjs';
import { array } from 'prop-types';
const useStyles = makeStyles(theme=>({
    
  content_img:{
    display:'flex',
    flexDirection:'column',
    flex:1,
    position:'relative'
},
photo_label:{
    fontSize:15,
    fontStyle:'italic',
    fontWeight:'bold'
},
logo_box:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border:'1px dashed #707070',
    minHeight:'150px;',
    background: 'rgba(244,244,244,0.6)',
    position:'relative',
    marginBottom:20
},
photo_check:{
    minWidth:'100%',
    minHeight:'100%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
},
input: {
    display: "none"
  },
img_card:{
    minWidth:'60%',
    minHeight:'60%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
}

}))
function StepsIdentification({personne,handleBack,reflesh}) {
  const classes = useStyles()
  const [inputField,setInputField] = useState({...personne})
  const handleFieldChange =(e)=>{
    e.preventDefault()
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }

  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));
  const handleChange = (newValue) => {
    setValue(newValue);
    setInputField({...inputField,'datenaissance':newValue})
  };

  const [state,setState] = useState({
    mainState: "initial",
    imageUploaded: 0,
    selectedFile: null
  })

const {getRootProps, getInputProps} = useDropzone({
  accept: 'image/*',
  onDrop: files => {
    setState({
          ['selectedFile']: files.map((file) => Object.assign(file, { preview:  URL.createObjectURL(file)}))
      });
  }
});
const imageResetHandler = event => {
    setState({
      mainState: "initial",
      selectedFile: null,
      imageUploaded: 0
    });
  };

  useEffect(() => {
    if(state.hasOwnProperty('selectedFile') && state.selectedFile){
       return ()=>state.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [state]);

  const thumbs = (state.hasOwnProperty('selectedFile') && state.selectedFile)&&state.selectedFile.map(file => (
    getExtension(file.name).toLowerCase()=='pdf'?<IconButton onClick={imageResetHandler} ><FilePresentIcon sx={{fontSize:60,color:'#888888'}}/> </IconButton>: <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
        <img
            width="40%"
            className={classes.media}
            src={file.preview}
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
    </CardActionArea>  
    ));

useEffect(()=>{
setInputField({...inputField,['photo']:state.selectedFile})
},[state.selectedFile])

const handleSave = (e)=>{
  e.stopPropagation()
  e.preventDefault()
  
  let formdata = new FormData()
      //personnes
      if(inputField.hasOwnProperty('civilite')){
        formdata.append('civilite',inputField['civilite'])
      }
      if(inputField.hasOwnProperty('nom')){
        formdata.append('nom',inputField['nom'])
      }
      if(inputField.hasOwnProperty('prenom')){
        formdata.append('prenom',inputField['prenom'])
      }
      if(inputField.hasOwnProperty('datenaissance')){
        formdata.append('datenaissance',dayjs(inputField['datenaissance']).format('YYYY-MM-DD'))
      }
      if(inputField.hasOwnProperty('lieunaissance')){
        formdata.append('lieunaissance',inputField['lieunaissance'])
      }
      if(inputField.hasOwnProperty('nationalite')){
        formdata.append('nationalite',inputField['nationalite'])
      }
      if(inputField.hasOwnProperty('numeropiece')){
        formdata.append('numeropiece',inputField['numeropiece'])
      }
      if(inputField.hasOwnProperty('naturepiece')){
      formdata.append('naturepiece',inputField['naturepiece'])
      }
      if(inputField.hasOwnProperty('esthandicap')){
      formdata.append('esthandicap',inputField['esthandicap'])
      }
      if(inputField.hasOwnProperty('naturehandicap')){
        formdata.append('naturehandicap',inputField['naturehandicap'])
      }
      if(inputField.hasOwnProperty('sitmatrimoniale')){
        formdata.append('sitmatrimoniale',inputField['sitmatrimoniale'])
      }
      if(inputField.hasOwnProperty('nbenfant')){
        formdata.append('nbenfant',inputField['nbenfant'])
      }
      if(inputField.hasOwnProperty('statut')){
        formdata.append('statut',inputField['statut'])
      }
      if(inputField.hasOwnProperty('nompere')){
        formdata.append('nompere',inputField['nompere'])
      }
      if(inputField.hasOwnProperty('professionpere')){
        formdata.append('professionpere',inputField['professionpere'])
      }
      if(inputField.hasOwnProperty('nommere')){
        formdata.append('nommere',inputField['nommere'])
      }
      if(inputField.hasOwnProperty('professionmere')){
        formdata.append('professionmere',inputField['professionmere'])
      }
      if(inputField.hasOwnProperty('nomconjoint')){
        formdata.append('nomconjoint',inputField['nomconjoint'])
      }
      if(inputField.hasOwnProperty('professionconjoint')){
        formdata.append('professionconjoint',inputField['professionconjoint'])
      }
      //localisation
      if(inputField.hasOwnProperty('lieuresidence')){
        formdata.append('lieuresidence',inputField['lieuresidence'])
      }
      if(inputField.hasOwnProperty('commune')){
        formdata.append('commune',inputField['commune'])
      }
      if(inputField.hasOwnProperty('quartier')){
        formdata.append('quartier',inputField['quartier'])
      }
      if(inputField.hasOwnProperty('cgqcgv')){
        formdata.append('cgqcgv',inputField['cgqcgv'])
      }
      //contact
      if(inputField.hasOwnProperty('bp')){
        formdata.append('bp',inputField['bp'])
      }
      if(inputField.hasOwnProperty('telephone')){
        formdata.append('telephone',inputField['telephonefixe'])
      }
      if(inputField.hasOwnProperty('mobile1')){
        formdata.append('mobile1',inputField['mobile1'])
      }
      if(inputField.hasOwnProperty('mobile2')){
        formdata.append('mobile2',inputField['mobile2'])
      }
      if(inputField.hasOwnProperty('numerowhatsapp')){
        formdata.append('numerowhatsapp',inputField['numerowhatsapp'])
      }
      if(inputField.hasOwnProperty('email')){
        formdata.append('email',inputField['email'])
      }
      if(inputField.hasOwnProperty('photo') && Array.isArray(inputField.photo) ){
          formdata.append('photo',inputField.photo[0])
      }

     
  axios.post(`/api/personne/update/${inputField.id}`,formdata).then(res=>{
    console.log(res);
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
}


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
      <form onSubmit={handleSave}>
          <Card>
          
                <Form.Group direction ='row' flex={1}>
                    <Form.Input required size="small" name="civilite" margin="dense" onChange={handleFieldChange} value={inputField['civilite']||""} select style={{marginRight:20}}  label="Civilité">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='Monsieur'>Monsieur</MenuItem>
                        <MenuItem value='Madame'>Madame</MenuItem>
                        <MenuItem value='Mademoiselle'>Mademoiselle</MenuItem>
                    </Form.Input>
               </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Nom"
                      required 
                      name='nom' 
                      value={inputField['nom'] ||""}
                      onChange={handleFieldChange} 
                      />
                    <Form.Input 
                      size="small" 
                      name='prenom' 
                      required
                      margin="dense" 
                      label="Prénom"
                      value={inputField['prenom'] ||""}
                      onChange={handleFieldChange} 
                      />
               </Form.Group>
               <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
               <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="dd/MM/yyyy"
                  value={value || inputField['datenaissance']}
                  name='datenaissance'
                  onChange={handleChange}
                  renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                />
                 <Form.Input 
                    size="small" 
                    name="lieunaissance" 
                    margin="dense" 
                    onChange={handleFieldChange} 
                    value={inputField['lieunaissance']||""} 
                    select 
                    label="Lieu de naissance">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem  key={ville} value={ville}>{ville}</MenuItem>))}
                  </Form.Input>
               </Form.Group>
               <Form.Group margin='10px 0 0 0'direction ='row' flex={1}>
                  <Autocomplete
                          disablePortal
                          id="nationalite"
                          size="small" 
                          margin="dense"
                          getOptionLabel={(option) => option.nationalite ? option.nationalite : 'ivoirienne'}
                          defaultValue='ivoirienne'
                          options={pays}
                          sx={{ width: '100%' }}
                          style={{marginRight:20}}  
                          value={inputField['nationalite'] ||""}
                          name='nationalite'
                          onChange={(e,v)=>setInputField({...inputField,'nationalite':v&&v.nationalite})}
                          renderInput={(params) => <Form.Input {...params} name='nationalite' label="Nationalité" wd='50%' />}
                      />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Numéro de la pièce"
                      value={inputField['numeropiece'] ||""}
                      name='numeropiece'
                      onChange={handleFieldChange}
                      />
                    <Form.Input 
                        size="small" 
                        margin="dense" 
                        select  
                        label="Nature de la pièce"
                        value={inputField['naturepiece'] ||""}
                        name='naturepiece'
                        onChange={handleFieldChange}
                        >
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='CNI'>CNI</MenuItem>
                        <MenuItem value='Attestation'>Attestation d'identité</MenuItem>
                        <MenuItem value='Passport'>Passport</MenuItem>
                    </Form.Input>
               </Form.Group>
  
               <Card.Hr />
               <Form.Group direction ='row' salign='center' flex={1}>
                <RadioField
                    groupItems={[
                      {
                        name: "O",
                        label: "Oui",
                      
                      },
                      {
                        name: "N",
                        label: "Non",
                      
                      }
                    ]}
                    group="Est sujet ou victime d'un handicap"
                    checkedDefault="O"
                    appearance={radiobutton_appearances.primary}
                    onChange ={handleFieldChange}
                    name='esthandicap'
                    vvalue ={inputField['esthandicap'] || null}
                />
                {(inputField['esthandicap'] && inputField['esthandicap']==='O')&& <Form.Input 
                  size="small" 
                  name='naturehandicap' 
                  required
                  margin="dense" 
                  label="Nature de l'handicap"
                  value={inputField['naturehandicap'] ||""}
                  onChange={handleFieldChange}
                />}
                
                </Form.Group>
      </Card>
      <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Situation matrimoniale</Typography>
      <Card>
              <Form.Group direction ='row' flex={1}>
                  <Form.Input 
                      size="small" 
                      margin="dense" 
                      select 
                      style={{marginRight:20}}  
                      label="Situation matrimoniale"
                      value={inputField['sitmatrimoniale'] ||""}
                      name='sitmatrimoniale'
                      onChange={handleFieldChange}
                      >
                      <MenuItem  value='No'>Aucun</MenuItem>
                      <MenuItem value='Célibataire'>Célibataire</MenuItem>
                      <MenuItem value='Marié(e)'>Marié(e)</MenuItem>
                      <MenuItem value='Divorcé'>Divorcé</MenuItem>
                      <MenuItem value='Concubinage'>En concubinage</MenuItem>
                  </Form.Input>
                  <Form.Input 
                    size="small" 
                    margin="dense" 
                    type='Number' 
                   
                    label="Nombre d'enfant" 
                    value={inputField['nbenfant'] ||""}
                    name='nbenfant'
                    onChange={handleFieldChange}
                    />
             </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                  <Form.Input 
                    size="small" 
                    margin="dense" 
                    style={{marginRight:20}} 
                    label="Nom du conjoint ou de la conjointe"
                    value={inputField['nomconjoint'] ||""}
                    name='nomconjoint'
                    onChange={handleFieldChange}
                    />
                  <Form.Input 
                    size="small" 
                    margin="dense" 
                    label="Profession" 
                    value={inputField['professionconjoint'] ||""}
                    name='professionconjoint'
                    onChange={handleFieldChange}
                    />
             </Form.Group>
             
      </Card>
      <Typography  sx={{ fontStyle: 'italic' }} variant='h6'>Parents</Typography>
      <Card>
        
            <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Nom du père"
                      value={inputField['nompere'] ||""}
                      name='nompere'
                      onChange={handleFieldChange}
                      />
                   <Form.Input 
                    size="small" 
                    margin="dense" 
                   
                    label="Profession du père" 
                    value={inputField['professionpere'] ||""}
                    name='professionpere'
                    onChange={handleFieldChange}
                    />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                  <Form.Input 
                      size="small" 
                      margin="dense" 
                      label="Nom de la mère"
                      style={{marginRight:20}}  
                      value={inputField['nommere'] ||""}
                      name='nommere'
                      onChange={handleFieldChange}
                      />
                       <Form.Input 
                        size="small" 
                        margin="dense" 
                        label="Profession de la mère" 
                        value={inputField['professionmere'] ||""}
                       
                        name='professionmere'
                        onChange={handleFieldChange}
                        />
               </Form.Group>
           </Card>
           <Typography  sx={{ fontStyle: 'italic' }} variant='h6'>Photo</Typography>
            <Card>
            <div className={classes.content_img}>
                  <Typography className={classes.photo_label}>Cliquez ou glissez déposer votre photo ici</Typography>
                  <div className={classes.logo_box} {...getRootProps()}>
                  
                      {thumbs}
                  
                      <input
                          {...getInputProps()}
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          name='photo'
                          disabled={!state.selectedFile}
                          />
                          {!state.selectedFile&&<label className={classes.photo_check} htmlFor="contained-button-file">
                              <AddAPhotoIcon sx={{fontSize:60,color:'#888888'}} />
                          </label>}
                  </div>
            </div>
            </Card>
      {/*<StepsPhoto handleNext={handleNext} handleBack={handleBack} steps={steps} step={step} />*/}
      
      <Card direction='row' palign={"space-between"}>
        <Form.Button onClick={handleBack} >Retour</Form.Button>

        <Form.Button type='submit' primary>Enregistrer</Form.Button>
      </Card>
      </form>
      </div>
      </LocalizationProvider>
  )
}

export default StepsIdentification