import React, {useEffect } from 'react'
import { Card } from 'components'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useOutletContext,useLocation } from 'react-router-dom';
import { getUrlFile,getOfresPartenaires } from 'db/dbActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { makeStyles } from 'tss-react/mui';
import Offreh from 'containers/Offreh/Offreh';
const useStyles = makeStyles()((theme=>({
  header:{
    display:'flex',

  },
  logoContainer:{
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    flex:1,
   
  },
  titreContainer:{
    display:'flex',
    alignItems:'center',
    flex:2
  },
  detContainer:{
    display:'flex',
    flexDirection:'row',
    flex:1,
    justifyContent:'center'
  },

  detItemLeft:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    alignItems:'flex-end',
    fontWeight:'bold',
    justifyContent:'flex-start',
   
  },
  detItemRight:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flex:1,
    marginLeft:20,
  },
})))

//style
const styles ={
  detContainer:{
    display:'flex',
    flexDirection:'row',
    flex:1,
    justifyContent:'center',
    marginBottom:20
  },

  detItemLeft:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    alignItems:'flex-end',
    fontWeight:'bold',
    justifyContent:'flex-start',
   
  },
  detItemRight:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flex:1,
    marginLeft:20,
  },
  label:{
    fontStyle:'italic',
    fontWeight:'bold'
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function PartenaireFiche(props) {
  const {classes} = useStyles()
  const [logourl,setLogourl] =React.useState(null)
  const params = useLocation()
  const [titre,setTitre] = useOutletContext();
  const {state:{data}} = params
  const [offrespartenaires,setOffrespartenaire] = React.useState(null)
  const [eventpartenaires,setEventpartenaire] = React.useState(null)
  const [demandespart,setDemandepart] = React.useState(null)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    getUrlFile(data['logo']).then(url=>{setLogourl(url)})
  },[data])


  const handleOffresPartenaire =()=>{
    getOfresPartenaires(data['id']).then(res=>{
      if(res['error']==false){
        console.log(res.data);
        setOffrespartenaire(res.data)
      }
    })
  } 
  useEffect(()=>{
    handleOffresPartenaire()
  },[data.id])

  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
         <Card>
          <Card.Header>
            <Card.Title>FICHE DU PARTENAIRE</Card.Title>
              <Card.Hr />
          </Card.Header>
          <div styles={{display:'flex',flexDirection:'column',alignItems:'center'}} >
              <div className={classes.logoContainer}>
                <Box sx={{width:'25%'}}>
                  <img
                      width="50%"
                     
                      src={logourl}
                  />
                </Box> 
                <Box>
                    <Typography variant='h2'>{data['raisonsociale']}</Typography> 
                    <Typography variant='subtitle2'>{data['code']||' '}</Typography>
                  </Box>
              </div>
             
              <div style={styles.detContainer}>
                  <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Type : </Typography> 
                      <Typography style={styles.label}>Capital : </Typography>
                      <Typography style={styles.label}>Activités : </Typography> 
                  </div>
                  <div style={styles.detItemRight}>
                    <Typography>{data['formejuridique']||' '}</Typography>
                    <Typography>{data['capital']||' '}</Typography>
                    <Typography>{data['activiteprincipal']||' '}</Typography>
                </div>
              </div>
              <div style={styles.detContainer}>
                  <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>N° RC : </Typography> 
                      <Typography style={styles.label}>N° contribuable : </Typography>
                  </div>
                  <div style={styles.detItemRight}>
                    <Typography>{data['comptecontribual']||' - '}</Typography>
                    <Typography>{data['numerorc']||' - '}</Typography>
                </div>
              </div>
              <div style={styles.detContainer}>
                  <div style={styles.detItemLeft}>
                      <Typography style={styles.label}>Localistation : </Typography> 
                      <Typography style={styles.label}>Téléphone : </Typography>
                      <Typography style={styles.label}>Mobile : </Typography>
                      <Typography style={styles.label}>Adresse e-mail : </Typography>
                      <Typography style={styles.label}>Site web : </Typography>
                  </div>
                  <div style={styles.detItemRight}>
                    <Typography>{data['ville']||' - '}</Typography>
                    <Typography>{data['telephone']||' - '}</Typography>
                    <Typography>{data['mobile']||' - '}</Typography>
                    <Typography>{data['email']||' - '}</Typography>
                    <Typography>{data['siteweb']||' - '}</Typography>
                </div>
              </div>
          </div>
              
          <div style={{display:'flex',justifyContent:'center'}}>
          <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary" indicatorColor="secondary">
                  <Tab label="Offres" {...a11yProps(0)} />
                  <Tab label="Personnes ressouces" {...a11yProps(1)} />
                  <Tab label="Evenements" {...a11yProps(2)} />
                  <Tab label="Demandes satisfaite" {...a11yProps(3)} />
                  <Tab label="Demandes en attente" {...a11yProps(4)} />
                </Tabs>
              </Box>
          <TabPanel value={value} index={0}>
           {(offrespartenaires  && offrespartenaires.length!==0)&&<MaterialReactTable 
              columns={[
                {title:"code",field:'code',render:rowdata=>(<Offreh readmore  offre={rowdata}  />)},
              ]}
              data={offrespartenaires}
              enableColumnOrdering
              enableEditing
              enablePinning
              enableRowActions
              enableRowSelection
              enableSelectAll={false}
              initialState={{ showColumnFilters: true, showGlobalFilter: true }}
              localization={MRT_Localization_FR}
           />}
          </TabPanel>
          <TabPanel value={value} index={1}>
          {(data.hasOwnProperty('personnesressources') && data['personnesressources'].length!==0)&&<TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableBody>
                    {data.personnesressources.map((perso,indx)=>{
                        return <TableRow key={perso.id}>
                            <TableCell align="center">{perso['fonction'] || ""}</TableCell>
                            <TableCell align="left">{perso['nom'] || ""}</TableCell>
                            <TableCell align="left">{perso['prenom'] ||""}</TableCell>
                            <TableCell align="left">{perso['telephone'] ||""}</TableCell>
                            <TableCell align="left">{perso['mobile'] ||""}</TableCell>
                            <TableCell align="left">{perso['email'] ||""}</TableCell> 
                    </TableRow>
                    })}
                    </TableBody>
                </Table>
                </TableContainer>}
          </TabPanel>
          <TabPanel value={value} index={2}>
          {(data.hasOwnProperty('evenements') &&  data['evenements'].length!==0)&&<TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableBody>
                    {data.personnesressources.map((perso,indx)=>{
                        return <TableRow key={perso.id}>
                            <TableCell align="center">{perso['type'] || ""}</TableCell>
                            <TableCell align="left">{perso['daterealisation'] || ""}</TableCell>
                            <TableCell align="left">{perso['dossier'] ||""}</TableCell>
                            <TableCell align="left">{perso['statut'] ||""}</TableCell>
                    </TableRow>
                    })}
                    </TableBody>
                </Table>
                </TableContainer>}
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
              Five
          </TabPanel>
        </Box>
          </div>
          <div></div>
        </Card>
    </div>
  )
}

export default PartenaireFiche