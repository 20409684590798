import React, { useEffect } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import axios from 'lib/axios';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
 const compare = (a,b)=> {
  if ( a.mois < b.mois ){
    return -1;
  }
  if ( a.mois > b.mois ){
    return 1;
  }
  return 0;
}

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  
 
  ChartJS.register(...registerables);
function GrDossier() {
  const labels = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Aout','Septembre','Octobre','Novembre','Decembre'];
  const [evodata,setEvodata] = React.useState([])
  const handlaDataSet = ()=>{
    axios.get(`/api/state/dossier-mensuel`).then(res=>{
      if(res.status ===200 || res.status===201){
        const data = res.data.sort(compare)
        let final =[]
        labels.forEach((m,ix)=>{
          const str =`000${ix+1}`.slice(-2)
          const idx = data.findIndex(item => item.mois === str)
          if(idx!==-1){
            final.push(data[idx].total)
          }else{
          
            final.push(0)
          }
        })
        setEvodata(final)
      }
    })
  }
  useEffect(()=>{
    handlaDataSet()
  },[])
  const data = {
    labels,
    datasets: [
      {
        label: 'Nouveau dossier',
        data:evodata,
      },
    ],
  };
  return (
    <div style={{paddin:20,width:'100%',height:'100%'}} >
       <Bar options={options} data={data} />
    </div>
  )
}

export default GrDossier