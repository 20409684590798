import React from 'react'

function Fabout() {
  return (
    
    <div className="container-fluid about pt-5">
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-5 mb-5 mb-lg-0">
                        <div className="d-flex h-100 border border-5 border-primary border-bottom-0 pt-1">
                            <img className="img-fluid  mx-auto" src={require("../../img/about_psf.png")} />
                        </div>
                    </div>
                    <div className="col-lg-7 pb-2">
                        <div className="mb-3 pb-1">
                            <h6 className="text-primary text-uppercase">A propos </h6>
                            <h4 className="display-6">ACCUEIL – INFORMATION – ORIENTATION</h4>
                        </div>
                        <p className="mb-4">Nous contribuons à la formation, au financement de jeunes entrepreneurs et à la qualification vers l’emploi dans le cadre de la politique de développement de la Commune</p>
                        <div className="row gx-5 gy-4">
                            <div className="col-sm-6">
                                <i className="fa fa-seedling display-1 text-secondary"></i>
                                <h4 className='text-primary'>Mission</h4>
                            
                                    <ul className="mb-0">
                                        <li>L’information, l’Accueil et l’Orientation des Jeunes de 18 à 40 ans, cibles de nos prestations </li>
                                        <li>La promotion auprès des jeunes de la Commune, des offres d’insertion retenues par la PFS </li>
                                        <li>Renforcement des capacités des jeunes en matière de création d’entreprise </li>
                                        <li>Le profilage des jeunes désirant être accompagnés dans un parcours d’insertion (enregistrement dans la base de données) (orientation des jeunes vers les pôles qualification ou entrepreneuriat) </li>
                                        <li>L’organisation des sessions d’information sur des thèmes liés à l’insertion ...</li>
                                    </ul>
                                        
                                
                            </div>
                            <div className="col-sm-6">
                                <i className="fa fa-award display-1 text-secondary"></i>
                                <h4 className='text-primary'>Cible</h4>
                                <p className="mb-0">Les jeunes femmes et hommes volontaires en accord avec les exigences du dispositif.
    Jeunes âgés de 18 à 40 ans à partir de 14 ans pour la professionnalisation par l’apprentissage.
    Sans instruction ou de niveau primaire, secondaire ou diplômés de l’enseignement supérieur.
    Avec ou sans idées de projets, ou porteurs de projets plus ou moins formalisés.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Fabout