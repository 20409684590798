
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CommonsContext } from 'context/CommonsContext'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button, Chip, CircularProgress, Divider, IconButton, Paper, Tooltip } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Form } from 'components'
import axios from 'lib/axios'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustumModal from 'components/CustumModal'
import  MaterialReactTable  from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import DeleteIcon from '@mui/icons-material/Delete';
import { cmerge } from 'lib/application'
import swal from 'sweetalert'
const FicheAffectationDossier = () => {
  const [loading,setLoading] = useState(false)
  const [sloading,setSloading] = useState(false)
  const location =  useLocation()
  const [dossier,setDossier] =  useState(location.state?.dossier)

  const [inputfield,setInputField] = useState({})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputfield,[name]:value})
  }
  const {handleCommonsdata} = useContext(CommonsContext)
  useEffect(()=>{
    handleCommonsdata('titre',"Dossier - Affectation de dossier")
  },[])
  const handleEditorChange =(field,value)=>{
    setInputField({...inputfield,[field]:value});
  }

  const [conseillerList,setConseillerList] = useState([])
  const handleConseillerList =()=>{
    setLoading(true)
    axios.get(`/api/users/actif`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setConseillerList(res.data)
        setLoading(false)
      }else{
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false)
    })
  }
  useEffect(()=>{
    handleConseillerList()
  },[])

  const [nouveauDossier,setNouveauDossier] = useState()

  const handleGetNouveauDossier = ()=>{
    axios.post(`/api/dossier/parametrer`,{etape:['Nouveau dossier'],statut:['Actif']}).then(res=>{
      setLoading(true)
      if(res.status ===200 || res.status===201){
        setNouveauDossier(res.data)
        setLoading(false)
      }else{
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false)
    })
  }

  const [open,setOpen] = useState(false)
  const handleClose =(e,reason)=>{
    if (reason && reason === "backdropClick") 
        return;
    setOpen(false)
  }
  const handleOpen =()=>{
    setOpen(true)
  }
  useEffect(()=>{
    handleGetNouveauDossier()
  },[])

  const table = {
    columns:useMemo(()=>[
      { accessorFn:row=>(<div className='flex flex-col'>
          <h6>{`${row.jeune.nom} ${row.jeune.prenom}`}</h6>
            <div><span className='mr-2 italic'>Né(e) le : </span><span>{dayjs(row.jeune.datenaissence).format('DD/MM/YYYY')}</span></div>
            <div><span className='mr-2 italic'> Dossier N°:</span><span>{row.code}</span></div>
           
            <div><span className='mr-2 italic'>Date dossier : </span><span>{dayjs(row.datedossier).format('DD/MM/YYYY')}</span></div>
          </div>
          ),
        header: 'Jeune'
      },
      { 
        accessorFn:row=>(
          row.demandes.map(dmd=>dmd.libelle)
        ),
        header: 'Demande' }
     
    ]),
    data:nouveauDossier || [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  };
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const [selection,setSelection] = useState(null)
  const handleSelection =()=>{
    setSelection(tableInstanceRef.current?.getSelectedRowModel().rows?.map(r=>r.original))
    handleClose()
  }
  useEffect(()=>{
    handleSelection()
  },[rowSelection])
 const handleAddDossier = ()=>{
    let newDossier =[]  
  if(dossier && Array.isArray(dossier)){
    newDossier = cmerge(dossier, selection, 'code')
  }else{
    if(selection.findIndex(item=>item.code === dossier.code) !==-1){
      newDossier =[...selection]
    }else{
      newDossier =[dossier,...selection]
    }
    
  }
  setDossier(newDossier)
 }

 const handleRemove=(row)=>{
  setDossier(dossier.filter(item=>item.code!==row.code))
 }
 //enegistrement de l'affectation
 const handleSave=(e)=>{
  e.preventDefault();
  e.stopPropagation()

  setSloading(true)
  const dataSend = {
    conseiller:inputfield.conseiller.id,
    note:inputfield.note,
    dossiers:Array.isArray(dossier) ? dossier : [dossier]
  }
  axios.post(`api/dossier/affecter/dossier`,dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
      setSloading(false)
      swal("Bien !","Affectation effectuée avec succès","success")
    }else{
      setSloading(false)
      swal("Ooops!","Une erreur s'est produite lors de l'affectation","error")
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      setSloading(false)
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      setSloading(false)
      swal("Ooops!","Une erreur inconnu s'est produite lors de l'affectation","error")
      console.log(err);
    }
  })
 }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustumModal 
        open={open} 
        handleClose={handleClose} 
        titre="Selectionnez un dossier dans la liste" 
        width='md'
        actions={
          <div className='flex flex-row justify-between items-center py-3 mx-2'>
            <Button className='px-4 py-2 bg-orange-400 text-white font-medium' onClick={handleClose}>Annuler</Button>
            <Button type='button' onClick ={handleAddDossier} disabled={!(selection && selection.length!==0)} className='px-4 py-2 bg-green-500 hover:bg-orange-400 text-white font-medium'>Ajouter</Button>
          </div>
        }
      >
        <MaterialReactTable 
          columns={table.columns} 
          data={table.data}
          enableRowSelection={true}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePinning
          enableRowActions={false}
          enableSelectAll={true}
          localization={MRT_Localization_FR}
          muiSelectCheckboxProps={{
            color: 'secondary', //makes all checkboxes use the secondary color
          }}
          tableInstanceRef={tableInstanceRef}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
        />
      </CustumModal>


    <div className='my-4'>
        <div className='rounded shadow-lg bg-white md:max-w-screen-sm max-w-md mx-auto '>
          <div className=' flex justify-between items-center p-2 bg-green-200 rounded-s-md'>
            <h1>Affectation de dossier</h1>
            <Tooltip title='Completer la liste des dossiers'>
              <IconButton className='group' onClick={handleOpen}>
                <AddCircleIcon className='text-green-500 text-[40px]' />
              </IconButton>
            </Tooltip>
            
          </div>
          {
            (dossier && Array.isArray(dossier)) ?(dossier.map(doss=>(
              <div className='flex flex-col relative'>

             
              <div className='flex flex-row justify-between bg-green-100 p-2 group'>
                <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{doss['code'] || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(doss['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(doss['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{doss['objet'] || ""}</span></div>     
                    <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{doss['statut'] || ""}</span></div>     
                </div>
                <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{doss['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{doss['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(doss['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{doss['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{doss['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{doss['jeune']['quartier'] || ""}</span></div>     
                </div>
              
          </div>
            
            <Divider  />
            <div className='bg-[#000000a8] absolute flex flex-row justify-center items-center  left-0 top-0 bottom-0 right-0'>
                <IconButton onClick={()=>handleRemove(doss)}>
                  <DeleteIcon />
                </IconButton>
            </div>
           </div>
            ))):(
              
              <div className='flex flex-row justify-between bg-green-100 p-2'>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{dossier['code'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(dossier['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(dossier['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{dossier['objet'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{dossier['statut'] || ""}</span></div>     
              </div>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{dossier['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{dossier['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(dossier['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{dossier['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{dossier['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{dossier['jeune']['quartier'] || ""}</span></div>     
              </div>
          </div>
            )

            
          }
          

           <div className='p-2'>
              <Paper className='my-4 p-2 pb-4'>
                <form onSubmit={handleSave}>
                  <Autocomplete
                      disablePortal
                      id="conseiller"
                      size="small" 
                      margin="dense"
                      getOptionLabel={(option) => (option.personne?.nom && option.personne?.prenom) ? `${option.personne.nom} ${option.personne.prenom}` : ''}
                    
                      options={conseillerList || []}
                      style={{marginRight:20,width: '100%'}}  
                      value={inputfield['conseiller'] ||""}
                      name='conseiller'
                      onChange={(e,v)=>setInputField({...inputfield,'conseiller':v})}
                      renderInput={(params) => <Form.Input {...params} name='conseiller' defaltValue='' label="Selectionnez un conseiller" wd='100%' />}
                  />
                <ReactQuill  
                      placeholder={"Note d'affectation"} 
                      style={{width:'100%',height:100,marginBottom:20}}
                      name='note' 
                      theme="snow" value={inputfield['note'] || ""} 
                      onChange={(v)=>handleEditorChange('note',v)} 
                  
                  />
                  <div className='flex flex-row justify-between items-center my-5'>
                    <Form.Button type='reset' >Annuler</Form.Button> 
                      <Form.Button disabled={sloading} type="submit" primary>
                          {sloading ? <CircularProgress /> : "Enregistrer"} 
                      </Form.Button> 
                    </div>
                </form>
              </Paper>

           </div>
        </div>
    </div>
    </LocalizationProvider>
  )
}

export default FicheAffectationDossier