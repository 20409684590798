import React,{useContext} from 'react'
import styled from 'styled-components/macro'
import {CommonsContext} from 'context/CommonsContext'
const Container = styled.div`
    padding:20px;
    border-left:5px solid #00B04B;
    margin:0px 20px 20px 20px;
    background-color: white;
    border-radius: 0px;
`
const Texte = styled.h4`
    color: #00B04B;
    font-style:italic;
`
function HeaderTitle() {
 const {commonsField} = useContext(CommonsContext)
 
  return (
    <Container>
        <Texte>{commonsField?.titre}</Texte>
    </Container>
  )
}

export default HeaderTitle