import { IconButton, Paper, Typography,Button, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit';
import { Card } from 'components'
import ReplyIcon from '@mui/icons-material/Reply';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'lib/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
const FicheModule = () => {
const params = useParams()
const navigate = useNavigate()
    const portail =  useSelector(state=>state.ui.portail).toLowerCase()
  const [data,setData] = useState(null)
  const handleGetModule =()=>{
    if(params && params.hasOwnProperty('id')){
      axios.get(`/api/modules/${params['id']}`).then(res=>{
        if(res.status ===200 || res.status ===201){
          setData(res.data)
        }
      })
    }
  }
  useEffect(()=>{
    handleGetModule()
  },[])
  const handleDeleteModule =()=>{
    swal({
      title: "Voullez-vous vraiment supprimer ce module?",
      text: "Cett opération est irreversible, vous perdriez toutes les informations enregistrées à propos de ce module !",
      icon: "warning",
      buttons:{
        cancel:"Annuler",
        confirm:'Supprimer'
      },
      dangerMode: true,
    }).then(willDelete=> {
      if(willDelete){
        axios.post(`api/modules/delete/${params['id']}`).then(res=>{
          if(res.status ===204 || res.status ===200 && res.status ===201){
            swal(
              'Suppression!',
              'Module supprimé avec succès.',
              'success'
            )
            navigate(`/${portail}/parametres`)
          }else{
            swal({
              title:"Oops",
              text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
              icon:"error",
              buttons:{
                confirm:{
                  text:"OK",
                  className:'bg-red-500'
                }
              }
            })
          }
        })
      }
      
    })
  }
  return (
    <div className='container md:max-w-screen-sm mx-auto'>
        <div className='flex justify-between items-center p-2'>
              <Button 
                    variant="outline-success" 
                    className="bg-green-600 text-white flex justify-center items-center hover:bg-green-900" 
                    onClick={()=>navigate(`/${portail}/parametres`)}>
                    <ReplyIcon/>Retour à la liste
                </Button>
                <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Fiche module/Privillèges N° #{data&&[data['id']]} </Typography>
                
                <div className='flex flex-row gap-3'>
                <IconButton onClick={()=>navigate(`/${portail}/parametres/modules/edit/${params.id}`)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleDeleteModule}>
                   <DeleteIcon />
                </IconButton>
                </div>
                
          </div>
          <Paper className="formbox" variant='outlined'>
              <Card>
                  <Card.Header>
                      <Card.Title>Information générales</Card.Title>
                  <Card.Hr />
                  </Card.Header>
                  <div className='flex flex-row justify-center items-center gap-2'>
                      <div className='flex flex-col'>
                        <Typography  className='font-bold italic'>Portail : </Typography> 
                        <Typography  className='font-bold italic'>Libelle : </Typography> 
                        <Typography  className='font-bold italic'>Code : </Typography> 
                        <Typography  className='font-bold italic'>Description : </Typography> 
                      </div>
                      <div className='flex flex-col'>
                        <Typography variant='subtitle'>{data&&data['portail']["libelle"] || "--"}</Typography> 
                        <Typography  variant='subtitle'>{data&&data["libelle"] || "--"}</Typography> 
                        <Typography  variant='subtitle'>{data&&data["codemodule"] || "--"}</Typography> 
                        <Typography  variant='subtitle'>{data&&data["description"] || "--"}</Typography> 
                      </div>
                  </div>
              </Card>
              <Card>
                  <Card.Header>
                      <Card.Title>Privilèges</Card.Title>
                  <Card.Hr />
                  </Card.Header>
                  {(data && data.hasOwnProperty('privileges') && data.privileges?.length!==0)?<TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                {data.privileges.map((right) => (
                                    <TableRow
                                    key={right.perms}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell>{right.libelle}</TableCell>
                                    <TableCell align="center">{right.perms}</TableCell>
                                    <TableCell align="center">{(right.hasOwnProperty('subperms') && right.subperms?.length!==0)&&right.subperms?.join()}</TableCell>
                                    <TableCell align="center">{right.type}</TableCell>
                                    <TableCell align="center">{right.default}</TableCell>
                                    <TableCell align="center"><Tooltip title="Supprimer">
                                            <IconButton onClick={()=>handleDeleteRight(right)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> :<p className='p-4 my-4 rounded-sm bg-slate-200 text-slate-600 font-bold' >Aucun privilège disponible</p>}
              </Card>
              <Card>
                  <Card.Header>
                      <Card.Title>Profils Associés</Card.Title>
                  <Card.Hr />
                  </Card.Header>
              </Card>
          </Paper>
    </div>
  )
}

export default FicheModule