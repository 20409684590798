import React from "react"
import { SideBar } from "components"
import logo from 'assets/logo.jpg'
import {menu} from 'fixtures/menu'

import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

import Portail from "components/portail/Portail";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function SideBarContainer({mobile,handleClose}){
    const navigate = useNavigate()
    const portail = useSelector(state=>state.ui.portail)
    const handleParametre =(e)=>{
        e.preventDefault()
        navigate(`/${portail}/parametres`)
       // navigate(0)
    }
    return (<SideBar>
        {!mobile&&<div>
                <SideBar.Inner salign='center' palign='center'>
                    <SideBar.Logo src ={logo} />
                </SideBar.Inner>
                <SideBar.Inner salign='center' palign='center'>
                    <Typography variant="h6">Mon portail</Typography>
                    <Portail current="conseiller"/>
                </SideBar.Inner>

            </div>}
            
            

        <SideBar.Inner palign='flex-start'>
                <SideBar.Base>
                    {menu.map(element => (
                          <SideBar.Item key={element.id}> 
                            <SideBar.Group salign='center'>
                                {element.icon==='home'&&<HomeIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='folder'&&<FolderIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='openFolder'&&<DriveFolderUploadIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='services'&&<DesignServicesIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='offer'&&<LocalOfferIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='etats'&&<LeaderboardIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='partner'&&<GroupIcon  sx={{ fontSize: 30}}/>}
                                {element.icon==='tools'&&<HomeRepairServiceIcon  sx={{ fontSize: 30}}/>}
                               <SideBar.Link to={(element.libelle==='Accueil')?`/${portail.toLowerCase()}`:`/${portail.toLowerCase()}/${element.lien}`}><SideBar.Texte>{element.libelle}</SideBar.Texte> </SideBar.Link>
                            </SideBar.Group>
                          </SideBar.Item>
                    ))}
                </SideBar.Base>   
        </SideBar.Inner>
        <SideBar.Inner palign='flex-end'>
                <SideBar.Button onClick={handleParametre}>
                    <SettingsIcon sx={{ fontSize: 40}} />
                </SideBar.Button>
            </SideBar.Inner>
    </SideBar>)
}