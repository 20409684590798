import React, { useEffect, useState,useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Fteams from '../components/fteams/Fteams'
import Fabout from '../components/fabout/Fabout'
import Fstats from '../components/fstats/Fstats'
import Flink from '../components/Flink'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux';
import ConnexionBox from '../components/ConnexionBox';
import { Suspense } from 'react';
import {Spinner } from 'react-bootstrap';
import axios from 'lib/axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Offrev from 'containers/Offrev/Offrev'
function Home() {
    const {user}  =useAuth({ middleware: 'auth' })
    const portail = useSelector(state=>state.ui.portail)
    const [lastOffre,setLastOffres] = useState(null)
    const handleLastOffres = useCallback(()=>{
        axios.get(`/api/offre/last`).then(res=>{
          if(res.status === 200 || res.status === 201){
            setLastOffres(res.data)
          }
        })
      },[])
      useEffect(()=>{
        handleLastOffres()
      },[])
    const navigate = useNavigate()
      const handleGourl =(url)=>{
        if(url){
            let a = document.createElement('a')
            a.href='url'
            a.target ='_blank'
            a.click()
        }
            
      }
  return (
    <>
       <div className="container-fluid p-0">
            <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="w-100" src={require("../img/carousel_entretien.jpg")} alt="Image" />
                        <div  style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Insertion socio-professionnelle</h3>
                                <h1 className="display-1 text-white mb-md-4">Démarrer votre parcours maintenant</h1>
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                                
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_recrutement.jpg")} alt="Image" />
                        <div  style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Insertion socio-professionnelle</h3>
                                <h1 className="display-1 text-white mb-md-4">Démarrer votre parcours maintenant</h1>
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                                
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_recrutement2.jpg")} alt="Image" />
                        <div  style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Insertion socio-professionnelle</h3>
                                <h1 className="display-1 text-white mb-md-4">Démarrer votre parcours maintenant</h1>
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                                
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_formation.jpg")} alt="Image" />
                        <div  style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Emplois et formations</h3>
                                <h1 className="display-1 text-white mb-md-4">Recherche d'emplois et renforcement des capacités</h1>
                                
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_formation2.png")} alt="Image" />
                        <div  style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Emplois et formations</h3>
                                <h1 className="display-1 text-white mb-md-4">Recherche d'emplois et renforcement des capacités</h1>
                                
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_financement.jpg")} alt="Image" />
                        <div style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Projets</h3>
                                <h1 className="display-1 text-white mb-md-4">Soyez assister par nos meilleures consseillers</h1>
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="w-100" src={require("../img/carousel_activite.png")} alt="Image" />
                        <div style={{backgroundColor: 'rgba(0, 0, 0, .4)'}} className="carousel-caption top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div className="text-start p-5" style={{maxWidth: 900}}>
                                <h3 className="text-white text-uppercase">Projets</h3>
                                <h1 className="display-1 text-white mb-md-4">Soyez assister par nos meilleures consseillers</h1>
                                <Suspense fallback={<Spinner/>}><ConnexionBox /></Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Précédent</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Suivant</span>
                </button>
            </div>
        </div>
        {/*Banner Start */}
        <div className="container-fluid banner mb-5">
            <div className="container">
                <div className="row gx-0">
                    <div className="col-md-12">
                        <div className="bg-white  d-flex flex-column justify-content-satrt p-5" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                            <h3 className="text-primary mb-3">Propulsé par</h3>
                            <div className=' d-flex justify-content-between align-items-center flex-wrap py-1'>
                               
                                <img style={{width:'15%'}} onClick={()=>handleGourl('https://port-bouet.ci')} className='flex-grow-1 ms-1 mb-1 cursor-pointer' src={require("../img/fondateur/marie-port-pouet.png")} alt="Logo marie port-bouet" />
                               
                                <img style={{width:'15%'}} onClick={()=>handleGourl('https://www.agenceemploijeunes.ci')} className='flex-grow-1  ms-1 mb-1 cursor-pointer' src={require("../img/fondateur/logo_aej_new.png")} alt="Agence emploi jeune" />
                                <img style={{width:'15%'}} onClick={()=>handleGourl('https://agefop.ci')} className='flex-grow-1  ms-1 mb-1 cursor-pointer' src={require("../img/fondateur/cropped-logoagefop-1.png")} alt="Logo Agefop" />
                                <img style={{width:'15%'}} onClick={()=>handleGourl('https://www.fdfp.ci')} className='flex-grow-1  ms-1 mb-1 cursor-pointer' src={require("../img/fondateur/logo-fdfp.png")} alt="Logo fdfp" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Fabout />
        {/*<!-- Facts Start -->*/}
        <Fstats />
        {/*<!-- Facts End -->*/}

   {/*<!-- Services Start -->*/}
    <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                        <h6 className="text-primary text-uppercase">Offres de services & dispostifs</h6>
                        <h1 className="display-5">Pôles et services de la PFS Port Bouët</h1>
                    </div>
                    <p className="mb-4">
                        - Le pôle Information-Accueil et Orientation (IAO) <br />
                        - Le Pôle Entrepreneuriat (PE) <br />
                        - Le Pôle Formation et Qualification vers l’Emploi (PQE)
                       </p>
                       {user?.name&&<Flink to={`/${portail}`} color="btn-primary">Acceder à mon compte</Flink>}
                    {(!user?.name)&&<Flink to="/inscription" className="btn btn-primary py-md-3 px-md-5">Nous réjoindre</Flink>}
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-info display-1 text-primary mb-3"></i>
                        <h4>Information – Accueil et Orientation (IAO)</h4>
                        <p className="mb-0">Le dispositif IAO est la porte d’entrée des jeunes pour la prise en charge de leur préoccupation d’insertion</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-user display-1 text-primary mb-3"></i>
                        <h4>Accompagnement vers l’emploi salarié par la formation</h4>
                        <p className="mb-0">Accompagner les jeunes de la Commune vers un emploi salarié ou la formation qualifiante par apprentissage afin de faciliter l’employabilité de ces deniers</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-clipboard-check display-1 text-primary mb-3"></i>
                        <h4>Accompagnement vers l’entrepreneuriat </h4>
                        <p className="mb-0">Formaliser les idées de projets des jeunes demandeurs et préparer à la gestion des Activités Génératrices de Revenus (AGR) par des formations aux métiers et de la gestion financière</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-tractor display-1 text-primary mb-3"></i>
                        <h4>CREE & TRI</h4>
                        <p className="mb-0"></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="service-item bg-light text-center p-5">
                        <i className="fa fa-seedling display-1 text-primary mb-3"></i>
                        <h4>GERME & GERME BIT</h4>
                        <p className="mb-0"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/*<!-- Services End -->*/}
    {/*<!-- Features Start -->*/}
    <div className="container-fluid bg-primary feature py-5 pb-lg-0 my-5">
        <div className="container py-5 pb-lg-0">
            <div className="mx-auto text-center mb-3 pb-2" style={{maxWidth: 500}}>
                <h6 className="text-uppercase text-secondary">Nos atouts</h6>
                <h1 className="display-5 text-white">Pourquoi venir à la PFS ?</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-3">
                    <div className="text-white mb-5">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3" style={{width:60, height:60}}>
                        {/* <i className="fa fa-seedling fs-4 text-white"></i>*/}
                        </div>
                        <h4 className="text-white">+150 Entreprises, ONG, Fondations partenaires qui nous font confiance</h4>
                        <p className="mb-0"> et prêts à vous accompagner dans votre projet</p>
                    </div>
                    <div className="text-white">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3" style={{width:60, height:60}}>
                           {/* <i className="fa fa-award fs-4 text-white"></i>*/}
                        </div>
                        <h4 className="text-white">Conseiller dédié</h4>
                        <p className="mb-0">Nos conseillers vous aident dans vos choix de carrières et vous fournissent un accompagnement</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="d-block bg-white h-100 text-center p-5 pb-lg-0">
                        <p>Différents Contrats, Accord-cadre ou Conventions de partenariat sont noués pour apporter un appui à la réalisation du plan d’actions tant au niveau de l’emploi qu’au niveau des formations des jeunes accueillis par la PFS Port Bouët. En somme le Bureau des élus, membres du Comité Local d’Insertion des Jeunes (CLIJ) et le Président de la PFS Port-Bouët ont élaboré les outils de base et ont mis en place les moyens nécessaires pour la réussite de ce projet innovant.</p>
                        <img className="img-fluid" src={require("../img/fondateur/logo_partenaire.jpg")} alt="Nos partenaires"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="text-white mb-5">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3" style={{width:60, height:60}}>
                           {/* <i className="fa fa-tractor fs-4 text-white"></i> */}
                        </div>
                        <h4 className="text-white">Une opportunité quelque soit votre niveau</h4>
                        <p className="mb-0"></p>
                    </div>
                    <div className="text-white">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3" style={{width:60, height:60}}>
                           {/* <i className="fa fa-phone-alt fs-4 text-white"></i>*/}
                        </div>
                        <h4 className="text-white">Plate-forme digitale de gestion de vos dossiers</h4>
                        <p className="mb-0">Nous créeons Le pont avec nos demandeurs à travers une plate-forme digitale de suivi des demandes disponible 24/7</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/*<!-- Features Start -->*/}

    {/*<!-- Products Start -->*/}
    {(lastOffre && lastOffre.length!==0)&&(
        <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5" style={{maxWidth:500}}>
                <h6 className="text-primary text-uppercase">Nos offres</h6>
                <h1 className="display-5">Decouvrez nos dernières offres</h1>
            </div>
            <OwlCarousel className="owl-carousel  px-5">
                
               {lastOffre.map(job=>(
                    <div className="pb-2 bg-white"> 
                    <div className="product-item position-relative bg-transparent d-flex flex-column text-center">
                        <Offrev key={job.id} offre={job} />
                        <div className="btn-action d-flex justify-content-center">
                            <Link className="btn bg-primary py-2 px-3" to={`/offres/postuler/offre?ref=${job.uuid}&ref=${job.id}`}><AddCircleOutlineIcon className="text-white"/><span className='ms-1 text-white'>Postuler</span></Link>
                            <Link className="btn bg-secondary py-2 px-3 ms-1" to={`/offres/offre/${job.id}`}><VisibilityIcon className="text-white" /><span className='ms-1 text-white'>Voir l'offre</span></Link>
                        </div>
                    </div>
                </div>
               ))}      
            </OwlCarousel>
            <div style={{display:'flex',padding:5,justifyContent:'center',alignItems:'center',borderTop:1,borderColor:"#f6f6f6"}}>
                <button onClick={()=>navigate(`/offres`)} className='bg-white text-green-600 hover:bg-green-500'>Voir plus</button>
            </div>
        </div>
    </div>
    )}
    
    {/*<!-- Products End -->*/}
    {/*<!-- Testimonial Start -->*/}
    <div className="container-fluid bg-testimonial py-5 my-5 d-none">
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <OwlCarousel className=" testimonial-carousel p-5">
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={require("../img/testimonial-2.jpg")} alt="" />
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25" />
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={require("../img/testimonial-2.jpg")} alt="" />
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25" />
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </div>
    {/*<!-- Testimonial End -->*/}
    {/*<!-- Blog Start -->*/}
    <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5" style={{maxWidth:500}}>
                <h6 className="text-primary text-uppercase">Actualités</h6>
                <h1 className="display-5">Dernières activités</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-1.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">PARTENARIAT AGEFOP-MAIRIE DE PORT-BOUET Dr EMMOU, TOUJOURS UNE LONGUEUR D’AVANCE</h4>
                            <span className="text-white fw-bold">Nov 12, 2020</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-2.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Présentation de 24 Jeunes recrutés pour une formation d'un an au Centre de Formation Professionnelle de Bimbresso dans le domaine de la Construction métallique, la mécanique auto, l'électricité bâtiment ...</h4>
                            <span className="text-white fw-bold">Juil 28, 2020</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-3.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">La Commune de Port-bouet est fortement présente au salon des Collectivités 2019, qui a vu son ouverture ce Jeudi 07 Novembre 2019 à Heden Golf Hôtel</h4>
                            <span className="text-white fw-bold">Nov 07, 2019</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
   {/* <!-- Blog End -->*/}
  {/* <!-- Team Start -->*/}
    <Fteams />
    {/*<!-- Team End -->*/}
  
    </>
  )
}

export default Home
