import React,{useReducer,useEffect,useContext} from 'react'
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Card } from 'components'
import { Form } from 'components'
import { villes } from 'fixtures/data';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {CardActionArea} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import AlertPage from 'containers/AlertPage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth } from 'hooks/auth';
import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()((theme) => {
    return {
        titlediv:{
            display:'flex',
            flex:1,
            justifyContent:'space-between',
            alignItems:'center'
        },
        content:{
            display:'flex',
            flex:1,
            [theme.breakpoints.down('sm')]: {
                flexDirection:'column'
            }
       
        },
        content_form:{
            display:'flex',
            flexDirection:'column',
            width:'70%',
            [theme.breakpoints.down('sm')]: {
                width:'100%'
            }
        },
        content_img:{
            display:'flex',
            flexDirection:'column',
            minHeight:'100%',
            flex:1
        },
        photo_label:{
            fontSize:15,
            fontStyle:'italic',
            fontWeight:'bold'
        },
        logo_box:{
            display:'flex',
            justifyContent:'center',
            alignItems:'ceùnter',
            border:'1px dashed #707070',
            minHeight:'85%',
            background: 'rgba(244,244,244,0.6)',
            position:'relative',
        },
        photo_check:{
            minWidth:'100%',
            minHeight:'100%',
            position:'absolute',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer'
        },
        input: {
            display: "none"
          },
        img_card:{
            minWidth:'100%',
            minHeight:'100%',
            position:'absolute',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer'
        }
    };
});



function AddPartenaire(props) {
  
   const {classes} =useStyles()
    const {user} = useAuth({middleware:'auth'});
    const [errors,setErrors] =React.useState([])
    const{open,handleClose,data,mode,refressdata} =props
  
    const [currentField,setCurrentField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      code:null,
      raisonsociale:null,
      formejuridique:null,
      activiteprincipal:null,
      actif:1,
      logo:null,
      capital:null,
      comptecontribual:null,
      numerorc:null,
      telephone:null,
      siteweb:null,
      adressemail:null,
      ville:null,
      personnesressources:[],
      evenements:[]
    });


    const [logostaut,setLogostaut] = React.useState({
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null
    })
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: files => {
            setLogostaut({
                mainState: "uploaded",
                selectedFile: files.map((file) => Object.assign(file, {
                    preview:  URL.createObjectURL(file)
                  })),
                imageUploaded: 1
            });
        }
      });


    const imageResetHandler = event => {
        setLogostaut({
          mainState: "initial",
          selectedFile: null,
          imageUploaded: 0
        });
        event.stopPropagation()
      };
      useEffect(() => {
        if(logostaut.hasOwnProperty('selectedFile') && logostaut.selectedFile){
           return ()=>logostaut.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
        }
      }, [logostaut]);

      const thumbs = (logostaut.hasOwnProperty('selectedFile') && logostaut.selectedFile)&&logostaut.selectedFile.map(file => (
        <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
            <img
                width="100%"
                className={classes.media}
                src={file.preview}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
        </CardActionArea>  
      ));
      //submit

    const handleSubmit =(event)=>{
        event.preventDefault();
       /* if(currentField['raisonsociale']){
            const dataSend = {...currentField,ajouterpar:user.uid}
            if(logostaut && logostaut.selectedFile && logostaut.selectedFile.lenght!==0){
                logostaut.selectedFile.map(async (file)=>{
                    const copieFichier = ref(storage, `documents/partenaires/${currentField.raisonsociale.replace(/ /g,'')}.${getExtension(file.path) || ""}`);
                    if(copieFichier){
                        const snapshot = await uploadBytes(copieFichier, file)
                        if(snapshot)  dataSend['logo'] =snapshot['ref'].fullPath;
                            //sauvegarde des documents
                        addpartenaire(firebase,dataSend).then(res=>{
                            if(res && res['error']===false){
                                setCurrentField({
                                    ['code']:null,
                                    ['raisonsociale']:null,
                                    ['formejuridique']:null,
                                    ['activiteprincipal']:null,
                                    ['actif']:1,
                                    ['logo']:null,
                                    ['capital']:null,
                                    ['comptecontribual']:null,
                                    ['numerorc']:null,
                                    ['telephone']:null,
                                    ['siteweb']:null,
                                    ['adressemail']:null,
                                    ['ville']:null,
                                    ['personnesressources']:[],
                                    ['evenements']:[]
                                })
                                handleOpenAletInfo(`Le partenaire ${dataSend.raisonsociale} à été créer avec succès`,true,handleClose)
                              
                            }else if (res && res['error']===true){
                                handleOpenAletInfo(`${res.message}`,false,null)
                                console.log("Erreur: "+ res.message);
                            }else{
                                handleOpenAletInfo(`Erreur inconnu lors de l'enregistrement`,false,null)
                                console.log("Erreur inconnu");
                            }
                        }).catch(err=>{
                            console.log(err);
                            handleOpenAletInfo(`Erreur inconnu lors de l'enregistrement`,false,null)
                        })
                    }
                })
            }else{
                //sauvegarde des documents
                addpartenaire(firebase,dataSend).then(res=>{
                    if(res && res['error']===false){
                        setCurrentField({
                            ['code']:null,
                            ['raisonsociale']:null,
                            ['formejuridique']:null,
                            ['activiteprincipal']:null,
                            ['actif']:1,
                            ['logo']:null,
                            ['capital']:null,
                            ['comptecontribual']:null,
                            ['numerorc']:null,
                            ['telephone']:null,
                            ['siteweb']:null,
                            ['adressemail']:null,
                            ['ville']:null,
                            ['personnesressources']:[],
                            ['evenements']:[]
                        })
                        handleOpenAletInfo(`Le partenaire ${dataSend.raisonsociale} à été créer avec succès`,true,handleCloseAletInfo(handleClose))
                        
                    }else if (res && res['error']===true){
                        handleOpenAletInfo(`${res.message}`,false,null)
                        console.log("Erreur: "+ res.message);
                    }else{
                        handleOpenAletInfo(`Erreur inconnu lors de l'enregistrement`,false,null)
                        console.log("Erreur inconnu");
                    }
                }).catch(err=>{
                    console.log(err);
                    handleOpenAletInfo(`Erreur inconnu lors de l'enregistrement`,false,null)
                })
            }
     
        }*/
        
    }
    //set field
    const handleChange = (event) => {
        const {name,value} =event.target
        setCurrentField({
            [name]:value
        })
      };
    
      const [aletInfo,setAlertInfo] = React.useState({})
      const handleOpenAletInfo =(message,succes,action)=>{
        setAlertInfo({
          open:true,
          message,
          succes,
          action
        })
      }
      const handleCloseAletInfo =(action)=>{
        setAlertInfo({
          open:false,
          message:"",
          succes:false
        })
        action&&action()
      }

  return (
    
    <div>
          <AlertPage open={aletInfo['open']||false} message={aletInfo['message']||""} succes={aletInfo['succes']||false} handleClose={handleCloseAletInfo} />
         <Dialog
            onClose={handleClose} 
            open={open}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>
                <div className={classes.titlediv}>
                    <Typography variant='h6'> Ajouter un partenaires</Typography>
                    <IconButton aria-label="part-close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
               
                <Card.Hr />
            </DialogTitle>
            <DialogContent>
                <div className={classes.content}>
                    <div className={classes.content_form}>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='raisonsociale' value={currentField.raisonsociale} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Raison sociale" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                <Form.Input required size="small" name="formejuridique" margin="dense" onChange={handleChange} value={currentField['formejuridique']||""} select style={{marginRight:20}}  label="Forme juridique/Type">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='INDIVIDUEL'>INDIVIDUEL</MenuItem>
                        <MenuItem value='SARL'>SARL</MenuItem>
                        <MenuItem value='SA'>SA</MenuItem>
                        <MenuItem value='CONSULTANT'>CONSULTANT</MenuItem>
                        <MenuItem value='SOCIETE'>SOCIETE D'ETAT</MenuItem>
                        <MenuItem value='MINISTERE'>MINISTERE</MenuItem>
                        <MenuItem value='INSTITUTION'>INSTITUTION</MenuItem>
                        <MenuItem value='ONG'>ONG</MenuItem>
                        <MenuItem value='ASSOCIATION'>ASSOCIATION</MenuItem>
                    </Form.Input>
                    <Form.Input   name='capital' value={currentField.capital} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Capital" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='activiteprincipal' value={currentField.activiteprincipal} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Activité principale" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='comptecontribual' value={currentField.comptecontribual} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° Compte contribuable" wd='50%' />
                  <Form.Input   name='numerorc' value={currentField.numerorc} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° contribuable" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
              <Form.Input 
                    size="small" 
                    margin="dense" 
                    select style={{marginRight:20}}  
                    label="Ville"
                    value={currentField['ville'] ||""}
                    name='ville'
                    onChange={handleChange}
                    >
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem key={ville} value={ville}>{ville}</MenuItem>))}
                  </Form.Input>
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='telephone' value={currentField.telephone} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="N° téléphone" wd='50%' />
                  <Form.Input   name='mobile' value={currentField.mobile} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Mobile" wd='50%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input  type="email" name='adressemail' value={currentField.adressemail} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Email" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='siteweb' value={currentField.siteweb} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Site web" wd='100%' />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                <ReactQuill  name='description' theme="snow" value={currentField.description} onChange={(v)=>setCurrentField({['description']:v})} />
            </Form.Group>
                    </div>
                    <div className={classes.content_img}>
                        <Typography className={classes.photo_label}>Cliquez ou glissez déposer pour joindre le logo du parténaire</Typography>
                        <div className={classes.logo_box} {...getRootProps()}>
                        
                            {thumbs}
                       
                            <input
                                {...getInputProps()}
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                name='photo'
                                disabled={!logostaut.imageUploaded}
                                />
                                 {(logostaut.imageUploaded===0)&&
                            <label className={classes.photo_check} htmlFor="contained-button-file">
                                <AddAPhotoIcon sx={{fontSize:60,color:'#888888'}} />
                            </label>}
                        </div>
                    </div>
                </div>
              
            </DialogContent>
            <DialogActions>
                <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Button onClick={handleClose} >Annuler</Form.Button>
                    <Form.Button onClick={handleSubmit} primary>Valider</Form.Button> 
                    </Form.Group>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default AddPartenaire