import React,{useEffect, useState,useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { IconButton, MenuItem, Paper, Typography } from '@mui/material'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import axios from 'lib/axios'
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs'
import {Divider} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Form as CustomForm } from 'components'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Pagination} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { red } from '@mui/material/colors'
import swal from 'sweetalert'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { CommonsContext } from 'context/CommonsContext'
import Dossier from 'containers/Dossier'

const useStyles =  makeStyles()((theme)=>({
   container:{
    display:'flex',
    justifyContent:'space-between'

   } ,
   searchBox:{
        display: 'flex',
        padding:20,
        marginBottom:20,
        flexDirection: 'column',
    }, 
    btnAdd:{
        paddingLeft:20,
        paddingRight:20,
        backgroundColor: '#00B04B',
        color:'white',
        marginRight:20
    },     
    detaildossier:{
        display:'flex',
        flex:1,
        justifyContent:'space-between'
      },
      labelle:{
        fontStyle:'italic',
        fontWeight:'bold'
      },
      navLabel:{
        textTransform:'uppercase', 
        marginLeft:10,
        fontSize:14
      },
      titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
      },
      iconsbtn:{
        color:'#00B04B',
        '&:hover':{
          color:'#F29400'
        }
      },
      iconsbtnDis:{
        color:red[400],
        '&:hover':{
          color:'#F29400'
        }
      },
      cleft:{
        flex:5,
        marginRight:20
      },
      cright:{
        flex:3,
        marginRight:20
      },
      headerContainer:{
        background:'#707070',
        color:'white',
        fontSize:14,
        display:'flex',
        flex:1,
        flexDirection:'column',
        padding:10,
        alignItems:'center',
        marginBottom:20
    },
}))
const PropositionOffre = () => {
    const {classes} = useStyles()
    const location = useLocation()
    const data = location.state.offre
    const navigate = useNavigate()
    const [dossiers,setDossiers] = useState(null)
    const [inputField,setInputField] = useState({
        offre_id:data&&data.id,
        dossiers:[],
        statut:'Proposer',
        etat:'En attente',
        venu:'Proposition'
      })
      const handleChange = (e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
      }
    const {handleCommonsdata,commonsField} = useContext(CommonsContext)
    useEffect(()=>{
        handleCommonsdata({...commonsField,'titre':"Gestion des offres - Proposition"})
    },[])
    const [paginateData,setPagnateData] = useState(null)
    const [inputSearch,setInputSearch] = useState(null)
    const handleSearch = (numberPage = 1) =>{
        const url  = `/api/dossier/recherche?page=${numberPage}`
        axios.post(url,inputSearch).then(res=>{
          if(res.status === 200 || res.status === 201){
            setDossiers(res.data.data)
            setPagnateData({
              current_page:res.data.meta?.current_page,
              last_page:res.data.meta?.last_page,
              per_page:res.data.meta?.per_page,
              total:res.data.meta?.total
            })
          }else{
            swal("Ooops !","Aucune offre trouvée",'warning')
          }
        }).catch(err=>{
          console.log(err.response);
          swal("Erreur","Erreur lors de l'exécution de la requête",'error')
        })
    }
    const handleDissociate =(dossier)=>{
        if(dossier){
          const currentDossier = [...inputField.dossiers]
          if(currentDossier.findIndex(item=>item.id === dossier.id) !== -1){
            setInputField({...inputField,['dossiers']:[...currentDossier.filter(item=>item.id !== dossier.id)]})
          }
        }
      }
      const handleAssociate =(dossier)=>{
        if(dossier){
          const currentDossier = [...inputField.dossiers]
          if(currentDossier.findIndex(item=>item.id === dossier.id) === -1){
            setInputField({...inputField,['dossiers']:[...currentDossier,dossier]})
          }
        }
      }

      const handleList =()=>{
        navigate(`/${portail}/offre/fiche/${data.id}`)
      }

      const handleSubmit = (e)=>{
        e.preventDefault()
        if(inputField.offre_id && inputField.dossiers.length !==0){
            axios.post(`/api/offre/proposer/${inputField.offre_id}`,inputField).then(res=>{
              if(res.status===200 || res.status === 201){
                swal("Bien","Porposition effectué avec succès","success")
                handleList()
              }else{
                swal(`Ooops !`,"Erreur lors de l'enregistrement",'error')
              }
            }).catch(err=>{
              console.log(err)
              swal("Ooops","Erreur lors de l'enregistrement",'error')
            })
          }
      }
      
  return (
    <div className={classes.container}>
        <div className={classes.cleft}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.headerContainer}>
                        <Typography variant='h3'>{`${data['titre'] || ""}`}</Typography> 
                        <Typography>{data['secteuractivites']? data['secteuractivites'].join(","):" - "}</Typography>
                        <Card.Hr />
                        <div className={classes.detaildossier}>
                            <div className={classes.left}>
                                <div><span className={classes.labelle}>Publié le : </span>{data.hasOwnProperty('datedebut')?dayjs(data['datedebut']).format("DD/MM/YYYY"):""}</div>
                                <div><span className={classes.labelle}>Date cloture : </span> {data.hasOwnProperty('datefin')?dayjs(data['datefin']).format("DD/MM/YYYY"):""}</div>
                                <div><sapn className={classes.labelle}>Nombre de post(s) :</sapn><span>{data['nombrepost']? data['nombrepost']:" - "} </span></div>
                            </div>  
                            <div className={classes.midlle}>
                                <div><span className={classes.labelle}>Partenaire : </span>{(data.partenaires && data.partenaires?.length!==0)&&data.partenaires[0].raisonsociale +"..."}</div>
                                <div><span className={classes.labelle}>Ville : </span><span> {data['ville']? data['ville']:" - "}</span> </div>
                                <div><sapn className={classes.labelle}> Metier:</sapn><span>{data['metiers']? data['metiers'].join(","):" - "}</span></div>
                            </div>  
                            <div className={classes.right}>
                                <div><span className={classes.labelle}>Type de contrat : </span>{data['typecontrat']? data['typecontrat']:" - "}</div>
                                <div><span className={classes.labelle}>Niveau d'expérience : </span>{data['niveauexperience']? data['niveauexperience'].join(","):" - "}  </div>
                                <div><span className={classes.labelle}>Niveau d'expérience : </span> {data['niveauetudes']? data['niveauetudes'].join(","):" - "}</div>
                            </div>  
                    </div>
                    </div>
                    <Card>
                        <Typography variant='h5'>Détails de la proposition</Typography>
                        <Card.Hr />
                
                        <CustomForm.Group direction ='row' flex={1}>
                            <DesktopDatePicker
                                label="Date de debut"
                                inputFormat="dd/MM/yyyy"
                                value={inputField['datedebut'] || ""}
                                name='datedebut'
                                onChange={(val)=>setInputField({...inputField,['datedebut']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                            <DesktopDatePicker
                                label="Date fin"
                                name='datefin'
                                inputFormat="dd/MM/yyyy"
                                value={inputField['datefin'] || ""}
                                onChange={(val)=>setInputField({...inputField,['datefin']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                        </CustomForm.Group>
                        <FormControl>
                            <FormLabel id="statut-group-label">Staut</FormLabel>
                            <RadioGroup row aria-labelledby="dstatut-group-label" name="statut" onChange={handleChange}  >
                                <FormControlLabel value="Proposer" control={<Radio checked={inputField['statut'] == 'Proposer'} color="success" />} label="Proposer" />
                                <FormControlLabel value="Accepter" control={<Radio checked={inputField['statut'] == 'Accepter'} color="success" />} label="Accepter" />
                                <FormControlLabel value="Refuser" control={<Radio checked={inputField['statut'] == 'Refuser'} color="success" />} label="Refuser" />
                                <FormControlLabel value="Initialiser" control={<Radio checked={inputField['statut'] == 'Initialiser'} color="success" />} label="Initialiser"/>
                                <FormControlLabel value="Suspendu" control={<Radio checked={inputField['statut'] == 'Suspendu'}  color="success"/>} label="Suspendu"/>
                                <FormControlLabel value="Cloturer" control={<Radio checked={inputField['statut'] == 'Cloturer'} color="success" />} label="Cloturer"/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="etat-group-label">Etat</FormLabel>
                            <RadioGroup row aria-labelledby="etat-group-label"name="etat" onChange={handleChange}>
                                <FormControlLabel value="En attente" control={<Radio checked={inputField['etat'] == 'En attente'} color="success" />} label="En attente" />
                                <FormControlLabel value="En cours" control={<Radio checked={inputField['etat'] == 'En cours'} color="success" />} label="En cours" />
                                <FormControlLabel value="Terminé" control={<Radio checked={inputField['etat'] == 'Terminé'} color="success" />} label="Terminé" />
                            </RadioGroup>
                        </FormControl>
                        <FormGroup>
                          <FormControlLabel name='notificationok' control={<Checkbox name='notificationok' onChange={handleChange} color="success" />} label="Envoyé une notification aux concernés" />
                          
                        </FormGroup>
                </Card>
                <Card>
                <Typography variant='h5'>Dossiers associés</Typography>
                        <Card.Hr />
                 
                        <Table>
                            <tbody>
                                {(inputField.dossiers && inputField.dossiers?.length!==0)?(inputField.dossiers.map(dossier=><tr>
                                  <td>{`${dossier['code']}`}</td>
                                  <td>{`${dossier.jeune?.nom} ${dossier.jeune?.prenom}`}</td>
                                  <td valign='middle'><IconButton onClick={()=>handleDissociate(dossier)}>
                                  <RemoveCircleOutlineIcon className={classes.iconsbtnDis} />
                              </IconButton></td>
                                  </tr>)):<tr>
                                    <td><p>Aucune offre associée, Vous pouver utiliser la recherche pour trouver une offre à associer à cette demande</p></td></tr>}
                            </tbody>
                        </Table>
                        </Card>
                        <Card direction='row' palign='space-between'>
                            <CustomForm.Button type='reset' >Annuler</CustomForm.Button> 
                            {inputField.dossiers.length!==0&&<CustomForm.Button type="submit" primary>Enregistrer</CustomForm.Button> }
                            
                        </Card>
            </form>
            </LocalizationProvider>
        </div>
       <div className={classes.cright}>
            <Typography variant='h5'>RECHERCHE UN DOSSIER</Typography>
            <Paper className={classes.searchBox} variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(civilité,nom,prenom,statut ...)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setInputSearch({...inputSearch,['search']:event.target.value})}
                    />
                    <div>
                    <Button variant="outline-success" id="button-addon2" className={classes.btnAdd} onClick={()=>handleSearch(1)}>
                        <SearchIcon />
                    </Button>
                    </div>
                </InputGroup>
                {(dossiers && dossiers.length!==0)&&<div>
                    <Table>
                        <tbody>
                            {dossiers.map(row=><tr>
                                <td><Dossier data={row} /></td>
                                <td valign='middle'>
                              {((inputField.dossiers.findIndex(item=>item.id === row.id)!== -1) ? (
                                <IconButton onClick={()=>handleDissociate(row)}>
                                  <RemoveCircleOutlineIcon className={classes.iconsbtnDis} />
                              </IconButton>) :  <IconButton onClick={()=>handleAssociate(row)}>
                                <AddCircleOutlineIcon className={classes.iconsbtn} />
                            </IconButton>)}
                           
                            </td>
                            </tr>)}
                        </tbody>
                    </Table>
                    {(dossiers && dossiers.length!==0 && paginateData)&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleSearch(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                    </div>
                    }
                </div>}
            </Paper>
       </div>
    </div>
  )
}

export default PropositionOffre
