export const formatNumber=(num)=> {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  export const  randomColor=()=>{
    let maxVal = 0xFFFFFF; // 16777215
    let randomNumber = Math.random() * maxVal; 
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);   
    return `#${randColor.toUpperCase()}`
}


export function getExtension(path) {
  var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
                                              // (supports `\\` and `/` separators)
      pos = basename.lastIndexOf(".");       // get last position of `.`
  
  if (basename === "" || pos < 1)            // if file name is empty or ...
      return "";                             //  `.` not found (-1) or comes first (0)
  
  return basename.slice(pos + 1);            // extract extension ignoring `.`
  }

  export function getAge(date) {
    const currentDate = new Date()
    const birthdate = new Date(date)
    return currentDate.getFullYear() - birthdate.getFullYear()
    
  }