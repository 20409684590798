import { Button, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Form, InputGroup } from 'react-bootstrap';
import "./styles/CategorieList.scss"
import axios from 'lib/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {Pagination} from "@mui/material";
import swal from 'sweetalert';

function CategorieList() {
  const portail =  (useSelector(state=>state.ui.portail)).toLowerCase()
  const navigate = useNavigate()
  const [search,setSearch] = useState(null)
  const [categories,setCategorie] = useState(null)
  const [paginateData,setPagnateData] = useState(null)
  
  const handleCategorie = (numberPage=1)=>{
    const url = search?`/api/categorie-offre/list/${search}?page=${numberPage}`:`/api/categorie-offre/list?page=${numberPage}`
    axios.get(url).then(res=>{
      if(res.status ===200 || res.status === 201){
        setCategorie(res.data.data)
        setPagnateData({
            current_page:res.data.current_page,
            last_page:res.data.last_page,
            per_page:res.data.per_page,
            total:res.data.total
          })
      }   
    })
  }
  useEffect(()=>{
    return handleCategorie()
  },[])
  const handleDelete = (id)=>{
    axios.delete(`/api/categorie-offre/${id}`).then(res=>{
      if(res.status ===200 || res.status ===201){
        swal("Bien !","Suppression réalisé avec succès",'success')
       
      }else{
        swal("Ooops !","Erreur lors de l'opération",'error')
      }
      handleCategorie()
    }).catch(err=>{
      swal("Ooops !","Erreur lors de l'opération",'error')
      console.log(err);
    })
  }
  const handlenewprofil = ()=>{
    navigate(`/${portail}/categorie-offre/nouveau`,{state:{mode:'ADD',categorie:null}})
  }

  return (
    <div className='categorielist'>
        <div className='titlebox'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Catégorie d'offres</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={()=>handlenewprofil()}>
                <AddIcon/>Ajouter une catégorie
            </Button>
        </div>
        <div className="bodybox">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(libelle,statut.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={()=>handleCategorie(1)}>
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Intitulé</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(categories && categories.length!==0)?categories.map(categorie=><tr>
                            <td>{categorie['id'] || ""}</td>
                            <td>{categorie['libelle'] || ""}</td>
                           
                            <td>
                                <div className="tbaction">
                                    <Tooltip title="Editer">
                                        <IconButton onClick={()=>navigate(`/${portail}/categorie-offre/modifier/${categorie.id}`,{state:{mode:'UP',categorie:categorie}})}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Supprimer">
                                        <IconButton onClick={()=>handleDelete(categorie.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                   
                                </div>
                            </td>
                        </tr>):<tr><td colSpan={3}><p>Aucune catégorie trouvée, Veuillez utiliser le bouton "Ajouter une catégorie" pour créer une nouvelle catégorie d'offre</p></td></tr>}
                       
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleCategorie(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
            </Paper>
        </div>
    </div>
  )
}

export default CategorieList
