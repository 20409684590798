import React, { useCallback, useEffect, useState } from 'react'
import ConnexionBox from '../components/ConnexionBox'
import { Link, useParams } from 'react-router-dom'
import axios from 'lib/axios'
import { useAuth } from 'hooks/auth'
import Offrev from 'containers/Offrev/Offrev'
import {Pagination, Typography} from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { red } from '@mui/material/colors'
function OffreList() {
  const params = useParams()
  const [paginateData,setPagnateData] = useState(null)
  const {user}  =useAuth({ middleware: 'auth' })
  const [search,setSearch] = useState(null)
  const [lastOffre,setLastOffres] = useState(null)
  const [offres,setOffres] = useState(null)
  const [categorie,setCategorie] = useState(null)

  const handleLastOffres = useCallback(()=>{
    axios.get(`/api/offre/last`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setLastOffres(res.data)
        setPagnateData({
          current_page:res.data.meta?.current_page,
          last_page:res.data.meta?.last_page,
          per_page:res.data.meta?.per_page,
          total:res.data.meta?.total
        })
      }
    })
  },[])
  const handleListeOffres = useCallback((numberpage = 1)=>{
    const url = user?.name ? (search ? `/api/offre/list/${search}?page=${numberpage}`:`/api/offre/list?page=${numberpage}`) : (search ? `/api/offre/public/${search}?page=${numberpage}`:`/api/offre/public?page=${numberpage}`)
    axios.get(url).then(res=>{
      if(res.status === 200 || res.status === 201){
        setOffres(res.data.data)
      }
    })
  },[])
  const [typeOffres,setTypeOffres] = useState(null)
  const handleTypeOffre = useCallback(()=>{
      axios.get(`/api/categorie-offre/list?page=1`).then(res=>{
          if(res.status === 200 || res.status === 201){
              setTypeOffres(res.data.data)
              setCategorie(res.data.data.filter(item=>item.id === params['type']))
          }
      })
  },[])

  useEffect(()=>{
    handleListeOffres()
    handleLastOffres()
    handleTypeOffre()
  })

  return (
    <div>
         <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">Nos offres</h1>
                       <ConnexionBox />
                    </div>
                </div>
            </div>
        </div>
        <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-12"> 
            
            <Typography variant='h6'>{/*typeOffres.filter(item=>item.id === params['type'])[0]?.libelle*/}</Typography>
          </div>
        </div>
        
        <div className="row g-5">
           {/*<!-- Blog list Start -->*/}
            <div className="col-lg-8">
                <div className="row g-5">
                   {(offres && offres.length !== 0) ?(offres.map(offre=>  <Link to={`/offres/offre/${offre.id}`} className="col-md-6"><Offrev key={offre.id} offre={offre} /></Link>)
                    
                   ):(
                    <div className='col-12 d-flex flex-column align-items-center justify-content-center'>
                      <SentimentVeryDissatisfiedIcon sx={{fontSize:60,color:red[600]}} />
                      <h4 style={{color:red[600]}}>Ooops ! Aucune offre trouvée</h4>
                    </div>
                   )}
                    
                  
                    <div className="col-12 center">
                    {paginateData&&<Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleListeOffres(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        shape="rounded"
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />}
                    </div>
                </div>
            </div>
            {/*<!-- Blog list End -->

  <!-- Sidebar Start -->*/}
            <div className="col-lg-4">
                {/*<!-- Search Form Start -->*/}
                <div className="mb-5">
                    <div className="input-group">
                        <input type="text" className="form-control p-3" onChange={(e)=>setSearch(e.target.value)} placeholder="recherche" />
                        <button className="btn btn-primary px-4" onClick={handleListeOffres}><i className="bi bi-search"></i></button>
                    </div>
                </div>
               {/* <!-- Search Form End -->

  <!-- Category Start -->*/}
                <div className="mb-5">
                    <h2 className="mb-4">Categories</h2>
                    <div className="d-flex flex-column justify-content-start bg-primary p-4">
                    {(typeOffres && typeOffres.length!==0)&&typeOffres.map(typeoffre=>
                     <Link key={typeoffre.id} to={`/offres/${typeoffre.id}`}  className="fs-5 fw-bold text-white mb-2"><i className="bi bi-arrow-right me-2"></i>{typeoffre.libelle}</Link>)}
                
                    </div>
                </div>
               {/* <!-- Category End -->

  <!-- Recent Post Start -->*/}
                <div className="mb-5">
                    <h2 className="mb-4">Dernières offres</h2>
                    <div className="bg-primary p-4">
                      {(lastOffre && lastOffre.length!==0)&&lastOffre.map(last=><div key={last.id} className="d-flex overflow-hidden mb-3">
                            <img className="img-fluid flex-shrink-0" src={require("assets/worker.png")} style={{width: "75px"}} alt="" />
                            <Link to={`/offres/offre/${last.id}`} className="d-flex align-items-center bg-white text-dark fs-5 fw-bold px-3 mb-0">{last.titre}
                            </Link>
                        </div>)}
                        
                        
                    </div>
                </div>
               {/* <!-- Recent Post End -->

  <!-- Image Start -->*/}
                <div className="mb-5">
                    <img src="img/blog-1.jpg" alt="" className="img-fluid rounded" />
                </div>
               
            </div>
            {/*<!-- Sidebar End -->*/}
        </div>
    </div>
   {/* <!-- Blog End -->*/}

    </div>
  )
}

export default OffreList
