import DossierHome from 'pages/backend/dossiers/DossierHome';
import DossierAdd from 'pages/backend/dossiers/DossierAdd';
import DosseierList from 'pages/backend/dossiers/DosseierList';
import DossierFiche from 'pages/backend/dossiers/DossierFiche';
import DossierAccueil from 'pages/backend/dossiers/DossierAccueil';
//gestion des partenaires
import PartenaireHome from 'pages/backend/administrateur/partenaires/PartenaireHome';
import PartenaireListe from 'pages/backend/administrateur/partenaires/PartenaireListe';
import PartenaireFiche from 'pages/backend/administrateur/partenaires/PartenaireFiche';
import AddPartenaireComplet from 'pages/backend/administrateur/partenaires/AddPartenaireComplet';
import UpdatePartenaireComplet from 'pages/backend/administrateur/partenaires/UpdatePartenaireComplet';
import { UtilisateurAdd,UtilisateurList,UtilisateurEdit } from 'pages/backend/administrateur/utilisateurs';
//gestion des offres
import {OffreHome,OffreAccueil,AddOffres,OffresLsit,OffreFiche,UpdateOffres} from 'pages/backend/conseillers/offres';
import Dashbord from 'pages/backend/dashbord/Dashbord';
import DossierAddSimplifier from 'pages/backend/dossiers/DossierAddSimplifier';
import FichePropositionOffres from 'pages/backend/dossiers/FichePropositionOffres';
import FichePropositionDispositifs from 'pages/backend/dossiers/FichePropositionDispositifs';
import { DispositifList,DetailsDispositif } from 'pages/backend/administrateur/dispositifs';
import AddEvenement from 'pages/backend/conseillers/evenements/AddEvenement';
import Etat from 'pages/backend/commons/etats';
import DossierRechercheDetaille from 'pages/backend/dossiers/DossierRechercheDetaille';
import PropositionOffre from 'pages/backend/conseillers/offres/PropositionOffre';
import SearchResult from 'pages/backend/recherche/SearchResult';
import SearchFields from 'pages/backend/recherche/SearchFields';
import FicheAffectationDossier from 'pages/backend/dossiers/FicheAffectationDossier';
import FicheGenerateUserAccount from 'pages/backend/dossiers/FicheGenerateUserAccount';
import FicheSortieDossier from 'pages/backend/dossiers/FicheSortieDossier';
import ListeDossierAffecter from 'pages/backend/dossiers/ListeDossierAffecter';
const routes =[
    { path: '/', index:true, component: Dashbord},
    { path: 'dossiers', name: 'Liste des dossiers', component: DossierHome, childs:[
        { path: '/', index:true, component: DossierAccueil},
        { path: 'nouveau',  component: DossierAdd},
        { path: 'nouveau/express',  component: DossierAddSimplifier},
        { path: 'fiche/:id',  component: DossierFiche},
        { path: 'liste',  component: DosseierList},
        { path: 'proposition/offres/:code',  component: FichePropositionOffres},
        { path: 'proposition/dispositifs/:code',  component: FichePropositionDispositifs},
        { path: 'evenements/nouveau',  component: AddEvenement},
        { path: 'envoyer',  component: AddEvenement},
        { path: 'recherche',  component: DossierRechercheDetaille},
        { path: 'sortir',  component: FicheSortieDossier},
        { path: 'compte/generer',  component: FicheGenerateUserAccount},
        { path: 'affectation/affecter',  component: FicheAffectationDossier},
        { path: 'liste/affecter',  component: ListeDossierAffecter},
    ] },
    { path: 'offres', name: 'Liste des utilisateurs', component: OffreHome, childs:[
        { path: '/', index:true, component: OffreAccueil},
        { path: 'nouveau',  component: AddOffres},
        { path: 'fiche/:id',  component: OffreFiche},
        { path: 'modifier/:id',  component: UpdateOffres},
        { path: 'liste',  component: OffresLsit},
        { path: 'proposition/:offre',  component: PropositionOffre},
    ] },
    { path: 'utilisateurs', name: 'Liste des utilisateurs', component: UtilisateurList },
    { path: 'utilisateur/nouveau', name: 'Ajouter un utilisateur', component: UtilisateurAdd },
    { path: 'utilisateur/modifier/:code', name: 'Modifier un utilisateur', component: UtilisateurEdit },
    { path: 'utilisateur/:code', name: 'Modifier un utilisateur', component: UtilisateurEdit },
    { path: 'partenaires',name: 'gestion des partenaires', component: PartenaireHome, childs:[
        { path: '/', index:true, component: PartenaireListe},
        { path: 'nouveau',  component: AddPartenaireComplet},
        { path: 'fiche/:id',  component: PartenaireFiche},
        { path: 'modifier/:id',  component: UpdatePartenaireComplet},
    ] },
    {path: 'services', name: 'Liste des dispositifs/services', component: DispositifList},
    { path: 'dispositifs/fiche/:code', name: 'Fiche un dispositif', component: DetailsDispositif },
    { path: 'etats', name: 'etat', component: Etat },
    { path: 'recherches/resultats', name: 'Résultat recherche', component: SearchResult },
    { path: 'recherches/criteres', name: 'critère de recherche', component: SearchFields },

]

export default routes