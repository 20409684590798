import DashJeune from "pages/backend/dashbord/DashJeune"
import DossierJeunes from "pages/backend/jeunes/mondossier/DossierJeunes"
import DemandesJeunes from "pages/backend/jeunes/demandes/DemandesJeunes"
import EmploisJeunes from "pages/backend/jeunes/emplois/EmploisJeunes"
import DispositifsJeunes from "pages/backend/jeunes/dispositifs/DispositifsJeunes"
import SearchResult from "pages/backend/recherche/SearchResult"
import SearchFields from "pages/backend/recherche/SearchFields"
const routes =[
    { path: '/', index:true, component: DashJeune},
    { path: '/mon-dossier', index:true, component: DossierJeunes},
    { path: '/mes-candidaturess', index:true, component: EmploisJeunes},
    { path: '/mes-demandes', index:true, component: DemandesJeunes},
    { path: '/mes-dispositifs', index:true, component: DispositifsJeunes},
    { path: '/offres', index:true, component: DispositifsJeunes},
    { path: 'recherches/resultats', name: 'Résultat recherche', component: SearchResult },
    { path: 'recherches/criteres', name: 'critère de recherche', component: SearchFields },

]

export default routes