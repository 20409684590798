import React,{useState} from 'react'
import {AuthSessionStatus, AuthValidationErrors, Homer} from 'components'
import logo from "assets/logo.jpg"
import PersonIcon from '@mui/icons-material/Person';
import {Form} from 'components'
import { useAuth } from 'hooks/auth'
import { Link,useSearchParams } from 'react-router-dom';

function Welcome() {
  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/administrateur'
  }) 
  const { resetPassword } = useAuth({ middleware: 'guest' })
    const [loginInput,setLoginInput] = React.useState({
      input_type:"",
      password:"",
      portail:"",
      error_list:[]
    })
    const handleChangeLoginInput =(e)=>{
      setLoginInput({...loginInput,[e.target.name]:e.target.value})
    }
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)
    
    const [isInvalid,setIsValide] = useState((loginInput['input_type'] === '' || loginInput['password'] === '')) 

    //login
    const handleLogin =(event)=>{
      event.preventDefault();
      if(!loginInput.input_type || loginInput.input_type.trim()==''){
        setErrors([...errors,'Veuillez saisir un login'])
        setIsValide(true)
        return
      }
      if(!loginInput.password || loginInput.password.trim()==''){
        setErrors([...errors,'Veuillez saisir un mot de passe'])
        setIsValide(true)
        return
      }
      login({input_type:loginInput.input_type, password:loginInput.password, setErrors, setStatus })
    }
  
  return (
    <Homer>
       <Homer.Frame flex={0.3} style={{marginBottom:10}} >
            <Homer.Inner>
              <Homer.Link to="/">
                  <div style={{position:'absolute',borderRadius:5,width:80}} >
                    <img src={logo} alt='Logo' style={{width:250,borderRadius:10,objectFit: "fill",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} />
                  </div>
              </Homer.Link>
               
            </Homer.Inner>
        </Homer.Frame>
        <Homer.FGradient flex={2}>
                <Homer.Inner salign='center'>
                    <Homer.Group  palign='center' salign='center' flex={1.5}>
                      <Homer.Title>Bienvenue sur la plate forme de services</Homer.Title>
                    </Homer.Group >
                    <Homer.Group direction='column' salign='center' palign='space-between' style={{background:"rgba(255,255,255,0.8)", padding:20,borderRadius:5}}>
                        <Homer.Group style={{display:'flex',position:'relative', justifyContent:'center',alignItems:'center'}}>
                              <Homer.Group style={{backgroundColor:'white',borderRadius:10,position:"absolute",top:-40 }}> 
                                <PersonIcon sx={{ fontSize: 60}} color="disabled" />
                              </Homer.Group>
                              <Homer.Group style={{marginTop:10}}><Homer.Title>Connexion</Homer.Title></Homer.Group>
                        </Homer.Group>
                         <Homer.Group>
                                   {/* Session Status */}
                            <AuthSessionStatus className="mb-4" status={status} />
                            {/* Validation Errors */}
                            <AuthValidationErrors className="mb-4" errors={errors} />
                          </Homer.Group>
                            <form style={{display:"flex",flexDirection:"column", width:'100%',padding:10}}  onSubmit={handleLogin}>
                                <Homer.Input 
                                  type="text" 
                                  name='input_type' 
                                  placeholder="Adresse email / nom utilisateur" 
                                  value={loginInput['input_type']||""}  
                                  onChange={handleChangeLoginInput}
                                  className="text-slate-800"
                                  />
                                  
                                <Homer.Input 
                                  type="password"  
                                  onChange={handleChangeLoginInput} 
                                  placeholder="Mot de passe" 
                                  name="password"
                                  className="text-slate-800"
                                  value={loginInput['password']||""} />
                                  <div className='flex justify-between my-2'>
                                    <Link className='hover:text-green-700' to="/inscription">S'inscrire</Link>
                                    <Link className='hover:text-green-700' to='/mot-de-passe-oublier'>Mot de passe oublier</Link></div>
                                
                                <Homer.Button type="submit">Se connecter</Homer.Button>
                            </form>
                        
                    </Homer.Group >
                  
                </Homer.Inner>
        </Homer.FGradient>
        <Homer.Frame flex={0.3} style={{marginTop:10}}>
            <Homer.Inner  palign="center">
                    Copyright &#169; { new Date().getFullYear() } - Tous droits réservés Mairie de Port-Bouet
            </Homer.Inner>
        </Homer.Frame>
    </Homer>
  )
}

export default Welcome