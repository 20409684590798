import React from 'react'
import { SideBar } from "components"
import logo from 'assets/logo.png'

import MenuIcon from '@mui/icons-material/DashboardRounded';
import ListIcon from '@mui/icons-material/ListAlt';
import AnalyseIcon from '@mui/icons-material/BarChart';
import EtatIcon from '@mui/icons-material/SatelliteTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import Portail from 'components/portail/Portail';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

function PsideBar() {
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const navigate = useNavigate()
    const handleParametre =(e)=>{
        e.preventDefault()
        navigate("/parametres")
       // navigate(0)
    }
  return (
    <SideBar>
            <SideBar.Inner salign='center' palign='center'>
                <SideBar.Logo src ={logo} />
            </SideBar.Inner>
            <SideBar.Inner salign='center' palign='center'>
                <Typography variant="h6">Mon portail</Typography>
                <Portail current="conseiller"/>
            </SideBar.Inner>

        <SideBar.Inner palign='flex-start'>
                <SideBar.Base>
                    <SideBar.Item > 
                        <SideBar.Group salign='center'>
                            <MenuIcon  sx={{ fontSize: 30}}/>
                            <SideBar.Link to={`/${portail}`}>
                              <SideBar.Texte>Tableau de bord</SideBar.Texte> 
                            </SideBar.Link>
                        </SideBar.Group>
                    </SideBar.Item>
                    <SideBar.Item > 
                        <SideBar.Group salign='center'>
                            <ListIcon  sx={{ fontSize: 30}}/>
                            <SideBar.Link to={`/${portail}/rapport`}>
                              <SideBar.Texte>Rapport</SideBar.Texte> 
                            </SideBar.Link>
                        </SideBar.Group>
                    </SideBar.Item>
                    <SideBar.Item > 
                        <SideBar.Group salign='center'>
                            <AnalyseIcon  sx={{ fontSize: 30}}/>
                            <SideBar.Link to={`/${portail}/analyse`}>
                              <SideBar.Texte>Analyse</SideBar.Texte> 
                            </SideBar.Link>
                        </SideBar.Group>
                    </SideBar.Item>
                    <SideBar.Item > 
                        <SideBar.Group salign='center'>
                            <EtatIcon  sx={{ fontSize: 30}}/>
                            <SideBar.Link to={`/${portail}/etat`}>
                              <SideBar.Texte>Etats de gestion</SideBar.Texte> 
                            </SideBar.Link>
                        </SideBar.Group>
                    </SideBar.Item>
                </SideBar.Base>   
        </SideBar.Inner>
        <SideBar.Inner palign='flex-end'>
                <SideBar.Button onClick={handleParametre}>
                    <SettingsIcon sx={{ fontSize: 40}} />
                </SideBar.Button>
            </SideBar.Inner>
    </SideBar>
  )
}

export default PsideBar