import React,{useEffect, useState} from 'react'
import Dash from 'components/dash'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add'
import {deleteOffre } from 'db/dbActions';
import Offreh from 'containers/Offreh/Offreh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { AddCircleOutlineRounded, DetailsOutlined, ListAlt } from '@mui/icons-material';
import { Table } from 'react-bootstrap';

import VisibilityIcon  from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import  DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import swal from 'sweetalert';
const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
const BtnDetails =styled.button`
  background:transparent;
  padding:2px 2rem 0 2rem;
  border:1px solid #f8f8f8;
  display:flex;
  font-size:11px;
  flex-direction:column;
  border-radius: 0px 0px 4px 4px;
  flex:1;
  width:100%;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  &:hover{
    background-color:#00B04B;
    width:100%;
    color:white
  }
`

const useStyles =makeStyles()((theme=>({
  actionBar:{
    display:'flex',
    justifyContent:'flex-end'
},
btnAdd:{
  color:'#00B04B',
  fontSize:40,
  '&:hover':{
    color:'#F29400'
  }
}
})))

function AlertConfirm(props){
  const {data,open,handleClose,action} =props
  return(
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Suppression?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiement supprimer cette offres ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex', flexDirection:'space-between'}}>
          <Button variant="outlined" color='secondary' onClick={handleClose}>Annuler</Button>
          <Button  color="error" variant="contained" onClick={()=>action(data)}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
  )
}


function OffreAccueil(props) {
  const {classes} = useStyles()
  const navigate =useNavigate()
  const [titre,setTitre] = useOutletContext();
  const [search,setSearch] = useState(null)
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [offrespartenaires,setOffrespartenaire] = React.useState(null)
  const [paginateData,setPagnateData] = useState(null)
  const handleOffresPartenaire =(numberPage=1)=>{
    const url =  search? `/api/offre/list/${search}?page=${numberPage}` : `/api/offre/list?page=${numberPage}` 
    axios.get(url).then(res=>{
      if(res.status === 200 || res.status === 201){
        setOffrespartenaire(res.data.data)
        setPagnateData({
          current_page:res.data.meta?.current_page,
          last_page:res.data.meta?.last_page,
          per_page:res.data.meta?.per_page,
          total:res.data.meta?.total
        })
      }else{
        swal("Ooops !","Aucune offre trouvé","error")
      }
    }).catch(err=>{
      swal("Ooops !","Erreur lors de l'opération","error")
    })
  } 

  useEffect(()=>{
    handleOffresPartenaire()
  },[])
  const handleShowListe =(cmode='all')=>{
    setTitre("Gestion des offres - Liste des offres")
    if(cmode){
      navigate(`/${portail}/offres/liste`,{state:{mode:cmode}})
    }
    
  }
  
  const handleNouveau =()=>{
    setTitre("Gestion des offres - Créer")
    navigate(`/${portail}/offres/nouveau`)

  }
  
  const handleDetails =(data)=>{
    setTitre("Gestion des offres - N° "+data['code'])
    if(data){
      navigate(`/${portail}/offres/fiche/${data['id']}`,{replace:true, state:{data}})
    }
  }
  const handleEdit =(data)=>{
    if(data){
      setTitre("Gestion des offres - Modification")
      navigate(`/${portail}/offres/modifier/${data['id']}`,{
        state: {
          data:data,
        }
      })
    }
  }

  const handleDelete =(data)=>{
    swal({
      title: "Voulez vous vraiment supprimer cette offre?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer cette donnée !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(result=>{
      if(result){
        axios.delete(`/api/offre/delete/${data['id']}`).then(res=>{
          if(res.status === 201 || res.status === 204 || res.status === 200){
            swal("Bien!","Suppression effectué avec succès",'success')
          }else{
            swal('Ooops !',"Erreur lors de la suppression",'error')
          }
        }).catch(err=>{
          console.error(err),
          swal('Ooops !',"Erreur lors de la suppression",'error')
        })
      }
    })
    
  }
  const [state,setState] = useState({
    "total":0,
    "encours":0,
    "clotures":0,
    "suspendu":0
  })
  const handleRefreshState =()=>{
    axios.get(`/api/state/offre-statut`).then(res=>{
      if(res.status === 200 || res.status === 201){
        let newstate ={total:0}
        if(res && res.hasOwnProperty('data')){
          let promise  = new Promise (function(resolve,reject){   
             res.data.forEach(element => {
              if(element.etat=='En cours'){
               newstate['encours'] = element.total
              }
              if(element.etat=='Cloturé'){
               newstate['clotures'] =element.total
              }
              if(element.etat=='Sortie' || element.statut=='Suspendu'){
               newstate['suspendu'] += Number(element.total)
              }
              newstate['total'] += parseInt(element.total)
              resolve(true)
           })
          });
          promise.then(aa=>{
            setState({...newstate})
          })
        }
      }
    })
    
  }
  useEffect(()=>{
    handleRefreshState()
  },[])
  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
              <div style={{display:'flex', marginTop:10}}>
                <Dash.MinCard style={{position:'relative'}}>
                    <Dash.Label>Total offres</Dash.Label>
                    <Dash.Text> {state.total}</Dash.Text>
                    <BtnDetails onClick={()=>handleShowListe('all')}> <KeyboardArrowDownIcon /></BtnDetails>
                </Dash.MinCard>
                <Dash.MinCard style={{position:'relative'}}>
                  <Dash.Label>Offres en cours</Dash.Label>
                  <Dash.Text> {state.encours}</Dash.Text>
                  <BtnDetails onClick={()=>handleShowListe('enc')}> <KeyboardArrowDownIcon /></BtnDetails>
                </Dash.MinCard>
                <Dash.MinCard style={{position:'relative'}}>
                  <Dash.Label>Offres cloturés</Dash.Label>
                  <Dash.Text> {state.clotures}</Dash.Text>
                  <BtnDetails onClick={()=>handleShowListe('clr')}> <KeyboardArrowDownIcon /></BtnDetails>
                </Dash.MinCard>
                <Dash.MinCard style={{position:'relative'}}>
                  <Dash.Label>Offres suspendues</Dash.Label>
                  <Dash.Text> {state.suspendu}</Dash.Text>
                  <BtnDetails onClick={()=>handleShowListe('aclr')}> <KeyboardArrowDownIcon /></BtnDetails>
                </Dash.MinCard>
              </div>
              <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,padding:10}}>
              <OfferHeader>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                  <OfferTitle>Dernières offres</OfferTitle>
                <div className={classes.actionBar}>

                    <IconButton aria-label="part-add" onClick={handleShowListe}>
                        <ListAlt className={classes.btnAdd} />
                    </IconButton>
                    <IconButton aria-label="part-add" onClick={handleNouveau}>
                        <AddCircleOutlineRounded className={classes.btnAdd} />
                    </IconButton>
                </div>
            </div>
                 
                  <Hr />
              </OfferHeader>
              <Table>
                <tbody>
                {(offrespartenaires  && offrespartenaires.length!==0) ?offrespartenaires.map(offre=>(
                  <tr>
                    <td><Offreh readmore  offre={offre}  /></td>
                    <td valign='middle'>
                      <div style={{display:'flex',flexDirection:'column'}}>
                      <Tooltip title="Détails">
                        <IconButton style={{borderRadius:0}} onClick={() =>handleDetails(offre)}>
                          <VisibilityIcon sx={{fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Modidier">
                        <IconButton  style={{borderRadius:0}} onClick={() =>handleEdit(offre)}>
                          <EditIcon sx={{fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer">
                        <IconButton style={{borderRadius:0}} onClick={()=>handleDelete(offre)}>
                          <DeleteIcon sx={{fontSize:30}} />
                        </IconButton>
                      </Tooltip>
                      </div>
                    </td>
                  </tr>)) : (<tr><td colSpan={2}><p>Aucune offre trouvée</p></td></tr>)

                }
                </tbody>
              </Table>
      </Dash.Frame>
           
    </div>
  )
}

export default OffreAccueil