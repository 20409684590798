import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'lib/axios';
import { useLocation } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { Button, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TuneIcon from '@mui/icons-material/Tune';
const SearchResult = () => {
    const location = useLocation();
    let dataSend = location.state.params;
    const [loading,setLoading] = useState(false)
    const [resultState,setResultState] = useState(null)
    const [results,setResults] = useState(null)
    const [selected,setSelected] = useState('dossiers')
    const handleSearch = ()=>{
      setLoading(true)
       axios.post(`/api/recherche/global`,dataSend).then(res=>{
        if(res.status === 200 || res.status === 201){
          setResultState(res.data['states'])
          setResults(res.data['details'])
          setLoading(false)
        }else{
          setLoading(false)
        }
       }).catch(err=>{
        setLoading(false)
          console.log(err);
       })
    }
    useEffect(()=>{
      return handleSearch()
    },[])
    const handleChangeDetail = (dt)=>{
      setSelected(dt)
    }
  return (
    <div className='w-full h-full mx-auto'>
        {loading ? <CircularProgress /> : (
          <div className='h-fill'>

            <h1>Résultat recherche ...</h1>
            
            <div className='grid grid-cols-4 gap-4 mb-5'>
                <div onClick={()=>handleChangeDetail('dossiers')} className={`p-2 ${selected==='dossiers' ? 'bg-green-500':"bg-white"} hover:bg-slate-300 cursor-pointer shadow-lg rounded-md`}>
                  <h3>Dossiers</h3>
                  <span className='text-[26px] font-bold'>{resultState?resultState['dossiers']||0:0}</span>
                </div>
                <div onClick={()=>handleChangeDetail('demandes')} className={`p-2 ${selected==='demandes' ? 'bg-green-500':"bg-white"} hover:bg-slate-300 cursor-pointer shadow-lg rounded-md`}>
                  <h3>Demandes</h3>
                  <span className='text-[26px] font-bold'>{resultState?resultState['demandes']||0:0}</span>
                </div>
                <div onClick={()=>handleChangeDetail('offres')} className={`p-2 ${selected==='offres' ? 'bg-green-500':"bg-white"} hover:bg-slate-300 cursor-pointer shadow-lg rounded-md`}>
                  <h3>Offres</h3>
                  <span className='text-[26px] font-bold'>{resultState?resultState['offres']||0:0}</span>
                </div>
                <div onClick={()=>handleChangeDetail('partenaires')} className={`p-2 ${selected==='partenaires' ? 'bg-green-500':"bg-white"} hover:bg-slate-300 cursor-pointer shadow-lg rounded-md`}>
                  <h3>Partenaires</h3>
                  <span className='text-[26px] font-bold'>{resultState?resultState['partenaires']||0:0}</span>
                </div>
            </div>

            <h4>Détails {selected} ...</h4>
            <div className='flex flex-row items-center justify-end gap-2 my-2 px-3'>
            <Button className='bg-slate-200'><TuneIcon />Critères</Button><Button className='bg-slate-200'><ExitToAppIcon />Exporter</Button>
            </div>
            {selected === 'dossiers'&&<Table className='table'>
                <thead><tr><td>N°</td><td>Date</td><td>Jeune</td><td>Quartier</td><td>statut</td></tr></thead>
                <tbody>
                    {(results && results['dossiers'] && results['dossiers'].length !==0)?results['dossiers'].map(dossier=><tr key={dossier.id}>
                        <td>{dossier['code']}</td>
                        <td>{dayjs(dossier['created_at']).format('DD/MM/YYYY')}</td>
                        <td>{dossier['jeune']['nom'] } {dossier['jeune']['prenom']}</td>
                        <td>{dossier['jeune']['quartier'] }</td>
                        <td>{dossier['statut']}</td>
                    </tr>):<tr>
                        <td colSpan={4}><p>Aucun résultat trouvé !</p></td>
                    </tr>}
                    
                </tbody>
            </Table>}
            {selected === 'offres'&&<Table className='table'>
                <thead><tr><td>N°</td><td>Date</td><td>Titre</td><td>Société(s)</td><td>Période</td><td>Contrat</td></tr></thead>
                <tbody>
                    {(results && results['offres'] && results['offres'].length !==0)?results['offres'].map(offre=><tr key={offre.id}>
                        <td>{offre['ref']}</td>
                        <td>{dayjs(offre['created_at']).format('DD/MM/YYYY')}</td>
                        <td>{offre['titre']}</td>
                        <td>{offre.partenaires?offre.partenaires.map(part=>part['raisonsociale'] ||""):null}</td>

                        <td>Du {dayjs(offre['datedebut']).format("DD/MM/YYYY")} Au {dayjs(offre['datefin']).format("DD/MM/YYYY")} </td>
                        <td>{offre['typecontrat']}</td>
                    </tr>):<tr>
                        <td colSpan={6}><p>Aucun résultat trouvé !</p></td>
                    </tr>}
                    
                </tbody>
            </Table>}
            {selected === 'demandes'&&<Table className='table'>
              <thead><tr><td>Dossier N°</td><td>Libelle</td><td>Date</td><td>Statut</td></tr></thead>
              <tbody>
              {(results && results['demandes'] && results['demandes'].length !==0)?results['demandes'].map(demande=><tr key={demande.id}>
                    <td>{demande['id']}</td>
                    <td>{demande['type']?demande['type']['libelle']:null}</td>
                    <td>{dayjs(demande['created_at']).format('DD/MM/YYYY')}</td>
                    <td>{offre['statut']}</td>
                    </tr>):<tr>
                        <td colSpan={4}><p>Aucun résultat trouvé !</p></td>
                    </tr>}
              </tbody>
            </Table>}
            {selected === 'partenaires'&&<Table className='table'>
              <thead><tr><td>N°</td><td>Partenaire</td><td>Activités</td><td>Types</td></tr></thead>
              <tbody>
              {(results && results['partenaires'] && results['partenaires'].length !==0)?results['partenaires'].map(partenaire=><tr key={partenaire.id}>
                    <td>{partenaire['raisonsociale'] || ""}</td>
                    <td>{partenaire['activitesprincpale']}</td>
                    <td>{partenaire['type']}</td>
                    </tr>):<tr>
                        <td colSpan={4}><p>Aucun résultat trouvé !</p></td>
                    </tr>}
              </tbody>
              </Table>}
          </div>
        )}
        
        
        
    </div>
  )
}

export default SearchResult