import React, { useCallback, useMemo, useState,useContext} from 'react'
import StepsIdentification from './steps/StepsIdentification'
import StepsCoordonnes from './steps/StepsCoordonnes'
import StepsCursus from './steps/StepsCursus'
import StepsConnaissance from './steps/StepsConnaissance'
import StepsDemandes from './steps/StepsDemandes'
import Stepper from '@mui/material/Stepper';
import StepsDocuments from './steps/StepsDocuments'
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { styled as MuiStyled } from '@mui/material/styles';
import styled from 'styled-components'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import clsx from 'clsx';
import { Card } from 'components'
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import AlertPage from 'containers/AlertPage'
import { AddContext } from 'context/add'
import StepsResume from './steps/StepsResume'
import Hidden from '@mui/material/Hidden';
import axios from 'lib/axios'
import swal from 'sweetalert'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
const Container = styled.div`
  display:flex;
  margin-right:20px;
`
const Title =styled.h4`
    color:#00B04B;
  `
  const SmallTexte = styled.p`
    color:#00B04B;
    font-size:10px;
    font-style:italic
  `
const useStyles = makeStyles()(theme=>({
  stepper: {
    padding: theme.spacing(3, 5, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  root: {
    backgroundColor: '#eaeaf0',
    padding: 10,
    borderRadius: '50%',
    width:50,
    height:50,
    textAlign:'center'
  },
  active: {
    color: 'white',
    backgroundColor:'#00B04B'
  },
  completed: {
    color: 'green',
  },
}))


const CustomStepIcon = (props) => {
  const {classes} = useStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Typography variant='h4'>{String(props.icon)}</Typography>
    </div>
  );
};
const ColorlibConnector = MuiStyled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height:50,
    width:3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));
const steps = ["Informations personnelles","Coordonnées","Cursus et expériences","Loisirs et connaissance","Demandes/Besoins","Documents","Resumés"];


function DossierAdd() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  
  const handleNext = (e) => {
    e&&e.preventDefault()
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [dossierData,setDossierData] =useState({})
  const [error, setError] = useState('');

  
  const navigate = useNavigate()


  //mise à jour des champ
  const handleChangeData =useCallback(function (name,value){
    setDossierData(d=>({...d,[name]:value}))
  },[])

  const values =useMemo(function (){
    return {...dossierData,handleChangeData}
  },[dossierData,handleChangeData])

  const portail = useSelector(state=>state.ui.portail).toLowerCase()

  const [aletInfo,setAlertInfo] =useState({})
    const handleOpenAletInfo =(message,succes,action)=>{
      setAlertInfo({
        open:true,
        message,
        succes,
        action
      })
    }

    const handleCloseAletInfo =(action)=>{
      setAlertInfo({
        open:false,
        message:"",
        succes:false
      })
      action&&action()
    }

    const handleResetField=()=>{
      setDossierData({})
      handleReset()
      handleCloseAletInfo()
    }

    const handleSubmit =async (e)=>{
      if(values['civilite'] && values['nom'] && values['prenom']){
        const dataSend = {...values}
        delete dataSend['handleChangeData']
        let formdata = new FormData()
        //personnes
        formdata.append('civilite',dataSend['civilite'])
        formdata.append('nom',dataSend['nom']?dataSend['nom']:null)
        formdata.append('prenom',dataSend['prenom']?dataSend['prenom']:null)
        formdata.append('datenaissance',dayjs(new Date(dataSend['datenaissance'])).format('YYYY-MM-DD'))
        formdata.append('lieunaissance',dataSend['lieunaissance']?dataSend['lieunaissance']:null)
        formdata.append('numeropiece',dataSend['numeropiece']?dataSend['numeropiece']:null)
        formdata.append('naturepiece',dataSend['naturepiece']?dataSend['naturepiece']:null)
        formdata.append('photo',dataSend['photo']?dataSend['photo'][0]:null)
        formdata.append('sitmatrimoniale',dataSend['sitmatrimoniale']?dataSend['sitmatrimoniale']:null)
        formdata.append('nombreenfant',dataSend['nombreenfant']?dataSend['nombreenfant']:0)
        formdata.append('nomconjoint',dataSend['nomconjoint']?dataSend['nomconjoint']:null)
        formdata.append('professionconjoint',dataSend['professionconjoint']?dataSend['professionconjoint']:null)
        formdata.append('nompere',dataSend['nompere']?dataSend['nompere']:null)
        formdata.append('professionpere',dataSend['professionpere']?dataSend['professionpere']:null)
        formdata.append('nommere',dataSend['nommere']?dataSend['nommere']:null)
        formdata.append('professionmere',dataSend['professionmere']?dataSend['professionmere']:null)
        formdata.append('ville',dataSend['ville']?dataSend['ville']:null)
        formdata.append('commune',dataSend['commune']?dataSend['commune']:null)
        formdata.append('quartier',dataSend['quartier']?dataSend['quartier']:null)
        formdata.append('cgvcgq',dataSend['cgvcgq']?dataSend['cgvcgq']:null)
        formdata.append('bp',dataSend['bp']?dataSend['bp']:null)
        formdata.append('telephonefixe',dataSend['telephonefixe']?dataSend['telephonefixe']:null)
        formdata.append('mobile1',dataSend['mobile1']?dataSend['mobile1']:null)
        formdata.append('mobile2',dataSend['mobile2']?dataSend['mobile2']:null)
        formdata.append('numerowhatsapp',dataSend['numerowhatsapp']?dataSend['numerowhatsapp']:null)
        formdata.append('email',dataSend['email']?dataSend['email']:null)
        formdata.append('diplome',dataSend['diplome'] || null)
        
        formdata.append('etablissement',dataSend['etablissement'] || null)
        formdata.append('anneediplome',dataSend['anneediplome'] || null)
        formdata.append('paysdiplome',dataSend['paysdiplome'] || null)
        formdata.append('niveauetude',dataSend['niveauetude'] || null)
        formdata.append('activite',dataSend['activite'] || null)
        formdata.append('secteuractivite',dataSend['secteuractivite'] || null)
        formdata.append('anneeexperience',dataSend['anneeexperience'] || null)
        //parcours
        if(dataSend.hasOwnProperty('parcours') && dataSend['parcours'].length!==0){
          formdata.append('parcours',JSON.stringify(dataSend['parcours']));
         
        }
        if(dataSend.hasOwnProperty('atouts') && dataSend['atouts'].length!==0){
          formdata.append('atouts',JSON.stringify(dataSend['atouts']));
        }
        if(dataSend.hasOwnProperty('documents')){
          dataSend.documents.forEach(element => {
            formdata.append('documents[]',element.selectedFile[0])
            formdata.append('documentstitle[]',element.do_doctype)
          });
         
        }
        if(dataSend.hasOwnProperty('demandes')){
          formdata.append('demandes',JSON.stringify(dataSend['demandes']));
        }
        
        axios.post('/api/dossier/add',formdata).then(res=>{
          if(res && res.status === 200 || res.status === 201){
            swal("Success","Enregistrement effectué avec succès","success")
            navigate(`/${portail.toLowerCase()}/dossiers}`)
            navigate(0)
          }else{
              console.log(res.data);
              swal("Ooops !",res.data.message,"error")
              handleChangeData('error_list', res.data.validation_errors);
              return
          }
        }).catch(err=>{
          console.log(err.response)
          swal("Ooops !","Erreur lors de l'enregistrement","error")
        })
      }
    }


  return (
    <Container>
      <AddContext.Provider value={values}>
        {(values.hasOwnProperty('error_list') && values['error_list'].length !== 0)&&<Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {values['error_list'].map(er=>(`${er.toString()}\n`))}
          </Alert>    
        </Stack>}
        
        <div style={{display:'flex', flex:1}}>
            <AlertPage open={aletInfo['open']||false} message={aletInfo['message']||""} action={aletInfo['action']} succes={aletInfo['succes']||false} handleClose={handleCloseAletInfo} />
            {(activeStep ===0)&&<StepsIdentification handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} />}
            {(activeStep ===1)&&<StepsCoordonnes handleNext={handleNext} handleBack={handleBack} steps={steps}  step={activeStep} />}
            {(activeStep ===2)&&<StepsCursus handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} />}
            {(activeStep ===3)&&<StepsConnaissance handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} />}
            {(activeStep ===4)&&<StepsDemandes handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} />}
            {(activeStep ===5)&&<StepsDocuments handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} />}
            {(activeStep ===6)&&<StepsResume handleNext={handleNext} handleBack={handleBack} steps={steps} step={activeStep} handleSubmit={handleSubmit} />}
            
        </div>
        </AddContext.Provider>
        <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical" connector={<ColorlibConnector />}>
        {steps.map((step, index) => (
          <Step key={step} >
            <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
        <Hidden lgUp>
          <div style={{display:'flex',flex:0.5,flexDirection:'column'}}>
            <Card direction ="row" style={{justifyContent:"space-between"}}>
                <div><Title>PLANIFIER UNE ACTIVITE</Title><SmallTexte>(Rendez-vous, Entretien,reunion)</SmallTexte></div>
                <button style={{padding:10,backgroundColor:'#00B04B',color:'white',border:'none',cursor:'pointer'}}><AddIcon /></button>
            </Card>
            <Card padding='10px 0px 10px 0px'>
              <Card.Header>
                    <Card.Title color="#00B04B">Proposer une offres/services</Card.Title>
                    <Card.Hr />
              </Card.Header>
              
            </Card>
          </div>
        </Hidden>
    </Container>
   
  )
}

export default DossierAdd