import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Button, Form, InputGroup, Table } from 'react-bootstrap';
import { Divider, IconButton, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import  FolderIcon  from '@mui/icons-material/Folder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import swal from 'sweetalert';
import axios from 'lib/axios';
import * as XLSX from 'xlsx'
import Spinner from 'react-bootstrap/Spinner';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import { getCiviliteFromSex, getTypeDemandeExport, splitName, splitPhoneNumer } from 'lib/application';
import dayjs from 'dayjs';
import {villes,communes,doctype} from 'fixtures/data'
import SearchIcon from '@mui/icons-material/Search';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
   
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
const useStyles = makeStyles()(theme=>({
  btnAdd:{
    paddingLeft:20,
    paddingRight:20,
    backgroundColor: '#00B04B',
    color:'white',
    flex:1,
    marginRight:20,
    paddingTop:20,
    paddingBottom:20,
  },
  searchBox:{
    display: 'flex',
    padding:20,
    marginBottom:20,
    flexDirection: 'column',
  },
}))
const CritereRechercheDossier = ({handleSearch,searchInput,setSearchInput,open,handleClose,...restProps}) => {
  
    const {classes} =useStyles()
    const [loading,setLoading] =useState(false)
    const [quartiers,setQuartiers] = useState(null)
    const [cqvcgv,setcqvcgv] = useState(null)
    const [services,setServices] = useState(null)
    useEffect(()=>{
      axios.get(`/api/type-demande/all`).then(res=>{
        if(res.status === 200 || res.status === 201){
          setServices(res.data)
        }
      })
      axios.get(`/api/quartier/all`).then(res=>{
        if(res.status === 200 || res.status === 201){
          setQuartiers(res.data)
        }
      })
      axios.get(`/api/comite/all`).then(res=>{
        if(res.status === 200 || res.status === 201){
          setcqvcgv(res.data)
        }
      })
    },[])
    const handleChange =(e)=>{
      const {name,value} = e.target
      setSearchInput({...searchInput,[name]:value})
    }
    const handleChecked = (e)=>{
      const {name,value} = e.target
     if(searchInput.statut.findIndex(item => item === value) !==-1){
      const newSatut = searchInput.statut.filter(item =>item !== value)
      setSearchInput({...searchInput,'statut':newSatut})
     }else{
      const newSatut =[...searchInput.statut,value]
      setSearchInput({...searchInput,'statut':newSatut})
     }
    }

  return (
    <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth='md'
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:20}}>
              <div style={{display:'flex', alignItems:'center'}}>
                <SearchIcon sx={{fontSize:40, marginRight:20}} />
                <Typography variant='h5'>Dossier - Recherche avancée</Typography>
              </div>
            </div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div>
                    
                    <Paper className={classes.searchBox} variant='outlined'>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control name='search' value={searchInput['search'] || ''} size="lg" type="text" placeholder='Mot clé' onChange={handleChange} accept=".xlsx,xls" />
                      </Form.Group>
                      <Form.Group  className="flex gap-2 mb-3">
                      <Form.Select size="lg" name='civilite' aria-label="Default select example" onChange={handleChange}>
                        <option>Civilité</option>
                        <option value="M">Monsieur</option>
                        <option value="Mme">Madame</option>
                        <option value="Mmme">Mademoiselle</option>
                      </Form.Select>
                        <Form.Control name='nom' value={searchInput['nom'] || ''} size="lg" type="text" placeholder='Nom' onChange={handleChange}  />
                        <Form.Control name='prenom' value={searchInput['prenom'] || ''} size="lg" type="text" placeholder='Prénoms' onChange={handleChange}/>
                        
                      </Form.Group>
                      <Form.Group className="flex gap-2  mb-3">
                          <Form.Control name='datenaissance' value={searchInput['datenaissance'] || ''} size="lg" type="date" placeholder='Date de naissance' onChange={handleChange} />
                          <Form.Control name='age' size="lg" value={searchInput['age'] || ''} type="number" placeholder='Age' onChange={handleChange} />
                      </Form.Group>
                      <Form.Group className="flex gap-2  mb-3">
                        <Form.Select name='lieunaissance' size="lg" onChange={handleChange} aria-label="Default select example">
                        <option>Lieu de naissance</option>
                          {(villes && villes.length !==0) && villes.map((ville,idx)=> <option key={idx} value={ville}>{ville}</option>)}
                        </Form.Select>
                       
                        <Form.Select name='lieuresidence' size="lg" onChange={handleChange} aria-label="Default select example">
                          <option>Lieu de residence</option>
                          {(villes && villes.length !==0) && villes.map((ville,index)=> <option key={index} value={ville}>{ville}</option>)}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="flex gap-2  mb-3">
                        <Form.Select name='commune' size="lg" onChange={handleChange} aria-label="Default select example">
                          <option>Commune</option>
                          {(communes && communes.length !==0) && communes.map((commune,idx)=> <option key={idx} value={commune}>{commune}</option>)}
                        </Form.Select>
                        <Form.Select name='quartier' onChange={handleChange} size="lg" aria-label="Default select example">
                          <option>Quartiers</option>
                          {(quartiers && quartiers.length !==0) && quartiers.map(quartier=> <option key={quartier.id} value={quartier.nom}>{quartier.nom}</option>)}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="flex gap-2  mb-3">
                        <Form.Select name='demandes' onChange={handleChange} size="lg" aria-label="Default select example">
                          <option>Demandes</option>
                          {(services && services.length !==0) && services.map(service=> <option key={service.id} value={service.id}>{service.libelle}</option>)}
                        </Form.Select>
                        <Form.Check 
                            type="switch"
                            id="nouveau-switch"
                            label="Nouveau"
                            value='Nouveau'
                            checked={searchInput.statut.findIndex(item => item === "Nouveau") !== -1}
                            onChange={handleChecked}
                          />
                          <Form.Check 
                            type="switch"
                            id="accepter-switch"
                            label="Accepter"
                            value='Accepter'
                            checked={searchInput.statut.findIndex(item => item ==="Accepter") !== -1}
                            onChange={handleChecked}
                          />
                          <Form.Check 
                            type="switch"
                            id="veille-switch"
                            label="En veille"
                            value='En veille'
                            checked={searchInput.statut.findIndex(item => item === "En veille") !== -1}
                            onChange={handleChecked}
                          />
                          <Form.Check
                            type="switch"
                            checked={searchInput.statut.findIndex(item => item === "Sortie") !== -1}
                            id="sortie-switch"
                            label="Sortie"
                            value='Sortie'
                            onChange={handleChecked}
                          />
                      </Form.Group>
                      <div style={{display:'flex',justifyContent:'center'}}>
                            {loading&&<Spinner animation="border" />}
                      </div>
                    </Paper>
                </div>  
                <Divider />
                <div className='flex flex-row p-2 justify-between items-center'>
                    <Button variant="outline-success" id="button-addon2" className={classes.btnAdd} onClick={handleSearch}>Rechercher <SearchIcon sx={{fontSize:40}}/></Button>
                </div> 
          </DialogContent>
        </BootstrapDialog>
    </div>
  )
}

export default CritereRechercheDossier