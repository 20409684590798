
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button, Pagination, Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CircleIcon from '@mui/icons-material/Circle';
import { green, red } from '@mui/material/colors';
import { Table } from 'react-bootstrap';
import { useAuth } from 'hooks/auth';
import axios from 'lib/axios';
import dayjs from 'dayjs';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Card } from 'components';
import { Alert, Form, InputGroup, Spinner } from 'react-bootstrap';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Snackbar from '@mui/material/Snackbar';
function ResultSearch ({resultData,handleChangePage,dettacheProfil,attacheProfil,profil,usersLier}){
    const {data,current_page,last_page,per_page,total} =resultData
    
    return <div style={{display:'flex',flexDirection:'column',marginTop : 20}}>
    <Card.Hr />
    <Typography>-- RESULTAT DE LA RECHERCHE</Typography>
    <Table striped bordered hover>
        <thead>
            <tr>
                <th>Nom</th>
                <th>Né(e) le</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {(data.length!==0)?data.map(userRow=>(<tr key={userRow.id}>
                <td>{`${userRow.personne?.civilite} ${userRow.personne?.nom} ${userRow.personne?.prenom}`}</td>
                <td>{`${dayjs(userRow.personne?.datenaissance).format('DD/MM/YYYY')}`}</td>
                <td>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {usersLier?((usersLier.findIndex(item=>item.id === userRow.id)=== -1)?(
                        <IconButton onClick={()=>attacheProfil(userRow.id,profil)}>
                            <AddCircleOutlineIcon />
                        </IconButton>):(
                        <IconButton onClick={()=>dettacheProfil(userRow.id)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>)):(<IconButton onClick={()=>attacheProfil(userRow.id,profil)}>
                            <AddCircleOutlineIcon />
                        </IconButton>)}
                        {}
                        
                        
                    </div>
                </td>
            </tr>)):<tr><td colSpan={3}><p>Aucun résultat trouvé</p></td></tr>}
        </tbody>
    </Table>
    <div style={{display:'flex',justifyContent:'flex-end'}}>
    <Pagination
        component="div"
        count={total}
        defaultPage={1}
        page={current_page}
        onChange={(event,numberPage)=>handleChangePage(numberPage)}
        rowsPerPage={per_page}
        variant='outlined'
        showFirstButton={current_page>10} 
        showLastButton ={total>=10}
        />
    </div>
    
</div>
        
    
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const UserProfilList = ({open,profil,handleClose,handleOpen,ctab}) => {
    const [value, setValue] = React.useState(ctab || 0);
    const [loading,setLoading] = useState(false)
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const {user,assignerRole,removeRole} = useAuth({middleware: "auth"})
    const [usersLier,setUsersLier] = React.useState([])
    const handleGetUserLier = (profil,numberPage)=>{
         const url = `api/users/user-profile/${profil}?page=${numberPage}`
         axios.get(url).then(res=>{
             if(res.status === 200 || res.status === 201){
                 setUsersLier(res.data)
             }else{
                 console.log(res.data);
             }
         }).catch(err=>{
             if(err.hasOwnProperty('response')){
                 console.log(err.response)
             }
         })
    }
   
    const [smessage,setSmessage]=  useState("Opération effctué avec succes")
    const [sopen,setSopen] = useState(false)
    const handleSopen = () => {
        setSopen(true);
      };
    
      const handleSClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSopen(false);
      };
   const handleDettacheUser = (user) =>{
     if(user && profil){
         if(removeRole(profil.libelle,user.id)){
             handleGetUserLier(profil.id,1)
             setSmessage("L'utilisateur à bien été détaché avec succès")
             handleSopen()
         }else{
            setSmessage("Erreur l'hors de l'opération")
            handleSopen()
         }
     }
   }
 
    const handleAttacheProfil = (user,pro) =>{
         if(user,pro,profil){
             if(assignerRole(profil.libelle,user)){
                 handleGetUserLier(pro,1)
                 setSmessage("L'utilisateur à bien été attché au profil")
                 handleSopen()
             }else{
                setSmessage("Erreur l'hors de l'opération")
                handleSopen()
             }
         }
    }
    useEffect(()=>{
        if (profil)
            handleGetUserLier(profil.id,1)
       },[JSON.stringify(profil)])

       const [inputSearch,setInputSearch] = useState(null)
   const handelChangeInputSearch = (e)=>{
    const {name,value} = e.target
    setInputSearch({...inputSearch,[name]:value})
   }
   const [usersResult,setUsersResult] = useState(null)
   const handleSearchUser = (pageNumger=1)=>{
        if(!inputSearch?.hasOwnProperty('nom') && inputSearch?.hasOwnProperty('datenaissance')){
            swal("Ooops","Veuillez renseigner au moin un champ de recherche","warning")
            return
        }
        const dataSend ={
            ...inputSearch,
            structure_id:user?.hasOwnProperty('structure_id')?user.structure_id:null
        }
        const url = `/api/users/basic-search?page=${pageNumger}`
        axios.post(url,dataSend).then(res=>{
            if(res.status===200 ||  res.status===201){
                setUsersResult(res.data)
            }else{
                swal("Ooops","Aucun utilisateur trouver","warning")
                return
            }
        }).catch(err=>{
            if(err.hasOwnProperty('response')){
                console.log(err.response);
                setErrors(err.response.data?.errors)
            }
        })
   }
   const handleSetTab = ()=>{
        setValue(ctab)
   }
   useEffect(()=>{
    handleSetTab()
   },[ctab])
    return (
   <div>
    <Snackbar
        open={sopen}
        autoHideDuration={5000}
        onClose={handleSClose}
        message={smessage}
      />
    <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth='md'
        fullWidth
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <div className='flex flex-row gap-3 items-center'>
                <Typography variant='h5'>Profil : #{profil&&profil.libelle} </Typography>
                {profil&& <div>
                    <CircleIcon sx={{
                    color:profil.statut=="Actif" ? green[400] : red[400],
                    fontSize:10
                }} /> <span className='text-[10px] italic text-slate-500'>{profil.statut}</span>
                    </div>}
               
            </div>
          
        </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                >
                <Tab icon={<PeopleAltIcon />} iconPosition="start" label="Liste des utilisateurs" />
                <Tab icon={<PersonAddIcon />} iconPosition="start" label="Ajouter un utilisateur" />
            </Tabs>
            {value ==0&&<div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Civilite</th>
                            <th>Nom</th>
                            <th>Prénoms</th>
                            <th>Date de naissance</th>
                            <th>Psuedo</th>
                            <th>email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        
                        (usersLier && usersLier.hasOwnProperty('data'))?usersLier.data.map(userlier=>(<tr>
                            <td>{userlier.personne?.civilite}</td>
                            <td>{userlier.personne?.nom}</td>
                            <td>{userlier.personne?.prenom}</td>
                            <td>{dayjs(userlier.personne?.datenaissance).format('DD/MM/YYYY')}</td>
                            <td>{userlier.username}</td>
                            <td>{userlier.email}</td>
                            <th>
                            <IconButton onClick={()=>handleDettacheUser(userlier.id)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton> 
                            </th>
                        </tr>)):<tr><td colSpan={5}>
                                <p>Aucun utilisateur n'est attaché à ce profil.<br />Vous pouvez rechercher les utilisateurs et leur attribuer ce profil. ou bien renseigner les profils directement à partir de l'utilisateur</p>
                            </td></tr>}
                    </tbody>
                </Table>
                    {(usersLier && usersLier.hasOwnProperty('data'))&&
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <Pagination
                            component="div"
                            count={usersLier.total}
                            defaultPage={1}
                            page={usersLier.current_page}
                            onChange={(event,numberPage)=>handleGetUserLier(profil.id,numberPage)}
                            rowsPerPage={usersLier.per_page}
                            variant='outlined'
                            showFirstButton={usersLier.current_page>10} 
                            showLastButton ={usersLier.total>=10}
                            />
                    </div>
                    }
            </div>}
            {value==1&&<div>
                <Paper style={{flex:1,backgroundColor:'#f1f1f1',marginLeft:20,borderRadius:10,padding:'20px 10px'}} variant='outlined'>
                    <Typography>LIER UN UTILISATEUR A CE PROFIL</Typography>
                    <div style={{marginTop:20}}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Nom"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    name ="nom"
                                    value ={inputSearch?.nom}
                                    onChange={handelChangeInputSearch}
                                />
                            </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Date de naissance"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                type='date'
                                name ="datenaissance"
                                value ={inputSearch?.datenaissance}
                                onChange={handelChangeInputSearch}
                            />
                        </InputGroup>
                        <div style ={{display:'flex',justifyContent:'center'}}>
                            <Button type='button' style={{backgroundColor:'#00B04B'}} onClick={()=>handleSearchUser(1)}>
                                {loading?<Spinner />:<SearchIcon sx={{fontSize:25,color:'#ffffff'}} />} 
                            </Button>
                            <Button type='button' style={{backgroundColor:'#00B04B',marginLeft:20}} onClick={()=>setInputSearch(null)}>
                                {loading?<Spinner />:<ClearIcon sx={{fontSize:25,color:'#ffffff'}} />} 
                            </Button>
                        </div>
                        
                    </div>
                                        {usersResult&&<ResultSearch 
                                                resultData={usersResult} 
                                                handleChangePage={handleSearchUser} 
                                                dettacheProfil={handleDettacheUser}
                                                attacheProfil={handleAttacheProfil}
                                                profil={profil?.id}
                                                usersLier={usersLier.data}
                                                />}
                                        
                                    </Paper>
            </div>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </BootstrapDialog>
   </div>
  )
}

export default UserProfilList