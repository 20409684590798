import React from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Table } from 'react-bootstrap';
import { Divider, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';
import  FolderIcon  from '@mui/icons-material/Folder';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import swal from 'sweetalert';
import axios from 'lib/axios';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  
const ModDemandeList = (props) => {
    const {open,handleClose,data}  = props
    const handleDelete = (dmd)=>{
        swal({
            title: "Voulez-vous vraiment supprimer cette demande?",
            text: "Cette opération est irreversible",
            type: "warning",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            denyButtonText: `Annuler`,
            buttons: true,
            dangerMode: true
          }).then(result=>{
            console.log(result);
            if(result){
                axios.delete(`/api/demande/${dmd.id}`).then(res=>{
                    if(res.status === 200 || res.status === 204 || res.status === 201){
                        swal("Supprimer!", "La demande à été supprimé avec succès", "success");
                    }else{
                        swal("Ooops!", "Erreur au momment de la suppresion", "error");
                    }
                }).catch(err=>{
                    swal("Ooops!", "Erreur au momment de la suppresion", "error");
                })     
            }else{
                swal("Ooops!", "Opération de suppression echouée", "error");
            }
          });
    }
  return (
    <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth='md'
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:20}}>
              <div style={{display:'flex', alignItems:'center'}}>
                <FolderIcon />
                <Typography variant='h5'>Liste des demandes - Dossier N° : {data&&data['code']}</Typography>
              </div>
            </div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <List>
            {(data && data.hasOwnProperty('demandes') && data.demandes.length!==0) ? data.demandes.map(dossier=><div><ListItem key={dossier.id} 
            secondaryAction={<div>
                    <Tooltip title="Modifier">
                        <IconButton edge="end" aria-label="delete">
                            <ModeEditOutlineIcon sx={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                        <IconButton edge="end" aria-label="delete" onClick={()=>handleDelete(dossier)}>
                            <DeleteIcon sx={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                </div>
              }
            >
            <ListItemIcon>
                <Chip label={dossier['statut']?(dossier['statut'].charAt(0).toUpperCase() + dossier['statut'].slice(1)) : ""} color={dossier['statut']==='En attente'?"primary":(dossier['statut']==='En traitement'?"warning":(dossier['statut']==='satisfait'?"success":"error"))} />
            </ListItemIcon>
                        <ListItemText
                          primary={<div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                              <Typography sx={{marginRight:10}}>{dossier.type?.libelle?dossier.type?.libelle : ""}</Typography>
                             
                          </div>}
                          secondary={
                            <div style={{marginLeft:20}}>
                                    <span style={{fontWeight:'bold'}}>{ formatDistance(new Date(dossier.created_at), new Date(), { addSuffix: true,locale:fr })}</span>
                                    <p>{dossier['description']&&<ReactQuill readOnly={true} theme={"bubble"} value={dossier['description'] || ""} />}</p>
                          </div> 
                        }
                        />
                      </ListItem><Divider variant="inset" component="li" /></div>): <ListItem>
                        <ListItemText primary="Aucune damnde trouvée" />
                      </ListItem>
                    }
                    
                </List> 
                
          </DialogContent>
        </BootstrapDialog>
    </div>
  )
}

export default ModDemandeList