import React from "react"
import { Outlet } from "react-router-dom"
import Header from "containers/HeaderAdmin"
import Breadcrumbs from "containers/Breadcrumbs"
import logo from "assets/logo.jpg"
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Administrateur.scss"
import Portail from "components/portail/Portail"
import { useSelector } from "react-redux"
function Administrateur() {
    const portail = useSelector(state =>state.ui.portail).toLowerCase()
  return (
    <div className="administrateur">   
        <div className="header">
          <div className="logobox">
              <img src={logo} className="logo" alt="Logo pfs"/>   
              <Portail />
          </div>
          <div className="head">
              <Header />
              <div className="navBar">
                <Navbar bg="light" expand="lg">
                  <Container fluid>
                    <Navbar.Brand href={`/${portail}`}>Mon portail</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                      <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: "100px" }}
                        navbarScroll
                      >
                        <NavDropdown title="Services" id="navbarScrollingDropdown">
                          <NavDropdown.Header>GESTIONS</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={`/${portail}/profils`}>
                            Profils
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/utilisateurs`}>
                            Comptes utilisateurs
                          </NavDropdown.Item>
                          <NavDropdown.Header>SUIVI</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={`/${portail}/suivi-action`}>
                            Suivi des actions
                          </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Structures" id="navbarScrollingDropdown">
                          <NavDropdown.Header>PARTENAIRES</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={`/${portail}/partenaires`}>
                            Partenaires
                          </NavDropdown.Item>
                          <NavDropdown.Header>ZONE DE COUVERTURE</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={`/${portail}/comites`}>
                           Comité de gestion (CGQ/CGV)
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/quartiers`}>
                           Quartiers
                          </NavDropdown.Item>
                          
                        </NavDropdown>
                        <NavDropdown title="Références" id="navbarScrollingDropdown">
                          <NavDropdown.Header>TABLE DE REFERENCES</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="references/prescripteurs">
                            Prescripteurs pour dispositifs
                          </NavDropdown.Item>
                          <NavDropdown.Item href="references/operateurs">
                            Operateurs
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/references/referents">
                            Référents
                          </NavDropdown.Item>
                          <NavDropdown.Header>AUTRES</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="references/themed">
                            Themes
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/references/actes">
                            Actes de services
                          </NavDropdown.Item>
                          <NavDropdown.Header></NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/references/all">
                            Tous les paramètres
                          </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Portail conseiller" id="navbarScrollingDropdown">
                          <NavDropdown.Item href={`/${portail}/dispositifs`}>
                            Dispositifs 
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/offres`}>
                            Offres de services
                          </NavDropdown.Item>
                          <NavDropdown.Header>PARAMETRES</NavDropdown.Header>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={`/${portail}/types-demandes`}>
                            Type de demandes
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/categories-offres`}>
                            Catégories d'offres
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/types-structures`}>
                            Type de structures
                          </NavDropdown.Item>
                          <NavDropdown.Item href={`/${portail}/natures-evenements`}>
                            Natures événements
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                      
                  </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
          </div>
        </div>
        <main className="main">
            <Breadcrumbs />
            <Outlet />
        </main>
        <div className="footer">
         Copyright &#169; { new Date().getFullYear() } - Tous droits réservés Marie de Port-bouet
        </div>
    </div>
  )
}

export default Administrateur