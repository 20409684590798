import React, { useEffect,useState } from 'react'
import Paper from '@mui/material/Paper';
import {  Typography,Button } from '@mui/material';
import { useNavigate,useParams } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {communes} from 'fixtures/data'
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import './styles/UpdateQuartier.scss'
import SaveIcon from '@mui/icons-material/Save';
import { Form as CustumForm } from 'components'
import { Card } from 'components'
import RefreshIcon from '@mui/icons-material/Refresh';
import {Spinner } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
function UpdateQuartier() {

  const navigate = useNavigate()
  const [loading,setLoading] =useState(false)
  const params =useParams()
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
  const [inputField,setInputField] = useState({})
  const handleInputChange = (e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  
  const handleSave =(e)=>{
    e.preventDefault()
    if(inputField.hasOwnProperty('nom') && inputField.hasOwnProperty('commune')){
      setLoading(true)
      axios.put(`/api/quartier/update/${params["code"]}`,inputField).then(res=>{
        if(res.status === 200 || res.status === 201){
          setLoading(false)
          swal('Bien !',"Enregistrement effectué avec succès",'success')
          handlelist()
        }else {
          setLoading(false)
          swal('Ooops',"Une erreur s'est produite lors de l'enregistrement",'error')
        }
      }).catch(err=>{
         console.log(err)
         setLoading(false)
         swal('Ooops',"Une erreur s'est produite lors de l'enregistrement",'error')
      })
    }else{
      setLoading(false)
      swal('Attention',"Veuillez remplir tous les champs",'warning')
    }
  }
  const handleQuartier = ()=>{
      axios.get('api/quartier/'+params['code']).then(res=>{
        if(res.status === 200 || res.status ===201){
          setInputField({...inputField,...res.data})
        }
      })
  }
  useEffect(()=>{
    return handleQuartier()
  },[params])

  const handlelist = ()=>{
    navigate(`/${portail}/quartiers`)
  }
  return (
    <div className='updatequartier'>
    
       <div className="titlebox">
            <Typography variant='h5'>Modification quartier - #{params['code'] || ""}</Typography>
            <Button className="btn" onClick={(e)=>handlelist()}><ArrowBackIosIcon sx={{color:'#00B04B'}} />Retour à la liste</Button>
        </div>

        <div className="bodybox">
          <form onSubmit={handleSave}>
              <Paper className="formbox" variant='outlined'>
                  <Card>
                      <Card.Header>
                          <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                      <CustumForm style={{display:'flex',flexDirection:'column'}}>
                        <CustumForm.Group  direction ='column' style={{paddingBottom:20}} flex={1} >
                        <CustumForm.Input   
                          name='nom' 
                          value={inputField['nom'] || ""} 
                          onChange={handleInputChange} 
                          style={{marginRight:20,marginBottom:20}}  
                          size="small" 
                          margin="dense" 
                          label="nom du quartier" 
                          wd='100%' />
      
                      <Autocomplete
                        disablePortal
                        id="commune"
                        size="small" 
                        margin="dense"
                        defaultValue='Port-Bouët'
                        options={communes}
                        fullWidth
                        sx={{ width: '100%' }}
                        value={inputField['commune'] || ""}
                        name='commune'
                        selected = {inputField['commune'] || ""}
                        onChange={(event, newValue) => {
                          setInputField({...inputField,['commune']:newValue});
                        }}
                        inputValue={inputField['commune'] || ""}
                        onInputChange={(event, newInputValue) => {
                          setInputField({...inputField,['commune']:newInputValue});
                        }}
                        renderInput={(params) => <CustumForm.Input value={inputField['commune'] || ""} wd='100%' {...params} label="Commune" />}
                        />
                      </CustumForm.Group>
                  </CustumForm>
                  </Card>
                  <Card>
                      <Card.Header>
                          <Card.Title>MEMBRES DU BUREAU</Card.Title>
                      <Card.Hr />
                      </Card.Header>
                    </Card>

        
                </Paper>
                <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                        </Button>
                        <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                            <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                        </Button>
                  </Card>     
                </form>
          
          
       </div>
    </div>
  )
}

export default UpdateQuartier
