import React,{useEffect,useState} from 'react'
import Table from 'react-bootstrap/Table';
import {Pagination} from "@mui/material";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Chip from '@mui/material/Chip';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import "./styles/QuartierList.scss"
import { Form, InputGroup, Modal,Spinner ,Alert} from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'lib/axios';

function QuartierList() {
    const navigate =useNavigate()
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const [loading,setLoading] =useState(false)
    const [quartiers,setQuartiers] = useState([])
    const [search,setSearch] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const handleQuartierList = (numberPage = 1)=>{
        setLoading(true)
        const url = search?`/api/quartier/list/${search}?page=${numberPage}`:`/api/quartier/list?page=${numberPage}`
        axios.get(url).then(res=>{
            if(res.status ===200 || res.status === 201){
              setLoading(false)
              setQuartiers(res.data.data)
           
              setPagnateData({
                  current_page:res.data.meta?.current_page,
                  last_page:res.data.meta?.last_page,
                  per_page:res.data.meta?.per_page,
                  total:res.data.meta?.total
                })
            }   
          }).catch(err=>{
            setLoading(false)
          })
    }

    useEffect(()=>{
        return handleQuartierList()
    },[])
   
    const handleDelete =(id)=>{
        setLoading(true)
        axios.delete(`/api/quartier/delete/${id}`).then(res=>{
            if(res.status === 200 || res.status === 201){
                setLoading(false)
                swal("Bien !","Suppression effectué avec succès",'success')
                handleQuartierList()
            }else{
                setLoading(false)
                swal("Ooops !","Problème lors de l'opération",'error')
            }
        }).catch(err=>{
            console.log(err)
            setLoading(false)
        })
    }
   
  return (
    <div className="quartierlist">
        <div className='titlebox'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Gestion des quartiers</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={(e)=>navigate(`/${portail}/quartier/nouveau`)}>
                <AddIcon/>Ajouter une un quartier
            </Button>
        </div>

        <div className="bodybox">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(nom,commune.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={()=>handleQuartierList(1)}>
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                 <Chip label={`Total(s) : ${paginateData?.total}`} variant="outlined" />
                <Table>
                    <thead>
                        <tr>
                        <th>Quartier</th>
                        <th>Commune</th>
                        <th>Responsable</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {(quartiers && quartiers.length!==0)?(quartiers.map((quartier) => (
                        <tr key={quartier.id}>
                            <td>{quartier.nom}</td>
                            <td>{quartier.commune}</td>
                            <td>{(quartier.responsable)?quartier.responsable:(<IconButton aria-label="edit"><PersonAddIcon sx={{fontSize:3}}/></IconButton>)}</td>
                            <td>
                                <IconButton aria-label="edit" onClick={(e)=>navigate(`/${portail}/quartier/modifier/${quartier.id}`)} >
                                    <ModeEditIcon sx={{fontSize:40}}/>
                                </IconButton>
                                <IconButton aria-label="delete" onClick={(e)=>handleDelete(quartier.id)} >
                                    <DeleteIcon sx={{fontSize:40}}/>
                                </IconButton>
                            </td>
                        </tr>
                        ))):<tr><td colSpan={5}><p>Aucune données trouvée, Veuillez Cliquez sur le bouton "Ajouter un quartier " pour créer un nouveau quartier</p></td></tr>}
                        
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleQuartierList(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
            </Paper>
        </div>
    </div>
  )
}

export default QuartierList
