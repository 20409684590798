import React from 'react'
import {Headeradmin} from 'components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MessageIcon from '@mui/icons-material/Message';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "hooks/auth";
const options = [
  "Tous",
  'Dossiers',
  'Demandes',
  'Offres',
  'Partenaires',
];

function HeaderContainer() {
  //Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const {user,logout} = useAuth({ middleware: 'auth' })

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
   
  
      //deconnexion
    const handleDeconnexion =(event)=>{
      event.preventDefault();
      logout()
    }
  return (
    <Headeradmin>
        <Headeradmin.Inner direction='row' salign='center' flex={4}>
          <div>
            <List component="nav" aria-label="cibles" sx={{ bgcolor: 'none'}}>
              <ListItem button
                id="lock-button" aria-haspopup="listbox" aria-controls="lock-menu"
                aria-label="Selectionnez une cible de recherche" aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
              >
                    <ListItemText
                      primary="Rechercher dans ..."
                      secondary={options[selectedIndex]}
                    />
              </ListItem>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}   
            >
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                    style={{fontSize:18}}
                  >
                    {option}
                  </MenuItem>
                ))}
            </Menu>
          </div>

          <div style={{display:'flex', flex:2,alignItems:'center'}}>
            <Headeradmin.Input type="text" placeholder="Nom,offre,numméro de dossier, ..." name="motcle" />
            <IconButton aria-label="search">
                <SearchIcon color="success" sx={{ fontSize: 30}} />
            </IconButton>
          </div>
        </Headeradmin.Inner>
       
        <Headeradmin.Group direction='row' flex={1} salign='center' palign="flex-end">
                <IconButton aria-label="search" color="success" >
                    <CalendarMonthIcon sx={{ fontSize: 30}} />
                </IconButton>
                <IconButton aria-label="search" color="success" >
                    <MessageIcon sx={{ fontSize: 30}} />
                </IconButton>
                <IconButton aria-label="search" color="success" >
                    <MailOutlineIcon sx={{ fontSize: 30}} />
                </IconButton>
                <IconButton aria-label="search" color="success" >
                    <PersonIcon sx={{ fontSize: 30}} />
                </IconButton>
                 <Headeradmin.Group direction='column' flex={1}>
                  <Typography variant='h6'>{user?.name} </Typography>
                    <Headeradmin.LightButton onClick={handleDeconnexion}>Deconnexion</Headeradmin.LightButton>
                </Headeradmin.Group>
        </Headeradmin.Group> 
    </Headeradmin>
  )
}

export default HeaderContainer