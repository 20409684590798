import React from 'react'
import { Avatar, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(theme=>({
    container:{
        display:'flex',
        justifyContent:'flex-start'
    },
    containeravatar:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        padding:10,
    }
    
    
}))
function Dossier(props) {
    const {classes} = useStyles()
    const {data} = props
  return (
    <div className={classes.container}>
        <div className={classes.containeravatar}>
            <Avatar sx={{ width: 60, height: 60 }} alt={`${data.jeune?.nom?data.jeune?.nom:"-"} ${data.jeune?.prenom?data.jeune?.prenom:"-"}` } src={`${data.jeune?.photo}`} />
        </div>
        <div className={classes.containerdossier}>
            <Typography variant='h5'>{`${data.jeune?.nom?data.jeune?.nom:"-"} ${data.jeune?.prenom?data.jeune?.prenom:"-"}` }</Typography>
            <Typography>
            <span style={{fontWeight:'bold',fontStyle:'italic'}}>Né(e) le :</span> {`${data.jeune?.datenaissance?dayjs(data.jeune?.datenaissance).format('DD/MM/YYYY'):"-"} à : ${data.jeune?.lieunaissance?data.jeune?.lieunaissance:"-"}` } 
            </Typography>
            <Typography>
                <span style={{fontWeight:'bold',fontStyle:'italic'}}>Reside à :</span> {`${data.jeune?.lieuresidence ? data.jeune?.lieuresidence:"-"} - ${(data.jeune?.commune && data.jeune?.commune!=='null')? data.jeune?.commune: "-"} / ${data.jeune?.quartier?data.jeune?.quartier:"-"} / ${data.jeune?.cgqcgv?data.jeune?.cgqcgv:"-"}` } 
            </Typography>
            <Typography> <span style={{fontWeight:'bold',fontStyle:'italic'}}>Date creation: </span>{dayjs(data.created_at).format('DD/MM/YYYY')}</Typography>
            <Typography>{`${data.jeune?.naturepiece?data.jeune?.naturepiece: 'CNI '} N° ` }:{`${data.jeune?.numeropiece?data.jeune?.numeropiece:'-'}` } </Typography>
        </div>
    </div>
    )
}

export default Dossier