import { getStorage,ref,getDownloadURL} from "firebase/storage";
import { 
    getFirestore, 
    addDoc,
    collection,
    query, 
    doc, 
    setDoc,
    getDocs,
    getDoc,
    where,
    Timestamp,
    orderBy,
    limit,
    deleteDoc
} from "firebase/firestore";
import { firebase } from "lib/firebase.prod";
import axios from "lib/axios";


//gestion des partenaires
export const addpartenaire = async (firebase,dataSend) => {
    const db = getFirestore(firebase)
    if(dataSend){
        dataSend.dateajout =Timestamp.fromDate(new Date())
      const docRef = await addDoc(collection(db, "partenaires"), dataSend);
      if(docRef){
        return {error:false,data:docRef.id}
      }else{
        return {error:true,message:"Une erreur s'est produit lors de l'enregistrement"}
      }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}
export const getAllpartenaires = async (firebase) => {
    const db = getFirestore(firebase)
    const docSnap = await getDocs(collection(db, "partenaires"));
    if (docSnap) {
        const currentdata =[]
        docSnap.forEach(doc => {
            currentdata.push({...doc.data(),id:doc.id})
        });
        return {error:false,data:currentdata}
    } else {
        return {error:true,message:"Aucune données trouvées"}
    }
}

export const getpartenaires = async (id) => {

    const db = getFirestore(firebase)
    const docRef = doc(db, "partenaires", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const currentdata ={...docSnap.data(),id:docSnap.id}
        return {error:false,data:currentdata}
    } else {
        return {error:true,message:"Aucune données trouvées"}
    }
}

export const deletePartenaire =async (id)=>{
    const db = getFirestore(firebase)
    if(id){
        try {
            await deleteDoc(doc(db, "partenaires",id));
            return {error:false}
        } catch (error) {
            return {error:true,message:"Erreur inconu lors de la supression"} 
        }
    }else{
        return {error:true,message:"Paramètre incorrect"}
    }
}
export const updatepartenaire = async (firebase,dataSend) => {
    const db = getFirestore(firebase)
    if(dataSend){
       try {
        await setDoc(doc(db, "partenaires", dataSend['id']), dataSend,{ merge:true });
        return {error:false,data:[]}
       } catch (error) {
        return {error:true,message:"Erreur inconnu lors modification"} 
       }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}
export const getRefTable =async (firebase,table,field)=>{
    
    const db = getFirestore(firebase)
    const q = query(collection(db, table), orderBy(field,"desc"), limit(1));
    const querySnapshot = await getDocs(q);
   
    if(querySnapshot && querySnapshot.lenght!==0){
        let  cmyCode 
         querySnapshot.forEach(doc=>{
            if(doc){
                const refCode = doc.data()[field]
                const spCodes = refCode.split('-')
                const myCode = spCodes[spCodes.length-1]
                cmyCode= myCode?(Number(myCode)+1):1;
            }else{
                cmyCode= 1;
            }
        })
      if(cmyCode){
        return cmyCode  
      }
        
    }else{
        console.log("Erreur");
    }
}

//gestion des offres
export const addOfres = async (firebase,dataSend)=>{

    const db = getFirestore()
    if(dataSend){
        dataSend.dateajout =Timestamp.fromDate(new Date())
      const docRef = await addDoc(collection(db, "offres"), dataSend);
      if(docRef){
        return {error:false,data:docRef.id}
      }else{
        return {error:true,message:"Une erreur s'est produit lors de l'enregistrement"}
      }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}

export const getOfresPartenaires = async (partenaire) => {
   try {
    const db = getFirestore(firebase)
    const q = query(collection(db, "offres"), where("partenaire", "==", partenaire));
    const querySnapshot = await getDocs(q);
    const currentdata =[]
    querySnapshot.forEach((doc) => {
        currentdata.push({...doc.data(),id:doc.id})
    });
    return {error:false,data:currentdata}
   } catch (error) {
    return {error:true,message:error}
   }
}

export const dernieresOffres =async ()=>{
    const db = getFirestore(firebase)
    const q = query(collection(db, "offres"), orderBy("datedebut"), limit(10));
    const querySnapshot = await getDocs(q);
   
    if(querySnapshot && querySnapshot.lenght!==0){
        const currentdata =[]
         querySnapshot.forEach(doc=>{
            if(doc){
                currentdata.push({...doc.data(),id:doc.id})
            }else{
                return {error:true,message:"Aucune donnée trouvé"}
            }
        })
        return {error:false,data:currentdata}  
    }else{
        return {error:true,message:"Erreur lors de l'opération"}
    }
}
export const updateoffre = async (firebase,dataSend) => {
    const db = getFirestore(firebase)
    if(dataSend){
       try {
        await setDoc(doc(db, "offres", dataSend['id']), dataSend,{ merge:true });
        return {error:false,data:[]}
       } catch (error) {
        return {error:true,message:"Erreur inconnu lors modification"} 
       }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}
export const deleteOffre =async (id)=>{
    const db = getFirestore(firebase)
    if(id){
        try {
            await deleteDoc(doc(db, "offres",id));
            return {error:false}
        } catch (error) {
            return {error:true,message:"Erreur inconu lors de la supression"} 
        }
    }else{
        return {error:true,message:"Paramètre incorrect"}
    }
}
//gestion des dossier
export const adddossier = async (dataSend) =>{
    const db = getFirestore(firebase)
    if(dataSend){
      const docRef = await addDoc(collection(db, "dossiers"), dataSend);
      if(docRef){
        return {error:false,data:docRef.id}
      }else{
        return {error:true,message:"Une erreur s'est produit lors de l'enregistrement"}
      }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}

export const getAllDossier = async (firebase) => {
    const db = getFirestore(firebase)
    const docSnap = await getDocs(collection(db, "dossiers"));
    if (docSnap) {
        const currentdata =[]
        docSnap.forEach(doc => {
            currentdata.push({id:doc.id,...doc.data()})
        });
        return {error:false,data:currentdata}
    } else {
        return {error:true,message:"Aucune données trouvées"}
    }
}



export const adddemande = async (dataSend) =>{
    const db = getFirestore(firebase)
    if(dataSend){
      const docRef = await addDoc(collection(db, "demandes"), dataSend);
      if(docRef){
        return {error:false,data:docRef.id}
      }else{
        return {error:true,message:"Une erreur s'est produit lors de l'enregistrement"}
      }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}

export const adddocuments = async (dataSend) =>{
    const db = getFirestore(firebase)
    if(dataSend){
      const docRef = await addDoc(collection(db, "documents"), dataSend);
      if(docRef){
        return {error:false,data:docRef.id}
      }else{
        return {error:true,message:"Une erreur s'est produit lors de l'enregistrement"}
      }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}

export const  getUrlFile = async (reffile) =>{
    const storage = getStorage()
    let urls = getDownloadURL(ref(storage,reffile))
    return await Promise.resolve(urls);
    
}


export const selectWidthParam =async (table,field,op,fieldv)=>{
   
    try {
        const db = getFirestore(firebase)
        const q = query(collection(db, table), where(field,op,fieldv));
        const querySnapshot = await getDocs(q);
        const currentdata =[]
        querySnapshot.forEach((doc) => {
            currentdata.push({...doc.data(),id:doc.id})
        });
        return {error:false,data:currentdata}
       } catch (error) {
        return {error:true,message:error}
       }
}

export const getAll = async (table) => {
    const db = getFirestore(firebase)
    const docSnap = await getDocs(collection(db, table));
    if (docSnap) {
        const currentdata =[]
        docSnap.forEach(doc => {
            currentdata.push({...doc.data(),id:doc.id})
        });
        return {error:false,data:currentdata}
    } else {
        return {error:true,message:"Aucune données trouvées"}
    }
}
export const getbyid = async (table,id) => {
    console.log(id);
     const db = getFirestore(firebase)
     const docRef = doc(db, table, id);
     const docSnap = await getDoc(docRef);
     if (docSnap.exists()) {
         const currentdata ={...docSnap.data(),id:docSnap.id}
         return {error:false,data:currentdata}
     } else {
         return {error:true,message:"Aucune données trouvées"}
     }
}
export const update = async (table,dataSend) => {
    const db = getFirestore(firebase)
    if(dataSend){
       try {
        await setDoc(doc(db, table, dataSend['id']), dataSend, { merge:true });
        return {error:false,data:[]}
       } catch (error) {
        return {error:true,message:"Erreur inconnu lors de modification"} 
       }
    }else{
        return {error:true,message:"Paramètres fournie incorrect"}
    }
}


export const getDernierDossier =()=>{
    return axios.get(`/api/last-dossiers`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const getDossierByStatut =()=>{
    return axios.get(`/api/statut-dossier`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}


//quartiers

export const getQuartierList =(params) =>{
    return axios.get('/api/quartier/list',params).then(res=>{
        if((res && res.data.status === 200 )|| res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const ajouterQuartier =(dataSend) =>{
    return axios.post('/api/quartier/add',dataSend).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const getQuartier =(codequartier) =>{
    return axios.get(`/api/quartier/${codequartier}`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const upQuartier =(codequartier,dataSend) =>{
    return axios.put(`/api/quartier/update/${codequartier}`,dataSend).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const deleteQuartier =(codequartier)=>{
    return axios.delete(`/api/quartier/${codequartier}`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}


//comités de gestions

export const getComitegestionList =(params) =>{
    return axios.get('/api/comites-gestions',params).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const adComiteGestion =(dataSend) =>{
    return axios.get('/api/add-comite-gesion',dataSend).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const getComitegestion =(code) =>{
    return axios.get(`/api/comite-gestion/${code}`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const updateCommitegestion =(code,dataSend) =>{
    return axios.put(`/api/update-comite-gestion/${code}`,dataSend).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}

export const deleteComitegestion =(code)=>{
    return axios.delete(`/api/delete-comite-gestion/${code}`).then(res=>{
        if((res && res.data.status === 200) || res.data.status === 201){
            return res.data.data
        }else if (res.status === 401)  {
            console.log(res.data.message);
            return false
        }else{
            console.log(res.data.message);
            return false
        }
    })
}
