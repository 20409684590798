import React,{useEffect,useState} from 'react'
import Table from 'react-bootstrap/Table';
import {Pagination} from "@mui/material";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {Typography,Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Chip from '@mui/material/Chip';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import "./styles/ComiteGestionList.scss"
import { Form, InputGroup} from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'lib/axios';


function ComiteGestionList(props) {
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const navigate =useNavigate()
    const [loading,setLoading] =useState(false)
    const [comites,setComites] = useState([])
    const [search,setSearch] = useState(null)
    const [paginateData,setPagnateData] = useState(null)

    const handleComiteList = (numberPage = 1)=>{
        setLoading(true)
        const url =search?`/api/comite/list/${search}?page=${numberPage}` : `/api/comite/list?page=${numberPage}` 
        axios.get(url).then(res=>{
            if(res.status ===200 || res.status === 201){
                setLoading(false)
                setComites(res.data.data)
                setPagnateData({
                    current_page:res.data.meta?.current_page,
                    last_page:res.data.meta?.last_page,
                    per_page:res.data.meta?.per_page,
                    total:res.data.meta?.total
                  })
            }else{
                console.log(res.data)
            }
            
        }).catch(err=>{
            console.log(err?.response)
        })
    }
  useEffect(()=>{
    handleComiteList()
    },[])
   

const handleDelete =(id)=>{
    setLoading(true)
    axios.delete('/api/comite/delete/'+id).then(res=>{
        if(res.status ===200 && res.status === 201){
            swal("Bien!","Suppression effectué avec succès",'succes')
            handleComiteList()
        }else{
            setLoading(false)
            swal("Ooops !","Problème lors de l'opération",'error')
        }
    }).catch(err=>{
        console.log(err)
        setLoading(false)
    })
}

  return (
    <div className="comites">
       <div className='titlebox'>
            <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Gestion des CGQ /CGV</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={(e)=>navigate(`/${portail}/comite/nouveau`)}>
                <AddIcon/>Ajouter un comité de gestion
            </Button>
        </div>
        <div className="bodybox">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(nom,quartier.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={()=>handleComiteList(1)}>
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                 <Chip label={`Total(s) : ${(paginateData&&paginateData.total)?paginateData.total:""}`} variant="outlined" />
                 <Table>
                    <thead>
                        <tr>
                            <th>CGQ/CGV</th>
                            <th>Quartier</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(comites && comites.length!==0)?comites.map((comite) => (
                            <tr key={comite.id}>
                                <td>{comite.nom}</td>
                                <td>{comite.quartier?.nom}</td>
                                <td>{comite.type}</td>
                                <td>
                                    <div>
                                        <IconButton aria-label="edit" onClick={(e)=>navigate(`/${portail}/comite/modifier/${comite.id}`)} >
                                            <ModeEditIcon sx={{fontSize:40}}/>
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={(e)=>handleDelete(comite.id)} >
                                            <DeleteIcon sx={{fontSize:40}}/>
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        )):<tr><td colSpan={4}><p>Aucune trouvée</p></td></tr>}
                    </tbody>
                </Table>
                {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleComiteList(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
            </Paper>
        </div>        
    </div>
  )
}

export default ComiteGestionList
