
import React from "react";
import {Container, Base,InputField,SelectField,CheckBoxField,RadioField,Button,Group,RButton,Hr,Error,Label} from './styles/form'

export default function Form({children,...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}


Form.Base = function FormBase({children,...restProps}){
    return (<Base {...restProps}>{children}</Base>)
}
Form.Group = function FormBGroup({children,...restProps}){
    return (<Group {...restProps}>{children}</Group>)
}

Form.Input = function FormInputField({children,...restProps}){
    return (<InputField {...restProps}>{children}</InputField>)
}

Form.Select = function FormSelectField({children,...restProps}){
    return (<SelectField  {...restProps}>{children}</SelectField>)
}

Form.CheckBox = function FormCheckBoxField({children,...restProps}){
    return (<CheckBoxField type='checkbox' {...restProps} />)
}

Form.CheckRadio = function FormRadioField({children,...restProps}){
    return (<RadioField type='radio' {...restProps} />)
}


Form.Button = function FormButton({children,...restProps}){
    return (<Button {...restProps}>{children}</Button>)
}

Form.RButton = function FormRButton({children,...restProps}){
    return (<RButton {...restProps}>{children}</RButton>)
}
Form.Hr = function FormHr({children,...restProps}){
    return (<Hr  {...restProps} />)
}
Form.Error = function FormError({ children, ...restProps }) {
    return <Error {...restProps}>{children}</Error>;
  };
  

Form.Label = function FormLabel({children,...restProps}){
    return (<Label {...restProps}>{children}</Label>)
}

  