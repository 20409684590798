import React from "react";
import {Container,Inner,Base,Item,Link,Icon,Texte,Button,Logo,Group,ButtonAdd} from './styles/sidebar'
export default function SideBar({children, ...restProps}) {
    return (<Container {...restProps}>{children}</Container>)
}

SideBar.Inner = function SideBarInner({children,...restProps}){
    return (<Inner {...restProps}>{children}</Inner>)
}

SideBar.Base = function SideBarBase({children,...restProps}){
    return (<Base {...restProps}>{children}</Base>)
}

SideBar.Item = function SideBarItem({children,...restProps}){
    return (<Item {...restProps}>{children}</Item>)
}

SideBar.Link = function SideBarLink({to,children,...restProps}){
    return (<Link to={to} {...restProps}>{children}</Link>)
}

SideBar.Texte = function SideBarTexte({children,...restProps}){
    return (<Texte {...restProps}>{children}</Texte>)
}

SideBar.Logo = function SideBarLogo({src,...restProps}){
    return (<Logo src={src} {...restProps} />)
}

SideBar.Button = function SideBarButton({children,...restProps}){
    return (<Button {...restProps}>{children}</Button>)
}

SideBar.ButtonAdd = function SideBarButtonAdd({children,...restProps}){
    return (<ButtonAdd {...restProps}>{children}</ButtonAdd>)
}


SideBar.Group = function SideBarGroup({children,...restProps}){
    return (<Group {...restProps}>{children}</Group>)
}