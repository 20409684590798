import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonsContext } from 'context/CommonsContext'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { CircularProgress, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Form } from 'components'
import RefreshIcon from '@mui/icons-material/Refresh';
import { makePassword,generateLogin, emailRegex } from 'lib/application';
import axios from 'lib/axios'
import { useSelector } from 'react-redux'

const FicheGenerateUserAccount = () => {
  const [loading,setLoading] =  useState(false)
  const {handleCommonsdata} = useContext(CommonsContext)
  const [inputField,setInputField] = useState({})
  const handleChange =(e)=>{
    e.preventDefault()
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  useEffect(()=>{
    handleCommonsdata('titre',"Dossier - Généreration de compte")
  },[])
  const location =  useLocation()
  const [dossier,setDossier] =  useState(location.state?.dossier)
  const handleConnexionData = useCallback(async ()=>{
    if(dossier){
        setInputField({
          ...inputField,
          'password':makePassword(8),
          'login':await generateLogin(dossier.jeune?.nom,dossier.jeune?.prenom),
          'email':dossier.jeune?.email,
          'nom':dossier.jeune?.nom,
          'prenom':dossier.jeune?.prenom,
        })
        
    }
  },[dossier])
  const [profil,setprofil] = useState(null)
  const handleDefaultProfil = ()=>{
    axios.get(`/api/profile/list/jeune?page=1`).then(res=>{
      if(res.status===200 || res.status ===201){
        setprofil(res.data?.data[0])
      }
    })
  }

  const [structure,setStructure] = useState(null)
  const handleDefaultStructure = ()=>{
    if(dossier && dossier.jeune.cgqcgv){
      axios.get(`/api/comite/list/${dossier.jeune.cgqcgv}?page=1`).then(res=>{
        if(res.status===200 || res.status ===201){
          setStructure(res.data?.data[0])
        }
      })
    }
  }
  useEffect( ()=>{
    handleConnexionData()
    handleDefaultProfil()
    handleDefaultStructure()
  },[dossier])

  const handleInitLogin =async()=>{
    setInputField({...inputField,'login':await generateLogin(dossier.jeune?.nom,dossier.jeune?.prenom)})
  }
  const handleInitPassword =async ()=>{
    setInputField({...inputField,'password':makePassword(8)})
  }
  const navigate =useNavigate()
  const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
  const handlelist = ()=>{
      navigate(`/${portail}/dossiers`)
  }

  const [errors,setErrors] = useState(null)
  const handleSaveParams =(e)=>{
        e.stopPropagation()
        e.preventDefault()
        const cerrors =[]
        if(!inputField.login || inputField.login.trim() ==''){
          cerrors.push("Login incorrect")
        }
        if(!inputField.password || inputField.password.trim() =='' || inputField.password.length<8){
          cerrors.push("Mot de passe incorrect")
        }
        if(!inputField.email || inputField.email.trim() =='' || !emailRegex.test(inputField.email)){
          cerrors.push("Adresse email inccorect")
        }
        if(cerrors && cerrors.length !==0){
          setErrors([...cerrors])
          return
        }

        const dataSend = {
          credentials:{
            email:inputField.email,
            name:inputField.nom +" "+inputField.prenom,
            username:inputField.login,
            password:inputField.password,
            password_confirmation:inputField.password,
            profil_id:profil?.id,
        },
        personne:{
          id:dossier.jeune?.id,
          civilite:dossier.jeune?.civilite,
          nom:dossier.jeune?.nom,
          prenom:dossier.jeune?.prenom,
          datenaissance:dossier.jeune?.datenaissance
        },
        dossier:{
          id:dossier.id,
          code:dossier.code,
        },
        structure:{
          id:structure?.id
        }
      }
   
      axios.post(`/api/users/create`,dataSend).then(res=>{
        if(res.status!==200 || res.status!==201){
            swal("Bien !","Compte crée avecsuccès","success")
            setLoading(false)
            handlelist()
        }else{
            console.log(res.data);
            setLoading(false)
            swal("Ooops !","Erreur lors de l'enregistrement","error")
        }
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        swal("Ooops !","Erreur lors de l'enregistrement","error")
    })
  }

  return (
    <div className='my-4'>
      <div className='rounded shadow-lg bg-white md:max-w-screen-sm max-w-md mx-auto '>
      {dossier&&
          <div className='flex flex-row justify-between bg-green-100 p-2'>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>N° :</span><span>{dossier['code'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date dossier :</span><span>{dayjs(dossier['datedossier']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Date création :</span><span>{dayjs(dossier['created_at']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Objet :</span><span>{dossier['objet'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Statut :</span><span>{dossier['statut'] || ""}</span></div>     
              </div>
              <div className='flex flex-col space-y-1 border-y-2 border-l-2 border-y-black p-2 rounded-md'>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Nom :</span><span>{dossier['jeune']['nom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Prenom :</span><span>{dossier['jeune']['prenom'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) le :</span><span>{dayjs(dossier['jeune']['datenaissance']).format('DD/MM/YYYY') || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Né(e) à :</span><span>{dossier['jeune']['lieunaissance'] || ""}</span></div>
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Réside à :</span><span>{dossier['jeune']['lieuresidence'] || ""}</span></div>     
                  <div className='space-x-2'><span className='font-bold text-black italic mr-2'>Quartier :</span><span>{dossier['jeune']['quartier'] || ""}</span></div>     
              </div>
          </div>}
       
          <div className='p-2'>
            <form onSubmit={handleSaveParams}>
              <Paper className='my-4 p-2 pb-4'>
                  <Typography variant='h5'>Paramètres de connexion</Typography>
                  <Divider />
                  {(errors && errors.length!==0)&&<div className='bg-red-100 text-red-700 font-bold p-2 flex flex-col justify-center rounded-md my-2 mx-2'>
                      {errors.map(er=>(<span className='text-sm'>*{er}</span>))}
                  </div>}
                  <div>
                        <Form.Input 
                                size="small" 
                                margin="dense" 
                                style={{marginRight:20,marginBottom:20}}  
                                label="email"
                                required 
                                wd='100%'
                                name='email'
                              
                                type='email' 
                                value={inputField?.email}
                                onChange={handleChange}
                            />
                            <Form.Group row direction='row'>
                                <div style={{display:'flex'}}>
                                    <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                                        <span style={{fontSize:16,margin:20}}>Nom d'utilisateur : </span>
                                        <span style={{fontSize:18,margin:20, fontWeight:'bold'}} >{inputField['login']||""}</span>
                                    </div>
                                    <Tooltip title="Régénérer le nom d'utilisateur">
                                        <IconButton onClick={handleInitLogin}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Form.Group>
                            <Form.Group row>
                                <div style={{display:'flex'}}>
                                    <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                                        <span style={{fontSize:16,margin:20}}>Mot de passe  : </span>
                                        <span style={{fontSize:18,margin:20, fontWeight:'bold'}}>{inputField['password']||""}</span>
                                    </div>
                                    <Tooltip title="Réinitialisé le mot de passe">
                                        <IconButton onClick={handleInitPassword}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                
                            </Form.Group>
                    </div>
              </Paper>
              <Paper className='my-4 p-2 pb-4'>
                  

                  <div className='flex flex-row justify-between items-center'>
                    <Form.Button type='reset' >Annuler</Form.Button> 
                      <Form.Button disabled={loading} type="submit" primary>
                          {loading ? <CircularProgress /> : "Enregistrer"} 
                      </Form.Button> 
                  </div>
              </Paper>
              </form>
          </div>
      </div>
    </div>
  )
}

export default FicheGenerateUserAccount