import React from 'react'
import logo from "assets/logo.jpg"
import {Form} from 'components'
import {Homer} from 'components'
import 'pages/frontend/bootstrap.min.css'
import 'pages/frontend/style.css'
import { Link } from 'react-router-dom'
function Page404() {
  return (
    <Homer>
       <Homer.Frame flex={0.3} style={{marginBottom:10}} >
       <Homer.Inner>
              <Homer.Link to="/">
                  <div style={{position:'absolute',borderRadius:5,width:80}} >
                    <img src={logo} alt='Logo' style={{width:100,borderRadius:10,objectFit: "fill",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}} />
                  </div>
              </Homer.Link>
            </Homer.Inner>
       </Homer.Frame>
       <Homer.FGradient flex={2}>
            <div className="relative flex items-top justify-center
        min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0">
          <div className="max-w-xl mx-auto sm:px-6 lg:px-8">
            <div className="flex items-center pt-8 sm:justify-start sm:pt-0">
              <div className="px-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider">
                404
              </div>
              <div className="ml-4 text-lg text-gray-500 uppercase tracking-wider">
                Not Found
              </div>
            </div>
          </div>
        </div>
       </Homer.FGradient>
     
      <Homer.Frame flex={0.3} style={{marginTop:10}}>
            <Homer.Inner  palign="center">
                    Copyright &#169; { new Date().getFullYear() } - Tous droits réservés Mairie de Port-Bouet
            </Homer.Inner>
        </Homer.Frame>
    </Homer>
  )
}

export default Page404
