import  React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Card} from 'components'
import Dash from 'components/dash'
import { Divider, Typography } from '@mui/material';
import Evolution from 'pages/backend/dashbord/Evolution';
import TauxTrtDemande from 'pages/backend/dashbord/TauxTrtDemande';
import {cg} from 'fixtures/data'
import { Form } from 'components'
import MenuItem from '@mui/material/MenuItem';
function createData(name, nombre) {
  return { name, nombre}
}


const rows = [
    createData('Nombre de jeunes accueillis', 35),
    createData('Nombre de jeunes profilés', 40),
    createData('Nombre de jeunes accompagnés individuellement', 60),
    createData('Nombre d’émissions radio/télévision pour la sensibilisation et mobilisation', 35),
    createData('Nombre de jeunes orientés vers le pôle entreprenariat', 15),
    createData('Nombre de jeunes orientés vers le pôle qualification', 35),
    createData('Nombre de partenaires potentiels rencontrés', 200)
  ];

  const rows2 = [
    createData('Nombre  jeunes mis en activités dans la Régie des Services Territoriaux', 106),
    createData('Nombre de jeunes mis en apprentissage de qualification CAP Emploi', 124),
    createData('Nombre de jeunes insérés dans un emploi salarié avec un contrat de travail de plus de trois mois', 46),
    createData('Nombre de jeunes mis en stage école', 35),
    createData('Nombre de jeunes insérés dans un emploi salarié ( essentiellement par la Mairie)', ""),
    createData('Nombre de jeunes orientés vers le pôle qualification', 35)
  ];
  const rows3 = [
    createData("Nombre de jeunes formés en \"CREE\" ou autre module de formation à la création d'entreprise", 50),
    createData('Nombre de plans d’affaires élaborés', 50),
    createData('Nombre de personnes formées en "Germe" ou autres modules de gestion', 60)
  ];
  
function Etat(props) {
  const handleSelectQuartier =(qt)=>{}
  const [currentcg,setCurrentcg]=React.useState([])
  const {cgv,setCgv} =React.useState("")
  const [quartierresidence,setQuartierresidence] = React.useState(null)
  const handlefiltercd =()=>{
    if(quartierresidence){
      const newcg = cg.filter(item=>item.quartier ===quartierresidence)
      if(newcg && newcg.lenght!==0){
        setCurrentcg(newcg)
      }else{
        setCurrentcg([])
      }
    }else{
      setCurrentcg(cg)
    }
  }
  React.useEffect(()=>{
    handlefiltercd()
  },[quartierresidence])
  return (
    <div>
      <Card>
        <Form.Input 
            size="small" 
            margin="dense" 
            select 
            style={{marginRight:20}}  
            label="CGQ/CGV"
            value={cgv}
            name='cgvcgq'
            onChange ={(e)=>setCgv(e.target.value)}
            >
              <MenuItem  value='No'></MenuItem>
              {(currentcg && currentcg.lenght!=0)&&currentcg.map(cg=>(<MenuItem value={cg.denomination}>{cg.denomination}</MenuItem>))}
                  
        </Form.Input>
      </Card>
      <Card style={{marginBottom:20}}>
        <Dash.Inner direction='row' style={{marginTop:20,marginRight:20}}>
          <Dash.Frame elevation={3} radius={0} style={{padding:20,marginLeft:0}}>
              <Typography variant='h5'>Evolution des demande</Typography>
              <Divider />
                <Evolution />
          </Dash.Frame>
          <Dash.Frame elevation={3} salign='center' radius={0} style={{padding:20}}>
                <Typography variant='h5'>Taux de satisfaction des demandes</Typography>
              <Divider />
              <TauxTrtDemande />
        
          </Dash.Frame> 
      </Dash.Inner>
      </Card>
        <Card>
          <Card.Header>
              <Card.Title>Information, Accueil / Identification et Orientation des jeunes</Card.Title>
              <Card.Hr />
          </Card.Header>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.nombre}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Card>
        <Card>
          <Card.Header>
              <Card.Title>Accompagnement des jeunes vers l’Emploi salarié</Card.Title>
              <Card.Hr />
          </Card.Header>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        
        <TableBody>
          {rows2.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <Typography>{row.name}</Typography>
              </TableCell>
              <TableCell align="right"> <Typography variant='h6'>{row.nombre}</Typography></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

        </Card>
        <Card>
          <Card.Header>
              <Card.Title>Accompagnement des jeunes vers l’Entrepreneuriat ou l’Auto Emploi</Card.Title>
              <Card.Hr />
          </Card.Header>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        
        <TableBody>
          {rows3.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography variant='h6'>{row.name}</Typography>
              </TableCell>
              <TableCell align="right"> <Typography variant='h5'>{row.nombre}</Typography></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Card>
        
    </div>
  )
}

export default Etat