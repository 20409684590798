
import React,{useState,useContext, useEffect} from 'react'
import { Card } from 'components'
import { Form } from 'components'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import Checkbox from '@mui/material/Checkbox';
import { Autocomplete, Divider, FormControlLabel, MenuItem, Typography } from '@mui/material'
import { AddContext } from 'context/add'
import { pays,secteuractivites,niveauetudes,niveauexperience } from 'fixtures/data'
import axios from 'lib/axios';

const groupItems=[
  {
    name: "cursus",
    label: "Parcours scolaire",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "formation",
    label: "Formations",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "experience",
    label: "Expériences professionnelles",
    information: "",
    disabled: false
  }
]

function StepsCursus({dossier,handleBack,reflesh}) {
  const [parcours,setParcours] = useState([])
  
  const [cursusField,setCursusField] = useState({
    type:null,
    encours:false,
    datedebut:null,
    datefin:null,
    diplome:null,
    etablissement:null,
    mission:null,
  })

  const handleChangeCursus =(e)=>{
    e.preventDefault()
    const {name,value} =e.target
    setCursusField({...cursusField,[name]:value})
  }
 
const [cursusData,setCursusData] = useState([])
const [expData,setExpData] = useState([])

useEffect(()=>{
  if(parcours && parcours.length !== 0){
    setCursusData(parcours.filter(item=>(item.type === 'cursus' || item.type === 'formation')))
    setExpData(parcours.filter(item=>item.type === 'experience'))
  }else{
    setCursusData([])
    setExpData([])
  }
},[parcours])


const handleCursusData =(e)=>{
  e.preventDefault()

  let currentData ={...cursusField}
  if(currentData.type && currentData.diplome){
    let dataparcour = (parcours && parcours.length!==0) ? parcours:[]
    
    currentData['id'] = dataparcour.length+1

    dataparcour =[...dataparcour,currentData]
    setParcours([...dataparcour])
    setCursusField({
      type:null,
      encours:false,
      datedebut:null,
      datefin:null,
      diplome:null,
      etablissement:null,
      mission:null,
    })
  }
}
const handleCheckboxChange =(name)=>{setCursusField({...cursusField,[name]:!cursusField[name]})}

const handleDeleteCurssus =(id)=>{
  const currentparcour = [...parcours]
  if(currentparcour.length > 1){
    const newparcours = currentparcour.filter(item=>item.id!==id)
    setParcours([...newparcours])
  }else{
    setParcours([])
  } 
}
const handleSave =(e)=>{
  e.preventDefault()
  if(!parcours || parcours.length===0){
    swal("Ooops!","Veuillez ajouter au moins un parcours",'warning')
    return
  }
  let formdata = new FormData()
  formdata.append('parcours',JSON.stringify(parcours))
  axios.post(`/api/dossier/add-parcours/${dossier.id}`,formdata).then(res=>{
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
}
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
      
      <Card>
          <Form>
              <Form.Base>
                <Form.Group direction ='row' salign='center' flex={1}>
                  <RadioField
                      groupItems={groupItems}
                      group="Type de parcours"
                      checkedDefault="cursus"
                      appearance={radiobutton_appearances.primary}
                      onChange ={handleChangeCursus}
                      name='type'
                      vvalue ={cursusField.type}
                    />
                    <FormControlLabel control={ <Checkbox name='encours' onChange={(e)=>handleCheckboxChange('encours')}  color="success" />} label="En cours" />
                </Form.Group>
                <Form.Group direction ='row' flex={1}>
                  <DesktopDatePicker
                    label="Date de debut"
                    inputFormat="dd/MM/yyyy"
                    value={cursusField.datedebut}
                    name='datedebut'
                    onChange={(val)=>setCursusField({...cursusField,['datedebut']:val})}
                    renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                  />
                  <DesktopDatePicker
                    label="Date fin"
                    name='datefin'
                    inputFormat="dd/MM/yyyy"
                    value={cursusField.datefin}
                    onChange={(val)=>setCursusField({...cursusField,['datefin']:val})}
                    renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                  />
               </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input   name='diplome' value={cursusField.diplome} onChange={handleChangeCursus} size="small" margin="dense" label="Libelle diplome / titre" wd='100%' />
               </Form.Group>
               <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                  <Form.Input  size="small" name='etablissement' value={cursusField.etablissement} onChange={handleChangeCursus} margin="dense" label="Etablissement" wd='100%' />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                  <Form.Input  size="small" name='do_curmission' value={cursusField.mission} onChange={handleChangeCursus} margin="dense" label="Missions" wd='100%' />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' palign='flex-end' flex={1}>
                    <Form.RButton color='#556B2F' onClick={handleCursusData}>Ajouter</Form.RButton> 
               </Form.Group>
              </Form.Base>
             
          </Form>
          <Form.Hr style={{marginTop:20}} />
          
          {(cursusData && cursusData.length !== 0)&&<div style={{marginTop:20}}>
            <Card.Title>Cursus scolaire /formation</Card.Title>
            <Card.Hr />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {cursusData.map((cur,indx)=>{
                    return <TableRow key={cur.id}>
                        <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                        <TableCell align="left">{cur.diplome}</TableCell>
                        <TableCell align="left">{cur.etablissement}</TableCell>
                        <TableCell align="left">{cur.mission}</TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete" onClick={(e)=>handleDeleteCurssus(cur.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                  </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {(expData && expData.length !== 0)&&<div style={{marginTop:20}}>
            <Card.Title>Experiences Professionnelles</Card.Title>
            <Card.Hr />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {expData.map((cur,indx)=>{
                      return <TableRow key={cur.id}>
                          <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                          <TableCell align="left">{cur.diplome}</TableCell>
                          <TableCell align="left">{cur.etablissement}</TableCell>
                          <TableCell align="left">{cur.mission}</TableCell>
                          <TableCell align="center">
                            <IconButton aria-label="delete" onClick={(e)=>handleDeleteCurssus(cur.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                    </TableRow>
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          
      </Card>
      <Card direction='row' palign='space-between'>
        <Form.Button onClick={handleBack} >Retour</Form.Button>
           <Form.Button onClick={handleSave} primary>Enregistrer</Form.Button> 
      </Card>
      </div>
    </LocalizationProvider>
  )
}

export default StepsCursus