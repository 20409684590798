import React, { useState } from 'react'
import {Header} from 'components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MessageIcon from '@mui/icons-material/Message';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import swal from 'sweetalert'
import { useAuth } from "hooks/auth";
import { useSelector } from 'react-redux';
const options = [
  "Tous",
  'Dossiers',
  'Demandes',
  'Offres',
  'Partenaires',
];

function HeaderContainer() {
  //Menu
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const {user,logout} = useAuth({ middleware: 'auth' })
  const [searcheInputs,setSearchInputs] = useState({
    word:null,
    element:null
  })
  const handleClickListItem = (event) => {    
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index,op) => {
    setSearchInputs({...searcheInputs,"element":op})
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
   

      //deconnexion
      const handleDeconnexion =(event)=>{
      event.preventDefault();
      logout()
    }
    
    const handleSearch =(e)=>{
   
      if(!searcheInputs.word || searcheInputs.word ===""){
        swal("ooops!","Veuiller saisir un paramètre de recherche","warning")
        return
      }
      navigate(`/${portail}/recherches/resultats`,{state:{params:searcheInputs}})
    }
  return (
    <Header>
        <div className='grid grid-cols-12 gap-2'>       
          <div className='col-span-8'>

              <div className='bg-slate-100 flex flex-row rounded-lg  my-1 items-center'>
                <List component="nav" aria-label="cibles" sx={{ bgcolor: 'none',width:'40%'}}>
                  <ListItem id="lock-button" aria-haspopup="listbox" aria-controls="lock-menu" aria-label="Selectionnez une cible de recherche" aria-expanded={open ? 'true' : undefined} onClick={handleClickListItem}>
                    <ListItemText primary="Rechercher dans ..." secondary={options[selectedIndex]} />
                  </ListItem>
                </List>
                <Header.Input onChange={(e)=>setSearchInputs({...searcheInputs,'word':e.target.value})} type="text" placeholder="Nom,offre,numméro de dossier, ..." name="motcle" />
                <IconButton onClick={handleSearch} aria-label="search"><SearchIcon color="success" sx={{ fontSize: 25}} /></IconButton>
              </div>
        </div>
       
        <div className='col-span-4 flex flex-row justify-between items-center'>
                <Header.Link to="/">Plus de critères</Header.Link>
                <IconButton aria-label="search" color="success" >
                    <CalendarMonthIcon sx={{ fontSize: 25}} />
                </IconButton>
                <IconButton aria-label="search" color="success" >
                    <MessageIcon sx={{ fontSize: 25}} />
                </IconButton>
                <IconButton aria-label="search" color="success" >
                    <MailOutlineIcon sx={{ fontSize: 25}} />
                </IconButton>
                 <Header.Group direction='column' flex={1}>
                   <Typography variant='h6'>{user?.name} </Typography>
                   <Header.LightButton onClick={handleDeconnexion}>Deconnexion</Header.LightButton>
                </Header.Group>
        </div>
        </div>
        <Menu id="lock-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{'aria-labelledby': 'lock-button', role: 'listbox',}}>
                  {options.map((option, index) => (
                    <MenuItem key={option} value={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index,option)} style={{fontSize:18}}>
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
                
    </Header>
  )
}

export default HeaderContainer