import { green } from "@mui/material/colors";
import axios from "./axios";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import dayjs from "dayjs";
import * as XLSX from 'xlsx'

export const makePassword = (length)=> {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result.toLowerCase();
}

export const generateLogin = async (nom,prenom)=>{
    if(nom && prenom){
        const currentName = nom.split(" ")[0].toLowerCase().replace(/\s/g, "").sansAccents()
        const currentFirstName = prenom.split(" ").slice(-1)[0].toLowerCase().replace(/\s/g, "").sansAccents();
        let userExist = true
        let taille =1
        let login
        while (userExist) {
            login=`${currentName.substring(0,taille)}.${currentFirstName}`
            userExist = await axios.get(`/api/users/check-exist/${login}`).then(res=>{return res.data[0]})
            taille++
        }
        return login
    }else {
        return null
    }
}

String.prototype.sansAccents = function() {
    return this.replace(/[ùûü]/g,"u").replace(/[îï]/g,"i").replace(/[àâä]/g,"a").replace(/[ôö]/g,"o").replace(/[éèêë]/g,"e").replace(/ç/g,"c").replace(/'/g,"");
 }

 export const splitName = (namecompact,serarateur=' ')=>{
    const tabname = namecompact.split(serarateur);
    const nom = tabname[0]
    const prenom = tabname.slice(1).join(' ')
    return {nom,prenom}
 }

 export const splitPhoneNumer = (phone)=>{
    const allphone = phone.replace(/\s/g, '').replace('-', '').replace(' - ', '').replace('_', '');
    let index=1
    let mobvalue=""
    let phones ={}
    for (let i = 0; i <= allphone.length; i++) {
        mobvalue += allphone.indexOf(i).toString()
        if(mobvalue.length === 10){
            phones[`mobile${index}`] = mobvalue
            mobvalue =""
            index+=1
        }else if(i === allphone.length-1){

        }
    }
    return phones
 }

 export const getCiviliteFromSex =(sex)=>{
    let civilite ="M"
    switch (sex) {
        case 'F':
        case 'Femme':
        case 'femin':
            civilite='Mme'
            break;
        case 'M':
            case 'Masculin':
            case 'masculin':
            case 'Homme':
            case 'homme':
                civilite='M'
                break
        default:
            civilite='Mlle'
            break;
    }
    return civilite
 }

 export const getTypeDemandeExport  = async (demande) => {
    const url = demande ? `/api/type-demande/list/${demande}` : `/api/type-demande/all`
    let ll = await axios.get(url).then(res=>res.data.data)
    if(ll && ll.length !==0){
        return ll[0]
    }else{
        return null
    }
   
 }

 export const getMois = (index) =>{
    const moisEnLettre = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"];
        return moisEnLettre[index];
 }

 export const fichieInscription =(data)=>{
  
    let doc = new jsPDF('p','px','a4');
    let pageSize = doc.internal.pageSize
    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    let pageHeight =  pageSize.height ? pageSize.height : pageSize.getHeight()
    let img = new Image(40, 10)
    img.src = require('assets/logo.jpg')
    doc.addImage(img, "JPEG", 10, 10, 50, 40);
    
     doc.setFontSize(16)
     doc.text((pageWidth/3), 25, "FICHE D'INSCRIPTION ")
     doc.setFontSize(7)
     doc.text(pageWidth-45, 15,  new Date().toLocaleDateString())
    let source = <div style={{width:pageWidth-45}}>
        <div style={{display:'flex',flexDirection:'space-between'}}>
            <div style={{fontSize:8,width:'40%',textAlign:'left'}}><span>N° d'identification :</span><strong> {data&&data['code']}</strong> </div>

            <div style={{display:'flex',flexDirection:'row', fontSize:8,width:'60%',textAlign:'center'}}> 
                <span style={{fontWeight:'bold'}}>Handicapé physique :</span> 
                <div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                    <input style={{height:10}} type="radio" checked={(data && data['esthandicape'] ==='O')} name='esthandicape' /><label>Oui</label>
                    <input style={{height:10,marginLeft:15}} type="radio" checked={(data && data['esthandicape'] ==='N')} name='esthandicape' /><label>Non</label></div>  
                </div>
            </div>
        <div style={{display:'flex',flexDirection:'space-between'}}> 
            <div style={{fontSize:8,width:'40%',textAlign:'left'}}></div>
            <div style={{display:'flex',flexDirection:'row', fontSize:8,width:'60%',textAlign:'center'}}>
                <span style={{fontWeight:'bold'}}>Nature de l'handicap :</span> <span>{data && data['naturehandicap'] }</span> 
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'space-between'}}> 
            <div style={{fontSize:8,width:'40%',textAlign:'left'}}><span>CGQ/CGV : </span><span>{data && data['cgqcgv'] }</span></div>
        </div>
        <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
            <tr><th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Indentification</th></tr>
            <tr>
                <td><strong>Nom & prénoms :</strong> {`${(data && data.hasOwnProperty('jeune')) && data.jeune['nom'] } ${(data && data.hasOwnProperty('jeune')) && data.jeune['prenom'] }`}</td> 
                <td>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <strong>Sexe :</strong> 
                        <label>M</label><input style={{height:10,marginRight:10}} type="radio" checked={((data && data.hasOwnProperty('jeune')) && data.jeune['genre'] ==='Homme' )} name='sexe' />
                        <label>F</label><input style={{height:10}} type="radio" checked={((data && data.hasOwnProperty('jeune')) && (data.jeune['genre'] ==='Femme') )} name='sexe' />
                    </div>
                </td>
            </tr>
            <tr>
                <td><strong>Nom du père :</strong> <span> {(data && data.hasOwnProperty('jeune')) && data.jeune['nompere'] }</span></td>
                <td><strong>Nom de la mère :</strong> <span>{(data && data.hasOwnProperty('jeune')) && data.jeune['nommere'] }</span></td>
            </tr>
            <tr>
                <td colSpan={2}><strong>Nature de la pièce : </strong><span>{(data && data.hasOwnProperty('jeune')) && data.jeune['numeropiece'] }</span> <strong> N° de la pièce :</strong><span>{data && data['naturepiece'] }</span></td>
            </tr>
            <tr>
                <td><strong>Date de naissance : </strong><span>{(data && data.hasOwnProperty('jeune')) && dayjs(data.jeune['datenaissance']).format('DD/MM/YYYY') }</span></td> 
                <td><strong> Lieu de naissance : </strong><span>{(data && data.hasOwnProperty('jeune')) && data.jeune['lieunaissance']}</span></td>
            </tr>
            <tr>
                <td colSpan={2}> 
                    <div style={{display:'flex',alignItems:'center'}}><strong>Situation matrimoniale :</strong>
                        <input style={{height:10}} type="radio" checked={(data && data['sitmatrimoniale'] ==='Marié(e)')} name='sitmatrimoniale' /><label>Marié(e)</label>
                        <input style={{height:10, marginLeft:10}} checked={(data && data['sitmatrimoniale'] ==='Concubin(e)')} type="radio" name='sitmatrimoniale' /><label>Concubin(e)</label>
                        <input style={{height:10, marginLeft:10}} checked={(data && data['sitmatrimoniale'] ==='Célibataire')} type="radio" name='sitmatrimoniale' /><label>Célibataire</label>
                        <input style={{height:10, marginLeft:10}} checked={(data && data['sitmatrimoniale'] ==='Veuf')} type="radio" name='sitmatrimoniale' /><label>Veuf</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={2}><strong>Nombre d'enfants à charge : /</strong><span>{(data && data.hasOwnProperty('jeune')) && data.jeune['nbenfant']}</span></td>
            </tr>
            <tr>
                <td colSpan={2}><strong>Nom et prénoms du conjoint(e) : /</strong><span>{(data && data.hasOwnProperty('jeune')) && data.jeune['nomconjoint']}</span></td>
            </tr>
        </table>
        <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
            <tr>
                <th  style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Coordonnées</th>
            </tr>
            <tr>
                <td ><strong>Adresse email :</strong> <span>{(data && data.hasOwnProperty('jeune')) && data.jeune['email']}</span></td>
            </tr>
            <tr>
                <td><strong>Téléphone  : </strong> <span>{(data && data.hasOwnProperty('jeune')) && data.jeune['mobile1']}</span></td>
                <td><strong>Téléphone 2  : </strong> <span>{(data && data.hasOwnProperty('jeune')) && data.jeune['mobile2']}</span></td>
                <td><strong>N° whatsapp  : </strong> <span>{(data && data.hasOwnProperty('jeune')) && data.jeune['numerowhatsapp']}</span></td>  
            </tr>
            <tr>
                <td><strong>Adresse postale :</strong><span>{(data && data.hasOwnProperty('jeune')) && data.jeune['bp']}</span></td>
            </tr>   
            <tr>
                <td><strong>Lieu de résidence :</strong><span>{`${(data && data.hasOwnProperty('jeune')) && data.jeune['lieuresidence']} - ${(data && data.hasOwnProperty('jeune')) && data.jeune['commune']} - ${(data && data.hasOwnProperty('jeune')) && data.jeune['quartier']}`}</span></td>
            </tr>   
        </table>
        {data && data.hasOwnProperty('demandes')&&<table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
            <tr>
                <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Besoins exprimés</th>
            </tr>
            <tr>
                <td>
                    <table  style={{width:pageWidth-45,marginLeft:15}}>
                        <thead>
                            <tr><th>Type</th><th>priorité</th><th>Description</th></tr>
                        </thead>
                        <tbody>
                        {data.demandes.map(demande=>(<tr>
                            <td>{demande.type.libelle}</td>
                            <td>{demande.priorite}</td>
                            <td>{demande.description}</td>
                        </tr>))}
                    </tbody>
                    </table>
                    
                </td>
            </tr>
        </table>}
        <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
            <tr>
                <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Qualifications</th>
            </tr>
            <tr>
                <td>
                    <table  style={{width:pageWidth-45,marginLeft:15}}>
                        <thead>
                            <tr><th>Situation</th><th>Niveau d'étude</th><th>Activité</th><th>Année d'expérience</th><th>Secteur</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.situation} {data['autresituation'] || ""}</td>
                                <td>{data.niveauetude}</td>
                                <td>{data.activite}</td>
                                <td>{data.anneeexperience}</td>
                                <td>{data.secteuractivite}</td>
                            </tr>
                    </tbody>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td>
                    <table  style={{width:pageWidth-45,marginLeft:15}}>
                        <thead>
                            <tr><th>Diplôme</th><th>Année</th><th>Etablissment</th><th>Pays</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.diplome}</td>
                                <td>{data.anneediplome}</td>
                                <td>{data.etablissement}</td>
                                <td>{data.paysdiplome}</td>
                            </tr>
                    </tbody>
                    </table>
                    
                </td>
            </tr>
        </table>
        {data && data.hasOwnProperty('parcours')&&(
            <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
                <tr>
                    <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Parcours</th>
                </tr>
                <tr>
                    <td>
                        <table  style={{width:pageWidth-45,marginLeft:15}}>
                            <thead>
                                <tr><th>Type</th><th>Periode</th><th>Fillière/Diplome</th> <th>Parcours diplomant?</th><th>En cours?</th></tr>
                            </thead>
                            <tbody>
                                {data.parcours.map(parcour=>(
                                    <tr>
                                        <td>{parcour.type}</td>
                                        <td>{`${parcour.datedebut&&dayjs(parcour.datedebut).format('DD/MM/YYYY')} - ${parcour.datefin&&dayjs(parcour.datefin).format('DD/MM/YYYY')}}`}</td>
                                        <td>
                                            <div>
                                                <h6>{parcour.diplome}</h6>
                                                <span>{parcour.etablissement}</span>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td><input style={{height:10}} type="checkbox" checked={parcour.estdiplomant} /></td>
                                        <td><input style={{height:10}} type="checkbox" checked={parcour.estencours} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        )}
        
        <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
            <tr>
                <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Situations avant profilage</th>
            </tr>
            <tr>
                <td>
                    <div style={{display:'flex',alignItems:'center'}}>
                     <input style={{height:10}} type="radio" checked={(data && data['situation'] ==='occupe')} /><label>Occupé(e)</label>
                     <input style={{height:10}} type="radio" checked={(data && data['situation'] ==='chomeur')} /><label>Chômeur</label>
                     <input style={{height:10}} type="radio" checked={(data && data['situation'] ==='enquete')} /><label>En quête</label>
                     <input style={{height:10}} type="radio" checked={(data && data['situation'] ==='entrepreneur')} /><label>A mon propre compte</label>
                     <input style={{height:10}} type="radio" checked={(data && data['situation'] ==='autre')} /><label>{data['autresituation'] || ""}</label>
                    </div>
                </td>
            </tr>
        </table>
        {data && data.hasOwnProperty('dispositifs')&&(
            <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
                <tr>
                    <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Parcours d'insertion</th>
                </tr>
            </table>
        )}
       {data && data.hasOwnProperty('offres')&&(
            <table style={{width:pageWidth-45,fontSize:8,marginTop:15}}>
                <tr>
                    <th colSpan={2} style={{backgroundColor:green[100],padding:5,margin:5,fontSize:7}}>Offres</th>
                </tr>
                {data.offres.map(offre=><tr>
                    <td>{offre.ref}</td>
                    <td>{offre.titre}</td>
                    <td>{offre.typecontrat}</td>
                    <td>{offre.paretnaire?.raisonsociale}</td>
                </tr>)}
                
            </table>
       )}
        
    </div>
     let elementAsString = renderToString(source);
     doc.html(elementAsString,{callback:function(doc) {
       var blob = doc.output("blob");
       window.open(URL.createObjectURL(blob));
     },x:25,y:65})
 }

 export const exportationExelFromJson = (data,cols,namefile)=>{
    const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
     
      XLSX.utils.sheet_add_aoa(worksheet, [cols], { origin: "A1" });
     
      const max_width = data.reduce((w, r) => Math.max(w, r[cols[0]].length), 10);
      worksheet["!cols"] = [ { wch: max_width } ];
    
    
      XLSX.writeFile(workbook, `${namefile}_${Date.now()}.xlsx`, { compression: true });
 }

 export const cmerge = (a, b, prop)=> {
    var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
    return reduced.concat(b);
  }

  export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const zerofilled=(v,s) =>{
    return ('0000000000000000000000'+v).slice(-s);
}
   