import React,{useState} from 'react'
import HeaderTitle from 'containers/HeaderTitle'
import { Outlet } from 'react-router-dom'

function ParametreHome() {
    const [titre,setTitre] = useState("Paramètres")
  return (
    <div>
        <HeaderTitle title={titre} />
        <main>
              <Outlet context={[titre,setTitre]} />
        </main>
    </div>
  )
}

export default ParametreHome
