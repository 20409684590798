import React from 'react'
function FFooter() {
  return (
    <div className='FFooter'>
        <div className="container-fluid bg-footer bg-primary text-white mt-5">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-8 col-md-6">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                            <h4 className="text-white mb-4">Nous contacter</h4>
                            <div className="d-flex mb-2">
                                <i className="bi bi-geo-alt text-white me-2"></i>
                                <p className="text-white mb-0">Côte d'ivoire - Abidjan - Commune de port-bouet</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-envelope-open text-white me-2"></i>
                                <p className="text-white mb-0">contact@pfs-portbouet.com</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-white me-2"></i>
                                <p className="text-white mb-0">+225 07 59 99 34 64</p>
                            </div>
                            <div className="d-flex mt-4">
                                <a className="btn btn-secondary btn-square rounded-circle me-2" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-secondary btn-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-secondary btn-square rounded-circle me-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-secondary btn-square rounded-circle" href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <h4 className="text-white mb-4">Accès rapide</h4>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Accueil</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>A propos</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Actualités</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Organes</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Offres</a>
                                <a className="text-white" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Contact</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <h4 className="text-white mb-4">Liens utiles</h4>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Accueil</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Mairie de portbouet</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>Agence emploi jeune</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>AGEPE</a>
                                <a className="text-white mb-2" href="#"><i className="bi bi-arrow-right text-white me-2"></i>FNS</a>
                                <a className="text-white" href="#"><i className="bi bi-arrow-right text-white me-2"></i>FDFP</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-lg-n5">
                    <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-secondary p-5">
                        <h4 className="text-white">Newsletter</h4>
                        <h6 className="text-white">Souscrivez à notre Newsletter</h6>
                        <p>Recevez les dernières offres et actualités de votre plate-forme de service</p>
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control border-white p-3" placeholder="votre adreese email" />
                                <button className="btn btn-primary">Envoyer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark text-white py-4">
        <div className="container text-center">
            <p className="mb-0">&copy; 2023 <a className="text-secondary fw-bold" href="#">www.pfs-portbouet.com</a>. Tous droits réservés. Développé par <a className="text-secondary fw-bold" href="https://groupearrowsservices.com">Groupe Arrows Services</a></p>
        </div>
    </div>
   { /*<!-- Footer End -->*/}
    </div>
  )
}

export default FFooter
