const AuthSessionStatus = ({ status, className, ...props }) => (
  <>
    {status && (
      <div
        className={`${className} bg-green-100 p-2 w-full rounded-md text-center mx-4 font-medium text-sm text-green-900`}
        {...props}>
        {status}
      </div>
    )}
  </>
)

export default AuthSessionStatus
