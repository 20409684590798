import React, { useEffect, useState,useContext } from 'react'
import Dash from 'components/dash'
import {formatNumber,randomColor} from 'utils/utils'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Divider, Typography } from '@mui/material';
import Evolution from './Evolution';
import TauxTrtDemande from './TauxTrtDemande';
import Offreh from 'containers/Offreh/Offreh';
import Flickity from 'react-flickity-component'
import "./styles.css"
import styled from 'styled-components';
import HeaderTitle from 'containers/HeaderTitle'
import axios from 'lib/axios';
import { Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GrDossier from './GrDossier';
import { CommonsContext } from 'context/CommonsContext';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
function Dashbord() {
  const [offres,setOffres] =useState(null)
  const {commonsField,handleCommonsdata} = useContext(CommonsContext)
  const navigate = useNavigate()
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const handleDernierOffre = (numberOffre=5)=>{
    axios.get(`/api/offre/last?limit=${numberOffre}`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setOffres(res.data)
      }
    })
  }
  const [hstate,setHstate]= useState(null)
  const handleState = ()=>{
    axios.get(`/api/state/home`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setHstate(res.data)
      }
    })
  }
  useEffect(()=>{
    handleDernierOffre()
    handleState()
    handleCommonsdata('titre',"Espace conseiller")
  },[])
  const handleClibtn = (route)=>{
    navigate(`/${portail}/${route}`)
  }

  return (
    <Dash>
      <HeaderTitle titre={commonsField?.titre} />  
      {hstate&&<Dash.Inner direction='row' style={{justifyContent:'space-between'}}>
        {hstate.map(e=>(
           <Dash.MinCard key={e.cible} style={{ flex:1,position:'relative'}}>
           <Dash.Label> {e.cible}</Dash.Label>
           <Badge> {formatNumber(e['total'])}</Badge>
           {(e.nouveau!==0)&&<div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:randomColor(),position:'absolute',top:5,right:10,padding:5,borderRadius:50}}>
            <Dash.SmallText> {e['nouveau']||""}</Dash.SmallText>
            </div>}
         </Dash.MinCard>
        ))}
         
      </Dash.Inner>}
      
      <Dash.Frame direction ='row' style={{marginTop:20,padding:20,marginRight:20}}>
          <Dash.ButtonAdd onClick = {()=>handleClibtn(`dossiers/nouveau/express`)}>
              <FolderSharedIcon sx={{fontSize:40,color:'#707070'}}/>
              Inscrire un jeune
          </Dash.ButtonAdd>
          <Dash.ButtonAdd onClick = {()=>handleClibtn(`offres/nouveau`)}>
            <LocalOfferIcon sx={{fontSize:40,color:'#707070'}}/>
              Ajouter une offre
          </Dash.ButtonAdd>
      </Dash.Frame>
      <Dash.Inner direction='row' style={{marginTop:20,marginRight:20}}>
       <div>
           <Dash.Frame elevation={3} radius={0} style={{padding:20,marginLeft:0,marginBottom:20}}>
           <Typography variant='h5'>Taux de satisfaction des demandes</Typography>
             <Divider />
            <TauxTrtDemande />
          </Dash.Frame>

          <Dash.Frame elevation={3} radius={0} style={{padding:20,marginLeft:0}}>
              <Typography variant='h5'>Evolution des demandes par moi</Typography>
              <Divider />
                <Evolution />
          </Dash.Frame>
        </div>

        <Dash.Frame elevation={3} salign='center' radius={0} style={{padding:20}}>
              
          <Typography variant='h5'>Niveau des inscription par mois </Typography>
              <Divider />
                <GrDossier />
        </Dash.Frame> 
      </Dash.Inner>
      <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,marginRight:20,padding:10}}>
        <OfferHeader>
            <OfferTitle>Dernières offres</OfferTitle>
            <Hr />
        </OfferHeader>
        {(offres && offres.length!==0)&& (
        <Flickity className={'carousel'} elementType={'div'} static reloadOnUpdate >
            {offres.map((offre,index)=> <Offreh key={index} readmore  offre={offre}  />)}
        </Flickity>)}
       
      </Dash.Frame>
    </Dash>
  )
}

export default Dashbord