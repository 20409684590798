import React,{useContext,useState,useEffect} from 'react'
import { Card } from 'components'
import {styled as  makeStyles} from "@mui/material";
import { AddContext } from 'context/add'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {CardActionArea,Typography} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import { getExtension } from 'utils/utils';
import FilePresentIcon from '@mui/icons-material/FilePresent';
const useStyles = makeStyles(theme=>({
    
      content_img:{
        display:'flex',
        flexDirection:'column',
        flex:1,
        position:'relative'
    },
    photo_label:{
        fontSize:15,
        fontStyle:'italic',
        fontWeight:'bold'
    },
    logo_box:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        border:'1px dashed #707070',
        minHeight:'150px;',
        background: 'rgba(244,244,244,0.6)',
        position:'relative',
        marginBottom:20
    },
    photo_check:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    input: {
        display: "none"
      },
    img_card:{
        minWidth:'60%',
        minHeight:'60%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    }

}))
function StepsPhoto(props) {
    const classes = useStyles()
    const {step,steps} = props
    const data = useContext(AddContext)

    const [state,setState] = useState({
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null
    })

    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/*',
      onDrop: files => {
        setState({
              ['selectedFile']: files.map((file) => Object.assign(file, { preview:  URL.createObjectURL(file)}))
          });
      }
    });
    const imageResetHandler = event => {
        setState({
          mainState: "initial",
          selectedFile: null,
          imageUploaded: 0
        });
      };

 
      useEffect(() => {
        if(state.hasOwnProperty('selectedFile') && state.selectedFile){
           return ()=>state.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
        }
      }, [state]);
    
      const thumbs = (state.hasOwnProperty('selectedFile') && state.selectedFile)&&state.selectedFile.map(file => (
        getExtension(file.name).toLowerCase()=='pdf'?<IconButton onClick={imageResetHandler} ><FilePresentIcon sx={{fontSize:60,color:'#888888'}}/> </IconButton>: <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
            <img
                width="40%"
                className={classes.media}
                src={file.preview}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
        </CardActionArea>  
        ));

  useEffect(()=>{
    data.handleChangeData('photo',state.selectedFile)
  },[state.selectedFile])
  return (
      <Card>
          <Card.Header>
              <Card.Title>{`Etape ${step+1} : ${steps[step]}` }</Card.Title>
              <Card.Hr />
          </Card.Header>
           <div className={classes.content_img}>
                  <Typography className={classes.photo_label}>Cliquez ou glissez déposer votre photo ici</Typography>
                  <div className={classes.logo_box} {...getRootProps()}>
                  
                      {thumbs}
                  
                      <input
                          {...getInputProps()}
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          name='photo'
                          disabled={!state.selectedFile}
                          />
                          {!state.selectedFile&&<label className={classes.photo_check} htmlFor="contained-button-file">
                              <AddAPhotoIcon sx={{fontSize:60,color:'#888888'}} />
                          </label>}
                  </div>
            </div>
               
         
    </Card>
   
  )
}

export default StepsPhoto