import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from 'containers/Sidebar'
import Header from 'containers/Header'
import { Divider, IconButton, Paper } from '@mui/material'
import Breadcrumbs from 'containers/Breadcrumbs'
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'assets/logo.jpg'
import CloseIcon from '@mui/icons-material/Close';
 function Conseiller () {
  const [mopen,setMopen] =useState(false)
  const handleMopen =()=>{
    setMopen(true)
  }
  const handleMClose =()=>{
    setMopen(false)
  }
  return (

      <div className='grid grid-cols-1 md:grid-cols-5 gap-3 max-h-screen relative'>

          <div className='hidden md:block md:col-span-1 max-h-[100vh]'>
            <SideBar handleClose={handleMClose} mobile={false} />
          </div>
          
          <div className='md:col-span-4 grid grid-rows-8 max-h-[100vh]'>
              <div className='sm:block md:hidden row-span-1 p-2 bg-white shadow-md border-b-2 border-b-green-500'>
                <div className='flex flex-row justify-between items-center'>
                  <img src={logo} className='w-8 h-8 rounded-full object-cover'/>
                  <span className='font-bold text-green-500'>PFS-PORTBOUET</span>
                  <IconButton onClick={handleMopen}>
                    <MenuIcon className='text-green-500' />
                  </IconButton>
                </div>
              </div>
              {mopen&& <div className='sm:block md:hidden absolute right-0 top-0 z-[9999] bg-white'>
                <div className='flex flex-row justify-end'>
                  <IconButton onClick={handleMClose}>
                    <CloseIcon className='text-green-500' />
                  </IconButton>
                </div>
                <SideBar handleClose={handleMClose} mobile={true} />
              </div>}
             
              <div className='hidden md:block row-span-1'><Header/></div>
              <main className='my-2 overflow-auto row-span-6'>
                <Breadcrumbs />
                <Outlet />
              </main>
              
              <div className='row-span-1 flex flex-row bottom-0 justify-center pt-2'>  
              <Divider />
              <p>Copyright &#169; { new Date().getFullYear() } - Tous droits réservés PFS Port-bouet </p>
              </div>
          </div>
          
      </div>

  )
}

export default Conseiller
