
import React,{useEffect, useState} from 'react'
import Dash from 'components/dash'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddPartenaire from './AddPartenaire';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/RefreshOutlined'
import { deletePartenaire } from 'db/dbActions';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import AlertPage from 'containers/AlertPage';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'lib/axios';
import { Table } from 'react-bootstrap';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import {Pagination} from "@mui/material";
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux';
const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
  `


function AlertConfirm(props){
  const {data,open,handleClose,action} =props
  return(
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Suppression?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiement supprimer cet partenaire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex', flexDirection:'space-between'}}>
          
          <Button variant="outlined" color='secondary' onClick={handleClose}>Annuler</Button>
          <Button  color="error" variant="contained" onClick={()=>action(data)}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
  )
}
const useStyles = makeStyles()((theme) => {
  return {
    actionBar:{
      display:'flex',
      justifyContent:'flex-end'
  },
  logo:{
      width:60,
  },
  item:{
    display:'flex'
  },
  label:{
    fontStyle:'italic',
    fontWeight:'bold',
    marginRight:20
  }
  };
});


function PartenaireListe() {

    const [open,setOpen]=React.useState(false)
    const { classes } = useStyles();

    const [mode,setMode] =React.useState("ADD")
    const [partData,setPartData] =React.useState(null)
    const [imgArray,setImgArray] =React.useState([])
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [actionMode,setActionMode] =React.useState("suprow")
    const [titire,setTitre] = React.useState("Gestion des parténaires")
    const [search,setSearch] = useState(null)
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    const [paginateData,setPagnateData] = useState(null)
    useEffect(()=>{
      setTitre("Gestion des parténaires")
    },[])
  //actionMode =[suprow=>suppression d'une ligne,supgroup=>supression groupé]
    const navigate =useNavigate()
    const handeleimgArrayData =(data)=>{
      setImgArray([])
      data.forEach((item) => {
        if(item['logo']!==null){
          getDownloadURL(ref(storage,item['logo'])).then((url) => {
            setImgArray((prev) => [...prev, {code:item.code,url:url}]);
          });
        }
      });
    }
  
    const handlePartData =(numberpage = 1)=>{
      const url =search?`/api/partenaire/list/${search}?page=${numberpage}`:`/api/partenaire/list?page=${numberpage}`
      axios.get(url).then(res=>{
        if(res.status === 200 || res.status ===201){
          setPartData(res.data.data)
          setPagnateData({
            current_page:res.data.meta?.current_page,
            last_page:res.data.meta?.last_page,
            per_page:res.data.meta?.per_page,
            total:res.data.meta?.total
          })
        }
      })
    }

    const handleClose = () => {
        setOpen(false);
      };
    const handleOpen = () => {
      setOpen(true);
    };
   
    useEffect(()=>{
      handlePartData()
    },[])

    const getUrlImage = (code)=>{
        let url = process.env.REACT_APP_BACKEND_URL+"/"+code
      console.log(url);
       return url
    }
    
    const handleClick = () => {
      console.info('You clicked the Chip.');
    };
    const handleNouveau =()=>{
      setTitre("Gestion des parténaires - Créer")
      navigate(`/${portail}/partenaires/nouveau`)

    }
    
    const handleDetails =(data)=>{
      setTitre("Gestion des parténaires - fiche du partenaire")
      if(data){
        navigate(`/${portail}/partenaires/fiche/${data['id']}`,{replace:true, state:{data}})
      }
    }
    const handleEdit =(data)=>{
      if(data){
        setTitre("Gestion des parténaires - Modification")
        navigate(`/${portail}/partenaires/modifier/${data['id']}`,{
          state: {
            data:data,
          }
        })
      }
    }
    const [aletInfo,setAlertInfo] = React.useState({})
      const handleOpenAletInfo =(message,succes,action)=>{
        setAlertInfo({
          open:true,
          message,
          succes,
          action
        })
      }
      const handleCloseAletInfo =(action)=>{
        setAlertInfo({
          open:false,
          message:"",
          succes:false
        })
        action&&action()
      }
    
    const [rowData,setRowData]  =React.useState(null)
    const [openConfirm,setOpenConfirm] =React.useState(false)
    const handleOpenConfirm =(amode,data)=>{
      setActionMode(amode)
      switch (amode) {
        case 'suprow':
          setRowData(data)
          break;
        case 'supgroup':
        default:
          break;
      }
      setOpenConfirm(true)
    }
    const handleCloseConfirm =()=>{
      setOpenConfirm(false)
    }

    //supression d'une ligne
    const handleDelete =(id)=>{
        handleCloseConfirm()
        deletePartenaire(id).then(res=>{
          if(res && res['error']===false){
            handlePartData()
            handleOpenAletInfo(`Suppression effectuée avec succès`,true,handleClose)
          }else{
            handleOpenAletInfo(`Erreur inconnu lors de la suppression`,false,null)
          }
        })
    }
    //supression de ligne selectionné
    const handleDeleteSelected =()=>{
      if (selectedRow && selectedRow.length!==0){
        selectedRow.forEach(ro=>{
          deletePartenaire(ro.AlertPageid).then(res=>{
            if(res && res['error']===false){
              handlePartData()
            }
          })
          handlePartData()
        })
      }
    }
    const handleRowSelected =(row)=>{
      let newRowData = [...selectedRow]
      if(newRowData.findIndex(item=>item.id === row.id) === -1){
        newRowData.push(row)
      }else{
        newRowData = newRowData.filter(item=>item.id!==row.id)
      }
      setSelectedRow(newRowData)
    }


  return (
    <div>
        <AddPartenaire open={open} refressdata={handlePartData} handleClose={handleClose} mode={mode} data={partData}/>
        <AlertConfirm open={openConfirm} handleClose={handleCloseConfirm} action={actionMode==='suprow'? handleDelete:handleDeleteSelected} data={actionMode==='suprow'?rowData&&rowData.id:selectedRow}  />
        <AlertPage open={aletInfo['open']||false} message={aletInfo['message']||""} succes={aletInfo['succes']||false} handleClose={handleCloseAletInfo} />
        <Dash>
            <Dash.Inner direction='row'>
                <Dash.MinCard style={{position:'relative'}}>
                    <Dash.Label>Total partenaires</Dash.Label>
                    <Dash.Text> 10</Dash.Text>
                </Dash.MinCard>
                <Dash.ButtonAdd onClick={handleNouveau} >
                    <CreateNewFolderIcon sx={{color:'#707070'}}/>
                    Ajouter un partenaire
                </Dash.ButtonAdd>
            </Dash.Inner>
        
        <Dash.Frame direction='column' salign='flex-start' style={{padding:10}}>
          <OfferHeader>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <OfferTitle>Partenaires</OfferTitle>
                <div className={classes.actionBar}>
                    <IconButton aria-label="part-add" onClick={handleOpen}>
                            <AddIcon />
                    </IconButton>
                    <IconButton aria-label="part-refresh" onClick={handlePartData}>
                            <RefreshIcon />
                    </IconButton>
                   
                    <IconButton aria-label="part-delete" onClick={()=>handleOpenConfirm('supgroup',selectedRow)}>
                            <DeleteIcon />
                    </IconButton>
                </div>
            </div>
              
              <Hr />
          </OfferHeader>

          <div style={{ minWidth: '100%' }}>
            <Chip label={`Total(s) : ${(paginateData&&paginateData.total)?paginateData.total:""}`} variant="outlined" />
            <Table striped bordered hover responsive>
              <tbody>
              {(partData && partData.length!==0)?(partData.map(rowdata=>(<tr key={rowdata?.uuid}>
                <td>
                  {(rowdata['logo']!==null)?(<img 
                                                className={classes.logo} 
                                                src ={getUrlImage(rowdata['logo'])} />):<InsertPhotoOutlinedIcon />}
                </td>
                <td><div>
                  <Typography style={{fontWeight:'bold'}}>{rowdata.codepartenaire}</Typography>
                  <Typography>{rowdata.raisonsociale}</Typography>
                  <Typography>{rowdata.activitesprincpale}</Typography>
                </div></td>
                <td><div style={{display:'flex',flexDirection:'column'}}>
                      <div className={classes.item}><span className={classes.label}>Type : </span> <Typography>{rowdata.formejuridique}</Typography></div>
                      <div className={classes.item}><span className={classes.label}>Capital : </span> <Typography>{rowdata.capital}</Typography></div>
                      <div className={classes.item}><span className={classes.label}>N° Contribuable : </span> <Typography>{rowdata.comptecontribuable}</Typography></div>
                      <div className={classes.item}><span className={classes.label}>N° RC : </span> <Typography>{rowdata.numerorc}</Typography></div>
                    </div>
                </td>
                <td><div>
                      <div className={classes.item}><span className={classes.label}>Email : </span> <Typography>{rowdata.adressemail}</Typography></div>
                      <div className={classes.item}><span className={classes.label}>Site web : </span> <Typography>{rowdata.siteweb}</Typography></div>
                      <div className={classes.item}><span className={classes.label}>Localisation : </span> <Typography>{rowdata['ville'] || ''}</Typography></div>
                    </div>
                </td>
                <td>
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <Badge badgeContent={4} color="primary">
                        <Chip label="Total offres" onClick={handleClick} />
                    </Badge>
                    <Badge badgeContent={4} color="secondary">
                        <Chip label="Offre en cours" color="success"  onClick={handleClick}/>
                    </Badge>
                  </div>
                </td>
                <td>
                  <div style={{display:'flex',}}>
                    <IconButton onClick={(event) =>handleDetails(rowdata)}>
                      <DescriptionOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={(event) => handleEdit(rowdata)}>
                      <ModeEditOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={(event) =>handleOpenConfirm('suprow',rowdata)}>
                    <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                </td>
              </tr>))):
              <tr><td><p>Aucun partenaire trouvé, veuillez cliquez sur "Ajouter un partenaire" pour créer un nouveau partenaire</p></td></tr>}
              </tbody>
            </Table>
            {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handlePartData(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
          </div>
        </Dash.Frame>
       
      </Dash>
    </div>
    
  )
}

export default PartenaireListe