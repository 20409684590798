import React, { useEffect, useState } from 'react'
import {IconButton, MenuItem, Tooltip, Typography} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Alert, Button, Col, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import SaveIcon from '@mui/icons-material/Save';
import './styles.css'
import 'react-quill/dist/quill.snow.css';
import './styles/UtilisateurAdd.scss'
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import { 
    StepUserSearch, 
} from './components';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import axios from 'lib/axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makePassword,generateLogin } from 'lib/application';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import dayjs from 'dayjs';
const groupItems=[
  {
    name: "Mme",
    label: "Mme",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "Mlle",
    label: "Mlle",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "M",
    label: "M",
    information: "",
    disabled: false
  }
]
const statuts = [
  {
    name: "Actif",
    label: "Actif",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "Inactif",
    label: "Inactif",
    information: "Veuillez selectionner un type",
    disabled: false
  },
]
function UtilisateurEdit(props) {
    const navigate =useNavigate()
    const [inputField,setInputField] = useState({})
    const params = useParams()
    const handlegetUser =()=>{
      axios.get(`/api/users/user-personne/${params['code']}`).then(res=>{
        if(res.status ===200 || res.status ===201){
          const data=res.data[0]
          console.log(data);
          setInputField({
            "civilite":data.personne?.civilite,
            "nom":data.personne?.nom,
            "prenom":data.personne?.prenom,
            "datenaissance":dayjs(data.personne.datenaissance).format("YYYY-MM-DD"),
            "codepersonne":data.personne?.codepersonne,
            "login":data.username,
            "statut":data.personne.statut,
            "photo":data.personne.photo,
            ...data})
        }
      })
    }

    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const handlelist = ()=>{
        navigate(`/${portail}/utilisateurs`)
    }
    const [loading,setLoading] = useState(false)
    
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const [profils,setProfils] =useState(null)

    const handleProfile = ()=>{
        axios.get('/api/profile/list').then(res=>{
            if(res.status === 200 || res.status ===201){
                setProfils(res.data.data)
            }else{
                console.log(res.data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    const [structures,setStructures] = useState(null)
    const handleStructure = () =>{
        axios.get('/api/structure/list').then(res=>{
            if(res.status === 200 || res.status ===201){
                setStructures(res.data.data)
            }else{
                console.log(res.data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
      handlegetUser()
      handleProfile()
      handleStructure()
    },[])

  const handleChangeDate = (name,newValue) => {
      setInputField({...inputField,[name]:newValue})
    };
  const handleConnexionData = useCallback(async ()=>{
      if(inputField.civilite  && inputField.nom && inputField.prenom){
          setInputField({...inputField,'password':makePassword(8)})
          setInputField({...inputField,'login':await generateLogin(inputField.nom,inputField.prenom)})
      }
  },[inputField.civilite,inputField.nom,inputField.prenom])

    useEffect( ()=>{
      handleConnexionData()
    },[inputField.civilite,inputField.nom,inputField.prenom])

   
    const handleInitPassword =async ()=>{
      setInputField({...inputField,'password':makePassword(8),renitpass:true})
    }
    const handleAnnulerInitPassword = async()=>{
      const {password,...restinputField} = inputField
      setInputField({...restinputField,renitpass:false})
    }
  const [errors,setErrors] =  useState([])
  const handleSubmit =(e)=>{
    setErrors([])
    setLoading(true)
    if(!inputField.email || inputField.email===""){
        setErrors([...errors,"Veuillez remplir le champ email !"])
    }
    if(!inputField.nom || inputField.nom===""){
        setErrors([...errors,"Veuillez remplir le champ nom !"])
    }
    if(!inputField.prenom || inputField.prenom===""){
        setErrors([...errors,"Veuillez remplir le champ prénom !"])
    }
    let dataSend = {
      credentials:{
        email:inputField.email,
        name:inputField.nom +" "+inputField.prenom,
        profil_id:inputField.profil_id,
        renitpass:inputField.renitpass,
        password:inputField.renitpass ? inputField.password:null,
        password_confirmation:inputField.renitpass ? inputField.password:null
    }, 
    personne:{
        id:inputField.personne.id,
        codepersonne:inputField.personne.codepersonne,
        civilite:inputField.civilite,
        nom:inputField.nom,
        prenom:inputField.prenom,
        datenaissance:inputField.datenaissance,
        statut:inputField.statut
    }}

    e.preventDefault()
    if(errors && errors.length!==0){
        swal("Ooops","Veuillez remplir tous les champs","warning")
        setLoading(false)
        return
    }
    axios.post(`/api/users/update/${inputField.id}`,dataSend).then(res=>{
        if(res.status!==200 || res.status!==201){
            swal("Bien !","Enregistrement effectué avec succès","success")
            setLoading(false)
            handlelist()
        }else{
            console.log(res.data);
            setLoading(false)
            swal("Ooops !","Erreur lors de l'enregistrement","error")
        }
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        swal("Ooops !","Erreur lors de l'enregistrement","error")
    })
  }
    return (
  <Container>
        <Row className='d-flex flex-be'>
           <Col xs={6} sm={10} md={10} lg={10}> <Typography sx={{ textTransform: 'uppercase' }} variant='h5'>Mise à jour compte utilisateur</Typography></Col>
           <Col xs={6} sm={2} md={2} lg={2}> <Button className="border-green-600 text-green-600" variant='outlined' onClick={(e)=>handlelist()}>
              <ArrowBackIosIcon />Retour à la liste
            </Button>
            </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className='mx-auto mt-3'>
          <form onSubmit={handleSubmit} className='relative'>

        <Card>
          <Card.Header>
              <Card.Title>INFORMATIONS PERSONNEL </Card.Title>
          <Card.Hr />
          </Card.Header>
          <div className=''>
              <img src={require(inputField.photo)} />
               <input type='file' hidden name='photo'  />
          </div>
          <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
              <RadioField
                  groupItems={groupItems}
                  group="Civilité"
                  checkedDefault="Mme"
                  appearance={radiobutton_appearances.primary}
                  name='civilite'
                  onChange={handleChange}
                  vvalue ={inputField.civilite || ""}
              />
              <CustumForm.Input 
                  size="small" 
                  margin="dense" 
                  style={{marginRight:20,marginBottom:20}}  
                  label="Nom"
                  required 
                  wd='100%'
                  name='nom' 
                  value={inputField.nom || ""}
                  onChange={handleChange}
              />
              <CustumForm.Input 
                  size="small" 
                  margin="dense" 
                  style={{marginRight:20,marginBottom:20}}  
                  label="Prenom"
                  required 
                  wd='100%'
                  name='prenom' 
                  value={inputField.prenom || ""}
                  onChange={handleChange}
              />
              <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="dd/MM/yyyy"
                  value={inputField.datenaissance || ""}
                  name='datenaissance'
                  onChange={(v)=>handleChangeDate('datenaissance',v)}
                  renderInput={(params) => <CustumForm.Input size="small"  wd='100%' margin="dense" style={{marginRight:20,marginBottom:20}}  {...params} />}
              />
                <Card.Hr />
              </CustumForm.Group>
              </LocalizationProvider>
          </div>
      </Card>
      <Card>
          <Card.Header>
              <Card.Title>PROFIL / STRUCTURE </Card.Title>
          <Card.Hr />
          </Card.Header>
          <div>
          <CustumForm.Group direction ='row' flex={1}>
                  {profils&&<CustumForm.Input 
                      required size="small" 
                      name="profil_id" 
                      margin="dense" 
                      onChange={(event)=>setInputField({...inputField,[event.target.name]:event.target.value})} 
                      value={inputField.profil_id || ""} 
                      select 
                      style={{marginRight:20}}  label="Profile">
                          <MenuItem  value='0'>Aucun</MenuItem>
                          {profils.map(profil=>(
                              <MenuItem  value={profil.id}>{profil.libelle.toUpperCase()}</MenuItem>
                          ))}
                      </CustumForm.Input>}
                      {structures && <CustumForm.Input 
                          required size="small" 
                          name="structure_id" 
                          margin="dense" 
                          onChange={(event)=>setInputField({...inputField,[event.target.name]:event.target.value})} 
                          value={inputField?.structure_id || ""} 
                          select 
                          style={{marginRight:20}}  label="Structure">
                          <MenuItem  value='0'>Aucun</MenuItem>
                          {structures.map(structure=>(
                              <MenuItem  value={structure.id}>{structure.libelle.toUpperCase()}</MenuItem>
                          ))}
                      </CustumForm.Input>}
                      
              </CustumForm.Group>
          </div>
      </Card>
      <Card>
            <Card.Header>
                <Card.Title>INFORMATIONS DE CONNEXION </Card.Title>
            <Card.Hr />
            </Card.Header>
            <div>
            <RadioField
                  groupItems={statuts}
                  group="Statut"
                  checkedDefault="Actif"
                  appearance={radiobutton_appearances.primary}
                  name='statut'
                  onChange={handleChange}
                  vvalue ={inputField.statut || ""}
              />
            <CustumForm.Input 
                    size="small" 
                    margin="dense" 
                    style={{marginRight:20,marginBottom:20}}  
                    label="email"
                    required 
                    wd='100%'
                    name='email'
                    type='email' 
                    value={inputField?.email}
                    onChange={handleChange}
                />
                <CustumForm.Group row direction='row'>
                    <div style={{display:'flex'}}>
                        <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                            <span style={{fontSize:16}}>Nom d'utilisateur : </span>
                            <span style={{fontSize:16,margin:20, fontWeight:'bold'}} >{inputField.login||""}</span>
                        </div>
                        
                    </div>
                </CustumForm.Group>
                <CustumForm.Group row>
                    <div style={{display:'flex'}}>
                        <div style={{dysplay:'flex',justifyContent:'flex-start',alignItems:'center',margin:20}}>
                            
                            <span style={{fontSize:16}}>Mot de passe  : </span>
                            <span style={{fontSize:18,margin:20, fontWeight:'bold'}}>{inputField['password']||""}</span>
                        </div>
                       <div className='d-flex items-center'>
                            <Button onClick={handleInitPassword} variant="outline-secondary" size="sm">
                                Générer un mot de passe
                            </Button>
                        
                            {inputField.renitpass&&<Button  onClick={handleAnnulerInitPassword} variant="outline-danger mx-1" size="sm">
                                Annuler
                            </Button>}
                            </div>
                    </div>
                    
                </CustumForm.Group>
            </div>
        </Card>
        <Card style={{padding:20,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{display:'flex',flexDirection:'row'}}>
              <Tooltip title="Enregistrer">
                <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                    {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                </Button>
                </Tooltip>
            </div>
            
        </Card>
        </form>
        </Col>
        </Row>
      </Container>
  )
}

export default UtilisateurEdit
