import React from 'react'
import {styled as  makeStyles} from "@mui/material";
import { Button, Paper, Typography,IconButton, } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SearchIcon from '@mui/icons-material/Search';
import Table from 'react-bootstrap/Table';
import Tooltip from '@mui/material/Tooltip';
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import "./styles/ProfilsList.scss"
import axios from 'lib/axios';
import { useState } from 'react';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import UserProfilList from './UserProfilList';
function ProfilsList(props) {
    const portail = (useSelector(state=>state.ui.portail)).toLowerCase()
    const navigate =useNavigate()
    const handlenewprofil = ()=>{
        navigate(`/${portail}/profil/nouveau`,{state:{mode:'ADD',profil:null}})
    }
    const [search,setSearch] = useState(null)
   
    const [profils,setProfils] =useState(null)
    const handleProfils = ()=>{
        let url = `/api/profile/list`
        if(search){
            url=  `/api/profile/list/${search}`
        }
        
        axios.get(url).then(res=>{
            if(res.status === 201 || res.status===200){
                setProfils(res.data.data)
            }else{
                swal("Désolé","Aucun résultat trouvé",'warning')
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        return handleProfils()
    },[])

    //supression
    const handleDelete =(id)=>{
        axios.delete(`/api/profile/delete/${id}`).then(res=>{
            if(res.status === 201 || res.status===200){
                handleProfils()
            }else{
                swal("Désolé","Aucun résultat trouvé",'warning')
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    const [profilEncours,setProfilEncours] = useState(null)
    const [tab,setTab] = useState(0)
    const [userOpen,setUserOpen] = useState(false)
    const handleUserOpen =(data,tabs)=>{
        setProfilEncours(data)
        setUserOpen(true)
        setTab(tabs)
    }
    const handleUserClose = (event,reason)=>{
        if (reason && reason === "backdropClick") 
        return;
        setUserOpen(false)
    }

  return (
<>
    <UserProfilList open={userOpen} handleClose={handleUserClose} handleOpen={handleUserOpen} profil={profilEncours} ctab={tab} />
    <div className="profillist">
        <div className="titlebox">
            <Typography variant='h5'>Gestion des profils</Typography>
            <Button variant="outline-success" className="btnAdd" onClick={()=>handlenewprofil()}>
                <AddIcon />Créer un profil
            </Button>
        </div>
        <div className="bodybox">
            <Typography variant='h5'>RECHERCHER</Typography>
            <Paper className="searchBox" variant='outlined'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Rechercher ...(libelle,statut.)"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(event)=>setSearch(event.target.value)}
                    />
                    <Button variant="outline-success" id="button-addon2" className='btnAdd' onClick={handleProfils}>
                        <SearchIcon />
                    </Button>
                    
                </InputGroup>
            </Paper>
            <Paper className="listBox" variant='outlined'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>intitulé</th>
                        <th>Portails accessibles</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(profils && profils.length!==0)&&profils.map(profil=><tr>
                            <td>{profil['id'] || ""}</td>
                            <td>{profil['libelle'] || ""}</td>
                            <td>
                                {(profil.portails && profil.portails.length!==0)&&profil.portails.map(port=>(
                                <sapn style={{display:'block'}}>{port.libelle}{(port.default===1)&&<Badge style={{marginLeft:20}} bg="info">
                                Par defaut
                              </Badge>}</sapn>
                                
                                ))}</td>
                            <td>
                                <div className="tbaction d-flex flex-row align-items flex-wrap">
                                    <Tooltip title="Editer">
                                        <IconButton onClick={()=>navigate(`/${portail}/profil/modifier/${profil.id}`,{state:{mode:'UP',profil:profil}})}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Ajouter un utilisateur">
                                        <IconButton onClick={()=>handleUserOpen(profil,1)}>
                                            <PersonAddIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Liste des utilisateurs">
                                        <IconButton onClick={()=>handleUserOpen(profil,0)}>
                                            <PeopleAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Supprimer">
                                        <IconButton onClick={()=>handleDelete(profil.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                   
                                </div>
                            </td>
                        </tr>)}
                       
                    </tbody>
                </Table>
            </Paper>
        </div>
    </div>
    </>
  )
}

export default ProfilsList
