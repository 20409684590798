import React, { useEffect,useState } from 'react'
import { Typography } from '@mui/material'
import { Card } from 'components'
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'
import {getpartenaires,getUrlFile } from 'db/dbActions';
import Box from '@mui/material/Box';
import dayjs from 'dayjs'
import { makeStyles } from 'tss-react/mui';
import axios from 'lib/axios';
import { Table } from 'react-bootstrap';
const useStyles = makeStyles()(theme=>({
    headerContainer:{
        background:'#707070',
        color:'white',
        fontSize:'18px',
        display:'flex',
        flex:1,
        flexDirection:'column',
        padding:'20px',
        alignItems:'center'
    },
    headerSub:{
        display:'flex',
        justifyContent:'space-around',
        marginTop:20,
        fontSize:18,
    },
    paritem:{
        display:'flex',
        marginBottom:10,
    },
    boxcard:{
      display:'flex',
      flexDirection:'column',
      padding:10,border:'1px solid #00B04B'
    },
    boxpropo:{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center'}
}))
const styles ={
    label:{
        fontWeight:'bold',
        marginRight:20,
    },
    

}
const Button =styled.button`
  background-color:#00B04B;
  padding:10px 2rem 10px 2rem;
  border:1px solid #f8f8f8;
  color:white;
  font-weight:bold;
  display:flex;
  font-size:16px;
  flex-direction:column;
  border-radius: 10px;
  flex:1;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  cursor:pointer;
  &:hover{
    background-color:#F29400;
    color:white
  }
`

const DetailsDispositif = (props) => {
    const {classes} = useStyles()
    const location =useLocation()
    const {data} = location.state
 
  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
        <div className={classes.headerContainer}>
            <Typography variant='h4'>{data['titre']||""}</Typography>
            <Button>Proposer le dispositif</Button>
            <div className={classes.headerSub}>
              <Typography style={{marginRight:20}}>
                Créer le :{data.hasOwnProperty('created_at')?dayjs(data['created_at']).format("DD/MM/YYYY"):""} 
              </Typography> 

              <Typography>
                Type :{data.hasOwnProperty('type')?data['type']:"" }  
              </Typography>

              <Typography>
                Statut :{data.hasOwnProperty('statut')?data['statut']:""} 
              </Typography>
            </div>
        </div>
        <Card>
            <Card.Header>
              <Card.Title>Informations principale</Card.Title>
              <Card.Hr />
            </Card.Header>    
              <div className={classes.boxcard}>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Libelle : </Typography> 
                  <Typography> {(data.libelle && data.libelle!=='null') ? data.libelle :"-"} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Description : </Typography> 
                  <Typography> {(data.description && data.description!=='null') ? data.description : "-"} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Autorisé la suspension : </Typography> 
                  <Typography>  {(data.suspensionok && data.suspensionok!=='null') ? (data.suspensionok==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Date d'entrée obligatoire : </Typography> 
                  <Typography>  {(data.dateentreeforce && data.dateentreeforce!=='null') ? (data.dateentreeforce==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Date d'initialisation obligatoire : </Typography> 
                  <Typography>  {(data.dateinitforcer && data.dateinitforcer!=='null') ? (data.dateinitforcer==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Mésure nationale : </Typography> 
                  <Typography>  {(data.mesurenationale && data.mesurenationale!=='null') ? data.mesurenationale : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Type d'appui : </Typography> 
                  <Typography>  {(data.typeappui && data.typeappui!=='null') ? data.typeappui : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Autorisé le déploiement automatique : </Typography> 
                  <Typography>  {(data.deployementauto && data.deployementauto!=='null') ? (data.deployementauto==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
            </div>
        </Card>
        <Card>
            <Card.Header>
                <Card.Title>ENTREES/SORTIES</Card.Title>     
            </Card.Header>
            <div className={classes.boxcard}>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Autorisé les entrées/sorties multiple : </Typography> 
                  <Typography>  {(data.esmultiple && data.esmultiple!=='null') ? (data.esmultiple==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Clôture automatique à la fin du dispositif : </Typography> 
                  <Typography>  {(data.clotureauto && data.clotureauto!=='null') ? (data.clotureauto==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.clotureauto && data.clotureauto!=='null' && data.clotureauto!=='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Motif de sortie automatique : </Typography> 
                  <Typography>  {(data.motifsortieauto && data.motifsortieauto!=='null') ? data.motifsortieauto : '-'} </Typography>
                </div>}
                {(data.clotureauto && data.clotureauto!=='null' && data.clotureauto!=='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Durée entre la fin et la clôture automatique : </Typography> 
                  <Typography>  {(data.durefincloture && data.durefincloture!=='null') ? data.durefincloture +" Jour(s)" : '-'} </Typography>
                </div>}
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Date d'entrée dans le dépassé possible : </Typography> 
                  <Typography>  {(data.dateentreepasse && data.dateentreepasse!=='null') ? (data.dateentreepasse==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.dateentreepasse && data.dateentreepasse!=='null' && data.dateentreepasse==='O')&& <div className={classes.paritem}>
                  <Typography style={styles.label}>Nombre de mois autorisé : </Typography> 
                  <Typography>  {(data.nombremois && data.nombremois!=='null') ? data.nombremois : '-'} </Typography>
                </div>}
            </div>
        </Card>
        <Card>
            <Card.Header>
                <Card.Title>DRUREE</Card.Title>     
            </Card.Header>
            <div className={classes.boxcard}>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Durée limitée : </Typography> 
                  <Typography>  {(data.dureelimite && data.dureelimite!=='null') ? (data.dureelimite==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.dureelimite && data.dureelimite!=='null' && data.dureelimite==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Durée du dispositif : </Typography> 
                  <Typography>  {(data.dureedispositif && data.dureedispositif!=='null') ? data.dureedispositif+' mois' : '-'} </Typography>
                </div>}
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Le dispositif peut être étendu : </Typography> 
                  <Typography>  {(data.etendu && data.etendu!=='null') ? (data.etendu==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.etendu && data.etendu!=='null' && data.etendu==='o')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Durée du dispositif : </Typography> 
                  <Typography>  {(data.dureeextension && data.dureeextension!=='null') ? data.dureeextension+' mois' : '-'} </Typography>
                </div>}

            </div>
        </Card>
        <Card>
            <Card.Header>
                <Card.Title>VERSEMENTS</Card.Title>     
            </Card.Header>
            <div className={classes.boxcard}>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Versements activés : </Typography> 
                  <Typography>  {(data.versementactiver && data.versementactiver!=='null') ? (data.versementactiver==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.versementactiver && data.versementactiver!=='null' && data.versementactiver==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Nombre de période initiale : </Typography> 
                  <Typography>  {(data.nbreperiodeinit && data.nbreperiodeinit!=='null') ? data.nbreperiodeinit+' mois' : '-'} </Typography>
                </div>}
                {(data.versementactiver && data.versementactiver!=='null' && data.versementactiver==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Nombre de période d'extension : </Typography> 
                  <Typography>  {(data.nbreperiodeext && data.nbreperiodeext!=='null') ? data.nbreperiodeext+' mois' : '-'} </Typography>
                </div>}
                {(data.versementactiver && data.versementactiver!=='null' && data.versementactiver==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Montant Max. par période : </Typography> 
                  <Typography>  {(data.montantmaxper && data.montantmaxper!=='null') ? data.montantmaxper+' FCFA' : '-'} </Typography>
                </div>}
                {(data.versementactiver && data.versementactiver!=='null' && data.versementactiver==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Montant max. versé : </Typography> 
                  <Typography>  {(data.montantmaxpaie && data.montantmaxpaie!=='null') ? data.montantmaxpaie+' FCFA' : '-'} </Typography>
                </div>}
            </div>
        </Card>
        <Card>
            <Card.Header>
                <Card.Title>PHASES</Card.Title>     
            </Card.Header>
            <div className={classes.boxcard}>
                <div className={classes.paritem}>
                  <Typography style={styles.label}>Le dispositif contient des phases : </Typography> 
                  <Typography>  {(data.phaseok && data.phaseok!=='null') ? (data.phaseok==='O'?'Oui':'Non') : '-'} </Typography>
                </div>
                {(data.phaseok && data.phaseok!=='null' && data.phaseok==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Nombre de phase limité : </Typography> 
                  <Typography>  {(data.nbrephaselimite && data.nbrephaselimite!=='null') ? (data.nbrephaselimite==='o'?'Oui':'Non') : '-'} </Typography>
                </div>}
                {(data.phaseok && data.phaseok!=='null' && data.phaseok==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Chevauchement de phase possible : </Typography> 
                  <Typography>  {(data.chevauchephase && data.chevauchephase!=='null') ? (data.chevauchephase==='o'?'Oui':'Non') : '-'} </Typography>
                </div>}
                {(data.phaseok && data.phaseok!=='null' && data.phaseok==='O')&&<div className={classes.paritem}>
                  <Typography style={styles.label}>Ordonnancement des phases disposnible : </Typography> 
                  <Typography>  {(data.ordonnancementphase && data.ordonnancementphase!=='null') ? (data.ordonnancementphase==='o'?'Oui':'Non') : '-'} </Typography>
                </div>}
                {(data.phaseok && data.phaseok!=='null' && data.phaseok==='O')&&<Table>
                                      <thead>
                                        <tr>
                                          <td>Type de phase</td>
                                          <td>Ordre</td>
                                          <td>Durée fixe</td>
                                          <td>Nb Max phase</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(data.hasOwnProperty('phases') && data.phases!==0)?data.phases.map((phase,index)=><tr>
                                          <td>
                                            <div>
                                              <span>{phase.typephase}</span><br />
                                              <small>{phase.description}</small>
                                            </div>
                                            </td>
                                            <td valign='middle'>{index+1}</td>
                                          <td>{phase.dureephasefixe==='o'?'Oui':"Non"}</td>
                                          <td></td>
                                         
                                        </tr>):<tr>
                                          <td colSpan={3}><p>Aucune phase</p></td>
                                        </tr>}
                                        
                                      </tbody>
                                    </Table>}
            </div>
        </Card>
        <div className={classes.boxpropo}><Button>Proposer le dispositif</Button></div>
    </div>
  )
}

export default DetailsDispositif
