import React, {  useContext, useEffect, useState } from 'react'
import Dash from 'components/dash'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useOutletContext } from 'react-router-dom';
import Dossier from 'containers/Dossier'
import { createTheme } from '@mui/system';
import {Header} from 'components'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, IconButton, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import {Badge} from '@mui/material';
import { useSelector } from 'react-redux';

import axios from 'lib/axios';
import { Table as TableBoostrap } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DocumentsList from './modals/DocumentsList';
import ModDemandeList from './modals/ModDemandeList';
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import { makeStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import {CommonsContext} from 'context/CommonsContext';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


//{createMuiTheme,MuiThemeProvider}
const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
const ActionBarContainer = styled.div`
  display:flex;
  padding:20px;
  justify-content:space-between
`

const BtnDetails =styled.button`
  background:transparent;
  padding:2px 2rem 0 2rem;
  border:1px solid #f8f8f8;
  display:flex;
  font-size:11px;
  flex-direction:column;
  border-radius: 0px 0px 4px 4px;
  flex:1;
  width:100%;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  &:hover{
    background-color:#00B04B;
    width:100%;
    color:white
  }
`
const BtnMore = styled.button`
  background:transparent;
  padding:2px 2rem 0 2rem;
  border:1px solid #707070;
  font-weight:bold;
  border-radius:20px;
  &:hover{
    background-color:#00B04B;
    color:white;
    font-weight:300;
  }
`

const tabletheme = createTheme({
  root:{
    width: "max-content"
  }
});

const useStyles = makeStyles()(theme=>({
  bntAll:{
    backgroundColor:"#00B04B",
    color:'white',
    borderRadius:10,
    padding:10,
    "&:hover":{
      backgroundColor:green[200],
    }
  }
}))

function DossierAccueil() {
 const {classes} = useStyles()
 const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [dossierList,SetDossierList] =useState([])
  const [titre,setTitre] = useOutletContext();
  const [state,setState] = useState({
    "total":0,
    "actif":0,
    "veille":0,
    "sortie":0,
    
  })
  const [cetape,setCetape] = useState({
    "nouveau":0,
    "affecter":0,
    "en traitement":0,
    "traiter":0,
    "non satisfait":0,
    "satisfait":0, 
    "annuler":0 
  })
const [currentDocument,setCurrentDocument] =useState([])
const navigate = useNavigate()
const [paginateData,setPagnateData] = useState(null)
const {handleCommonsdata} = useContext(CommonsContext)

const handleDossierList = (numberPage = 1)=>{
  axios.post(`/api/dossier/list?page=${numberPage}`,{etape:['Nouveau dossier'],statut:['Actif']}).then(res=>{
    if(res.status === 200 || res.status === 201){
      SetDossierList(res.data.data)
      setPagnateData({
        current_page:res.data.meta?.current_page,
        last_page:res.data.meta?.last_page,
        per_page:res.data.meta?.per_page,
        total:res.data.meta?.total
      })
    }
  })
}
const handlesetListDocument = (docs)=>{
  setCurrentDocument(docs)
  handleClickOpen()
}

const handleRefreshState =()=>{
  axios.post(`/api/dossier/statut`).then(res=>{
    if(res.status === 200 || res.status === 201){
      let newstate ={total:0}
      if(res && res.hasOwnProperty('data')){
        let promise  = new Promise (function(resolve,reject){   
           res.data.forEach(element => {
            if(element.statut=='Actif'){
             newstate['actif'] = element.nombre
            }
            if(element.statut=='Veille'){
             newstate['veille'] =element.nombre
            }
            if(element.statut=='Sortie' || element.statut=='Archivé'){
             newstate['sortie'] = element.nombre
            }
            newstate['total'] += parseInt(element.nombre)
            resolve(true)
         })
        });

        promise.then(aa=>{
          setState({...newstate})
        })
      }
    }
  })
  //etape
}
const handleRefleshEtape =()=>{
  axios.post(`/api/dossier/etape`).then(res=>{
    if(res.status === 200 || res.status === 201){
      let newetape ={}
      
      if(res && res.hasOwnProperty('data')){
        let promise  = new Promise (function(resolve,reject){   
           res.data.forEach(element => {
            if(element.etape=='Nouveau dossier'){
              newetape['nouveau'] = element.nombre
            }
            if(element.etape=='Affecter'){
              newetape['affecter'] =element.nombre
            }
            if(element.etape=='En traitement'){
              newetape['en traitement'] = element.nombre
            }
            if(element.etape=='Traiter'){
              newetape['traiter'] = element.nombre
             }
             if(element.etape=='Non satisfait'){
              newetape['non satisfait'] = element.nombre
             }
             if(element.etape=='Satisfait'){
              newetape['satisfait'] = element.nombre
             }
             if(element.etape=='Annuler'){
              newetape['annuler'] = element.nombre
             }
          
            resolve(true)
         })
        });

        promise.then(aa=>{
          setCetape({...newetape})
        })
      }
    }
  })
}

useEffect(()=>{
  handleDossierList()
  handleRefreshState()
  handleRefleshEtape()
  handleCommonsdata("titre","Gesion des dossiers")
},[])

const handleShowDossier =(data)=>{
  navigate(`/${portail.toLowerCase()}/dossiers/fiche/${data['id']}`,{replace:true,state:{data}})
}
const handleShowListe =(mode,etape)=>{
    let compTitre=""
    switch (mode) {
      case 'all':
        break;
      case 'Actif':
        compTitre ="Liste des dossiers actifs"
        break;
      case 'En veille':
        compTitre ="Liste des dossiers en veille"
        break;
      case 'Sortie':
        compTitre ="Liste des dossiers sorties"
        break;
      default:
        break;
    }
    switch (etape) {
      case 'all':
        break;
      case 'Affecterr':
        compTitre ="Liste des dossiers affecter"
        break;
      case 'En traitement':
        compTitre ="Liste des dossiers en traitement"
        break;
      case 'traiter':
        compTitre ="Liste des dossiers traiter"
        break;
      default:
        break;
    }
    setTitre("Gestion des dossiers - "+compTitre)
    navigate(`/${portail.toLowerCase()}/dossiers/liste`,{replace:true,state:{titre:compTitre,statut:mode,etape:etape}})
}
  
  //affichage liste des documents
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handledeletedossier =(currentDossier)=>{
    if(currentDossier){
      swal({
        title: "Voullez-vous vraiment supprimer ce dossier?",
        text: "Cett opération est irreversible, vous perdriez toutes les informations enregistrées à propos de ce dossier !",
        icon: "warning",
        icon: "warning",
        buttons:{
          cancel:"Annuler",
          confirm:'Supprimer'
        },
        dangerMode: true,
      }).then(willDelete=>{
          if (willDelete) {
            axios.delete(`api/dossier/delete/${currentDossier['id']}`).then(res=>{
              if(res.status === 200 || res.status === 201 || res.status === 204){
                swal({text:"Le dossier à été supprimer avec succès",
                  icon: "success",
                  buttons:{
                    confirm:{
                      text:"OK",
                      className:"bg-green-500"
                    }
                  }
                });
                handleDossierList()
              }else{
                swal({
                  title:"Oops",
                  text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
                  icon:"error",
                  buttons:{
                    confirm:{
                      text:"OK",
                      className:'bg-red-500'
                    }
                  }
                })
              }
            }).catch(err=>{
              if(err.hasOwnProperty('response')){
                console.log(err.response);
                swal({
                  title:"Ooops",
                  text:`${err.response['data'] || "Echec! une erreur inconnu s'est produit lors de la suppression"} :)`,
                  icon:"error",
                  buttons:{
                    confirm:{
                      text:"OK",
                      className:'bg-red-500'
                    }
                  }
                })
              }else{
                console.log(err);
                swal({
                  title:"Oops",
                  text:"Echec! une erreur inconnu s'est produit lors de la suppression :)",
                  icon:"error",
                  buttons:{
                    confirm:{
                      text:"OK",
                      className:'bg-red-500'
                    }
                  }
                })
              }
            })
          } else {
            swal({
              text:"Opération annulée :)",
              icon:"error",
              buttons:{
                confirm:{
                  text:"OK",
                  className:'bg-red-500'
                }
              }
            })
        }
      });
     
    }else{
        swal({title:"Oops",
        text:"Veuillez selectionner un dossier et reessayer",
        icon:"warning",
        buttons:{
          confirm:{
            text:"OK",
            className:'bg-red-500'
          }
        }
      })
    }  
  }

  
const [currentData,setCurrentData] = useState(null)
const [opendmd,setOpendmd] = useState(false)
const handleCloseDmd = ()=>{
  setOpendmd(false)
}
const handleOpenDmd = ()=>{
  setOpendmd(true)
}
const showMoreDemande = (data)=>{
  setCurrentData(data)
  handleOpenDmd()
}

  return (
    <>
      <DocumentsList open={open} handleClose={handleClose} documents={currentDocument} />
      <ModDemandeList open={opendmd} handleClose={handleCloseDmd} data={currentData} />
      <Dash>
        <Dash.Inner direction='row'>
            <div className='flex flex-col flex-1 justify-center'>
              <Dash.Text>Dossiers</Dash.Text>

              <div className='flex flex-row flex-wrap items-center'>

                    <div className='flex flex-row flex-wrap'>
                          <Dash.MinCard style={{position:'relative'}}>
                            <Dash.Label>Total</Dash.Label>
                            <Badge> {state.total}</Badge>
                            <BtnDetails onClick={()=>handleShowListe('all')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                          <Dash.MinCard style={{position:'relative'}}>
                            <Dash.Label>Actifs</Dash.Label>
                            <Dash.Text> {state.actif}</Dash.Text>
                            <BtnDetails onClick={()=>handleShowListe('Actif')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                          <Dash.MinCard style={{position:'relative'}}>
                            <Dash.Label>En veille</Dash.Label>
                            <Dash.Text>{state.veille}</Dash.Text>
                            <BtnDetails onClick={()=>handleShowListe('Suspendu')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                          <Dash.MinCard style={{position:'relative'}}>
                            <Dash.Label>Sorties</Dash.Label>
                            <Dash.Text> {state.sortie}</Dash.Text>
                            <BtnDetails onClick={()=>handleShowListe('Sortie')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                    </div>
                    <div className='flex flex-row flex-wrap'>
                        <Dash.MinCard color='#F8F8F8' style={{position:'relative'}}>
                            <Dash.Label color='#F8F8F8'>Nouveau dossier affecter</Dash.Label>
                            <Badge> {cetape['affecter']} </Badge>
                            <BtnDetails onClick={()=>handleShowListe(null,'Affecter')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                          <Dash.MinCard color='#F8F8F8' style={{position:'relative'}}>
                            <Dash.Label>En cours de traitement</Dash.Label>
                            <Badge> {cetape['en traitement']} </Badge>
                            <BtnDetails onClick={()=>handleShowListe(null,'En traitement')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                          <Dash.MinCard color='#F8F8F8' style={{position:'relative'}}>
                            <Dash.Label>Dossier traité du mois</Dash.Label>
                            <Badge> {cetape['traiter']} </Badge>
                            <BtnDetails  onClick={()=>handleShowListe(null,'Terminer')}> <KeyboardArrowDownIcon /></BtnDetails>
                          </Dash.MinCard>
                    </div>
                    <Button className='flex flex-col items-center ml-4 justify-center bg-green-500 rounded-0 py-4 group text-white hover:bg-orange-400 font-bold' onClick={()=>navigate(`/${portail.toLowerCase()}/dossiers/nouveau/express`)}>
                        <AddIcon className='text-[40px]'/>
                        Nouveau dossier
                    </Button>
              </div>
            </div> 
      </Dash.Inner>
 
      <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,marginRight:20,padding:10}}>
        <OfferHeader>
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <OfferTitle>Derniers dossiers crées</OfferTitle>
            <div className='flex flex-row gap-2 items-center'>
              <Button
                style={{backgroundColor:"#00B04B",color:'white',fontWeight:'bold',fontVariant:'normal',borderRadius:0,textTransform:'none', padding:10,"&:hover":{backgroundColor:green[200]}}} 
                onClick={(e)=>handleShowListe(null,'Affecter')}>
                    <AssignmentReturnIcon sx={{marginRight:2}} />
                    Dossiers affecteés
              </Button>
              <Header.Link 
                style={{backgroundColor:"#00B04B",color:'white',fontWeight:'bold', padding:10,"&:hover":{backgroundColor:green[200]}}} 
                to={`/${portail?.toLowerCase()}/dossiers/liste`}>
                    <VisibilityIcon sx={{marginRight:2}} />
                    Afficher tous les dossiers
              </Header.Link>
            </div>
          </div>
            
           
            <Hr />
        </OfferHeader>
        <TableBoostrap>
          <thead>
            <tr>
              <th>Dossier</th>
              <th>Statut</th>
              <th>Traitement ...</th>
              <th>Dernières demandes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {(dossierList.length!==0)?(
            dossierList.map((row,index)=><tr key={index}>
              <td><Dossier data={row} />,</td>
              <td valign='middle'>
                <Chip label={`${row.status || "Inconnu"}`} color={row.status==='Actif'?"success":(row.status==='Suspendu' ?"warning":"error")} />
              </td>
              <td valign='middle'>
                {row.etape==='Nouveau dossier'&&<Chip label={`${row.etape || "Inconnu"}`} color='primary' variant='outlined' />}
                {row.etape==='Affecter'&&<Chip label={`${row.etape || "Inconnu"}`} color='primary' />}
                {row.etape==='En traitement'&&<Chip label={`${row.etape || "Inconnu"}`} color='warning' variant='outlined' />}
                {row.etape==='Terminer'&&<Chip label={`${row.etape || "Inconnu"}`} color='warning' />}
                {row.etape==='Non satisfait'&&<Chip label={`${row.etape || "Inconnu"}`} color='succes' variant='outlined' />}
                {row.etape==='Satisfait'&&<Chip label={`${row.etape || "Inconnu"}`} color='succes' />}
                {row.etape==='Annuler'&&<Chip label={`${row.etape || "Inconnu"}`} color='error' />}
                {(!row.etape && row.etape==='')&&<Chip label={`Inconnu`} color='error' variant='outlined' />}
              </td>
              <td>
                <div>
                  <div>
                {row.demandes&&<List dense>
                    {row.demandes?.map((dossier,index)=>{
                      if(index <= 1 ){
                        return <ListItem key={`${index}-${dossier.id}`}>
                         {/*<ListItemIcon>
                          <Chip label={dossier['statut']?(dossier['statut'].charAt(0).toUpperCase() + dossier['statut'].slice(1)) : ""} color={dossier['statut']==='En attente'?"primary":(dossier['statut']==='En traitement'?"warning":(dossier['statut']==='satisfait'?"success":"error"))} />
                      </ListItemIcon>*/}
                        <ListItemText
                          primary={<div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                              <Typography sx={{marginRight:10}}>{dossier.type?.libelle?dossier.type?.libelle : ""}</Typography>
                             
                          </div>}
                          secondary={<div style={{marginLeft:20}}>
                          <span style={{fontWeight:'bold'}}>{ formatDistance(new Date(dossier.created_at), new Date(), { addSuffix: true,locale:fr })}</span>
                          
                </div> }
                        />
                      </ListItem>
                      }else if(index === 2){
                        return <div style={{display:'flex',justifyContent:'center'}}><BtnMore onClick={()=>showMoreDemande(row)}>
                          <p>... toutes les demandes</p>
                        </BtnMore></div>
                      }else{
                        return false
                      }  
                      
                    })}
                  </List>}  
              </div>
              </div></td>
              <td valign='middle'>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <Tooltip title="Détail du dossier">
                    <IconButton onClick={(e)=>handleShowDossier(row) }>
                        <VisibilityIcon sx={{fontSize:30}} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Documents attachés">
                    <IconButton onClick={(e)=>handlesetListDocument(row.jeune.documents) }>
                        <AttachFileIcon sx={{fontSize:30}} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer le dossier">
                    <IconButton  onClick={(e)=>handledeletedossier(row)}>
                        <DeleteIcon  sx={{fontSize:30}}/>
                    </IconButton>
                  </Tooltip>
                  
                </div>
              </td>
              
              </tr>)
            
          ) : (<tr><td colSpan={3}><p>Aucun dossier trouvé</p></td></tr>)}
          </tbody>
        </TableBoostrap>
      </Dash.Frame>
    </Dash>
    </>
    )
}

export default DossierAccueil