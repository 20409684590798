import React,{useReducer,useContext, useEffect, useState} from 'react'
import { Card,Form } from 'components'
import { AddContext } from 'context/add'
import {styled as  makeStyles} from "@mui/material";
import { doctype } from 'fixtures/data';
import {useDropzone} from 'react-dropzone';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {CardActionArea} from '@mui/material';
import { Typography } from '@mui/material';
import { getExtension } from 'utils/utils';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'lib/axios';

const useStyles = makeStyles(theme=>({
  content_img:{
    display:'flex',
    flexDirection:'column',
    flex:1,
    position:'relative'
},
photo_label:{
    fontSize:15,
    fontStyle:'italic',
    fontWeight:'bold'
},
logo_box:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border:'1px dashed #707070',
    minHeight:'150px;',
    background: 'rgba(244,244,244,0.6)',
    position:'relative',
    marginBottom:20
},
photo_check:{
    minWidth:'100%',
    minHeight:'100%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
},
input: {
    display: "none"
  },
img_card:{
    minWidth:'60%',
    minHeight:'60%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
}
}))
function StepsDocuments({dossier,handleBack,reflesh}) {
  const [documents,setDocuments] = useState([])
  const classes =useStyles()
  const [type,setType] = React.useState()
  const [docfile,setDocfile] =useReducer((state, newState) => ({ ...state, ...newState }),
  {
    do_doctype: "",
    selectedFile: null
  }); 
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: files => {
        setDocfile({
            ['selectedFile']: files.map((file) => Object.assign(file, { preview:  URL.createObjectURL(file)}))
        });
    }
  });
  const handleFormChange =(event)=>{
    const {name,value} =event.target
    setDocfile({
      [name]:value
    })
  }
  
  const imageResetHandler = event => {
    setDocfile({
      ['selectedFile']: null,
    });
    event.stopPropagation()
  };
  useEffect(() => {
    if(docfile.hasOwnProperty('selectedFile') && docfile.selectedFile){
       return ()=>docfile.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [docfile]);

  const thumbs = (docfile.hasOwnProperty('selectedFile') && docfile.selectedFile)&&docfile.selectedFile.map(file => (
    getExtension(file.name).toLowerCase()=='pdf'?<IconButton onClick={imageResetHandler} ><FilePresentIcon sx={{fontSize:60,color:'#888888'}}/> </IconButton>: <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
        <img
            width="40%"
            className={classes.media}
            src={file.preview}
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
    </CardActionArea>  
  ));
  const addFile =(e)=>{
    e.preventDefault()
    if(docfile['do_doctype']!=="" && docfile['selectedFile']){
      let nexfile =documents?[...documents,docfile]:[docfile]
      setDocuments([...nexfile])
    }
  }
const handleSave =(e)=>{
  e.preventDefault()
  if(!documents || documents.length===0){
    swal("Ooops!","Veuillez ajouter au moins un documents",'warning')
    return
  }
  let formdata = new FormData()

  if(documents && Array.isArray(documents) ){
    documents.forEach(element => {
      formdata.append('documents[]',element.selectedFile[0])
      formdata.append('documentstitle[]',element.do_doctype)
    });
   
}
  axios.post(`/api/dossier/add-documents/${dossier.id}`,formdata).then(res=>{
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
}
  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20, minHeight:'100%'}}>
    
    <Card>
          <Form style ={{display:'flex',flexDirection:'column'}} >
            <Form.Group  margin='10px 0px 0px 0px'direction ='column' flex={1} >
                        <Form.Input 
                            name='do_doctype' 
                            size="small" 
                            margin="dense" 
                            style={{marginRight:20}} 
                            select 
                            value ={docfile['do_doctype']||""} 
                            onChange={handleFormChange}  label="Titre du document" wd='100%'>
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {(doctype && doctype.lenght!==0)&& doctype.map(type=>(<MenuItem value={type}>{type}</MenuItem>))}
                        </Form.Input>
                       
                  </Form.Group>
                <Form.Group margin='10px 0px 0px 0px'direction ='column' flex={1}>
                <div className={classes.content_img}>
                        <Typography className={classes.photo_label}>Cliquez ou glissez déposer vos fichier ici</Typography>
                        <div className={classes.logo_box} {...getRootProps()}>
                        
                            {thumbs}
                       
                            <input
                                {...getInputProps()}
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                name='photo'
                                disabled={!docfile.selectedFile}
                                />
                                {!docfile.selectedFile&&<label className={classes.photo_check} htmlFor="contained-button-file">
                                    <CloudDownloadIcon sx={{fontSize:60,color:'#888888'}} />
                                </label>}
                        </div>
                    </div>
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign='flex-end' flex={1}>
                  <Form.RButton color='#556B2F' onClick={addFile}>Ajouter</Form.RButton> 
             </Form.Group>
          </Form>
         {(documents && documents.length!==0)&&<div>
            <Form.Hr style={{marginTop:20}} />
            <Typography>Liste des documents</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  { documents.map((doc,indx)=>{
                    return <TableRow key={indx}>
                        <TableCell align="left">{doc.do_doctype}</TableCell>
                          <TableCell align="center">{
                            (doc.hasOwnProperty('selectedFile') && doc.selectedFile)&&doc.selectedFile.map(file=>(getExtension(file.name).toLowerCase()=='pdf'?<FilePresentIcon sx={{fontSize:60,color:'#888888'}}/>:
                            
                            <img
                                width="20%"
                                className={classes.media}
                                src={file.preview}
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                      
                  ))}</TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                  </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>} 
          
        </Card>
        <Card direction='row' palign='space-between'>
          <Form.Button onClick={handleBack} >Retour</Form.Button>
         <Form.Button onClick={handleSave} primary>Enregistrer</Form.Button> 
    </Card>
      </div>
  )
}

export default StepsDocuments