
import Home from 'pages/frontend/home/Home';
import About from 'pages/frontend/about/About';
import ArticleList from 'pages/frontend/actualites/ArticleList';
import Organe from 'pages/frontend/organes/Organe';
import Contact from 'pages/frontend/contact/Contact';
import Partenaires from 'pages/frontend/partenaires/Partenaires';
import { Page404 } from 'pages/errors';
import Medias from 'pages/frontend/medias/Medias';
import Inscription from 'pages/frontend/inscription/Inscription';
import OffreList from 'pages/frontend/offres/OffreList';
import Offre from 'pages/frontend/offres/Offre';
import OffrePostuler from 'pages/frontend/offres/OffrePostuler';
const routes = [
    { path: '/',  index:true, name: 'Accueil', component: Home},
    { path: 'a-propos',  name: 'A propos', component: About },
    { path: 'actualites',  name: 'Actualités', component: ArticleList },
    { path: 'organes',  name: 'Organes', component: Organe },
    { path: 'partenaires',  name: 'Partenaires', component: Partenaires },
    { path: 'contact',  name: 'Contact', component: Contact},
    { path: 'offres/:type?',  name: 'Offres', component: OffreList },
    { path: 'offres/offre/:id',  name: 'Mon offre', component: Offre },
    { path: 'offres/postuler/:offre',  name: 'Postuler à une offres', component: OffrePostuler },
    { path: 'mediatheque/:type',  name: 'mediatheques', component: Medias },
    { path: 'inscription',  name: 'Inscription', component: Inscription },
    { path: '404',  name: '404', component: Page404 },
];


export default routes