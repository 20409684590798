import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
  }
    html,body{
        font-family: 'Helvetica Neue', Helvetica,Arial,sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color:rgb(245, 246, 248);
        color:#333333;
        font-size:16px;
        height:100vh;
    }

`