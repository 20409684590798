import React from 'react'
import {Breadcrumbs as MUIBreadcrumbs,Typography} from '@mui/material';
import Link from '@mui/material/Link';

import {
    useLocation,
    useNavigate,
  } from "react-router-dom";
import { useSelector } from 'react-redux';
function Breadcrumbs(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const portail =useSelector(state=>state.ui.portail)
    const {pathname} = location
    const pathnames = pathname.split('/').filter(x=>x)
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" style={{margin:'10px 20px'}}>
      {pathnames.length > 0 ? (
        <Link onClick={()=>navigate(`/${portail}`)}>Accueil</Link>

      ):(<Typography color="text.primary">Accueil</Typography>)}
       {pathnames.map((name,index)=>{
          const routeTo =`${pathnames.slice(0, index + 1).join('/')}`
          const isLast = index ===pathnames.length-1
          return isLast ? ( <Typography style={{color:'#00B04B'}} key={name} color="text.primary">{name}</Typography>):(
          <Link key={name} onClick={()=>navigate(`/${routeTo}`)}>{name}</Link>)
       })}
      </MUIBreadcrumbs>
  )
}

export default Breadcrumbs