import React,{Fragment} from 'react'
import {styled as  makeStyles} from "@mui/material";
import { Radio,RadioGroup,FormControlLabel,FormControl,FormLabel } from '@mui/material'
import { string, oneOf, arrayOf, shape, bool } from "prop-types";

const useStyles = makeStyles(() => ({
    formControl: props => props.appearance.formControl,
    groupControl: props => props.appearance.groupControl,
    informationControl: props => props.appearance.informationControl,
    radioControl: props => props.appearance.radioControl
  }));
  export default function RadioField(props) {
    const { group, groupItems,vvalue,name,onChange } = props;
    const {
      formControl,
      groupControl,
    } = useStyles(props);
   
    const handleChange = event => {
      onChange(event);
    };

    return (
      <div>
        {groupItems.length && (
          <FormControl component="fieldset" className={formControl}>
            <FormLabel component="legend" className={groupControl}>
              {group}
            </FormLabel>
            <RadioGroup
              aria-label={name}
              name={name}
              value={vvalue}
              onChange={handleChange}
              row
            >
              {groupItems.map((item, index) => (
                <Fragment key={index}>
                  <FormControlLabel
                    value={item.name}
                    control={
                      <Radio color='success'/>
                    }
                    label={item.label}
                    disabled={item.disabled && "disabled"}
                  />
                 
                </Fragment>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </div>
    );
  }

  export const radiobutton_appearances = {
    primary: {
      formControl: {
        margin: "24px"
      },
      groupControl: {
        color: "#6a7070",
        "&:first-letter": {
          textTransform: "capitalize"
        }
      },
      informationControl: {
        color: "#6a7070",
        "&:first-letter": {
          textTransform: "capitalize"
        }
      },
      radioControl: {
        color: "#047a9c",
        "&:checked": {
          color: "#047a9c"
        }
      }
    }
  };
  
  RadioField.propTypes = {
    groupItems: arrayOf(
      shape({
      name: string.isRequired,
        label: string,
        information: string,
        disabled: bool
      })
    ),
    appearance: oneOf(Object.values(radiobutton_appearances)),
    group: string,
    checkedDefault: string
  };
  
  RadioField.defaultProps = {
    groupItems: {
      label: null,
      name: null,
      information: "",
      disabled: false
    },
    appearance: radiobutton_appearances.primary,
    group: null,
    checkedDefault: null
  };