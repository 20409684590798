import { Chip, Divider, Paper } from '@mui/material'
import React, { useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import swal from 'sweetalert';
import axios from 'lib/axios';
import * as XLSX from 'xlsx'
import Spinner from 'react-bootstrap/Spinner';
import { makeStyles } from 'tss-react/mui';
import { Card } from 'components';
const useStyles = makeStyles()(theme=>({
    btnAdd:{
      paddingLeft:20,
      paddingRight:20,
      backgroundColor: '#00B04B',
      color:'white',
      marginRight:20
    },
    searchBox:{
      display: 'flex',
      padding:20,
      marginBottom:20,
      flexDirection: 'column',
    },
  }))
 
const ImportModule = (props) => {
    const classes = useStyles()
    const [data,setData] = React.useState(null)
    const [loading,setLoading] =useState(false)
    const handleFileUpload =(e)=>{
        setLoading(true)
        const reader = new FileReader()
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          const bstr = e.target.result;
          const workbook = XLSX.read(bstr,{ type: rABS ? "binary" : "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(sheet,{raw: false,defval:"",dateNF:'dd/mm/yyyy'})
          setData(parsedData)
         
        }
        if (rABS) reader.readAsBinaryString(e.target.files[0]);
        else reader.readAsArrayBuffer(e.target.files[0]);
        setLoading(false)
      }
      const handleSave =()=>{
        setLoading(true)
          if(data && data.length!==0){
          
            axios.post(`/api/modules/import-privileges`,{data}).then(res=>{
              if(res.status === 200 || res.status ===201){
                if(res){
                  setLoading(false)
                  setData(null)
                  swal("Bien !","Importation terminée","success")
                }else{
                  setLoading(false)
                  swal("Ooops!","Erreur lors de l'enregistrement","error")
                }
              }
            }).catch(err=>{
              if(err.hasOwnProperty('response')){
                console.log(err.response.data)
                swal("Ooops!",err.response.data.message,"error")
              }else{
                console.log(err)
                swal("Ooops!","Erreur lors de l'enregistrement","error")
              }
              setLoading(false)
              
            })
          }
      }
  return (
    <div className='container md:max-w-screen-sm mx-auto'>
         <Card>
                <Card.Header>
                    <Card.Title>IMPORTATION MODULES</Card.Title>
                </Card.Header>
        </Card>
        <Paper className={classes.searchBox} variant='outlined'>
          <Form.Group controlId="formFile" className="mb-3">
              <Form.Control size="lg" type="file" onChange={handleFileUpload} accept=".xlsx,xls" />  

          </Form.Group>
          <div style={{display:'flex',justifyContent:'center'}}>{loading&&<Spinner animation="border" />}</div>
        </Paper>
        <Paper>
         {(data && data.length!==0) &&<div className='flex justify-between py-2 px-2'>
          <Chip label={`${(data.length -1)}  Ligne(s)`} variant="outlined" />
               
          <Button disabled={loading} variant="outline-success" id="button-addon2" className="bg-green-400 hover:bg-orange-400" onClick={handleSave}>
            {loading?<Spinner />:"Enregistrer"} 
          </Button>
          </div>}
          <Divider className='my-3' />
        {(data && data.length!==0)&&<Table striped bordered hover>
                  <thead>
                    <tr>
                      {Object.keys(data[1]).map((key)=>(
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row,index)=>(
                      <tr key={index}>
                        {Object.values(row).map((value,idx)=>(<td key={idx}>{value}</td>))}
                      </tr>
                    ))}
                  </tbody>
                </Table>}
        </Paper>
    </div>
  )
}

export default ImportModule