import React from 'react'
import Flink from '../components/Flink'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux';
const ConnexionBox = ({shIns,shCnx}) => {
    const {user}  =useAuth({ middleware: 'auth' })
    const portail = useSelector(state=>state.ui.portail)
  return (
    <>
        {!(user && user?.name && !shCnx)&&<Flink to="/connexion" color="btn-primary">Connexion</Flink>}

        {!(user && user?.name && !shIns)&&<Flink to="/inscription" color="btn-secondary">Inscription</Flink>}
        {user?.name&&<Flink to={`/${portail.toLowerCase()}`} color="btn-primary">Acceder à mon compte</Flink>}
        
    </>
  )
}

export default ConnexionBox