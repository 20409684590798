import React from 'react'
import Fabout from '../components/fabout/Fabout'
import Fstats from '../components/fstats/Fstats'
import Fteams from '../components/fteams/Fteams'
import Flink from '../components/Flink'
import ConnexionBox from '../components/ConnexionBox'
function About() {
  return (
    <>
        <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">A propos</h1>
                        <ConnexionBox />
                    </div>
                </div>
            </div>
        </div>
        <Fabout />
        <Fstats />
        <Fteams />
    </>
  )
}

export default About