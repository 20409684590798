import React,{useContext,useEffect,useState} from 'react'
import { Card } from 'components'
import { Form } from 'components'
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AddContext } from 'context/add'
import {villes,communes} from 'fixtures/data'
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';
import axios from 'lib/axios';
import swal from 'sweetalert';
function StepsCoordonnes({personne,handleBack,reflesh}) {
  const [inputfield,setInputField] = useState({...personne})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputfield,[name]:value})
  }
  const [quartiers,setQuartiers] = useState(null)
  const [cqvcgv,setcqvcgv] = useState(null)
  useEffect(()=>{
    axios.get(`/api/quartier/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setQuartiers(res.data)
      }
    })
    axios.get(`/api/comite/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setcqvcgv(res.data)
        setCurrentcg(res.data)
      }
    })
  },[])
  const [currentcg,setCurrentcg]=React.useState([])
  const handlefiltercd =()=>{
    if(inputfield.hasOwnProperty('quartier') && cqvcgv){
      const newcg = cqvcgv.filter(item=>item.quartier?.nom === inputfield['quartier'])
      setCurrentcg(newcg)
    }else{
      setCurrentcg(cqvcgv)
    }
  }
  useEffect(()=>{
    handlefiltercd()
  },[inputfield.quartier,cqvcgv])
 
const handleSave =(e)=>{
  e.stopPropagation()
  e.preventDefault()
  
  axios.post(`/api/personne/update/${personne.id}`,inputfield).then(res=>{
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
}
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
        <form onSubmit={handleSave}>
          <Card>
          
                <Form.Group direction ='row' flex={1}>
                  <Form.Input 
                      size="small" 
                      margin="dense" 
                      select 
                      required
                      style={{marginRight:20}}  
                      label="Ville de résidence"
                      value={inputfield['lieuresidence'] ||""}
                      name='lieuresidence'
                      defaltValue ='Abidjan'
                      sx={{ width: '100%' }}
                      onChange={handleChange}
                      >
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem value={ville}>{ville}</MenuItem>))}
                    </Form.Input>
                </Form.Group>
                <Form.Group direction ='row' flex={1} margin='10px 0 0 0'>
                  <Autocomplete
                    disablePortal
                    id="commune"
                    size="small" 
                    margin="dense"
                    required
                    defaultValue='Port-Bouët'
                    options={communes}
                    sx={{ width: '100%' }}
                    style={{marginRight:20}}  
                    value={inputfield['commune'] ||""}
                    name='commune'
                    onChange={(event,v)=>setInputField({...inputfield,'commune':v})}
                    renderInput={(params) => <Form.Input {...params} name='commune' label="Commune" />}
                    />
               </Form.Group>
                <Form.Group  margin='10px 0 0 0' direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      select 
                      style={{marginRight:20}}  
                      label="Quartier"
                      value={inputfield['quartier'] ||""}
                      name='quartier'
                      onChange={handleChange}
                      >
                        <MenuItem  value='No'></MenuItem>
                        {(quartiers && quartiers.lenght!=0)&&quartiers.map(quartier=>(<MenuItem key={quartier.id} value={quartier.nom}>{quartier.nom}</MenuItem>))}
                        
                    </Form.Input>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      select 
                      style={{marginRight:20}}  
                      label="CGQ/CGV"
                      value={inputfield['cgvcgq'] ||""}
                      name='cgvcgq'
                      onChange={handleChange}
                      >
                        <MenuItem  value='No'></MenuItem>
                        {(currentcg && currentcg.lenght!=0)&&currentcg.map(cg=>(<MenuItem key ={cg.id} value={cg.nom}>{cg.nom}</MenuItem>))}
                        
                    </Form.Input>
                  </Form.Group>
             
              </Card>
              <Typography  sx={{ fontStyle: 'italic' }} variant='h6'>Contacts</Typography>
              <Card>
       
            <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                  <Form.Input 
                    size="small" 
                    margin="dense" 
                    label="Adresse postale" 
                    wd='100%' 
                    value={inputfield['bp'] ||""}
                    name='bp'
                    onChange={handleChange}
                    />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Téléphone fiixe" 
                      value={inputfield['telephonefixe'] ||""}
                      name='telephonefixe'
                      onChange={handleChange}
                      />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Mobile 1" 
                      required
                      value={inputfield['mobile1'] ||""}
                      name='mobile1'
                      onChange={handleChange}
                      />
                    <Form.Input 
                      size="small" 
                      margin="dense"  
                      label="Mobile 2" 
                      value={inputfield['mobile2'] ||""}
                      name='mobile2'
                      onChange={handleChange}
                      />
               </Form.Group>
               <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                    <Form.Input 
                      size="small" 
                      margin="dense" 
                      style={{marginRight:20}}  
                      label="Numéro whatsapp" 
                      value={inputfield['numerowhatsapp'] ||""}
                      name='numerowhatsapp'
                      onChange={handleChange}
                      />
               </Form.Group>
               <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                  <Form.Input  
                    size="small" 
                    margin="dense" 
                    label="Adresse email" 
                    wd='100%'
                    type="email" 
                    value={inputfield['email'] ||""}
                    name='email'
                    onChange={handleChange}
                    />
              </Form.Group>
            
      </Card>
      <Card direction='row' palign='space-between'>
        <Form.Button onClick={handleBack} >Annuler</Form.Button> 
              
        <Form.Button type='submit' primary>Enregistrer</Form.Button> 
      </Card>
      </form>
      </div>
      </LocalizationProvider>
  )
}

export default StepsCoordonnes