import { useAuth } from 'hooks/auth';
import React,{useEffect} from 'react'
import { Link,useNavigate,useOutletContext } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useJeune } from 'hooks/jeune';
import { zerofilled } from 'lib/application';
import { useSelector } from 'react-redux';

const DashJeune = (props) => {
    const {user} =useAuth()
    const {jeune,jeuneInfos:{dossier,stats}} = useJeune(user?.personne_id)
    const [myref] = useOutletContext();
    const navigate =useNavigate()
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    useEffect(()=>{
    const script = document.createElement("script");
    script.src = "./lib/chart/chart.min.js";
    if(myref.current !== undefined){
      script.async = true;
      myref.current.appendChild(script);
    }
    return ()=>{
      if(myref && myref.hasOwnProperty('current') && myref.current && myref.current !== undefined){
        myref.current.removeChild(script);
      }
    }
  })

  return (
    <div className='w-screen-sm mx-auto p-2'>
        <div className='flex my-4 flex-row gap-2 items-center'>
            <DashboardIcon className='text-green-600' sx={{fontSize:40}} />
            <h1 className='text-semibold text-green-600'>Tableau de bord</h1>
        </div>
        <div className="py-4 px-2">
                <div className="row  flex flex-row flex-wrap">
                    <div className="flex-1 cursor-pointer">
                        <div className="bg-white rounded d-flex align-items-center justify-content-between p-4">
                            <i className="fa fa-chart-line fa-3x text-success"></i>
                            <div className="ms-3">
                                <p className="mb-2">Demandes</p>
                                <h6 className="mb-0">{stats&&zerofilled(stats?.demandes,stats?.demandes?.length || 2)}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 cursor-pointer">
                        <div className="bg-white rounded d-flex align-items-center justify-content-between p-4">
                            <i className="fa fa-chart-bar fa-3x text-success"></i>
                            <div className="ms-3">
                                <p className="mb-2">Proposition d'emploi</p>
                                <h6 className="mb-0">{stats&&zerofilled(stats?.emplois,stats?.emplois.length || 2)}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 cursor-pointer">
                        <div className="bg-white rounded d-flex align-items-center justify-content-between p-4">
                            <i className="fa fa-chart-area fa-3x text-success"></i>
                            <div className="ms-3">
                                <p className="mb-2">Candidature</p>
                                <h6 className="mb-0">{stats&&zerofilled(stats?.candidatures,stats?.candidatures.length || 2)}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 cursor-pointer">
                        <div className="bg-white rounded d-flex align-items-center justify-content-between p-4">
                            <i className="fa fa-chart-pie fa-3x text-success"></i>
                            <div className="ms-3">
                                <p className="mb-2">Formations</p>
                                <h6 className="mb-0">{stats&&zerofilled(stats?.formations,stats?.formations.length || 2)}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 px-2">
                <div className="flex flex-row flex-wrap gap-2">
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/mon-dossier`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                              <i className="fa fa-folder fa-3x"></i>
                                <h5 className="mb-0">Mon dossier</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/demandes/Ajouter-demande`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                                <i className="fa fa-edit fa-3x"></i>
                                <h5 className="mb-0">Rediger une demandes</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/documents`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                            <i className="fa fa-file-archive fa-3x"></i>
                                <h5 className="mb-0">Mes documents</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 px-2">
                <div className="flex flex-row flex-wrap gap-2">
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/emplois`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                                <i className="fa fa-user-nurse fa-3x"></i>
                                <h5 className="mb-0">Offres d'emploi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" onClick={()=>navigate(`/${portail}/formations`)}>
                        <div className="bg-light text-center rounded p-4" style={{cursor:'pointer'}}>
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                            <i className="fa fa-user-graduate fa-3x"></i>
                                <h5 className="mb-0">Offres de formations</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" onClick={()=>navigate(`/${portail}/dispositifs`)}>
                        <div className="bg-light text-center rounded p-4" style={{cursor:'pointer'}}>
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                            <i className="fa fa-servicestack fa-3x"></i>
                                <h5 className="mb-0">Dispositifs d'accompagnement</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 px-2">
                <div className=" flex flex-row flex-wrap gap-2">
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/messages`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                              <i className="fa fa-message fa-3x"></i>
                                <h5 className="mb-0">Message</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/rendez-vous`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                              <i className="fa fa-message fa-3x"></i>
                                <h5 className="mb-0">Rendez-vous</h5>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1" style={{cursor:'pointer'}} onClick={()=>navigate(`/${portail}/autre-evenements`)}>
                        <div className="bg-light text-center rounded p-4">
                            <div className="d-flex flex-column align-items-center justify-content-between mb-4">
                              <i className="fa fa-message fa-3x"></i>
                                <h5 className="mb-0">Evenements</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default DashJeune
