import styled from "styled-components/macro"
import {Link as RouterLink} from 'react-router-dom'

export const Container =styled.div`
    display:flex;
    flex:1;
    width:100%;
    background-color: #ffffff;
    justify-content: space-between;
    top:0;
    overflow:hidden;
    border-bottom:5px solid #00B04B;
    padding-top:10px;
    padding-bottom:10px;
`
export const Inner =styled.div`
    display:flex;
    flex:${({flex})=>flex||1};
    border-radius:20px;
    background-color:#F8F8F8;
    flex-direction:${({direction})=>direction||'column'};
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
    margin-left:20px;
`


export const Link = styled(RouterLink)`
    transition:all .3s ease;
    color:#707070;
    font-size: 1em;
`

export const Texte = styled.h1`
    font-size: 1.35rem;
    color:#00B04B;
`


export const Group = styled.div`
    display:flex;
    flex-grow:1;
    padding-left:20px;
    flex-direction:${({direction})=>direction||'row'};
    align-items:${({salign})=>salign||'flex-start'};
    justify-content:${({palign})=>palign||'flex-start'};
`
export const Button  = styled.button`
    display:flex;
    color:white;
    background-color:#00B04B;
    width:100%;
    margin-top:20px;
    justify-content:center;
    align-items:center;
    padding:20px;
    border:none;
    cursor:pointer;
    font-size:18px;
    &:hover {
        outline: none;
        box-shadow: 0px 0px 5px #00B04B;
    }  
`

export const ButtonAdd  = styled.button`
    display:flex;
    color:#00B04B;
    background-color:white;
    justify-content:center;
    align-items:center;
    padding:20px;
    border:none;
    cursor:pointer;
    font-size:18px;
    border:2px dashed #707070; 
    &:hover {
        background-color:#00B04B;
        color:white;
    }  
`

export const Input  = styled.input`
    background-color:#F8F8F8;
    top:0;
    border: none;
    width:100%;
    height:40px;
    padding:0px 10px;
    font-size:16px;
    border-left:1px solid #00B04B;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 2px #00B04B;
    }
`

export const LightButton = styled.button`
    transition:all .3s ease;
    color:#707070;
    font-size: 1em;
    border:none;
    background:transparent !important;
    cursor:pointer;
`