import React, { useContext, useEffect, useReducer,useState} from 'react'
import Typography from '@mui/material/Typography';
import styled from 'styled-components'
import { Card } from 'components'
import { useNavigate } from "react-router-dom";
import axios from 'lib/axios'
import swal from 'sweetalert'
import dayjs from 'dayjs'
import { makeStyles } from 'tss-react/mui'
import { Form } from 'components'
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {villes,communes,doctype} from 'fixtures/data'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import Checkbox from '@mui/material/Checkbox';
import {Pagination, FormControlLabel, Divider } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useDropzone} from 'react-dropzone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {CardActionArea,Paper} from '@mui/material';
import { getExtension } from 'utils/utils';
import { useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent'
import { fichieInscription } from 'lib/application';
import { CommonsContext } from 'context/CommonsContext';
import { Button } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { pays,secteuractivites,niveauetudes,niveauexperience } from 'fixtures/data'
import CircularProgress from '@mui/material/CircularProgress';
const groupItems=[
  {
    name: "cursus",
    label: "Parcours scolaire",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "formation",
    label: "Formations",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "experience",
    label: "Expériences professionnelles",
    information: "",
    disabled: false
  }
]
const prioriteItems=[
  {
    name: "Haute",
    label: "Haute",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "Normal",
    label: "Normal",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
      name: "Bas",
      label: "Bas",
      information: "Veuillez selectionner un type",
      disabled: false
    }
]

const Container = styled.div`
  display:flex;
  margin-right:20px;
`
const Title =styled.h4`
    color:#00B04B;
  `
  const SmallTexte = styled.p`
    color:#00B04B;
    font-size:10px;
    font-style:italic
  `
const useStyles = makeStyles()(theme=>({
  rbox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    position:'relative',
    width:'60%',
    marginRight:'auto',
    marginLeft:'auto',
    [theme.breakpoints.down("md")]:{
      width:'75%',
    },
    [theme.breakpoints.down("sm")]:{
      width:'100%',
    },
    [theme.breakpoints.down("xs")]:{
      width:'100%',
    }
  },
  stepper: {
    padding: theme.spacing(3, 5, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  root: {
    backgroundColor: '#eaeaf0',
    padding: 10,
    borderRadius: '50%',
    width:50,
    height:50,
    textAlign:'center'
  },
  active: {
    color: 'white',
    backgroundColor:'#00B04B'
  },
  completed: {
    color: 'green',
  },
  content_img:{
    display:'flex',
    flexDirection:'column',
    flex:1,
    position:'relative'
},
photo_label:{
    fontSize:15,
    fontStyle:'italic',
    fontWeight:'bold'
},
logo_box:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border:'1px dashed #707070',
    minHeight:'150px;',
    background: 'rgba(244,244,244,0.6)',
    position:'relative',
    marginBottom:20
},
photo_check:{
    minWidth:'100%',
    minHeight:'100%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
},
input: {
    display: "none"
  },
img_card:{
    minWidth:'60%',
    minHeight:'60%',
    position:'absolute',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
},boxOffres:{
  display:'flex',
  flexWrap:'wrap',
  flex:1,
  padding:20
},
cardOffres:{
flex:1,
margin:10,
padding:20,
display:'flex',
flexDirection:'column',
justifyContent:'center',
alignItems:'center',
cursor:'pointer',
'&:hover':{
    backgroundColor:'#00B04B',
    color:'white',
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
}
},
cardOffresCheck:{
flex:1,
margin:10,
padding:20,
display:'flex',
flexDirection:'column',
justifyContent:'center',
alignItems:'center',
cursor:'pointer',
backgroundColor:'#00B04B',
color:'white',
boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

},
nextSetp:{
  backgroundColor:'#00B04B',
  padding:10,
  border:'none',
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  color:'white',
  marginBottom:20,
  "&:hover":{
    border:'none',
    backgroundColor:'#F29400',
    color:'white',
  }
}
}))





function DossierAddSimplifier() {
  const {classes} = useStyles();
  const [loading,setLoading] = useState(false)
  //Année diplome
  const curentDate = new Date()
  const anneeStart  = (curentDate.getFullYear())-40
  const anneeEnd  = curentDate.getFullYear()
  const annee =()=>{
    const rows =[]
    for (let i = anneeEnd; i >= anneeStart; i--){
        rows.push(<MenuItem key={i} style={{fontWeight:'bold'}} value={i}>{i}</MenuItem>)
    }
    return rows
  }


  const {handleCommonsdata} =useContext(CommonsContext)
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [inputfield,setInputField] = useState({
    datenaissance:new Date('2014-08-18T21:11:54'),
    parcours:[],
    quartier:'',
    paysdiplome:"Côte d'ivoire",
    nationalite:'ivoirienne'
  })
  const handleInputChange = (e)=>{
    const {name,value} = e.target
    console.log(e);
    setInputField({...inputfield,[name]:value})
  }
  const onAutocompleteChange =(e,v)=>{
    setInputField({...inputfield,'commune':v})
  }
  //date field echange
  const handleChange = (name,newValue) => {
    setInputField({...inputfield,[name]:newValue})
  };
  const [activeStep,setActiveStep] = useState(1)
  const handleNext =()=>{
    setActiveStep(activeStep + 1)
  }
  const handleBack = ()=>{
    setActiveStep(activeStep - 1)
  }
  const [paginateData,setPagnateData] = useState(null)
  const [dispositifs,setDispositifs] = useState([])
  const handleDispositif = (numberPage = 1)=>{
    axios.get(`/api/dispositif/list?page=${numberPage}`).then(res=>{
      if(res.status===200 || res.status === 201){
        setDispositifs(res.data.data)
        setPagnateData({
          current_page:res.data.meta?.current_page,
          last_page:res.data.meta?.last_page,
          per_page:res.data.meta?.per_page,
          total:res.data.meta?.total
        })
      }
    })
  }
  const [cursusField,setCursusField] = useState({
    type:null,
    encours:false,
    datedebut:null,
    datefin:null,
    diplome:null,
    etablissement:null,
    mission:null,
  })

  const handleChangeCursus =(e)=>{
    e.preventDefault()
    const {name,value} =e.target
    setCursusField({...cursusField,[name]:value})
  }
 
const [cursusData,setCursusData] = useState([])
const [expData,setExpData] = useState([])
useEffect(()=>{
  if(inputfield.hasOwnProperty('parcours') && inputfield.parcours.length !== 0){    
    setCursusData(inputfield.parcours.filter(item=>(item.type === 'cursus' || item.type === 'formation')))
    setExpData(inputfield.parcours.filter(item=>item.type === 'experience'))
  }else{
    setCursusData([])
    setExpData([])
  }
},[inputfield.parcours])


const handleCursusData =(e)=>{
  e.preventDefault()
  let currentData ={...cursusField}
  if(currentData.type && currentData.diplome){
    let dataparcour
    if(inputfield.hasOwnProperty('parcours')){
      dataparcour = [...inputfield.parcours]
    }else{
      dataparcour=[]
    }
    currentData['id'] = dataparcour.length+1

    dataparcour =[...dataparcour,currentData]
    setInputField({...inputfield,'parcours':dataparcour})
    setCursusField({
      type:null,
      encours:false,
      datedebut:null,
      datefin:null,
      diplome:null,
      etablissement:null,
      mission:null,
     
    })
  }
}
const handleCheckboxChange =(name)=>{setCursusField({...cursusField,[name]:!cursusField[name]})}

const handleDeleteCurssus =(id)=>{
  const currentparcour = [...data.parcours]
  if(currentparcour.length > 1){
    const newparcours = currentparcour.filter(item=>item.id!==id)
    setInputField({...inputfield,'parcours':newparcours})
  }else{
    setInputField({...inputfield,'parcours':[]})
  } 
}

  const navigate = useNavigate()
  const [currentcg,setCurrentcg]=React.useState([])
  const [services,setServices] = useState(null)
  const [quartiers,setQuartiers] = useState(null)
  const [cqvcgv,setcqvcgv] = useState(null)
  useEffect(()=>{
    axios.get(`/api/type-demande/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setServices(res.data)
      }
    })
    axios.get(`/api/quartier/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setQuartiers(res.data)
      }
    })
    axios.get(`/api/comite/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setcqvcgv(res.data)
        setCurrentcg(res.data)
      }
    })
   
  },[])
   
useEffect(()=>{
  handleCommonsdata('titre',"Inscription d'un jeune")
},[])
 useEffect(()=>{
  handleDispositif()
 },[activeStep])  
    const handlefiltercd =()=>{
      if(inputfield.hasOwnProperty('quartier') && cqvcgv){
        const newcg = cqvcgv.filter(item=>item.quartier?.nom === inputfield['quartier'])
        setCurrentcg(newcg)
      }else{
        setCurrentcg(cqvcgv)
      }
    }
    useEffect(()=>{
      handlefiltercd()
    },[inputfield.quartier,cqvcgv])

    const handleDemandeChange = (e)=>{
      const {name,value} = e.target
      setInputField({...inputfield,'demandes':{...inputfield.demandes,[name]:value}})
    }
    const handleEditorChange =(field,value)=>{
      const cdmd = {...inputfield.demandes,[field]:value}
      setInputField({...inputfield,'demandes':cdmd});
    }
    
   
    //documents
  const [type,setType] = React.useState()
  const [docfile,setDocfile] =useReducer((state, newState) => ({ ...state, ...newState }),
  {
    do_doctype: "",
    selectedFile: null
  }); 
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: files => {
        setDocfile({
            ['selectedFile']: files.map((file) => Object.assign(file, { preview:  URL.createObjectURL(file)}))
        });
    }
  });
  const handleFormChange =(event)=>{
    const {name,value} =event.target
    setDocfile({
      [name]:value
    })
  }
  
  const imageResetHandler = event => {
    setDocfile({
      ['selectedFile']: null,
    });
    event.stopPropagation()
  };
  useEffect(() => {
    if(docfile.hasOwnProperty('selectedFile') && docfile.selectedFile){
       return ()=>docfile.selectedFile.forEach(file => URL.revokeObjectURL(file.preview));
    }
  }, [docfile]);

  const thumbs = (docfile.hasOwnProperty('selectedFile') && docfile.selectedFile)&&docfile.selectedFile.map(file => (
    getExtension(file.name).toLowerCase()=='pdf'?<IconButton onClick={imageResetHandler} ><FilePresentIcon sx={{fontSize:60,color:'#888888'}}/> </IconButton>: <CardActionArea onClick={imageResetHandler} className={classes.img_card}>
        <img
            width="20%"
            className={classes.media}
            src={file.preview}
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
    </CardActionArea>  
  ));
  const addFile =(e)=>{
    e.preventDefault()
    if(docfile['do_doctype']!=="" && docfile['selectedFile']){
      let nexfile =inputfield.hasOwnProperty("documents")?[...inputfield["documents"],docfile]:[docfile]
      setInputField({...inputfield,'documents':nexfile})
    }
  }

  const handleSubmit =async (e)=>{
    e.preventDefault()
    if(inputfield['civilite'] && inputfield['nom'] && inputfield['prenom']){
      const dataSend = {...inputfield}
      setLoading(true) 
      let formdata = new FormData()
      //personnes
      formdata.append('civilite',dataSend['civilite'])
      formdata.append('nom',dataSend['nom']?dataSend['nom']:null)
      formdata.append('prenom',dataSend['prenom']?dataSend['prenom']:null)
      formdata.append('datenaissance',dayjs(new Date(dataSend['datenaissance'])).format('YYYY-MM-DD'))
      formdata.append('lieunaissance',dataSend['lieunaissance']?dataSend['lieunaissance']:null)
      formdata.append('nationalite',dataSend['nationalite'] || null)
      formdata.append('numeropiece',dataSend['numeropiece']?dataSend['numeropiece']:null)
      formdata.append('naturepiece',dataSend['naturepiece']?dataSend['naturepiece']:null)

      formdata.append('esthandicap',dataSend['esthandicap'] || null)
      formdata.append('naturehandicap',dataSend['naturehandicap'] || null)
    //localisation
      formdata.append('lieuresidence',dataSend['lieuresidence']?dataSend['lieuresidence']:null)
      formdata.append('commune',dataSend['commune']?dataSend['commune']:null)
      formdata.append('quartier',dataSend['quartier']?dataSend['quartier']:null)
      formdata.append('cgqcgv',dataSend['cgqcgv']?dataSend['cgqcgv']:null)
      //contact
      formdata.append('bp',dataSend['bp']?dataSend['bp']:null)
      formdata.append('telephone',dataSend['telephone']?dataSend['telephone']:null)
      formdata.append('mobile1',dataSend['mobile1']?dataSend['mobile1']:null)
      formdata.append('mobile2',dataSend['mobile2']?dataSend['mobile2']:null)
      formdata.append('numerowhatsapp',dataSend['numerowhatsapp']?dataSend['numerowhatsapp']:null)
      formdata.append('email',dataSend['email']?dataSend['email']:null)
      formdata.append('datedossier',dataSend['datedossier'] || dayjs().format('YYYY-MM-DD'))
      formdata.append('email',dataSend['email']?dataSend['email']:null)
      //qualification
      formdata.append('situation',dataSend['situation']?dataSend['situation']:null)
      formdata.append('autresituation',dataSend['autresituation'] || null)
      formdata.append('diplome',dataSend['diplome'] || null)
      formdata.append('etablissement',dataSend['etablissement'] || null)
      formdata.append('anneediplome',dataSend['anneediplome'] || null)
      formdata.append('paysdiplome',dataSend['paysdiplome'] || null)
      formdata.append('niveauetude',dataSend['niveauetude'] || null)
      formdata.append('activite',dataSend['activite'] || null)
      formdata.append('secteuractivite',dataSend['secteuractivite'] || null)
      formdata.append('anneeexperience',dataSend['anneeexperience'] || null)
      //parcours
      if(dataSend.hasOwnProperty('parcours') && dataSend['parcours'].length!==0){
        formdata.append('parcours',JSON.stringify(dataSend['parcours']));
      }
      if(dataSend.hasOwnProperty('dispositif') && dataSend['dispositif'].length!==0){
        formdata.append('dispositif',JSON.stringify(dataSend['dispositif']));
      }
      
      if(dataSend.hasOwnProperty('documents')){
        dataSend.documents.forEach(element => {
          formdata.append('documents[]',element.selectedFile[0])
          formdata.append('documentstitle[]',element.do_doctype)
        });
      }

      //demandes
      if(dataSend.hasOwnProperty('demandes')){
        formdata.append('demandes',JSON.stringify(dataSend['demandes']));
      }
     
      axios.post('/api/dossier/add',formdata).then(res=>{
        if(res && res.status === 200 || res.status === 201){
          swal("Success","Enregistrement effectué avec succès","success")
          fichieInscription(res.data)
          navigate(`/${portail}/dossiers`)
          setLoading(false) 
          navigate(0)

        }else{
            console.log(res.data);
            setLoading(false) 
            swal("Error","Erreur lors de l'enregistrement","error")
            handleChangeData('error_list', res.data.validation_errors);
            return
        }
      }).catch(err=>{
          console.log(err)
          setLoading(false) 
          swal("Error","Erreur lors de l'enregistrement","error")
      })
    }
  }
const handleSelecectDispositif = (service)=>{
  setInputField({...inputfield,'dispositif':service})
  handleCommonsdata("titre",`Inscription d'un jeune - ${service.libelle}`)
}
useEffect(()=>{
  setLoading(false) 
},[])
  return (
    <Container direction='column'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={classes.rbox}>
            {activeStep===2&&<Card>
                <Card.Header>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <IconButton style={{marginRight:20}} onClick={handleBack}>
                      <ArrowBackIcon sx={{color:'#00B04B'}} />
                    </IconButton>
                    <Card.Title>Fiche d'inscription jeunne</Card.Title>
                  </div>
                    <Card.Hr />
                </Card.Header>
              </Card>}
              <Form style={{flex:1}}>

              {activeStep===1&&<div style={{display:'flex',flexDirection:'column'}}>
                    <div>
                      <Typography variant='h5'> Choissez un dispositif ou un pôle d'accompagnement ?</Typography>
                      <Typography>Faite le choix selon le niveau d'avanssement du dossier</Typography>
                    </div>
                      <div className={classes.boxOffres}>
                          {(dispositifs && dispositifs.length!==0)&&dispositifs.map(dispositif=>{
                              return <Card key={dispositif.id} onClick={()=>handleSelecectDispositif(dispositif)} className={(dispositif.id === inputfield.dispositif?.id)?classes.cardOffresCheck:classes.cardOffres}>
                                  <span>{dispositif.libelle}</span>
                              </Card>
                          })}
                      </div>
                      <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                      {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleDispositif(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
                      </div>
                    <Button onClick={handleNext} className={classes.nextSetp}>Continuer ...</Button>
                </div>}

                {activeStep===2&&<form onSubmit={handleSubmit} >
                <div className='mx-auto min-w-max'>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Besoin du jeune</Typography>
                  <Card style={{width:'100%'}}>
                    <Form.Group  margin='10px 0px 0px 0px'direction ='row' flex={1}>
                      <Form.Input 
                            name='service' 
                            size="small" 
                            margin="dense" 
                            style={{ width: '50%',marginRight:20 }}
                            select 
                            value ={inputfield.demandes?.typedemande_id} 
                            onChange={handleDemandeChange}  label="Motif de sa demande">
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {(services && services.lenght!==0)&& services.map(srs=>(<MenuItem  value={srs.id}>{srs.libelle}</MenuItem>))}
                      </Form.Input>
                      <Form.Input 
                            name='situation' 
                            size="small" 
                            margin="dense" 
                            style={{ width: '50%' }}
                            select 
                            value ={inputfield.demandes?.situation} 
                            onChange={handleDemandeChange}  label="Situation actuelle"
                      >
                          <MenuItem  value='No'>Aucun</MenuItem>
                          <MenuItem value='occupe'>Occupé</MenuItem>
                          <MenuItem value='chomeur'>Chômeur</MenuItem>
                          <MenuItem value='enquete'>En quête</MenuItem>
                          <MenuItem value='entrepreneur'>A mon propre compte</MenuItem>
                          <MenuItem value='autre'>Autre</MenuItem>
                      </Form.Input>
                    </Form.Group>
                    {(inputfield.situation ==='autre')&&<Form.Group  margin='10px 0px 0px 0px'direction ='row' flex={1}>
                      <Form.Input 
                          size="small" 
                          name='autresituation' 
                          required
                          margin="dense" 
                          label="Veuillez préciser votre situation actuelle"
                          style={{width:'100%'}}
                          value={inputfield['autresituation'] ||""}
                          onChange={handleInputChange} 
                        />
                    </Form.Group>
                  }
                    <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <DesktopDatePicker
                          required
                         
                          label="Date de la demande"
                          inputFormat="dd/MM/yyyy"
                          value={inputfield['datedossier'] || dayjs().format('DD/MM/YYYY')}
                          name='datedossier'
                          onChange={(newvalue)=>handleChange('datedossier',newvalue)}
                          renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      </Form.Group>
                    <Form.Group  margin='10px 0 20px 0'direction ='row' flex={1} >
                      <ReactQuill  
                          placeholder={"Description de du besoin"} 
                          style={{width:'100%'}}
                          name='description' 
                          theme="snow" value={inputfield.demandes?.description} 
                          onChange={(v)=>handleEditorChange('description',v)} 
                      
                      />
                    </Form.Group>
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Informations personnelles</Typography>
                  <Card>
                    <Form.Group direction ='row' flex={1}>
                      <Form.Input required size="small" name="civilite" margin="dense" onChange={handleInputChange} value={inputfield['civilite']||""} select style={{marginRight:20}}  label="Civilité">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='Monsieur'>Monsieur</MenuItem>
                        <MenuItem value='Madame'>Madame</MenuItem>
                        <MenuItem value='Mademoiselle'>Mademoiselle</MenuItem>
                      </Form.Input>
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        style={{marginRight:20}}  
                        label="Nom"
                        required 
                        name='nom' 
                        value={inputfield['nom'] ||""}
                        onChange={handleInputChange} 
                      />
                      <Form.Input 
                        size="small" 
                        name='prenom' 
                        required
                        margin="dense" 
                        label="Prénom"
                        value={inputfield['prenom'] ||""}
                        onChange={handleInputChange} 
                      />
                    </Form.Group>
                    <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <DesktopDatePicker
                          label="Date de naissance"
                          inputFormat="dd/MM/yyyy"
                          value={inputfield['datenaissance'] || ""}
                          name='datenaissance'
                          onChange={(newvalue)=>handleChange('datenaissance',newvalue)}
                          renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      <Form.Input 
                        size="small" 
                        name="lieunaissance" 
                        margin="dense" 
                        onChange={handleInputChange} 
                        value={inputfield['lieunaissance']||""} 
                        select 
                        label="Lieu de naissance">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem  key={ville} value={ville}>{ville}</MenuItem>))}
                      </Form.Input>
                    </Form.Group>
                    <Autocomplete
                          disablePortal
                          id="nationalite"
                          size="small" 
                          margin="dense"
                          getOptionLabel={(option) => option.nationalite ? option.nationalite : 'ivoirienne'}
                          defaultValue={pays[45]}
                          options={pays || []}
                          style={{marginRight:20,width: '100%'}}  
                          value={inputfield['nationalite'] ||"ivoirienne"}
                          name='nationalite'
                          onChange={(e,v)=>setInputField({...inputfield,'nationalite':v})}
                          renderInput={(params) => <Form.Input {...params} name='nationalite' defaltValue='ivoirienne' label="Nationalité" wd='50%' />}
                      />
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        style={{marginRight:20}}  
                        label="Numéro de la pièce"
                        value={inputfield['numeropiece'] ||""}
                        name='numeropiece'
                        onChange={handleInputChange}
                        />
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        select  
                        label="Nature de la pièce"
                        value={inputfield['naturepiece'] ||""}
                        name='naturepiece'
                        onChange={handleInputChange}
                      >
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='CNI'>CNI</MenuItem>
                        <MenuItem value='Attestation'>Attestation d'identité</MenuItem>
                        <MenuItem value='Passport'>Passport</MenuItem>
                        <MenuItem value='Permis'>Permis de conduire</MenuItem>
                        <MenuItem value='Carte consulaire'>Carte consulaire</MenuItem>
                        <MenuItem value='Autre'>Autre</MenuItem>
                      </Form.Input>
                    </Form.Group>
                    <Card.Hr />
                    <Form.Group direction ='row' salign='center' flex={1}>
                      <RadioField
                          groupItems={[
                            {
                              name: "O",
                              label: "Oui",
                            
                            },
                            {
                              name: "N",
                              label: "Non",
                            
                            }
                          ]}
                          group="Est sujet ou victime d'un handicap"
                          checkedDefault="O"
                          appearance={radiobutton_appearances.primary}
                          onChange ={handleInputChange}
                          name='esthandicap'
                          vvalue ={inputfield.esthandicap}
                      />
                      {(inputfield.esthandicap && inputfield.esthandicap==='O')&& <Form.Input 
                        size="small" 
                        name='naturehandicap' 
                        required
                        margin="dense" 
                        label="Nature de l'handicap"
                        value={inputfield['naturehandicap'] ||""}
                        onChange={handleInputChange} 
                      />}
                      
                      </Form.Group>
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Qualifications</Typography>
                  <Card>
                    <Form.Group direction ='row' salign='center' flex={1}>
                      <Form.Input 
                          size="small" 
                          margin="dense" 
                          label="Diplôme" 
                          wd='50%' 
                          style={{marginRight:20}}
                          value={inputfield['diplome'] ||""}
                          name='diplome'
                          onChange={handleInputChange}
                        />
                        <Form.Input 
                          size="small" 
                          margin="dense" 
                          label="Etablissement d'obtention" 
                          wd='50%' 
                          value={inputfield['etablissement'] ||""}
                          name='etablissement'
                          onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group direction ='row' salign='center' flex={1}>
                      <Form.Input 
                              name='anneediplome' 
                              size="small" 
                              margin="dense" 
                              style={{ width: '50%',marginRight:20 }}
                              select 
                              value ={inputfield['anneediplome'] || ""} 
                              onChange={handleInputChange}  label="Année d'obtention"
                        >
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {annee().map(r=>r)}
                      </Form.Input>

                      <Autocomplete
                          disablePortal
                          id="paysdiplome"
                          size="small" 
                          margin="dense"
                          getOptionLabel={(option) => option.libelle ?  option.libelle :  "Côte d'Ivoire"}
                          defaultValue={pays || pays[45]}
                          options={pays}
                          style={{ width:'50%'}}  
                          value={inputfield['paysdiplome'] ||""}
                          name='paysdiplome'
                          onChange={(e,v)=>setInputField({...inputfield,'paysdiplome':v})}
                          renderInput={(params) => <Form.Input {...params} name='paysdiplome' label="Pays d'obtention" wd='100%' inputProps={{
                            ...params.inputProps
                          }} />}
                      />
                    </Form.Group>
                     <Divider style={{marginTop:10, marginBottom:10}} />
                     <Form.Group direction ='row' salign='center' flex={1}>
                        <Form.Input 
                              name='niveauetude' 
                              size="small" 
                              margin="dense" 
                              style={{ width: '50%',marginRight:20 }}
                              select 
                              value ={inputfield['niveauetude'] || ""} 
                              onChange={handleInputChange}  label="Niveau d'étude"
                        >
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {niveauetudes.map((niveau,idx)=><MenuItem key={idx} style={{fontWeight:'bold'}} value={niveau}>{niveau}</MenuItem>)} 
                      </Form.Input>
                     </Form.Group>
                     <Divider style={{marginTop:10, marginBottom:10}} />
                     <Form.Group direction ='row' salign='center' flex={1}>
                        <Form.Input 
                              size="small" 
                              margin="dense" 
                              label="Activité pratiqué" 
                              wd='100%' 
                              style={{marginRight:20}}
                              value={inputfield['activite'] ||""}
                              name='activite'
                              onChange={handleInputChange}
                            />
                     </Form.Group>
                     <Form.Group direction ='row' salign='center' flex={1}>
                          <Form.Input 
                              name='secteuractivite' 
                              size="small" 
                              margin="dense" 
                              style={{ width: '50%',marginRight:20 }}
                              select 
                              value ={inputfield['secteuractivite'] || ""} 
                              onChange={handleInputChange}  label="Secteur de métier"
                        >
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {secteuractivites.map((activite,idx)=><MenuItem key={idx} style={{fontWeight:'bold'}} value={activite}>{activite}</MenuItem>)} 
                      </Form.Input>
                      <Form.Input 
                              size="small" 
                              margin="dense" 
                              label="Nombre d'année d'expérience" 
                              wd='50%' 
                              type='number'
                              style={{marginRight:20}}
                              value={inputfield['anneeexperience'] ||""}
                              name='anneeexperience'
                              onChange={handleInputChange}
                            />
                     </Form.Group>
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Parcours  <small>(Formations,diplomes,experiences profesionnelles)</small></Typography>
                  <Card>
                    <Form.Group direction ='row' salign='center' flex={1}>
                      <RadioField
                          groupItems={groupItems}
                          group="Type de parcours"
                          checkedDefault="cursus"
                          appearance={radiobutton_appearances.primary}
                          onChange ={handleChangeCursus}
                          name='type'
                          vvalue ={cursusField.type}
                      />
                      <FormControlLabel control={ <Checkbox name='encours' onChange={(e)=>handleCheckboxChange('encours')}  color="success" />} label="En cours" />
                    </Form.Group>
                    <Form.Group direction ='row' flex={1}>
                      <DesktopDatePicker
                        label="Date de debut"
                        inputFormat="dd/MM/yyyy"
                        value={cursusField.datedebut}
                        name='datedebut'
                        onChange={(val)=>setCursusField({...cursusField,['datedebut']:val})}
                        renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                      <DesktopDatePicker
                        label="Date de fin"
                        name='datefin'
                        inputFormat="dd/MM/yyyy"
                        value={cursusField.datefin}
                        onChange={(val)=>setCursusField({...cursusField,['datefin']:val})}
                        renderInput={(params) => <Form.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                      />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                      <Form.Input   name='diplome' value={cursusField.diplome} onChange={handleChangeCursus} size="small" margin="dense" label="Libelle diplome / titre" wd='100%' />
                    </Form.Group>
                    <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <Form.Input  size="small" name='etablissement' value={cursusField.etablissement} onChange={handleChangeCursus} margin="dense" label="Etablissement" wd='100%' />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input  size="small" name='mission' value={cursusField.mission} onChange={handleChangeCursus} margin="dense" label="Missions" wd='100%' />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' palign='flex-end' flex={1}>
                      <Form.RButton color='#556B2F' onClick={handleCursusData}>Ajouter</Form.RButton> 
                    </Form.Group>

                    {(cursusData && cursusData.length !== 0)&&<div style={{marginTop:20}}>
                      <Card.Title>Fillières /formation</Card.Title>
                      <Card.Hr />
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            {cursusData.map((cur,indx)=>{
                              return <TableRow key={cur.id}>
                                  <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                                  <TableCell align="left">{cur.diplome}</TableCell>
                                  <TableCell align="left">{cur.etablissement}</TableCell>
                                  <TableCell align="left">{cur.mission}</TableCell>
                                  <TableCell align="center">
                                    <IconButton aria-label="delete" onClick={(e)=>handleDeleteCurssus(cur.id)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                            </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>}
                    {(expData && expData.length !== 0)&&<div style={{marginTop:20}}>
                      <Card.Title>Experiences Professionnelles</Card.Title>
                      <Card.Hr />
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            {expData.map((cur,indx)=>{
                                return <TableRow key={cur.id}>
                                    <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                                    <TableCell align="left">{cur.diplome}</TableCell>
                                    <TableCell align="left">{cur.etablissement}</TableCell>
                                    <TableCell align="left">{cur.mission}</TableCell>
                                    <TableCell align="center">
                                      <IconButton aria-label="delete" onClick={(e)=>handleDeleteCurssus(cur.id)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                              </TableRow>
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>}
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Contacts</Typography>
                  <Card>
                    <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        label="Adresse postale" 
                        wd='100%' 
                        value={inputfield['bp'] ||""}
                        name='bp'
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        style={{marginRight:20}}  
                        label="Téléphone fiixe" 
                        value={inputfield['telephone'] ||""}
                        name='telephone'
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        style={{marginRight:20}}  
                        label="Mobile 1" 
                        value={inputfield['mobile1'] ||""}
                        name='mobile1'
                        onChange={handleInputChange}
                      />
                      <Form.Input 
                        size="small" 
                        margin="dense"  
                        label="Mobile 2" 
                        value={inputfield['mobile2'] ||""}
                        name='mobile2'
                        onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        style={{marginRight:20}}  
                        label="Numéro whatsapp" 
                        value={inputfield['numerowhatsapp'] ||""}
                        name='numerowhatsapp'
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group margin='10px 0 0 0' direction ='row' flex={1}>
                      <Form.Input  
                        size="small" 
                        margin="dense" 
                        label="Adresse email" 
                        wd='100%'
                        type="email" 
                        value={inputfield['email'] ||""}
                        name='email'
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Localisation</Typography>
                  <Card>
                    <Form.Group direction ='row' flex={1}>
                      <Form.Input 
                          size="small" 
                          margin="dense" 
                          select 
                          style={{marginRight:20}}  
                          label="Ville de résidence"
                          value={inputfield['lieuresidence'] ||""}
                          name='lieuresidence'
                          defaltValue ='Abidjan'
                          sx={{ width: '100%' }}
                          onChange={handleInputChange}
                          >
                              <MenuItem  value='No'>Aucun</MenuItem>
                              {(villes && villes.lenght!=0)&&villes.map(ville=>(<MenuItem value={ville}>{ville}</MenuItem>))}
                      </Form.Input>
                      <Autocomplete
                          disablePortal
                          id="commune"
                          size="small" 
                          margin="dense"
                          defaultValue='Port-Bouët'
                          options={communes}
                          sx={{ width: '100%' }}
                          style={{marginRight:20}}  
                          value={inputfield['commune'] ||""}
                          name='commune'
                          onChange={onAutocompleteChange}
                          renderInput={(params) => <Form.Input {...params} name='commune' label="Commune" wd='50%' />}
                      />
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0' direction ='row' flex={1}>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        select 
                        style={{marginRight:20}}  
                        label="Quartier"
                        value={inputfield['quartier'] ||""}
                        name='quartier'
                        onChange={handleInputChange}
                        >
                          <MenuItem  value='No'></MenuItem>
                          {(quartiers && quartiers.lenght!==0)&&quartiers.map(quartier=>(<MenuItem key={quartier.id} value={quartier.nom}>{quartier.nom}</MenuItem>))}
                          
                      </Form.Input>
                      <Form.Input 
                        size="small" 
                        margin="dense" 
                        select 
                        style={{marginRight:20}}  
                        label="CGQ/CGV"
                        value={inputfield['cgqcgv'] ||""}
                        name='cgqcgv'
                        onChange={handleInputChange}
                        >
                          <MenuItem  value='No'></MenuItem>
                          {(currentcg && currentcg.lenght!==0)&&currentcg.map(cg=>(<MenuItem key={cqvcgv.id} value={cg.nom}>{cg.nom}</MenuItem>))}
                          
                      </Form.Input>
                    </Form.Group>
                  </Card>
                  <Typography sx={{ fontStyle: 'italic' }} variant='h6'>Documents</Typography>
                  <Card>
                    <Form.Group  margin='10px 0px 0px 0px'direction ='column' flex={1} >
                      <Form.Input 
                          name='do_doctype' 
                          size="small" 
                          margin="dense" 
                          style={{marginRight:20}} 
                          select 
                          value ={docfile['do_doctype']||""} 
                          onChange={handleFormChange}  label="Titre du document" wd='100%'>
                        <MenuItem  value='No'>Aucun</MenuItem>
                        {(doctype && doctype.lenght!==0)&& doctype.map(type=>(<MenuItem value={type}>{type}</MenuItem>))}
                      </Form.Input>      
                    </Form.Group>

                    <Form.Group margin='10px 0px 0px 0px'direction ='column' flex={1}>
                      <div className={classes.content_img}>
                        <Typography className={classes.photo_label}>Cliquez ou glissez déposer vos fichier ici</Typography>
                        <div className={classes.logo_box} {...getRootProps()}>
                            {thumbs}
                            <input
                                {...getInputProps()}
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                name='photo'
                                disabled={!docfile.selectedFile}
                                />
                                {!docfile.selectedFile&&<label className={classes.photo_check} htmlFor="contained-button-file">
                                    <CloudDownloadIcon sx={{fontSize:60,color:'#888888'}} />
                                </label>}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group  margin='10px 0 0 0'direction ='row' palign='flex-end' flex={1}>
                      <Form.RButton color='#556B2F' onClick={addFile}>Ajouter</Form.RButton> 
                    </Form.Group>

                    {(inputfield.hasOwnProperty('documents') && inputfield['documents'].length!==0)&&<div>
                      <Form.Hr style={{marginTop:20}} />
                      <Typography>Liste des documents</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            { inputfield['documents'].map((doc,indx)=>{
                              return <TableRow key={indx}>
                                  <TableCell align="left">{doc.do_doctype}</TableCell>
                                    <TableCell align="center">{
                                      (doc.hasOwnProperty('selectedFile') && doc.selectedFile)&&doc.selectedFile.map(file=>(getExtension(file.name).toLowerCase()=='pdf'?<FilePresentIcon sx={{fontSize:60,color:'#888888'}}/>:
                                      
                                      <img
                                          width="20%"
                                          className={classes.media}
                                          src={file.preview}
                                          onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                      />
                                
                            ))}</TableCell>
                                  <TableCell align="center">
                                    <IconButton aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                            </TableRow>
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>} 
                  </Card>
                  <Card direction='row' palign='space-between'>
                    <Form.Button type='reset' >Annuler</Form.Button> 
                    <Form.Button type="submit" primary>
                        Enregistrer
                    </Form.Button> 
                  </Card>
                  </div>
                </form>}
              </Form>
              {loading&&<div className='absolute top-0 bottom-0 left-0 right-0 flex flex-row justify-center items-center bg-[#000000a2]'>
                  <CircularProgress className='text-green-500' />
              </div>}
              
            </div>
          </LocalizationProvider>

        </Container>
   
  )
}

export default DossierAddSimplifier