import React,{useState,useContext, useEffect} from 'react'
import { Card } from 'components'
import { Form } from 'components'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import { AddContext } from 'context/add'
import MenuItem from '@mui/material/MenuItem';
import axios from 'lib/axios';
const groupItems=[
  {
    name: "Loisirs",
    label: "Loisirs",
    information: "Veuillez selectionner un type",
    disabled: false
  },
  {
    name: "Connaissance",
    label: "Connaissance",
    information: "Veuillez selectionner un type",
    disabled: false
  }
]

function StepsConnaissance({dossier,handleBack,reflesh}) {
  const [atouts,setAtouts] = useState([])
  const [currentField,setCurrentField] =useState({
    type:null,
    niveau:null,
    libelle:null,
  }) 

  const handleChange =(e)=>{
    e.preventDefault()
    const {name,value} =e.target
    setCurrentField({...currentField, [name]:value})
  }
const [loisirsData,setLoisirsData] = useState([])
const [connaissanceData,setConnaissanceData] = useState([])

useEffect(()=>{
  if(atouts && atouts.length !==0){
    setLoisirsData(atouts.filter(item=>item.type === 'Loisirs'))
    setConnaissanceData(atouts.filter(item=>item.type === 'Connaissance'))
  }else{
    setLoisirsData([])
    setConnaissanceData([])
  }
},[atouts])

const handleLoisirsData =(e)=>{
  e.preventDefault()
  let currentData ={...currentField}

  if(currentData.type && currentData.libelle){
    let currentAtouts =(atouts && atouts.length!==0) ? [...atouts]:[]
    
    currentData['id'] = currentAtouts.length+1
    currentAtouts = [...currentAtouts, currentData]
    setAtouts([...currentAtouts])
    setCurrentField({
      type:null,
      niveau:null,
      libelle:null,
    })
  }
}

const handleDeleteLoisirs =(id)=>{
  const currentatouts = [...atouts]
  if(currentatouts.length > 1){
    const newatouts = currentatouts.filter(item=>item.id!==id)
    setAtouts([...newatouts])
  }else{
    setAtouts([])
  } 
}

const handleSave =(e)=>{
  e.preventDefault()
  if(!atouts || atouts.length===0){
    swal("Ooops!","Veuillez ajouter au moins une connaissance",'warning')
    return
  }
  let formdata = new FormData()
  formdata.append('atouts',JSON.stringify(atouts))
  axios.post(`/api/dossier/add-atouts/${dossier.id}`,formdata).then(res=>{
    if(res.status===200 || res.status ===201){
      reflesh()
      swal("Ooops!","Enregistrement effectué avec succès",'success')
    }else{
      console.log(res)
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  }).catch(err=>{
    if(err.hasOwnProperty('response')){
      console.log(err.response.data);
      swal("Ooops!",err.response.data.message,"error")
    }else{
      console.log(err);
      swal("Ooops!","Une erreur s'est produit lors de l'opération",'error')
    }
  })
}

  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
    
    <Card>
        <Form>
            <Form.Base>
              <Form.Group direction ='row' salign='center' flex={1}>
                <RadioField
                    groupItems={groupItems}
                    group="Type"
                    checkedDefault="Loisirs"
                    appearance={radiobutton_appearances.primary}
                    onChange ={handleChange}
                    name='type'
                    vvalue ={currentField.do_contype}
                  />
              </Form.Group>
              <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                <Form.Input   name='libelle' value={currentField.libelle} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Libelle" wd='50%' />
                <Form.Input name='niveau' size="small" margin="dense" select  onChange={handleChange}  label="Niveau">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='Debutant'>Debutant</MenuItem>
                        <MenuItem value='Intermediaire'>Intermediaire</MenuItem>
                        <MenuItem value='Expert'>Expert</MenuItem>
                    </Form.Input>
             </Form.Group>
            
             <Form.Group  margin='10px 0 0 0'direction ='row' palign='flex-end' flex={1}>
                  <Form.RButton color='#556B2F' onClick={handleLoisirsData}>Ajouter</Form.RButton> 
             </Form.Group>
            </Form.Base>
           
        </Form>
        <Form.Hr style={{marginTop:20}} />
        {(connaissanceData && connaissanceData.length!==0)&&<div style={{marginTop:20}}>
          <Card.Title>Connaissances</Card.Title>
          <Card.Hr />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {connaissanceData.map((cur,indx)=>{
                  return <TableRow key={cur.id}>
                      <TableCell align="center">{cur.libelle}</TableCell>
                        <TableCell align="left">{cur.niveau}</TableCell>
                      <TableCell align="center">
                        <IconButton aria-label="delete" onClick={()=>handleDeleteLoisirs(cur.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
        {(loisirsData && loisirsData.length!==0)&&<div style={{marginTop:20}}>
          <Card.Title>Loisiirs</Card.Title>
          <Card.Hr />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {loisirsData.map((cur,indx)=>{
                    return <TableRow key={cur.id}>
                        <TableCell align="center">{cur.libelle}</TableCell>
                        <TableCell align="left">{cur.niveau}</TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete" onClick={()=>handleDeleteLoisirs(cur.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                  </TableRow>
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
        
    </Card>
    <Card direction='row' palign='space-between'>
      <Form.Button onClick={handleBack} >Retour</Form.Button>
         <Form.Button onClick={handleSave} primary>Suivant</Form.Button> 
    </Card>
    </div>
  )
}

export default StepsConnaissance