import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
const CustumModal = ({open,handleClose,children,leftButton,rightButton,actions,titre,width}) => {
  return (
    <div>
        <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={width}
        fullWidth
      >
        
        <DialogTitle className='p-3 m-0 bg-green-500 text-white' id="customized-dialog-title">
          <div>
            {leftButton}
            {titre}
            {rightButton}
        </div>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            {children}
        </DialogContent>
        <DialogActions>
         {actions}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
    </div>
  )
}

export default CustumModal