import styled from "styled-components/macro";
import { Paper } from "@mui/material";

export const Container = styled(Paper)`
  display:flex;
  flex-direction:${({direction})=>direction||'column'};
  justify-content:${({palign})=>palign||'flex-start'};
  align-items;${({salign})=>salign||'flex-start'};
  padding:${({padding})=>padding||'20px'};
  margin-bottom:20px;
`

export const Header = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
export const Title = styled.h1`
  font-size:15px;
  color:${({color})=>color||'#707070'};
  text-transform:uppercase;
  margin-bottom:10px;
`
export const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`