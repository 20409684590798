import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem} from '@mui/x-tree-view/TreeItem';
import axios from 'lib/axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox } from '@mui/material';

const bfsSearch = (graph, targetId) => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode.id === targetId) {
      return currNode;
    }
    if (currNode.privileges) {
      queue.push(...currNode.privileges);
    }
  }
  return []; // Target node not found
};

const ModulePrivilege = ({portail,handleChecked,pprivileges,mode}) => {

    const [privileges,setPrivileges] = useState(null)

    const handleGetPrivilege =()=>{
        axios.get(`/api/modules/privilege-from-portail/${portail}`).then(res=>{
            if(res.status === 200 || res.status === 201){
                setPrivileges(res.data[0].modules)
            }
        })
    }

    useEffect(()=>{
      handleGetPrivilege()
    },[JSON.stringify(portail)])

    const autocheckedprivilege = ()=>{

      if(pprivileges && pprivileges.length!==0 && privileges && privileges.length !==0){  
        const currentPriv = structuredClone(privileges)
        const cpprivileges = structuredClone(pprivileges)

        const newPriv =currentPriv.map(cp=>{
          if(cpprivileges && cpprivileges.find(pp=>pp.id === cp.id)){
            cp.checked =true
            if(Array.isArray(cp.privileges)){
              cp.privileges.map(cpp=>{
                const  ppp = cpprivileges.filter(item=>item.id === cp.id)[0]?.privileges;
                if(Array.isArray(ppp) && ppp.find(el=> el.id == cpp.id)){
                  cpp.checked=true
                }
               // && ppp.forEach(el=>{(el.id===cpp.id || el.checked===true) ? cpp.checked=true:cpp.checked=false});
                return cpp
              })
            }//end cp.privileges is array

            return cp
          }else{
            return cp
          }//end cp findindex

        })
        setPrivileges([...newPriv])
      }
    }
   
    // Get all father IDs from specific node
    const getAllFathers = (id, list = []) => {
      const node = bfsSearch(privileges, id);
      if (node.module_id) {
        list.push(node.module_id);
        return getAllFathers(node.module_id, list);
      }
  
      return list;
    };
  
  
    const uncheckedAllchild = (nodeid,list)=>{
      const newList =  list.map(mod=>{
        if(mod.id === nodeid){
          if(!mod['checked']){Array.isArray(mod.privileges) && mod.privileges.forEach(item=>item.checked=false)}
        }
        return mod
      })
      return newList
    }
    const unchecheckedfather =(nodeid,list)=>{
      let nbChecked = 0 
      return list.map(mod=>{
        if(mod.id === nodeid){
          nbChecked = Array.isArray(mod.privileges) ? mod.privileges.filter(item=>item.checked===true).length : 0
          console.log();
          (nbChecked ===0) && (mod.checked=false)
        }
        return mod
      })
      
    }



    const handleNodeSelect = (event, nodeId,type) => {
        event.stopPropagation();
       
        const currentModule = structuredClone(privileges)
        const newmodule = currentModule.map(mod=>{
          if(type==='module'){
            if(mod.id === nodeId){
              mod.hasOwnProperty('checked') ?   (mod.checked= !mod.checked) : mod.checked=true
              Array.isArray(mod.privileges) && mod.privileges.map(pr=>{
                pr.hasOwnProperty('checked')? (pr.checked  = !pr.checked) : pr.checked=true
                return pr
              })
            }
            return mod
          }
          if(type==='privilege'){
            Array.isArray(mod.privileges) && mod.privileges.forEach(pr=>{
              if(pr.id === nodeId){
                  if(!mod.hasOwnProperty('checked') || (mod.hasOwnProperty('checked') && !pr.checked)){
                    mod.checked=true
                  }
                if(pr.hasOwnProperty('checked')){
                  pr.checked  = !pr.checked
                }else{
                  pr.checked=true
                  mod.checked=true
                } 
              }
            })
            return mod
          }
          
        })
        if(type ==='privilege'){
          setPrivileges(unchecheckedfather(getAllFathers(nodeId)[0],newmodule))
        }else{
          setPrivileges(uncheckedAllchild(nodeId,newmodule))
        }
      
    };
  
    const handleExpandClick = (event) => {
      // prevent the click event from propagating to the checkbox
      event.stopPropagation();
    };
  


    const renderTree = (nodes,type='module') => {
       
       return <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        onClick={handleExpandClick}
        label={
          <>
            <Checkbox
              checked={nodes['checked'] || false}
              tabIndex={-1}
              disableRipple
              onClick={(event) => handleNodeSelect(event, nodes.id,type)}
            />
            {nodes.libelle}
          </>
        }
      >
        {(Array.isArray(nodes.privileges) && nodes.privileges.length!==0) ? nodes.privileges.map((node) =>node ? renderTree(node,'privilege'):null) : null}
      </TreeItem>
    };

     useEffect(()=>{
      if(privileges){
        let cfi = []
        for (let i = 0; i < privileges.length; i++) {
          if(privileges[i]){
            cfi.push(privileges[i]);
          }
      }
        handleChecked(cfi)
      }
      
    },[JSON.stringify(privileges)])

    useEffect(()=>{
      if(mode ==='UP' && pprivileges && pprivileges.length!==0 && privileges && privileges.length !==0){
        autocheckedprivilege()
      }
    },[JSON.stringify(pprivileges)])


  return (
    <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: 300 }}>
        {(privileges&&privileges.length!==0)&& <TreeView
        multiSelect
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        
      >
        {privileges.map(module=>{
          
          return module ? renderTree(module) : null })}
              
      </TreeView>}
     
    </Box>
  )
}

export default ModulePrivilege