
import React from 'react';
import Welcome from 'pages/backend/Welcome'
import {Routes,Route,} from "react-router-dom";
import { IsUserRedirect, ProtectedRoute } from 'utils/route-utils';
import Conseiller from 'layout/conseiller';
import Decisionnel from 'layout/decisionnel';
import Administrateur from 'layout/administrateur'
import Jeune from 'layout/jeunes';
import SiteLayout from 'layout/site/SiteLayout';
import {siteRoutes,adminRoutes,conseillerRoutes,jeuneRoutes,decisioRoutes} from 'routes'
import ForgotPassword from 'auth/ForgotPassword';
import PasswordReset from 'auth/PasswordReset';
function App() {
  const user  = localStorage.getItem('user')!=='undefined'?JSON.parse(localStorage.getItem('user')):null
  return (
    <Routes>
        <Route path="/" element={<SiteLayout />}>
        {siteRoutes&&siteRoutes.map((routedata,idx)=>{
            return routedata.component&&(
              routedata.index?(<Route 
                key={idx}
                index
                element={<routedata.component />}
            />):(<Route 
                key={idx}
                path={routedata.path}
                element={<routedata.component />}
            />)
              
            )
          })}
        </Route>
        <Route path="/connexion" element={<IsUserRedirect user={user} loggedInPath='/connexion'><Welcome /></IsUserRedirect>} />
        <Route path="/mot-de-passe-oublier" element={<IsUserRedirect user={user} loggedInPath='/mot-de-passe-oublier'><ForgotPassword /></IsUserRedirect>} />
        <Route path="/password-reset/:token" element={<IsUserRedirect user={user} loggedInPath='/password-reset/:token'><PasswordReset /></IsUserRedirect>} />
        
        <Route path='/administrateur' element={<ProtectedRoute user={user}><Administrateur/></ProtectedRoute>}>
              {adminRoutes&&adminRoutes.map((routedata,idx)=>{
                return routedata.component&&(
                  routedata.index?(<Route 
                    key={idx}
                    index
                    element={<routedata.component />}
                />):(
                  routedata.childs?(<Route key={idx}
                    path={routedata.path}
                    element={<routedata.component />}>
                      {routedata.childs.map((child,xd)=>(child.index?(
                      <Route 
                        key={`${idx}-${xd}`}
                        index
                        element={<child.component />}
                />):(<Route 
                  key={`${idx}-${xd}`}
                  path={child.path}
                  element={<child.component />}
              />)))}
                  </Route>):(<Route 
                    key={idx}
                    path={routedata.path}
                    element={<routedata.component />}
                />))    
                )
              })}
        </Route>
        <Route path='/conseiller' element={<ProtectedRoute user={user}> <Conseiller /></ProtectedRoute>}>
              {conseillerRoutes&&conseillerRoutes.map((routedata,idx)=>{
                return routedata.component&&(
                  routedata.index?(<Route 
                    key={idx}
                    index
                    element={<routedata.component />}
                />):(
                  routedata.childs?(<Route key={idx}
                    path={routedata.path}
                    element={<routedata.component />}>
                      {routedata.childs.map((child,xd)=>(child.index?(
                      <Route 
                        key={`${idx}-${xd}`}
                        index
                        element={<child.component />}
                />):(<Route 
                  key={`${idx}-${xd}`}
                  path={child.path}
                  element={<child.component />}
              />)))}
                  </Route>):(<Route 
                    key={idx}
                    path={routedata.path}
                    element={<routedata.component />}
                />))    
                )
              })}
        </Route>
        <Route path='/decisionnel' element={<ProtectedRoute user={user}><Decisionnel /></ProtectedRoute>}>
          {decisioRoutes&&decisioRoutes.map((routedata,idx)=>{
                return routedata.component&&(
                  routedata.index?(<Route 
                    key={idx}
                    index
                    element={<routedata.component />}
                />):(
                  routedata.childs?(<Route key={idx}
                    path={routedata.path}
                    element={<routedata.component />}>
                      {routedata.childs.map((child,xd)=>(child.index?(
                      <Route 
                        key={`${idx}-${xd}`}
                        index
                        element={<child.component />}
                />):(<Route 
                  key={`${idx}-${xd}`}
                  path={child.path}
                  element={<child.component />}
              />)))}
                  </Route>):(<Route 
                    key={idx}
                    path={routedata.path}
                    element={<routedata.component />}
                />))    
                )
              })}
        </Route>
        <Route path='/jeune' element={<ProtectedRoute user={user}><Jeune /></ProtectedRoute>} >
          {jeuneRoutes&&jeuneRoutes.map((routedata,idx)=>{
                return routedata.component&&(
                  routedata.index?(<Route 
                    key={idx}
                    index
                    element={<routedata.component />}
                />):(
                  routedata.childs?(<Route key={idx}
                    path={routedata.path}
                    element={<routedata.component />}>
                      {routedata.childs.map((child,xd)=>(child.index?(
                      <Route 
                        key={`${idx}-${xd}`}
                        index
                        element={<child.component />}
                />):(<Route 
                  key={`${idx}-${xd}`}
                  path={child.path}
                  element={<child.component />}
              />)))}
                  </Route>):(<Route 
                    key={idx}
                    path={routedata.path}
                    element={<routedata.component />}
                />))    
                )
              })}
        </Route>
    </Routes>
  );
}

export default App;
