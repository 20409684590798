import React from "react";
import {Container,Frame,Card,Text,SmallText,Inner,MinCard,Label,ButtonAdd} from './styles/dash'
export default function Dash({children, ...restProps}) {
    return (<Container {...restProps}>{children}</Container>)
}

Dash.Frame = function HeaderFrame({children,...restProps}){
    return (<Frame {...restProps}>{children}</Frame>)
}

Dash.Inner = function HeaderInner({to,children,...restProps}){
    return (<Inner  {...restProps}>{children}</Inner>)
}
Dash.Card = function HeaderCard({to,children,...restProps}){
    return (<Card  {...restProps}>{children}</Card>)
}

Dash.MinCard = function HeaderMinCard({to,children,...restProps}){
    return (<MinCard  {...restProps}>{children}</MinCard>)
}

Dash.Text = function HeaderText({children,...restProps}){
    return (<Text {...restProps}>{children}</Text>)
}
Dash.Label = function HeaderLabel({children,...restProps}){
    return (<Label {...restProps}>{children}</Label>)
}

Dash.SmallText = function HeaderTSmallText({children,...restProps}){
    return (<SmallText {...restProps}>{children}</SmallText>)
}

Dash.ButtonAdd = function HeaderButtonAdd({children,...restProps}){
    return (<ButtonAdd {...restProps}>{children}</ButtonAdd>)
}

