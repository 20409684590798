import React, { useState } from 'react'
import HeaderTitle from 'containers/HeaderTitle'
import { Outlet } from 'react-router-dom'
function DossierHome() {
  const [titre,setTitre] = useState("Gestion des dossiers jeunes")
  return (
    <div>
        <HeaderTitle title={titre} />
        <main>
              <Outlet context={[titre,setTitre]} />
        </main>
        
    </div>
  )
}

export default DossierHome