import React from 'react'
import { Outlet } from 'react-router-dom'
import PsideBar from 'layout/decisionnel/PsideBar'
import Header from 'containers/Header'
import { Divider } from '@mui/material'


 function Decisionnel(props) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-5 gap-4 h-full'>
        <div className='hidden md:block md:col-span-1 max-h-[100vh]'>
          <PsideBar />
        </div>

        <div className='md:col-span-4 flex flex-col  max-h-[100vh]'>
            <Header />
            <main style={{marginTop:20,overflow:'auto',marginBottom:20}}>
                <Outlet />
            </main>
            <Divider />
            <div style={{display:'flex',bottom:0, justifyContent:'center',paddingTop:20}}>  
            Copyright &#169; { new Date().getFullYear() } - Tous droits réservés PFS Port-bouet  </div>
        </div>

    </div>
  )
}

export default Decisionnel

