import React, { useEffect,useState } from 'react'
import Flink from '../components/Flink'
import { useAuth } from 'hooks/auth'
import { useSelector } from 'react-redux'
import ConnexionBox from '../components/ConnexionBox'
import { Button, ButtonGroup, Card, Form, Row, Spinner, ToggleButton } from 'react-bootstrap'
import axios from 'lib/axios'
import { useNavigate } from 'react-router-dom'
import { fontSize, padding, style } from '@mui/system'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Checkbox, Divider, FormControlLabel, IconButton, Paper, Tooltip } from '@mui/material'
import {villes,communes,doctype} from 'fixtures/data'
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import swal from 'sweetalert'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { red } from '@mui/material/colors'
import { pays,secteuractivites,metiers,niveauetudes,niveauexperience,competences } from 'fixtures/data'
import { fichieInscription } from 'lib/application'
import dayjs from 'dayjs'
const steps = [
    'Votre besoins',
    'Informationnelles',
    'Filières / Formations',
    'Coordonnées',
  ];

  const groupItems=[
    {
      name: "cursus",
      label: "Parcours scolaire",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "formation",
      label: "Formations",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "experience",
      label: "Expériences professionnelles",
      information: "",
      disabled: false
    }
  ]
  const prioriteItems=[
    {
      name: "Haute",
      label: "Haute",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "Normal",
      label: "Normal",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
        name: "Bas",
        label: "Bas",
        information: "Veuillez selectionner un type",
        disabled: false
      }
  ]
  const radios = [
    { name: 'Basse', value: 'Basse' },
    { name: 'Normale', value: 'Normale' },
    { name: 'Haute', value: 'Haute' },
  ];
function Inscription() {
    const {user}  =useAuth({ middleware: 'auth' })
    const portail = useSelector(state=>state.ui.portail)

    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const [currentcg,setCurrentcg]=React.useState([])
    const [services,setServices] = useState(null)
    const [quartiers,setQuartiers] = useState(null)
    const [cqvcgv,setcqvcgv] = useState(null)
    const curentDate = new Date()
    const anneeStart  = (curentDate.getFullYear())-40
    const anneeEnd  = curentDate.getFullYear()

    const [inputfield,setInputField] = useState({
        datenaissance:new Date('2014-08-18T21:11:54'),
        parcours:[],
        quartier:'',
        demande:{
            "service":null,
            "situation":"etudiant",
            "description":null,
        }
      });

      const handleInputChange = (e)=>{
        const {name,value} = e.target
        setInputField({...inputfield,[name]:value})
      }
    
    useEffect(()=>{
        axios.get(`/api/type-demande/all`).then(res=>{
        if(res.status === 200 || res.status === 201){
            setServices(res.data)
        }
    })
    axios.get(`/api/quartier/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setQuartiers(res.data)
      }
    })
    axios.get(`/api/comite/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setcqvcgv(res.data)
        setCurrentcg(res.data)
      }
    })
  },[])

  const handlefiltercd =()=>{
    if(inputfield.hasOwnProperty('quartier') && cqvcgv){
      const newcg = cqvcgv.filter(item=>item.quartier?.nom === inputfield['quartier'])
      setCurrentcg(newcg)
    }else{
      setCurrentcg(cqvcgv)
    }
  }
  useEffect(()=>{
    handlefiltercd()
  },[inputfield.quartier,cqvcgv])
 

const handleChangeDemande = (e)=>{
    const {name,value} = e.target
    let currentDemande = (inputfield.hasOwnProperty('demande') ? {...inputfield.demande} : {})
    let maDemande = {...currentDemande,[name]:value}
    setInputField({...currentDemande,'demande':maDemande})
}

  const handleEditorChange =(field,value)=>{
    
    let currentDemande = (inputfield.hasOwnProperty('demande') ? {...inputfield.demande} : {})
    let maDemande = {...currentDemande,[field]:value}
    setInputField({...currentDemande,'demande':maDemande})
  }


const saveInscription =()=>{
    inputfield["datedossier"] = dayjs().format('YYYY-MM-DD'),


    axios.post(`/api/dossier/inscription-jeune`,inputfield).then(res=>{
        if(res.status === 201 || res.status === 200){
            swal({
                title: "Félicitation !",
                text: "Votre demande d'inscription à été bien effectuée, Un conseiller prendra contact avec vous dans un bref délai!",
                icon: "success"})
                fichieInscription(res.data)
            navigate("/")
        }else{
            swal({
                title: "Ooops!",
                text: "Problème lors du traitement, veuillez réessayer plutart",
                icon: "warning",
                dangerMode: true,
            })
            return 
        }
    }).catch(err=>{
        console.log(err)
        swal({
            title: "Ooops!",
            text: "Impossible de terminer cette opéréation assurez vous d'avoir rempli tous les champs",
            icon: "error",
            dangerMode: true,
        })
        return 
    })
}
const handleSubmit = (e) => {
    e.preventDefault()
    if(inputfield.nom ==null || inputfield.nom.trim()===""){
        swal("Ooops !","Veuillez rensigner tous les champs obligatoire","warning")
        return
    }
    if(inputfield.prenom ==null || inputfield.prenom.trim()===""){
        swal("Ooops !","Veuillez rensigner tous les champs obligatoire","warning")
        return
    }
    if(inputfield.datenaissance ==null || inputfield.datenaissance.trim()===""){
        swal("Ooops !","Veuillez rensigner tous les champs obligatoire","warning")
        return
    }
    if(inputfield.demande ==null){
        swal("Ooops !","Aucune demande renseigner","warning")
        return
    }
    const url = `/api/users/basic-search?page=1`
    const dataSend ={civilite:inputfield.civilite,nom:inputfield.nom,prenom:inputfield.prenom,datenaissance:inputfield.datenaissance,'combine':true}
        axios.post(url,dataSend).then(res=>{
          if(res.status === 200 || res.status ===201){
                if(res.data.data && res.data.data.length!==0){
                    swal("Ooops","Un dossier identifique à votre profil à été détecté par le système","warning")
                    return 
                }else{
                    saveInscription()
                }
            }else{
                console.log(res.data);
                swal("Ooops","Le système à rencontrer une erreur, veuillez réessayer plutart","warning")
                return 
            }
        }).catch(err=>{
            console.log(err);
            swal("Ooops","Le système à rencontrer une erreur, veuillez réessayer plutart","error")
            return 
        })
}

const paysLis =()=>{
    const rows =[]
    for (let i = anneeEnd; i >= anneeStart; i--){
        rows.push(<option key={i} style={{fontWeight:'bold'}} value={i}>{i}</option>)
    }
    return rows
}

return (
    <>
        <div className="container-fluid bg-primary py-2 bg-hero">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-3 text-white mb-md-2">Bienvenue sur votre plate forme de services(PFS)</h1>
                        <h4 className='lead'>Inscrivez-vous ici et profitez de l'accompagnement de nos meilleures conseillers</h4>
                        <h4 className='lead'>Recez des alerte des offres d'emploi et de formation de nos partenaires</h4>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="container">
                <div className="row g-0 d-flex justify-content-center">
                    <div className="col-lg-8">
                        <div className="bg-d h-100 w-100">
                            <form className='mt-5' onSubmit={handleSubmit}>
                                <div style={{backgroundColor:red[100],color:red[800],fontWeight:500}} className='rounded-lg p-4  mb-2 flex items-center justify-center'>
                                    <PriorityHighIcon style={{fontSize:40, color:red[800]}} />
                                    <p style={{fontStyle:'italic',fontWeight:'bold',color:red[800],fontSize:12}}>* En remplissant ce formulaire vous autorisez la Plate Forme de services (PFS) de Port-bouet à partager vos informations avec nos parténaires afin de trouver de trouver les meilleures offres correspondant à votre profile</p>
                                </div>
                                <Card style={{  width: '100%',padding:20,marginBottom:20 }} > 
                                    <Card.Title> Votre besoins</Card.Title>
                                    <Card.Body>
                                        <Form.Group as={Row} className=' mt-3'>
                                        {(services && services.length !== 0)&&(<div className='col-lg-6 col-sm-12'>
                                        <label for="naturepiece" className="form-label">Quel est le motif de votre demande ?</label>
                                        <Form.Select className=' mb-5' onChange={handleChangeDemande} defaultValue={inputfield.demande.service} name='service' aria-label="Default select example" >
                                            <option style={{fontWeight:'bold'}}></option>
                                            {services.map(srv=> <option value={srv.id}>{srv.libelle}</option>)}
                                            </Form.Select> </div>)}
                                           
                                            <div className='col-lg-6 col-sm-12'>
                                                    <label for="situation" className="form-label">Votre situation actuelle</label>
                                                    <Form.Select className='mb-2' onChange={handleInputChange} name='situation' defaultValue={inputfield.situation} aria-label="Default select example">
                                                        <option value='occupe'>Occupé</option>
                                                        <option value='chomeur'>Chômeur</option>
                                                        <option value='enquete'>En quête</option>
                                                        <option value='entrepreneur'>A mon propre compte</option>
                                                        <option value='autre'>Autre</option>
                                                    </Form.Select>
                                                </div>
                                
                                                {(inputfield.situation ==='autre')&&<div className='col-lg-12'>
                                                    <Form.Control className='mb-2 sm-2' value={inputfield.demande['autresituation'] || ""} onChange={handleInputChange} name='autresituation' type="text" placeholder="Précisez votre situation" />
                                                </div>}
                                                </Form.Group>
                                        <ReactQuill  
                                            placeholder={"Decrivez en quelques mots votre demande"} 
                                            style={{width:'100%',height:100}}
                                            name='description' 
                                            theme="snow" 
                                            value={inputfield.demande['description'] || ""} 
                                            onChange={(v)=>handleEditorChange('description',v)} 
                                           
                                        />    
                                    </Card.Body>   
                                </Card>
                                <Card style={{  width: '100%',padding:20,marginBottom:20 }} > 
                                    <Card.Title>Informations personnelles</Card.Title>
                                    <Card.Body>
                                    <div className='col-lg-12 col-sm-12'>
                                                    <label for="civilite" className="form-label">Civilité *</label>
                                        <Form.Select required className='mb-3' onChange={handleInputChange} name='civilite' defaultValue={inputfield.civilite}  aria-label="Default select example">
                                            <option style={{fontWeight:'bold'}}></option>
                                            <option value='Mme'>Madame</option>
                                            <option value='Mlle'>Mademoiselle</option>
                                            <option value='M'>Monsieur</option>
                                        </Form.Select>
                                        </div>
                                        <Form.Group as={Row} className='mb-3'>
                                            <div className='col-lg-4'>
                                                <Form.Control required className='mb-3 sm-2' value={inputfield['nom'] || ""} onChange={handleInputChange} name='nom' type="text" placeholder="Nom*" />
                                            </div>
                                            <div className='col-lg-8'>
                                                <Form.Control required className='mb-3 col-x-8' value={inputfield['prenom'] || ""} onChange={handleInputChange} name='prenom' type="text" placeholder="Prénom*" />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Row} className='mb-3'>
                                            <div className='col-lg-4 col-sm-12'>
                                                <label for="datenaissance" className="form-label">Date de naissance*</label>
                                                <input required type="date" className="form-control" value={inputfield['datenaissance'] || ""} onChange={handleInputChange} name='datenaissance' id="datenaissance" placeholder="Date de naissance" />
                                            
                                            </div>
                                            <div className='col-lg-8 col-sm-12'>
                                                <label for="lieunaissance" className="form-label">Lieu de naissance</label>
                                                <Form.Select className='mb-3' onChange={handleInputChange} name='lieunaissance' defaultValue={inputfield.lieunaissance} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}>Lieu de naissance</option>
                                                    {(villes && villes.lenght!=0)&&villes.map(ville=>(<option  key={ville} value={ville}>{ville}</option>))}
                                                </Form.Select>
                                            </div>
                                            <div className='col-lg-6 col-sm-12'>
                                                    <label for="nationalité" className="form-label">Nationalité</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='paysdiplome' defaultValue={inputfield.nationalite} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}></option>
                                                      {pays.map(p=><option key={p.nationalite} style={{fontWeight:'bold'}} value={p.nationalite}>{p.nationalite}</option>)}
                                                            
                                                        
                                                    </Form.Select>
                                                </div>
                                            <div className='col-lg-8 col-sm-12'>
                                                <label for="numeropiece" className="form-label">N° pièce d'identification</label>
                                                <input type="text" className="form-control" onChange={handleInputChange} value={inputfield['numeropiece'] || ""} name='numeropiece' id="numeropiece" placeholder="N° pièce d'identification" />
                                            
                                            </div>
                                                <div className='col-lg-4 col-sm-12'>
                                                    <label for="naturepiece" className="form-label">Nature de la pièce</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='naturepiece' defaultValue={inputfield.naturepiece} aria-label="Default select example">
                                                        <option style={{fontWeight:'bold'}}>Nature de la picèce</option>
                                                        <option value='CNI'>CNI</option>
                                                        <option value='Attestation'>Attestation d'identité</option>
                                                        <option value='Passport'>Passport</option>
                                                        <option value='Permis'>Permis de conduire</option>
                                                        <option value='Carte consulaire'>Carte consulaire</option>
                                                        <option value='Autre'>Autre</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                            <Divider />
                                            <Form.Group as={Row} className='mb-3'>
                                                <RadioField
                                                    groupItems={[
                                                        {
                                                            name: "O",
                                                            label: "Oui",
                                                          
                                                          },
                                                          {
                                                            name: "N",
                                                            label: "Non",
                                                          
                                                          }
                                                    ]}
                                                    group="Estes-vous victime d'un handicap"
                                                    checkedDefault="Non"
                                                    appearance={radiobutton_appearances.primary}
                                                    onChange ={handleInputChange}
                                                    name='esthandicap'
                                                    vvalue ={inputfield.esthandicap}
                                                />
                                                {(inputfield.esthandicap && inputfield.esthandicap==="O")&&<Form.Control required  value={inputfield['naturehandicap'] || ""} onChange={handleInputChange} name='naturehandicap' type="text" placeholder="Nature de l'handicap*" />}
                                             
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <Card style={{  width: '100%',padding:20,marginBottom:20 }} > 
                                        <Card.Title>Vos qualifications</Card.Title> 
                                        <Card.Body>
                                            <Form.Group as={Row} className='mb-3'>
                                                <div className='col-lg-6 col-sm-12 col-md-6'>
                                                    <Form.Control className='mb-3 sm-2' name='diplome' value= {inputfield.diplome} onChange={handleInputChange} type="text" placeholder="Diplôme" />
                                                </div>
                                                <div className='col-lg-6 col-sm-12 col-md-6'>
                                                    <Form.Control className='mb-3 col-x-8' name='etablissement' value= {inputfield.etablissement} onChange={handleInputChange} type="text" placeholder="Etablissement d'obtention" />
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Row} className='mb-3'>
                                                <div className='col-lg-6 col-sm-12'>
                                                    <label for="anneediplome" className="form-label">Année d'obtention</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='anneediplome' defaultValue={inputfield.anneediplome} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}></option>
                                                        {paysLis().map(r=>r)} 
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className='col-lg-6 col-sm-12'>
                                                    <label for="paysdiplome" className="form-label">Pays d'obtention</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='paysdiplome' defaultValue={inputfield.paysdiplome} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}></option>
                                                      {pays.map(p=><option key={p.libelle} style={{fontWeight:'bold'}} value={p.libelle}>{p.libelle}</option>)}
                                                            
                                                        
                                                    </Form.Select>
                                                </div>
                                                
                                            </Form.Group>
                                            <Divider style={{margin:20}} />
                                            
                                           
                                            <Form.Group as={Row} className='mb-3'>
                                                <div className='col-lg-6 col-sm-12'>
                                                    <label for="niveauetude" className="form-label">Niveau d'étude</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='niveauetude' defaultValue={inputfield.niveauetude} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}></option>
                                                        {niveauetudes.map((niveau,idx)=><option key={idx} style={{fontWeight:'bold'}} value={niveau}>{niveau}</option>)} 
                                                        
                                                    </Form.Select>
                                                </div>
                                                
                                            </Form.Group>
                                            <Divider style={{margin:20}} />
                                            <div className='col-lg-12 col-sm-12'>
                                                <label for="activite" className="form-label">Quel activité pratiquez vous ?</label>
                                                <Form.Control  name='activite' value= {inputfield.activite} onChange={handleInputChange} />
                                            </div>
                                            <Form.Group as={Row} className='mb-3'>
                                                <div className='col-lg-6 col-sm-12'>
                                                    <label for="secteuractivite" className="form-label">Secteur de métier</label>
                                                    <Form.Select className='mb-3' onChange={handleInputChange} name='secteuractivite' defaultValue={inputfield.secteuractivite} aria-label="Default select example">
                                                    <option style={{fontWeight:'bold'}}></option>
                                                        {secteuractivites.map((activite,idx)=><option key={idx} style={{fontWeight:'bold'}} value={activite}>{activite}</option>)} 
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className='col-lg-6 col-sm-12'>
                                                    <label for="anneeexperience" className="form-label">Nombre d'année d'expérience</label>
                                                    <Form.Control  name='anneeexperience' value= {inputfield.anneeexperience} onChange={handleInputChange} type="number" />
                                                </div>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                    <Card style={{  width: '100%',padding:20,marginBottom:20 }} > 
                                        <Card.Title>Contacts</Card.Title>
                                        <Card.Body>
                                        
                                            <div className='col-lg-12 col-sm-12 mb-3'>
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" onChange={handleInputChange} value={inputfield['email'] || ""} name='email' id="email" placeholder="email@example.com" />
                                            </div>
                                            <Form.Group as={Row} className='mb-3'>
                                                <div className='col-lg-4 col-sm-12'>
                                                    <label for="mobile" className="form-label">Téléphone</label>
                                                    <input required type="phone" className="form-control" onChange={handleInputChange} name='mobile1' value={inputfield['mobile1'] || ""} id="mobile" placeholder="00 00 00 00 00" />
                                                </div>
                                                <div className='col-lg-4 col-sm-12'>
                                                    <label for="mobile2" className="form-label">Téléphone 2</label>
                                                    <input type="phone" className="form-control" onChange={handleInputChange} name='mobile2' value={inputfield['mobile2'] || ""} id="mobile2" placeholder="00 00 00 00 00" />
                                                </div>
                                                <div className='col-lg-4 col-sm-12'>
                                                    <label for="numerowhatsapp" className="form-label">N° whatsapp</label>
                                                    <input type="phone" className="form-control" onChange={handleInputChange} name='numerowhatsapp' value={inputfield['numerowhatsapp'] || ""} id="numerowhatsapp" placeholder="00 00 00 00 00" />
                                                </div>
                                            </Form.Group>
                                            <div className="mb-3">
                                                <label for="bp" className="form-label">Adresse</label>
                                                <textarea className="form-control" onChange={handleInputChange} name='bp' value={inputfield['bp'] || ""} id="bp" rows="3"></textarea>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card style={{  width: '100%',padding:20,marginBottom:20 }}>
                                        <Card.Title>Localisation</Card.Title>
                                        <Card.Body>
                                            <Form.Group as={Row} className='mb-3'>
                                                    <div className='col-lg-6 col-sm-12'>
                                                        <label for="lieuresidence" className="form-label">Ville de résidence</label>
                                                        <Form.Select className='mb-3' onChange={handleInputChange} id='lieuresidence' defaultValue={inputfield.lieuresidence || ""} name='lieuresidence' aria-label="Default select example">
                                                            <option style={{fontWeight:'bold'}}></option>
                                                            {(villes && villes.lenght!=0)&&villes.map((ville,index)=>(<option key={`${ville}-${index}`} value={ville}>{ville}</option>))}
                                                        </Form.Select>
                                                    </div>
                                                
                                                    <div className='col-lg-6 col-sm-12'>
                                                        <label for="commune" className="form-label">Commune</label>
                                                        <Form.Select className='mb-3' onChange={handleInputChange} defaultValue={inputfield.commune} name='commune' aria-label="Default select example">
                                                            <option style={{fontWeight:'bold'}}></option>
                                                            {(communes && communes.lenght!=0)&&communes.map((commune,idx)=>(<option key={idx} value={commune}>{commune}</option>))}
                                                        </Form.Select>
                                                    </div>
                                                    
                                                    <div className='col-lg-6 col-sm-12'>
                                                        <label for="quartier" className="form-label">Quartier</label>
                                                        <Form.Select className='mb-3' onChange={handleInputChange} defaultValue={inputfield.quartier} name='quartier' aria-label="Default select example">
                                                            <option style={{fontWeight:'bold'}}></option>
                                                            {(quartiers && quartiers.lenght!=0)&&quartiers.map(quartier=>(<option key={quartier.id} value={quartier.nom}>{quartier.nom}</option>))}
                                                        </Form.Select>
                                                    </div>
                                                    </Form.Group>
                                            </Card.Body>
                                    </Card>
                                    <Card style={{  width: '100%',padding:20,marginBottom:20 }} >
                                        <div style ={{display:'flex',justifyContent:'space-between'}}>
                                            <Button type='button' style={{backgroundColor:'#F29400',border:'none'}}>
                                                Annuler
                                            </Button>
                                            <Button disabled={loading} type='submit' style={{marginLeft:20,backgroundColor:'#00B04B'}}>
                                                {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />}  Enregistrer
                                        </Button>
                                        </div>
                                    </Card>
                            
                                </form>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
    
    </>
  )
}

export default Inscription
