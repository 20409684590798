import React,{useEffect,useState} from 'react'
import Dash from 'components/dash'
import styled from 'styled-components';
import { useNavigate,useLocation,useOutletContext } from 'react-router-dom';
import {Pagination, Paper, Tooltip, Typography} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add'
import Offreh from 'containers/Offreh/Offreh';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import axios from 'lib/axios';
import ReplyAllSharpIcon from '@mui/icons-material/ReplyAllSharp';
import swal from 'sweetalert';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';
const OfferHeader = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
  margin-bottom:20px;
 
`
const OfferTitle = styled.h1`
  font-size:15px;
  color:#707070;
  text-transform:uppercase;
  margin-bottom:10px;
`
const Hr = styled.hr`
  width:100%;
  background:#00B04B;
  opacity:.5
`
const useStyles =makeStyles()((theme)=>({
    actionBar:{
      display:'flex',
      justifyContent:'flex-end'
  },
  btnAdd:{
    color:'#00B04B',
    fontSize:40,
    '&:hover':{
      color:'#F29400'
    }
  },
  searchBox:{
    display: 'flex',
    padding:20,
    marginBottom:20,
    flexDirection: 'column',
  },
  btnsearch:{
      paddingLeft:20,
      paddingRight:20,
      backgroundColor: '#00B04B',
      color:'white',
      marginRight:20
    }
  }))
  
function OffresLsit(props) {
    const {classes} = useStyles()
    const [titre,setTitre] = useOutletContext();
    const navigate =useNavigate()
    const {state} =useLocation()
    const [search,setSearch] = useState(null)
    const portail = useSelector(state=>state.ui.portail).toLowerCase()
    const [paginateData,setPagnateData] = useState(null)
    const handleBack=()=>{
      navigate(`/${portail}/offres`)
    }
  const [offrespartenaires,setOffrespartenaire] = React.useState(null)
  const handleOffresPartenaire =(numberPage = 1)=>{
    const url =  search? `/api/offre/list/${search}?page=${numberPage}` : `/api/offre/list?page=${numberPage}` 
    axios.get(url).then(res=>{
      if(res.status === 200 || res.status === 201){
        setOffrespartenaire(res.data.data)
        setPagnateData({
          current_page:res.data.meta?.current_page,
          last_page:res.data.meta?.last_page,
          per_page:res.data.meta?.per_page,
          total:res.data.meta?.total
        })
      }else{
        swal("Ooops !","Aucune offre trouvé","error")
      }
    }).catch(err=>{
      swal("Ooops !","Erreur lors de l'opération","error")
    })
    
  } 
  useEffect(()=>{
    handleOffresPartenaire()
  },[])
  const handleNouveau =()=>{
    setTitre("Gestion des offres - Créer")
    navigate(`/${portail}/offres/nouveau`)
  }
  
  const handleDetails =(data)=>{
    setTitre("Gestion des offres - N° "+data['code'])
    if(data){
      navigate(`/${portail}/offres/fiche/${data['id']}`,{replace:true, state:{data}})
    }
  }
  const handleEdit =(data)=>{
    if(data){
      setTitre("Gestion des offres - Modification")
      navigate(`/${portail}/offres/modifier/${data['id']}`,{
        state: {
          data:data,
        }
      })
    }
  }


  const handleDelete =(offre)=>{
     axios.delete(`/api/offre/delete/${offre['id']}`).then(res=>{
      console.log(res);
      if(res.status ===200 || res.status === 201 || res.status ===204){
        swal("Bien !","Suppression effectuée avec succès","success")
        handleOffresPartenaire()
      }else{
        swal("Ooops!","Impossible d'effectué cette opération",'error')
      }
     }).catch(err=>{
      swal("Ooops!",`Erreur inconnu lors de la suppression`,'error')
     })
  }

  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20,marginLeft:20}}>
         <Dash.Frame direction='column' salign='flex-start' style={{marginTop:20,marginBottom:20,padding:10}}>
              <OfferHeader>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <OfferTitle>Liste des offres</OfferTitle>
                  <div className={classes.actionBar}>
                    <IconButton onClick={handleBack} aria-label="part-add">
                        <ReplyAllSharpIcon className={classes.btnAdd} />
                    </IconButton>
                    <IconButton  aria-label="part-add" onClick={handleNouveau}>
                        <AddIcon className={classes.btnAdd} />
                    </IconButton>
                  </div>
                </div>
                <Hr />
                <div>
                  <Typography variant='h5'>RECHERCHER</Typography>
                  <Paper className={classes.searchBox} variant='outlined'>
                      <InputGroup className="mb-3">
                          <Form.Control
                              placeholder="Rechercher ...(titre,partenaire,contrat,...)"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(event)=>setSearch(event.target.value)}
                          />
                          <div>
                            <Button variant="outline-success" id="button-addon2" className={classes.btnsearch} onClick={()=>handleOffresPartenaire(1)}>
                                <SearchIcon />
                            </Button>
                            <Button variant="outline-success" id="button-addon2" onClick={()=>handleOffresPartenaire(1)}>
                                <PageviewIcon />Recherche détaillée
                            </Button>
                          </div>
                      </InputGroup>
                  </Paper>
                </div>
              </OfferHeader>
              <Table>
                <tbody>
                {(offrespartenaires  && offrespartenaires.length!==0) ?offrespartenaires.map(offre=>(
                  <tr>
                    <td><Offreh readmore  offre={offre}  /></td>
                    <td valign='middle'>
                      <div style={{display:'flex',flexDirection:'column'}}>
                      <Tooltip title="Détails">
                        <IconButton onClick={() =>handleDetails(offre)}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Modidier">
                        <IconButton onClick={() =>handleEdit(offre)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer">
                        <IconButton onClick={() =>handleDelete(offre)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      </div>
                    </td>
                  </tr>)) : (<tr><td colSpan={2}><p>Aucune offre trouvée</p></td></tr>)

                }
                </tbody>
              </Table>
              {paginateData&&<div style={{display:'flex',justifyContent:'center',marginTop:20,marginBottom:20}}>
                    <Pagination
                        component="div"
                        count={paginateData?.last_page}
                        defaultPage={1}
                        page={paginateData?.current_page}
                        onChange={(event,numberPage)=>handleOffresPartenaire(numberPage)}
                        rowsPerPage={paginateData?.per_page}
                        variant='outlined'
                        showFirstButton={paginateData?.current_page>10} 
                        showLastButton ={paginateData?.total>=10}
                        />
                </div>}
      </Dash.Frame>
           
    </div>
  )
}

export default OffresLsit