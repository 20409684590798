import React,{useState,useEffect} from 'react'
import axios from 'lib/axios';

function Fstats() {
    const [statjeune,setStatjeune] =useState(null)
    const handleStatejeune = () =>{
        axios.get(`/api/state/home/jeunes`).then(res=>{
            if(res.status ===200 || res.status === 201){
                setStatjeune(res.data)
            }
        })
    }
    useEffect(()=>{
        handleStatejeune()
    },[])

  return (
    <div className="container-fluid bg-primary facts py-5 mb-5">
    <div className="container py-5">
        <div className="row gx-5 gy-4">
            <div className="col-lg-3 col-md-6">
                <div className="d-flex">
                    <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                        <i className="fa fa-star fs-4 text-white"></i>
                    </div>
                    {(statjeune && statjeune.hasOwnProperty('dossiers'))&&(
                        <div className="ps-4">
                        <h5 className="text-white">Jeunes accueillis</h5>
                        <h1 className="display-5 text-white mb-0" data-toggle="counter-up">+{statjeune['dossiers']}</h1>
                    </div>
                    )}
                    
                </div>
            </div>
            {(statjeune && statjeune.hasOwnProperty('emplois'))&&(
                <div className="col-lg-3 col-md-6">
                <div className="d-flex">
                    <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                        <i className="fa fa-users fs-4 text-white"></i>
                    </div>
                    <div className="ps-4">
                        <h5 className="text-white">Aide à l'emploi</h5>
                        <h1 className="display-5 text-white mb-0" data-toggle="counter-up">+ {statjeune['emplois']}</h1>
                    </div>
                </div>
            </div>
            )}
            {(statjeune && statjeune.hasOwnProperty('projets'))&&(
                <div className="col-lg-3 col-md-6">
                <div className="d-flex">
                    <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                        <i className="fa fa-check fs-4 text-white"></i>
                    </div>
                    <div className="ps-4">
                        <h5 className="text-white">Projets/ auto emploi</h5>
                        <h1 className="display-5 text-white mb-0" data-toggle="counter-up">+ {statjeune['projets']}</h1>
                    </div>
                </div>
            </div>
            )}
            {(statjeune && statjeune.hasOwnProperty('projets'))&&(
                <div className="col-lg-3 col-md-6">
                <div className="d-flex">
                    <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
                        <i className="fa fa-mug-hot fs-4 text-white"></i>
                    </div>
                    <div className="ps-4">
                        <h5 className="text-white">Renforcement des capacités</h5>
                        <h1 className="display-5 text-white mb-0" data-toggle="counter-up">+ {statjeune['formations']}</h1>
                    </div>
                </div>
            </div>
            )}
            
        </div>
    </div>
</div>
  )
}

export default Fstats
