import Etat from 'pages/backend/commons/etats';
import DashDecisionnel from 'pages/backend/dashbord/DashDecisionnel';
import SearchFields from 'pages/backend/recherche/SearchFields';
import SearchResult from 'pages/backend/recherche/SearchResult';

const routes =[
    { path: '/', index:true, component: DashDecisionnel},
    { path: 'etat', name: 'Liste des dossiers', component: Etat},
    { path: 'analyse', name: 'Analyse', component: Etat},
    { path: 'rapport', name: 'Liste des rapports', component: Etat },
    { path: 'recherches/resultats', name: 'Résultat recherche', component: SearchResult },
    { path: 'recherches/criteres', name: 'critère de recherche', component: SearchFields },

]

export default routes