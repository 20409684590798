import React from 'react'
import ConnexionBox from '../components/ConnexionBox'
function ArticleList() {
  return (
    <>
      
        <div className="container-fluid bg-primary py-5 bg-hero mb-5">
            <div className="container py-5">
                <div className="row justify-content-start">
                    <div className="col-lg-8 text-center text-lg-start">
                        <h1 className="display-1 text-white mb-md-4">Nos actualités</h1>
                        <ConnexionBox />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-1.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">PARTENARIAT AGEFOP-MAIRIE DE PORT-BOUET Dr EMMOU, TOUJOURS UNE LONGUEUR D’AVANCE</h4>
                            <span className="text-white fw-bold">Nov 12, 2020</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-2.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Présentation de 24 Jeunes recrutés pour une formation d'un an au Centre de Formation Professionnelle de Bimbresso dans le domaine de la Construction métallique, la mécanique auto, l'électricité bâtiment ...</h4>
                            <span className="text-white fw-bold">Juil 28, 2020</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-3.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">La Commune de Port-bouet est fortement présente au salon des Collectivités 2019, qui a vu son ouverture ce Jeudi 07 Novembre 2019 à Heden Golf Hôtel</h4>
                            <span className="text-white fw-bold">Nov 07, 2019</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-3.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Visite ce Mercredi 06 Novembre 2019 en mi-journée des Brigades Lycée Moderne et Lycée Municipal de Gonzaqueville...</h4>
                            <span className="text-white fw-bold">Nov 07, 2019</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-3.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">La phase 2 des THIMO a démarré
Après la phase 1 de recrutement par tirage au sort le 24 Septembre 2019</h4>
                            <span className="text-white fw-bold">Sept 29, 2019</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={require("../img/blog-3.jpg")} alt="" />
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Étape de Vaccination et Visite médicale des jeunes bénéficiaires du Projet THIMO PFS/AEJ, ce Mercredi 09 Octobre 2019 à la Mission Locale de Port Bouet</h4>
                            <span className="text-white fw-bold">Oct 09, 2019</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </>
  )
}

export default ArticleList