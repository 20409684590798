import React from 'react'

const DispositifsJeunes = () => {
  return (
    <div>
      Dispositifs jeunes
    </div>
  )
}

export default DispositifsJeunes
