import React,{useContext, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { IconButton, MenuItem, Paper, Typography } from '@mui/material'
import axios from 'lib/axios'
import {formatDistance} from 'date-fns'
import { fr } from 'date-fns/locale'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs'
import {Divider} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Form as CustomForm } from 'components'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { red } from '@mui/material/colors'
import swal from 'sweetalert'
import { CommonsContext } from 'context/CommonsContext'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
const styles ={
  detContainer:{
    display:'flex',
    flexDirection:'row',
    flex:1,
    justifyContent:'center'
  },

  detItemLeft:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    alignItems:'flex-end',
    fontWeight:'bold',
    justifyContent:'flex-start',
   
  },
  detItemRight:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flex:1,
    marginLeft:20,
  },
  label:{
    fontStyle:'italic',
    fontWeight:'bold'
  },
  toolsbard:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  logoContainer:{
    display: 'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    flex:1,
  },
}
const useStyles = makeStyles()((theme)=>({
  containBox:{
      display:'flex',
      justifyContent:'space-between'
  },
  searchBox:{
      display: 'flex',
      padding:20,
      marginBottom:20,
      flexDirection: 'column',
  },
  btnAdd:{
      paddingLeft:20,
      paddingRight:20,
      backgroundColor: '#00B04B',
      color:'white',
      marginRight:20
  },
  detaildossier:{
      display:'flex',
      flex:1,
      justifyContent:'space-between'
    },
    labelle:{
      fontStyle:'italic',
      fontWeight:'bold'
    },
    navLabel:{
      textTransform:'uppercase', 
      marginLeft:10,
      fontSize:14
    },
    titleBox:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    iconsbtn:{
      color:'#00B04B',
      '&:hover':{
        color:'#F29400'
      }
    },
    iconsbtnDis:{
      color:red[400],
      '&:hover':{
        color:'#F29400'
      }
    },
    cardOffres:{
      flex:1,
      margin:10,
      padding:20,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      cursor:'pointer',
      '&:hover':{
          backgroundColor:'#00B04B',
          color:'white',
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      }
  },
  cardOffresCheck:{
      flex:1,
      margin:10,
      padding:20,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      cursor:'pointer',
      backgroundColor:'#00B04B',
      color:'white',
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
  
  }
}))
const FichePropositionDispositifs = (props) => {
    const location =useLocation()
    const {dossier} = location.state
    const {classes} = useStyles()
    const [photo,setPhoto] =useState(null)
    const [inputSearch,setInputSearch] = useState({
        statut:'Actif'
    })
    const {handleCommonsdata} = useContext(CommonsContext)
    useEffect(()=>{
      handleCommonsdata('titre',"Dossier - Proposition de dispositif d'accompagnement")
    },[])

    useEffect(()=>{
        if(dossier.jeune?.hasOwnProperty('photo') && dossier.jeune?.photo !==null && dossier.jeune?.document!=='null'){
        setPhoto(dossier.jeune.photo)
        }else{
            if(dossier.jeune.hasOwnProperty('documents')){
                const tof = dossier.jeune.documents.filter(item=>item.titre === 'Photo')
                if(tof && tof.length !== 0){
                setPhoto(tof[0].url)
                }
            }
        }
    },[])

    const [dispositifList,setDispositifList] = useState(null)
    const [paginateData,setPagnateData] = useState(null)
    const handleSearch = (numberPage=1)=>{
        const url  = `/api/dispositif/list?page=${numberPage}`
        axios.get(url,inputSearch).then(res=>{
          if(res.status === 200 || res.status === 201){
            setDispositifList(res.data.data)
            setPagnateData({
              current_page:res.data.meta?.current_page,
              last_page:res.data.meta?.last_page,
              per_page:res.data.meta?.per_page,
              total:res.data.meta?.total
            })
          }
        })
      }
      useEffect(()=>{
        handleSearch(1)
      },[])
      const [inputField,setInputField] = useState({
        dossier_id:dossier.id,
        personne_id:dossier.jeune?.id,
        demande_id:null,
        dispositifs:[],
        statut:'Proposer',
        etat:'En attente',
        venu:'Proposition'
      })
      const handleChange = (e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
      }
     
      const handleList =()=>{
        navigate(`/${portail}/dossiers`)
      }
      
      const handleSubmit = (e)=>{
        e.preventDefault()
        if(inputField.demande_id && inputField.dispositif){
          console.log(inputField);
          axios.post(`/api/dispositif-jeune/add/${inputField.dossier_id}`,inputField).then(res=>{
            if(res.status===200 || res.status === 201){
              swal("Bien","Porposition effectué avec succès","success")
              handleList()
            }else{
              swal(`Ooops !`,"Erreur lors de l'enregistrement",'error')
            }
          }).catch(err=>{
            console.log(err)
            swal("Ooops","Erreur lors de l'enregistrement",'error')
          })
        }
      }

      
    const [activeStep,setActiveStep] = useState(1)
    const handleSelectDispositif = (cat)=>{
      if(cat){
          setInputField({...inputField,"dispositif":cat})
          setActiveStep(activeStep + 1)
      }
    }

  return (
    <div>
        <Card>
          <div styles={{display:'flex',flexDirection:'column'}} >
              <div style={styles.logoContainer}>
                <div>{photo&&<img width="100" src={`${process.env.REACT_APP_BACKEND_URL}/${photo}`}/>}</div> 
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginLeft:20}}>
                    <Typography variant='h2'>{`${dossier.jeune?.nom} ${dossier.jeune?.prenom}`}</Typography> 
                    <div className='flex flex-row gap-3'>
                        <div className={classes.left}>
                            <div><span className={classes.labelle}>Age : </span>{}</div>
                            <div><span className={classes.labelle}>N° Dossier : </span> <Typography variant='subtitle'>{dossier['code']||' '}</Typography></div>
                            <div><sapn className={classes.labelle}>Créer le :</sapn><span>{dayjs(dossier.created_at).format('DD/MM/YYYY')}</span></div>
                        </div>  
                        <div className={classes.midlle}>
                            <div><span className={classes.labelle}>Commune : </span>{(dossier.jeune?.commune!=='null')&&dossier.jeune?.commune}</div>
                            <div><span className={classes.labelle}>Quartier : </span><span>{dossier.jeune?.quartier}</span> </div>
                            <div><sapn className={classes.labelle}>CGQ/CGV :</sapn><span>{dossier.jeune?.cgqcgv}</span></div>
                        </div>  
                        <div className={classes.right}>
                            <div><span className={classes.labelle}>Statut : </span>{dossier['statut'] || ""}</div>
                            <div><span className={classes.labelle}>Etape de traitement : </span> {dossier['etape']}</div>
                        </div>  
                    </div>
                  </div>
              </div>
            </div>
            <Divider />
          </Card>
          <div className={classes.containBox}>
            
          <div style={{flex:2}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <form onSubmit={handleSubmit}>
                <Card style={{flex:2}}>
                    <Card.Header>  
                        <Card.Title>Fiche de proposition de dispositifs d'accompagnement</Card.Title>
                        <Card.Hr />
                    </Card.Header>
                    {activeStep===1&&<div style={{display:'flex',flexDirection:'column'}}>
                    <Typography variant='h6'> Quels dispositifs /services souhaitez-vous proposer ?</Typography>
                    <div className={classes.boxOffres}>
                        {(dispositifList && dispositifList.length!==0)&&dispositifList.map(cat=>{
                            return <Card key={cat.id} onClick={()=>handleSelectDispositif(cat)} className={(inputField.dispositif && cat.id === inputField.dispositif.id)?classes.cardOffresCheck:classes.cardOffres}>
                                <Typography variant='h5'>{cat.libelle}</Typography>
                                <ReactQuill readOnly={true} theme={"bubble"} value={cat['description'] || ""} />
                                <div>
                                  <span>Type : {cat.type}</span> <span>Statut :{cat.status} </span>
                                </div>
                            </Card>
                        })}
                    </div>
                    
                </div>}
                {activeStep===2&&
                <div>
                    <CustomForm.Group>
                    <CustomForm.Input 
                        name='demande_id' 
                        size="small" 
                        margin="dense" 
                        sx={{ width: '100%' }}
                        style={{marginRight:20}}
                        select 
                        value ={inputField['demande_id']||""} 
                        onChange={handleChange}  label="Demande">
                        {(dossier?.hasOwnProperty('demandes') && dossier.demandes!==0)&& dossier?.demandes.map(srs=>(<MenuItem  value={srs.id}>{srs.type.libelle}</MenuItem>))}
                    </CustomForm.Input>
                </CustomForm.Group>
                <FormControl>
                    <FormLabel id="statut-group-label">Staut</FormLabel>
                    <RadioGroup row aria-labelledby="dstatut-group-label" name="statut" onChange={handleChange}  >
                        <FormControlLabel value="Proposer" control={<Radio checked={inputField['statut'] == 'Proposer'} color="success" />} label="Proposer" />
                        <FormControlLabel value="Accepter" control={<Radio checked={inputField['statut'] == 'Accepter'} color="success" />} label="Accepter" />
                        <FormControlLabel value="Refuser" control={<Radio checked={inputField['statut'] == 'Refuser'} color="success" />} label="Refuser" />
                        <FormControlLabel value="Initialiser" control={<Radio checked={inputField['statut'] == 'Initialiser'} color="success" />} label="Initialiser"/>
                        <FormControlLabel value="Suspendu" control={<Radio checked={inputField['statut'] == 'Suspendu'}  color="success"/>} label="Suspendu"/>
                        <FormControlLabel value="Cloturer" control={<Radio checked={inputField['statut'] == 'Cloturer'} color="success" />} label="Cloturer"/>
                    </RadioGroup>
                </FormControl>
                <CustomForm.Group direction ='row' flex={1}>
                            <DesktopDatePicker
                                label="Date initialisation"
                                required={inputField.dispositif?.dateentreeforce==='O'}
                                inputFormat="dd/MM/yyyy"
                                value={inputField['initialiserle'] || ""}
                                name='initialiserle'
                                onChange={(val)=>setInputField({...inputField,['initialiserle']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                            <DesktopDatePicker
                                label="Date d'entrée"
                                required={inputField.dispositif?.dateentreeforce==='O'}
                                name='dateentreeprevu'
                                inputFormat="dd/MM/yyyy"
                                value={inputField['dateentreeprevu'] || ""}
                                onChange={(val)=>setInputField({...inputField,['dateentreeprevu']:val})}
                                renderInput={(params) => <CustomForm.Input size="small" margin="dense" style={{marginRight:20}} {...params} />}
                            />
                        </CustomForm.Group>
                </div>}
                
            </Card>
            <Card direction='row' palign='space-between'>
                            <CustomForm.Button type='reset' >Annuler</CustomForm.Button> 
                            {inputField.dispositif?.length!==0&&<CustomForm.Button type="submit" primary>Enregistrer</CustomForm.Button> }
                            
                        </Card>
            </form>
            </LocalizationProvider>
            </div>
            {activeStep===2&&<Card style={{flex:1,marginLeft:40}}>
                <div>
                    <Typography variant='h5'>{inputField.dispositif?.libelle}</Typography>
                    <div>
                      <p><ReactQuill readOnly={true} theme={"bubble"} value={inputField.dispositif?.description} /></p>
                      <span>Type : {inputField.dispositif?.type}</span> <br /><span>Statut :{inputField.dispositif?.status} </span>
                    </div>
                    <IconButton onClick={()=>setActiveStep(1)}>
                      <PublishedWithChangesIcon />
                    </IconButton>
                </div>
            </Card>}
            
          </div>
    </div>
  )
}

export default FichePropositionDispositifs
