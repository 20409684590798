import React, { useState,useEffect } from 'react'
import {Button, MenuItem, Paper, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { Card } from 'components'
import { Form as CustumForm } from 'components'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControlLabel } from '@mui/material'
import "./styles/DispositifsAdd.scss"
import axios from 'lib/axios';
import swal from 'sweetalert';
import { Alert, Form, Spinner, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import {RadioField} from "components";
import { makeStyles } from 'tss-react/mui';
import { radiobutton_appearances } from "components/radioField";
import {dispositiSortie,typePhase} from 'fixtures/data'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const initialState ={
  libelle:null,
  description:null,
  type:null,
  suspensionok:null,
  dateentreeforce:null,
  dateinitforcer:null,
  mesurenationale:null,
  typeappui:null,
  deployementauto:null,
  esmultiple:null,
  clotureauto:null,
  motifsortieauto:null,
  durefincloture:"",
  dateentreepasse:null,
  nombremois:null,
  dureelimite:null,
  dureedispositif:null,
  etendu:null,
  dureeextension:null,
  versementactiver:null,
  nbreperiodeinit:null,
  nbreperiodeext:null,
  statut:"Actif",
  montantmaxpaie:null,
  montantmaxper:null,
  phaseok:null,
  nbrephaselimite:null,
  chevauchephase:null,
  ordonnancementphase:null,
  phases:[],
}
const SaisiePhase =({open,handleClose,data,setData})=>{
  const [inputPhase,setInputPhase] = useState({})
  const handleChangeInputPhase = (e)=>{
    const {name,value} = e.target
    setInputPhase({...inputPhase,[name]:value})
    
  }
  
  const handleAddPhase = ()=>{
    if(inputPhase.hasOwnProperty('typephase') && inputPhase.hasOwnProperty('description') && inputPhase.hasOwnProperty('dureephasefixe')){
      if(data && data.length !==0 && data.findIndex(item => item.typephase === inputPhase.typephase)){
        swal("Ooops","Cette phase existe déjà","warning")
      }else{
        setData(inputPhase)
        handleClose()
      }
    }else{
      swal("Ooops","Veuillez completer tous les champs","warning")
    } 
  }
  return(
    <div>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Nouvelle phase
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div>
            <CustumForm.Group  margin='10px 0 0 0' direction ='row' flex={1} >
                <CustumForm.Input 
                   size="small" 
                  name="typephase" 
                  margin="dense" 
                  onChange={handleChangeInputPhase} 
                  value={inputPhase['typephase']||""} 
                  select 
                  wd='100%'  
                  label="Type de phase">

                    {typePhase&&typePhase.map((type,index)=>(<MenuItem key={index}  value={type}>{type}</MenuItem>))}
                  </CustumForm.Input>
            </CustumForm.Group>
            <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
              <CustumForm.Input 
                  size="small" 
                  margin="dense" 
                  style={{marginRight:20,marginBottom:20}}  
                  label="description"
                   
                  wd='100%'
                  name='description' 
                  value={inputPhase['description'] ||""}
                  onChange={handleChangeInputPhase} 
                  />
            </CustumForm.Group>
            <div>
              <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                <div style={{display:'flex'}}>
                  <Form.Check 
                    type="radio"
                    id='dureephasefixe'
                    label="Oui"
                    name ='dureephasefixe'
                    style={{marginLeft:20}}
                    onChange={handleChangeInputPhase}
                    value='O'
                    checked = {inputPhase?.dureephasefixe==='O'}
                  />
                  <Form.Check 
                    type="radio"
                    id='dureephasefixe'
                    label="Non"
                    name ='dureephasefixe'
                    style={{marginLeft:10}}
                    onChange={handleChangeInputPhase}
                    value='N'
                    checked = {inputPhase?.dureephasefixe==='N'}
                  />
                </div>
                } 
                label="Durée de phase fixe"
                labelPlacement='start'
                />
            </div> 
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleAddPhase}>
         Ajouter
        </Button>
      </DialogActions>
    </BootstrapDialog>
  </div>
  )
}

const useStyles = makeStyles()((theme=>({
  
})))

const DispositifAdd = (props) => {
  const location  =  useLocation()
  const navigate = useNavigate()
  const portail = useSelector(state=>state.ui.portail).toLowerCase()
  const [loading,setLoading] = useState(false)
  const [errors,setErrors] = useState(null)

  const handleSubmit =(e)=>{
    e.preventDefault()
    setLoading(true)
    if(!inputField.hasOwnProperty('libelle') || inputField.libelle.trim() ==="" ){
        swal("Erreur !","Veuillez renseigner un intitulé",'error')
        setLoading(false)
        return
    }
     
    axios.post(`/api/dispositif/add`,inputField).then(res=>{
        if(res.status === 200 || res.status ===201){
            swal("Bien !","Dispositif créée avec succès",'success')
            setLoading(false)
              handlelist()
        }else{
            swal("Erreur !","Erreur lors de la création du dispositif, veillez corriger et réessayer",'error')
            setLoading(false)
        }
    }).catch(err=>{
        console.log(err)
        if(err.hasOwnProperty("response")){
            setErrors(err.response.data?.errors)
        }
        swal("Erreur !","Erreur lors de la création du dispositif, veillez corriger et réessayer",'error')
        setLoading(false)
    })
    
  }
  const handlelist = ()=>{
    navigate(`/${portail}/dispositifs`)
  }
  const [inputField,setInputField] = useState(initialState)
  const handleChange =(e)=>{
      const {name,value} = e.target
      setInputField({...inputField,[name]:value})
  }
  const handleEditorChange =(field,value)=>{
      setInputField({...inputField,[field]:value});
  }
  const handeleAutoCompleteChange =(name,values)=>{
    setInputField({...inputField, [name]:values})
  }
  const handleSetData = (dt) =>{
    setInputField({...inputField,'phases':[...inputField.phases,dt]})
  }
 const [open,setOpen] = useState(false)
 const handleOpen = () => {
  setOpen(true)
 }
 const handleClose = () => {
  setOpen(false)
 }

  return (
    <div className="categorieadd">
        <SaisiePhase open={open} handleClose={handleClose} data={inputField.phases} setData={handleSetData} />
        <div className="titlebox">
            <Typography variant='h5'>Nouveau dispositf d'accompnement</Typography>
            <Button className="btn" onClick={(e)=>handlelist()}><ArrowBackIosIcon sx={{color:'#00B04B'}} />Retour à la liste</Button>
        </div>
        <div className="bodybox">
            <form onSubmit={handleSubmit}>
              <Paper className="formbox" variant='outlined'>
                  <Typography>DESCRIPTION</Typography>
                  <Card.Hr />
                      <div style={{display:'flex',flexDirection:'column',justifyContent:"flex-start"}}>
                          <CustumForm style={{display:'flex',flexDirection:'column'}}>
                            <Card>
                              <Card.Header>
                                  <Card.Title>INFORMATIONS PRINCIPALES</Card.Title>
                             
                              </Card.Header>
                              <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                  <CustumForm.Input 
                                      size="small" 
                                      margin="dense" 
                                      style={{marginRight:20,marginBottom:20}}  
                                      label="Intitule"
                                      required 
                                      wd='100%'
                                      name='libelle' 
                                      value={inputField['libelle'] ||""}
                                      onChange={handleChange} 
                                      />
                                  {(errors && errors.hasOwnProperty('libelle'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['libelle'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}
                                  <ReactQuill  
                                  placeholder={"Description du profil"} 
                                  style={{width:'100%',marginBottom:20}}
                                  name='description' 
                                  theme="snow" 
                                  value={inputField['description']||""} 
                                  onChange={(v)=>handleEditorChange('description',v)} 
                              
                              />
                              {(errors && errors.hasOwnProperty('description'))&&<Alert key="danger" variant="danger">
                                          <ul>
                                          {errors['description'].map((err,index)=><li key={index}>{err}</li>)}
                                          </ul>
                                      </Alert>}

                              
                              </CustumForm.Group>
                              <CustumForm.Group>
                              <RadioField
                                    groupItems={[{label:'Local',name:'Local'},{label:'Regional',name:'Regional'},{label:'National',name:'National'}]}
                                    group="Type"
                                    checkedDefault="Normal"
                                    appearance={radiobutton_appearances.primary}
                                    onChange ={handleChange}
                                    name='type'
                                    vvalue ={inputField.type}
                                />
                              </CustumForm.Group>   
                              <div>
                              <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='suspensionok'
                                          label="Oui"
                                          name ='suspensionok'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.suspensionok==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='suspensionok'
                                          label="Non"
                                          name ='suspensionok'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.suspensionok==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé la suspension?"
                                      labelPlacement='start'
                                      />
                              </div> 
                              <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='dateentreeforce'
                                          label="Oui"
                                          name ='dateentreeforce'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.dateentreeforce==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='dateentreeforce'
                                          label="Non"
                                          name ='dateentreeforce'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.dateentreeforce==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Date d'entrée obligatoire ?"
                                      labelPlacement='start'
                                      />
                                    </div>
                                    <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='dateinitforcer'
                                          label="Oui"
                                          name ='dateinitforcer'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.dateinitforcer==='O'}
                                          
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='dateinitforcer'
                                          label="Non"
                                          name ='dateinitforcer'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.dateinitforcer==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Date d'initialisation obligatoire ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                    <CustumForm.Input 
                                        size="small" 
                                        margin="dense" 
                                        style={{marginRight:20,marginBottom:20}}  
                                        label="Mésure nationale"
                                       
                                        wd='100%'
                                        name='mesurenationale' 
                                        value={inputField['mesurenationale'] ||""}
                                        onChange={handleChange} 
                                        />
                                  </CustumForm.Group>
                                  <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                    <CustumForm.Input 
                                        size="small" 
                                        margin="dense" 
                                        style={{marginRight:20,marginBottom:20}}  
                                        label="Type d'appui"
                                         
                                        wd='100%'
                                        name='typeappui' 
                                        value={inputField['typeappui'] ||""}
                                        onChange={handleChange} 
                                        />
                                    </CustumForm.Group>
                                      
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='deployementauto'
                                          label="Oui"
                                          name ='deployementauto'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.deployementauto==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='deployementauto'
                                          label="Non"
                                          name ='deployementauto'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.deployementauto==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé le déploiement automatique ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                </Card>
                                <Card>
                                  <Card.Header>
                                      <Card.Title>ENTREES/SORTIES</Card.Title>     
                                  </Card.Header>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='esmultiple'
                                          label="Oui"
                                          name ='esmultiple'
                                          onChange={handleChange}
                                          style={{marginLeft:20}}
                                          value='O'
                                          checked = {inputField?.esmultiple==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='esmultiple'
                                          label="Non"
                                          name ='esmultiple'
                                          onChange={handleChange}
                                          style={{marginLeft:10}}
                                          value='N'
                                          checked = {inputField?.esmultiple==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Autorisé les entrées/sorties multiple ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                      <div style={{display:'flex'}}>
                                        <Form.Check 
                                          type="radio"
                                          id='clotureauto'
                                          label="Oui"
                                          name ='clotureauto'
                                          style={{marginLeft:20}}
                                          onChange={handleChange}
                                          value='O'
                                          checked = {inputField?.clotureauto==='O'}
                                        />
                                        <Form.Check 
                                          type="radio"
                                          id='clotureauto'
                                          label="Non"
                                          name ='clotureauto'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.clotureauto==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Clôture automatique à la fin du dispositif?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  {(inputField?.clotureauto && inputField?.clotureauto==='O')&&
                                    <CustumForm.Group  margin='10px 0px 20px 0px'direction ='column' flex={1} >
                                      <Autocomplete
                                          multiple
                                          freeSolo
                                          id="secteur-standard"
                                          fullWidth
                                          options={dispositiSortie}
                                          onChange={(e,values)=>handeleAutoCompleteChange("motifsortieauto",values)}
                                          renderInput={(params) => (
                                            <CustumForm.Input
                                              {...params}
                                              name='motifsortieauto'
                                              label="Motif de sortie automatique"
                                              placeholder="Motif de sortie automatique"
                                              size="small" margin="dense" wd='100%'
                                            />
                                          )}
                                      />                                        
                                    </CustumForm.Group>}
                                    {(inputField?.clotureauto && inputField.clotureauto==='O')&&<CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                    <CustumForm.Input 
                                        size="small" 
                                        margin="dense" 
                                        style={{marginRight:20,marginBottom:20}}  
                                        label="Durée entre la fin prévisionnelle et la clôture automatique (en jours)" 
                                        wd='100%'
                                        type='number'
                                        name='durefincloture' 
                                        value={inputField['durefincloture'] ||""}
                                        onChange={handleChange} 
                                        />
                                    </CustumForm.Group>}
                                    <div>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='dateentreepasse'
                                            label="Oui"
                                            name ='dateentreepasse'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.dateentreepasse==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='dateentreepasse'
                                          label="Non"
                                          name ='dateentreepasse'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.dateentreepasse==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Date d'entrée dans le dépassé possible ?"
                                      labelPlacement='start'
                                      />
                                      {(inputField?.dateentreepasse && inputField.dateentreepasse==='O')&&<CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                    <CustumForm.Input 
                                        size="small" 
                                        margin="dense" 
                                        style={{marginRight:20,marginBottom:20}}  
                                        label="Nombre de mois autorisé" 
                                        wd='100%'
                                        type='number'
                                        name='nombremois' 
                                        value={inputField['nombremois'] ||""}
                                        onChange={handleChange} 
                                        />
                                    </CustumForm.Group>}
                                  </div>
                                </Card>
                                <Card>
                                  <Card.Header>
                                      <Card.Title>DRUREE</Card.Title>     
                                  </Card.Header>
                                  <div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='dureelimite'
                                            label="Oui"
                                            name ='dureelimite'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.dureelimite==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='dureelimite'
                                          label="Non"
                                          name ='dureelimite'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.dureelimite==='N'}
                                        />
                                        
                                      </div>
                                      } 
                                      label="Durée limitée ?"
                                      labelPlacement='start'
                                      />
                                      {(inputField?.dureelimite && inputField.dureelimite==='O')&&<CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                        <CustumForm.Input 
                                            size="small" 
                                            margin="dense" 
                                            style={{marginLeft:20,marginBottom:20}}  
                                            label="Durée du dispositif (en mois)"
                                            required 
                                            type='number'
                                            name='dureedispositif' 
                                            value={inputField['dureedispositif'] ||""}
                                            onChange={handleChange} 
                                            />
                                        </CustumForm.Group>}
                                  </div>
                                  
                                  <div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='etendu'
                                            label="Oui"
                                            name ='etendu'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.etendu==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='etendu'
                                          label="Non"
                                          name ='etendu'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.etendu==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Le dispositif peut être étendu?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                  <CustumForm.Group direction ='column' style={{paddingBottom:20}} flex={1}>
                                    <CustumForm.Input 
                                        size="small" 
                                        margin="dense" 
                                        style={{marginLeft:20,marginBottom:20}}  
                                        label="Durée de l'extension (en mois)"
                                        required 
                                        type='number'
                                        name='dureeextension' 
                                        value={inputField['dureeextension'] || ""}
                                        onChange={handleChange} 
                                        />
                                  </CustumForm.Group>
                              </Card>
                              <Card>
                                  <Card.Header>
                                      <Card.Title>VERSEMENTS</Card.Title>     
                                  </Card.Header>
                                  <div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='versementactiver'
                                            label="Oui"
                                            name ='versementactiver'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.versementactiver==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='versementactiver'
                                          label="Non"
                                          name ='versementactiver'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.versementactiver==='N'}
                                        />
                                        
                                      </div>
                                      } 
                                      label="Versements activés ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                      {(inputField?.versementactiver && inputField?.versementactiver==='o')&&<CustumForm.Group direction ='row' style={{paddingBottom:20}} flex={1}>
                                          <CustumForm.Input 
                                              size="small" 
                                              margin="dense" 
                                              style={{marginLeft:20,marginBottom:20}}  
                                              label="Nombre de période initiale"
                                              
                                              type='number'
                                              name='nbreperiodeinit' 
                                              value={inputField['nbreperiodeinit'] || ""}
                                              onChange={handleChange} 
                                            />
                                            <CustumForm.Input 
                                              size="small" 
                                              margin="dense" 
                                              style={{marginLeft:20,marginBottom:20}}  
                                              label="Nombre de période d'extension"
                                               
                                              type='number'
                                              name='nbreperiodeext' 
                                              value={inputField['nbreperiodeext'] || ""}
                                              onChange={handleChange} 
                                              />
                                        </CustumForm.Group>}
                                        
                                        {(inputField?.versementactiver && inputField?.versementactiver==='o')&&<CustumForm.Group direction ='row' style={{paddingBottom:20}} flex={1}>
                                          <CustumForm.Input 
                                              size="small" 
                                              margin="dense" 
                                              style={{marginLeft:20,marginBottom:20}}  
                                              label="Montant Max. par période"
                                             
                                              type='number'
                                              name='montantmaxper' 
                                              value={inputField['montantmaxper'] || ""}
                                              onChange={handleChange} 
                                              />
                                              <CustumForm.Input 
                                              size="small" 
                                              margin="dense" 
                                              style={{marginLeft:20,marginBottom:20}}  
                                              label="Montant max. versé"
                                               
                                              type='number'
                                              name='montantmaxpaie' 
                                              value={inputField['montantmaxpaie'] || ""}
                                              onChange={handleChange} 
                                              />
                                          </CustumForm.Group>}
                                        
                                         
                                  
                                  </Card>
                                  <Card>
                                  <Card.Header>
                                      <Card.Title>PHASES</Card.Title>     
                                  </Card.Header>
                                  <div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='phaseok'
                                            label="Oui"
                                            name ='phaseok'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.phaseok==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='phaseok'
                                          label="Non"
                                          name ='phaseok'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.phaseok==='N'}
                                        />
                                        
                                      </div>
                                      } 
                                      label="Le dispositif contient des phases ?"
                                      labelPlacement='start'
                                      />
                                  </div>
                                   {(inputField?.phaseok && inputField?.phaseok==='O')&&<div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='nbrephaselimite'
                                            label="Oui"
                                            name ='nbrephaselimite'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='o'
                                            checked = {inputField?.nbrephaselimite==='o'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='nbrephaselimite'
                                          label="Non"
                                          name ='nbrephaselimite'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.nbrephaselimite==='N'}
                                        />
                                        
                                      </div>
                                      } 
                                      label="Nombre de phase limité ?"
                                      labelPlacement='start'
                                      />
                                      <Card.Hr />
                                  </div>}
                                   
                                  {(inputField?.phaseok && inputField?.phaseok==='o')&&<div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='chevauchephase'
                                            label="Oui"
                                            name ='chevauchephase'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.chevauchephase==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='chevauchephase'
                                          label="Non"
                                          name ='chevauchephase'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.chevauchephase==='N'}
                                        />
                                      </div>
                                      } 
                                      label="Chevauchement de phase possible ?"
                                      labelPlacement='start'
                                      />
                                  </div>}
                                  {(inputField?.phaseok && inputField?.phaseok==='o')&&<div style={{display:'flex',alignItems:'center'}}>
                                      <FormControlLabel sx={{marginRight:5,marginBottom:5}} control={ 
                                        <div style={{display:'flex'}}>
                                          <Form.Check 
                                            type="radio"
                                            id='ordonnancementphase'
                                            label="Oui"
                                            name ='ordonnancementphase'
                                            style={{marginLeft:20}}
                                            onChange={handleChange}
                                            value='O'
                                            checked = {inputField?.ordonnancementphase==='O'}
                                          />
                                        <Form.Check 
                                          type="radio"
                                          id='ordonnancementphase'
                                          label="Non"
                                          name ='ordonnancementphase'
                                          style={{marginLeft:10}}
                                          onChange={handleChange}
                                          value='N'
                                          checked = {inputField?.ordonnancementphase==='N'}
                                        />
                                        
                                      </div>
                                      } 
                                      label="Ordonnancement des phases disposnible?"
                                      labelPlacement='start'
                                      />
                                    </div>}
                                    {(inputField?.phaseok && inputField?.phaseok==='o')&&<div>
                                    <Table>
                                      <thead>
                                        <tr>
                                          <td>Type de phase</td>
                                          <td>Ordre</td>
                                          <td>Durée fixe</td>
                                          <td>Nb Max phase</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(inputField.hasOwnProperty('phases') && inputField.phases!==0)?inputField.phases.map((phase,index)=><tr>
                                          <td>
                                            <div>
                                              <span>{phase.typephase}</span><br />
                                              <small>{phase.description}</small>
                                            </div>
                                            </td>
                                            <td valign='middle'>{index+1}</td>
                                          <td>{phase.dureephasefixe==='o'?'Oui':"Non"}</td>
                                          <td></td>
                                         
                                        </tr>):<tr>
                                          <td colSpan={3}><p>Aucune phase</p></td>
                                        </tr>}
                                        
                                      </tbody>
                                    </Table>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <IconButton onClick={handleOpen}>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                  </div>}
                                  
                                </Card>
                  
                                  
                                  
                          </CustumForm>
                      </div>
                      
                  </Paper>
                  <Card style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
                        <Button disabled={loading} type='submit' style={{backgroundColor:'#00B04B'}}>
                            {loading?<Spinner />:<SaveIcon sx={{fontSize:40,color:'#ffffff'}} />} 
                        </Button>
                        <Button disabled={loading} type='reset' style={{marginLeft: 20, backgroundColor:'#F29400'}}>
                            <RefreshIcon sx={{fontSize:40,color:'#ffffff'}} />
                        </Button>
                  </Card>     
            </form>
        </div>
    </div>
  )
}

export default DispositifAdd
