// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDWoxV0Kq8QvYOeYUGvVNm1cwOjEcKccUQ",
  authDomain: "psfapp-bc8f7.firebaseapp.com",
  projectId: "psfapp-bc8f7",
  storageBucket: "psfapp-bc8f7.appspot.com",
  messagingSenderId: "235802263977",
  appId: "1:235802263977:web:9cf2ae82f60ed4cd87c4b8"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export {firebase}