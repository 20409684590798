import React,{useCallback, useContext, useEffect, useState} from 'react'
import { Card,Form } from 'components'
import { AddContext } from 'context/add'
import { Divider, Typography,IconButton } from '@mui/material';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { getExtension } from 'utils/utils';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {styled as  makeStyles} from "@mui/material";
import axios from 'lib/axios';
const styles ={
  detContainer:{
    display:'flex',
    flexDirection:'row',
    flex:1,
    justifyContent:'center'
  },

  detItemLeft:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    alignItems:'flex-end',
    fontWeight:'bold',
    justifyContent:'flex-start',
   
  },
  detItemRight:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flex:1,
    marginLeft:20,
  },
  label:{
    fontStyle:'italic',
    fontWeight:'bold'
  }
}
const useStyles = makeStyles(theme=>({

}))
function StepsResume(props) {
  const data = useContext(AddContext)
  const classes = useStyles()
  const {step,steps,handleBack,handleSubmit} = props
  const [resumeData,setResumeData] =useState(null)
  console.log(data);
  const handleResumeData =useCallback(()=>{
    let dataSet ={}
    if(data.hasOwnProperty('parcours') && data.parcours.length!==0){
      dataSet['cursus']=data.parcours.filter(item=>(item.type ==='cursus' || item.type ==='formation'))
      dataSet['experience']=data.parcours.filter(item=>item.type ==='experience')
    }
    if(data.hasOwnProperty('atouts') && data.atouts.length!==0){
      dataSet['loisirs'] = data.atouts.filter(item=>(item.type ==='Loisirs'))
      dataSet['connaissances'] = data.atouts.filter(item=>item.type ==='Connaissance')
    }
    setResumeData({...resumeData,...dataSet})
  },[data.parcours,data.atouts])
  useEffect(()=>{
    handleResumeData()
  },[data.parcours,data.atouts])
  const [services,setServices] = useState(null)
  useEffect(()=>{
    axios.get(`/api/type-demande/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setServices(res.data)
      }
    })
  },[])
console.log(data);
  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
      <Card>
          <Card.Header>
              <Card.Title>{`Etape ${step+1} : ${steps[step]}` }</Card.Title>
              <Card.Hr />
          </Card.Header>
      </Card>
      
      <Card>
          <Card.Header>
            <Typography variant='subtitle2'>INFORMATIONS D'IDENTIFICATION</Typography>
            <Divider />
          </Card.Header>
          <div style={styles.detContainer}>
             <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Civilité : </Typography> 
              <Typography style={styles.label}>Nom : </Typography>
              <Typography style={styles.label}>Prénoms : </Typography> 
              <Typography style={styles.label}>Date de naissance : </Typography>
              <Typography style={styles.label}>Lieu de naissance : </Typography>
              <Typography style={styles.label}>Nationalité : </Typography>
              <Typography style={styles.label}>Nom du pére : </Typography>
              <Typography style={styles.label}>Nom de la mère : </Typography>
              <Typography style={styles.label}>{data['naturepiece']||'CNI'} : </Typography>
              <Typography style={styles.label}>Est victime d'un handicap ? : </Typography>
              {(data.hasOwnProperty('esthandicap') && data['esthandicap']==='Oui')&&<Typography style={styles.label}>Nature de l'handicap : </Typography>}
            </div>

             <div style={styles.detItemRight}>
              <Typography>{data['civilite']||' '}</Typography>
              <Typography>{data['nom']||' '}</Typography>
              <Typography>{data['prenom']||' - '} </Typography> 
              <Typography>{data['datenaissance']?dayjs(data['datenaissance']).format('DD/MM/YYYY'):" - "} </Typography> 
              <Typography>{data['lieunaissance']||' - '} </Typography>
              <Typography>{data['nationalite']||' - '} </Typography>
              <Typography>{data['nompere']||' - '}</Typography>
              <Typography>{data['nommere']||' - '}</Typography>
              <Typography>{data['numeropiece']||' - '} : </Typography>
              <Typography>{data['esthandicap']||' - '} : </Typography>
              {(data.hasOwnProperty('esthandicap') && data['esthandicap']==='Oui')&&<Typography>{data['naturehandicap']}</Typography>}
          </div>
        </div>
      </Card>
      <Card>
          <Card.Header>
            <Typography variant='subtitle2'>SITUATION FAMILIALE</Typography>
            <Divider />
          </Card.Header>
          <div style={styles.detContainer}>
              <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Situation matrimoniale : </Typography>
              <Typography style={styles.label}>Nombre d'enfant : </Typography>
              <Typography style={styles.label}>Nom du conjoint/de la conjointe : </Typography>
              <Typography style={styles.label}>Profession du conjoint/ de la conjointe : </Typography>
              </div>
              <div style={styles.detItemRight}>
                <Typography>{data['sitmatrimoniale']||'-  '} </Typography>
                <Typography>{data['nombreenfant']||' '}</Typography>
                  <Typography>{data['nomconjoint']||' - '} </Typography> 
                <Typography>{data['professionconjoint']||' - '} </Typography>
            </div>
          </div>
      </Card>

      <Card>
          <Card.Header>
          <Typography variant='subtitle2'>COORDONNEES</Typography>
            <Divider />
          </Card.Header>
          <div style={styles.detContainer}>
            <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Ville de résidence : </Typography> 
              <Typography style={styles.label}>Commune de résidence : </Typography>
              <Typography style={styles.label}>Quartier : </Typography>
              <Typography style={styles.label}>CGQ/CGV : </Typography>
            </div>

            <div style={styles.detItemRight}>
              <Typography>{data['ville']||'-  '} </Typography>
              <Typography>{data['commune']||' '}</Typography>
              <Typography>{data['quartier']||' - '} </Typography>
              <Typography>{data['cgvcgq']||' - '} </Typography>
            </div>
          </div>
          <div style={styles.detContainer}>
            <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Adresse postale : </Typography>
              <Typography style={styles.label}>Téléphone fixe : </Typography>
              <Typography style={styles.label}>Mobile: </Typography>
              <Typography style={styles.label}>N° whatsapp : </Typography>    
              <Typography style={styles.label}>Adresse email : </Typography>
           </div>
            <div style={styles.detItemRight}>
              <Typography>{data['bp']||'-  '} </Typography>
              <Typography>{data['telephonefixe']||' '}</Typography>
              <Typography>{data['mobile1']||' - '} / {data['mobile2']||' - '}</Typography>
              <Typography>{data['numerowhatsapp']||' - '} </Typography> 
              <Typography>{data['email']||' - '}</Typography>
            </div>
          </div>
      </Card>
      <Card>
          <Card.Header>
          <Typography variant='subtitle2'>QUALIFICATIONS</Typography>
            <Divider />
          </Card.Header>
          <div style={styles.detContainer}>
            <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Diplôme : </Typography>
              <Typography style={styles.label}>Etablissement dipôme : </Typography>
              <Typography style={styles.label}>Année dernier diplôme : </Typography>
              <Typography style={styles.label}>Pays dernier diplôme : </Typography>
            </div>

            <div style={styles.detItemRight}>
              
              <Typography>{data['diplome']||' '}</Typography>
              <Typography>{data['etablissement']||' - '} </Typography>
              <Typography>{data['anneediplome']||' - '} </Typography>
              <Typography>{data['paysdiplome']||' - '} </Typography>
            </div>
          </div>


          <div style={styles.detContainer}>
            <div style={styles.detItemLeft}>
              <Typography style={styles.label}>Situation actuelle : </Typography>
              <Typography style={styles.label}>Niveau d'etude : </Typography>
              <Typography style={styles.label}>Activite actuelle : </Typography>
              <Typography style={styles.label}>Secteur: </Typography>
              <Typography style={styles.label}>Année d'expérience : </Typography>    
            
           </div>
            <div style={styles.detItemRight}>
              <Typography>{data['situation']||'-  '} </Typography>
              <Typography>{data['niveauetude']||'-  '} </Typography>
              <Typography>{data['activite']||' '}</Typography>
              <Typography>{data['secteuractivite']||' - '} / {data['mobile2']||' - '}</Typography>
              <Typography>{data['anneeexperience']||' - '} </Typography> 
             
            </div>
          </div>
      </Card>

      <Card>
          <Card.Header>
          <Typography variant='subtitle2'>CURSUS / EXPERIENCES</Typography>
            <Divider />
          </Card.Header>
          {(resumeData && resumeData.hasOwnProperty('cursus') && resumeData['cursus'].length!==0)&&<div style={{marginTop:20}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {resumeData.cursus.map((cur,indx)=>{
                    return <TableRow key={"cur-"+indx}>
                        <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                        <TableCell align="left">{cur.diplome}</TableCell>
                        <TableCell align="left">{cur.etablissement}</TableCell>
                        <TableCell align="left">{cur.mission}</TableCell>
                  </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
          {(resumeData && resumeData.hasOwnProperty('experience') && resumeData['experience'].length !==0)&&<div style={{marginTop:20}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {resumeData.experience.map((cur,indx)=>{
                    return <TableRow key={"ex-"+indx}>
                        <TableCell align="center">{`${dayjs(cur.datedebut).format('DD/MM/YYYY')} - ${dayjs(cur.datefin).format('DD/MM/YYYY')}`}</TableCell>
                        <TableCell align="left">{cur.diplome}</TableCell>
                        <TableCell align="left">{cur.etablissement}</TableCell>
                        <TableCell align="left">{cur.mission}</TableCell>
                  </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>}
      </Card>
      <Card>
          <Card.Header>
          <Typography variant='subtitle2'>CONNAISSANCES  / LOISIRS</Typography>
            <Divider />
          </Card.Header>
          {(resumeData && resumeData.hasOwnProperty('connaissances') && resumeData['connaissances'].length!==0)&&<div style={{marginTop:20}}>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {resumeData.connaissances.map((cur,indx)=>{
                  return <TableRow key={"cn-"+indx}>
                      <TableCell align="center">{cur.libelle}</TableCell>
                      <TableCell align="left">{cur.niveau}</TableCell>
                </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
        {(resumeData && resumeData.hasOwnProperty('loisirs') && resumeData['loisirs'].length !==0)&&<div style={{marginTop:20}}>
         
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {resumeData.loisirs.map((cur,indx)=>{
                    return <TableRow key={"lrs-"+indx}>
                        <TableCell align="center">{cur.libelle}</TableCell>
                        <TableCell align="left">{cur.niveau}</TableCell>
                  </TableRow>
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
      </Card>
      <Card>
          <Card.Header>
          <Typography variant='subtitle2'>BESOINS</Typography>
            <Divider />
          </Card.Header>
          <div style={styles.detContainer}>
            <div style={styles.detItemLeft}><Typography style={styles.label}>Objectif : </Typography></div>
            <div style={styles.detItemRight}><Typography>{data['objectif']||'- '} </Typography></div>     
          </div>
          <div style={styles.detContainer}>
          {(data && data.hasOwnProperty('demandes'))&&<div style={{marginTop:20}}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {data.demandes.map((dmd,indx)=>{
                    return (
                    <TableRow key={"dmd-"+indx}>
                        <TableCell align="center">{(services && services.length!==0)&&services.filter(item=>item.id===dmd.service)[0]['designation'] }</TableCell>
                        <TableCell align="left">{dmd.priorite}</TableCell>
                        <TableCell align="left">{dmd.description}</TableCell>
                  </TableRow>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
          </div>
      </Card>
      <Card>
          <Card.Header>
            <Typography variant='subtitle2'>Documents exigés</Typography>
            <Divider />
          </Card.Header>
          
          <div style={styles.detContainer}>
          <div style={{marginTop:20}}>
          {(data.hasOwnProperty('documents') && data['documents'].length!==0)&&<div>
            <Form.Hr style={{marginTop:20}} />
            <Typography>Liste des documents</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  { data['documents'].map((doc,indx)=>{
                    return <TableRow key={"doc-"+indx}>
                        <TableCell align="left">{doc.do_doctype}</TableCell>
                          <TableCell align="center">{
                            (doc.hasOwnProperty('selectedFile') && doc.selectedFile)&&doc.selectedFile.map(file=>(getExtension(file.name).toLowerCase()=='pdf'?<FilePresentIcon sx={{fontSize:60,color:'#888888'}}/>:
                            
                            <img
                                width="20%"
                                className={classes.media}
                                src={file.preview}
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                      
                  ))}</TableCell>
                        <TableCell align="center">
                          <IconButton aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                  </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>} 
        </div>
          </div>
      </Card>
      <Card direction='row' palign='space-between'>
        {step>0&&<Form.Button onClick={handleBack} >Retour</Form.Button> }
           <Form.Button onClick={handleSubmit} primary>Valider</Form.Button> 
      </Card>
    </div>
  )
}

export default StepsResume