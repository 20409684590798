import { createContext, useState } from 'react';

 
import React from 'react'
export const UserContext = createContext()
const PortailContext = ({children}) => {
    const {prortails,setProtails} = useState()
  return (
    <UserContext.Provider value={{prortails,setProtails}}>{children}</UserContext.Provider>
  )
}

export default PortailContext
