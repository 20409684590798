import React,{useReducer} from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui'
import { Card } from 'components'
import { Form } from 'components'
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
const useStyles = makeStyles()((theme=>{
    return {
        titlediv:{
            display:'flex',
            flex:1,
            justifyContent:'space-between',
            alignItems:'center'
        },
    content:{
        display:'flex',
        flex:1,
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column'
        }
   
    },
    content_form:{
        display:'flex',
        flexDirection:'column',
        width:'70%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_diag:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        }
    },
    content_img:{
        display:'flex',
        flexDirection:'column',
        minHeight:'100%',
        flex:1
    },
    photo_label:{
        fontSize:15,
        fontStyle:'italic',
        fontWeight:'bold'
    },
    logo_box:{
        display:'flex',
        justifyContent:'center',
        alignItems:'ceùnter',
        border:'1px dashed #707070',
        minHeight:'85%',
        background: 'rgba(244,244,244,0.6)',
        position:'relative',
    },
    photo_check:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    input: {
        display: "none"
      },
    img_card:{
        minWidth:'100%',
        minHeight:'100%',
        position:'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    boxOffres:{
        display:'flex',
        flexWrap:'wrap',
        flex:1,
        padding:20
    },
    cardOffres:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        '&:hover':{
            backgroundColor:'#00B04B',
            color:'white',
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        }
    },
    cardOffresCheck:{
        flex:1,
        margin:10,
        padding:20,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        backgroundColor:'#00B04B',
        color:'white',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"

    }
};
}
))

const  AddPersoRessource = (props)=>{
    const {open,handleSetData,handleClose} =props
    const {classes} =useStyles()
    const [personneField,setPersonneField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      nom:null,
      prenom:null,
      telephone:null,
      mobile:null,
      email:null,
      fonction:null,
      civilite:null
    });
    const handleChange = (event) => {
        const {name,value} =event.target
        setPersonneField({
            [name]:value
        })
      };
    
      const handleAdd = ()=>{
        if(personneField['nom'] && personneField['prenom'] && (personneField['telephone'] || personneField['mobile'])){
            handleSetData(personneField)
            handleClose()
        }else{

        }
      }
    return (
        <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        onClose={(event, reason) => {
            if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClose()
            }
        }}
    >
            <DialogTitle>
                <div className={classes.titlediv}>
                    <Typography variant='h6'> Ajouter une personnes réssource</Typography>
                    <IconButton aria-label="part-close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Card.Hr />
            </DialogTitle>
            <DialogContent>
                <div className={classes.content_diag}>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Input required size="small" name="civilite" margin="dense" onChange={handleChange} value={personneField['civilite']||""} select style={{marginRight:20}}  label="Civilité">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='Mlle'>Mlle</MenuItem>
                        <MenuItem value='Mmme'>Mme</MenuItem>
                        <MenuItem value='Monsieur'>Monsieur</MenuItem>
                    </Form.Input>
                    <Form.Input required size="small" name="fonction" margin="dense" onChange={handleChange} value={personneField['fonction']||""} select style={{marginRight:20}}  label="Fonction/rôle">
                        <MenuItem  value='No'>Aucun</MenuItem>
                        <MenuItem value='DG'>Directeur générale</MenuItem>
                        <MenuItem value='DRH'>Directeur des réssources humaines</MenuItem>
                        <MenuItem value='Comptable'>Comptable</MenuItem>
                        <MenuItem value='Commercial'>Commercial</MenuItem>
                        <MenuItem value='Service achat'>Service achat</MenuItem>
                        <MenuItem value='Service achat'>Autre</MenuItem>
                    </Form.Input>
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                  <Form.Input name='nom' value={personneField.nom} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Nom" wd='50%' />
                  <Form.Input   name='prenom' value={personneField.prenom} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Prénom" wd='50%' />
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                    <Form.Input name='telephone' value={personneField.telephone} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Téléphone"  />
                    <Form.Input name='mobile' value={personneField.mobile1} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Mobile"  />  
                </Form.Group>
                <Form.Group  margin='10px 0 0 0'direction ='row' palign="space-around" flex={1} >
                    <Form.Input   name='email' value={personneField.email} style={{marginRight:20}} onChange={handleChange} size="small" margin="dense" label="Adresse email" wd='100%' />
                </Form.Group>
                </div>
            </DialogContent>
            <DialogActions>
                <Form.Group palign='space-between'  margin='10px 0 0 0'direction ='row' flex={1} >
                    <Form.Button onClick={handleClose} >Annuler</Form.Button>
                    <Form.Button onClick={handleAdd} primary>Ajouter</Form.Button> 
                    </Form.Group>
            </DialogActions>
        </Dialog>
    )
}

export default AddPersoRessource