import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { changePortail } from 'redux/actions/uiActions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Portail({changePortail,ui:{portail,portails}}) {
    const current =portail;
    const mycurrent = (portails && portails.length!==0)&&portails.filter(element => element.libelle.toLowerCase()==current.toLowerCase())[0]?.id
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleNavigate = (route)=>{
      navigate(`/${route}`)
    }
    const [selectedIndex, setSelectedIndex] = React.useState(mycurrent);
    
    const handleClick = (index) => {
        console.info(`You clicked ${(portails && portails.length!==0)&&portails.filter(item=>item.id===index)[0]['libelle']}`);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        changePortail(portails.filter(item=>item.id===index)[0]?.libelle,handleNavigate)
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
    };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained"  color='inherit' ref={anchorRef} aria-label="split button" >
        <Button fullWidth onClick={()=>handleClick((portails && portails.length!==0)&&portails.filter(item=>item.id===selectedIndex)[0]?.id)} 
        >{(portails && portails.length!==0)&&portails.filter(item=>item.id===selectedIndex)[0]?.libelle}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {(portails && portails.length!==0)&&<Popper
      sx={{
        zIndex: 1,
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
             <MenuList id="split-button-menu" autoFocusItem>
                {portails.map(option => (<MenuItem
                    key={option.id}
                    selected={option.id === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, option.id)}
                  >
                    {option.libelle.toLocaleUpperCase()}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>}
  </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ui: state.ui,
});
const mapPropsAction ={changePortail}

export default connect(mapStateToProps,mapPropsAction)(Portail)
