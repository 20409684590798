import React, {  useEffect, useState } from 'react'
import { styled as uiStyled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const StyledTableCell = uiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = uiStyled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const BootstrapDialog = uiStyled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function DocumentsList({open,handleClose,documents}) {
  
    const [docCheck,setDocCheck] = useState([])
    const [docList,setDocList] = useState([])
    useEffect(()=>{
      if(documents && documents.length!==0){
        setDocList(documents.map(doc=>{
          doc['check'] = false
          return doc
        }))
      }
    },[documents])
  
    const handleShowDocument =(doc)=>{
      let a = document.createElement('a')
      a.href =`${process.env.REACT_APP_BACKEND_URL}/${doc.url}`
      a.target ='_blank'
      a.click()
    }
    const handleChecked =(doc)=>{
      let newChecked = [...docList]
      newChecked = newChecked.map(el=>{
        if(el.id === doc.id){
          el['check'] = !doc.check
        }
        return el
      })
    
      setDocList([...newChecked])
    }
    const handleCheckedAll = (e)=>{
      const {checked} = e.target
      let mydoc =[]
      if(checked){
        documents.forEach(el=>{
          el['check'] =true
          mydoc.push(el)
        })
        if(mydoc.length !==0){
          setDocList([...mydoc])
        }
      }else{
        documents.forEach(el=>{
          el['check'] =false
          mydoc.push(el)
        })
        if(mydoc.length !==0){
          setDocList([...mydoc])
        }
      }
    }
    const handleCheckChecked =()=>{
      let currentChecked =[]
      docList.forEach(el=>{
        if(el.check){
          currentChecked.push(el.id)
        }
      })
      if(currentChecked && currentChecked.length!==0){
        setDocCheck([...currentChecked])
      }
    }
    useEffect(()=>{
      handleCheckChecked()
    },[docList])
  
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth='md'
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginRight:20}}>
              <div style={{display:'flex', alignItems:'center'}}>
                <AttachFileIcon />
                <Typography variant='h5'>Liste des documents</Typography>
              </div>
             
              {(docCheck && docCheck.length!==0)&&<IconButton color='primary'><SendIcon /></IconButton>}
            </div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {(docList && docList.length!==0)?(
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Checkbox onChange={handleCheckedAll} color="success" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docList.map((document) => (
                    <StyledTableRow key={document.id}>
                       <StyledTableCell component="th" scope="row">
                            <Checkbox checked={document.check}  onChange={(e)=>handleChecked(document)} color="success" />
                       </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {document.titre}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <IconButton onClick={(e)=>handleShowDocument(document)}>
                              <CloudDownloadIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
      </TableContainer>):(<Typography>Aucun document attaché</Typography>)}
          
          </DialogContent>
         
        </BootstrapDialog>
      </div>
    );
  }
  
  export default DocumentsList