import React,{useState,useContext, useEffect} from 'react'
import { Card,Form } from 'components'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {RadioField} from "components";
import { radiobutton_appearances } from "components/radioField";
import MenuItem from '@mui/material/MenuItem';
import {objectifs} from 'fixtures/data'
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import { AddContext } from 'context/add'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'custumstyle.css'
import axios from 'lib/axios';
import { pays,secteuractivites,niveauetudes,niveauexperience } from 'fixtures/data'
const styles ={
  texteArea:{
    border:'none', 
    width:'100%',
    borderBottomWidth:1, 
    borderBottomColor:'#00B04B',
    padding:10,
    marginBottom:20,
    marginRight:20,
    borderRadius:4,
    backgroundColor:'#F8F8F8',
    '& focus':{
      border:'none', 
    }
  }
}
const groupItems=[
    {
      name: "Haute",
      label: "Haute",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
      name: "Normal",
      label: "Normal",
      information: "Veuillez selectionner un type",
      disabled: false
    },
    {
        name: "Bas",
        label: "Bas",
        information: "Veuillez selectionner un type",
        disabled: false
      }
  ]
function StepsDemandes(props) {
    const data = useContext(AddContext)
    const {handleNext, step,steps,handleBack} = props
    const [inputFields, setInputFields] = useState([
        { dmdservice: '', dmdpriorite: '',dmddescription:'' }
      ])

  const [services,setServices] = useState(null)
  useEffect(()=>{
    axios.get(`/api/type-demande/all`).then(res=>{
      if(res.status === 200 || res.status === 201){
        setServices(res.data)
      }
    })
  },[])

    const [currentField,setCurrentField] = useState({
      objectif:null,
      service:null,
      priorite:null,
      description:null
    })

  const handleFormChange =(index,event)=>{
    let datalo =data.hasOwnProperty('demandes') ?[...data['demandes']]:[{objectif:"", service: '',priorite: '',description:'' }]
    datalo[index][event.target.name]=event.target.value;
    setInputFields(datalo);
    data.handleChangeData('demandes',datalo)
  }
const handleChangeWithValue =(index,name,value)=>{
    let datalo =data.hasOwnProperty('demandes') ?[...data['demandes']]:[{objectif:"", service: '',priorite: '',description:'' }]
    datalo[index][name]=value;
    setInputFields(datalo);
    data.handleChangeData('demandes',datalo)
}
  const handleEditorChange =(index,field,value)=>{
    let datalo =data.hasOwnProperty('demandes') ?[...data['demandes']]:[{objectif:"",service: '', priorite: '',description:'' }]
    datalo[index][field]=value;
    setInputFields(datalo);
    data.handleChangeData('demandes',datalo)
  }

  const addFields =(e)=>{
    e.preventDefault()
    let newfield =  {objectif:'',service: '', priorite: '',description:'' }
    setInputFields([newfield,...inputFields])
    data.handleChangeData('demandes',[newfield,...inputFields])
  }
  const handleDelete = (idx)=>{
    if(idx!==(inputFields.length-1)){
      const cdata =[...inputFields]
      const newfields =  cdata.splice(idx,1)
      setInputFields(newfields)
      data.handleChangeData('demandes',newfields)
    }
  }
  useEffect(()=>{
    if(data.hasOwnProperty('demandes') && data.demandes.lenght!==0){
      setInputFields(data.demandes);
    }
  },[])

  return (
    <div style={{display:'flex', flexDirection:'column',flex:1,marginRight:20}}>
    <Card>
        <Card.Header>
          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Card.Title>{`Etape ${step+1} : ${steps[step]}` }</Card.Title>
            <Form.RButton color='#556B2F'  onClick={addFields}><AddIcon /></Form.RButton> 
          </div> 
            <Card.Hr />
        </Card.Header>
      </Card>
      <Card>
        <Form>
            <Form.Base>
              {inputFields.map((fields,index)=>{
                return <div 
                  key={index} 
                  style={{display:'flex', flexDirection:'column',flex:1,paddingLeft:10,marginBottom:20,background:(index!==0)?"#f9f8e3":"transparent"}}>
                  <Form.Group margin='0px 0px 20px 0px' direction ='row' salign='center' flex={1}>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      id="objectif"
                      options={objectifs}
                      style={{ width: '100%' }}
                      value={fields.objectif}
                      name='objectif'
                      onChange={(event,v)=>handleChangeWithValue(index,'objectif',v)}
                      renderInput={(params) => <Form.Input {...params} name='objectif' label="Objectif" />}
                      />
                  </Form.Group>
                  
                  <Form.Group margin='10px 0px 0px 0px'direction ='row' flex={1} >
                        <Form.Input 
                            name='service' 
                            size="small" 
                            margin="dense" 
                            style={{ width: '100%'}}
                            select 
                            value ={fields.service} 
                            onChange={(event)=>handleFormChange(index,event)}  label="Demande">
                          <MenuItem  value='No'>Aucun</MenuItem>
                          {(services && services.lenght!==0)&& services.map(srs=>(<MenuItem key={srs.id} value={srs.id}>{srs.libelle}</MenuItem>))}
                        </Form.Input>
                    </Form.Group>
                  <Form.Group  margin='10px 0 0 0'direction ='row' flex={1} >
                    <ReactQuill  
                        placeholder={"Description du besoin"} 
                        style={{width:'100%'}}
                        name='description' 
                        theme="snow" value={fields.description} 
                        onChange={(v)=>handleEditorChange(index,'description',v)} 
                    
                    />
                    
                  </Form.Group>
                  {(index !== 0)&&<Form.Group  margin='10px 0 0 0'direction ='row' palign='center' flex={1} >
                        <IconButton aria-label="delete" onClick={(e)=>handleDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                  </Form.Group>}
                  
                </div>
              })}
             
            </Form.Base>
           
        </Form>
       
    </Card>
    <Card direction='row' palign='space-between'>
      {step>0&&<Form.Button onClick={handleBack} >Retour</Form.Button> }
         <Form.Button onClick={handleNext} primary>Suivant</Form.Button> 
    </Card>
    </div>
  )
}

export default StepsDemandes